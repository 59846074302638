<template>
  <div id="nav-bar" class="nav-bar">
    <nav class="nav-bar__container">
      <ul class="nav-list">
        <li class="nav-item nav-item--home" :class="{ activated: isActivated('home') }">
          <router-link :to="{ name: 'Home' }">
            <div class="container">
              <img :src="homeIcon" class="image" />
              <span class="label">홈</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item nav-item--qr" :class="{ activated: isActivated('qr') }">
          <button class="button qr-button" @click="openQrCodeReader">
            <img src="@/assets/icons/svg/qr-white.svg" class="image" />
          </button>
        </li>
        <li class="nav-item nav-item--usage" :class="{ activated: isActivated('usage') }">
          <router-link :to="{ name: 'Usage' }">
            <div class="container">
              <img :src="usageIcon" class="image" />
              <span class="label">이용내역</span>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'NavBar',
  props: {},

  computed: {
    homeIcon() {
      if (this.isActivated('home')) return require('@/assets/icons/svg/home-blue.svg');

      return require('@/assets/icons/svg/home.svg');
    },

    qrIcon() {
      if (this.isActivated('qr')) return require('@/assets/icons/svg/qr-white.svg');

      return require('@/assets/icons/svg/qr-white.svg');
    },

    usageIcon() {
      if (this.isActivated('usage')) return require('@/assets/icons/svg/sheet-blue.svg');

      return require('@/assets/icons/svg/sheet.svg');
    }
  },

  methods: {
    isActivated(nav) {
      //@todo qr코드 레이어 열렸을 때 리턴도 추가
      return this.$route.name.toLowerCase() === nav.toLowerCase();
    },

    openQrCodeReader() {
      this.$analytics.logEvent('complete home qr scan');

      setTimeout(() => {
        this.$store.dispatch('qrCodeScanner/open', {
          type: 'onetime-callback',
          callback: async ({ status, code, store_id, error }) => {
            try {
              if (status === 'ERROR') {
                throw error;
              }

              console.log( "code", code );

              if (status === 'SUCCESS') {
                setTimeout(() => {
                  this.$router.push({ name: 'Redirect', params: { code: code } });
                }, 0);
              }
            } catch (e) {
              this.$store.commit('alert/ADD_ITEM', {
                message: e.toString(),
                status: 'error'
              });
            }
          }
        });
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-bar {
  width: 100%;
  background: $color-white;
  @include container('& > .nav-bar__container', $max-content-width);

  .nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: unit(16);
    box-shadow: inset 0 2px 1px -1px #eaeeef;
    padding: 0 unit(16);
  }

  .nav-item {
    flex: 0 1 unit(100);

    .container {
      text-align: center;
      height: unit(60);
      line-height: unit(12);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: unit(4);

      .image {
        flex: 0;
        width: unit(28);
        height: unit(28);
        object-fit: contain;
        margin: 0 auto;
        margin-top: unit(6);
      }

      .label {
        flex: 0;
        font-size: unit(14);
        margin-top: 0;
        margin-bottom: auto;
      }
    }

    &--home {
      // flex: 0 0 unit(84);
      // width: unit(84);
      // height: unit(50);
    }

    &--qr {
      transform: translateY(-50%);
      flex: 0 0 unit(84);
      height: unit(60);
    }

    &--usage {
    }

    &.activated {
      color: $color-primary;
    }
  }

  .qr-button {
    width: unit(86);
    height: unit(86);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(47.02% 47.02% at 50% 50%, #008fff 0%, rgba(0, 143, 255, 0) 100%);
    border-radius: unit(100);
    transform: translateY(#{unit(-8)});

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: unit(64);
      height: unit(64);
      content: '';
      background: $color-primary;
      border-radius: unit(100);
    }

    &::after {
      width: unit(68);
      height: unit(68);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      background: rgba(0, 143, 255, 0.24);
      border-radius: 50%;
    }

    .image {
      width: unit(32);
      height: unit(32);
      object-fit: contain;
      display: inline-block;
      z-index: 1;
    }
  }
}
</style>
