<template>
  <div class="ticket-message" :class="{ 'ticket-message--activated': activated }">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{ $__t("예약완료") | toUpperCase }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_issued) | moment("HH:mm") }}
        </div>
      </div>

      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body">
            <div class="message-body__contents">
              <h3 class="primary">
                {{ ticket.store_name }}
              </h3>
              <p class="secondary">
                <span>{{ reserveDateTimeText }}</span
                ><br />
                <span>{{ ticketQuantityText }}</span>
              </p>
              <button
                v-if="ticket.status === 'ISSUED'"
                type="button"
                @click="cancelReservation"
                class="cancel-button"
                v-button
              >
                {{ $__t("Cancel Booking") | capitalizeFirstLetter }}
              </button>
            </div>
            <div class="message-body__actions">
              <button
                v-button
                type="button"
                @click="ticket.status === 'ISSUED' ? $emit('open-edit') : ''"
                class="action-button"
                :class="{ disabled: ticket.status !== 'ISSUED' }"
                :disabled="ticket.status !== 'ISSUED'"
              >
                {{ $__t("시간 변경") | toUpperCase }}
              </button>
              <button
                v-button
                type="button"
                @click="$emit('open-find')"
                class="action-button"
              >
                {{ $__t("상점 찾기") | toUpperCase }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from "@/components/includes/QrCode";
import { directive as Viewer } from "v-viewer";

export default {
  name: "MessageIssued",

  props: {
    ticket: {
      type: Object,
      default: {},
    },

    order: {
      type: Object,
      default: {},
    },

    activated: {
      type: Boolean,
      default: false,
    },

    invitation: {
      type: Array,
      default: () => [],
    },
    cancelAvailable: {
      type: Boolean,
    },
  },

  data() {
    return {};
  },
  mounted() {
    // console.log("ticket", this.ticket);
  },

  computed: {
    onClickHeaderButton() {
      return null;
    },

    reserveDateTimeText() {
      const momentDateStart = this.$moment(this.ticket.ticket_date_start);
      const momentDateEnd = this.$moment(this.ticket.ticket_date_end);
      const momentNow = this.$moment();

      let text = "";

      if (momentDateStart.format("YYYY-MM-DD") === momentDateEnd.format("YYYY-MM-DD")) {
        if (momentNow.format("YYYY-MM-DD") === momentDateStart.format("YYYY-MM-DD")) {
          text = `${this.$__t("{date-time-picker}.today")} ${momentDateStart.format(
            "HH:mm"
          )} - ${momentDateEnd.format("HH:mm")}`;
        } else {
          text = `${momentDateStart.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateStart.format("ddd").toUpperCase()
          )}) ${momentDateStart.format("HH:mm")} - ${momentDateEnd.format("HH:mm")}`;
        }
      } else {
        if (momentNow.format("YYYY-MM-DD") === momentDateStart.format("YYYY-MM-DD")) {
          text = `${this.$__t("{date-time-picker}.today")} ${momentDateStart.format(
            "HH:mm"
          )} - ${momentDateEnd.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateEnd.format("ddd").toUpperCase()
          )}) ${momentDateEnd.format("HH:mm")}`;
        } else {
          text = `${momentDateStart.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateStart.format("ddd").toUpperCase()
          )}) ${momentDateStart.format("HH:mm")} - ${momentDateEnd.format(
            "MM/DD"
          )}(${this.$__t(
            "SSA_" + momentDateEnd.format("ddd").toUpperCase()
          )}) ${momentDateEnd.format("HH:mm")}`;
        }
      }

      return text;
    },

    ticketQuantityText() {
      try {
        let value = "";
        //물품 단일화 버전
        if (this.ticket?.extras?.version) {
          if (this.ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t("물품")} ${this.ticket.ticket_quantity}`;

            if (this.ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t("item");
            } else {
              value += quantityString + this.$__t("items");
            }
          }
          //물품 단일화 전(기존 버전)
        } else {
          if (this.ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t("{luggage-selector}.small")} ${
              this.ticket.ticket_quantity
            }`;

            if (this.ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t("item");
            } else {
              value += quantityString + this.$__t("items");
            }
          }

          if (this.ticket.ticket_over_quantity > 0) {
            let quantityString = `${this.$__t("{luggage-selector}.big")} ${
              this.ticket.ticket_over_quantity
            }`;

            if (this.ticket.ticket_over_quantity > 0)
              quantityString = " " + quantityString;

            if (this.ticket.ticket_over_quantity === 1) {
              value += quantityString + this.$__t("item");
            } else {
              value += quantityString + this.$__t("items");
            }
          }
        }
        return value;
      } catch (e) {
        return null;
      }
    },
  },

  methods: {
    cancelReservation() {
      if (!this.cancelAvailable) {
        return this.$store.commit("alert/ADD_ITEM", {
          message: "예약 시간이 경과하여 취소가 불가능합니다.",
          status: "error",
        });
      }
      return this.$router.push({
        name: "CancelTicket",
        parmas: { id: this.ticket.ticket_code },
      });
    },
  },

  components: {
    QrCode,
  },

  directives: {
    viewer: Viewer,
  },
};
</script>
