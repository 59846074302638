<template>
  <div class="locker-box-selector" :class="{ readonly: readonly }">
    <div class="locker-viewport" ref="viewport">
      <ul class="locker-list">
        <li v-for="(column, colIndex) in data" :key="`column-${colIndex}`" class="column">
          <ul class="row">
            <li
              ref="cell"
              v-for="(row, rowIndex) in column"
              :key="`column-${colIndex}-row-${rowIndex}`"
              :style="{ height: getBoxHeight(row) + 'px' }"
              :class="getCellClass(row)"
              @click="readonly ? '' : onClickCell(row)"
              :data-activated="isSelectedBox(row)"
            >
              <span class="text">
                <template v-if="row.locker_number"> {{ row.locker_number }} • {{ row.locker_size }} </template>
                <template v-else>
                  <i class="fas fa-lock"></i>
                </template>
              </span>
            </li>
            <li
              v-show="highestColHeight > getColHeight(column)"
              :style="{ height: getDummyBoxHeight(column) + 'px' }"
              class="extra"
            ></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'value', 'readonly'],

  data() {
    return {
      selectedBox: null,
    };
  },

  watch: {
    selectedBox(newValue) {
      this.$emit('input', newValue);
    },

    value(newValue) {
      this.selectedBox = newValue;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.readonly) {
        this.scrollToValueCell();
      }
    });
  },

  computed: {
    highestColHeight() {
      let height = 0;
      if (Array.isArray(this.data) && this.data.length > 0) {
        height = Math.max(
          ...this.data.map((column) => {
            return column.reduce((acc, cur, idx) => acc + this.getBoxHeight(cur), 0);
          })
        );
      }

      return height;
    },
  },

  methods: {
    getDummyBoxHeight(column) {
      return this.highestColHeight - this.getColHeight(column);
    },

    getColHeight(column) {
      return column.reduce((acc, cur, idx) => acc + this.getBoxHeight(cur), 0);
    },

    isSelectedBox(cell) {
      return (
        this.selectedBox &&
        this.getLockerId(cell) &&
        this.getLockerId(cell) === (this.selectedBox.locker_id ?? this.selectedBox.id)
      );
    },

    getCellClass(cell) {
      if (this.readonly) {
        return this.readonlyCellClass(cell);
      }

      const STATUS = {
        READY: 'available',
        STORED: 'using',
        RESERVED: 'using',
        BLOCKED: 'unavailable',
      };

      const classList = [STATUS[cell.status ?? 'BLOCKED']];

      if (this.selectedBox) {
        classList.push(this.isSelectedBox(cell) ? 'selected' : 'not-selected');
      }

      return classList;
    },

    readonlyCellClass(cell) {
      if (this.selectedBox?.locker_id === this.getLockerId(cell)) {
        return ['available', 'selected'];
      } else {
        return ['unavailable', 'not-selected'];
      }
    },

    getLockerId(cell) {
      return cell.locker_id ?? cell.id;
    },

    getBoxHeight({ locker_height }) {
      const locker_width = 500;
      // if (locker_size === 'S') height = 50;
      // if (locker_size === 'M') height = 80;
      // if (locker_size === 'L') height = 120;
      // if (locker_size === 'XL') height = 160;

      const ratio = locker_height / locker_width;
      const height = ratio * 100;

      return height;
    },

    onClickCell(cell) {
      if (cell.status !== 'READY') return;

      if (this.selectedBox === cell) {
        this.selectedBox = null;
        return;
      }

      this.selectedBox = cell;
    },

    getValueCell() {
      const cellList = this.$refs.cell;

      if (Array.isArray(cellList)) {
        for (const cell of cellList) {
          if (cell.dataset.activated == 'true') {
            return cell;
          }
        }
      }

      return null;
    },

    scrollToValueCell() {
      const targetCell = this.getValueCell();

      if (!targetCell) return;

      const viewport = this.$refs.viewport;

      const targetCellAbsTop = window.scrollY + targetCell.getBoundingClientRect().top;
      const targetCellAbsLeft = window.scrollX + targetCell.getBoundingClientRect().left;
      const viewportAbsTop = window.scrollY + viewport.getBoundingClientRect().top;
      const viewportAbsLeft = window.scrollX + viewport.getBoundingClientRect().left;

      viewport.scrollTo(
        targetCellAbsLeft - viewportAbsLeft - viewport.clientWidth / 2,
        targetCellAbsTop - viewportAbsTop - viewport.clientHeight / 2
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.locker-box-selector {
  .locker-viewport {
    width: 100%;
    height: unit(348);
    overflow-x: scroll;
    overflow-y: scroll;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: auto;
  }

  .locker-list {
    display: flex;
    gap: unit(6);
    margin-top: unit(20);
    width: 100%;
    text-align: center;
    align-items: stretch;

    .column {
      display: flex;
      width: 100%;
      white-space: nowrap;
      width: auto;
      flex-direction: row;

      & > li {
        flex: 0 0 100px;
        width: 100px;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 400px;
      width: 100px;

      & > li {
        margin: unit(2) 0;
        min-height: 40px;
        background-color: #f1f3f5;
        border-radius: unit(14);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        font-weight: 500;
        font-size: unit(14);
        line-height: 1.43;

        &::before {
          content: '';
          visibility: hidden;
          padding-bottom: 60%;
          height: 0;
        }

        &.using {
          background-color: #b4b4b4;

          &.not-selected {
            background: rgba(180, 180, 180, 0.4);
          }
        }

        &.available {
          background-color: #008fff;

          &.not-selected {
            background: rgba(0, 143, 255, 0.4);
          }
        }

        &.unavailable {
          background-color: #f1f3f5;
        }

        &.selected {
          box-shadow: 0px unit(4) unit(12) rgba(0, 0, 0, 0.16), unit(4) unit(8) unit(28) rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  &.readonly {
  }
}
</style>
