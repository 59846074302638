<template>
  <div class="postcode-select ui-textbox" :class="{ hover: state.hover, focus: state.focus, fill: state.fill, error: error, disabled: disabled }">
    <label ref="hover" @click="onClickInput">
      <div class="label">{{ label }}</div>
      <div clas="body">
        <input ref="input" class="form-control" type="text" :name="name" :value="value" @focus="onFocus" @blur="onBlur" autocapitalize="off" autocomplete="off" />
        <div v-if="searchIcon" class="icon-button">
          <button type="button">
            <img src="/img/icon-search-blue.png">
          </button>
        </div>
        <ui-solid-button v-else display="inline" size="extra-small" class="button-end"><button type="button" ><i class="fa fa-map-signs"></i>{{ $__t( "store-form.store-zipcode.button" ) }}</button></ui-solid-button>
      </div>
    </label>
    <div class="error" v-if="error">{{ error }}</div>

    <portal to="destination" v-if="isOpen">
      <div class="postcode-select-layer" @click="onClickLayer">
        <daum-postcode :q="query" @complete="onComplete" ></daum-postcode>
      </div>
    </portal>
  </div>
</template>

<script>

export default {
  mounted() {

  },
  data() {
    var state = { hover: false, focus: false, fill: false, error: false };
    return {
      callback: null,      
      geocodeResult: {
        loaded: false,
        geo_latitude: 0,
        geo_longitude: 0
      },
      isOpen: false,
      state
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    query: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    plaeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchIcon: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    geocode_provider(value, oldValue) {
      if (this.callback && value) {
        let geodata = this.geocodeResult[value]
        if (typeof this.callback === 'function') {
          this.callback(geodata)
        }
      }
    },
    value:{
      handler(value){
        if(value.trim() != ''){
          this.state.fill = true;
        }else{
          this.state.fill = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClickInput(e) {
      this.$data.isOpen = true
    },
    onClickLayer(e) {
      this.$data.isOpen = false
    },
    
    async onComplete( data ) {
      let { zonecode } = data, res
      
      this.$data.callback = null

      try{
        res = await this.$store.dispatch('location/geocode', { address: data.address, language: this.$store.state.lang });

        if(res.data.data.items.length > 0){
          res = res.data.data.items[0];

          this.geocodeResult.geo_latitude = res.geo.lat + '';
          this.geocodeResult.geo_longitude = res.geo.lng + '';

          this.geocodeResult.loaded = true; 
        }
      }
      catch(e){ 
        this.$log.error(e) 
      }
      finally{
        this.$emit("input", zonecode)
        this.$emit("complete", {
          ...data,
          geoCodeProvider: this.geocode_provider,
          ...this.geocodeResult
        })
        this.$data.isOpen = false
      }
    },

    onFocus(e) {
      this.state.focus = true
      this.$emit("focus", e)
    },
    onBlur(e) {
      this.state.focus = false
      this.$emit("blur", e)
    }
  }
}
</script>
