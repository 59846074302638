<template>
  <div id="mypage-coupon-page" class="page more-depth">
    <div class="container">
      <div class="page-header">
        <h1 class="page-header__title">{{ $__t("이용권") }}/{{ $__t("쿠폰 내역") }}</h1>
      </div>

      <div class="page-body">
        <!-- <router-link :to="{ name: 'Shop' }">
          <div class="lead-section" v-button>
            <div class="image-area">
              <img src="/img/icon/celebrate.svg" />
            </div>
            <div class="text-area">
              <p>
                제주도 어디든! 몇 번이든! 다 보관할 수 있는
                <strong>프리패스 구매하러가기 <i class="fas fa-arrow-right"></i></strong>
              </p>
            </div>
          </div>
        </router-link> -->

        <ul class="tab-list">
          <li
            :class="{ activated: currentTab === 'all' }"
            @click="currentTab = 'all'"
            class="tab-item"
          >
            {{ $__t("전체") }}
          </li>
          <li
            @click="currentTab = 'pass'"
            class="tab-item"
            :class="{ activated: currentTab === 'pass' }"
          >
            {{ $__t("이용권") }}
          </li>
          <li
            @click="currentTab = 'coupon'"
            class="tab-item"
            :class="{ activated: currentTab === 'coupon' }"
          >
            {{ $__t("쿠폰") }}
          </li>
        </ul>

        <ul v-if="targetItems?.length > 0" class="coupon-list">
          <li v-for="item in targetItems" :key="item.id" class="coupon-item">
            <router-link :to="{ name: 'Stores' }">
              <div
                class="ui-coupon__for-guest"
                :class="{
                  disabled: !(
                    item.status === 'PREPARED' ||
                    item.status === 'STARTED' ||
                    (item.used === false && item.expired === false)
                  ),
                }"
              >
                <div class="status">{{ getStatusText(item) }}</div>
                <div class="title">
                  {{
                    (item.coupon_code ? $__t("쿠폰") + " " + item.coupon_name : null) ||
                    item.product_name ||
                    (item.pass_code ? $__t("프리패스 이용권") + " " + item.pass_code : "")
                  }}
                </div>
                <div
                  class="description"
                  v-if="
                    item.status === 'PREPARED' ||
                    item.status === 'STARTED' ||
                    (item.used === false && item.expired === false)
                  "
                >
                  {{ $__t("보관 가능 상점 보러가기") }} <i class="fas fa-arrow-right"></i>
                </div>
              </div>
            </router-link>
          </li>
        </ul>

        <div v-else class="empty-list">
          <p>{{ $__t("데이터가 존재하지 않습니다") }}</p>
        </div>
      </div>

      <!-- <div class="page-footer">
        <div class="button-area">
          <button type="button" class="ui-inverted-button activated" v-button @click="resetFormData();openSlider('code-register-slider');">
            <span class="ui-inverted-button__text">이용권 등록</span>
          </button>
        </div>
      </div> -->
    </div>

    <ui-slider id="code-register-slider" ref="code-register-slider" :bgClick="true">
      <div class="code-register">
        <div class="code-register__body">
          <ui-textbox
            v-model="formData.registrable.value"
            :type="formData.registrable.type"
            :label="formData.registrable.label"
            :placeholder="formData.registrable.placeholder"
            :error="formData.registrable.error"
          />
        </div>
        <div class="code-register__footer">
          <div class="code-register__submit-area">
            <button
              type="button"
              class="ui-submit-button activated"
              @click="onClickRegister"
            >
              <span class="ui-submit-button__text">
                {{ $__t("등록하기") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </ui-slider>
  </div>
</template>

<script>
import UiCoupon from "@/components/resources/coupon/ForGuest.vue";
import UiSlider from "@/components/modules/Slider/Body.vue";
import network from "@/modules/network";

export default {
  name: "MypageCoupons",

  data() {
    return {
      currentTab: "all",

      formData: {
        registrable: {
          label: "이용권 코드",
          type: "text",
          value: "",
          placeholder: "코드를 입력해주세요.",
          error: "",
        },
      },
    };
  },

  async mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener("scroll", this.toggleHeaderTitleVisibility);
    }, 0);

    await this.fetchCoupons();
    await this.fetchPasses();
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener("scroll", this.toggleHeaderTitleVisibility);
  },

  computed: {
    targetItems() {
      const coupons = this.$store.getters["resourceCoupons/GET_ITEMS_BY_LIST"]({
        list: "MyCoupons",
      });
      const passes = this.$store.getters["resourceProducts/GET_ITEMS_BY_LIST"]({
        list: "MyPasses",
      });

      if (this.currentTab === "coupon") {
        return coupons;
      }

      if (this.currentTab === "pass") {
        return passes;
      }

      return [...passes, ...coupons];
    },
  },

  methods: {
    resetFormData() {
      this.formData.registrable.value = "";
      this.formData.registrable.error = "";
    },

    getStatusText(item = {}) {
      if (item.coupon_code) {
        if (item.used) {
          return this.$__t("사용됨");
        }

        if (item.expired) {
          return this.$__t("EXPIRED");
        }

        return this.$__t("사용가능");
      }

      if (item.product_id) {
        if (item.status === "PREPARED") {
          return this.$__t("구매완료·사용전");
        }

        if (item.status === "STARTED") {
          return this.$__t("사용중");
        }
      }

      return this.$__t("사용불가");
    },

    async fetchCoupons() {
      let options = {
        list: "MyCoupons",
        query: {
          page: 1,
          size: 10,
          group: "available",
        },
        clear: true,
      };

      try {
        await this.$store.dispatch("resourceCoupons/getList", options);
      } catch (e) {
        this.$log.error(e);
      }
    },

    async fetchPasses() {
      let options = {
        list: "MyPasses",
        query: {
          page: 1,
          size: 10,
          group: "available",
          type: "ALL",
        },
      };

      try {
        await this.$store.dispatch("resourceProducts/getMyList", options);
      } catch (e) {
        this.$log.error(e);
      }
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $(".page-header > .page-header__title", this.$el);
      const $siteHeaderTitle = $("#site-header > .container > .header > .title");
      const $siteHeader = $("#site-header");

      if (clear === true) {
        $siteHeaderTitle.removeClass("off");
        return;
      }

      const isHeaderCovered =
        $pageHeaderTitle.offset().top - $pageHeaderTitle.outerHeight() < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass("off");
        $siteHeader.removeClass("shadow");
      } else {
        $siteHeaderTitle.removeClass("off");
        $siteHeader.addClass("shadow");
      }
    },

    async onClickRegister() {
      this.$store.commit("loading/SET_TRUE");

      try {
        await network.put(this.$store.state.config.apiURL + "/v2/freepass/link", {
          user_id: this.$store.state.auth.gid,
          pass_code: this.formData.pass_code,
        });

        await Promise.all[(this.fetchPasses(), this.fetchCoupons())];

        setTimeout(() => {
          this.$store.commit("alert/ADD_ITEM", {
            message: this.$__t("등록되었습니다"),
            status: "success",
          });
          this.closeSlider("code-register-slider");
          this.$store.commit("loading/SET_FALSE");
        }, 1000);
      } catch (e) {
        this.formData.registrable.value = "";

        if ((e?.message || "").indexOf("NO_EXISTS_DATA")) {
          this.formData.registrable.error = this.$__t("코드가 올바르지 않습니다.");
        }

        this.$store.commit("alert/ADD_ITEM", {
          // message: e?.message,
          message: this.formData.registrable.error,
          status: "error",
        });
        this.$store.commit("loading/SET_FALSE");
      }
    },

    openSlider(ref) {
      this.$refs[ref].open();
    },

    closeSlider(ref) {
      this.$refs[ref].close();
    },
  },
  components: {
    UiCoupon,
    UiSlider,
  },
};
</script>

<style lang="scss" scoped></style>
