<template>
    <div id="promotion-home">
        <div class="container">
            <div style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center;">
                <i class="fa fa-spinner fa-pulse"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: [ "code" ],
        data(){
            return {
            };
        },
        computed: {
        },
        mounted(){
            this.axios.get(this.$store.state.config.apiURL+'/v2/campaigns/invite/'+this.code).then(({data})=> {
                this.$log.log("get code success!!")
                this.$log.log(data.data.campaign.campaign_code);
                this.campaign_code = data.data.campaign.campaign_code;
                if(data.data.promotion){
                    this.promotion = data.data.promotion;
                }
                this.$store.dispatch( "userData/set", {campaignCode: this.campaign_code, promotion: this.promotion} );
                this.$router.replace({name:'Home'});
            }).catch(error => {
                this.$store.commit("alert/ADD_ITEM", { message: error, status: "error" });
                location.replace('/')
            })
        },
        methods: {

        }
    }
</script>
