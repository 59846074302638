<template>
  <transition name="slide-up">
    <div v-if="opened" ref="background" class="slider-item">
      <div v-if="message && messageShow" class="message_banner">
        <div class="message_banner__text">
          {{ $__t("아래 시간은 사용 예상시간이에요.") }}<br />
          {{ $__t("실제 이용시간만큼 결제되니 안심하고 사용하세요") }} :)
        </div>
        <button class="remove-btn" @click="onClickCloseMessage">
          <img src="/img/icon/close-white.svg" alt="" />
        </button>
      </div>
      <div @click.stop="" class="container" :class="{ center: center === true }">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "UiSlider",

  props: {
    data: {
      type: Object,
      default: () => {},
    },

    background: {
      type: Boolean,
      default: false,
    },

    bgClick: {
      type: Boolean,
      default: false,
    },

    message: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      message_banner: null,
      opened: false,
      messageShow: true,
    };
  },

  watch: {
    opened(opened) {
      if (opened) {
        this.$nextTick(() => {
          if (this.$refs.background && this.bgClick === true) {
            this.$refs.background.addEventListener("mousedown", (e) => {
              if (this.$store.state.config.isTouchDevice || e.which == 1) {
                if (e.target == this.$el) {
                  this.close();
                }
              }
            });
          }
        });
      }
    },
  },

  mounted() {
    if (this.data?.duration > 0) {
      setTimeout(this.close, this.data.duration);
    }
  },

  methods: {
    open() {
      this.opened = true;
      this.$emit("open");
    },

    close() {
      this.opened = false;
      this.$emit("close");
    },

    onClickCloseMessage() {
      this.messageShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.message_banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: unit(16) unit(24);
  // width: unit(300);
  width: 90%;
  height: unit(80);
  background: linear-gradient(90deg, #7474ff 19.85%, #4d4dff 96.57%);
  text-align: left;
  border-radius: 16px;
  margin-top: unit(200);
  &__text {
    // width: unit(223);
    width: 95%;
    line-height: unit(24);
    font-size: unit(14);
    color: #ffffff;
  }
  .remove-btn {
    width: unit(16);
    height: unit(16);
  }
}
</style>
