<template>
  <div>
    <div
      v-if="loading"
      style="
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        z-index: 1450;
      "
    >
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
    <div
      class="page"
      id="mybilling-page"
      :class="{ 'more-depth': $route.meta && $route.meta.headerType === 'more-depth' }"
    >
      <div class="container">
        <div class="page-header">
          <div class="container">
            <div class="title">
              <span class="text">{{ $__t('Payment Card') }}</span>
            </div>
            <div id="site-back-button" @click="backTo">
              <i class="fa fa-angle-left"></i>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="subpage" id="mybilling-submit-page" v-if="selectedView === 'MyBillingCard'">
            <div class="container">
              <div class="information">
                <img src="/img/logotype.svg" class="logo" />
                <span class="text">{{
                  $__t(
                    'By adding card once, all the hassles will gone and be able to use LugStay service in more convenient way.'
                  )
                }}</span>
              </div>
              <div v-if="!Object.keys(billings).length" class="card-button" @click.stop="isMobile('cardListModal')">
                <img src="/img/card.png" srcset="/img/card@2x.png 2x, /img/card@3x.png 3x" class="card" />
                <span class="text">{{ $__t('Credit/Debit Card') }}</span>
                <img
                  src="/img/arrow-right.png"
                  srcset="/img/arrow-right@2x.png 2x, /img/arrow-right@3x.png 3x"
                  class="arrow"
                />
              </div>
              <div v-else class="list-box">
                <ul>
                  <li
                    ref="cardSingle"
                    v-for="(i, index) in billings"
                    :key="index"
                    :class="{ active: i.is_primary === 1 }"
                  >
                    <div class="card-button" :key="index">
                      <img
                        v-if="i.is_primary === 1"
                        src="/img/card.png"
                        srcset="/img/card@2x.png 2x, /img/card@3x.png 3x"
                        class="card"
                        style="box-shadow: 1px 1px 1px 0 rgba(30, 79, 117, 0.34)"
                      />
                      <img
                        v-else
                        src="/img/card-disable.png"
                        srcset="/img/card-disable@2x.png 2x, /img/card-disable@3x.png 3x"
                        class="card"
                      />
                      <span class="text">{{ i.card_name }}&nbsp;{{ i.card_number.substr(15, 4) }}</span>
                      <img
                        @click="toggleKebabButton(i, index, $event)"
                        src="/img/kebab-menu.png"
                        srcset="/img/kebab-menu@2x.png 2x, /img/kebab-menu@3x.png 3x"
                        class="kebab"
                        :key="index"
                      />
                      <popup
                        :ref="'modifyBillingPopup' + index"
                        :items="popupMenu.items"
                        :coordinates="popupMenu.coordinates"
                        @clickItem="onClickPopupItem"
                        :blur="true"
                      />
                    </div>
                  </li>
                  <li>
                    <div class="add-card" @click="openModal('registerCardModal')">
                      <span class="text">{{ $__t('Add Payment Card') }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="notice">
                <ul>
                  <li class="body-title">{{ $__t('[Card Registration & Configuration Guide]') }}</li>
                  <li class="dot no-margin">
                    {{
                      $__t(
                        'Basic fee will be charged when you make your reservation and additional fee will be paid later by the hours of usage.'
                      )
                    }}
                  </li>
                  <li class="dot">
                    {{ $__t('Adding card is to register your primary credit card or debit card.') }}
                  </li>
                  <li class="dot">
                    {{
                      $__t(
                        'Once you register your card, enjoying service will be much easier since automatic payment is possible.'
                      )
                    }}
                  </li>
                  <li class="dot">
                    {{ $__t('You can register a number of cards when if you have multiple purposes.') }}
                  </li>
                  <li class="dot">
                    {{
                      $__t(
                        'You can configure primary card & delete card by clicking the icon ( ፧ ) on the right side of the added card'
                      )
                    }}
                  </li>
                </ul>
                <ul>
                  <li class="body-title">
                    {{ $__t('If you have any questions, please contact the messenger at the bottom right.') }}
                  </li>
                </ul>
              </div>
            </div>
            <site-footer />
          </div>

          <div class="subpage" id="mybilling-list-page" v-else-if="selectedView === 'MyBillingList'">
            <div class="container">
              <div class="list-box">
                <ul>
                  <li
                    ref="cardSingle"
                    v-for="(i, index) in billings"
                    :key="index"
                    :class="{ active: i.is_primary === 1 }"
                  >
                    <div class="card-button" :key="index">
                      <img
                        v-if="i.is_primary === 1"
                        src="/img/card.png"
                        srcset="/img/card@2x.png 2x, /img/card@3x.png 3x"
                        class="card"
                        style="box-shadow: 1px 1px 1px 0 rgba(30, 79, 117, 0.34)"
                      />
                      <img
                        v-else
                        src="/img/card-disable.png"
                        srcset="/img/card-disable@2x.png 2x, /img/card-disable@3x.png 3x"
                        class="card"
                      />
                      <span class="text">{{ i.card_name }}&nbsp;{{ i.card_number.substr(15, 4) }}</span>
                      <img
                        @click="toggleKebabButton(i, index, $event)"
                        src="/img/kebab-menu.png"
                        srcset="/img/kebab-menu@2x.png 2x, /img/kebab-menu@3x.png 3x"
                        class="kebab"
                        :key="index"
                      />
                      <popup
                        :ref="'modifyBillingPopup' + index"
                        :items="popupMenu.items"
                        :coordinates="popupMenu.coordinates"
                        @clickItem="onClickPopupItem"
                        :blur="true"
                      />
                    </div>
                  </li>
                  <li>
                    <div class="add-card" @click="switchView('MyBillingReg')">
                      <span class="text">{{ $__t('Add Payment Card') }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="notice">
              <ul>
                <li class="body-title">{{ $__t('[Card Registration & Configuration Guide]') }}</li>
                <li class="dot no-margin">
                  {{
                    $__t(
                      'Basic fee will be charged when you make your reservation and additional fee will be paid later by the hours of usage.'
                    )
                  }}
                </li>
                <li class="dot">
                  {{ $__t('Adding card is to register your primary credit card or debit card.') }}
                </li>
                <li class="dot">
                  {{
                    $__t(
                      'Once you register your card, enjoying service will be much easier since automatic payment is possible.'
                    )
                  }}
                </li>
                <li class="dot">
                  {{ $__t('You can register a number of cards when if you have multiple purposes.') }}
                </li>
                <li class="dot">
                  {{
                    $__t(
                      'You can configure primary card & delete card by clicking the icon ( ፧ ) on the right side of the added card'
                    )
                  }}
                </li>
              </ul>
              <ul>
                <li class="body-title">
                  {{ $__t('If you have any questions, please contact the messenger at the bottom right.') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="subpage" id="mybilling-submit2-page" v-else-if="selectedView === 'MyBillingReg'">
            <div class="container">
              <card-form ref="cardFormMobile" @serialized="submitForm" />
              <div class="submit-section">
                <button type="button" @click="$refs.cardFormMobile.$refs.registerCardform.onSubmit()">
                  <span class="text">{{ $__t('Save') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      ref="registerCardModal"
      id="registerCardModal"
      :header-label="$__t('Register Payment Card')"
      :submit-button-label="$__t('Save')"
      :cancel-button-label="$__t('Cancel')"
      style="z-index: 1400"
      @submit="$refs.cardForm.$refs.registerCardform.onSubmit()"
    >
      <template v-slot:body>
        <card-form ref="cardForm" @serialized="submitForm" />
      </template>
    </modal>

    <modal
      ref="cardListModal"
      :header-label="$__t('Register Payment Card')"
      :submit-button-label="$__t('Save')"
      :cancel-button-label="$__t('Cancel')"
      @submit="closeModal('cardListModal')"
    >
      <template v-slot:body>
        <div class="list-box">
          <ul>
            <li ref="cardSingle" v-for="(i, index) in billings" :key="index" :class="{ active: i.is_primary === 1 }">
              <div class="card-button">
                <img
                  v-if="i.is_primary === 1"
                  src="/img/card.png"
                  srcset="/img/card@2x.png 2x, /img/card@3x.png 3x"
                  class="card"
                  style="box-shadow: 1px 1px 1px 0 rgba(30, 79, 117, 0.34)"
                />
                <img
                  v-else
                  src="/img/card-disable.png"
                  srcset="/img/card-disable@2x.png 2x, /img/card-disable@3x.png 3x"
                  class="card"
                />
                <span class="text">{{ i.card_name }}&nbsp;{{ i.card_number.substr(15, 4) }}</span>
                <img
                  @click.stop="toggleKebabButton(i, index, $event)"
                  src="/img/kebab-menu.png"
                  srcset="/img/kebab-menu@2x.png 2x, /img/kebab-menu@3x.png 3x"
                  class="kebab"
                />
                <popup
                  :ref="'modifyBillingPopup' + index"
                  :items="popupMenu.items"
                  :coordinates="popupMenu.coordinates"
                  @clickItem="onClickPopupItem"
                  :blur="true"
                />
              </div>
            </li>
            <li>
              <div class="add-card" @click="openModal('registerCardModal')">
                <span class="text">{{ $__t('Add Payment Card') }}</span>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </modal>

    <modal
      ref="deleteCardModal"
      id="deleteCardModal"
      :header-label="$__t('Delete Payment Card')"
      :submit-button-label="$__t('Delete')"
      :cancel-button-label="$__t('Cancel')"
      @submit="
        deleteBilling();
        closeModal('deleteCardModal');
      "
    >
      <template v-slot:body>
        <div class="center-textbox">
          <span class="text">
            {{ $__t('You cannot use LugStay service without registered payment card.') }}
            <br />
            {{ $__t('Do you want to delete the card?') }}
          </span>
        </div>
      </template>
    </modal>
    <modal
      ref="primaryCardModal"
      id="primaryCardModal"
      :header-label="$__t('Register Primary Card')"
      :submit-button-label="$__t('닫기')"
      @submit="closeModal('primaryCardModal')"
    >
      <template v-slot:body>
        <div class="center-textbox">
          <span class="text">{{ $__t('Please select a card as your primary payment method') }}</span>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import SiteFooter from '@/components/outline/Footer';

export default {
  name: 'MyBillingCard',
  components: {
    SiteFooter,
  },
  data() {
    return {
      loading: false,
      selectedPopupIndex: '',
      selectedView: '',
      billings: {},
      popupMenu: {
        coordinates: {
          clientX: '',
          clientY: '',
        },
        items: [this.$__t('Select as primary card'), this.$__t('Delete')],
      },
      selectedCard: {},
    };
  },

  async created() {
    await this.getBillingList();
    if (Object.keys(this.billings).length > 0 && window.innerWidth < 1121) {
      this.switchView('MyBillingList');
    } else {
      this.switchView('MyBillingCard');
    }

    window.addEventListener('popstate', () => this.switchView('MyBillingList'));
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', () => this.switchView('MyBillingList'));

    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    openModal(ref) {
      if (ref == 'registerCardModal') {
        this.$analytics.logTrack('track', 'AddPaymentInfo', {
          content_category: 'AddCardInfo',
          value: 1,
          currency: 'KRW',
        });
      }
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },
    async getBillingList() {
      try {
        let res = await this.$store.dispatch('billings/getCardList');
        this.$data.billings = res;

        if (this.$data.billings.length > 0) {
          await this.isPrimaryCardExists();
        } else {
          if (this.selectedView == 'MyBillingList') {
            this.switchView('MyBillingCard');
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    },
    toggleKebabButton(i, index, event) {
      this.selectedCard = i;
      this.selectedPopupIndex = index;
      let target = $(event.target);

      this.popupMenu.coordinates.clientX = target.position().left + target.width() / 2;
      this.popupMenu.coordinates.clientY = target.position().top + target.height() / 2;
      Object.keys(this.$refs).forEach((refName) => {
        if (refName.indexOf('modifyBillingPopup') !== -1) {
          if (this.$refs[refName].length) {
            this.$refs[refName][0].closePopup();
          }
        }
      });
      this.$refs['modifyBillingPopup' + this.selectedPopupIndex][0].openPopup();
    },
    deleteBilling() {
      this.$store
        .dispatch('billings/deleteCard', this.selectedCard)
        .then((res) => {
          if (res.data.status == 'error') {
            this.$store.commit('alert/ADD_ITEM', { message: this.$__t(res.data.error), status: 'error' });
            return;
          }
          this.getBillingList();
        })
        .catch((err) => {
          this.$store.commit('alert/ADD_ITEM', { message: err, status: 'error' });
        });
    },
    updatePrimaryBilling() {
      this.$store
        .dispatch('billings/updatePrimaryCard', this.selectedCard)
        .then((res) => {
          this.getBillingList();
        })
        .catch((err) => {
          this.$log.log(err);
        });
    },
    onClickPopupItem(param) {
      if (param === this.$__t('Select as primary card')) {
        this.updatePrimaryBilling();
        this.$refs['modifyBillingPopup' + this.selectedPopupIndex][0].closePopup();
      } else if (param === this.$__t('Delete')) {
        let billings_length = Object.keys(this.billings).length;

        if (billings_length === 1) {
          this.openModal('deleteCardModal');
          this.$refs['modifyBillingPopup' + this.selectedPopupIndex][0].closePopup();
          return;
        }

        this.deleteBilling();
        this.$refs['modifyBillingPopup' + this.selectedPopupIndex][0].closePopup();
      }
    },
    submitForm(values) {
      this.loading = true;

      let data = {
        billing_gateway: values.user_iso_code === 'KR' ? 'NICEPAY' : 'EXIMBAY',
        card_password: values.user_iso_code === 'KR' ? values.cardPass : '',
        expiration_year: values.expDate.substr(2, 2),
        expiration_month: values.expDate.substr(0, 2),
        id_number: values.user_iso_code === 'KR' ? values.birthDate : '',
        card_number: values.card,
        is_primary: '1',
        card_cvc: values.cvc,
      };

      this.$store
        .dispatch('billings/addCard', data)
        .then((res) => {
          this.$analytics.logTrack('track', 'AddPaymentInfo');
          if (res.data.data.status === 'AUTHORIZED') {
            this.getBillingList();
            this.$analytics.logEvent('add payment info');
            this.loading = false;
            if (this.selectedView === 'MyBillingReg') {
              this.switchView('MyBillingList');
              return;
            }
            this.closeModal('registerCardModal');
            this.switchView('MyBillingCard');
          } else {
            this.loading = false;
            this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t('Invalid card information'),
              status: 'error',
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit('alert/ADD_ITEM', {
            message: this.$__t('Invalid card information'),
            status: 'error',
          });
        });
    },
    isPrimaryCardExists() {
      let primaryBilling = this.$data.billings.find((o) => o.is_primary == 1);

      if (!primaryBilling) {
        setTimeout(() => {
          this.openModal('primaryCardModal');
        }, 500);
      }
    },
    isMobile(ref) {
      if (window.innerWidth <= 1120) {
        if (Object.keys(this.billings).length) {
          this.selectedView = 'MyBillingList';
          return;
        }
        this.$analytics.logTrack('track', 'AddPaymentInfo', {
          content_category: 'AddCardInfo',
          value: 1,
          currency: 'KRW',
        });
        this.selectedView = 'MyBillingReg';
      } else {
        if (Object.keys(this.billings).length) {
          this.openModal(ref);
          return;
        }
        this.openModal('registerCardModal');
      }
    },
    scrollToError() {
      setTimeout(() => {
        if ($('.ui-form-list-item .error').length >= 0) {
          let $error = $('.error');
          if ($error.length > 0) {
            $('html, body').scrollTop($($error[0]).offset().top);
          }
        }
      }, 0);
    },
    switchView(view) {
      if (view == 'MyBillingReg') {
        this.$analytics.logTrack('track', 'AddPaymentInfo', {
          content_category: 'AddCardInfo',
          value: 1,
          currency: 'KRW',
        });
        window.history.pushState({}, null, '');
      }

      this.selectedView = view;
    },
    backTo() {
      if (this.selectedView === 'MyBillingReg') {
        if (Object.keys(this.billings).length === 0) {
          this.switchView('MyBillingCard');
          return;
        }
        this.switchView('MyBillingList');
      } else if (this.selectedView === 'MyBillingList') {
        if (Object.keys(this.billings).length === 0) {
          this.switchView('MyBillingCard');
          return;
        }
        this.$router.push({ name: 'Home' });
      } else if (this.selectedView === 'MyBillingCard') {
        this.$router.push({ name: 'Home' });
      }
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header > .container > .title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    },
  },
};
</script>
