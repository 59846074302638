import Vue from 'vue';
import moment from 'moment';
import network from '@/modules/network';

const COLOR = {
  warn: 'yellow',
  info: 'blue',
  log: 'black',
  error: 'red',
};

const log = {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    GET_ITEMS: (state) => {
      let mappedItems = state.items.map((o) => {
        let { tag, message, type } = o;

        if (tag && message) {
          return `<span style="${COLOR.type || 'black'}">TAG : ${tag} / MESSAGE : ${message} / ${moment().format(
            'YYYY-MM-DD HH:mm:ss'
          )}</span><br/>`;
        }

        return o;
      });

      return mappedItems;
    },
  },
  mutations: {
    ADD_ITEM(state, payload) {
      state.items.push(payload);
    },
    REMOVE_ITEM(state, payload) {
      state.items = state.items.filter((o) => o != payload);
    },
  },

  actions: {
    create({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .post(rootState.config.apiURL + '/v2/log/free', payload)
          .then(resolve)
          .catch(reject);
      });
    },
  },
};

export default log;
