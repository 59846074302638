<template>
  <div class="ticket-message" :class="{ 'ticket-message--activated': activated }">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{ $__t('예약완료') | toUpperCase }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_issued) | moment('HH:mm') }}
        </div>
      </div>

      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body">
            <div class="message-body__contents">
              <h3 class="primary">
                <span class="text">{{ ticket.store_name }}</span>
                <button
                  type="button"
                  v-button
                  v-if="ticket.status === 'ISSUED'"
                  @click="cancelReservation"
                  class="button"
                >
                  {{ $__t('Cancel Booking') }}
                </button>
              </h3>
              <p class="secondary">
                <span>{{ lockerBoxInfoText }}</span
                ><br />
                <span>{{ reserveDateTimeText }}</span>
              </p>
            </div>
            <div class="message-body__actions">
              <button v-button type="button" @click="$emit('open-find')" class="action-button">
                {{ $__t('라커 찾기') | toUpperCase }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from '@/components/includes/QrCode';
import dates from '@/mixins/dates';
import { directive as Viewer } from 'v-viewer';

export default {
  name: 'MessageLockerIssued',

  props: {
    ticket: {
      type: Object,
      default: {},
    },

    order: {
      type: Object,
      default: {},
    },

    activated: {
      type: Boolean,
      default: false,
    },

    invitation: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [dates],

  data() {
    return {};
  },

  computed: {
    reserveDateTimeText() {
      const ticketExpiration = this.$moment(this.ticket?.ticket_date_start);

      return `${this.getDateLabel(ticketExpiration)} ${ticketExpiration.format('HH:mm')}까지 보관 필요`;
    },

    lockerBoxInfoText() {
      return `보관함 ${this.order?.product_data?.locker_number} • ${this.order?.product_data?.locker_size}`;
    },
  },

  methods: {
    cancelReservation() {
      return this.$router.push({ name: 'CancelTicket', params: { id: this.ticket.ticket_code } });
    },
  },

  components: {
    QrCode,
  },

  directives: {
    viewer: Viewer,
  },
};
</script>

<style lang="scss" scoped>
.message-body__contents {
  .primary {
    display: flex;
    justify-content: space-between;
    gap: unit(16);

    .text {
      flex: 1;
    }

    .button {
      flex: 0 0 auto;
      width: auto;
      padding: unit(3) unit(9);
      font-size: unit(12);
      align-self: flex-start;
      margin-top: unit(4);
      height: unit(20);
      line-height: 120%;
      color: $color-gray-5;
      border: solid 1px $color-gray-5;
      border-radius: unit(30);
    }
  }
}
</style>
