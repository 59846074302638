<template>
  <div class="page" id="host-landing-page">
    <div class="page-section page-section__trigger-section--top">
      <div class="section-container">
        <router-link v-button :to="{ name: 'Home' }">
          <div class="logo-area">
            <img src="/img/logo-white.svg" />
          </div>
        </router-link>
        <div class="title-area">
          <p>상점의 빈 공간을 공유해 추가 수익을 창출하세요.</p>
        </div>
        <div class="button-area">
          <!-- <ui-solid-button
            v-button
            color="brightblue"
            size="little-large"
            display="inline"
            @click="applyHost('top')"
          >
            <button type="button">{{ $__t("호스트 신청하기") }}</button>
          </ui-solid-button> -->
        </div>
      </div>
      <progressive-background src="/img/hostlanding-background-1.png">
      </progressive-background>
    </div>

    <div class="page-section page-section__intro-section">
      <div class="section-container">
        <div class="text-area">
          <p class="primary">
            물품을 보관할 수 있는
            <span class="underline-skewed"
              ><span class="text">별도의 공간</span></span
            >만 있다면 어느 곳이든 가능합니다.
          </p>
          <p class="secondary">
            <span class="underline-skewed"
              ><strong class="text">전국 3,000개</strong></span
            >
            이상의 상점과 함께하고 있어요.
          </p>
        </div>
      </div>
    </div>

    <div class="page-section page-section__advantage-section">
      <div class="section-container">
        <div class="title-area">
          <p>럭스테이 호스트라면 누구나 누릴 수 있어요!</p>
        </div>
        <div class="content-area">
          <ul>
            <li>
              <div class="advantage-card">
                <div class="card-image">
                  <progressive-img
                    :blur="30"
                    :ratio="1"
                    src="/img/host-advantage-revenue.png"
                    srcset="/img/host-advantage-revenue@2x.png 400w"
                  />
                </div>
                <div class="card-title">물품 보관 수익</div>
                <div class="card-description">
                  유휴 공간을 활용해 추가적인<br />
                  부수익을 얻을 수 있어요
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-card">
                <div class="card-image">
                  <progressive-img
                    :blur="30"
                    :ratio="1"
                    src="/img/host-advantage-promote.png"
                    srcset="/img/host-advantage-promote@2x.png 400w"
                  />
                </div>
                <div class="card-title">홍보효과</div>
                <div class="card-description">
                  매장 노출을 통해<br />
                  우리 매장을 알릴 수 있어요.
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-card">
                <div class="card-image">
                  <progressive-img
                    :blur="30"
                    :ratio="1"
                    src="/img/host-advantage-attract.png"
                    srcset="/img/host-advantage-attract@2x.png 400w"
                  />
                </div>
                <div class="card-title">고객 유치</div>
                <div class="card-description">
                  물품 보관 고객을 매장 이용<br />
                  고객으로 만들 기회를 가질 수 있어요.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="content-area"></div>
      </div>
    </div>

    <div class="page-section page-section__service-section">
      <div class="section-container">
        <div class="title-area">
          <p>
            럭스테이 앱에 표현되는<br />
            나의 매장을 확인해 보세요.
          </p>
        </div>
        <div class="content-area">
          <ui-tab-image :items="serviceItems" />
        </div>
      </div>
    </div>

    <div class="page-section page-section__review-section">
      <div class="section-container">
        <div class="title-area">
          <p>럭스테이 호스트 분들의 후기를 담았습니다.</p>
        </div>
        <div class="content-area">
          <ui-card-image :items="reviewItems" :pager="true" />
        </div>
      </div>
    </div>

    <div class="page-section page-section__procedure-section">
      <div class="section-container">
        <div class="title-area">
          <p>호스트 신청과정</p>
        </div>
        <div class="content-area">
          <ul>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"
                    ><span class="text">Step.01</span></span
                  >
                </div>
                <div class="card-title">신청서 작성</div>
                <div class="card-description">
                  <p>
                    호스트 신청서<br />
                    작성완료
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"
                    ><span class="text">Step.02</span></span
                  >
                </div>
                <div class="card-title">계약서 작성</div>
                <div class="card-description">
                  <p>
                    입력한 <strong>이메일</strong>로<br />
                    <strong>계약서 발송</strong>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"
                    ><span class="text">Step.03</span></span
                  >
                </div>
                <div class="card-title">사진 등록</div>
                <div class="card-description">
                  <p>
                    <strong>카카오 알림톡</strong>을 통해<br />
                    <strong>상점 사진</strong> 전송
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="procedure-card">
                <div class="card-step">
                  <span class="underline-skewed"
                    ><span class="text">Step.04</span></span
                  >
                </div>
                <div class="card-title">승인 완료</div>
                <div class="card-description">
                  <p>최종 등록 완료</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="page-section page-section__faq-section">
      <div class="section-container">
        <div class="title-area">
          <p>자주 묻는 질문</p>
        </div>
        <div class="content-area">
          <ul>
            <li class="faq-item">
              <ui-accordion header="럭스테이는 어떤 서비스인가요?">
                <p>
                  럭스테이는 상점 내 남는 공간을 활용해서 고객들의 물품을
                  보관해주고<br />
                  물품보관 수수료를 창출할 수 있는 유휴공간 활용
                  서비스입니다.<br />
                  별도로 락커를 설치하거나, 넓은 자체 공간을 확보할 필요가
                  없으며<br />
                  외부인이 접근이 어려운 보안이 보장되는 장소에 물품을
                  보관해주시면 됩니다.
                </p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion header="번화가가 아닌 상점에서도 가능한가요?">
                <p>
                  사업자 등록이 되어있는 상점이나 사무실이라면 어디든 럭스테이의
                  가맹점이 될 수 있습니다.
                </p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion header="비용책정과 정산은 어떻게 진행되나요?">
                <p>
                  사용한 시간만큼 금액이 계산되어 고객이 사전에 입력한
                  카드정보로 자동으로 결제됩니다.<br />
                  고객에게 직접 물품보관료 결제를 진행하실 필요가 없습니다.<br />
                  정산은 한달에 한 번 진행되며, 매달 정산을 진행하지 않아도
                  금액은 자동 누적됩니다.
                </p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion
                header="운영 시간에 변동이 있을 경우는 어떻게 하나요?"
              >
                <p>
                  럭스테이 어플을 통해 호스트가 직접 운영 시간과 일자를 변경할
                  수 있습니다.
                </p>
              </ui-accordion>
            </li>
            <li class="faq-item">
              <ui-accordion
                header="보관 물품의 분실이나 파손시 보상절차가 궁금합니다."
              >
                <p>
                  럭스테이 자체 가입 보험을 통해 최대 100만원까지 보상됩니다.
                </p>
              </ui-accordion>
            </li>
          </ul>
        </div>
      </div>

      <div class="page-section page-section__cs-section">
        <div class="section-container">
          <div class="cs-card">
            <div class="text-area">
              <p class="primary">더 궁금한 점이 있으신가요?</p>
              <p class="secondary">
                고객센터 운영시간<br />평일 10:00 ~ 17:30 / 토, 일, 공휴일
                휴무<br />점심시간 12:00 ~ 13:30
              </p>
            </div>
            <button class="call-area" v-button @click="onClickCall">
              <div class="wrapper">
                <div class="text-area">
                  <div class="key">고객센터</div>
                  <div class="value">02.1877.9727</div>
                </div>
                <div class="image-area">
                  <img src="/img/icon-cs-phone.png" />
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="page-section page-section__trigger-section--bottom">
        <div class="section-container">
          <div class="title-area">
            <p>
              지금 호스트 신청하고,<br />
              더 많은 고객을 만나보세요.
            </p>
          </div>
          <div class="button-area">
            <!-- <ui-solid-button
              v-button
              color="brightblue"
              size="little-large"
              display="inline"
              @click="applyHost('bottom')"
            >
              <button type="button">{{ $__t("호스트 신청하기") }}</button>
            </ui-solid-button> -->
          </div>
        </div>
        <progressive-background src="/img/hostlanding-background-2.png">
        </progressive-background>
      </div>

      <div class="page-section page-section__footer-section">
        <div class="section-container">
          <div v-button @click="onClickCall" class="cs-area">
            <div class="key">고객센터</div>
            <div class="value">02.1877.9727</div>
            <div class="extra">
              <p>
                고객센터 운영시간<br />평일 10:00 ~ 17:30 / 토, 일, 공휴일
                휴무<br />점심시간 12:00 ~ 13:30
              </p>
            </div>
          </div>
          <div class="copyright-area">
            <div class="misc">
              <ul>
                <li class="item">
                  {{ $__t("__회사명") }}
                </li>
                <li class="item">
                  {{ $__t("사업자 등록번호") }} :
                  {{ $__t("__사업자 등록번호") }}
                </li>
                <li class="item clear-after">
                  {{ $__t("대표") }} : {{ $__t("__대표") }}
                </li>
                <li class="item">{{ $__t("이메일") }} : support@lugstay.com</li>
                <li class="item clear-after">
                  {{ $__t("통신판매업 신고번호") }} :
                  {{ $__t("__통신판매업 신고번호") }}
                </li>
                <li class="item clear-after">
                  {{ $__t("주소") }} : {{ $__t("__본사") }}
                </li>
              </ul>
            </div>
            <div class="nav-bar">
              <ul>
                <li class="menu-item">
                  <router-link v-button :to="{ name: 'CsTermsOfUse' }"
                    ><span>{{
                      $__t("{terms-of-use-form}.terms_of_use.label")
                    }}</span></router-link
                  >
                </li>
                <li class="menu-item">
                  <router-link v-button :to="{ name: 'CsPrivacyPolicy' }"
                    ><span>{{ $__t("개인정보 처리방침") }}</span></router-link
                  >
                </li>
                <li class="menu-item">
                  <router-link v-button :to="{ name: 'CsGuide' }"
                    ><span>{{ $__t("서비스 가이드") }}</span></router-link
                  >
                </li>
                <li class="menu-item">
                  <router-link v-button :to="{ name: 'CsNotices' }"
                    ><span>{{ $__t("공지사항") }}</span></router-link
                  >
                </li>
              </ul>
            </div>
            <div class="copyright">
              <span>©2021 BLUEWHALE COMPANY INC. All Right Reserved.</span>
            </div>
          </div>
          <div class="link-area">
            <ul>
              <li @click="onClick('facebook')">
                <img src="/img/sns-icon-facebook.png" />
              </li>
              <li @click="onClick('instagram')">
                <img src="/img/sns-icon-instagram.png" />
              </li>
              <li @click="onClick('naver_blog')">
                <img src="/img/sns-icon-naver-blog.png" />
              </li>
              <li @click="onClick('youtube')">
                <img src="/img/sns-icon-youtube.png" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <guide-modal id="host-apply-modal" ref="host-apply-modal">
      <template v-slot:body>
        <host-apply-form @submit="closeRef('host-apply-modal')" />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import UiAccordion from "@/components/modules/Accordion/Body.vue";
import UiCardImage from "@/components/modules/CardImage/Body.vue";
import UiTabImage from "@/components/modules/TabImage/Body.vue";

import HostApplyForm from "@/components/includes/HostApplyForm.vue";
import loadScript from "load-script";

export default {
  name: "HostLanding",

  data() {
    return {
      scrollDepth: {
        30: false,
        50: false,
        70: false,
        90: false,
      },

      reviewItems: [
        {
          img: "/img/host-image-total-beauty.png",
          header:
            "매장의 여유 공간을 효율적으로 활용해서 물품 보관도 하고<br/> 수익도 얻을 수 있겠다는 생각이 들었습니다.",
          body: "유휴 공간을 활용해 나름 쏠쏠하게 부가수익을 올렸고, 물품 보관을 하러 오시는 고객에게 우리 살롱을 홍보하고 고객을 유치할 수도 있었어요. 이벤트를 위해 꽃다발과 케이크를 맡기신 손님이 계셨는데 이렇게도 럭스테이를 활용할 수 있구나 하고 배웠습니다.",
          label: "토탈뷰티 살롱 MDB네일",
        },
        {
          img: "/img/host-image-villa-de-murir.png",
          header:
            "추가적인 홍보 비용 없이<br/> 새로운 고객을 꾸준히 늘릴 수 있었습니다.",
          body: "물품을 보관하러 오셨다가 저희 빌라 드 뮤리의 고객이 되어주시기도 해요. 럭스테이를 통해 무료로 매장 홍보 효과를 보고 잠재적 수요도 확충할 수 있는 것이지요. 별도의 번거로운 수고 없이 럭스테이로 추가 수익 쌓는 헤택이 큰 장점이라고 할 수 있습니다.",
          label: "빌라드 뮤리",
        },
      ],

      serviceItems: [
        {
          img: "/img/host-service-map.png",
          tab: "지도",
          text: "상점의 실 주소를 바탕으로 럭스테이 지도 위에 상점의 위치가 노출됩니다. 매력적인 사진으로 고객의 관심을 사로잡을 수 있습니다.",
        },
        {
          img: "/img/host-service-store-single.png",
          tab: "상점상세",
          text: "상점 운영 시간 / 시설 현황 정보를 제공합니다. 냉동보관, 탈의실 등 고객에게 추가적으로 제공할 수 있는 서비스를 설정할 수 있습니다.",
        },
        {
          img: "/img/host-service-reservation.png",
          tab: "예약확인",
          text: "고객의 물품 보관 현황을 각 단계별로 손쉽게 확인할 수 있습니다.",
        },
      ],
    };
  },

  computed: {},

  created() {
    window.addEventListener("popstate", this.onPopState);
    window.addEventListener("scroll", this.trackScrollDepth);
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.onPopState);
  },

  mounted() {},

  methods: {
    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    async applyHost(location) {
      window.history.pushState({}, null, "");
      loadScript("https://embed.typeform.com/embed.js", (error, script) => {
        if (error) {
          this.$store.commit("alert/ADD_ITEM", {
            message: error,
            status: "error",
          });
          return;
        }

        const url = "https://form.typeform.com/to/AGtwPiVJ";
        const options = {
          mode: "popup",
          open: "time",
          openValue: 10,
          hideScrollbars: true,
          onReady: this.onTypeformReady,
          onClose: this.onCloseTypeform,
          onSubmit: this.onSubmitTypeform,
        };
        try {
          window.typeformEmbed.makePopup(url, options);
        } catch (e) {}
      });
    },

    onTypeformReady() {
      window.ChannelTalk.hide();
      this.$channelTalk.hide();
    },

    onCloseTypeform() {
      this.$channelTalk.show();
    },

    onSubmitTypeform() {
      this.$channelTalk.show();
    },

    onPopState() {
      window.location.reload();
    },

    onClickCall() {
      return (location.href = "tel:+82-218779727");
    },

    onClick(target) {
      switch (target) {
        case "facebook":
          if (this.wni.isNative) {
            this.wni.execute("wnPopupOpen", {
              url: "https://www.facebook.com/LugStay.official/",
            });
          } else {
            window.open("https://www.facebook.com/LugStay.official/");
          }
          break;
        case "instagram":
          if (this.wni.isNative) {
            this.wni.execute("wnPopupOpen", {
              url: "https://www.instagram.com/lugstay.official/",
            });
          } else {
            window.open("https://www.instagram.com/lugstay.official/");
          }
          break;
        case "naver_blog":
          if (this.wni.isNative) {
            this.wni.execute("wnPopupOpen", {
              url: "https://blog.naver.com/lugstay",
            });
          } else {
            window.open("https://blog.naver.com/lugstay");
          }
          break;
        case "youtube": {
          if (this.wni.isNative) {
            this.wni.execute("wnPopupOpen", {
              url: "https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured",
            });
          } else {
            window.open(
              "https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured"
            );
          }
          break;
        }
      }
    },
  },

  components: {
    UiAccordion,
    UiCardImage,
    UiTabImage,

    HostApplyForm,
  },
};
</script>
