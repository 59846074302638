<template>
  <div id="iez-guide">
    <div class="container">
      <div class="header">
        <div class="title">
          {{ $__t("어떻게 사용하나요?") }}
        </div>
      </div>

      <div class="body">
        <div class="section" v-for="item in items" :key="item.title">
          <div class="title">
            {{ item.title }}
          </div>
          <div
            class="content"
            v-for="object in item.content"
            :key="item.title + '-' + object.step"
          >
            <div v-if="object.description" class="text primary">
              {{ object.description }}
            </div>
            <div
              class="wrapper"
              v-for="(image, index) in object.image"
              :key="image.src"
            >
              <div class="image">
                <img :src="image.src" />
              </div>
              <div class="text-container">
                <div class="numbering">
                  <div>{{ index + 1 }}</div>
                </div>
                <div class="text secondary">
                  <span v-if="image.caption">
                    {{ image.caption }}
                  </span>
                  <div v-if="image.detail" v-html="image.detail" class="sub" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="submit-section">
          <ui-solid-button display="block" @click="$parent.onActiveTab(2)">
            <button type="button">{{ $__t("go keep my item") }}</button>
          </ui-solid-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const items = [
      {
        title: `STEP 1 ${this.$__t("Drop Off")}`,
        content: [
          {
            step: "Step 1",
            description: this.$__t(
              "Go to the store to drop off your item and show your QR ticket to the host and check-in"
            ),
            image: [
              {
                src: "/img/iez-guide-step1-1-1.png",
                caption: this.$__t(
                  "Search a store to keep your item and make a reservation."
                ),
              },
              {
                src: "/img/iez-guide-step1-1-2.png",
                caption: this.$__t(
                  "Go to the store you made a reservation and let host scan your QR ticket and proceed check-in."
                ),
              },
            ],
          },
          {
            step: "Step 2",
            description: this.$__t("Host will keep your item secured"),
            image: [
              {
                src: "/img/iez-guide-step1-2-1.png",
                caption: this.$__t("Host will take a photo of the item."),
              },
              {
                src: "/img/iez-guide-step1-2-2.png",
                caption: this.$__t("Your item is kept secured."),
              },
            ],
          },
        ],
      },
      {
        title: `STEP 2 ${this.$__t("Pick Up")}`,
        content: [
          {
            step: "Step 1",
            image: [
              {
                src: "/img/iez-guide-step2-1-1.png",
                caption: this.$__t(
                  "Go to the store you dropped off your item and show host the item image in QR ticket and pick up"
                ),
              },
              {
                src: "/img/iez-guide-step2-1-2.png",
                caption: this.$__t("Push the STOP button."),
                detail: `${this.$__t(
                  "LugStay service is charged real-time."
                )}<br />${this.$__t(
                  "Please push the STOP button to avoid being overcharged."
                )}`,
              },
            ],
          },
        ],
      },
    ];
    return {
      items,
    };
  },
};
</script>
