<template>
  <div class="ticket-message">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{ $__t("찾기완료") | toUpperCase }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_check_out) | moment("HH:mm") }}
        </div>
      </div>
      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body" style="flex-direction: column">
            <div class="message-section">
              <div class="message-section__container list-section">
                <h3 class="list-section__header">{{ $__t("보관 정보") }}</h3>
                <dl class="list">
                  <dt class="list__key">{{ $__t("보관 공간") }}</dt>
                  <dd class="list__value">
                    {{ ticket.store_name }}
                  </dd>
                </dl>
                <dl class="list">
                  <dt class="list__key">{{ $__t("보관 시간") }}</dt>
                  <dd class="list__value">
                    {{ reserveDateTimeText }}
                  </dd>
                </dl>
              </div>
              <div class="message-section__container list-section">
                <h3 class="list-section__header">{{ $__t("결제 정보") }}</h3>
                <dl class="list">
                  <dt class="list__key">{{ $__t("기본 요금") }}</dt>
                  <dd class="list__value">
                    {{ basicPrice.toLocaleString() }}{{ $__t("won") }}
                  </dd>
                </dl>
                <dl class="list">
                  <dt class="list__key">{{ $__t("추가 요금") }}</dt>
                  <dd class="list__value">
                    + {{ additionalPrice.toLocaleString() }}{{ $__t("won") }}
                  </dd>
                </dl>
                <dl class="list">
                  <dt class="list__key">{{ $__t("할인 요금") }}</dt>
                  <dd class="list__value">- {{ discountAmount }}{{ $__t("won") }}</dd>
                </dl>
              </div>
              <div class="message-section__container list-section">
                <dl class="list list--primary">
                  <dt class="list__key">{{ $__t("최종 결제 금액") }}</dt>
                  <dd class="list__value">
                    {{ order.payment_total.toLocaleString() }}{{ $__t("won") }}
                  </dd>
                </dl>
                <dl class="list">
                  <dt class="list__key">{{ $__t("결제 방법") }}</dt>
                  <dd class="list__value" v-if="billing.card_number">
                    {{ `${billing.card_name} ${billing.card_number.substr(0, 4)}` }}
                  </dd>
                </dl>
                <dl class="list">
                  <dt class="list__key">{{ $__t("결제 일시") }}</dt>
                  <dd class="list__value">
                    {{ order.paid_at | moment("YYYY-MM-DD HH:mm:ss") }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from "@/components/includes/QrCode";

export default {
  name: "MessageCheckOut",

  props: {
    ticket: {
      type: Object,
      default: {},
    },

    order: {
      type: Object,
      default: {},
    },

    activated: {
      type: Boolean,
      default: false,
    },

    invitation: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      billing: {},
    };
  },

  async mounted() {
    this.billing = await this.getBilling();
  },

  computed: {
    duration() {
      return this.$moment.duration(
        Math.abs(
          this.$moment(this.ticket.datetime_check_out).diff(
            this.$moment(this.ticket.datetime_check_in)
          )
        )
      );
    },

    discountAmount() {
      return this.order?.payment?.discount || 0;
    },

    predictedAmount() {
      return (this.order?.predicted_amount || 0).toLocaleString();
    },

    discountAmount() {
      return (this.order?.payment?.discount || 0).toLocaleString();
    },

    originAmount() {
      return (
        this.order?.payment?.origin?.toLocaleString() ||
        (this.basicPrice + this.additionalPrice).toLocaleString() ||
        0
      );
    },

    basicPrice() {
      let price = 0;

      switch (this.order?.product_data?.locker_size) {
        case "S":
          price = 1000;
          break;
        case "M":
          price = 1500;
          break;
        case "L":
          price = 2000;
          break;
        case "XL":
          price = 3000;
          break;
      }

      return price;
    },

    additionalPrice() {
      if (this.duration) {
        let durationMinutes = this.duration.asMinutes();

        const unitPrice =
          this.order?.product_data?.locker_size === "XL"
            ? 500
            : this.order?.product_data?.locker_size === "L"
            ? 400
            : this.order?.product_data?.locker_size === "M"
            ? 300
            : this.order?.product_data?.locker_size === "S"
            ? 200
            : 0;

        const afterHalfDayPrice =
          this.order?.product_data?.locker_size === "XL"
            ? 2500
            : this.order?.product_data?.locker_size === "L"
            ? 2000
            : this.order?.product_data?.locker_size === "M"
            ? 1500
            : this.order?.product_data?.locker_size === "S"
            ? 1000
            : 0;

        let price = 0;

        if (durationMinutes <= 120) {
          price = 0;
        } else if (durationMinutes > 120 && durationMinutes <= 720) {
          price = Math.ceil((durationMinutes - 120) / 60) * unitPrice;
        } else {
          price =
            10 * unitPrice + Math.ceil((durationMinutes - 720) / 720) * afterHalfDayPrice;
        }

        return price;
      }
    },

    discountAmount() {
      return this.order?.payment_discount;
    },

    quantitySmallText() {
      let text = "";

      text +=
        parseInt(this.ticket?.ticket_quantity || 0) > 0
          ? `${this.$__t("{luggage-selector}.small")} ${parseInt(
              this.ticket?.ticket_quantity || 0
            )}${
              parseInt(this.ticket?.ticket_quantity || 0) > 1
                ? this.$__t("items")
                : this.$__t("item")
            } X (100/${this.$__t("per 10 minutes")} X ${this.toTenMinutes(
              this.duration
            )}${this.$__t("m")})`
          : "";

      return text;
    },

    quantityBigText() {
      let text = "";

      text +=
        parseInt(this.ticket?.ticket_over_quantity || 0) > 0
          ? `${this.$__t("{luggage-selector}.big")} ${parseInt(
              this.ticket?.ticket_over_quantity || 0
            )}${
              parseInt(this.ticket?.ticket_over_quantity || 0) > 1
                ? this.$__t("items")
                : this.$__t("item")
            } X (150/${this.$__t("per 10 minutes")} X ${this.toTenMinutes(
              this.duration
            )}${this.$__t("m")})`
          : "";

      return text;
    },

    reserveDateTimeText() {
      const momentDateStart = this.$moment(this.ticket.ticket_date_start);
      const momentDateEnd = this.$moment(this.ticket.ticket_date_end);
      const momentNow = this.$moment();

      let text = "";

      if (momentDateStart.format("YYYY-MM-DD") === momentDateEnd.format("YYYY-MM-DD")) {
        if (momentNow.format("YYYY-MM-DD") === momentDateStart.format("YYYY-MM-DD")) {
          text = `${this.$__t("{date-time-picker}.today")} ${momentDateStart.format(
            "HH:mm"
          )} - ${momentDateEnd.format("HH:mm")}`;
        } else {
          text = `${momentDateStart.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateStart.format("ddd").toUpperCase()
          )}) ${momentDateStart.format("HH:mm")} - ${momentDateEnd.format("HH:mm")}`;
        }
      } else {
        if (momentNow.format("YYYY-MM-DD") === momentDateStart.format("YYYY-MM-DD")) {
          text = `${this.$__t("{date-time-picker}.today")} ${momentDateStart.format(
            "HH:mm"
          )} - ${momentDateEnd.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateEnd.format("ddd").toUpperCase()
          )}) ${momentDateEnd.format("HH:mm")}`;
        } else {
          text = `${momentDateStart.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateStart.format("ddd").toUpperCase()
          )}) ${momentDateStart.format("HH:mm")} - ${momentDateEnd.format(
            "MM/DD"
          )}(${this.$__t(
            "SSA_" + momentDateEnd.format("ddd").toUpperCase()
          )}) ${momentDateEnd.format("HH:mm")}`;
        }
      }

      return text;
    },
  },

  methods: {
    toTenMinutes(duration) {
      return Math.ceil(this.$moment.duration(duration).asMinutes() / 10) * 10 - 60 > 0
        ? Math.ceil(this.$moment.duration(duration).asMinutes() / 10) * 10 - 60
        : 0;
    },

    async getBilling() {
      const response = await this.axios.get(
        this.$store.state.config.apiURL +
          "/v2/payment/billing/" +
          this.order?.billing?.billing_id
      );

      return response.data?.data;
    },
  },

  components: {
    QrCode,
  },
};
</script>

<style scoped lang="scss">
.message-section {
  padding: unit(20) unit(16);
}
.list-section {
  padding-bottom: unit(20);

  & + .list-section {
    padding-top: unit(20);
    border-top: solid 1px #e1e4e6;
  }

  &__header {
    font-size: unit(14);
    line-height: 1.43;
    letter-spacing: -0.006em;
  }

  .list {
    margin: unit(16) 0;
    display: flex;
    align-items: top;
    gap: unit(4);

    &:last-of-type {
      margin-bottom: 0;
    }

    * {
      font-size: unit(14);
      line-height: 1.43;
      letter-spacing: -0.006em;
    }

    &__key {
      color: #878d91;
    }

    &__value {
      flex: 1;
      text-align: right;
      color: $color-black;
    }

    &--primary {
      margin: 0;

      .list__key {
        color: #008fff;
        line-height: unit(24);
      }

      .list__value {
        color: #008fff;
        font-size: unit(16);
        line-height: unit(24);
        font-weight: bold;
      }
    }
  }
}
</style>
