<template>
    <div id="host-guide">
        <div class="container">
            <div class="carousel">
                <carousel slideType="slide" :arrow="true" :pager="true" :zoomable="true">                                    
                        <template v-for="src in this.images">
                            <li class="ui-carousel-item" :key="src">
                                <viewer :options="{toolbar: false, title: false}">   
                                    <img :src="src">
                                    <div class="zoomable"/> 
                                </viewer>
                            </li> 
                        </template>      
                </carousel>                
            </div>                      
        </div>
    </div>
</template>

<script>
import Carousel from '@/components/modules/Carousel/Body';    
import CarouselItem from '@/components/modules/Carousel/Item';

export default {
    data(){
        return{
            images:[
                "/img/hostGuide/img2.jpeg",
                "/img/hostGuide/img1.jpeg"
                ]        
        }
    },
    name: 'HostGuide',
    components:{
        Carousel,  
        CarouselItem 
    },
}
</script>