<template>
  <div class="subpage" id="mypage-coupons">
    <div class="container">
      <div class="tabs">
        <div class="tab active" id="tab-available" @click="clickTab('available')">{{$__t("available coupon")}}</div>
        <div class="tab" id="tab-completed" @click="clickTab('completed')">{{$__t("used coupon")}}</div>
      </div>
      <div class="total">
        <span class="total-text">{{ $__t('전체') }} <span class="total-num">{{total}}</span></span>
      </div>
      <div class="coupon-list" v-if="docs && docs.length > 0">
        <div class="coupon-list-item" v-for="doc in docs" :class="{disabled: doc.used || doc.expired}" @click="selectCoupon(doc)">
          <div class="coupon-left">
            <div class="coupon-image">
              <div class="coupon-image-leftside" :class="{fix: doc.promotion_config.type == 'fix'}"></div>
              <div class="coupon-image-body" :class="{fix: doc.promotion_config.type == 'fix'}">
                <template v-if="doc.promotion_config.type == 'fix'">
                  <div class="coupon-amount">{{doc.promotion_config.fix_amount.toLocaleString()}}{{$__t('won')}}</div>
                </template>
                <template v-else>
                  <div class="coupon-rate-amount">{{doc.promotion_config.rate_amount}}%</div>
                </template>
              </div>
            </div>
          </div>
          <div class="coupon-contents">
            <div class="coupon-title">{{doc.coupon_name}}</div>
            <div class="exp-date">{{!doc.coupon_date_due_no_expiration ? $__t("Expiration Date")+" "+$moment(doc.coupon_date_due).format('YYYY.MM.DD') : $__t("No expiration date")}}<div class="status">{{doc.used ? $__t("used") : doc.expired ? $__t('EXPIRED') : ''}}</div></div>
          </div>
          <div class="arrow">
            <i class="fa fa-angle-right"></i>
          </div>
        </div>
        <ui-text-button display="block" color="primary" class="list-more" @click="getMoreListItems" v-if="listLoadable">
          <button type="button">{{ $__t( "더 불러오기" ) }}</button>
        </ui-text-button>
      </div>
      <div class="no-coupon" v-else>
        <div>
          <img src="/img/icon-coupon-gray.png">
          <span v-if="listQuery.group == 'available'" >{{$__t("There are no coupons")}}</span>
          <span v-else>{{ $__t('There are no coupons used') }}</span>
        </div>
      </div>
      <div class="coupon-modal" v-if="isShowModal">
        <div class="coupon-modal-body">
          <div class="modal-close-button"><i class="fa fa-times" @click="closeModal"></i></div>
          <div class="modal-image-section">
            <div class="coupon-left">
              <div class="coupon-image">
                <div class="coupon-image-leftside" :class="{fix: selectedCoupon.promotion_config.type == 'fix'}"></div>
                <div class="coupon-image-body" :class="{fix: selectedCoupon.promotion_config.type == 'fix'}">
                  <template v-if="selectedCoupon.promotion_config.type == 'fix'">
                    <div class="coupon-amount">{{selectedCoupon.promotion_config.fix_amount.toLocaleString()}}{{$__t('won')}}</div>
                  </template>
                  <template v-else>
                    <div class="coupon-rate-amount">{{selectedCoupon.promotion_config.rate_amount}}%</div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-title-section">{{selectedCoupon.coupon_name}}</div>
          <div class="modal-exp-date">{{!selectedCoupon.coupon_date_due_no_expiration ? $__t("Expiration Date")+" "+$moment(selectedCoupon.coupon_date_due).format('YYYY.MM.DD') : $__t("No expiration date")}}</div>
          <div class="modal-description" v-html="selectedCoupon.coupon_descript"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CouponListItem from "@/components/resources/coupon/ForGuest";
import Clipboard from "clipboard";
export default {
  components: {
    // CouponListItem
  },
  data(){
    return {
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 12,
        group: 'available'
      },
      total: 0,
      selectedCoupon: null,
      isShowModal: false,
    };
  },
  computed: {
    docs() {
      return this.$store.getters[ "resourceCoupons/GET_ITEMS_BY_LIST" ]({ list: "MyCoupons" });
    },
    lang() {
      return this.$store.state.lang;
    }
  },
  watch: {
    'listQuery.group'(v) {
      this.getListItems(true);
    },
    $route(to, form) {
      this.getListItems(true);
    }
  },
  created(){
    this.lang
    this.getListItems(true);
    var clipboard = new Clipboard( ".copy-coupon-code" );
    clipboard.on( "success", e => {
      alert( this.$__t( "쿠폰 코드가 복사되었습니다." ) );
    });
  },
  methods: {
    getListItems(clear){
      this.$store.dispatch( "resourceCoupons/getList", { list: "MyCoupons", query: this.listQuery, clear: clear }).then( res => {
        this.total = res.data.data.total;
        if( this.docs.length == res.data.data.total || res.data.data.items.length == 0 ){
          this.listLoadable = false;
        } else {
          this.listLoadable = true;
        };
      })
    },
    getMoreListItems(){
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    },
    clickTab(group) {
      this.listQuery.group = group;
      $('.tab').removeClass('active');
      $('#tab-'+group).addClass('active');
    },
    selectCoupon(coupon) {
      this.selectedCoupon = coupon;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    }
  }
}
</script>
