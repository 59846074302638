const Hotjar = {
  init(app, options = {}, callback = function () { }) {
    this._hjid = options.hjid;
    this._hjsv = options.hjsv;

    if (this._hjid && this._hjsv) {
      var h = window;
      var o = document;
      var t = "https://static.hotjar.com/c/hotjar-"
      var j = '.js?sv='
      var a = null;
      var r = null;
      var _arguments = [h, o, t, j, a, r];

      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(_arguments)};
      h._hjSettings={hjid:this._hjid,hjsv:this._hjsv};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    }
  },
};

export default Hotjar;
