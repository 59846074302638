<template>
  <div class="page" id="store-delivery">
    <div class="page-body">
      <router-view :store="store"/>
    </div>
  </div>
</template>

<script>
import DeliveryFormStep0 from "@/components/includes/DeliveryFormStep0";
import DeliveryFormStep1 from "@/components/includes/DeliveryFormStep1";
import DeliveryFormStep2 from "@/components/includes/DeliveryFormStep2";
import DeliveryFormStep3 from "@/components/includes/DeliveryFormStep3";

export default {
  components: {
    DeliveryFormStep0,
    DeliveryFormStep1,
    DeliveryFormStep2,
    DeliveryFormStep3
  },

  data() {
    return {      
      store: null,
      store_id: this.$route.params.store_id
    };
  },

  computed:{
    doc(){
      return this.$store.getters["stores/GET_ITEM"]({key: "id", value: this.$data.store_id})
    }
  },

  async created() {
    await this.getStore()
  },

  methods: {
    async getStore(){
      let store = this.doc;

      if(store == null){
        store = await this.$store.dispatch('stores/get', {id: this.$data.store_id })
        let { data } = store.data;
        this.$data.store = data;
      }else{
        this.store = store
      }
    }

  },
};
</script>
