<template>
  <div class="ui-profile-photo-uploader" :style="'background-image:url(' + imagePreview + ')'">
    <div class="body" ref="image" @click="nativeUpload">
      <input ref="input" type="file" :readonly="wni.isNative" />
    </div>
  </div>
</template>

<script>
import loadImage from 'blueimp-load-image';
import toBlob from 'blueimp-canvas-to-blob';

export default {
  props: ['value'],
  data() {
    return {
      imageSource: ''
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    imagePreview() {
      let source = '';

      if (this.imageSource) source = this.imageSource;
      else if (this.value && this.value.resource_url) source = this.value.resource_url;
      else source = '/img/default-profile.png';

      return source;
    }
  },
  mounted() {
    this.$refs.input.addEventListener('change', (e) => {
      let loadingImage = e.target.files[0];

      const reader = new FileReader();
      let image = this.$refs.image;

      reader.addEventListener(
        'load',
        (e) => {
          this.$data.imageSource = e.target.result;
          image.src = e.target.result;
        },
        false
      );

      reader.readAsDataURL(loadingImage);

      loadImage(
        loadingImage,
        (img, data) => {
          img.toBlob((blob) => {
            let imagefile = null;
            try {
              imagefile = new File([blob], loadingImage.name, { type: loadingImage.type });
            } catch (e) {
              if (typeof Object.assign != 'function') {
                Object.assign = function (target) {
                  'use strict';
                  if (target == null) {
                    throw new TypeError('Cannot convert undefined or null to object');
                  }

                  target = Object(target);
                  for (var index = 1; index < arguments.length; index++) {
                    var source = arguments[index];
                    if (source != null) {
                      for (var key in source) {
                        if (Object.prototype.hasOwnProperty.call(source, key)) {
                          target[key] = source[key];
                        }
                      }
                    }
                  }
                  return target;
                };
              }
              imagefile = Object.assign(blob, loadingImage.name, { type: loadingImage.type });
            } finally {
              let formData = new FormData();
              formData.append('key', this.$store.state.auth.resource_key);
              formData.append('tag', 'user-profile');
              formData.append('file', imagefile);

              if (this.tag === 'thumbnail_image') {
                formData.append('tWidth', 128);
                formData.append('tHeight', 128);
              } else {
                formData.append('tWidth', '');
                formData.append('tHeight', '');
              }
              this.axios
                .post(this.$store.state.config.apiURL + '/v2/resources/upload', formData, {
                  headers: this.$store.state.config.userHeaders
                })
                .then((res) => {
                  if (res.data.status == 'success') {
                    this.$emit('input', res.data.data.resources[0]);
                    this.$emit('uploaded', res.data.data.resources[0]);
                  } else {
                    this.$store.commit('alert/ADD_ITEM', { message: res, status: 'success' });
                  }
                })
                .catch((err) => {
                  this.$store.commit('alert/ADD_ITEM', { message: err, status: 'error' });
                });
            }
          });
        },
        {
          orientation: true,
          canvas: true
        }
      );
    });
  },
  methods: {
    nativeUpload() {
      if (this.wni.isNative) {
        this.wni.execute('wnCameraUpload', {
          type: 'library',
          path: `${this.$store.state.config.mode}/user-profile`,
          bucket: 'lugstay',
          callback: this.wni.cb(({ size, url, error }) => {
            if (error) {
              // @TODO: Permission
              // this.wni.execute('wnOpenAppSetting', { type: 'general' });

              if (error.indexOf('fail to') < 0) {
                this.$store.commit('alert/ADD_ITEM', { message: error, status: 'error' });
              }
              return;
            }

            let data = {
              key: this.$store.state.auth.resource_key,
              tag: this.tag,
              resource_type: 'image',
              resource_url: url
            };

            this.$emit('input', data);
          })
        });
      }
    }
  }
};
</script>
