<template>
  <div id="user-guide">
    <div class="container">
      <!-- <div class="header">
        {{ $__t("어떻게 사용하나요?") }}
      </div>
      <div class="title">
        <div class="text">
          {{ doc.title }}
        </div>
      </div>
      <div class="section" v-for="item in doc.content" :key="item.step">
        <div class="textarea">
          <div class="step">{{ item.step }}</div>
          <div class="description">
            {{ item.description }}
            <div class="detail" v-if="item.detail" v-html="item.detail" />
          </div>
        </div>
        <div class="image" v-for="(obj, i) in item.image" :key="obj.src">
          <img :src="obj.src" :alt="`이용방법-${item.step}-${i}`" />
          <div v-if="obj.caption" class="caption">
            {{ obj.caption }}
          </div>
        </div>
      </div> -->
      <div class="delay-message">
        <div class="title">📌 카카오톡 채널 고객센터 운영중단</div>
        카카오 데이터 센터 화재로 인해 카카오톡 채널을 이용한 고객센터 운영이 잠시 중단됩니다😭 <br />
        문의하실 내용은 채널톡 '럭스테이' 를 이용해 남겨주시면 빠른 처리 도와드릴게요!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['index', 'carousel', 'page'],
  data() {
    let data = [
      {
        title: `1. ${this.$__t('Drop Off')}`,
        content: [
          {
            step: 'Step 1',
            description: this.$__t('Go to the store to drop off your item and show your QR ticket to the host and check-in'),
            image: [
              {
                src: '/img/checkin-step1-1.png',
                caption: this.$__t('Search a store to keep your item and make a reservation.')
              },
              {
                src: '/img/checkin-step1-2.png',
                caption: this.$__t('Go to the store you made a reservation and let host scan your QR ticket and proceed check-in.')
              }
            ]
          },
          {
            step: 'Step 2',
            description: this.$__t('Host will keep your item secured'),
            image: [
              {
                src: '/img/checkin-step2-1.png',
                caption: this.$__t('Host will take a photo of the item.')
              },
              {
                src: '/img/checkin-step2-2.png',
                caption: this.$__t('Your item is kept secured.')
              }
            ]
          }
        ]
      },
      {
        title: `2. ${this.$__t('Pick Up')}`,
        content: [
          {
            step: 'Step 1',
            description: this.$__t('Go to the store you dropped off your item and show host the item image in QR ticket and pick up'),
            image: [{ src: '/img/checkout-step1-1.png' }]
          },
          {
            step: 'Step 2',
            description: this.$__t('Push the STOP button.'),
            detail: `${this.$__t('LugStay service is charged real-time.')}<br />${this.$__t(
              'Please push the STOP button to avoid being overcharged.'
            )}`,
            image: [{ src: '/img/checkout-step2-1.png' }]
          }
        ]
      }
    ];
    return {
      data
    };
  },
  computed: {
    doc() {
      return this.carousel ? this.data[this.currentIndex] : this.data[this.index];
    },
    currentIndex() {
      return this.$store.getters['carousel/GET_ITEM']({ key: 'currentPage' }) - 1 || Number(0);
    }
  },
  created() {
    this.$store.commit('carousel/SET_ITEM', {
      key: 'maxPage',
      value: this.data.length
    });
    this.$store.commit('carousel/SET_ITEM', {
      key: 'currentPage',
      value: this.page || 1
    });
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
</style>
