<template>
  <div class="subpage" id="host-store-create">
    <div class="container">

      <store-form mode="create" />

    </div>
  </div>
</template>

<script>
import StoreForm from "@/components/includes/StoreForm";
export default {
  components: {
    StoreForm
  },
}
</script>
