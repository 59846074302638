var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-to-use book"},[_c('section',{staticClass:"how-to-use__section"},[_vm._m(0),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$__t(
          'Press the QR code scan button at home or select the reservation details from Usage details below.'
        )
      )}})]),_c('section',{staticClass:"how-to-use__section"},[_vm._m(1),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$__t(
          'When you leave your items and retrieve them, please take a picture of the QR code in the store.'
        )
      )}})]),_c('section',{staticClass:"how-to-use__section"},[_vm._m(2),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.$__t("맡길 물품 사진을 찍어주세요.")))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-frame"},[_c('img',{staticClass:"image",attrs:{"src":"/img/guide-ticket-1.png","alt":"Guide Booking"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-frame"},[_c('img',{staticClass:"image",attrs:{"src":"/img/guide-ticket-2.png","alt":"Guide Booking"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-frame"},[_c('img',{staticClass:"image",attrs:{"src":"/img/guide-ticket-3.png","alt":"Guide Booking"}})])
}]

export { render, staticRenderFns }