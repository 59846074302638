<template>
    <div id="promotion-contents">
        <div class="container">
            <div class="promotion-tabs">
                <ul>
                    <li class="active" id="tab1" @click="tabIndex=1">{{$__t("Obtain your exclusive link")}}</li>
                    <li id="tab2" @click="tabIndex=2">{{$__t("Statistics")}}</li>
                </ul>
            </div>
            <div id="promotion-obtain-link">
                <div class="title">{{$__t("Share your exclusive affiliate link!")}}</div>
                <div class="description">{{$__t("You get USD 100 for every USD 1000 order you introduced")}}</div>
                <div class="input-label">
                    {{$__t("Share your exclusive link and get $$:")}}
                </div>
                <div class="input-area">
                    <input type="text" id="promotionLink" :value="`${$store.state.config.siteURL}/campaign/codes/${campaign_code}`" readonly>
                    <div @click="copyToClipboard('promotionLink')" class="pointer">{{$__t("Copy Link")}}</div>
                </div>
                <div class="input-label">
                    {{$__t("Or put the link on your website:")}}
                </div>
                <div class="input-area">
                    <input type="text" id="promotionHtml" :value="`<a rel='nofollow' href='${$store.state.config.siteURL}/campaign/codes/${campaign_code}'>Go to Lugstay</a>`" readonly>
                    <div @click="copyToClipboard('promotionHtml')" class="pointer">{{$__t("Copy HTML")}}</div>
                </div>
                <div class="bg-luggage">
                    <ui-solid-button @click="goToStores" class="pointer"><span>Book   ></span></ui-solid-button>
                    <div class="lugstay-box">
                        <div class="lugstay">LugStay</div>
                        <div class="lugstay-sub">Luggage Storage</div>
                    </div>
                </div>
            </div>
            <div id="promotion-statistics" class="hidden">
                <div class="white-view-box">
                    <div class="title">{{$__t("Total income in the current period")}}</div>
                    <div class="contents"><span class="currency">USD</span><span class="value">{{currentIncome}}</span></div>
                    <ui-solid-button display="block"><span>{{$__t("Request payment")}}</span></ui-solid-button>
                    <div class="description">{{$__t("Total amount less than minimum threshold")}}.</div>
                    <div class="link">{{$__t("Payment Method")}}  ></div>
                </div>
                <div class="white-view-box">
                    <div class="title">{{$__t("Orders introduced")}}</div>
                    <div class="contents"><span class="value">{{ordersCount}}</span></div>
                </div>
                <div class="white-view-box">
                    <div class="title">{{$__t("Accumulated payout")}}</div>
                    <div class="contents"><span class="currency">USD</span><span class="value">{{accPayout}}</span></div>
                </div>
                <div class="white-view-box">
                    <div class="title">{{$__t("Click through")}}</div>
                    <div class="contents"><span class="value">{{clickCount}}</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        components: {
        },
        // props: [ "code" ],
        data(){
            return {
                tabIndex: 1,
                campaign_code: "",
                currentIncome: 0,
                ordersCount: 0,
                accPayout: 0,
                clickCount: 0,
            };
        },
        mounted(){
            this.axios.get(this.$store.state.config.apiURL+'/v2/campaigns/invite', {headers: this.$store.state.config.userHeaders}).then(({data})=> {
                this.campaign_code = data.data.campaign_code;
            }).catch(error => {
                this.$store.commit("alert/ADD_ITEM", { message: error, status: "error" });
            })

            this.axios.get(this.$store.state.config.apiURL+'/v2/campaigns/invite/info', {headers: this.$store.state.config.userHeaders}).then(({data})=> {
                this.clickCount = data.data.invited;
                this.ordersCount = data.data.paid;
            }).catch(error => {
                this.$store.commit("alert/ADD_ITEM", { message: error, status: "error" });
            })
        },
        computed: {
        },
        watch: {
          tabIndex(value, old) {
              if(value && value != old) {
                  this.clickTab(value);
              }
          }
        },
        methods: {
            clickTab(idx) {
                if(idx == 1) {
                    $('#promotion-statistics').addClass('hidden');
                    $('#promotion-obtain-link').removeClass('hidden');
                    $('#tab1').addClass('active');
                    $('#tab2').removeClass('active');

                } else if(idx == 2) {
                    $('#promotion-statistics').removeClass('hidden');
                    $('#promotion-obtain-link').addClass('hidden');
                    $('#tab1').removeClass('active');
                    $('#tab2').addClass('active');
                }
            },
            copyToClipboard(id) {
                var copyText = document.getElementById(id);

                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/

                document.execCommand("copy");

                alert(this.$__t("Copied to clipboard"));
            },
            goToStores() {
                this.$router.push({name: 'Stores'})
            }
        }
    }
</script>
