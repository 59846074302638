var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-ticket for-host",class:{
    ISSUED: _vm.ticket.status == 'ISSUED',
    CHECK_IN: _vm.ticket.status == 'CHECK_IN',
    CHECK_OUT: _vm.ticket.status == 'CHECK_OUT',
    CANCELLED: _vm.ticket.status == 'CANCELLED',
    EXPIRED: _vm.isExpired,
  }},[_c('router-link',{attrs:{"to":{ name: 'HostTicketSingle', params: { code: _vm.ticket.ticket_code } }}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"store"},[(
            _vm.ticket.resources &&
            _vm.ticket.resources.luggage_photo &&
            _vm.ticket.resources.luggage_photo.resource_url
          )?_c('img',{attrs:{"src":_vm.ticket.resources.luggage_photo.resource_url}}):_c('img',{attrs:{"src":"/img/no-image.png"}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.ticket.user_name || _vm.ticket.reserve.user_first_name + " " + _vm.ticket.reserve.user_last_name)),_c('br'),_c('span',[_c('i',{staticClass:"fa fa-envelope"}),_vm._v(" "+_vm._s(_vm.ticket.reserve.user_email))]),_c('br'),_c('span',[_c('i',{staticClass:"fa fa-phone-alt"}),_vm._v(" "+_vm._s(_vm.ticket.reserve.user_phone))])]),_c('div',{staticClass:"arrow"},[_c('i',{staticClass:"fa fa-angle-right"})])]),_c('div',{staticClass:"reserve"},[_c('div',{staticClass:"dateInfo"},[_vm._v(" "+_vm._s(this.$moment(this.ticket.ticket_date_start).format(_vm.$__t("M. D. HH:mm")))+" ~ "+_vm._s(this.$moment(this.ticket.ticket_date_end).format(_vm.$__t("M. D. HH:mm")))+" / "+_vm._s(this.ticket.ticket_quantity + this.ticket.ticket_over_quantity)+" "+_vm._s(_vm.$__t("개"))+" ")]),_c('div',{staticClass:"statusInfo"},[_c('div',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.isExpired ? _vm.$__t("EXPIRED") : _vm.$__t(`${_vm.ticket.status}_H`))+" ")]),(
              !_vm.isExpired && (_vm.ticket.status == 'ISSUED' || _vm.ticket.status == 'CHECK_IN')
            )?_c('div',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.checkTicketCode(_vm.ticket.ticket_code)}}},[_vm._v(" "+_vm._s(_vm.ticket.status == "ISSUED" ? "check-in" : "check-out")+" ")]):_vm._e(),(_vm.ticket.status === 'CHECK_OUT')?_c('span',{staticClass:"payment"},[_vm._v(" "+_vm._s(_vm.ticket.payment_origin > 0 ? _vm.ticket.payment_origin.toLocaleString() : 0)+_vm._s(_vm.$__t("won"))+" ")]):_vm._e()])])])]),_c('transition',{attrs:{"name":"slide-down"}},[(_vm.flag)?_c('checkout-dialog',{attrs:{"data":_vm.DialogItem,"checkoutButtonDisable":_vm.checkoutButtonDisable},on:{"makeCheckoutButtonDisable":_vm.makeCheckoutButtonDisable}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }