var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"myreserved-store-info"}},[_c('header',[_c('h1',[_vm._v(_vm._s(_vm.$__t('Booked Store Information')))])]),_c('div',{staticClass:"store"},[_c('div',{staticClass:"store-name"},[_vm._v(" "+_vm._s(_vm.store.store_name)+" ")]),_c('div',{staticClass:"store-thumbnail"},[_c('div',{staticClass:"image-box",style:('background-image:url(' + _vm.thumbnailImage + ')')})]),_c('div',{staticClass:"store-review"},[_c('span',{staticClass:"rate"},[_vm._v(_vm._s(_vm.rate))]),_c('rating-star',{attrs:{"rate":_vm.store.rating_total / _vm.store.rating_count}}),(_vm.store.reviews > 5)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.store.reviews)+" reviews")]):_vm._e()],1),_c('dl',{staticClass:"store-address"},[_c('dt',{staticClass:"key"},[_vm._v(_vm._s(_vm.$__t('주소')))]),_c('dd',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.storeAddress)+" ")])]),_c('dl',{staticClass:"store-schedule"},[_c('dt',{staticClass:"key"},[_vm._v(_vm._s(_vm.$__t('영업시간')))]),_c('dd',{staticClass:"value"},[_c('ul',_vm._l((_vm.displayWeeks),function(weekKey,index){return _c('li',{key:weekKey,style:({'font-weight': 'SA_'+ _vm.$moment().format('ddd').toUpperCase() === weekKey ? 'bold' : 'normal'})},[_c('div',{style:({ display: index !== 0 && !_vm.showAll ? 'none' : '' })},[_c('div',{staticClass:"day"},[_vm._v(_vm._s(_vm.$__t(weekKey)))]),_vm._v(" "+_vm._s(_vm.store.time_table && _vm.store.time_table[weekKey] ? _vm.displayTimeTable(_vm.store.time_table[weekKey]) : _vm.$__t('Day Off'))+" "),(
									'SA_' +
										_vm.$moment()
											.format('ddd')
											.toUpperCase() ===
										weekKey
								)?_c('badge',[_vm._v("today")]):_vm._e()],1)])}),0)]),_c('ui-text-button',{class:{ close: _vm.showAll },attrs:{"display":"inline","color":"primary"},on:{"click":_vm.toggleShowAll}},[_vm._v(" "+_vm._s(_vm.showOrClose)+" ")])],1)]),_c('div',{staticClass:"button-area"},[_c('div',{staticClass:"card-button naver-area",on:{"click":function($event){return _vm.openMap('naver')}}},[_c('img',{staticClass:"logo",attrs:{"src":"/img/naver-logo.png"}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$__t('Naver Map')))]),_c('img',{staticClass:"arrow",attrs:{"src":"/img/arrow-right.png","srcset":"/img/arrow-right@2x.png" + " 2x, " + "/img/arrow-right@3x.png" + " 3x"}})]),_c('div',{staticClass:"card-button",on:{"click":function($event){return _vm.openMap('google')}}},[_c('img',{staticClass:"logo",attrs:{"src":"/img/google-logo.png"}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$__t('Google Map')))]),_c('img',{staticClass:"arrow",attrs:{"src":"/img/arrow-right.png","srcset":"/img/arrow-right@2x.png" + " 2x, " + "/img/arrow-right@3x.png" + " 3x"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }