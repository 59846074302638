<template>
	<div id="myreserved-store-info">
		<header>
			<h1>{{ $__t('Booked Store Information')}}</h1>
		</header>
		<div class="store">
			<div class="store-name">
				{{ store.store_name }}
			</div>
			<div class="store-thumbnail">
				<div class="image-box" :style="'background-image:url(' + thumbnailImage + ')'" />
			</div>
			<div class="store-review">
				<span class="rate">{{ rate }}</span>
				<rating-star :rate="store.rating_total / store.rating_count" />
				<span class="total" v-if="store.reviews > 5">{{ store.reviews }} reviews</span>
			</div>
			<dl class="store-address">
				<dt class="key">{{ $__t('주소')}}</dt>
				<dd class="value">
					{{ storeAddress }}
				</dd>
			</dl>
			<dl class="store-schedule">
				<dt class="key">{{ $__t('영업시간') }}</dt>
				<dd class="value">
					<ul>
						<li v-for="(weekKey, index) in displayWeeks" :key="weekKey" :style="{'font-weight': 'SA_'+ $moment().format('ddd').toUpperCase() === weekKey ? 'bold' : 'normal'}">
							<div :style="{ display: index !== 0 && !showAll ? 'none' : '' }">
								<div class="day">{{ $__t(weekKey) }}</div>
								{{ store.time_table && store.time_table[weekKey] ? displayTimeTable(store.time_table[weekKey]) : $__t('Day Off') }}
								<badge
									v-if="
										'SA_' +
											$moment()
												.format('ddd')
												.toUpperCase() ===
											weekKey
									"
									>today</badge
								>
							</div>
						</li>
					</ul>
				</dd>
				<ui-text-button display="inline" color="primary" @click="toggleShowAll" :class="{ close: showAll }">
					{{ showOrClose }}
				</ui-text-button>
			</dl>
		</div>
		<div class="button-area">
			<div class="card-button naver-area" @click="openMap('naver')">
				<img src="/img/naver-logo.png" class="logo" />
				<div class="text">{{$__t('Naver Map')}}</div>
				<img src="/img/arrow-right.png" srcset="/img/arrow-right@2x.png 2x, /img/arrow-right@3x.png 3x" class="arrow" />
			</div>
			<div class="card-button" @click="openMap('google')" >
				<img src="/img/google-logo.png" class="logo" />
				<div class="text">{{$__t('Google Map')}}</div>
				<img src="/img/arrow-right.png" srcset="/img/arrow-right@2x.png 2x, /img/arrow-right@3x.png 3x" class="arrow" />
			</div>
		</div>
	</div>
</template>

<script>
import RatingStar from '@/components/includes/RatingStar';
import Badge from '@/components/modules/Badge/Badge';

export default {
	props: ['store'],
	components: {
		RatingStar,
		Badge,
	},
	data() {
		return {
			showAll: false,
			displayWeeks: ['SA_MON', 'SA_TUE', 'SA_WED', 'SA_THU', 'SA_FRI', 'SA_SAT', 'SA_SUN'], // SA_HOL
		};
	},
	computed: {
		showOrClose() {
			return this.showAll ? this.$__t('닫기') : "+"+this.$__t('More');
		},
		rate() {
			return this.store.rating_total && this.store.rating_count ? (this.store.rating_total / this.store.rating_count).toFixed(1) : '';
		},
		thumbnailImage() {
			let imgUrl = null;
			if (this.store && this.store.resources.thumbnail_image && this.store.resources.thumbnail_image.resource_url) {
				imgUrl = this.store.resources.thumbnail_image.resource_url;
			} else {
				imgUrl = '/img/no-image.png';
			}
			return imgUrl;
		},
		storeAddress(){
      const lang = this.$store.state.lang;
      let address = this.store.store_address;
      let address_detail = this.store.store_address_detail

      if(address_detail){
        if(lang == 'ko'){
          address += ", " + address_detail.trim()
        }else{
          address = address_detail.trim() + ", " + address
        }
      }

      return address;
    }
	},
	methods: {
		toggleShowAll() {
			this.showAll = !this.showAll;
		},
		displayTimeTable(timeTableItem) {
			if (timeTableItem) {
				let nextDayMark = '';
				let endHour = timeTableItem[1].split(':')[0];
				let endMin = timeTableItem[1].split(':')[1];
				if (endHour > 24) {
					endHour -= 24;
					if (endHour < 10) endHour = '0' + endHour;
					nextDayMark = '(+1)';
				}
				return timeTableItem[0] + '~' + endHour + ':' + endMin + nextDayMark;
			} else {
				return this.$__t('Day Off');
			}
		},
		openMap(service) {
			switch (service) {
				case 'naver':
					if (this.wni.isNative) {
						this.openInNative(`nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`);
					} else {
						window.open(`nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`, '_blank');
					}
					break;

				case 'google':
					if (this.wni.isNative) {
						this.openInNative(`https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`);
					} else {
						window.open(`https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,'_blank')
					}
					break;
			}
    },
    openInNative(url) {
			this.wni.execute('wnOpenURL', { url: url });
		},
	},
};
</script>
