import loadScript from "load-script";

const cnvValue = {
  purchase: "1",
  signUp: "2",
  addToCart: "3",
  register: "4",
  etc: "5",
};

const NaverPremiumLog = {
  init(app, options = {}, callback = function () {}) {
    this._wa = options.wa;

    if (this._wa) {
      window.wcs_add = window.wcs_add || {};
      window.wcs_add["wa"] = this._wa;
      window._nasa = window._nasa || {};

      loadScript(`//wcs.naver.net/wcslog.js`, (error, script) => {
        if (error) {
          callback(error, script);
          return;
        }

        window.wcs.inflow();
        wcs_do(window._nasa);

        callback(error, script);
      });
    }
  },

  purchase(options = {}) {
    const { payment_total } = options;

    if (this._wa) {
      if (window.wcs && typeof window.wcs_do === "function") {
        let wcs = window.wcs;
        var nasa = {};
        nasa["cnv"] = wcs.cnv("1", payment_total + "");
        window.wcs_do(nasa);
      } else {
        console.warn("Not Loaded WSC or WSC DO!!");
      }
    }
  },

  cnv(eventType, option) {
    try {
      if (this._wa) {
        if (window.wcs && typeof window.wcs_do === "function") {
          let wcs = window.wcs;
          var nasa = {};
          nasa["cnv"] = wcs.cnv(cnvValue[eventType], option || 1);
          window.wcs_do(nasa);
        } else {
          console.warn("Not Loaded WSC or WSC DO!!");
        }
      }
    } catch (e) {
      console.error(e);
    }
  },

  event(eventCategory, eventName) {
    if (this._wa) {
      if (window.wcs && typeof window.wcs_do === "function") {
        let wcs = window.wcs;
        var nasa = {};
        nasa["event"] = wcs.event(eventCategory, eventName);
        window.wcs_do(nasa);
      } else {
        console.warn("Not Loaded WSC or WSC DO!!");
      }
    }
  },
};

export default NaverPremiumLog;
