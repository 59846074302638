export default [
  {
    name: "ServiceDetail",
    path: "/services/tickets/:ticket_id/detail",
    component: () => import("@/components/pages/service-product/ServiceDetail.vue"),
    props: true,
    meta: {
      headerType: "more-depth",
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      level: 1,
      reserve_form: true,
    },
  },
  {
    name: "ServiceProduct",
    path: "/services/:service_id",
    // path: '/test',
    component: () => import("@/components/pages/service-product/ServiceProduct.vue"),
    props: true,
    meta: { hideHeader: true, hideFooter: true, hideSidebar: true, hideChannelTalk: true, reserve_form: true },
  },
  {
    name: "ServiceProductReserve",
    path: "/services/reserve/:order_id",
    component: () => import("@/components/pages/service-product/ServiceProductReserve.vue"),
    props: true,
    meta: {
      headerType: "more-depth",
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      level: 1,
      reserve_form: true,
    },
  },
  {
    name: "ServiceReservePayMethods",
    path: "/services/reserve/:order_id/pay-methods",
    component: () => import("@/components/pages/service-product/billings/ServiceReservePayMethods.vue"),
    props: true,
    meta: {
      headerType: "more-depth",
      title: "결제수단 선택",
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      level: 1,
      reserve_form: true,
    },
  },
  {
    name: "ServiceProductTicketSingle",
    path: "/myReservation/services/tickets/:id",
    component: () => import("@/components/pages/service-product/ServiceProductTicketSingle.vue"),
    props: true,
    meta: { hideHeader: true, hideFooter: true, hideChannelTalk: true, level: 1 },
  },
  {
    name: "ServiceProductTicketCancel",
    path: "/services/tickets/:id/cancel",
    component: () => import("@/components/pages/service-product/ServiceProductTicketCancel.vue"),
    props: true,
    meta: { headerType: "more-depth", title: "cancel reservation", hideChannelTalk: true },
  },
  {
    name: 'CsNotices',
    path: '/cs/notices',
    component: () => import("@/components/pages/notice/NoticeListPage.vue"),
    meta: { headerType: 'more-depth', title: '공지사항', hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'CsNoticesSingle',
    path: '/cs/notices/:id',
    component: () => import("@/components/pages/notice/NoticePage.vue"),
    meta: { headerType: 'more-depth', title: '공지사항', hideChannelTalk: true, allowPc: true },
    props: true
  },
  {
    name: 'CsTermsChange',
    path: '/cs/terms-change',
    component: () => import("@/components/pages/cs/CsTermsChange.vue"),
    meta: { headerType: 'more-depth', title: '약관 신구 대조표', hideChannelTalk: true, allowPc: true },
    props: true
  },
  {
    name: 'AuthLeave',
    path: '/auth/leave',
    component: () => import("@/components/pages/auth/AuthLeave.vue"),
    meta: { headerType: 'more-depth', title: '회원 탈퇴', hideChannelTalk: true, allowPc: true },
    props: true
  }
];
