var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-slider"},[_c('div',{staticClass:"ticket-slider__viewport"},[(_vm.items.length > 0)?_c('Carousel',{staticClass:"ticket-slider__list",style:(_vm.listStyle),attrs:{"increment-pager":true,"gap":16}},_vm._l((_vm.items.filter((_) => _vm.status.indexOf(_.status) > -1)),function(item,index){return _c('CarouselItem',{key:item.ticket_code,staticClass:"ticket-slider__list-item"},[_c('router-link',{attrs:{"to":{
            name:
              item.ticket_type === 'PRODUCT'
                ? 'ServiceProductTicketSingle'
                : 'TicketSingle',
            params: { id: item.ticket_code },
          }}},[_c('div',{staticClass:"ticket-item ticket-item--activated",class:{ on: index === _vm.idx }},[_c('div',{staticClass:"image-frame"},[_c('object',{attrs:{"data":_vm.getEmoji(item),"type":"image/svg+xml"}})]),_c('div',{staticClass:"info"},[_c('p',{staticClass:"primary",domProps:{"innerHTML":_vm._s(_vm.getPrimaryText(item))}}),_c('p',{staticClass:"secondary",domProps:{"innerHTML":_vm._s(_vm.$__t(_vm.getSecondaryText(item)))}})]),_c('div',{staticClass:"indicator"},[_c('img',{attrs:{"src":"/img/icon-arrow-right-black.png"}})])])])],1)}),1):[_c('div',{staticClass:"ticket-item ticket-item--empty"},[_c('div',{staticClass:"image-frame"},[_c('object',{attrs:{"data":"/img/emoji/emoji-cry.svg","type":"image/svg+xml"}})]),_c('p',{staticClass:"secondary",domProps:{"innerHTML":_vm._s(_vm.$__t(_vm.getSecondaryText()))}})])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }