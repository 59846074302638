<template>
  <div class="ui-carousel" :class="{ fade: slideType == 'fade', slide: slideType != 'fade' }">
    <div class="ui-carousel-viewport" ref="viewport">
      <ul class="ui-carousel-list" ref="list" v-if="slideType == 'fade'">
        <slot />
      </ul>
      <ul class="ui-carousel-list" ref="list" v-if="slideType != 'fade'" :style="'width:'+( 100 * length )+'%; transform: translateX('+( ( -100/length ) * idx )+'%);'">
        <slot />
      </ul>
      <div class="ui-carousel-arrow next" ref="arrowNext" @click="slideNext" v-if="arrow && items.length > 1">
        <svg viewBox="0 0 48 48">
          <polyline points="18,22 24,28 30,22" fill="none" stroke-width="2" />
        </svg>
      </div>
      <div class="ui-carousel-arrow prev" ref="arrowPrev" @click="slidePrev" v-if="arrow && items.length > 1">
        <svg viewBox="0 0 48 48">
          <polyline points="18,22 24,28 30,22" fill="none" stroke-width="2" />
        </svg>
      </div>
      <div class="ui-carousel-pager-zoomable" v-if="pager && items.length > 1 && zoomable">
        <span v-for="dot in length" :class="{ on: dot == idx+1 }" @click="slideTo( dot-1 )"></span>
      </div>
      <div class="ui-carousel-pager" v-else-if="pager && items.length > 1">
        <span v-for="dot in length" :class="{ on: dot == idx+1 }" @click="slideTo( dot-1 )"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [ "slideType", "arrow", "pager" , "zoomable"],
  data(){
    var idx = 0;
    var length = 0;
    var items = [];
    var dragEventNames = {
      start: [ "mousedown", "touchstart" ],
      move: [ "mousemove", "touchmove" ],
      end: [ "mouseup", "touchend" ],
    };
    var dragStartX = 0;
    var dragMoveX = 0;
    return {
      idx,
      length,
      items,
      dragEventNames,
      dragStartX,
      dragMoveX,
    };
  },
  mounted(){
    this.idx = 0;
    this.items = this.$refs.list.querySelectorAll( ".ui-carousel-item" );
    if( this.items.length ){
      this.items[ this.idx ].classList.add( "on" );
      this.length = this.items.length;
    };
    if( this.items.length > 1 ){
      this.bindEvent();
    };
  },
  methods: {
    slideTo( idx ){
      if( this.idx == idx ){
        this.$refs.list.style.transform = "translateX(" + ( -1 * ( 100/this.length ) * this.idx ) + "%)";
      };
      this.idx = idx;
      for( var i=0; i<this.items.length; i++ ){
        this.items[i].classList.remove( "on" );
      };
      this.items[ this.idx ].classList.add( "on" );
    },
    slideNext(){
      this.slideTo( this.idx == this.length-1 ? 0 : ( this.idx+1 ) );
    },
    slidePrev(){
      this.slideTo( this.idx == 0 ? this.length-1 : ( this.idx-1 ) );
    },
    bindEvent(){
      this.$refs.viewport.addEventListener( this.dragEventNames.start[ this.$store.state.config.isTouchDevice ? 1 : 0 ], e => {
        if( this.$store.state.config.isTouchDevice || ( !this.$store.state.config.isTouchDevice && e.which == 1 ) ) this.bindDragStart( e );
      });
    },
    bindDragStart( e ){
      this.dragStartX = this.$store.state.config.isTouchDevice ? e.touches[0].clientX : e.x;
      window.addEventListener( this.dragEventNames.move[ this.$store.state.config.isTouchDevice ? 1 : 0 ], this.bindDragMove );
      window.addEventListener( this.dragEventNames.end[ this.$store.state.config.isTouchDevice ? 1 : 0 ], this.bindDragEnd );
    },
    bindDragMove( e ){
      this.dragMoveX = this.$store.state.config.isTouchDevice ? this.dragStartX - e.touches[0].clientX : this.dragStartX - e.x;
      this.$refs.list.style.transitionDuration = "0s";
      this.$refs.list.style.transform = "translateX(" + ( -1 * ( this.dragMoveX + this.idx*this.$refs.viewport.offsetWidth ) ) + "px)";
    },
    bindDragEnd( e ){
      this.$refs.list.style.transitionDuration = "";
      if( this.dragMoveX > this.$refs.viewport.offsetWidth/4 ){
        this.slideNext();
      }else if( this.dragMoveX < -1 * this.$refs.viewport.offsetWidth/4 ){
        this.slidePrev();
      }else{
        this.slideTo( this.idx );
      };
      this.dragStartX = 0;
      this.dragMoveX = 0;
      window.removeEventListener( this.dragEventNames.move[ this.$store.state.config.isTouchDevice ? 1 : 0 ], this.bindDragMove );
      window.removeEventListener( this.dragEventNames.end[ this.$store.state.config.isTouchDevice ? 1 : 0 ], this.bindDragEnd );
    }
  }
};
</script>
