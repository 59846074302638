<template>
  <div
    class="ui-store-slider"
    @mousedown.stop="onMouseDown"
    @touchstart.stop="onMouseDown"
    :class="{ disabled: !!disabled }"
  >
    <div class="hr"></div>
    <div class="container" ref="body" @click="disabled ? '' : onClickBody()">
      <div class="body">
        <template v-if="store">
          <div class="image-area">
            <img :src="storeThumbnail" alt="Store Thumbnail" class="thumbnail" />
            <img
              v-if="store.extra && store.extra.time_24hour"
              src="/img/icon-24-hours.png"
              alt="Open for 24 hours"
              class="badge"
            />
          </div>
          <div class="text-area">
            <ul>
              <li>
                <!-- <span class="store-name">{{ $__t(store.store_name) }}</span> -->
                <span class="store-name">{{ storeName }}</span>
              </li>
              <li :class="{ error: timeTableTodayText === $__t('Day Off') }">
                {{ timeTableTodayText }}
              </li>
              <li v-if="distance > 0">
                <span class="text">
                  {{ distanceText }}
                </span>
              </li>
              <li class="error">
                <p>
                  {{
                    noOpeningHoursError ||
                    overLuggageLimitError ||
                    invalidStatusError ||
                    temporaryHolidayError
                  }}
                </p>
              </li>
              <li class="insure">
                <span class="insure-logo-area">
                  <img src="/img/insure-logo.svg" alt="파손보험" class="insure_logo" />
                </span>
                <span class="insure-text-area"
                  ><span class="strong">{{ $__t("100만원") }}</span>
                  {{ $__t("분실 파손 보험") }}</span
                >
              </li>
            </ul>
          </div>
          <i class="fa fa-angle-right"></i>
        </template>
        <template v-else>
          <i class="fa fa-spinner fa-pulse"></i>
        </template>
      </div>
      <div class="footer">
        <ul class="facility-list">
          <li class="facility-item" v-for="item in facilities" :key="item">
            {{ $__t(item) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  name: "StoreSlider",

  props: ["store_id", "distance", "formData", "disabled", "disabledReason"],

  data() {
    return {
      position: 0,
      previousY: 0,
      offest: 0,
      rem: 0,

      displayWeeks: [
        "SA_SUN",
        "SA_MON",
        "SA_TUE",
        "SA_WED",
        "SA_THU",
        "SA_FRI",
        "SA_SAT",
        "SA_HOL",
      ],
    };
  },

  created() {
    if (!this.store) {
      this.$store.dispatch("stores/get", { id: this.store_id });
    }
  },

  computed: {
    languageSetting() {
      return localStorage.getItem("lugstay_language");
    },
    store() {
      // console.log(
      //   "store",
      //   this.$store.getters["stores/GET_ITEM"]({
      //     key: "id",
      //     value: this.store_id,
      //   })
      // );
      return this.$store.getters["stores/GET_ITEM"]({
        key: "id",
        value: this.store_id,
      });
    },
    storeName() {
      // if (this.languageSetting === "ko") {
      //   return this.store?.store_name_locales?.ko;
      // } else {
      //   if (!this.store?.store_name_locales?.base) {
      //     return this.store?.store_name_locales?.ko;
      //   }
      //   return this.store?.store_name_locales?.base;
      // }
      return this.store?.store_name;
    },

    facilities() {
      return [
        this.elevatorText,
        this.store?.store_type === "STORE" ? "Store" : "Locker",
        this.store?.store_category,
        this.store?.floor ? this.store.floor + this.$__t("층") : null,
      ].filter((_) => !!_);
    },

    timeTableTodayText() {
      const timeTableToday = this.store?.time_table[
        this.displayWeeks[this.$moment().day()]
      ];

      if (timeTableToday) {
        let nextDayMark = "";
        let endHour = timeTableToday[1].split(":")[0];
        let endMin = timeTableToday[1].split(":")[1];
        if (endHour > 24) {
          endHour -= 24;
          if (endHour < 10) endHour = "0" + endHour;
          nextDayMark = "(+1)";
        }
        return (
          this.$__t("{date-time-picker}.today") +
          " " +
          timeTableToday[0] +
          "~" +
          endHour +
          ":" +
          endMin +
          nextDayMark
        );
      } else {
        return this.$__t("Day Off");
      }
    },

    elevatorText() {
      if (!this.store) return null;

      if (Number(this.store.floor) === 1) return null;

      let text = this.$__t("SF_ELEVATOR") + " ";

      text +=
        this.store?.facilities?.toLowerCase().indexOf("elevator") > -1
          ? this.$__t("있음")
          : this.$__t("없음");

      return text;
    },

    distanceText() {
      let text = this.$__t("현재위치에서 도보 *분");

      return text.replace("*", Math.ceil(parseFloat(this.distance / 70)));
    },

    storeThumbnail() {
      let thumbnail =
        this.store?.resources?.thumbnail_image?.resource_thumbnail_url ||
        this.store?.resources?.thumbnail_image?.resource_url ||
        "/img/store-thumbnail-default.svg";

      return thumbnail;
    },

    noOpeningHoursError() {
      let value = ["INVALID_START_DATE", "INVALID_END_DATE"];

      const DISABLED_REASONS = (this.disabledReason || "").split(",");

      if (DISABLED_REASONS.some((o) => value.some((v) => o.includes(v)))) {
        value = this.$__t("INVALID_START_DATE");
      } else {
        value = null;
      }

      return value;
    },

    overLuggageLimitError() {
      let value = "OUT_OF_STOCK";

      if (this.disabledReason !== value) {
        value = null;
      } else {
        value = this.$__t("{for-map}.lug_limit.error");
      }

      return value;
    },

    invalidStatusError() {
      let value = "INVALID_STATUS_STORE";

      if (this.disabledReason.indexOf(value) < 0) {
        value = "";
      }

      return this.$__t(value);
    },

    temporaryHolidayError() {
      let value = ["CLOSED_START_DATE", "CLOSED_END_DATE"];

      const DISABLED_REASONS = (this.disabledReason || "").split(",");

      if (DISABLED_REASONS.some((o) => value.some((v) => o.includes(v)))) {
        value = this.$__t("상점의 사정으로 예약이 불가한 상점입니다.");
      } else {
        value = null;
      }

      return value;
    },

    floorText() {
      return this.store?.floor > 0
        ? this.store.floor + this.$__t("층")
        : this.$__t("지하") +
            " " +
            this.store.floor.toString().replaceAll("-", "") +
            this.$__t("층");
    },
  },
  // mounted() {
  //   console.log('disabled', this.disabled);
  //   console.log('disabledReason', this.disabledReason);
  // },
  // watch: {
  //   disabled: {
  //     handler(value, oldValue) {
  //       console.log('disabled', value);
  //     }
  //   },
  //   disabledReason(value, oldValue) {
  //     console.log('disabledReason', value);
  //   }
  // },
  methods: {
    onMouseDown(e) {
      if (!!this.disabled) return;

      this.previousY = e.touches ? e.touches[0].clientY : e.clientY;

      this.$refs.body.addEventListener("mousemove", this.onMouseMove, {
        passive: false,
      });
      this.$refs.body.addEventListener("mouseup", this.onMouseUp, {
        passive: false,
      });
      this.$refs.body.addEventListener("touchmove", this.onMouseMove, {
        passive: false,
      });
      this.$refs.body.addEventListener("touchend", this.onMouseUp, {
        passive: false,
      });
    },

    onMouseMove(e) {
      if (e.cancelable) e.preventDefault();

      let clientY = e.touches ? e.touches[0].clientY : e.clientY;

      this.offset = clientY - this.previousY;

      if (this.offset > 60) {
        this.position = 0;
        this.$refs.body.dispatchEvent(new MouseEvent("mouseup"));
        return;
      } else if (Math.abs(this.offset) >= 100) {
        this.$refs.body.dispatchEvent(new MouseEvent("mouseup"));
        return;
      }

      this.position = this.offset;

      this.setPosition(0);
    },

    onMouseUp(e) {
      let duration = null;

      if (this.offset < 0) {
        if (Math.abs(this.offset) < 100) {
          this.position = 0;
        } else {
          this.position = (window.innerHeight - this.$el.clientHeight) * -1;
          duration = Math.abs(this.offset) / 800 + 0.1;
          this.$store.commit("loading/SET_TRUE");

          setTimeout(() => {
            this.position = 0;
            this.setPosition(duration);
            window.history.replaceState(
              {},
              "",
              "/stores" +
                utils.generateQueryString({
                  ...this.$route.query,
                  autoSearch: "target",
                  sid: this.store.id,
                })
            );
            this.$emit("reserve");
            setTimeout(() => {
              this.$store.commit("loading/SET_FALSE");
            }, 0);
          }, 100);
        }
      } else {
        this.position = 0;
      }

      this.$refs.body.removeEventListener("mousemove", this.onMouseMove);
      this.$refs.body.removeEventListener("mouseup", this.onMouseUp);
      this.$refs.body.removeEventListener("touchmove", this.onMouseMove);
      this.$refs.body.removeEventListener("touchend", this.onMouseUp);

      this.setPosition(duration || Math.abs(this.offset) / 200 + 0.1);
    },

    onClickBody() {
      window.history.replaceState(
        {},
        "",
        "/stores" +
          utils.generateQueryString({
            autoSearch: "target",
            sid: this.store.id,
            ...this.$route.query,
          })
      );
      this.$store.commit("loading/SET_TRUE");

      setTimeout(() => {
        this.$emit("reserve");
        setTimeout(() => this.$store.commit("loading/SET_FALSE"), 0);
      }, 100);
    },

    setPosition(transformDuration = 0) {
      let itemPosition = `
        transition-timing-function: ease-out;
        transition: transform ${transformDuration}s;
        transform: translate(-50%, ${this.position}px);
      `;

      this.$el.style.cssText = itemPosition;
    },
  },
};
</script>
<style lang="scss" scoped>
.insure {
  display: flex;
  align-items: center;
}
.insure-text-area {
  font-size: unit(12);
  padding-bottom: unit(1);
}
.strong {
  font-weight: 700;
  color: #008fff;
}
</style>
