<template>
  <div class="popup-luggage" v-if="opened">
    <div class="popup-luggage-container" @click.stop="">
      <i class="fa fa-times" @click.stop="clickClose"></i>
      <price></price>
    </div>
  </div>
</template>

<script>
import Price from "@/components/pages/_/Price";

export default {
  components: {
    Price
  },
  data(){
    return {
      opened: false,
    };
  },
  watch: {
    opened(isOpened){
      if(isOpened){
        window.addEventListener('popstate', this.closeLuggage);
      }else{
        window.removeEventListener('popstate', this.closeLuggage);
      }
    }
  },
  methods: {
    openLuggage() {
      this.opened = true;
      window.history.pushState({}, null, "")
    },
    closeLuggage() {
      this.opened = false;
    },
    clickClose() {
      window.history.go(-1);
    }
  }
}
</script>
