<template>
  <div class="ticket-check-in">
    <h1 class="header">{{ $__t("Checked in completed!") }}</h1>
    <p class="explanatory">
      <!-- <strong>물품 사진</strong>을 꼭 <strong>촬영하셔야 보관이 완료됩니다.</strong> 사진 촬영을 하지 않아 보관 완료가 되지 않을 시에는
      <b>티켓이 만료</b>될 수 있습니다. -->
      📸 {{ $__t("물품 확인 및 분실 방지를 위해 보관 물품 사진을 촬영하세요.") }}
    </p>
    <div v-if="loading" class="loading">
      <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
    </div>
    <div class="image-frame">
      <p v-if="!imagePreview" class="placeholder">
        {{ $__t("물품 사진을 촬영해주세요.") }}
      </p>
      <!-- <input v-else type="file" accept="image/*" capture="environment" class="input" @change="onChangeFile" /> -->
      <img v-else :src="imagePreview" class="image" ref="image" />
    </div>
    <ul class="info-list">
      <li class="info-list__item">
        <span>{{ $__t("맡기실 물품이 잘 보이도록 찍어주세요.") }}</span>
      </li>
      <li class="info-list__item">
        <span>{{ $__t("보관 중 파손 or 분실 시 증거 자료가 될 수 있습니다.") }}</span>
      </li>
    </ul>
    <div class="actions">
      <template v-if="wni.isNative">
        <template v-if="resource">
          <button v-ripple type="button" @click="nativeUpload" class="btn btn--cancel">
            {{ $__t("재촬영") }}
          </button>
          <button
            v-ripple
            type="button"
            @click="confirm"
            class="btn btn--confirm"
            :class="{ 'btn--disabled': !imagePreview }"
          >
            {{ $__t("확인") }}
          </button>
        </template>
        <template v-else>
          <button v-ripple type="button" class="btn btn--cancel" @click="$emit('close')">
            {{ $__t("닫기") }}
          </button>
          <button v-ripple type="button" @click="nativeUpload" class="btn btn--confirm">
            {{ $__t("사진 촬영") }}
          </button>
        </template>
      </template>

      <template v-else>
        <template v-if="imagePreview">
          <div v-ripple class="btn btn--cancel">
            <input
              id="file"
              type="file"
              accept="image/*"
              capture="environment"
              class="input"
              @change="onChangeFile"
            />
            <label for="file">{{ $__t("재촬영") }}</label>
          </div>
          <button
            v-ripple
            type="button"
            @click="confirm"
            class="btn btn--confirm"
            :class="{ 'btn--disabled': !imagePreview }"
          >
            {{ $__t("확인") }}
          </button>
        </template>
        <template v-else>
          <button v-ripple type="button" class="btn btn--cancel" @click="$emit('close')">
            {{ $__t("닫기") }}
          </button>
          <div v-ripple class="btn btn--confirm">
            <input
              id="file"
              type="file"
              accept="image/*"
              capture="environment"
              class="input"
              @change="onChangeFile"
            />
            <label for="file">{{ $__t("사진 촬영") }}</label>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import loadImage from "blueimp-load-image";
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/loading-primary.json";

export default {
  props: ["ticket"],

  data() {
    return {
      loading: false,
      lottieOptions: {
        animationData: LottieLoading,
      },

      file: null,
      resource: null,
      imagePreview: "",
    };
  },

  methods: {
    nativeUpload() {
      this.loading = true;
      this.wni.execute("wnCameraUpload", {
        type: "camera",
        bucket: "lugstay",
        path: `${this.$store.state.config.mode}/luggage-app`,
        editing: false,
        callback: this.wni.cb(async ({ size, url, error }) => {
          try {
            if (error) {
              this.loading = false;
              // @TODO: Permission
              // this.wni.execute('wnOpenAppSetting', { type: 'general' });
              return this.$store.commit("alert/ADD_ITEM", {
                message: error,
                status: "error",
              });
            }

            const response = await this.axios.post(
              this.$store.state.config.apiURL + "/v2/resources/image",
              {
                key: this.$store.state.auth.resource_key,
                tag: "luggage_photo",
                resource_type: "image",
                resource_url: url,
              },
              {
                headers: this.$store.state.config.userHeaders,
              }
            );

            this.imagePreview = url;
            this.resource = response?.data?.data;

            this.loading = false;
          } catch (error) {
            this.loading = false;
            if (error.indexOf("fail to") < 0) {
              this.$store.commit("alert/ADD_ITEM", {
                message: error,
                status: "error",
              });
            }
          }
        }),
      });
    },

    onChangeFile(e) {
      this.loading = true;
      this.file = e.target.files[0];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        (e) => {
          this.$data.imagePreview = e.target.result;
          setTimeout(() => {
            const image = this.$refs.image;
            image.src = e.target.result;
            this.loading = false;
          }, 0);
        },
        false
      );

      reader.readAsDataURL(this.file);
    },

    async uploadResource(formData) {
      const response = await this.axios.post(
        this.$store.state.config.apiURL + "/v2/resources/upload",
        formData,
        {
          headers: this.$store.state.config.userHeaders,
        }
      );

      try {
        this.resource = response.data?.data?.resources[0];
      } catch (e) {
        this.resource = null;
      }
    },

    async updateTicket() {
      try {
        await this.axios.put(
          this.$store.state.config.apiURL +
            `/v2/luggage/tickets/${this.ticket.ticket_code}`,
          {
            resources: { luggage_photo: this.resource },
          }
        );
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
        console.error(e);
      }
    },

    async checkIn() {
      try {
        await this.$store.dispatch("tickets/luggageCheckIn", {
          code: this.ticket.ticket_code,
        });
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
        console.error(e);
      }
    },

    async confirm() {
      const loadingImage = this.file;
      this.loading = true;

      if (this.wni.isNative) {
        await this.updateTicket();
        // await this.checkIn();
        this.loading = false;
        this.$emit("fetch-order");
        this.$emit("fetch-ticket");
        this.$emit("close");
        return;
      }

      try {
        loadImage(
          loadingImage,
          (img, data) => {
            img.toBlob(async (blob) => {
              let imageFile = null;
              try {
                imageFile = new File([blob], loadingImage.name, {
                  type: loadingImage.type,
                });
              } catch (e) {
                imageFile = Object.assign(blob, loadingImage.name, {
                  type: loadingImage.type,
                });
              } finally {
                let formData = new FormData();
                formData.append("key", this.$store.state.auth.resource_key);
                formData.append("tag", "luggage_photo");
                formData.append("file", imageFile);

                if (!this.wni.isNative) {
                  await this.uploadResource(formData);
                }
                await this.updateTicket();
                await this.checkIn();
                this.$analytics.logEvent("complete check in");
                this.loading = false;
                this.$emit("close");
                this.$emit("fetch-order");
                this.$emit("fetch-ticket");
              }
            });
          },
          {
            orientation: true,
            canvas: true,
            maxWidth: 600,
          }
        );
      } catch (e) {
        this.loading = false;
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      }
    },
  },

  components: {
    Lottie,
  },
};
</script>

<style scoped lang="scss">
.ticket-check-in {
  background: $color-white;

  .header {
    margin-top: unit(24);
    font-size: unit(22);
    line-height: 1.45;
    font-weight: bold;
  }

  .explanatory {
    margin-top: unit(4);
    font-size: unit(16);
    line-height: 1.43;
    letter-spacing: -0.006em;
    color: #292a2b;

    strong,
    b {
      font-size: unit(16);
      font-weight: bold;
    }

    b {
      color: $color-red;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-frame {
    margin-top: unit(30);
    padding-bottom: 60%;
    border: 1px solid #e1e4e6;
    border-radius: unit(10);

    .placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: unit(16);
      font-weight: unit(14);
      line-height: 1.43;
      color: #a9afb3;
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-list {
    margin-top: unit(20);

    &__item {
      padding-left: unit(16);
      font-size: unit(14);
      line-height: 1.71;
      letter-spacing: -0.006em;
      color: #61676c;

      &::before {
        content: "·";
        position: absolute;
        top: unit(2);
        left: 0;
        margin-right: unit(2);
        font-size: unit(14);
        line-height: 1.71;
        letter-spacing: -0.006em;
        color: #61676c;
      }
    }
  }

  .actions {
    margin-top: unit(50);
    display: flex;
    gap: unit(10);

    .btn {
      flex: 1;
      font-size: unit(14);
      line-height: 1.43;
      padding: unit(15) unit(16);
      text-align: center;

      &--cancel {
        color: #a9afb3;
        background: $color-white;
        border: solid 1px #e1e4e6;
        border-radius: unit(10);
      }

      &--confirm {
        color: $color-white;
        background: #48d9eb;
        border-radius: unit(10);
      }

      &--disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .input {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
    }
  }
}
</style>
