<template>
  <div class="subpage" id="delivery-status-page">
    <div class="container">
      <div class="section">
        <div class="status-step">
          <div class="container" :class="{activated: deliveryImageStatus == item.status }" v-for="item in list" :key="item.status">
            <div class="image">
              <img :src="deliveryImageStatus == item.status ? item.src : item.invalidSrc">
            </div>
            <div class="text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      
      <div class="section">
        <div class="status-history">
          <div class="row header">
            <div class="cell">
              {{ $__t('{delivery-status}.header.datetime') }}
            </div>
            <div class="cell">
              {{ $__t('{delivery-status}.header.current_location') }}
            </div>
            <div class="cell">
              {{ $__t('상태') }}
            </div>
          </div>
          <div class="row" v-for="(record, index) in records" :key="record.status">
            <div class="cell">
              {{ record.datetime }}
            </div>
            <div class="cell">
              {{ record.location }}
            </div>
            <div class="cell" :class="{ activated : index == 0}">
              {{ record.status }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data(){
    let list = [
      {
        status: 'ISSUED',
        src: '/img/delivery-status-issued.png',
        invalidSrc: '/img/delivery-status-issued-invalid.png',
        text: this.$__t('{delivery-status}.image.status.1')
      },
      {
        status: 'CHECK_IN',
        src: '/img/delivery-status-checkin.png',
        invalidSrc: '/img/delivery-status-checkin-invalid.png',
        text: this.$__t('{delivery-status}.image.status.2')
      },
      {
        status: 'PICK_UP',
        src: '/img/delivery-status-pickup.png',
        invalidSrc: '/img/delivery-status-pickup-invalid.png',
        text: this.$__t('{delivery-status}.image.status.3')
      },
      {
        status: 'DROP_OFF',
        src: '/img/delivery-status-dropoff.png',
        invalidSrc: '/img/delivery-status-dropoff-invalid.png',
        text: this.$__t('{delivery-status}.status.dropoff')
      },
      {
        status: 'CHECK_OUT',
        src: '/img/delivery-status-checkout.png',
        invalidSrc: '/img/delivery-status-checkout-invalid.png',
        text: this.$__t('{delivery-status}.status.checkout')
      }
    ]
    return{
      list,
      open_ticket: ''
    }
  },

  computed:{
    ticket(){
      return this.$store.getters['tickets/GET_ITEM']({ key: 'ticket_code', value: this.id }) || this.open_ticket;
    },
    invoice(){
      return this.$store.getters['resourceDelivery/GET_ITEM']({ type: 'invoice', key: 'ticket_code', value: this.id });
    },
    records(){
      if(!this.invoice || !this.ticket) return;

      let { datetime_reserve, datetime_check_in, datetime_pick_up, datetime_drop_off, datetime_check_out, sender_store_id, sender_address, recipient_store_id } = this.invoice;
      let { sender_place, sender_store_name, recipient_place, recipient_store_name } = this.ticket.product_data ? this.ticket.product_data : this.ticket;
      
      let statusObject = {}, list = [];

      if(datetime_reserve){
        statusObject.status = this.$__t('예약');
        statusObject.datetime = this.$moment(datetime_reserve).format('YYYY-MM-DD HH:mm');

        statusObject.location = '-'

        list.push(statusObject);
        statusObject = {};
      }

      if(datetime_check_in){
        statusObject.status = this.$__t('{delivery-status}.status.checkin');
        statusObject.datetime =  this.$moment(datetime_check_in).format('YYYY-MM-DD HH:mm');

        if(!sender_store_id){
          statusObject.location = sender_place;
        }else{
          statusObject.location = sender_store_name;
        }

        list.push(statusObject);
        statusObject = {};
      }

      if(datetime_pick_up){
        statusObject.status = this.$__t('{delivery-status}.status.pickup');
        statusObject.datetime = this.$moment(datetime_pick_up).format('YYYY-MM-DD HH:mm');

        if(!sender_store_id){
          statusObject.location = sender_place;
        }else{
          statusObject.location = sender_store_name;
        }

        list.push(statusObject);
        statusObject = {};
      }

      if(datetime_drop_off){
        statusObject.status = this.$__t('{delivery-status}.status.dropoff');
        statusObject.datetime = this.$moment(datetime_drop_off).format('YYYY-MM-DD HH:mm');

        if(!recipient_store_id){
          statusObject.location = recipient_place;
        }else{
          statusObject.location = recipient_store_name;
        }

        list.push(statusObject);
        statusObject = {};
      }

      if(datetime_check_out){
        statusObject.status = this.$__t('{delivery-status}.status.checkout');
        statusObject.datetime = this.$moment(datetime_check_out).format('YYYY-MM-DD HH:mm');

        if(!recipient_store_id){
          statusObject.location = recipient_place;
        }else{
          statusObject.location = recipient_store_name;
        }

        list.push(statusObject);
        statusObject = {};
      }

      return list.reverse();

    },
    deliveryImageStatus(){
      if(!this.ticket) return;

      if(this.ticket.status == 'ASSIGNING' || this.ticket.status == 'APPROVE'){
        return "CHECK_IN"
      }else{
        return this.ticket.status
      }
    }
  },

  async created(){
    await this.getTicket();
    await this.getInvoice();
  },

  methods: {
    async getTicket(){
      try{
        let res = await this.$store.dispatch('tickets/get', {id: this.id});

        return res.data.data;
      }
      catch(e){
        try{
          let res = await this.$store.dispatch('tickets/getByShare', { code: this.id });

          this.open_ticket = res;

          return res;
        }
        catch(e){}
      }
    },

     async getInvoice(){
      try{
        let res = await this.$store.dispatch('resourceDelivery/getInvoice', {code: this.id})

        return res;
      }
      catch(e){
        this.$log.error(e)
        this.$store.commit('alert/ADD_ITEM', {message: this.$__t(e), status: 'error' })
      }
    }
  },

  name: 'DeliveryStatus'
}
</script>