<template>
  <div class="box-select-form">
    <div class="container">
      <div class="header">
        <div class="breadcrumb">
          <ul>
            <li class="using">사용중</li>
            <li class="unavailable">사용불가</li>
            <li class="available">사용가능</li>
          </ul>
        </div>
      </div>
      <div class="body">
        <div class="selector" ref="selector">
          <ul class="column">
            <li v-for="(column, colIndex) in locker" :key="`column-${colIndex}`">
              <ul class="row">
                <li ref="cell" v-for="(row, rowIndex) in column"  :key="`column-${colIndex}-row-${rowIndex}`" :style="{ height: row.locker_ratio + '%' }" :class="getCellClass(row)" @click.stop="onClickCell(row)">
                  <span v-if="selectedBox && (selectedBox.locker_number === row.locker_number)">
                    <i v-if="available" class="fas fa-check"></i>
                    <i v-else class="fas fa-circle-notch a-rotate"></i>
                  </span>
                  <span v-else class="text">
                    <template v-if="row.locker_number">
                      {{ row.locker_number }}
                    </template>
                    <template v-else>
                      <i class="fas fa-lock"></i>
                    </template>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "locker"],

  data() {
    return {
      available: false,
      selectedBox: null,
      selectorHeight: 0,
			rem: 12,
    };
  },

  mounted() {
		this.setRem();
    window.addEventListener("resize", this.setRem);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setRem);
  },

  computed: {
    store() {
      return this.$store.getters["stores/GET_ITEM"]({ key: "id", value: this.id });
    },
  },

  methods: {
    onClickSubmit() {
      return this.$emit("select", this.selectedBox);
    },

    async reload() {
      this.$store.commit("loading/SET_TRUE");
      await this.$store.dispatch("stores/get", { id: this.id, list: "SearchedStores" });
      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE");
      }, 50);
    },

    setSelectorHeight() {
      this.selectorHeight = this.$refs.selector.offsetWidth * (7 / 6);
    },

    setRem() {
      const clientWidth = window.innerWidth;
      let rem = 12;

      if (clientWidth < 480) {
        rem = 12;
      } else if (480 <= clientWidth && clientWidth < 640) {
        rem = 13;
      } else if (640 <= clientWidth && clientWidth < 800) {
        rem = 14;
      } else {
        rem = 16;
      }

      this.rem = rem;
    },

    getRemSize($size) {
      return (this.rem * $size) / 12;
    },

		getCellClass(cell){
			const STATUS = {
				'READY': 'available',
				'STORED': 'using',
				'RESERVED': 'using',
				'BLOCKED': 'unavailable'
			}

			if(!cell.status){
				return 'unavailable'	
			}

			if(cell.locker_number && cell.locker_number === this.selectedBox?.locker_number){
				return [STATUS[cell.status], 'selected']
			}

			return STATUS[cell.status] || 'unavailable'
		},

		onClickCell( cell ){
      this.available = false;
			if(cell.status !== 'READY') return;

      if(this.selectedBox === cell){
        this.selectedBox = null;
        return this.onClickSubmit();
      }

			this.selectedBox = cell;

      this.onClickSubmit();
		},

    async setAvailability( flag ){
      if( flag === undefined ){
        return;
      }

      if(flag) {
        await this.$utils.wait(250);
        this.available = flag;
      }

      if(!flag){
        this.available = flag;
        this.selectedBox = null;
      }
    }
  },
};
</script>
