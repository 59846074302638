<template>
  <div class="page" id="auth-delete-page">
    <div class="container">

      <div class="form">
        <div class="form-header">
          <div class="title">{{ $__t( "계정 삭제하기" ) }}</div>
          <div class="description">
            <p>{{ $__t( "계정을 삭제하기 위해서 추가 인증을 실행합니다. 비밀번호를 한 번 더 입력해주세요." ) }}</p>
          </div>
        </div>
        <div class="form-body">
          <ui-form @submit="submitForm" :formData="formData" ref="form">
            <ui-form-list>
              <ui-form-list-item>
                <ui-textbox :placeholder="formData.password.placeholder" :type="formData.password.type" :label="formData.password.label" :error="formData.password.error" v-model="formData.password.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-solid-button display="block" color="red"><button type="submit"><i class="fa fa-trash"></i>{{ $__t( "계정 삭제하기" )}}</button></ui-solid-button>
              </ui-form-list-item>
            </ui-form-list>
          </ui-form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    var formData = {
      password: {
        type: "password",
        label: this.$__t( "비밀번호" ),
        placeholder: this.$__t( "로그인 비밀번호를 입력해주세요" ),
        validation: [
          { type: "required" },
        ],
        value: "",
        error: ""
      },
    };
    return {
      formData
    };
  },
  methods: {
    submitForm( values ){
      this.$log.log( "submitForm", values )

      this.$store.dispatch( "auth/delete", values ).then( res => {
        this.$refs.form.resetForm();
        this.$router.replace({ name: "Home" });
        this.$store.commit( "alert/ADD_ITEM", { message: this.$__t("계정 탈퇴가 완료되었습니다."), status: "success" });
      }).catch( err => {
        this.$log.error(err)
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
  }
}
</script>
