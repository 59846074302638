<template>
  <div class="page" id="myticket-page">
    <div class="container">
      <div class="page-header" :style="headerStyle">
        <div class="container">
          <div class="title">{{ $__t("Reservation History") }}</div>
        </div>
      </div>

      <div class="page-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    headerStyle() {
      let style = {};

      switch (this.$route.name) {
        case "TicketSingle":
        case "DeliveryStatus":
          style.display = "none";
          break;
      }

      return style;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$store.commit("SET_TRUE", { key: "appIntroViewed" });
    },
  },

  name: "MyReservation",
};
</script>
