<template>
  <div id="delivery-form-step">
    <div class="container">
      <delivery-select @select="submitForm($event)"/>
    </div>
  </div>
</template>

<script>
import DeliverySelect from "@/components/includes/DeliverySelect";

export default {
  props: ['store'],
  data(){
    return{}
  },
  computed: {

  },
  methods: {
    submitForm( type ){
      this.$router.push({ name: 'StoreDeliveryStep1', query: { delivery_type: type.key, ...this.$route.query } })
    }
  },
  components: {
    DeliverySelect
  },
  inheritAttrs: false
}
</script>