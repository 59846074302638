<template>
  <div class="ticket-message">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{ $__t('예약만료') | toUpperCase }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_expired) | moment('HH:mm') }}
        </div>
      </div>
      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body">
            <div class="message-body__contents">
              <h3 class="primary">
                {{ ticket.store_name }}
              </h3>
              <p class="secondary">
                <span>{{ lockerBoxInfoText }}</span
                ><br />
                <span>{{ reserveDateTimeText }}</span>
              </p>
            </div>
            <div class="message-body__extras">
              <h3 class="primary">
                {{ $__t('만료사유') }}
              </h3>
              <div class="secondary">{{ $__t('{expired_reason}') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dates from '@/mixins/dates';

export default {
  name: 'MessageExpired',

  props: {
    ticket: {
      type: Object,
      default: {},
    },

    order: {
      type: Object,
      default: {},
    },

    activated: {
      type: Boolean,
      default: false,
    },

    invitation: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [dates],

  data() {
    return {};
  },

  computed: {
    reserveDateTimeText() {
      const ticketExpiration = this.$moment(this.ticket?.ticket_date_start).add(1, 'h');

      return `${this.getDateLabel(ticketExpiration)} ${ticketExpiration.format('HH:mm')}까지 보관 필요`;
    },

    lockerBoxInfoText() {
      return `보관함 ${this.order?.product_data?.locker_number} • ${this.order?.product_data?.locker_size}`;
    },
  },
};
</script>
