<template>
  <div class="subpage" id="host-notices-page">
    <div class="container">
      <div class="page-header">
        <div class="title">{{ $__t('공지사항') }}</div>
      </div>

      <div class="page-body">
        <div class="notice-list" v-if="docs.length > 0">
          <div class="container">
            <ul class="item-list">
              <li class="list-item" :class="{ on: opened.indexOf(doc.post_id) >= 0 }" v-for="doc in docs">
                <router-link :to="{ name: 'HostNoticesSingle', params: { id: doc.post_id } }">
                  <div class="title" @click="toggleItem(doc.post_id)">
                    <div class="container">
                      {{ doc.post_title }}
                      <div class="datetime">{{ doc.created_at | moment('YYYY-MM-DD') }}</div>
                      <i class="fa fa-angle-right"></i>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
            <ui-text-button
              display="block"
              color="brightblue"
              class="list-more"
              @click="getMoreListItems"
              v-if="listLoadable"
            >
              <button type="button">{{ $__t('더 불러오기') }}</button>
            </ui-text-button>
          </div>
        </div>
        <div class="notice-list-empty" v-else>
          <div class="container">
            <div class="title">{{ $__t('No post found') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: [],
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 12,
      },
    };
  },
  computed: {
    docs() {
      return this.$store.getters['resourceHostNotices/GET_ITEMS_BY_LIST']({ list: 'Notices' });
    },
  },
  created() {
    this.getListItems();
  },
  methods: {
    getListItems() {
      this.$store.dispatch('resourceHostNotices/getList', { list: 'Notices', query: this.listQuery }).then((res) => {
        if (this.docs.length == res.data.data.total || res.data.data.items.length == 0) {
          this.listLoadable = false;
        } else {
          this.listLoadable = true;
        }
      });
    },
    getMoreListItems() {
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    },
    toggleItem(index) {
      if (this.opened.indexOf(index) >= 0) {
        this.opened = this.opened.filter((o) => o != index);
      } else {
        this.opened.push(index);
      }
    },
  },
};
</script>
