<template>
  <div class="subpage" id="mypage-tickets">
    <div class="container">
      <div class="ticket-tab">
        <ul>
          <li :class="{ on: filter == 'available' }" @click="showTab(0)" style="text-transform: uppercase">
            {{ $__t("active") }}
          </li>
          <li :class="{ on: filter == 'user-completed' }" @click="showTab(1)" style="text-transform: uppercase">
            {{ $__t("completed") }}
          </li>
          <li :class="{ on: filter == 'cancelled' }" @click="showTab(2)" style="text-transform: uppercase">
            {{ $__t("CANCELLED") }}
          </li>
        </ul>
      </div>

      <div class="ticket-list" v-if="docs.length > 0">
        <div class="container">
          <ul class="item-list">
            <li class="item" v-for="doc in docs">
              <ticket-list-item
                :ticket="doc"
                @click-cancel-reservation="
                  selectTicket(doc);
                  openModal('cancelReservationModal');
                "
              />
            </li>
          </ul>
          <ui-text-button
            display="block"
            color="primary"
            class="list-more"
            @click="getMoreListItems"
            v-if="listLoadable"
          >
            <button type="button">{{ $__t("더 불러오기") }}</button>
          </ui-text-button>
        </div>
      </div>

      <div class="ticket-list-empty" v-if="docs.length == 0 && loading == false">
        <div class="container">
          <div class="title">{{ $__t("Can't find a place to drop off your item?") }}</div>
          <ui-solid-button color="primary" size="small">
            <router-link :to="{ name: 'Stores' }">
              {{ $__t("go keep my item") | capitalizeFirstLetter }}
            </router-link>
          </ui-solid-button>
        </div>
      </div>
    </div>

    <modal ref="cancelReservationModal" id="cancelReservationModal">
      <template v-slot:body>
        <img src="/img/modal-close@3x.png" @click.stop="closeModal('cancelReservationModal')" />
        <reservation-cancel
          :code="selectedTicketCode"
          @close="closeModal('cancelReservationModal')"
          @submit="cancelReservation({ ...selectedTicket, ...{ cancel_reason: $event } })"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import TicketListItem from "@/components/resources/ticket/ForGuest.vue";
import ImageLinkBanner from "@/components/modules/Banner/ImageLinkBanner";
import countryCodes from "@/assets/json/country-codes.json";
import ReservationCancel from "@/components/pages/reservation/ReservationCancel.vue";

export default {
  name: "MyPageTickets",
  components: {
    ticketListItem: TicketListItem,
    ImageLinkBanner,
    ReservationCancel,
  },
  data() {
    let majorCountryCodes = [
      "CA",
      "US",
      "GB",
      "FR",
      "DE",
      "CN",
      "RU",
      "MN",
      "JP",
      "TW",
      "PH",
      "HK",
      "TH",
      "VN",
      "IN",
      "MY",
      "SG",
      "AU",
      "ID",
      "KR",
    ];
    var _code = countryCodes
      .map((o) => {
        return { name: o.name, code: o.code };
      })
      .filter((o) => {
        return majorCountryCodes.includes(o.code);
      });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });

    _code.push({ name: "Others", code: "ETC" });

    return {
      filter: "available",
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 10,
      },
      tickets: [],
      subscription: null,
      subscribed: true,
      primaryCardNum: "",
      showcard: false,

      selectedTicketCode: "",
    };
  },
  computed: {
    docs() {
      return this.$store.getters["tickets/GET_ITEMS_BY_LIST"]({ list: "MyTickets_" + this.filter });
    },
    selectedTicket() {
      return this.$store.getters["tickets/GET_ITEM"]({ key: "ticket_code", value: this.selectedTicketCode });
    },
    loading() {
      return this.$store.getters["loading/GET_STATUS"];
    },
  },
  watch: {
    filter: {
      handler(value) {
        this.getListItems();

        if (value === "cancelled") {
          $(".page-header .container .title").text(this.$__t("Cancellation"));
        } else {
          $(".page-header .container .title").text(this.$__t("Reservation History"));
        }
      },
    },
  },
  created() {
    this.getListItems();
  },
  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },
    clearItems() {
      this.$store.dispatch("tickets/clear", { list: "MyTickets" });
    },
    getListItems(clear) {
      this.$store
        .dispatch("tickets/getList", {
          list: "MyTickets_" + this.filter,
          filter: this.filter,
          query: this.listQuery,
          clear: clear,
        })
        .then((res) => {
          this.$store.commit("loading/SET_FALSE");
          if (this.docs.length == res.data.data.total || res.data.data.items.length == 0) {
            this.listLoadable = false;
          } else {
            this.listLoadable = true;
          }
        });
    },
    getMoreListItems() {
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    },
    showTab(index) {
      this.$store.commit("loading/SET_TRUE");
      switch (index) {
        case 0:
          if (this.filter == "available") {
            this.$store.commit("loading/SET_FALSE");
          }
          this.filter = "available";
          break;
        case 1:
          if (this.filter == "user-completed") {
            this.$store.commit("loading/SET_FALSE");
          }
          this.filter = "user-completed";
          break;
        case 2:
          if (this.filter == "cancelled") {
            this.$store.commit("loading/SET_FALSE");
          }
          this.filter = "cancelled";
          break;
        default:
          this.filter = "available";
          break;
      }
    },
    isExpired(ticket) {
      return ticket.status == "EXPIRED";
    },
    goToStores() {
      this.$router.push({ name: "Stores" });
    },

    selectTicket(doc) {
      this.selectedTicketCode = doc.ticket_code;
    },

    async cancelReservation(options) {
      this.$store.commit("loading/SET_TRUE");
      this.closeModal("cancelReservationModal");

      let res,
        { receipt_id, cancel_reason } = options;

      const params = {
        id: receipt_id,
        cancel_reason_code: cancel_reason.value,
        cancel_reason_detail: cancel_reason.value === "cancel_reason.etc" ? cancel_reason.detail : "",
      };

      try {
        res = await this.$store.dispatch("orders/cancel", params);

        let { data, error } = res.data;

        if (!error) {
          this.$store.commit("alert/ADD_ITEM", { message: this.$__t("예약이 취소되었습니다."), status: "success" });
        }
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      } finally {
        await this.refreshTickets();
        setTimeout(() => this.$store.commit("loading/SET_FALSE"), 100);
      }
    },

    async refreshTickets() {
      try {
        this.$store.commit("tickets/CLEAR_ALL_LIST");
        this.$store.commit("tickets/CLEAR_ITEMS");
        //clear all tickets

        return await this.$store.dispatch("tickets/getList", {
          list: "MyTickets_" + this.filter,
          filter: this.filter,
          query: { page: 1, size: 10 },
        });
      } catch (e) {
        //catch
      }
    },
  },
};
</script>
