<template>
  <div class="how-to-use book">
    <section class="how-to-use__section">
      <div class="image-frame">
        <img src="/img/guide-book-1.png" alt="Guide Booking" class="image" />
      </div>
      <!-- <p class="description">
        <strong>'이용시간 및 물품 개수 설정하기'</strong>버튼을 눌러 이용시간과 물품
        개수를 입력해주세요.
      </p> -->
      <p
        class="description"
        v-html="
          $__t(
            'Click the Set the usage time and number of luggage button to input the usage time and the number of luggage.'
          )
        "
      ></p>
    </section>
    <section class="how-to-use__section">
      <div class="image-frame">
        <img src="/img/guide-book-2.png" alt="Guide Booking" class="image" />
      </div>
      <p
        class="description"
        v-html="
          $__t(
            'After selecting an available store, click the Storage button on the store details page.'
          )
        "
      ></p>
    </section>
    <section class="how-to-use__section">
      <div class="image-frame">
        <img src="/img/guide-book-3.png" alt="Guide Booking" class="image" />
      </div>
      <p
        class="description"
        v-html="
          $__t(
            'After checking the storage information, payment method, and payment details, please click the Reservation button at the bottom.'
          )
        "
      ></p>
    </section>

    <div class="callout-container">
      <p
        class="callout-container__text"
        v-html="
          $__t(
            'To prevent non-use, after the estimated amount is paid in advance at the time of booking, the amount paid in advance will be canceled immediately after completing baggage claim, and the actual amount used will be finally paid.'
          )
        "
      ></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.how-to-use {
  padding: unit(40) unit(16);

  p::v-deep {
    font-size: unit(14);
    line-height: 1.43;

    strong {
      font-weight: bold;
    }
  }

  &__section + &__section {
    margin-top: unit(50);
  }

  .image-frame {
    width: 100%;
    padding-bottom: 100%;

    .image {
      @include fsa;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .description {
    margin-top: unit(20);
    padding-right: unit(24);
  }

  .callout-container {
    margin-top: unit(16);
    background: rgba(0, 143, 255, 0.1);
    border-radius: unit(10);
    padding: unit(20) unit(14);
  }
}
</style>
