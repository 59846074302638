<template>
  <div class="ui-luggage-photo-uploader">
    <ui-image-uploader type="camera" :placeholder="placeholder" :label="label" tag="luggage_photo" @uploaded="setResource" @loading="$emit('loading')"/>
  </div>
</template>

<script>
import UiImageUploader from "@/components/modules/RichForm/ImageUploader";
export default {
  components: {
    UiImageUploader
  },
  props: [ "label", "placeholder", "ticket" ],
  data(){
    return {};
  },
  methods: {
    setResource( resource ){
      this.$store.commit('loading/SET_TRUE');

      try {
        this.axios.put( this.$store.state.config.apiURL + "/v2/manager/tickets/" + this.ticket.ticket_code, { resources: JSON.stringify({ luggage_photo: resource }) }, { headers: this.$store.state.config.userHeaders }).then( res => {
          if( res.data.status == "success" ){
            this.$store.commit( "tickets/SET_ITEM", { data: res.data.data });
            this.$emit('set-resource');
          }else{
            this.$store.commit( "alert/ADD_ITEM", { message: res, status: "error" });
          }
        }).catch( err => {
          this.$log.error(err);
          this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
        }).finally(() => {
          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 1000)
        });
      }
      catch(e) {
        this.$store.commit('loading/SET_FALSE');
      }
    },
  },
}
</script>
