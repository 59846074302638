import loadScript from 'load-script';

const FacebookPixelCode = {
  name: 'FacebookPixelCode',

  init(app, options = {}, callback = function () {}) {
    this._appId = options.appId;
    if (this._appId) {
      // loadScript(`//connect.facebook.net/en_US/fbevents.js`, (error, script) => {
      //   if (error) {
      //     callback(error, script);
      //     return;
      //   }

      fbq('init', this._appId);
      fbq('track', 'PageView');

      const noscript = document.createElement('NOSCRIPT');
      noscript.innerHTML = `<img height="1" width="1" src="https://www.facebook.com/tr?id=${this._appId}&ev=PageView&noscript=1"/>`;
      document.body.appendChild(noscript);
      // callback(error, script);
      // });
    } else {
      callback();
    }
  },

  logTrack(title = 'track', event, ...args) {
    if (this._appId) {
      fbq(title, event, ...args);
    }
  },
};

export default FacebookPixelCode;
