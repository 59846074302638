<template>
  <div id="checkout-page">
    <div
      v-if="loading"
      style="width: 100%; height: 100%; z-index: 10000; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.51)"
    >
      <div
        style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center"
      >
        <i class="fa fa-spinner fa-pulse"></i>
      </div>
    </div>
    <div class="container">
      <div class="header">
        {{ $__t('check out') | capitalizeFirstLetter }}
      </div>
      <div class="body">
        <div class="information">
          <div class="primary">
            {{ $__t('Do you want to check out?') }}
          </div>
          <div class="secondary">
            {{ $__t('Did you visit the store and pick up the item?') }}<br />{{
              $__t("You won't get covered unless you check out before you receive the item")
            }}
          </div>
        </div>
        <div class="section">
          <div class="box">
            <div class="title basic" @click="toggleShowContent" :class="{ expanded: isShowContent }">
              <span class="key"
                >① <strong>{{ $__t('basic charge') }}</strong> ({{ $__t('pre-paid charge') }})</span
              >
              <span class="value">{{ totalBasicPrice.toLocaleString() + $__t('won') }}</span>
              <img src="/img/lang-arrow.png" />
            </div>
            <div class="content" v-show="isShowContent">
              <div v-if="ticket.ticket_quantity">{{ $__t('basic charge') }} 2,000{{ $__t('won') }} x Small {{ ticket.ticket_quantity }}개</div>
              <div v-if="ticket.ticket_quantity">= {{ smallBasicPrice.toLocaleString() + $__t('won') }}</div>
              <div v-if="ticket.ticket_quantity && ticket.ticket_over_quantity">+</div>
              <div v-if="ticket.ticket_over_quantity">
                {{ $__t('basic charge') }} 3,000{{ $__t('won') }} x Large {{ ticket.ticket_over_quantity }}개
              </div>
              <div v-if="ticket.ticket_over_quantity">= {{ largeBasicPrice.toLocaleString() + $__t('won') }}</div>
            </div>
          </div>
          <div class="sub">
            {{
              $__t(
                'Basic fee goes with 2,000 won for Small / 3,000 won for Large, additional fee will be charged 100 won/10min, 150 won/10min per each.'
              )
            }}<img src="/img/question@3x.png" @click="openModal('priceGuideModal')" />
          </div>
        </div>
        <div class="section">
          <div class="box">
            <div class="title expanded">
              <span class="key"
                >② <strong>{{ $__t('additional charge') }}</strong> ({{ $__t('additional-pay charge') }})</span
              >
              <span class="value">{{ totalAdditionalPrice.toLocaleString() + $__t('won') }}</span>
            </div>
            <div class="content">
              <div v-if="ticket.ticket_quantity">
                Small {{ ticket.ticket_quantity }}개 x (100{{ $__t('won') }}/{{ $__t('per 10 minutes') }} x
                <strong>{{ toTenMinutes.toLocaleString() + $__t('m') }}</strong
                >)
              </div>
              <div v-if="ticket.ticket_quantity">= {{ smallAdditionalPrice.toLocaleString() + $__t('won') }}</div>
              <div v-if="ticket.ticket_quantity && ticket.ticket_over_quantity">+</div>
              <div v-if="ticket.ticket_over_quantity">
                Large {{ ticket.ticket_over_quantity }}개 x (150{{ $__t('won') }}/{{ $__t('per 10 minutes') }} x
                <strong>{{ toTenMinutes.toLocaleString() + $__t('m') }}</strong
                >)
              </div>
              <div v-if="ticket.ticket_over_quantity">= {{ largeAdditionalPrice.toLocaleString() + $__t('won') }}</div>
              <div v-if="price.discount" class="price-discounted">
                <span class="price-discounted-blue">{{ $__t('discounted').toUpperCase() }}</span> &nbsp;<strong
                  >-{{ price.discount.toLocaleString() + $__t('won') }}</strong
                >
              </div>
            </div>
          </div>
          <div class="sub">
            {{
              $__t(
                'Additional charge will exists after first 1 hour, price will be set by the hours of usage, 100 won/per 10 minutes for Small, 150 won/per 10 minutes for Large, fixed charge after 8 hours.'
              )
            }}
            <img src="/img/question@3x.png" @click="openModal('priceGuideModal')" />
          </div>
        </div>
        <div class="total">
          <strong>{{ $__t('Additional charge will be paid when you check out.') }}</strong>
        </div>
        <div class="payment">
          {{ $__t('According to real time payment policy, there can be a difference between actual price and price you see now.') }}
        </div>
        <div class="button-area">
          <div class="submit-weighted-button-area">
            <button type="button" class="false" @click="checkoutButtonClicked ? '' : $emit('close')">
              {{ $__t('아니오') }}
            </button>
            <button type="button" class="true" @click="checkoutButtonClicked ? '' : checkout()">
              {{ $__t('예') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <guide-modal id="priceGuideModal" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
      <template v-slot:body>
        <price-guide />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import PriceGuide from '@/components/pages/_/PriceGuide';

export default {
  components: {
    PriceGuide
  },
  props: ['ticket', 'duration', 'price', 'account', 'password'],
  data() {
    return {
      isShowContent: false,
      loading: false,
      checkoutButtonClicked: false
    };
  },
  computed: {
    toTenMinutes() {
      return Math.ceil(this.$moment.duration(this.duration).asMinutes() / 10) * 10 - 60 > 0
        ? Math.ceil(this.$moment.duration(this.duration).asMinutes() / 10) * 10 - 60
        : 0;
    },

    smallBasicPrice() {
      return 2000 * this.ticket.ticket_quantity;
    },

    largeBasicPrice() {
      return 3000 * this.ticket.ticket_over_quantity;
    },

    totalBasicPrice() {
      return 2000 * this.ticket.ticket_quantity + 3000 * this.ticket.ticket_over_quantity;
    },

    smallAdditionalPrice() {
      let durationMinutes = this.duration.asMinutes();
      let durationDays = this.duration.asDays();

      if (durationMinutes - 60 <= 0) {
        // 1시간 이전
        return 0;
      } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
        // 1시간 이후 7시간 30분 이전
        //가격 정책 전
        // return 100 * Math.ceil((durationMinutes - 60) / 10) * this.ticket.ticket_quantity;

        //가격 정책 후
        return 150 * Math.ceil((durationMinutes - 60) / 10) * this.ticket.ticket_quantity;
      } else if (450 < durationMinutes && durationMinutes <= 1440) {
        //7시간 30분 초과 24시간 이하
        // 가격 정책 전
        // return 6000 * this.ticket.ticket_quantity - this.smallBasicPrice;

        //가격 정책 후
        return 8500 * this.ticket.ticket_quantity - this.smallBasicPrice;
      } else if (1440 < durationMinutes) {
        //24시간 초과
        //가격 정책 전
        // return (6000 + Math.ceil(durationDays - 1) * 4000) * this.ticket.ticket_quantity - this.smallBasicPrice;

        //가격 정책 후
        return (8500 + Math.ceil(durationDays - 1) * 5000) * this.ticket.ticket_quantity - this.smallBasicPrice;
      }
    },

    largeAdditionalPrice() {
      let durationMinutes = this.duration.asMinutes();
      let durationDays = this.duration.asDays();

      if (durationMinutes - 60 <= 0) {
        // 1시간 이전
        return 0;
      } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
        // 1시간 이후 7시간 30분 이전
        return 150 * Math.ceil((durationMinutes - 60) / 10) * this.ticket.ticket_over_quantity;
      } else if (450 < durationMinutes && durationMinutes <= 1440) {
        //7시간 30분 초과 24시간 이하
        return 9000 * this.ticket.ticket_over_quantity - this.largeBasicPrice;
      } else if (1440 < durationMinutes) {
        //24시간 초과
        return (9000 + Math.ceil(durationDays - 1) * 6000) * this.ticket.ticket_over_quantity - this.largeBasicPrice;
      }
    },

    totalAdditionalPrice() {
      return this.price.total - this.totalBasicPrice;
    },

    discountedPrice() {
      return this.price.discount;
    }
  },
  methods: {
    toggleShowContent() {
      this.isShowContent = !this.isShowContent;
    },
    checkout() {
      if (this.checkoutButtonClicked) return;
      this.loading = true;
      this.checkoutButtonClicked = true;

      setTimeout(async () => {
        const payload = {
          code: this.ticket.ticket_code,
          data: {
            account: this.account,
            password: this.password
          }
        };
        try {
          let res = '';
          if (payload.data && payload.data.account && payload.data.password) {
            res = await this.$store.dispatch('tickets/checkoutbyShare', payload);
          } else {
            res = await this.$store.dispatch('tickets/checkoutAfter', payload);
          }

          this.$analytics.logTrack('track', 'Purchase', { currency: 'KRW', value: this.totalAdditionalPrice });

          this.$emit('success');
        } catch (e) {
          this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
        } finally {
          this.loading = false;
          this.checkoutButtonClicked = false;
        }
      }, 1000);
    },
    openModal(ref) {
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    }
  }
};
</script>
