<template>
  <div class="subpage" id="mypage-change-password">
    <div class="container">

      <div class="form">
        <div class="form-header">
          <div class="title">{{ $__t( "비밀번호 변경하기" ) }}</div>
        </div>
        <div class="form-body">
          <ui-form @submit="submitForm" :formData="formData" ref="form">
            <ui-form-list>

              <ui-form-list-item>
                <ui-textbox :placeholder="formData.password.placeholder" :type="formData.password.type" :label="formData.password.label" :error="formData.password.error" v-model="formData.password.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox :placeholder="formData.new_password.placeholder" :type="formData.new_password.type" :label="formData.new_password.label" :error="formData.new_password.error" v-model="formData.new_password.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox :placeholder="formData.new_password_confirm.placeholder" :type="formData.new_password_confirm.type" :label="formData.new_password_confirm.label" :error="formData.new_password_confirm.error" v-model="formData.new_password_confirm.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-solid-button display="block"><button type="submit"><i class="fa fa-envelope"></i>{{ $__t( "비밀번호 변경하기" ) }}</button></ui-solid-button>
              </ui-form-list-item>

            </ui-form-list>
          </ui-form>
        </div>
        <div class="form-footer">
          <p><router-link :to="{ name: 'MypageProfile' }">{{ $__t( "프로필 관리로 돌아가기" ) }}</router-link></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    var formData = {
      password: {
        type: "password",
        label: this.$__t( "비밀번호" ),
        placeholder: this.$__t( "로그인 비밀번호를 입력해주세요" ),
        validation: [
          { type: "required" },
        ],
        value: "",
        error: ""
      },
      new_password: {
        type: "password",
        label: this.$__t( "새 비밀번호" ),
        placeholder: this.$__t( "새 비밀번호를 입력해주세요" ),
        validation: [
          { type: "required" },{type: 'passwordLength'}
        ],
        value: "",
        error: ""
      },
      new_password_confirm: {
        type: "password",
        label: this.$__t( "새 비밀번호 확인" ),
        placeholder: this.$__t( "새 비밀번호를 한 번 더 입력해주세요" ),
        validation: [
          { type: "matchField:new_password" },
        ],
        value: "",
        error: ""
      },
    };
    return {
      formData
    };
  },
  methods: {
    submitForm( values ){
      this.$store.dispatch( "auth/password", values ).then( res => {
        this.$refs.form.resetForm();
        this.$router.replace({ name: "MypageProfile" });
        this.$store.commit( "alert/ADD_ITEM", { message: "비밀번호가 설정되었습니다.", status: "success" });
      }).catch( err => {
        if(err.data.error == 'INVALID_AUTH'){
          this.formData.password.error = this.$__t('Invalid password');
        }
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
  }
}
</script>
