<template>
  <div class="ui-store for-guest">
    <interest-toggle :store="store" size="large" />
    <router-link class="container" :to="{ name: 'StoreSingle', params: { store_id: store.id }}">
      <div class="image" :style="'background-image:url('+( store.resources.thumbnail_image ? (store.resources.thumbnail_image.resource_thumbnail_url || store.resources.thumbnail_image.resource_url) : '/img/no-image.png' )+')'"></div>
      <div class="text">
        <div class="name">{{ localed(store.store_name_locales) || store.store_name }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: [ "store" ],
  computed:{

  },
  methods: {
    localed( locales ){
      const lang = this.$store.state.lang;

      switch(lang){
        case 'ko':
          return locales.ko;
        break;
        case 'en':
          return locales.base || locales.ko;
        break;
        case 'cn':
          return locales.cn || locales.base || locales.ko;
        break;
        case 'hk':
          return locales.hk || locales.base || locales.ko;
        break;
        case 'jp':
          return locales.jp || locales.base || locales.ko;
        break;
      }

    }
  }
}
</script>
