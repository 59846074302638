<template>
  <div id="mypage-ticket-single-usage">
    <div class="container">
      <div class="store" v-if="doc.ticket_type === 'LUGGAGE'">
        <img
          class="store-image"
          :src="
            doc.store_resources.thumbnail_image.resource_thumbnail_url ||
            doc.store_resources.thumbnail_image.resource_url
          "
          alt="상점 사진"
        />
        <div class="store-info">
          <div class="store-name">
            {{ doc.store_name }}
          </div>
          <div class="store-address">{{ storeAddress }}</div>
        </div>
      </div>

      <div class="section no-shadow" v-if="doc.ticket_type == 'DELIVERY'">
        <div class="container">
          <div class="title">
            {{ $__t("Usage") }}
          </div>
        </div>
      </div>

      <div
        class="section no-shadow bg-color-gray place-ticket"
        v-if="doc.ticket_type === 'DELIVERY'"
      >
        <div class="ui-delivery-place-ticket">
          <div class="container">
            <div class="delivery-place-ticket">
              <div class="container">
                <div class="body">
                  <div class="title">
                    {{ $__t("{delivery-step3}.place_ticket.title.origin") }}
                  </div>
                  <div class="place-name">
                    {{
                      doc.product_data.sender_store_name ||
                      doc.product_data.sender_place_name ||
                      ""
                    }}
                  </div>
                  <div class="place-address-area">
                    {{
                      doc.product_data.sender_address +
                      doc.product_data.sender_address_detail
                    }}
                  </div>
                </div>
                <div class="footer">
                  <div class="datetime-area">
                    <div class="date">
                      {{ parseDateTime(doc.datetime_check_in).date }}
                    </div>
                    <div class="time">
                      {{ parseDateTime(doc.datetime_check_in).time }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="delivery-place-ticket">
              <div class="container">
                <div class="body">
                  <div class="title">
                    {{
                      $__t("{delivery-step3}.place_ticket.title.destination")
                    }}
                  </div>
                  <div class="place-name">
                    {{
                      doc.product_data.recipient_store_name ||
                      doc.product_data.recipient_place_name ||
                      ""
                    }}
                  </div>
                  <div class="place-address-area">
                    {{
                      doc.product_data.recipient_address +
                      doc.product_data.recipient_address_detail
                    }}
                  </div>
                </div>
                <div class="footer">
                  <div class="datetime-area">
                    <div class="date">
                      {{ parseDateTime(doc.datetime_check_out).date }}
                    </div>
                    <div class="time">
                      {{ parseDateTime(doc.datetime_check_out).time }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section" v-if="doc.ticket_type === 'DELIVERY'">
        <div class="container">
          <dl class="primary">
            <dt>{{ $__t("{delivery-step2}.title") }}</dt>
          </dl>
          <dl class="secondary">
            <dt>
              {{ $__t("{usage-info}.delivery.key.type") }}
            </dt>
            <dd>
              {{ $__t("{usage-info}.delivery_type.quick") }}
            </dd>
          </dl>
          <dl>
            <dt>
              {{ $__t("{usage-info}.delivery.key.company") }}
            </dt>
            <dd>
              {{ courier.delivery_company }}
            </dd>
          </dl>
          <dl>
            <dt>
              {{ $__t("{usage-info}.delivery.key.delivery_guy") }}
            </dt>
            <dd>
              {{ courier.courier_name }}
            </dd>
          </dl>
          <dl>
            <dt>
              {{ $__t("{usage-info}.delivery.key.contact") }}
            </dt>
            <dd>
              {{ courier.courier_phone }}
            </dd>
          </dl>
        </div>
      </div>

      <div class="section" v-if="doc.ticket_type === 'LUGGAGE'">
        <div class="container border-top">
          <dl class="primary">
            <dt>{{ $__t("이용 정보") }}</dt>
          </dl>
          <dl class="secondary">
            <dt>
              {{ $__t("{usage-info}.key.reserved_time") }}
            </dt>
            <dd>
              {{ doc.ticket_date_start | moment("MM-DD HH:mm") }} ~
              {{ doc.ticket_date_end | moment("MM-DD HH:mm") }}
            </dd>
          </dl>
          <dl>
            <dt>
              {{ $__t("{usage-info}.key.usage_time") }}
            </dt>
            <dd>
              {{ doc.datetime_check_in | moment("MM-DD HH:mm") }} ~
              {{ doc.datetime_check_out | moment("MM-DD HH:mm") }}
            </dd>
          </dl>
        </div>
      </div>

      <div class="section">
        <div class="container">
          <dl class="primary">
            <dt>{{ $__t("{usage-info}.key.item_info") }}</dt>
          </dl>
          <dl v-if="doc.ticket_quantity" class="secondary">
            <dt>Small</dt>
            <dd>
              {{
                doc.ticket_quantity +
                (doc.ticket_quantity > 1 ? $__t("items") : $__t("item"))
              }}
            </dd>
          </dl>
          <dl>
            <dt>Large</dt>
            <dd>
              {{
                doc.ticket_over_quantity +
                (doc.ticket_over_quantity > 1 ? $__t("items") : $__t("item"))
              }}
            </dd>
          </dl>
          <dl v-if="doc.ticket_type === 'DELIVERY'">
            <dt>
              {{ $__t("{usage-info}.key.item_content") }}
            </dt>
            <dd>{{ doc.product_data.luggage_name }}</dd>
          </dl>
        </div>
      </div>

      <div
        class="section"
        :class="{ 'no-shadow': doc.ticket_type === 'LUGGAGE' }"
      >
        <div class="container">
          <dl class="primary">
            <dt>
              {{ $__t("{usage-info}.key.total_payment") }}
              <button
                type="button"
                @click="showSlip('base')"
                v-if="
                  doc.ticket_type == 'DELIVERY' && receipt && receipt.base_tid
                "
              >
                <img src="/img/icon-receipt-blue.png" />
              </button>
            </dt>
            <dd class="color-primary">{{ paidTotal }}</dd>
          </dl>
          <dl class="secondary" v-if="doc.ticket_type === 'LUGGAGE'">
            <dt>
              {{ $__t("basic charge") | capitalizeFirstLetter }}
              <button
                type="button"
                @click="showSlip('base')"
                v-if="
                  receipt &&
                  receipt.base_tid &&
                  receipt.base_tid != 0 &&
                  doc.paid_total >
                    doc.ticket_quantity * 2500 + doc.ticket_over_quantity * 3000
                "
              >
                <img src="/img/icon-receipt-blue.png" />
              </button>
            </dt>
            <dd>{{ basicLuggageCharge }}</dd>
          </dl>
          <dl v-if="doc.ticket_type === 'LUGGAGE'">
            <dt>
              {{ $__t("Additional Charge") | capitalizeFirstLetter }}
              <button
                type="button"
                @click="showSlip('extra')"
                v-if="receipt && receipt.extra_tid && receipt.extra_tid != 0"
              >
                <img src="/img/icon-receipt-blue.png" />
              </button>
            </dt>
            <dd>{{ additionalLuggageCharge }}</dd>
          </dl>
          <dl v-if="doc.ticket_type === 'LUGGAGE'">
            <dt>
              {{ $__t("{usage-info}.discount_coupon.total") }}
            </dt>
            <dd
              :class="{
                'color-red': doc.order && doc.order.promotion_coupon_code,
              }"
            >
              {{
                doc.order && doc.order.promotion_coupon_code
                  ? "- " +
                    doc.order.payment_discount.toLocaleString() +
                    $__t("won")
                  : 0 + $__t("won")
              }}
            </dd>
          </dl>
          <dl v-if="doc.ticket_type === 'LUGGAGE'">
            <dt>
              {{ $__t("{usage-info}.discount_code.total") }}
            </dt>
            <dd
              :class="{
                'color-red': doc.order && doc.order.promotion_discount_code,
              }"
            >
              {{
                doc.order && doc.order.promotion_discount_code
                  ? "- " +
                    doc.order.payment_discount.toLocaleString() +
                    $__t("won")
                  : 0 + $__t("won")
              }}
            </dd>
          </dl>
        </div>
      </div>

      <div
        class="section no-shadow status"
        v-if="doc.ticket_type === 'DELIVERY'"
      >
        <div class="container">
          <ui-border-button display="block" color="brightblue">
            <button type="button">
              {{ $__t("{usage-info}.button.delivery.status") }}
              <img src="/img/arrow-blue-right.png" />
            </button>
          </ui-border-button>
        </div>
      </div>
    </div>
    <!-- container -->

    <guide-modal
      id="creditCardSlipModal"
      ref="creditCardSlipModal"
      name="creditCardSlipModal"
    >
      <template v-slot:body>
        <credit-card-slip :data="transaction" />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import CreditCardSlip from "@/components/pages/_/CreditCardSlip";

export default {
  props: ["code"],

  data() {
    return {
      transaction: "",
      courier: "",
    };
  },

  computed: {
    doc() {
      return this.$store.getters["tickets/GET_ITEM"]({
        key: "ticket_code",
        value: this.code,
      });
    },
    receipt() {
      return this.$store.getters["orders/GET_ITEM"]({
        key: "order_id",
        value: this.doc.receipt_id,
      });
    },
    paidTotal() {
      return this.doc.paid_total
        ? this.doc.paid_total.toLocaleString() + this.$__t("won")
        : 0 + this.$__t("won");
    },
    basicLuggageCharge() {
      const price =
        this.doc.ticket_quantity * 2000 + this.doc.ticket_over_quantity * 3000;

      return price.toLocaleString() + this.$__t("won");
    },
    additionalLuggageCharge() {
      if (!this.receipt?.payment_origin) return;

      const price =
        this.receipt.payment_origin -
        this.doc.ticket_quantity * 2000 -
        this.doc.ticket_over_quantity * 3000;

      return price.toLocaleString() + this.$__t("won");
    },
    storeAddress() {
      const lang = this.$store.state.lang;
      let address = this.doc.store_address;
      let address_detail = this.doc.store_address_detail;

      if (address_detail) {
        if (lang == "ko") {
          address +
            " " +
            address_detail.ko +
            ", " +
            (address_detail.floor === "-1"
              ? "지하 1층"
              : address_detail.floor + "층");
        } else {
          address_detail.floor + "F, " + address_detail.base + address;
        }
      }

      return address;
    },
  },

  async created() {
    if (this.doc.issue_type == "DELIVERY") {
      await this.getCourier();
    }

    if (!this.receipt) {
      this.getReceipt();
    }
  },

  watch: {
    selectedReason(value) {
      this.formData.cancel_reason.etc.error = "";
    },
  },

  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    showSlip(which) {
      switch (which) {
        case "basic":
          this.openModal("creditCardSlipModal");
          break;

        case "additional":
          this.openModal("creditCardSlipModal");
          break;
      }
    },

    parseDateTime(datetime) {
      let value = this.$moment(datetime).format("YYYY-MM-DD_HH:mm");

      value = value.split("_");
      return {
        date: value[0],
        time: value[1],
      };
    },

    searchOnGoogleMap(address) {
      const addressEncoded = encodeURI(address);

      if (this.wni.isNative) {
        this.wni.execute("wnOpenURL", {
          url: `https://www.google.com/maps/search/?api=1&query=${addressEncoded}`,
        });
      } else {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${addressEncoded}`,
          "_blank"
        );
      }
    },

    searchOnNaverMap(url) {
      const encodedUrl = encodeURI(url);

      if (this.wni.isNative) {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          url =
            url.replace("nmap://", "intent://") +
            "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
          this.wni.execute("wnOpenURL", { url });
        }
      } else {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          url =
            url.replace("nmap://", "intent://") +
            "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
          location.href = url;
        } else {
          location.href = url;
        }
      }
    },

    openNaverMapInIphoneBrowser(url) {
      this.$store.commit("loading/SET_TRUE");

      window.location.href = url;

      setTimeout(() => {
        try {
          if (window.location.href.indexOf("nmap") < 0) {
            throw new Error("NO_EXISTS_DATA");
          }
        } catch (e) {
          let confirmed = confirm(
            this.$__t("Naver map is not installed, so go to the App Store.")
          );

          if (confirmed) {
            location.href = "http://itunes.apple.com/app/id311867728?mt=8";
          }
        } finally {
          this.$store.commit("loading/SET_FALSE");
        }
      }, 0);
    },

    submitForm() {
      if (!this.selectedReason) {
        this.openModal("selectReasonInformModal");
        return;
      }

      if (
        this.selectedReason === "cancel_reason.etc" &&
        !this.formData.cancel_reason.etc.detail
      ) {
        this.formData.cancel_reason.etc.error = this.$__t(
          "__VALIDATION_ERROR_REQUIRED"
        );
        return;
      } else {
        this.formData.cancel_reason.etc.error = "";
      }

      this.$emit("submit", this.formData.cancel_reason[this.selectedReason]);
    },

    async showSlip(which) {
      let res;

      try {
        res = await this.$store.dispatch("billings/getTransaction", {
          id: this.receipt[which + "_tid"],
        });

        this.$data.transaction = res;

        this.openModal("creditCardSlipModal");
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e),
          status: "error",
        });
      }
    },

    async getCourier() {
      try {
        let res = await this.$store.dispatch("resourceDelivery/getCourier", {
          code: this.code,
        });
        this.$data.courier = res;
      } catch (e) {}
    },

    getReceipt() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("orders/order", { id: this.doc.receipt_id })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
  },

  components: {
    "credit-card-slip": CreditCardSlip,
  },
};
</script>
