<template>
  <popup class="resource popup-user-guide" id="popup-user-guide" ref="popup-user-guide" :arrow="true" :noAppear="true" name="userGuideModal">
    <template v-slot:body>
      <div class="user-guide">
        <div class="delay-message">
          <div class="title">📌 카카오톡 채널 고객센터 운영중단</div>
          카카오 데이터 센터 화재로 인해 카카오톡 채널을 이용한 고객센터 운영이 잠시 중단됩니다😭 <br />
          문의하실 내용은 채널톡 '럭스테이' 를 이용해 남겨주시면 빠른 처리 도와드릴게요!
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/v3/components/common/modal/Popup.vue';

export default {
  components: {
    Popup
  },
  computed: {
    isRouterChanged() {
      return this.$store.state.path.isRouterChanged;
    },
    isOpenedModal() {
      return this.$store.getters['userData/GET_ITEM']({ name: 'userGuideModal', noDefault: true });
    }
  },
  methods: {
    openUserGuideModal() {
      if (!this.isOpenedModal && this.isRouterChanged) {
        this.$refs['popup-user-guide'].open('popup-user-guide');
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.openUserGuideModal();
    }, 1000);
  }
};
</script>

<style lang="scss">
.resource.popup-user-guide {
  padding: unit(16);
  .delay-message {
    padding: 0 unit(16);
  }
}
</style>
