<template>
  <div id="ticket-single-page" class="page">
    <div style="visibility: hidden; z-index: -1; height: 0">
      <input
        type="text"
        id="clipboard-store-address"
        :value="ticket ? ticket.store_address : null"
        readonly
      />
    </div>

    <div class="container">
      <div class="page-header" ref="header">
        <div class="page-header__button-area">
          <button v-button type="button" @click="onBack" class="page-header__button">
            <img src="/img/icon/close.svg" />
          </button>
        </div>
        <div class="page-header__text-area">
          <template v-if="loaded">
            <transition name="fade" mode="out-in">
              <div :key="0">
                <div class="text-area">
                  <h1 class="text--primary">
                    {{ headerPrimaryText }}
                  </h1>
                  <p class="text--secondary" v-html="headerSecondaryText" />
                </div>
                <div
                  class="ticket-usage"
                  :class="{
                    activated:
                      ticket.status === 'CHECK_IN' || ticket.status === 'CHECK_OUT',
                    'activated--pass':
                      order &&
                      order.promotion_pass_code &&
                      passDuration > 0 &&
                      ticket.status === 'CHECK_IN',
                  }"
                >
                  <div class="ticket-usage__text-area">
                    <div class="container">
                      <div class="ticket-usage__time">
                        {{ headerTicketUsageTimeText }}
                      </div>
                      <div class="ticket-usage__price">
                        <strong>{{ headerTicketUsagePriceText }}</strong
                        >{{ $__t("won") }}
                      </div>
                    </div>
                    <!-- <div class="container" v-if="order && order.promotion && order.promotion.pass_date_start">
                      <span
                        v-if="
                          $moment() > $moment(order.promotion.pass_date_start) &&
                          $moment() < $moment(order.promotion.pass_date_end) &&
                          ticket.status === 'CHECK_IN'
                        "
                        class="ticket-usage__explanatory"
                        >프리패스 이용권 적용중</span
                      >
                      <span v-else class="ticket-usage__explanatory--red">프리패스 이용권 적용중</span>
                    </div> -->
                  </div>
                  <div class="ticket-usage__bar-area">
                    <div class="status-bar" :class="ticket.status">
                      <ul class="status-bar__list">
                        <li
                          class="status-bar__item item--ISSUED"
                          :class="{
                            activated: ticket.status === 'ISSUED',
                            [ticket.status]: true,
                          }"
                        >
                          <div
                            class="status-label"
                            :class="{
                              activated: ticket.status === 'ISSUED',
                            }"
                          >
                            {{
                              order &&
                              order.product_data &&
                              order.product_data.store_type === "LOCKER" &&
                              order.product_data.order_type === "IMMEDIATE"
                                ? "배정"
                                : $__t("예약")
                            }}
                          </div>
                        </li>
                        <li
                          class="status-bar__item item--CHECK_IN"
                          :class="{
                            activated: ticket.status === 'CHECK_IN',
                            [ticket.status]: true,
                          }"
                        >
                          <div
                            class="status-label"
                            :class="{ activated: ticket.status === 'CHECK_IN' }"
                          >
                            {{ $__t("{store-list-item-for-map}.button.storage") }}
                          </div>
                        </li>
                        <li
                          class="status-bar__item item--CHECK_OUT"
                          :class="{
                            activated: ticket.status === 'CHECK_OUT',
                            [ticket.status]: true,
                          }"
                        >
                          <div
                            class="status-label"
                            :class="{
                              activated: ticket.status === 'CHECK_OUT',
                            }"
                          >
                            {{ $__t("찾기") }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </template>
          <template v-else>
            <transition name="fade" mode="out-in">
              <div :key="1" class="text-area">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
            </transition>
          </template>
        </div>
      </div>
      <div class="page-body">
        <ul class="ticket-message__list">
          <template v-if="loaded">
            <transition-group tag="ul" name="fade" mode="out-in">
              <li
                class="ticket-message__list-item"
                v-for="(_component, index) in messageComponents"
                :key="_component"
              >
                <component
                  :is="_component"
                  :ticket="ticket"
                  :order="order"
                  :class="{ activated: index === messageComponents.length - 1 }"
                  :invitation="invitation"
                  :checkInConfirmed="isLockerCheckInConfirmed"
                  :cancelAvailable="ticket?.cancel_available"
                  @open-camera="onScanned"
                  @open-edit="openRef('editModal')"
                  @open-find="openRef('findModal')"
                  @open-invite="openRef('invite-slider')"
                  @open-checkout="
                    $analytics.logEvent('pick up button');
                    openRef('check-out-slider');
                  "
                  @fetch-invitation="fetchInvitation"
                  @locker-check-in="lockerCheckIn"
                  @locker-open="lockerOpen"
                  @locker-cancel="lockerCancel"
                />
              </li>
            </transition-group>
          </template>
          <template v-else>
            <transition-group name="fade" mode="out-in">
              <div :key="1">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
              <div :key="2" style="margin-top: 2rem">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
              <div :key="3" style="margin-top: 2rem">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
            </transition-group>
          </template>
        </ul>
      </div>

      <div class="page-footer" v-if="ticket && ticket.status">
        <div
          id="check-out-alert-balloon"
          ref="check-out-alert-balloon"
          class="page-footer__balloon alert-balloon hidden"
        >
          <div class="content">
            <img src="/img/icon/megaphone.svg" class="content__icon" />
            <span class="content__text">{{
              $__t("물품 보관 완료 10분 뒤부터 사용가능합니다.")
            }}</span>
          </div>
        </div>

        <div
          v-if="isLocker && ticket.status === 'CHECK_IN' && !isLockerCheckInConfirmed"
          id="locker-temp-open-alert-balloon"
          ref="locker-temp-open-alert-balloon"
          class="page-footer__balloon alert-balloon"
        >
          <div class="content">
            <span class="content__text"
              >❗️ {{ checkInConfirmExpireDurationText }} 동안 임시 재오픈
              가능합니다.</span
            >
          </div>
        </div>

        <div class="button-area">
          <template v-if="isLocker">
            <button
              v-if="ticket.status === 'ISSUED'"
              type="button"
              class="ui-submit-button activated"
              @click="openRef('locker-box-selector-slider')"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
            >
              <span class="ui-submit-button__text"> {{ $__t("보관함 열기") }} </span>
            </button>
            <button
              v-else-if="ticket.status === 'CHECK_IN'"
              type="button"
              class="ui-submit-button activated singlebutton"
              @click="
                isLockerCheckInConfirmed
                  ? (() => {
                      $analytics.logEvent('pick up button');
                      openRef('check-out-slider');
                    })()
                  : lockerOpen()
              "
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
            >
              <span class="ui-submit-button__text"> {{ $__t("보관함 열기") }} </span>
            </button>
            <button
              v-else
              type="button"
              class="ui-submit-button activated singlebutton"
              @click="callCustomerService"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
            >
              <span class="ui-submit-button__text"> {{ $__t("C/S") }} </span>
            </button>
          </template>
          <template v-else>
            <!-- //here -->
            <button
              v-if="
                ticket && ticket.ticket_type === 'LUGGAGE' && ticket.status === 'ISSUED'
              "
              type="button"
              class="activated storeid"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
              @click="openToCheckInWithoutQr"
            >
              <span class="">
                <template>{{ $__t("QR스캔이 안되나요?") }}</template>
              </span>
            </button>
            <button
              v-else-if="
                ticket && ticket.ticket_type === 'LUGGAGE' && ticket.status === 'CHECK_IN'
              "
              type="button"
              class="activated storeid"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
              @click="openToCheckOutWithoutQr"
            >
              <span class="">
                <template>{{ $__t("QR스캔이 안되나요?") }}</template>
              </span>
            </button>

            <!-- //here -->
            <button
              v-if="
                ticket &&
                ticket.ticket_type === 'LUGGAGE' &&
                (ticket.status === 'ISSUED' || ticket.status === 'CHECK_IN')
              "
              type="button"
              class="ui-submit-button activated"
              :class="{ scan: ticket.store_id === 3592 || ticket.store_id === 1651 }"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
              @click="openQrCodeReader"
            >
              <!-- <i class="fas fa-qrcode"></i> -->
              <span class="ui-submit-button__text">
                <template v-if="ticket.status === 'ISSUED'">
                  {{ $__t("QR 스캔하여 물품 보관") }}
                </template>
                <template v-else-if="ticket.status === 'CHECK_IN'">
                  {{ $__t("QR 스캔하여 물품 찾기") }}
                </template>
              </span>
            </button>

            <button
              v-else
              type="button"
              class="ui-submit-button activated singlebutton"
              @click="callCustomerService"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
            >
              <span class="ui-submit-button__text"> {{ $__t("C/S") }} </span>
            </button>
          </template>
        </div>
      </div>
    </div>

    <guide-modal id="condition-modal" ref="editModal" :transparent="true">
      <template v-slot:body>
        <ticket-edit
          id="ticket-edit"
          @close-modal="closeRef('editModal')"
          :order="order"
          @fetch-ticket="fetchTicket"
          @fetch-order="fetchOrder"
        />
      </template>
    </guide-modal>

    <ui-slider id="invite-slider" ref="invite-slider" :bgClick="true">
      <ticket-invite
        @fetch-invitation="fetchInvitation"
        @close-modal="closeRef('invite-slider')"
      />
    </ui-slider>

    <ui-slider
      id="check-in-without-qr-slider"
      ref="check-in-without-qr-slider"
      :bgClick="true"
    >
      <checkin-without-qr
        :ticket="ticket"
        @status="onCheckInStatus"
        @close="closeRef('check-in-without-qr-slider')"
      />
    </ui-slider>

    <ui-slider
      id="check-in-upload-image-slider"
      ref="check-in-upload-image-slider"
      :bgClick="true"
    >
      <ticket-check-in
        :ticket="ticket"
        @close="closeRef('check-in-upload-image-slider')"
        @fetch-ticket="fetchTicket"
        @fetch-order="fetchOrder"
      />
    </ui-slider>

    <ui-slider
      id="check-out-without-qr-slider"
      ref="check-out-without-qr-slider"
      :bgClick="true"
    >
      <checkout-without-qr
        :ticket="ticket"
        @close="closeRef('check-out-without-qr-slider')"
        @check-out="openToCheckOut"
      />
    </ui-slider>

    <ui-slider id="check-out-slider" ref="check-out-slider">
      <ticket-check-out
        @close-slider="closeRef('check-out-slider')"
        @fetch-ticket="fetchTicket"
        @fetch-order="fetchOrder"
        :duration="usageDuration"
        :ticket="ticket"
        :order="order"
      ></ticket-check-out>
    </ui-slider>

    <guide-modal
      id="find-modal"
      ref="findModal"
      :noCloseButton="true"
      :noBgClick="true"
      :external-close="true"
    >
      <template v-slot:body>
        <div class="include-modal">
          <div class="include-modal__header">
            <div class="include-modal__header--primary">
              {{ isLocker ? $__t("라커 찾기") : $__t("상점 찾기") }}
            </div>
            <div class="include-modal__header--secondary">
              {{
                ticket.status === "ISSUED" && !isLocker
                  ? $__t("보관하실 상점 위치를 확인해보세요.")
                  : ""
              }}
            </div>
          </div>
          <div class="include-modal__body">
            <div class="map-button table fixed">
              <ul class="map-button__list table-row">
                <li class="map-button__item table-cell middle">
                  <button
                    v-button
                    type="button"
                    @click="onClickMapAction('google')"
                    class="circle-button"
                  >
                    <img src="/img/button/find-google-map.svg" />
                  </button>
                </li>
                <li
                  v-if="$store.state.config.isTouchDevice"
                  class="map-button__item table-cell middle"
                >
                  <button
                    v-button
                    type="button"
                    @click="onClickMapAction('naver')"
                    class="circle-button"
                  >
                    <img src="/img/button/find-naver-map.svg" />
                  </button>
                </li>
                <li class="map-button__item table-cell middle text-button">
                  <button
                    type="button"
                    v-button
                    @click="onClickMapAction('copy')"
                    data-clipboard-target="#clipboard-store-address"
                    class="circle-button copy-btn"
                  >
                    <div class="text">{{ $__t("주소복사") }}</div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </guide-modal>

    <ui-slider
      id="locker-box-selector-slider"
      ref="locker-box-selector-slider"
      :bgClick="true"
    >
      <h1 class="locker-box-selector__header">
        {{ order && order.product_data ? order.product_data.locker_number : null }}번
        보관함을 여시겠습니까?
      </h1>
      <LockerBoxSelector
        :value="reservedLockerBox"
        :data="lockerList"
        @close="closeRef('locker-box-selector-slider')"
        @locker-check-in="lockerCheckIn"
        :readonly="true"
        class="locker-box-selector"
      />
      <div class="locker-box-selector__button-area">
        <button
          type="button"
          class="btn btn--close"
          @click="closeRef('locker-box-selector-slider')"
        >
          {{ $__t("아니오") }}
        </button>
        <button type="button" class="btn btn--submit activated" @click="lockerCheckIn">
          {{ $__t("예") }}
        </button>
      </div>
    </ui-slider>
  </div>
</template>

<script>
import MessageLockerIssued from "@/components/includes/TicketSingle/MessageLockerIssued.vue";
import MessageLockerExpired from "@/components/includes/TicketSingle/MessageLockerExpired.vue";
import MessageLockerCheckIn from "@/components/includes/TicketSingle/MessageLockerCheckIn.vue";
import MessageLockerCheckOut from "@/components/includes/TicketSingle/MessageLockerCheckOut.vue";

import MessageFreePass from "@/components/includes/TicketSingle/MessageFreePass.vue";

import MessageIssued from "@/components/includes/TicketSingle/MessageIssued";
import MessageCheckIn from "@/components/includes/TicketSingle/MessageCheckIn";
import MessageCheckOut from "@/components/includes/TicketSingle/MessageCheckOut";
import MessageCancelled from "@/components/includes/TicketSingle/MessageCancelled";
import MessageExpired from "@/components/includes/TicketSingle/MessageExpired";
import MessageInvited from "@/components/includes/TicketSingle/MessageInvited";

import TicketInvite from "@/components/includes/TicketSingle/InviteForm.vue";
import TicketCheckOut from "@/components/includes/TicketSingle/CheckOutForm.vue";
import TicketCheckIn from "@/components/includes/TicketSingle/TicketCheckIn.vue";
import CheckinWithoutQr from "@/components/includes/TicketSingle/CheckinWithoutQr.vue";
import CheckoutWithoutQr from "@/components/includes/TicketSingle/CheckoutWithoutQr.vue";

import CheckOutUnavailable from "@/components/includes/TicketSingle/CheckOutUnavailable.vue";

import LockerBoxSelector from "@/components/resources/locker/LockerBoxSelector.vue";

import utils from "@/utils";
import UiSlider from "@/components/modules/Slider/Body.vue";
import _ from "lodash";

import Debug from "debug";
const debug = Debug("lugstay:web:components:pages:v2:TicketSingle");

export default {
  name: "TicketSingle",

  props: ["id"],

  data() {
    return {
      loaded: false,
      ticket: {},

      order_id: 0,
      invitation: [],

      moment_datetime_now: null,
      fetchTimer: null,
      usageTimer: null,

      usageDuration: 0,

      lockerPrice: 0,
      price: 0,

      query: {
        from: this.$route.query.from,
        method: this.$route.query.method,
        action: this.$route.query.action,
      },
    };
  },

  computed: {
    // ticket() {
    //   return this.$store.getters['tickets/GET_ITEM']({ key: 'ticket_code', value: this.id });
    // },

    order() {
      return this.$store.getters["orders/GET_ITEM"]({
        key: "order_id",
        value: this.ticket?.receipt_id,
      });
    },

    store() {
      return this.$store.getters["stores/GET_ITEM"]({
        key: "id",
        value: this.ticket?.store_id,
      });
    },

    headerPrimaryText() {
      let text = "";

      switch (this.ticket?.status) {
        case "EXPIRED":
          text = this.$__t("{ticket-single}.header-primary.EXPIRED");
          break;
        case "CANCELLED":
          text = this.$__t("{ticket-single}.header-primary.CANCELLED");
          break;
        case "ISSUED":
          text = this.$__t("{ticket-single}.header-primary.COMPLETE");

          //temp
          if (
            this.order?.product_data?.store_type === "LOCKER" &&
            this.order?.product_data?.order_type === "IMMEDIATE"
          ) {
            text = this.$__t("{ticket-single}.header-primary.LOCKER");
          }
          break;
        case "CHECK_IN":
          text = this.$__t("{ticket-single}.header-primary.CHECK_IN");
          //temp
          if (
            this.order?.product_data?.store_type === "LOCKER" &&
            !this.isLockerCheckInConfirmed
          ) {
            text = this.$__t("{ticket-single}.header-primary.LOCKER_ACTIVATED");
          }
          break;
        case "CHECK_OUT":
          text = this.$__t("{ticket-single}.header-primary.CHECK_OUT");

          if (this.order?.product_data?.store_type === "LOCKER") {
            text = this.$__t("{ticket-single}.header-primary.LOCKER_CHECK_OUT");
          }
          break;
      }

      return this.$__t(text);
    },

    headerSecondaryText() {
      let text = "";

      switch (this.ticket?.status) {
        case "ISSUED":
          text = this.$__t("{ticket-single}.header-secondary.ISSUED");
          if (this.order?.product_data?.store_type === "LOCKER") {
            text = this.$__t(
              "{ticket-single}.header-secondary.LOCKER_ISSUED_DELAYED"
            ).replace(
              "{TICKET_DATE_START}",
              this.$moment(this.ticket.ticket_date_start).format("HH:mm")
            );
          }
          break;
        case "CANCELLED":
          text = `럭스테이 예약이 <strong>${this.$moment(
            this.ticket.datetime_cancelled
          ).format("M월 DD일 HH:mm")}</strong>에 취소되었습니다.`;
          text = this.$__t("{ticket-cancel}.header-secondary-text").replace(
            "{TICKET_DATE_CANCELLED}",
            this.$moment(this.ticket.datetime_cancelled).format(
              localStorage.getItem("lugstay_language") === "base"
                ? "MMM Do HH:mm"
                : "M월 DD일 HH:mm"
            )
          );
          break;
        case "CHECK_IN":
          text = this.$__t("{ticket-single}.header-secondary.CHECK_IN");

          if (this.order?.product_data?.store_type === "LOCKER") {
            if (this.isLockerCheckInConfirmed) {
              text = this.$__t(
                "{ticket-single}.header-secondary.LOCKER_CHECK_IN_CONFIRMED"
              );
            } else {
              text = this.$__t("{ticket-single}.header-secondary.LOCKER_CHECK_IN");
            }
          }
          break;
        case "CHECK_OUT":
          text = this.$__t("{ticket-single}.header-secondary.CHECK_OUT");
          break;
      }

      return this.$__t(text);
    },

    headerTicketUsageTimeText() {
      let formula = "00:00:00";

      const _duration = this.usageDuration;

      try {
        if (this.ticket?.datetime_check_in) {
          formula = "";
          let duration_seconds = String(_duration.seconds());
          let duration_minutes = String(_duration.minutes());
          let duration_hours = String(_duration.hours());

          formula = `${
            duration_seconds.length < 2 ? "0" + duration_seconds : duration_seconds
          }`;
          formula =
            `${
              duration_minutes.length < 2 ? "0" + duration_minutes : duration_minutes
            }:` + formula;
          formula =
            `${duration_hours.length < 2 ? "0" + duration_hours : duration_hours}:` +
            formula;

          if (Math.floor(_duration.asDays()) != 0) {
            formula = `${Math.floor(_duration.asDays())}${this.$__t("d")} ` + formula;
          }
        }
      } catch (e) {
      } finally {
        return formula;
      }
    },

    passDuration() {
      try {
        if (this.ticket?.datetime_check_in && this.order) {
          let timeDiffDuration = null;
          let fromMoment = this.moment_datetime_now || this.$moment();
          let endMoment = this.$moment(this.order.promotion.pass_date_end);

          let diffMoment = endMoment.diff(fromMoment);

          if (diffMoment < 0) {
            diffMoment = 0;
          }

          timeDiffDuration = this.$moment.duration(diffMoment);

          return timeDiffDuration;
        }
      } catch (e) {
        console.error(e);
      }
    },

    headerTicketUsagePriceText() {
      if (this.ticket?.datetime_check_out) {
        return (this.order?.payment_total || 0).toLocaleString();
      }

      if (this.ticket?.datetime_check_in) {
        if (
          this.ticket?.ticket_type === "LOCKER" ||
          this.order?.product_data?.store_type === "LOCKER"
        ) {
          return this.lockerPrice.toLocaleString();
        }

        return (this.price?.total || 0).toLocaleString();
      }
    },

    isValidInvitation() {
      const view_type = localStorage.getItem("__LUGSTAY__VIEW__TYPE__");

      if (view_type === "iez") {
        return false;
      }

      return Array.isArray(this.invitation) && this.invitation.length > 0;
    },

    isLocker() {
      return (
        this.ticket?.ticket_type === "LOCKER" ||
        this.order?.product_data?.store_type === "LOCKER"
      );
    },

    messageComponents() {
      return this.isLocker ? this.lockerMessageComponents : this.storeMessageComponents;
    },

    lockerMessageComponents() {
      const ALL_MESSAGES = [
        "MessageLockerIssued",
        "MessageLockerExpired",
        "MessageCancelled",
        "MessageLockerCheckIn",
        "MessageInvited",
        "MessageLockerCheckOut",
      ];

      let messageList = [];

      if (this.ticket?.status === "EXPIRED") {
        messageList = ALL_MESSAGES.filter(
          (message_name) =>
            message_name === "MessageLockerIssued" ||
            message_name === "MessageLockerExpired"
        );
      } else if (this.ticket?.status === "ISSUED") {
        messageList = ALL_MESSAGES.filter(
          (message_name) => message_name === "MessageLockerIssued"
        );
      } else if (this.ticket?.status === "CANCELLED") {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <=
              ALL_MESSAGES.findIndex(
                (_message_name) => _message_name === "MessageCancelled"
              ) && message_name !== "MessageLockerExpired"
        );
      } else if (this.ticket?.status === "CHECK_IN") {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <=
              ALL_MESSAGES.findIndex(
                (_message_name) => _message_name === "MessageInvited"
              ) &&
            message_name !== "MessageLockerExpired" &&
            message_name !== "MessageCancelled" &&
            (message_name === "MessageInvited" ? this.isValidInvitation : true)
        );
      } else if (this.ticket?.status === "CHECK_OUT") {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            message_name !== "MessageLockerExpired" &&
            message_name !== "MessageCancelled" &&
            (message_name === "MessageInvited" ? this.isValidInvitation : true)
        );
      }

      if (this.order?.promotion_pass_code && this.order?.promotion.pass_date_start) {
        messageList.unshift("MessageFreePass");
      }

      return messageList;
    },

    storeMessageComponents() {
      const ALL_MESSAGES = [
        "MessageExpired",
        "MessageIssued",
        "MessageCancelled",
        "MessageCheckIn",
        "MessageInvited",
        "MessageCheckOut",
      ];

      let messageList = [];

      if (this.ticket?.status === "EXPIRED") {
        messageList = ALL_MESSAGES.filter(
          (message_name) => message_name === "MessageExpired"
        );
      } else if (this.ticket?.status === "ISSUED") {
        messageList = ALL_MESSAGES.filter(
          (message_name) => message_name === "MessageIssued"
        );
      } else if (this.ticket?.status === "CANCELLED") {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <=
              ALL_MESSAGES.findIndex(
                (_message_name) => _message_name === "MessageCancelled"
              ) && message_name !== "MessageExpired"
        );
      } else if (this.ticket?.status === "CHECK_IN") {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <=
              ALL_MESSAGES.findIndex(
                (_message_name) => _message_name === "MessageInvited"
              ) &&
            message_name !== "MessageExpired" &&
            message_name !== "MessageCancelled" &&
            (message_name === "MessageInvited" ? this.isValidInvitation : true)
        );
      } else if (this.ticket?.status === "CHECK_OUT") {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            message_name !== "MessageExpired" &&
            message_name !== "MessageCancelled" &&
            (message_name === "MessageInvited" ? this.isValidInvitation : true)
        );
      }

      if (this.order?.promotion_pass_code && this.order?.promotion.pass_date_start) {
        messageList.unshift("MessageFreePass");
      }

      return messageList;
    },

    reservedLockerBox() {
      const { locker_id, locker_size, locker_number } = this.order?.product_data || {};

      return {
        locker_id,
        locker_size,
        locker_number,
      };
    },

    lockerList() {
      return this.store?.extra?.locker || [];
    },

    isLockerCheckInConfirmed() {
      if (!this.ticket?.datetime_check_in) return false;

      if (this.$store.state.config.mode === "debug") {
        return true;
      }

      return this.$moment(this.moment_datetime_now).isAfter(
        this.$moment(this.ticket?.datetime_check_in).add(3, "m")
      );
    },

    checkInConfirmExpireDurationText() {
      if (this.ticket?.datetime_check_in) {
        const momentCheckInConfirmedAt = this.$moment(this.ticket?.datetime_check_in).add(
          3,
          "m"
        );
        const duration = this.$moment.duration(
          momentCheckInConfirmedAt.diff(this.$moment(this.moment_datetime_now))
        );

        return this.$moment(duration.as("milliseconds")).format(this.$__t("mm분 ss초"));
      }

      return this.$__t("mm분 ss초");
    },
  },

  watch: {
    ticket: {
      async handler(ticket, oldTicket) {
        if (ticket?.status !== oldTicket?.status) {
          this.$store.commit("loading/SET_TRUE");
          setTimeout(() => {
            this.scrollToActivatedTicketMessage();
          }, 0);

          setTimeout(() => {
            this.$store.commit("loading/SET_FALSE");
          }, 1000);
        }

        if (ticket?.status === "CHECK_IN") {
          if (!this.usageTimer) {
            this.setUsageTimer();
          }

          if (this.ticket.ticket_type === "LOCKER") {
            this.lockerPrice =
              this.order?.payment_total || (await this.fetchLockerPrice());
          }
        }

        if (oldTicket && ticket?.status !== "CHECK_IN") {
          clearInterval(this.usageTimer);
        }

        if (ticket?.status === "CHECK_OUT") {
          this.setUsageDuration();

          clearInterval(this.fetchTimer);
          clearInterval(this.usageTimer);
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.$analytics.logEvent("ticket confirm page view");

    await this.setFetchTimer();
    this.loaded = true;
    this.scrollToActivatedTicketMessage();
    this.checkQuery();
  },

  beforeDestroy() {
    clearInterval(this.fetchTimer);
    clearInterval(this.usageTimer);

    this.$store.commit("resourceForm/UNSET_ITEM", {
      data: { form_id: "reserve_form" },
    });

    this.fetchAllTickets();
  },

  methods: {
    async checkInwithoutImage() {
      try {
        await this.$store.dispatch("tickets/luggageCheckIn", {
          code: this.ticket.ticket_code,
        });
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
        console.error(e);
      }
    },
    async fetchTicket() {
      debug("fetchTicket");

      try {
        this.ticket = await this.$store.dispatch("tickets/get", {
          id: this.id,
        });
        // console.log('ticket', this.ticket);

        if (this.ticket.status === "ISSUED") {
          this.$store.commit("resourceForm/SET_ITEM", {
            data: {
              form_id: "reserve_form",
              date_start: this.ticket.ticket_date_start,
              date_end: this.ticket.ticket_date_end,
              quantity_small: this.ticket.ticket_quantity,
              quantity_big: this.ticket.ticket_over_quantity,
            },
          });
        }
      } catch (e) {
        throw Error(e);
      }
    },

    async fetchAllTickets(options = {}) {
      this.$store.commit("loading/SET_TRUE");

      try {
        this.$store.commit("tickets/CLEAR_ITEMS");
        this.$store.commit("tickets/CLEAR_ALL_LIST");
        //clear all tickets

        const TICKETS_ALL = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_all",
          filter: "all",
          query: { page: 1, size: 10 },
        });

        const TICKETS_AVAILABLE = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_available",
          filter: "available",
          query: { page: 1, size: 10 },
        });

        return await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE]);
      } catch (e) {
        //catch
      } finally {
        setTimeout(() => {
          this.$store.commit("loading/SET_FALSE");
        }, 100);
      }
    },

    async fetchStore() {
      let id = this.ticket?.store_id;

      if (id) {
        try {
          return await this.$store.dispatch("stores/get", { id });
        } catch (e) {
          throw Error(e);
        }
      }
    },

    async fetchOrder() {
      let id = this.ticket?.receipt_id;

      if (id) {
        try {
          const data = await this.$store.dispatch("orders/order", {
            id,
          });

          return data;
        } catch (e) {
          throw Error(e);
        }
      }
    },

    async fetchPrice() {
      try {
        let payload = {
          id: this.ticket?.store_id,
          type: "stores",
          query: {
            start_date: this.$moment(this.ticket.datetime_check_in).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end_date: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
            pass_code: this.order?.promotion?.pass_code,
            small_quantity: this.ticket.ticket_quantity,
            big_quantity: this.ticket.ticket_over_quantity,
          },
        };

        let res = await this.$store.dispatch("stores/predictPrice", payload);

        return res?.data?.data;
      } catch (e) {
        throw Error(e);
      }
    },

    async fetchInvitation() {
      try {
        let res = await this.$store.dispatch("tickets/getShare", {
          code: this.id,
        });

        const INVITATION = res?.data?.data?.items;

        if (INVITATION) {
          this.invitation = INVITATION;
        }
      } catch (e) {
        this.$log.error(e);
      }
    },

    async setFetchTimer() {
      try {
        this.$store.commit("loading/SET_TRUE");
        await this.fetchTicket();
        await Promise.all([this.fetchOrder(), this.fetchStore()]);
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t("페이지에 접근할 권한이 없습니다."),
          status: "alert",
        });

        this.$router.replace("/");
      } finally {
        this.$store.commit("loading/SET_FALSE");
      }

      if (this.ticket.status === "CHECK_IN") {
        await this.fetchInvitation();

        if (this.ticket.ticket_type === "LOCKER") {
          this.lockerPrice = this.order?.payment_total ?? (await this.fetchLockerPrice());
        } else {
          this.price = await this.fetchPrice();
        }
      }

      this.fetchTimer = setInterval(async () => {
        await Promise.all([this.fetchTicket(), this.fetchOrder()]);
        if (this.ticket.status === "CHECK_IN") {
          await this.fetchInvitation();

          if (this.ticket.ticket_type === "LOCKER") {
            this.lockerPrice =
              this.order?.payment_total ?? (await this.fetchLockerPrice());
          } else {
            this.price = await this.fetchPrice();
          }
        }
      }, 6000);
    },

    async fetchLockerPrice() {
      let res;

      try {
        let payload = {
          id: this.ticket?.store_id,
          type: this.ticket?.ticket_type,
          query: {
            start_datetime: this.$moment(this.ticket.datetime_check_in).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end_datetime: this.$moment(this.ticket.datetime_check_out).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            locker_size: this.ticket.product_data?.locker_size,
          },
        };

        res = await this.$store.dispatch("stores/predictPrice", payload);

        return res?.data?.data?.total;
      } catch (e) {
        this.$log.error(e);
        return 0;
      }
    },

    setUsageTimer() {
      this.setUsageDuration();
      this.moment_datetime_now = this.$moment(new Date());

      this.usageTimer = setInterval(() => {
        this.setUsageDuration();
        this.moment_datetime_now = this.$moment(new Date());
      }, 1000);
    },

    setUsageDuration() {
      const MOMENT_CHECK_IN = this.$moment(this.ticket?.datetime_check_in);
      const MOMENT_POINT = this.$moment(this.ticket?.datetime_check_out);

      this.usageDuration = this.$moment.duration(
        Math.abs(MOMENT_CHECK_IN.diff(MOMENT_POINT))
      );
    },

    onClickMapAction(method) {
      if (method === "copy") {
        this.$copyText(this.ticket.store_address).then((e) => {
          this.$store.commit("notice/ADD_ITEM", {
            message: this.$__t("복사되었습니다."),
          });
        }, {});
      } else {
        switch (method) {
          case "naver":
            this.openNaverMap(
              `nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`
            );
            break;

          case "google":
            if (this.wni.isNative) {
              this.wni.execute("wnOpenURL", {
                url: `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,
              });
            } else {
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,
                "_blank"
              );
            }
            break;
        }
      }
    },

    openNaverMap(url) {
      const encodedUrl = encodeURI(url);

      if (this.wni.isNative) {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          this.wni.execute("wnOpenURL", { url: url });
          setTimeout(() => {
            if (!document.hidden) {
              const _url =
                url.replace("nmap://", "intent://") +
                "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
              return this.wni.execute("wnOpenURL", { url: _url });
            }
          }, 2000);
        }
      } else {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          url =
            url.replace("nmap://", "intent://") +
            "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
          location.href = url;
        } else {
          location.href = url;
        }
      }
    },

    openNaverMapInIphoneBrowser(url) {
      this.$store.commit("loading/SET_TRUE");

      window.location.href = url;

      setTimeout(() => {
        try {
          if (!document.hidden) {
            throw new Error("1102:NO_EXISTS_DATA");
          }
        } catch (e) {
          let confirmed = confirm(
            this.$__t("Naver map is not installed, so go to the App Store.")
          );

          if (confirmed) {
            location.href = "http://itunes.apple.com/app/id311867728?mt=8";
          }
        } finally {
          this.$store.commit("loading/SET_FALSE");
        }
      }, 1000);
    },

    reload() {
      this.$store.commit("loading/SET_TRUE");

      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE");
      }, 1000);
    },

    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    onBack() {
      if (this.$data.query.from === this.$route.name) {
        return this.$router.push({ name: "Home" });
      }

      return this.$router.back();
    },

    scrollToActivatedTicketMessage() {
      return this.$nextTick(() => {
        const activatedList = this.$el.querySelectorAll(".ticket-message.activated");
        const header = this.$refs.header;

        if (activatedList.length > 0) {
          const elOffset = $(activatedList[activatedList.length - 1]).offset().top;
          const headerHeight = header.offsetHeight;

          let offset = elOffset - headerHeight;

          $("html, body").animate({ scrollTop: offset }, 200);
        }
      });
    },

    async lockerCheckIn(event) {
      event.target.classList.add("disabled");
      event.target.classList.remove("activated");
      event.target.disabled = true;

      this.$store.commit("loading/SET_TRUE");
      await utils.wait(1000);

      try {
        await this.$store.dispatch("tickets/lockerCheckIn", {
          type: this.order?.product_data?.store_type,
          code: this.ticket?.ticket_code,
        });

        return this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t("체크인되었습니다."),
          status: "success",
        });
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      } finally {
        await Promise.all[(this.fetchTicket(), this.fetchOrder())];
        this.closeRef("locker-box-selector-slider");
        await utils.wait(1000);
        this.$store.commit("loading/SET_FALSE");
      }
    },

    async lockerOpen() {
      this.$store.commit("loading/SET_TRUE");

      try {
        await this.axios.put(
          this.$store.state.config.apiURL +
            `/v2/locker/tickets/${this.ticket.ticket_code}/open`,
          {},
          { headers: this.$store.state.config.userHeaders }
        );
      } catch (e) {
        this.$log.error(e);
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      } finally {
        await this.$utils.wait(1000);
        await Promise.all[(this.fetchTicket(), this.fetchOrder())];
        this.$store.commit("loading/SET_FALSE");
      }
    },

    async lockerCancel() {
      const confirmed = confirm(this.$__t("{ticket-single}.confirm.LOCKER_CANCEL"));
      this.$store.commit("loading/SET_TRUE");

      if (confirmed) {
        try {
          await this.axios.put(
            this.$store.state.config.apiURL +
              `/v2/locker/tickets/${this.ticket.ticket_code}/cancel`,
            {},
            { headers: this.$store.state.config.userHeaders }
          );
        } catch (e) {
          this.$log.error(e);
          this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
        } finally {
          await this.$utils.wait(1000);
          await Promise.all[(this.fetchTicket(), this.fetchOrder())];
          this.$store.commit("loading/SET_FALSE");
        }
      }
    },

    async checkIn() {
      this.$store.commit("loading/SET_TRUE");

      let type = "";
      if (this.ticket?.ticket_type === "LUGGAGE") type = "luggage";
      else if (this.ticket?.ticket_type === "LOCKER") type = "locker";
      if (!type) return;

      try {
        await this.$store.dispatch(`tickets/${type}CheckIn`, {
          code: this.ticket?.ticket_code,
        });
        await this.$utils.wait(0);
        this.$store.commit("loading/SET_FALSE");
        this.openRef("luggage-photo-slider");
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
        console.error(e);
        this.$store.commit("loading/SET_FALSE");
      }
    },

    checkQuery() {
      if (this.$data.query.action === "CHECK_IN" && this.ticket.status === "ISSUED") {
        return this.openRef("check-in-slider");
      }

      if (this.$data.query.action === "CHECK_OUT" && this.ticket.status === "CHECK_IN") {
        if (!this.checkCheckOutAvailable()) {
          return this.$store.commit("modal/ADD_ITEM", {
            component: CheckOutUnavailable,
            size: "small",
          });
        }

        return this.openRef("check-out-slider");
      }
    },

    openQrCodeReader: _.debounce(function () {
      // console.log('this.checkCheckOutAvailable()', this.checkCheckOutAvailable());
      if (this.ticket.status === "CHECK_IN" && !this.checkCheckOutAvailable()) {
        return this.handleCheckOutAlertBalloon();
      }

      this.$analytics.logEvent("complete ticket qr scan");
      this.$store.dispatch("qrCodeScanner/open", {
        type: "onetime-callback",
        callback: async ({ status, code, store_id, error }) => {
          // console.log('openQrCodeReader #3', { status, code, store_id, error });
          try {
            if (status === "ERROR") {
              throw error;
            }

            if (status === "SUCCESS") {
              const item = await this.$store.dispatch("qrCodeScanner/findTicket", {
                store_id: this.ticket.store_id,
                ticket_code: this.ticket.ticket_code,
              });
              if (this.ticket.status === "CHECK_IN" && this.checkCheckOutAvailable()) {
                return this.openToCheckOut();
              } else {
                this.checkInwithoutImage()
                  .then(() => {
                    this.fetchTicket();
                  })
                  .then(() => {
                    setTimeout(() => {
                      this.onScanned(item);
                    }, 1500);
                  });
              }

              // setTimeout(() => {
              //   this.$router.push({ name: 'Redirect', params: { code: code } });
              // }, 0);
            }
          } catch (e) {
            this.$store.commit("alert/ADD_ITEM", {
              message: e.toString(),
              status: "error",
            });
          }
        },
      });
    }, 500),

    checkCheckOutAvailable() {
      if (this.isLocker) {
        if (this.ticket.status === "CHECK_IN") {
          const duration = this.$moment.duration(
            this.$moment().diff(this.$moment(this.ticket.datetime_check_in))
          );

          return duration.asMinutes() >= 3;
        }
      }

      if (this.ticket.status === "CHECK_IN") {
        if (this.$store.state.config.mode !== "debug") {
          const duration = this.$moment.duration(
            this.$moment().diff(this.$moment(this.ticket.datetime_check_in))
          );
          return duration.asMinutes() >= 10;
        }
        return true;
      }

      return false;
    },

    handleCheckOutAlertBalloon() {
      const $checkOutAlertBalloon = this.$refs["check-out-alert-balloon"];
      if ($checkOutAlertBalloon.classList.contains("hidden")) {
        $checkOutAlertBalloon.classList.remove("hidden");
        setTimeout(() => {
          $checkOutAlertBalloon.classList.add("hidden");
        }, 2000);
      }
    },

    callCustomerService() {
      setTimeout(() => {
        location.href = "tel:+82-218779727";
      }, 500);
    },
    openSlideForUploadLuggageImage() {
      debug("openSlideForUploadLuggageImage");
      this.openRef("check-in-upload-image-slider");
    },

    async onCheckInStatus(status) {
      debug("onCheckInStatus", status);

      try {
        if (status === "done") {
          await this.fetchTicket();

          setTimeout(() => {
            this.openSlideForUploadLuggageImage();
          }, 1500);
        } else if (status === "error") {
          //
        } else if (status === "cancel") {
          //
        } else {
          // console.log(`check status ${status}`);
        }
      } catch (e) {
        console.error(e);
      }
    },
    openToCheckInWithoutQr() {
      this.openRef("check-in-without-qr-slider");
    },
    openToCheckOutWithoutQr() {
      if (this.ticket.status === "CHECK_IN" && !this.checkCheckOutAvailable()) {
        return this.handleCheckOutAlertBalloon();
      }
      this.openRef("check-out-without-qr-slider");
    },

    openToCheckOut() {
      this.openRef("check-out-slider");
    },
    onScanned(item) {
      // console.log('onscanned');
      // this.openToCheckIn();
      this.openSlideForUploadLuggageImage();
    },
  },

  components: {
    MessageLockerIssued,
    MessageLockerExpired,
    MessageLockerCheckIn,
    MessageLockerCheckOut,

    MessageFreePass,

    MessageIssued,
    MessageCheckIn,
    MessageCheckOut,
    MessageCancelled,
    MessageExpired,
    MessageInvited,

    TicketEdit: () => import("@/components/pages/store/StoresSearchCondition"),
    TicketInvite,
    TicketCheckIn,
    TicketCheckOut,
    CheckinWithoutQr,
    CheckoutWithoutQr,

    LockerBoxSelector,

    UiSlider,
  },
};
</script>

<style lang="scss" scoped>
#find-modal::v-deep.lug-guide-modal {
  & > .lug-guide-modal-container {
    width: 90%;
    max-width: 320px;
  }
}

#ticket-single-page {
  @include fill-viewport-min-height;

  .page-header {
    padding: env(safe-area-inset-top) 0 0 0;
    background-color: $color-white;
    flex-direction: column;
    box-shadow: 0px unit(4) unit(12) rgba(0, 0, 0, 0.16),
      unit(4) unit(8) unit(28) rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px unit(14) unit(14);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;

    &__button-area {
      display: flex;
      justify-content: space-between;
      height: unit(56);
    }

    &__button {
      width: unit(56);
      height: unit(56);

      img {
        margin: auto;
        width: unit(24);
        height: unit(24);
        object-fit: contain;
      }
    }

    &__text-area {
      min-height: unit(212);
      padding: unit(20) unit(16);

      .text-area {
        .text {
          &--primary {
            font-weight: bold;
            font-size: unit(22);
            line-height: 1.45;
            color: $color-black;
          }

          &--secondary {
            margin-top: unit(4);
            font-size: unit(14);
            line-height: 1.43;
            color: #292a2b;
            font-weight: 400;
            letter-spacing: -0.006em;

            &::v-deep > strong {
              color: $color-black;
              font-weight: bold;
            }
          }
        }
      }

      .ticket-usage {
        background: $color-white;
        border: 1px solid #e1e4e6;
        border-radius: unit(10);
        margin-top: unit(20);

        &__explanatory {
          color: #008fff;
          font-weight: 500;
          font-size: unit(14);

          &--red {
            color: $color-red;
            font-weight: 500;
            font-size: unit(14);
          }
        }

        &__title-text {
          font-weight: bold;
          font-size: unit(14);
          line-height: unit(22);
          letter-spacing: -0.04em;
        }

        &__text-area {
          padding: unit(12) unit(12) unit(26) unit(12);

          & > * {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: unit(16);
          }
        }

        &__time {
          font-weight: bold;
          font-size: unit(24);
          line-height: 1.42;
          letter-spacing: -0.006em;
          color: #ced3d6;
        }

        &.activated .ticket-usage__time {
          color: $color-black;
        }

        &__price {
          font-size: unit(24);
          line-height: 1.42;
          letter-spacing: -0.006em;
          color: #ced3d6;

          strong {
            font-weight: bold;
          }
        }

        &.activated .ticket-usage__price {
          color: $color-black;

          strong {
            color: $color-black;
          }
        }

        &__bar-area {
          padding: unit(12) 0;

          .status-bar {
            display: table;
            width: 100%;
            table-layout: fixed;
            height: 0;

            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 0;
              border-top: 1px dashed #c3d3d6;
              top: unit(-6);
              left: 50%;
              transform: translate(-50%, 0);
            }

            &.ISSUED {
              &::after {
                content: "";
                position: absolute;
                top: unit(-6);
                left: 0;
                width: calc(100% * (1 / 6));
                border-top: solid 1px #48d9eb;
                z-index: 1;
              }
            }

            &.CHECK_IN {
              .item {
                &--ISSUED {
                  &::before {
                  }

                  &::after {
                    display: none;
                  }
                }
              }

              &::after {
                content: "";
                position: absolute;
                top: unit(-6);
                left: 0;
                width: calc(100% * (1 / 2));
                border-top: solid 1px #48d9eb;
                z-index: 1;
              }
            }

            &.CHECK_OUT {
              &::after {
                content: "";
                position: absolute;
                top: unit(-6);
                left: 0;
                width: calc(100% * (5 / 6));
                border-top: solid 1px #48d9eb;
                z-index: 1;
              }
            }

            &__list {
              display: table-row;
            }

            &__item {
              display: table-cell;
              text-align: center;

              &::before {
                content: "";
                position: absolute;
                border-radius: 50%;
                width: unit(10);
                height: unit(10);
                top: 0;
                left: 50%;
                transform: translate(-50%, calc(-50% - #{unit(5)}));
                background-color: #c3d3e6;
                z-index: 2;
              }

              .status-label {
                margin-top: unit(9);
                font-size: unit(14);
                line-height: 1.43;
                text-align: center;
                letter-spacing: -0.006em;
                color: #ced3d6;

                &.activated {
                  color: $color-black;
                }
              }

              .status-time {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, calc(-100% - #{unit(12)}));
                display: inline-block;
                color: #ced3d6;
                text-align: center;
                line-height: unit(17);
              }

              &.activated {
                &::before {
                  background-color: #48d9eb;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: -50%;
                  left: 50%;
                  transform: translate(-50%, 0);
                  width: unit(20);
                  height: unit(20);
                  border-radius: 50%;
                  background: rgba(0, 143, 255, 0.24);
                }
              }
            }
          }
        }
      }
    }
  }

  .page-body {
    padding: unit(30) unit(16) unit(130) unit(16);

    .ticket-message__list-item {
      & + .ticket-message__list-item {
        margin-top: unit(30);
      }
    }
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: unit(100);
    background: #f1f3f5;
    // -webkit-backdrop-filter: blur(unit(3));
    // backdrop-filter: blur(unit(3));
    // background: rgba(241, 243, 255, 0.5);

    .button-area {
      width: 100%;
      padding: 0 unit(16) unit(12) unit(16);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      i {
        color: $color-white;
        margin-right: unit(4);
        font-size: unit(16);
      }

      button {
        border-radius: unit(16);
        &.singlebutton {
          margin-top: unit(30);
        }
        &.storeid {
          margin-bottom: unit(5);
          height: unit(30);
          color: #2c6790;
          text-decoration: underline;
          text-underline-position: under;
          background-color: transparent;
          margin-right: unit(5);
          width: 100%;
          text-transform: uppercase;
          font-size: unit(14);
          font-weight: 600;
        }
        &.scan {
          width: 100%;
          display: inline-block;
        }
      }
    }

    &__balloon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      width: auto;
      min-width: unit(280);
      justify-content: center;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16))
        drop-shadow(4px 8px 28px rgba(0, 0, 0, 0.08));
      transition: all 0.2s;
      opacity: 1;

      .hidden {
        opacity: 0;
        transition: all 0.2s;
      }
    }
  }
}

#locker-box-selector-slider::v-deep {
  .locker-box-selector {
    margin-top: unit(24);

    .locker-viewport {
      height: calc(#{vh(100)} - #{unit(140)});
      height: unit(360);
    }
  }

  .locker-box-selector__header {
    font-weight: bold;
    font-size: unit(16);
    line-height: 1.38;
    text-align: center;
    margin-top: unit(14);
  }

  .locker-box-selector__button-area {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: $color-white;
    display: flex;
    margin-top: unit(40);
    gap: unit(10);

    .btn {
      padding: unit(15) unit(16);
      font-weight: 700;
      font-size: unit(14);
      line-height: 1.43;
      letter-spacing: -0.006em;
      color: #a9afb3;
      height: unit(50);
      border-radius: unit(16);

      &--close {
        flex: 0 0 unit(80);
        border: 1px solid #e1e4e6;
      }

      &--submit {
        flex: 1;
        background: #a9afb3;
        color: $color-white;
        cursor: not-allowed;

        &.activated {
          background: $color-primary;
          color: $color-white;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
