<template>
	<div class="rating-star">
		<div class="prefix" v-if="rate">{{ prefix }}</div>
		<div class="label" v-if="rate">{{ rate || 0 }}{{ $__t('{rating-star}점') }}</div>
		<div class="body">
			<div class="background">
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
			</div>
			<div class="point">
				<i class="fa fa-star" v-for="i in parseInt(rates)"></i>
				<i class="fa fa-star-half" v-if="rates != parseInt(rates)"></i>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['prefix', 'rate'],
	computed: {
		rates() {
			if (!this.rate) {
				return 0;
      }
			return this.rate / 2;
		},
	},
};
</script>
