<template>
  <div class="subpage" id="mypage-profile">
    <div
      v-if="loading"
      style="width: 100%; height: 100%; z-index: 10000; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.51)"
    >
      <div
        style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center"
      >
        <i class="fa fa-spinner fa-pulse"></i>
      </div>
    </div>
    <div class="container">
      <div class="form">
        <div class="form-header">
          <div class="title">{{ $__t('프로필 관리하기') }}</div>
        </div>
        <div class="form-body">
          <ui-form @submit="submitForm" @error="scrollToError" :formData="formData" ref="form">
            <ui-form-list>
              <!--프로필사진-->
              <ui-form-list-item>
                <profile-uploader v-model="formData.profile_photo.value" />
              </ui-form-list-item>
              <!--이름-->
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.user_name.placeholder"
                  :type="formData.user_name.type"
                  :label="formData.user_name.label"
                  :error="formData.user_name.error"
                  v-model="formData.user_name.value"
                />
              </ui-form-list-item>

              <!-- <ui-form-list-multiple-item>
								<ui-textbox
									:placeholder="formData.user_first_name.placeholder"
									:type="formData.user_first_name.type"
									:label="formData.user_first_name.label"
									:error="formData.user_first_name.error"
									v-model="formData.user_first_name.value"
								/>
								<ui-textbox
									:placeholder="formData.user_last_name.placeholder"
									:type="formData.user_last_name.type"
									:label="formData.user_last_name.label"
									:error="formData.user_last_name.error"
									v-model="formData.user_last_name.value"
								/>
							</ui-form-list-multiple-item> -->
              <!--국가-->
              <ui-form-list-item>
                <!-- <ui-select :placeholder="formData.user_iso_code.placeholder" :items="formData.user_iso_code.items" :label="formData.user_iso_code.label" :error="formData.user_iso_code.error" v-model="formData.user_iso_code.value" /> -->
                <ui-auto-complete
                  :placeholder="formData.user_iso_code.placeholder"
                  :label="formData.user_iso_code.label"
                  :error="formData.user_iso_code.error"
                  :items="formData.user_iso_code.items"
                  v-model="formData.user_iso_code.value"
                  type="iso"
                ></ui-auto-complete>
              </ui-form-list-item>
              <ui-form-list-multiple-item>
                <!-- <ui-select :placeholder="formData.user_dial_code.placeholder" :items="formData.user_dial_code.items" :label="formData.user_dial_code.label" :error="formData.user_dial_code.error" v-model="formData.user_dial_code.value" :displayValue="true" /> -->
                <!--국가번호-->
                <ui-auto-complete
                  :placeholder="formData.user_dial_code.placeholder"
                  :label="formData.user_dial_code.label"
                  :error="formData.user_dial_code.error"
                  :items="formData.user_dial_code.items"
                  v-model="formData.user_dial_code.value"
                  type="dial"
                ></ui-auto-complete>
                <!--휴대폰번호-->
                <ui-textbox
                  :placeholder="formData.user_phone.placeholder"
                  :type="formData.user_phone.type"
                  :label="formData.user_phone.label"
                  :error="formData.user_phone.error"
                  v-model="formData.user_phone.value"
                  maxLength="15"
                  :readonly="true"
                />
              </ui-form-list-multiple-item>
                <!--이메일주소-->
              <ui-form-list-item :error="formData.user_email.error">
                <ui-textbox
                  :placeholder="formData.user_email.placeholder"
                  :type="formData.user_email.type"
                  :label="formData.user_email.label"
                  :error="formData.user_email.error"
                  v-model="formData.user_email.value"
                />
              </ui-form-list-item>

              <ui-form-list-item>
                <ui-solid-button display="block">
                  <button type="button" @click.prevent="$refs.form.submitForm">{{ $__t('프로필 저장하기') }}</button>
                </ui-solid-button>
              </ui-form-list-item>
            </ui-form-list>
          </ui-form>
        </div>
        <div class="form-footer">
          <p>
            <router-link :to="{ name: 'AuthLeave' }">{{ $__t('탈퇴하기') }}</router-link>
          </p>

          <!-- <p>
            <router-link :to="{ name: 'MypageChangePassword' }">{{ $__t('비밀번호 변경하기') }}</router-link>
          </p> -->
          <!-- <p style="margin-top: 1rem">
            {{ $__t('Promote Lugstay and earn money') }}!<img
              src="/img/icon-question-circle.png"
              style="display: inline-block; width: 0.9rem; height: 0.9rem; margin-left: 5px; top: 2px; cursor: pointer"
              @click="showModal"
            />
            <a @click="applyPromoter">{{ $__t('Apply') }}</a>
          </p> -->
          <div class="promotion-area" v-if="isShowLink" style="border-top: #adb5bd solid 1px; padding-top: 1rem">
            <div class="promotion-area-input-label">{{ $__t('Promotion Link') }}</div>
            <div class="input-area">
              <input
                type="text"
                id="promotionLink"
                class="promotion-link"
                :value="`${$store.state.config.siteURL}/campaign/codes/${campaign_code}`"
                readonly
              />
              <div @click="copyToClipboard('promotionLink')" class="copy-button">{{ $__t('Copy Link') }}</div>
            </div>
            <div class="guide" style="margin-top: 1rem">
              <ul>
                <li class="header">[ {{ $__t('Promotional Link Usage and Notices') }} ]</li>
                <li>- {{ $__t('Share promotional links with your friends.') }}</li>
                <li>- {{ $__t('If a customer signs up through the link, we will give you 10% of the payment.') }}</li>
                <li>- {{ $__t('Customers who sign up through the link will receive a luggage storage discount coupon.') }}</li>
                <li>
                  -
                  {{
                    $__t(
                      'Earnings will only accumulate to the member through the promotional link and will be accumulated for one year only. So please be sure to share the promotional link with your friends.'
                    )
                  }}
                </li>
                <li>- {{ $__t('Earnings can be paid in increments of $10 from the accumulated amount of $50') }}</li>
                <li>- {{ $__t('Your payments do not accumulate.') }}</li>
                <li>- {{ $__t('Please contact ljk@lugstay.com for cumulative earnings and payment requests.') }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="guide-modal" v-if="isShowModal">
          <div class="guide-modal-body">
            <div class="modal-close-button"><i class="fa fa-times" @click="closeModal"></i></div>
            <div class="modal-title-section" style="padding-top: 0">{{ $__t('Promote Lugstay and earn money') }}!</div>
            <div class="modal-description">
              {{ $__t("When you share a generated promotional link, we'll share the revenue from the customers who signed up.") }}.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileUploader from '@/components/modules/RichForm/ProfileUploader';
import countryCodes from '@/assets/json/country-codes.json';
import AutoComplete from '@trevoreyre/autocomplete-vue';

export default {
  name: 'MyPageProfile',
  components: {
    ProfileUploader,
    AutoComplete
  },
  data() {
    var _code = countryCodes.map((o) => {
      return { name: o.name, code: o.code };
    });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });
    _code.forEach((o) => {
      if (o.name === 'Korea, Republic of') {
        _code.splice(_code.indexOf(o), 1);
        _code.unshift(o);
      }
      if (o.name === 'Others') {
        _code.splice(_code.indexOf(o), 1);
        _code.push(o);
      }
    });

    var _dial_code = countryCodes.map((o) => {
      return { name: o.name, code: o.dial_code };
    });
    _dial_code.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    _dial_code.forEach((o) => {
      if (o.name === 'Korea, Republic of') {
        _dial_code.splice(_dial_code.indexOf(o), 1);
        _dial_code.unshift(o);
      }
      if (o.name === 'Others') {
        _dial_code.splice(_dial_code.indexOf(o), 1);
        _dial_code.push(o);
      }
    });
    var formData = {
      profile_photo: {
        value: {},
        error: ''
      },
      user_name: {
        type: 'text',
        label: this.$__t('이름'),
        placeholder: this.$__t('이름을 입력해주세요'),
        validation: [{ type: 'required' }],
        value: '',
        error: ''
      },
      // user_last_name: {
      //   type: 'text',
      //   label: this.$__t('성'),
      //   placeholder: this.$__t('Korean or English'),
      //   validation: [{ type: 'required' }, { type: 'koreanOrEnglish' }],
      //   value: '',
      //   error: ''
      // },
      // user_first_name: {
      //   type: 'text',
      //   label: this.$__t('이름'),
      //   placeholder: this.$__t('Korean or English'),
      //   validation: [{ type: 'required' }, { type: 'koreanOrEnglish' }],
      //   value: '',
      //   error: ''
      // },
      user_iso_code: {
        label: this.$__t('국가'),
        placeholder: this.$__t('국가를 선택해주세요'),
        validation: [{ type: 'required' }],
        value: '',
        items: _code.map((o) => {
          return { label: o.code + ' (' + o.name + ')', value: o.code };
        }),
        error: ''
      },
      user_dial_code: {
        label: this.$__t('국가 번호'),
        placeholder: this.$__t('국가 번호를 선택해주세요'),
        validation: [{ type: 'required' }],
        value: '',
        items: _dial_code.map((o) => {
          return { label: o.code + ' (' + o.name + ')', value: o.code };
        }),
        error: ''
      },
      user_phone: {
        type: 'text',
        label: this.$__t('휴대폰 번호'),
        placeholder: this.$__t('휴대폰 번호를 입력해주세요'),
        validation: [{ type: 'required' }, { type: 'number' }],
        value: '',
        error: ''
      },
      user_email: {
        type: 'text',
        label: this.$__t('이메일 주소'),
        placeholder: this.$__t('이메일 주소를 입력해주세요'),
        validation: [{ type: 'required' }, { type: 'email' }],
        value: '',
        error: ''
      }
    };
    return {
      formData,
      countryCodes,
      isShowModal: false,
      isShowLink: false,
      campaign_code: null,
      loading: false,
      fullIso: ''
    };
  },
  mounted() {
    this.loadAuthInfo();
  },
  activated() {
    this.loadAuthInfo();
  },
  methods: {
    loadAuthInfo() {
      this.formData.user_email.value = this.$store.state.auth.user_email;
      this.formData.user_name.value = this.$store.state.auth.user_name;
      this.formData.user_phone.value = this.$store.state.auth.user_phone;
      // this.formData.user_first_name.value = this.$store.state.auth.user_first_name;
      // this.formData.user_last_name.value = this.$store.state.auth.user_last_name;
      this.formData.user_phone.value = this.$store.state.auth.user_phone;
      this.formData.profile_photo.value = {
        resource_url: this.$store.state.auth.profile_image
      };
      this.formData.user_dial_code.value = this.$store.state.auth.user_dial_code;
      let item = this.formData.user_iso_code.items.find((o) => o.value == this.$store.state.auth.user_iso_code);
      this.formData.user_iso_code.value = item ? item.label : this.$store.state.auth.user_iso_code;
    },

    checkName(values) {
      var regType = /^[가-힣|A-Za-z]*$/;
      var error = null;

      // if (!values.user_first_name) {
      //   error = new Error(this.$__t('Name in Korean or English'));
      //   this.$data.formData.user_first_name.error = error.message;
      // } else if (!regType.test(values.user_first_name)) {
      //   error = new Error(this.$__t('Name in Korean or English'));
      //   this.$data.formData.user_first_name.error = error.message;
      // } else {
      //   this.$data.formData.user_first_name.error = null;
      // }

      // if (!values.user_last_name) {
      //   error = new Error(this.$__t('Name in Korean or English'));
      //   this.$data.formData.user_last_name.error = error.message;
      // } else if (!regType.test(values.user_last_name)) {
      //   error = new Error(this.$__t('Name in Korean or English'));
      //   this.$data.formData.user_last_name.error = error.message;
      // } else {
      //   this.$data.formData.user_last_name.error = null;
      // }

      if (!values.user_name) {
        error = new Error(this.$__t('Name in Korean or English'));
        this.$data.formData.user_name.error = error.message;
      } else if (!regType.test(values.user_name)) {
        error = new Error(this.$__t('Name in Korean or English'));
        this.$data.formData.user_name.error = error.message;
      } else {
        this.$data.formData.user_name.error = null;
      }

      return error;
    },

    submitForm(values) {
      this.loading = true;

      if (!this.formData.user_iso_code.items.some((e) => e.label === values.user_iso_code)) {
        this.formData.user_iso_code.error = this.$__t('Please select a existing value among the list.');
      }
      if (!this.formData.user_dial_code.items.some((e) => e.value === values.user_dial_code)) {
        this.formData.user_dial_code.error = this.$__t('Please select a existing value among the list.');
      }
      if (this.formData.user_dial_code.error || this.formData.user_iso_code.error) {
        this.loading = false;
        return;
      }

      values.user_iso_code = values.user_iso_code.substring(0, values.user_iso_code.indexOf(' ('));

      let error = this.checkName(values);
      if (error) {
        this.loading = false;
        return;
      }

      try {
        let res = this.$store.dispatch('auth/edit', values);
        this.$store.commit('alert/ADD_ITEM', { message: this.$__t('프로필이 수정되었습니다.'), status: 'success' });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: this.$__t(e), status: 'error' });
      } finally {
        this.loading = false;
      }
    },
    applyPromoter() {
      Object.keys(this.formData).forEach((o) => {
        this.$refs.form.validateField(this.formData[o]);
      });
      if (this.$refs.form.existError) this.scrollToError();

      let interval = setInterval(() => {
        if (!this.loading) {
          clearInterval(interval);
          if ($('.ui-form-list-item .error').length > 0) {
            return;
          }

          this.axios
            .get(this.$store.state.config.apiURL + '/v2/campaigns/invite', { headers: this.$store.state.config.userHeaders })
            .then(({ data }) => {
              this.campaign_code = data.data.campaign_code;
              this.isShowLink = true;
            })
            .catch((error) => {
              this.$store.commit('alert/ADD_ITEM', { message: error, status: 'error' });
            });
        }
      }, 300);
    },
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    copyToClipboard(id) {
      var copyText = document.getElementById(id);

      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand('copy');

      alert(this.$__t('Copied to clipboard'));
    },
    serializeFields() {
      var values = {};
      Object.keys(this.formData).forEach((o) => {
        if (typeof this.formData[o].value == 'undefined') {
          values[o] = this.formData[o].value;
        } else if (typeof this.formData[o].value == 'array' || typeof this.formData[o].value == 'object') {
          values[o] = JSON.stringify(this.formData[o].value);
        } else {
          if (typeof this.formData[o].value == 'boolean') {
            values[o] = this.formData[o].value;
          } else {
            values[o] = this.formData[o].value.toString();
          }
        }
      });
      return values;
    },
    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ui-form-list-item div.error');
        if (errorList.length >= 0) {
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }

          $('html, body').animate({ scrollTop: offset }, 200);
        }
      }, 0);
    }
  }
};
</script>
