<template>
  <div id="mobile-landing" class="page">
    <template v-if="appIntroVisible">
      <app-introduction />
    </template>
    <template v-else>
      <keep-alive>
        <component :is="targetComponent" />
      </keep-alive>

      <!-- <NavBar /> -->
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import MobileLandingTickets from '@/components/pages/landings/MobileLandingTickets';
import LugNavMenu from '@/components/pages/v2/_MoreList.vue';
import NavBar from '@/components/resources/nav/NavBar.vue';
import Home from '@/v3/pages/home/Home.vue';

export default {
  name: 'MobileLandingPage',

  data() {
    const _component = this.componentOnHash();

    return {
      selectedListIndex: 2,
      targetComponent: _component || Home,
    };
  },

  created() {
    this.onInitialize();
    window.addEventListener('replaceState', this.onReplaceState);
  },

  mounted() {
    // if (this.targetComponent === MobileLandingHome) {
    //   return this.wni.execute('wnAppChangeStatusBar', {
    //     'status-bar-style': 'light',
    //     'background-color': '#008fff',
    //   });
    // }

    return this.wni.execute('wnAppChangeStatusBar', {
      'status-bar-style': 'dark',
      'background-color': '#ffffff'
    });
  },

  watch: {
    appIntroVisible(value) {
      if (value === true) {
        return this.wni.execute('wnAppChangeStatusBar', {
          'status-bar-style': 'light',
          'background-color': '#008fff'
        });
      }

      return this.wni.execute('wnAppChangeStatusBar', {
        'status-bar-style': 'dark',
        'background-color': '#ffffff'
      });
    }
  },

  beforeDestroy() {
    window.removeEventListener('replaceState', this.onReplaceState);
  },

  computed: {
    primaryText() {
      return this.$__t('{mobile-landing-page}.text.primary');
    },

    appIntroVisible() {
      if (!this.$store.state.auth.gid && !this.appIntroViewed) {
        Vue.component('AppIntroduction', () => import('@/components/outline/v2/AppIntroduction'));

        return true;
      }

      return false;
    },

    appIntroViewed() {
      return this.$store.state.appIntroViewed;
    }
  },

  methods: {
    onInitialize() {
      this.$log.log('onInitialize');

      this.$store
        .dispatch('app/initialize', {})
        .then((data) => {
          if (this.wni.isNative === true) {
            // App VersionCheck
            this.onAppUpdateCheck(data, () => {});
          }
        })
        .catch((e) => {
          this.$log.log(e);
        });
    },

    onAppUpdateCheck(data, handler) {
      this.$log.log('onAppUpdateCheck');

      let { update_status, update_link } = data;

      if (update_status === 'FORCE_UPDATE') {
        this.$store.commit('dialog/ADD_ITEM', {
          message: this.$__t('There is newer version of this application available, click Confirm to upgrade now.'),
          ok: {
            label: this.$__t('Confirm'),
            callback: () => {
              this.wni.execute('wnOpenURL', { url: update_link, exit: true });
            }
          }
        });
      } else if (update_status === 'NEED_UPDATE') {
        this.$store.commit('dialog/ADD_ITEM', {
          message: this.$__t('There is newer version of this application available, click YES to upgrade now?'),
          true: {
            label: this.$__t('예'),
            callback: () => {
              this.wni.execute('wnOpenURL', { url: update_link, exit: false });
            }
          },
          false: {
            label: this.$__t('아니오'),
            callback: () => {
              handler();
            }
          }
        });
      } else {
        handler();
      }
    },

    getIconSource(number) {
      let source = '';

      switch (number) {
        case 1:
          source = '/img/icon-list-gray.svg';
          if (this.selectedListIndex === 1) source = '/img/icon-list-white.svg';
          break;

        case 2:
          source = '/img/icon-home-gray.svg';
          if (this.selectedListIndex === 2) source = '/img/icon-home-white.svg';
          break;

        case 3:
          source = '/img/icon-more-gray.svg';
          if (this.selectedListIndex === 3) source = '/img/icon-more-white.svg';
          break;
      }

      return source;
    },

    getText(number) {
      let text = '';

      switch (number) {
        case 1:
          text = this.$__t('Usage');
          break;

        case 2:
          text = this.$__t('Home');
          break;

        case 3:
          text = this.$__t('More');
          break;
      }

      return text;
    },

    componentOnHash() {
      const PAGES = [
        {
          name: 'Tickets',
          regExp: /tickets/,
          component: MobileLandingTickets
        },
        {
          name: 'Home',
          regExp: /home/,
          component: Home
        },
        {
          name: 'More',
          regExp: /more/,
          component: LugNavMenu
        }
      ];

      return PAGES.find((page) => page.regExp.test(location.hash))?.component || PAGES[1].component;
    },

    onReplaceState() {
      this.targetComponent = this.componentOnHash();

      // if (this.targetComponent === MobileLandingHome) {
      //   return this.wni.execute('wnAppChangeStatusBar', {
      //     'status-bar-style': 'light',
      //     'background-color': '#008fff',
      //   });
      // }

      return this.wni.execute('wnAppChangeStatusBar', {
        'status-bar-style': 'dark',
        'background-color': '#ffffff'
      });
    },

    onSelectFooterItem(e) {
      let { hash } = e || {};

      if (hash) {
        window.history.replaceState({}, '', '#' + hash);
      }
    }
  },

  components: {
    Tickets: MobileLandingTickets,
    Home,
    LugNavMenu,
    NavBar
  }
};
</script>
