<template>
  <div class="page" id="cs-host-page">
    <div class="container">

      <div class="section" id="cs-host-header-section" style="background-image:url(/img/cs-host-background.jpg)">
        <div class="container">
          <div class="title">{{ $__t( "당신의 상점에 빈 공간이 있으신가요?" ) }}</div>
        </div>
      </div>

      <div class="section" id="cs-host-intro-section">
        <div class="container">
          <ul>
            <li>
              <div class="image"><img src="/img/host-form-icon-01.png" /></div>
              <div class="text">
                <div class="title">{{ $__t( "추가적인 수익창출" ) }}</div>
                <div class="description">{{ $__t( "빈 공간에 대한 보관수수료를 벌 수 있습니다." ) }}</div>
              </div>
            </li>
            <li>
              <div class="image"><img src="/img/host-form-icon-02.png" /></div>
              <div class="text">
                <div class="title">{{ $__t( "고객유입" ) }}</div>
                <div class="description">{{ $__t( "더 많은 고객을 매장으로 유치할 수 있습니다." ) }}</div>
              </div>
            </li>
            <li>
              <div class="image"><img src="/img/host-form-icon-03.png" /></div>
              <div class="text">
                <div class="title">{{ $__t( "홍보 효과" )}}</div>
                <div class="description">{{ $__t( "매장을 새로운 고객에게 홍보할 수 있는 기회입니다." ) }}</div>
              </div>
            </li>
            <li>
              <div class="image"><img src="/img/host-form-icon-04.png" /></div>
              <div class="text">
                <div class="title">{{ $__t( "무료 가입" ) }}</div>
                <div class="description">{{ $__t( "별도의 가입비나 설치비용이 필요 없습니다." ) }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section" id="cs-host-form-section">
        <div class="container">

          <div class="form">
            <div class="form-header">
              <div class="title">{{ $__t( "호스트 신청하기" ) }}</div>
              <div class="description">
                <p>{{ $__t( "럭스테이 호스트가 되어 혜택을 누리세요." ) }}</p>
              </div>
            </div>
            <div class="form-body">
              <ui-form @submit="submitForm" @error="scrollToError" :formData="formData" ref="form">
                <ui-form-list>
                  <ui-form-list-item>
                    <ui-textbox
                    v-ga="$analytics.tracking.bind(this, 'home', 'focus', 'host_information_business number')"
                    :placeholder="formData.biz_number.placeholder" :type="formData.biz_number.type" :label="formData.biz_number.label" :error="formData.biz_number.error" v-model="formData.biz_number.value" />
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <ui-textbox
                    v-ga="$analytics.tracking.bind(this, 'home', 'focus', 'host_information_name')"
                    :placeholder="formData.biz_name.placeholder" :type="formData.biz_name.type" :label="formData.biz_name.label" :error="formData.biz_name.error" v-model="formData.biz_name.value" />
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <ui-textbox
                    v-ga="$analytics.tracking.bind(this, 'home', 'focus', 'host_information_address')"
                    :placeholder="formData.biz_address.placeholder" :type="formData.biz_address.type" :label="formData.biz_address.label" :error="formData.biz_address.error" v-model="formData.biz_address.value" />
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <ui-textbox
                    v-ga="$analytics.tracking.bind(this, 'home', 'focus', 'host_information_phone_number')"
                    :placeholder="formData.biz_phone.placeholder" :type="formData.biz_phone.type" :label="formData.biz_phone.label" :error="formData.biz_phone.error" v-model="formData.biz_phone.value" />
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <ui-solid-button display="block"><button type="submit">{{ $__t( "호스트 신청하기" ) }}</button></ui-solid-button>
                  </ui-form-list-item>
                </ui-form-list>
              </ui-form>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      formData: {
        biz_number: {
          type: "text",
          label: this.$__t( "사업자 등록번호" ),
          placeholder: this.$__t( "사업자 등록번호를 입력해주세요." ),
          validation: [
            { type: "required" },
          ],
          value: "",
          error: ""
        },
        biz_name: {
          type: "text",
          label: this.$__t( "대표자명" ),
          placeholder: this.$__t( "대표자명를 입력해주세요." ),
          validation: [
            { type: "required" },
          ],
          value: "",
          error: ""
        },
        biz_address: {
          type: "text",
          label: this.$__t( "사업장 주소" ),
          placeholder: this.$__t( "사업장 주소를 입력해주세요." ),
          validation: [
            { type: "required" },
          ],
          value: "",
          error: ""
        },
        biz_phone: {
          type: "text",
          label: this.$__t( "대표전화" ),
          placeholder: this.$__t( "대표전화를 입력해주세요." ),
          validation: [
            { type: "required" },
          ],
          value: "",
          error: ""
        },
      }
    };
  },
  created(){

  },
  methods: {
    submitForm( values ){
      this.$store.dispatch( "host/create", values ).then( res => {
        let { host_id } = res.data
        this.$store.state.auth.host_id = host_id

        this.$store.dispatch( "host/refresh" ).then( res => {
          this.$refs.form.resetForm();
          this.$router.replace({ name: "HostStores" });
          this.$store.commit( "alert/ADD_ITEM", { message: "호스트 등록이 완료되었습니다.", status: "success" });
        });
      }).catch( err => {
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
    scrollToError(){
      setTimeout(() => {
        if( $( ".ui-form-list-item .error" ).length >= 0 ){
          $( "html, body" ).scrollTop( $( $( ".ui-form-list-item .error" )[0] ).offset().top - $( "#site-header" ).innerHeight() );
        };
      }, 0);
    },
  }
}
</script>
