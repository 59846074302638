<template>
  <div class="site-map v1">
    <div class="site-map__header">
      <div class="map-header__button-area">
        <button
          v-button
          @click="$router.back()"
          type="button"
          class="map-header__button map-header__button--left"
        >
          <img src="@/assets/icons/svg/arrow-left.svg" alt="back" />
        </button>

        <!-- <button
          v-button
          @click="openSidebar"
          type="button"
          class="map-header__button map-header__button--right"
        >
          <img src="@/assets/icons/svg/hamburger.svg" alt="sidebar" />
        </button> -->

      </div>
      <div class="map-header__search">
        <img src="/img/icon/search-lens-black.svg" alt="" class="lens-img" />
        <button
          v-button
          @click="openKeywordSearch('top')"
          class="map-header__button map-header__button--center"
          v-html="locationHeaderHtml"
        />
      </div>
      <div class="map-header__toggle-area">
        <button v-button @click="toggleStoreType('STORE')" type="button" class="toggle-button" :class="{ on: storeType === 'STORE' }">
          <span class="text">{{ $__t('상점') }}</span>
        </button>
        <button v-button @click="toggleStoreType('LOCKER')" type="button" class="toggle-button" :class="{ on: storeType === 'LOCKER' }">
          <span class="text">{{ $__t('라커') }}</span>
        </button>
      </div>
    </div>

    <div class="site-map__body">
      <div class="toggle-area">
        <button
          v-button
          @click="toggleStoreType('STORE')"
          type="button"
          class="toggle-button"
          :class="{ on: storeType === 'STORE' }"
        >
          <img src="/img/icon/store-icon.svg" alt="" />
          <span class="text">{{ $__t("상점") }}</span>
        </button>
        <button
          v-button
          @click="toggleStoreType('LOCKER')"
          type="button"
          class="toggle-button"
          :class="{ on: storeType === 'LOCKER' }"
        >
          <img src="/img/icon/locker-icon.svg" alt="" />
          <span class="text">{{ $__t("라커") }}</span>
        </button>
      </div>
      <div class="loading-area">
        <div v-if="lottieLoading" class="map-header__loading-container">
          <lottie :options="lottieOptions" :height="200" :width="200"></lottie>
        </div>
      </div>
    </div>

    <div
      class="site-map__footer"
      :style="activatedLocation ? { 'padding-bottom': '16.5rem' } : null"
    >
      <div class="map-footer__button-area">
        <div class="map-footer__util-area">
          <div class="container">
            <!--돋보기-->
            <!-- <button v-button class="util-button" @click="openKeywordSearch('top')">
              <img src="@/assets/icons/svg/search.svg" />
            </button> -->

            <button v-button class="util-button" @click="setCenterWithMyLocation">
              <img src="@/assets/icons/svg/map.svg" />
            </button>
          </div>
        </div>

        <div
          v-if="storeType === 'STORE'"
          class="map-footer__button time-banner"
          :class="{ 'store-activated': !!activatedLocation }"
        >
          <button
            v-button
            type="button"
            @click="onClickTimeButton"
            class="time-banner__button"
          >
            <div class="content">
              <!-- <img src="@/assets/icons/svg/clock-blue.svg" /> -->
              <!-- <div class="text">
                {{ usageTimeHeader }}
              </div> -->
              <!-- <div class="selected-time">
                {{ usageTimeText }}
              </div> -->
              <div class="text">
                {{ usageTimeText }}
              </div>
            </div>
          </button>
          <!--잠깐!이용시간은 설정하셨나요?메시지-->
          <!-- <div v-if="!isReserveInfoSet" :class="{ hovered: activatedLocation }" class="map-footer__alert alert-balloon">
            <div class="content">
              <img src="/img/icon/megaphone.svg" class="content__icon" />
              <span class="content__text">{{ $__t('{stores}.is_date_changed') }}</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <Map
      @dragstart="onDragStart"
      @drag="onDrag"
      @dragend="onDragEnd"
      @zoom_changed="onZoomChanged"
      @click="onMapClick"
      @marker-click="onMarkerClick"
      @map-load="initiate"
      @tilesloaded="onTilesLoaded"
      @drawn="onMarkerDrawn"
      @bounds="onBounds"
      @radius="onRadius"
      @center_changed="onCenterChanged"
      :locations="locations"
      :activated-location="activatedLocation"
      :zoom="zoom"
    />

    <!-- <ui-slider v-if="!activatedLocation" id="condition-modal" ref="condition-modal" :bgClick="true" :background="true" :message="true" :center="true"> -->
    <ui-slider
      id="condition-modal"
      ref="condition-modal"
      :bgClick="true"
      :background="true"
      :message="true"
      :center="true"
    >
      <Condition
        @close="closeModal('condition-modal')"
        @change="onConditionChange"
        :activatedStore="activatedLocation"
        :center="mapResources.center"
      ></Condition>
    </ui-slider>

    <!-- <guide-modal id="condition-modal" ref="condition-modal" :transparent="true">
      <template v-slot:body>
        <Condition
          @close="closeModal('condition-modal')"
          @change="onConditionChange"
          :activatedStore="activatedLocation"
          :center="mapResources.center"
        ></Condition>
      </template>
    </guide-modal> -->

    <transition name="slide-left" mode="out-in">
      <KeywordSearch v-if="keywordSearchOpened" :callback="searchCallback" />
    </transition>

    <StoreSlider
      v-if="activatedLocation"
      :store_id="activatedLocation.id"
      :formData="reserveFormState"
      :disabled="locationDisabled"
      :disabledReason="locationDisabledReason"
      :distance="distanceWithActivatedStore"
      @reserve="reserve(activatedLocation.id)"
    />

    <guide-modal id="configNeedModal" ref="configNeedModal" :noBgClick="true">
      <template v-slot:body>
        <div class="content">
          {{ $__t("이용시간 및 물품 개수를 설정해주세요.") }}
        </div>
        <div class="button-area">
          <ui-text-button
            color="primary"
            size="small"
            display="block"
            @click="
              closeModal('configNeedModal');
              openModal('condition-modal');
            "
          >
            <button type="button">{{ $__t("Confirm") }}</button>
          </ui-text-button>
        </div>
      </template>
    </guide-modal>
  </div>
</template>

<script>
import Map from "@/components/resources/map/Map.vue";
import Condition from "@/components/pages/store/StoresSearchCondition.vue";
import KeywordSearch from "@/components/pages/v2/PlaceSearch.vue";
import StoreSlider from "@/components/resources/store/StoreSlider.vue";
import Lottie from "vue-lottie";
import _ from "lodash";
import LottieLoading from "@/assets/lottie/lottie-map-loading.json";
import UiSlider from "@/components/modules/Slider/Body.vue";

export default {
  name: "Stores",

  data() {
    const { date_start, date_end } = this.getDefaultDates();

    return {
      suggestOpened: false,
      keywordSearchOpened: false,
      locations: [],
      activatedLocation: null,

      defaultStartDate: date_start,
      defaultEndDate: date_end,

      loadStatus: "unset", // [unset, idle, loading, loaded, myLocationLoading, error]
      loadPaging: {
        page: 1,
        size: 10,
        total: 0,
      },

      locationDisabled: false,
      locationDisabledReason: "",
      distanceWithActivatedStore: 0,

      lottieOptions: {
        animationData: LottieLoading,
      },

      lottieLoading: true,

      storeType: this.$route.query.store_type || "STORE",
      defaultZoom: this.$route.query.zoom ? parseInt(this.$route.query.zoom) : 17,
      zoom: this.$route.query.zoom ? parseInt(this.$route.query.zoom) : 17,
    };
  },

  watch: {
    stores(newStores, oldStores) {
      if (typeof newStores === "object" && newStores.length > 0) {
        this.locations = newStores
          .map((store) => {
            if (store) {
              return {
                id: store.id,
                geo_latitude: store.geo_latitude,
                geo_longitude: store.geo_longitude,
                store_type: store.store_type,
                store_name: store.store_name,
                bookable: store.bookable === false ? false : true,
                free_pass: !!(
                  (Array.isArray(store.products) && store.products.length > 0) ||
                  (Array.isArray(store.passes) && store.passes.length > 0)
                ),
              };
            }
          })
          .filter((o) => o.store_type === this.storeType);
      } else {
        this.locations = oldStores
          .map((store) => {
            if (store) {
              return {
                id: store.id,
                geo_latitude: store.geo_latitude,
                geo_longitude: store.geo_longitude,
                store_type: store.store_type,
                store_name: store.store_name,
                bookable: store.bookable === false ? false : true,
                free_pass: !!(
                  (Array.isArray(store.products) && store.products.length > 0) ||
                  (Array.isArray(store.passes) && store.passes.length > 0)
                ),
              };
            }
          })
          .filter((o) => o.store_type === this.storeType);
      }
    },
  },

  computed: {
    stores() {
      return this.$store.getters["stores/GET_ITEMS_BY_LIST"]({
        list: `SearchedStores_${this.storeType}`,
      }).filter((store) =>
        this.storeType ? store?.store_type === this.storeType : true
      );
    },

    reserveFormState() {
      return this.$store.getters["resourceForm/GET_ITEM"]({
        key: "form_id",
        value: "reserve_form",
      });
    },

    isReserveInfoSet() {
      return (
        this.reserveFormState?.date_start &&
        this.reserveFormState?.date_end &&
        (this.reserveFormState?.quantity_big || this.reserveFormState?.quantity_small)
      );
    },

    mapResources() {
      // console.log('this.$store.state.resourceMap', this.$store.state.resourceMap);
      return this.$store.state.resourceMap;
    },

    mapInstance() {
      return this.$store.state.resourceMap.map;
    },

    locationHeaderHtml() {
      let { keyword } = this.reserveFormState || {};

      let iconSource = "/img/icon/map-current-location.svg";

      if (this.activatedLocation) {
        if (this.activatedLocation?.store_type === "LOCKER") {
          iconSource = "/img/icon-map-locker-location.svg";
        }

        if (this.activatedLocation?.bookable === false) {
          iconSource = "/img/icon-map-unavailable-store-location.svg";
        } else {
          iconSource = "/img/icon-map-available-store-location.svg";
        }
      }

      let iconHtml = `<img src="${iconSource}" class="map-header__button-icon"/>`;

      if (!keyword) {
        iconHtml = "";
        keyword = this.$__t("{stores}.header.search.text");
      }

      let locationTextHtml = `<span class="map-header__button-text ${
        keyword === this.$__t("{stores}.header.search.text") ? "button-text--empty" : ""
      }">${keyword}</span>`;

      // return iconHtml + locationTextHtml;
      return locationTextHtml;
    },

    usageTimeHeader() {
      const dateStart = this.reserveFormState?.date_start || this.defaultStartDate;
      const dateEnd = this.reserveFormState?.date_end || this.defaultEndDate;

      let dateDiff = this.$moment(this.$moment(dateStart)).diff(this.$moment(dateEnd));

      let minutes = Math.abs(this.$moment.duration(dateDiff).minutes());
      let hours = Math.abs(this.$moment.duration(dateDiff).hours());
      let days = Math.floor(Math.abs(this.$moment.duration(dateDiff).asDays()));

      let value = "";

      if (days > 0) {
        value += " " + days + this.$__t("d");
      }

      if (hours > 0) {
        value += " " + hours + this.$__t("_h");
      }

      if (minutes > 0) {
        value += " " + minutes + this.$__t("m");
      }

      return this.isReserveInfoSet
        ? this.$__t("total") + value + " " + this.$__t("use")
        : this.$__t("이용시간 및 물품 개수 설정하기");
    },

    usageTimeText() {
      // if (!this.isReserveInfoSet) return this.$__t('상점의 영업시간에만 보관과 찾기가 가능합니다.');
      // console.log('this.reserveFormState', this.reserveFormState);
      if (!this.isReserveInfoSet) return this.$__t("이용시간 및 물품 개수 설정하기");

      const dateStart = this.reserveFormState?.date_start || this.defaultStartDate;
      const dateEnd = this.reserveFormState?.date_end || this.defaultEndDate;

      const momentStartDate = this.$moment(dateStart);
      const momentEndDate = this.$moment(dateEnd);

      let value = "";

      if (momentStartDate.format("YYYY-MM-DD") === this.$moment().format("YYYY-MM-DD")) {
        if (momentStartDate.format("YYYY-MM-DD") === momentEndDate.format("YYYY-MM-DD")) {
          value +=
            this.$__t("{date-time-picker}.today") +
            " " +
            momentStartDate.format("HH:mm") +
            " ~ " +
            momentEndDate.format("HH:mm");
        } else {
          value +=
            this.$__t("{date-time-picker}.today") +
            " " +
            momentStartDate.format("HH:mm") +
            " ~ " +
            momentEndDate.format("MM/DD HH:mm");
        }
      } else if (
        momentStartDate.format("YYYY-MM-DD") !== momentEndDate.format("YYYY-MM-DD")
      ) {
        value +=
          momentStartDate.format("MM/DD HH:mm") +
          " ~ " +
          momentEndDate.format("MM/DD HH:mm");
      } else {
        value +=
          momentStartDate.format("MM/DD HH:mm") + " ~ " + momentEndDate.format("HH:mm");
      }

      value += `${!!this.ticketQuantity ? " / " + this.ticketQuantity : ""}`;

      let itemNum = 0;
      let item = localStorage.getItem("lugstay_language") === "ko" ? "개" : "item";
      itemNum =
        this.reserveFormState?.quantity_small +
        this.reserveFormState?.quantity_big +
        item;

      return value + " • " + itemNum;
    },
  },

  mounted() {
    if (!this.reserveFormState) {
      this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: "reserve_form",
          date_start: this.defaultStartDate,
          date_end: this.defaultEndDate,
        },
      });
      setTimeout(() => {
        this.onClickTimeButton();
      }, 1500);
    } else {
      // setTimeout(() => {
      //   this.onClickTimeButton();
      // }, 1500);
      return;
    }

    this.wni.execute("wnAppChangeStatusBar", {
      "status-bar-style": "light",
      "background-color": "#000000",
    });
  },

  created() {
    this.$store.commit("stores/CLEAR_ITEMS");
    this.$store.commit("stores/CLEAR_LIST", {
      list: "SearchedStores",
    });
  },

  // activated() {},

  // deactivated() {
  //   this.cancelLoading();
  // },

  beforeDestroy() {
    this.cancelLoading();

    this.wni.execute("wnAppChangeStatusBar", {
      "status-bar-style": "dark",
      "background-color": "#ffffff",
    });

    clearInterval(this._intervalInstance);
    this._intervalInstance = null;
  },

  methods: {
    async toggleStoreType(store_type) {
      // console.log('toggle');
      if (store_type !== "LOCKER" && store_type !== "STORE") return;

      if (store_type === "LOCKER") {
        this.storeType = "LOCKER";
      } else if (store_type === "STORE") {
        this.storeType = "STORE";
      }

      this.onStoreTypeChanged();
    },

    async onStoreTypeChanged() {
      this.lottieLoading = true;
      this.activatedLocation = null;
      this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: "reserve_form",
          keyword: "",
        },
      });

      window.history.replaceState(
        {},
        "",
        this.$route.path +
          this.$utils.generateQueryString({
            ...this.$route.query,
            store_type: this.storeType,
          })
      );
      await this.loadStores();

      setTimeout(() => {
        this.lottieLoading = false;
      }, 1000);
    },

    onTilesLoaded() {},

    onBounds(e) {
      if (e) {
        this._bounds = e;
      }
    },

    onRadius(radius) {
      if (radius) {
        this._radius = radius;
      }
    },

    onMapClick() {
      this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: "reserve_form",
          keyword: "",
        },
      });

      this.activatedLocation = null;
    },

    onZoomChanged() {
      this.loadStores();
    },

    onDragEnd() {
      // this.loadStores();
    },

    getRadius() {
      const ne = this.mapInstance.getBounds().getNorthEast();
      return google.maps.geometry.spherical.computeDistanceBetween(
        this.mapInstance.getCenter(),
        ne
      );
    },

    onDragStart() {
      this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: "reserve_form",
          keyword: "",
        },
      });
    },

    onDrag() {
      if (this._watchId) {
        navigator.geolocation.clearWatch(this._watchId);
      }
      this.loadStores();
    },

    onCenterChanged(e) {
      this.loadPaging.page = 1;
    },

    onMarkerDrawn() {
      if (this._markerTimeoutInstance) {
        clearTimeout(this._markerTimeoutInstance);
        this._markerTimeoutInstance = null;
      }

      this._markerTimeoutInstance = setTimeout(async () => {
        this._markerTimeoutInstance = null;
      }, 500);
    },

    async onConditionChange() {
      this.loadPaging.page = 1;
      this._activatedLocation = _.cloneDeep(this.activatedLocation);

      this.$store.commit("stores/CLEAR_ITEMS");
      this.$store.commit("stores/CLEAR_LIST", {
        list: "SearchedStores",
      });

      this.locations = [];
      this.loadStatus = "loading";

      if (this._activatedLocation) {
        this.mapInstance.setCenter(
          new google.maps.LatLng(
            this._activatedLocation.geo_latitude,
            this._activatedLocation.geo_longitude
          )
        );

        await this.checkAvailable();
        await this.loadStores();

        return (this._intervalInstance = setInterval(() => {
          const targetLocation = this.locations.find(
            (o) => o.id === this.activatedLocation?.id
          );
          if (targetLocation) {
            this.activateLocation(targetLocation);
            clearInterval(this._intervalInstance);
            this._intervalInstance = null;
          }
        }, 100));
      }

      return this.loadStores();
    },

    async initiate() {
      this.loadStores();
      const initiateType = this.$route.query.autoSearch;
      let _keyword = "";

      if (!initiateType && this.storeType !== "LOCKER") {
        return await this.setCenterWithMyLocation();
      }

      try {
        switch (initiateType) {
          case "text":
            var text = this.$route.query.autoSearchText;
            _keyword = text;

            setTimeout(() => {
              this.submitSearch();
            }, 0);
            break;
          case "latlng":
            var latlng = this.$route.query.autoSearchLatlng.split(",");
            this.mapInstance.setCenter(
              new google.maps.LatLng(parseFloat(latlng[0]), parseFloat(latlng[1]))
            );
            break;

          case "my":
            this.setCenterWithMyLocation();
            break;

          case "target":
            const store_id = this.$route.query?.sid;

            if (store_id) {
              let res = await this.$store.dispatch("stores/get", {
                id: store_id,
              });
              const store = res?.data?.data;

              if (store) {
                _keyword = store.store_name;
                this.mapInstance.setCenter(
                  new google.maps.LatLng(store.geo_latitude, store.geo_longitude)
                );

                await this.loadStores();

                this._intervalInstance = setInterval(() => {
                  const targetStore = this.locations.find((o) => o.id === store.id);

                  if (targetStore) {
                    clearInterval(this._intervalInstance);
                    this._intervalInstance = null;
                    this.activateLocation(targetStore);
                  }
                }, 100);
              }
            }
            break;
        }
      } catch (e) {
        console.error(e);
        await this.setCenterWithMyLocation();
      } finally {
        this.$store.commit("resourceForm/SET_ITEM", {
          data: {
            form_id: "reserve_form",
            keyword: _keyword,
          },
        });
      }
    },

    loadStores() {
      if (this._timeoutInstance) {
        clearTimeout(this._timeoutInstance);
        this._timeoutInstance = null;
      }

      this._timeoutInstance = setTimeout(async () => {
        try {
          this._timeoutInstance = null;
          this.$data.loadStatus = "loading";
          await this.loadLocations();

          if (this._firstLoaded === false) {
            this._firstLoaded = true;
            this.lottieLoading = true;
          } else {
            this.lottieLoading = false;
          }
        } catch (e) {
          if (this.$store.getters["network"].isCancel(e)) {
            return;
          }
          console.error(e);
          this.loadStatus = "error";
        }
      }, 500);
    },

    cancelLoading() {
      if (this._timeoutInstance) {
        clearTimeout(this._timeoutInstance);
        this._timeoutInstance = null;
      }

      this.$data.loadStatus = "unset";
    },

    loadLocations() {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await this.getStoresAround();
          if (!data) return;

          const { items, count } = data;

          if (count < this.loadPaging.size) {
            this.loadStatus = "loaded";
            return resolve(items);
          } else {
            this._pageLoadable = true;

            while (this._pageLoadable) {
              this.loadPaging.page += 1;
              const { count } = await this.getStoresAround({
                page: this.loadPaging.page,
              });
              if (count < this.loadPaging.size) {
                this._pageLoadable = false;
              }
            }

            this.loadStatus = "loaded";
            resolve([]);
          }
        } catch (e) {
          if (!this.$store.getters["network"].isCancel(e)) {
            console.error(e);
            this.$data.loadStatus = "error";
          }

          reject(e);
        }
      });
    },

    async activateLocation(location = {}) {
      this.activatedLocation = this.locations.find((o) => o.id === location.id) || null;

      this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: "reserve_form",
          keyword: location.store_name,
        },
      });
    },
    //Map Events

    async getStoresAround(options = {}) {
      try {
        const center = this.mapInstance.getCenter();

        const latitude = center.lat();
        const longitude = center.lng();
        const query = {
          latitude,
          longitude,
          date_start: this.reserveFormState?.date_start || this.defaultStartDate,
          date_end: this.reserveFormState?.date_end || this.defaultEndDate,
          radius: this.getRadius(),
          quantity:
            this.reserveFormState?.quantity_small + this.reserveFormState?.quantity_big ||
            1,
          store_type: this.storeType,
          ...options,
          page: options.page || this.loadPaging.page,
          size: options.size || this.loadPaging.size,
        };

        let data = await this.$store.dispatch("stores/getAround", {
          query,
          list: `SearchedStores_${this.storeType}`,
          options: { clearGroup: true },
        });

        setTimeout(this.suggestOpen, 0);

        this._lastLoadedCenter = { lat: latitude, lng: longitude };

        return data;
      } catch (e) {
        throw new Error(e);
      }
    },

    async checkAvailable() {
      if (this.activatedLocation?.store_type === "LOCKER") {
        this.locationDisabled = false;
        this.locationDisabledReason = "";
        return;
      }

      let { date_start, date_end, quantity_small, quantity_big } =
        this.reserveFormState || {};

      if (!(date_start && date_end)) {
        ({ date_start, date_end } = this.getDefaultDates());
      }

      return await this.$store
        .dispatch("orders/check", {
          query: {
            store_id: this.activatedLocation.id,
            start_date: date_start,
            end_date: date_end,
            quantity: quantity_small + quantity_big || 1,
          },
        })
        .then(() => {
          this.locationDisabled = false;
          this.locationDisabledReason = "";
        })
        .catch((error) => {
          this.locationDisabled = true;
          this.locationDisabledReason = error;
        });
    },

    async setCenterWithMyLocation() {
      if (this._watchId) {
        navigator.geolocation.clearWatch(this._watchId);
      }

      const func = _.debounce(async () => {
        this.lottieLoading = true;
        setTimeout(() => {
          this.lottieLoading = false;
        }, 5000);
        this.loadStatus = "myLocationLoading";
        this.activatedLocation = null;

        try {
          const center = await new Promise((resolve, reject) => {
            if (this.wni.isNative) {
              this.wni.execute("wnPermissionLocation", {
                auto_start: true,
                callback: this.wni.cb((result) => {
                  let { status } = result;
                  console.log('status', status);

                  // @TODO: Permission Location
                  // this.wni.execute('wnOpenAppSetting', { type: 'general' });

                  if (status.toLowerCase() === "granted") {
                    this.wni.execute("wnLocationCurrent", {
                      callback: this.wni.cb(async (result = {}) => {
                        const { latitude, longitude } = result;
                        const center = new google.maps.LatLng(latitude, longitude);
                        this.mapInstance.setCenter(center);
                        this.mapInstance.setZoom(this.$data.defaultZoom);
                        await this.$utils.wait(0);
                        this.lottieLoading = false;

                        let data = await this.$store.dispatch("location/reverseGeocode", {
                          query: { latitude, longitude },
                        });
                        // console.log('this.mapResources?.zoom', this.mapResources?.zoom);
                        data = await this.axios.post(
                          this.$store.state.config.apiURL + "/v2/log/location",
                          {
                            location_zoom: this.mapResources?.zoom,
                            location_latitude: latitude,
                            location_longitude: longitude,
                            keyword: "",
                            address: data?.jibunAddress,
                          }
                        );

                        resolve(center);
                      }),
                    });
                  } else {
                    reject(new Error("ACCESS_DENIED"));
                    this.loadStatus = "error";
                  }
                }),
              });
            } else if (typeof navigator?.geolocation?.watchPosition === "function") {
              this._watchId = navigator.geolocation.watchPosition(
                async (result) => {
                  const { latitude, longitude } = result?.coords || {};
                  const center = new google.maps.LatLng(latitude, longitude);
                  this.mapInstance.setCenter(center);
                  this.mapInstance.setZoom(this.$data.defaultZoom);
                  await this.$utils.wait(0);
                  this.lottieLoading = false;

                  let data = await this.$store.dispatch("location/reverseGeocode", {
                    query: { latitude, longitude },
                  });
                  data = await this.axios.post(
                    this.$store.state.config.apiURL + "/v2/log/location",
                    {
                      // location_zoom: this.mapInstance.getZoom(),
                      location_zoom: this.mapResources?.zoom,
                      location_latitude: latitude,
                      location_longitude: longitude,
                      keyword: "",
                      address: data?.jibunAddress,
                    }
                  );

                  resolve(center);
                },
                (error) => {
                  this.loadStatus = "error";
                  reject(error);
                },
                {
                  timeout: 5000,
                }
              );
            } else {
              this.loadStatus = "error";
              reject(new Error("ACCESS_DENIED"));
            }
          });

          if (this._currentLocationMarker) {
            this._currentLocationMarker.setPosition(center);
          } else {
            this._currentLocationMarker = new google.maps.Marker({
              position: center,
              map: this.mapInstance,
              icon: {
                url: "/img/icon/map-current-location-marker.svg",
                scaledSize: new google.maps.Size(24, 24),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 12),
              },
            });
          }

          this.$store.commit("resourceForm/SET_ITEM", {
            data: {
              form_id: "reserve_form",
              keyword: this.$__t("{delivery-status}.header.current_location"),
            },
          });

          this.loadStores();
        } catch (e) {
          console.error(e);
          this.loadStores();
        }
      }, 100);

      func();
    },

    async submitSearch() {
      try {
        const { keyword } = this.reserveFormState || {};

        let data = await this.$store.dispatch("location/geocode", {
          query: { address: keyword, language: this.$store.state.lang },
        });

        let items = data?.items;

        if (Array.isArray(items) && items.length > 0) {
          const { geo, store_id, zoom } = items[0];

          this.mapInstance.setCenter(new google.maps.LatLng(geo));

          if (store_id) {
            this._intervalInstance = setInterval(() => {
              const store = this.locations.find((o) => o.id === store_id);

              if (store) {
                this.activateLocation(store);
                this.mapInstance.setZoom(this.$data.defaultZoom);
                clearInterval(this._intervalInstance);
                this._intervalInstance = null;
              }
            }, 100);

            setTimeout(() => {
              if (this._intervalInstance) {
                this.mapInstance.setZoom(zoom);
                clearInterval(this._intervalInstance);
                this._intervalInstance = null;
              }
            }, 3000);
          }
        }

        return;
      } catch (e) {
        console.error(e);
      }
    },

    async searchCallback() {
      if (this._watchId) {
        navigator.geolocation.clearWatch(this._watchId);
      }

      try {
        await this.submitSearch();
        this.loadStores();
      } catch (e) {
        console.error(e);
      }
    },

    async onMarkerClick(marker = {}) {
      try {
        this.locationDisabledReason = "";
        this.locationDisabled = marker.bookable !== true;

        this.activateLocation(marker);
        await this.checkAvailable();

        if (this._currentLocationMarker) {
          this.$data.distanceWithActivatedStore = await google.maps.geometry.spherical.computeDistanceBetween(
            this._currentLocationMarker.position,
            new google.maps.LatLng({
              lat: marker.geo_latitude,
              lng: marker.geo_longitude,
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
    },

    suggestOpen() {
      if (this.mapInstance.getZoom() < 8) return;

      if (this.stores.length < 1) {
        this.suggestOpened = true;
      }
    },

    getStoreType(store = {}) {
      return store?.store_type;
    },

    getDefaultDates() {
      const momentNow = this.$moment();

      const date_start = this.$utils.getClosestValidDateTime(momentNow);
      const date_end = this.$utils.getClosestValidDateTime(momentNow.add(10, "minutes"));

      return {
        date_start,
        date_end,
      };
    },

    onClickTimeButton() {
      this.openModal("condition-modal");
    },

    openKeywordSearch() {
      this.keywordSearchOpened = true;
    },

    openModal(ref) {
      return this.$refs[ref].open();
    },

    closeModal(ref) {
      return this.$refs[ref].close();
    },

    openSidebar() {
      return this.$parent.toggleSidebar();
    },

    reserve(id) {
      if (!this.isReserveInfoSet && this.activatedLocation?.store_type !== "LOCKER") {
        this.$analytics.logEvent("time setting alarm");

        return this.openModal("configNeedModal");
      }

      window.history.replaceState({}, "", `/stores?autoSearch=target&sid=${id}`);

      setTimeout(() => {
        this.$router.push({
          name: "StoreSingle",
          params: { store_id: id, formData: { ...this.reserveFormState } },
        });
      }, 0);
    },
  },

  components: {
    Map,
    KeywordSearch,
    Condition,
    StoreSlider,
    Lottie,
    UiSlider,
  },
};
</script>
