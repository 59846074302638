<template>
  <div id="incheon-survey-page">
    <div class="background-container">
      <div class="container">
        <div class="text">
          <div class="logo">
            <img src="/img/incheon-lugstay-logo.png" alt="인천 x 럭스테이 로고" />
          </div>
          <div class="primary">
            이제 <b>인천에서도</b><br />
            럭스테이로<br />
            <b>주변상점</b>에 <b>물품 맡기고</b><br />
            <b>가볍게</b> 다니세요!
          </div>
          <div class="explanatory">
            <span>쿠폰 이벤트기간 : 21년 7월 1일 ~ 8월 31일까지</span>
            <div class="extra">(발급쿠폰은 9월 30일까지 사용가능)</div>
          </div>
          <div class="secondary">
            인천 터미널역, 주안역, 동인천역, 송도 센트럴파크,<br />
            해돋이공원, 월미도 등 <strong>인천 전 지역 100여개 상점</strong> 이용 가능
          </div>
        </div>
        <div class="coupon">
          <img src="/img/incheon-survey-image.svg" alt="인천 x 럭스테이 이미지" />
        </div>
        <div class="button-area">
          <button type="button" @click="onClickAppDownload">
            앱 다운로드
          </button>
          <button type="button" @click="onClickGetFreeCoupon">
            무료쿠폰 받기
          </button>
        </div>
        <div class="notice">
          본 이벤트는 참여 인원에 따라 조기 마감 될 수 있습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadScript from "load-script";
const TYPE_FORM_ID = "KcWumukn";

export default {
  data() {
    return {};
  },

  mounted() {
    setTimeout(() => this.$analytics.logTrack("track", "survey_incheon_pageview"), 0)
  },

  methods: {
    onClickAppDownload() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.$analytics.logTrack("track", "survey_incheon_ButtonforApplinstall")

      if (/android/i.test(userAgent)) {
        window.open("https://bit.ly/럭스테이플레이스토어", "LugStay Google Play Store", "_blank");
        return;
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open("https://bit.ly/럭스테이앱스토어", "LugStay Apple App Store", "_blank");
        return;
      }

      window.open("https://bit.ly/럭스테이플레이스토어", "LugStay Google Play Store", "_blank");
    },

    onClickGetFreeCoupon() {
      loadScript("https://embed.typeform.com/embed.js", (error, script) => {
        if (error) {
          this.$store.commit("alert/ADD_ITEM", { message: error, status: "error" });
          return;
        }

        const url = `https://form.typeform.com/to/${TYPE_FORM_ID}`;
        const options = {
          mode: "popup",
          open: "time",
          openValue: 10,
          hideScrollbars: true,
          onSubmit: this.onSubmitSurvey,
        };
        try {
          this.$analytics.logTrack("track", "survey_incheon_TypeformFirstInteraction")
          window.typeformEmbed.makePopup(url, options);
        } catch (e) {
          this.$log.error(e);
        }
      });
    },

    async onSubmitSurvey(e) {
      this.$analytics.logTrack("track", "survey_incheon_TypeformSubmit")

      const { response_id } = e;
      try {
        if (response_id) {
          let res = await this.axios.post(this.$store.state.config.apiURL + "/v2/iez/survey", { response_id }, { headers: this.$store.state.config.userHeaders });

          let { error, data } = res.data;

          if (data === "exceeded") {
            alert("더이상 쿠폰을 발행할 수 없습니다.");
            window.location.href = "/";
          }
        }
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      }
    },
  },
};
</script>
