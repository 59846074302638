export default [

  // Reserve
  // 서비스 예약
  // {
  //   name: 'ServiceReserve',
  //   path: '/services/reserve/:order_id',
  //   component: () => import('@/v3/pages/product/service/ServiceReserve.vue'),
  //   meta: {
  //     headerType: 'more-depth',
  //     hideChannelTalk: true,
  //     hideSidebar: true,
  //     hideFooter: true,
  //     level: 1,
  //     reserve_form: true
  //   },
  //   props: true
  // },
  // {
  //   name: "ServiceReservePayMethods",
  //   path: "/services/reserve/:order_id/pay-methods",
  //   component: () => import("@/v3/pages/product/service/ServiceReservePayMethods.vue"),
  //   meta: {
  //     headerType: "more-depth",
  //     title: "결제수단 선택",
  //     hideChannelTalk: true,
  //     hideSidebar: true,
  //     hideFooter: true,
  //     level: 1,
  //     reserve_form: true,
  //   },
  //   props: true,
  // },

  // // 일반 물품보관
  // {
  //   name: 'ReserveOrder',
  //   path: '/reserve/:order_id',
  //   component: () => import('@/v3/pages/product/luggage/LuggageReserve.vue'),
  //   meta: {
  //     headerType: 'more-depth',
  //     hideChannelTalk: true,
  //     hideSidebar: true,
  //     hideFooter: true,
  //     level: 1,
  //     reserve_form: true
  //   },
  //   props: true,
  // },
  // {
  //   name: "ReservePayMethods",
  //   path: "/reserve/:order_id/pay-methods",
  //   component: () => import("@/v3/pages/product/luggage/LuggageReservePayMethods.vue"),
  //   meta: {
  //     headerType: "more-depth",
  //     title: "결제수단 선택",
  //     hideChannelTalk: true,
  //     hideSidebar: true,
  //     hideFooter: true,
  //     level: 1,
  //     reserve_form: true,
  //   },
  //   props: true,
  // },


  // Ticket
  // {
  //   name: 'TicketSingle',
  //   path: '/my/reservation/tickets/:id',
  //   component: () => import('@/v3/pages/ticket/_TicketSingle.vue'),
  //   props: true,
  //   meta: { hideHeader: true, hideFooter: true, hideChannelTalk: true, level: 1 }
  // },
  
  // New Version Pages
  // {
  //   name: 'Home',
  //   path: '/',
  //   component: () => import('@/v3/pages/home/Home.vue'),
  //   meta: { fetchData: true, hideHeader: true, hideChannelTalk: true, allowPc: true }
  // },
  {
    name: 'BillingListAndChangePrimary',
    path: '/billing/list',
    component: () => import('@/v3/pages/billing/BillingListAndChangePrimary.vue'),
    meta: {
      headerType: 'more-depth',
      title: '',
      hideChannelTalk: true,
      fetchData: true,
      hideFooter: true,
      allowPc: true,
      level: 1
    },
    props: true
  },
  {
    name: 'SelectPaymentForAdd',
    path: '/payment/select',
    component: () => import('@/v3/pages/payment/SelectPaymentForAdd.vue'),
    meta: {
      headerType: 'more-depth',
      title: '',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      allowPc: true,
      level: 1
    },
    props: true
  },
  {
    name: 'AddPaymentEximbay',
    path: '/payment/add/eximbay',
    component: () => import('@/v3/pages/payment/AddPaymentEximbay.vue'),
    meta: {
      headerType: 'more-depth',
      title: '',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      allowPc: true,
      level: 1
    },
    props: true
  },
  {
    name: 'AddPaymentNicePay',
    path: '/payment/add/nicepay',
    component: () => import('@/v3/pages/payment/AddPaymentNicePay.vue'),
    meta: {
      headerType: 'more-depth',
      title: '',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      allowPc: true,
      level: 1
    },
    props: true
  },

  // for KakaoPay
  {
    name: 'AddPaymentKakaoPay',
    path: '/payment/add/kakaopay/:state',
    component: () => import('@/v3/pages/payment/AddPaymentKakaoPay.vue'),
    meta: {
      headerType: 'more-depth',
      title: '',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      allowPc: true,
      level: 1
    },
    props: true
  }
];
