<template>
  <div class="more-list" @click.stop="">
    <div class="container">
      <div class="body">
        <ul class="list-section">
          <li
            v-for="(item, index) in data.items"
            :key="`${data.title}-item-${index}`"
            :class="{ active: item.active === true }"
          >
            <button type="button" v-button @click="item.action()" :class="{ 'link-button': item.link === true }">
              <span>{{ item.title | capitalizeFirstLetter }}</span>
              <img v-if="item.link === true" alt="arrow" src="/img/arrow-right@3x.png" />
            </button>
            <img v-if="item.active === true" alt="checked" src="/img/icon-checked.svg" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        title: this.$__t("이용안내"),
        items: [
          {
            title: this.$__t("이용방법"),
            action: () => {
              this.$router.push({ name: "CsGuide" }).finally(() => this.$emit("close-sidebar"));
            },
            link: true,
          },
          {
            title: this.$__t("Price"),
            action: () => {
              this.$router.push({ name: "Price" }).finally(() => this.$emit("close-sidebar"));
            },
            link: true,
          },
          {
            title: this.$__t("자주 찾는 질문"),
            action: () => {
              this.$router.push({ name: "CsFaq" }).finally(() => this.$emit("close-sidebar"));
            },
            link: true,
          },
        ],
      },
    };
  },
};
</script>
