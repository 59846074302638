<template>
  <transition name="slide-up">
    <div
      class="lg-popup"
      :class="{ external: externalClose }"
      v-if="opened"
      ref="background"
      :style="{ backgroundColor: transparent ? 'transparent' : null }"
    >
      <div class="lg-popup-container" ref="viewport">
        <img v-if="!noCloseButton" @click.stop="close" src="/img/modal-close.png" srcset="/img/modal-close@2x.png 2x, /img/modal-close@3x.png 3x" />
        <div class="header" v-if="headerTitle">
          <span class="title">{{ headerTitle }}</span>
        </div>
        <div class="body"><slot name="body" /></div>
        <div class="button-area" v-if="noAppear">
          <button type="button" @click.stop="close(true)">
            <span class="text">{{ $__t('{pop-up}.no-appear-7') }}</span>
          </button>
          <button type="button" @click.stop="close">
            <span class="text">{{ $__t('닫기') }}</span>
          </button>
        </div>
      </div>
      <div v-if="externalClose" class="external-button-area">
        <button type="button" @click="close">
          <img src="/img/modal-close.png" srcset="/img/modal-close@2x.png 2x, /img/modal-close@3x.png 3x" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from 'lodash';

export default {
  props: ['headerTitle', 'arrow', 'noAppear', 'name', 'noCloseButton', 'noBgClick', 'externalClose', 'transparent'],
  data() {
    var dragEventNames = {
      start: ['mousedown', 'touchstart'],
      move: ['mousemove', 'touchmove'],
      end: ['mouseup', 'touchend']
    };
    var dragStartX = 0;
    var dragMoveX = 0;

    return {
      opened: false,
      hidePopup: false,
      dragEventNames,
      dragStartX,
      dragMoveX
    };
  },

  beforeDestroy() {
    this.$store.commit('popup/REMOVE_ITEM', { data: { id: this.$attrs.id } });
  },

  watch: {
    opened(opened) {
      if (opened) {
        this.$nextTick(() => {
          if (this.$refs.background && this.noBgClick !== true) {
            this.$refs.background.addEventListener('mousedown', (e) => {
              if (this.$store.state.config.isTouchDevice || e.which == 1) {
                if (e.target == this.$el) {
                  this.close();
                }
              }
            });
          }
        });
      }
    },
    maxIndex: {
      handler(value) {
        if (value > 0 && this.$refs.viewport) {
          this.bindEvent();
        }
      }
    },
    hidePopup(value) {
      if (value) {
        this.close();
      }
    }
  },

  computed: {
    currentIndex() {
      return this.$store.getters['carousel/GET_ITEM']({ key: 'currentPage' }) - 1 || Number(0);
    },
    maxIndex() {
      return this.$store.getters['carousel/GET_ITEM']({ key: 'maxPage' }) - 1 || Number(0);
    },
    topLayerPopup() {
      return this.$store.getters['popup/GET_LAST_ITEM']();
    }
  },
  methods: {
    async close(setCookie = false) {
      const poppedPopup = this.topLayerPopup;

      if (poppedPopup && poppedPopup.id === this.$attrs.id) {
        this.opened = false;
        await this.$store.dispatch('popup/pop');
        this.$emit('modalClosed');

        if (setCookie === true) {
          this.setCookie(this.name);
        }
      }
    },
    clickClose: _.debounce(function (setCookie = false) {
      if (setCookie === true) {
        this.setCookie(this.name);
      }

      window.history.go(-1);
    }, 10),
    open(ref = '') {
      this.opened = true;
      this.$store.commit('popup/PUSH_ITEM', {
        data: { id: this.$attrs.id, context: this }
      });
      this.$emit('modalOpened');
    },
    slideNext() {
      this.$store.commit('carousel/INCREMENT');
    },
    slidePrev() {
      this.$store.commit('carousel/DECREMENT');
    },
    setCookie(name) {
      this.$store.dispatch('userData/set', {
        name: name,
        value: true,
        expires: 60 * 60 * 24 * 7
      });
    },
    bindEvent() {
      this.$refs.viewport.addEventListener(this.dragEventNames.start[this.$store.state.config.isTouchDevice ? 1 : 0], (e) => {
        this.bindDragStart(e);
      });
    },
    bindDragStart(e) {
      this.dragStartX = this.$store.state.config.isTouchDevice ? e.touches[0].clientX : e.x;
      window.addEventListener(this.dragEventNames.move[this.$store.state.config.isTouchDevice ? 1 : 0], this.bindDragMove);
      window.addEventListener(this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0], this.bindDragEnd);
    },
    bindDragMove(e) {
      this.dragMoveX = this.$store.state.config.isTouchDevice ? this.dragStartX - e.touches[0].clientX : this.dragStartX - e.x;
    },
    bindDragEnd(e) {
      if (this.dragMoveX > this.$refs.viewport.offsetWidth / 4) {
        this.slideNext();
      } else if (this.dragMoveX < (-1 * this.$refs.viewport.offsetWidth) / 4) {
        this.slidePrev();
      }
      this.dragStartX = 0;
      this.dragMoveX = 0;
      window.removeEventListener(this.dragEventNames.move[this.$store.state.config.isTouchDevice ? 1 : 0], this.bindDragMove);
      window.removeEventListener(this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0], this.bindDragEnd);
    }
  }
};
</script>

<style lang="scss">
.lg-popup {
  @include fsf;
  background-color: $color-shadow;
  z-index: 1401;
  @include vertical-center-container('& > .lg-popup-container', $site-width-g);

  & > .lg-popup-container {
    margin: auto;
    width: 100%;
    background-color: white;
    overflow: auto;
    border-radius: $radius;

    & > img {
      position: absolute;
      right: 0;
      top: 0;
      width: 2.5 * $unit;
      height: auto;
      margin-top: 0.833 * $unit;
      margin-right: 0.833 * $unit;
      cursor: pointer;
      z-index: 1401;
    }

    & > .header {
      padding-top: 4 * $unit;
      padding-left: 1.417 * $unit;
      @media screen and (min-width: $site-width-d) {
        padding-left: 0;
      }

      span.title {
        font-size: 1.75 * $unit;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        word-break: keep-all;
        word-wrap: break-word;
      }
    }

    & > .body {
      padding: 2 * $unit 0;
    }

    .ui-carousel-arrow {
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 2 * $unit;
        height: auto;
      }

      &.prev {
        img {
          transform: rotate(90deg);
        }
      }

      &.next {
        img {
          transform: rotate(90deg);
        }
      }
    }

    & > .button-area {
      height: 3.25 * $unit;
      border-top: solid 1px #e0e0e0;
      display: flex;

      & > button {
        cursor: pointer;

        .text {
          font-size: $unit;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.48px;
          text-align: center;
          color: #757575;
        }

        & + button {
          border-left: solid 1px #e0e0e0;
        }
      }
    }
  }

  &.external {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    &::before {
      display: none !important;
    }

    & > .lg-popup-container {
      max-height: calc(100% - #{unit(90)});
      flex: 0 1 auto;
      bottom: 0;
      margin-bottom: 0;
      width: 90%;
      max-width: $max-content-width;
    }

    & > .external-button-area {
      flex: 0 0 auto;
      margin-top: unit(20);
      margin-bottom: unit(60);
      padding-bottom: env(safe-area-inset-bottom);
      width: 100%;

      button {
        width: unit(50);
        height: unit(50);
        margin: auto;
        background-color: $color-white;
        border-radius: 50%;
        padding: unit(7);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
