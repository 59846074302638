<template>
  <div class="page" id="auth-callback-page">
<!--    <div class="container">-->

<!--      google callback-->
<!--      {{ $route.query }}-->

<!--    </div>-->
  </div>
</template>

<script>
export default {
  created(){
    alert()
    return new Promise( ( resolve, reject ) => {
      return this.axios.get( `${this.$store.state.config.apiURL}/v2/passport/auth/wechat/oauth/callback?state=${this.$route.query.state}&code=${this.$route.query.code}`).then( res => {
        if( res.data.status == "success" ){
          this.$store.dispatch("auth/setAuth", res.data.data);
          resolve( res );
        }else{
          reject( res );
        };
      }).catch( (res) => {
        alert(JSON.stringify(res))
      } );
    }).finally( res => {
      opener.location.replace( "/" );
      self.close();
      // location.replace( "/" );
    });
  }

};
</script>
