<template>
  <div class="page" id="auth-login-page-for-existing-users">
    <div class="container">
      <login-form
        :redirect="$route.query.redirect"
        :queryString="$route.query.queryString"
        :mode="$route.query.mode"
        :callback="$route.params.callback"
        :noRegister="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: this.$route.query?.token
    };
  },
  mounted() {
    setTimeout(() => {
      this.onDirect();
    }, 0);
  },
  methods: {
    async onDirect() {
      const token = this.$router.currentRoute?.query?.token;
      const redirect = this.$router.currentRoute?.query?.redirect;

      if (token) {
        await this.$store.dispatch('auth/direct', { token: token, redirect });
      }
    }
  }
};
</script>
