<template>
  <div class="more-tickets">
    <div class="container">
      <div class="page-header" ref="title">
        <h1 class="page-header__title">{{ $__t('Usage History') }}</h1>
      </div>
      <div class="body">
        <div ref="tickets" class="tickets">
          <template v-if="$store?.state?.auth?.gid">
            <template v-if="ticketLoaded">
              <transition-group tag="ul" name="fade" mode="out-in">
                <li v-for="(item, index) in tickets" :key="`ticket-${index}`" @click="onClickTicketItem(item)">
                  <ui-ticket
                    :ticket="item"
                    @store-single="
                      $router.push({
                        name: 'StoreSingle',
                        params: { store_id: $event?.store_id }
                      })
                    "
                  />
                </li>
              </transition-group>
              <div v-if="tickets?.length < 1" class="fixed-center">
                <div class="text-area">
                  {{ $__t('이용 중인 내역이 없습니다.') }}
                </div>
                <div class="button-area">
                  <button type="button" @click="$router.push({ name: 'Stores' })">
                    <span>{{ $__t('go keep my item') }}</span>
                  </button>
                </div>
              </div>
              <div v-if="loadable" style="padding: 1rem 0; margin-top: 2rem">
                <button
                  type="button"
                  style="color: #48d9eb; height: 100%; text-transform: uppercase; font-weight: bold; font-size: 1.25rem"
                  @click="loadMoreTickets"
                >
                  {{ $__t('More') }}
                </button>
              </div>
            </template>
            <template v-else>
              <PuSkeleton :count="10" :loading="true" height="10rem" />
            </template>
          </template>
          <template v-else>
            <div class="fixed-center">
              <div class="text-area">
                {{ $__t('회원가입 후 이용할 수 있습니다.') }}
              </div>
              <div class="button-area">
                <button type="button" @click="$router.push({ name: 'AuthLogin', query: { redirect: $router.currentRoute.fullPath } })">
                  <span>{{ $__t('로그인') }} / {{ $__t('회원가입') }}</span>
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <NavBar v-if="wni.isNative && $router.currentRoute.fullPath.indexOf('more') < 0" />

    <guide-modal id="usageModal" ref="usageModal" name="usageModal">
      <template v-slot:body>
        <usage-info :code="selectedTicket?.ticket_code" />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import TicketItem from '@/components/resources/ticket/TicketItem';
import UsageInfo from '@/components/pages/_/UsageInfo';
import TicketDetail from '@/components/pages/_/TicketDetail.vue';
import NavBar from '@/components/resources/nav/NavBar.vue';

export default {
  name: 'MobileLandingTickets',

  data() {
    return {
      ticketLoaded: false,
      selectedTicket: null,
      listQuery: {
        page: 1,
        size: 10
      },
      loadable: false,

      headerVisible: null
    };
  },

  created() {
    this.fetchTickets().then(() => {
      this.ticketLoaded = true;
      if (this.tickets?.length >= this.listQuery?.size) this.loadable = true;
    });
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      if (this.$route.name === 'Tickets') this.$analytics.logEvent('booking list page view');
      else if (this.$route.name === 'MoreTickets')
        document.querySelector('#mobile-landing-more').addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    if (this.$route.name === 'MoreTickets') {
      document.querySelector('#mobile-landing-more').removeEventListener('scroll', this.toggleHeaderTitleVisibility);
    }
  },

  watch: {
    headerVisible: {
      handler(value) {
        const $siteHeaderTitle = $('#site-header > .container > .header > .title');
        const $siteHeader = $('#site-header');

        if (value === true) {
          $siteHeaderTitle.removeClass('off');
          $siteHeader.addClass('shadow');
        } else if (value === false) {
          $siteHeaderTitle.addClass('off');
          $siteHeader.removeClass('shadow');
        }
      }
    }
  },

  computed: {
    tickets() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'MyTickets_all',
        sort: 'sort'
      });
    }
  },

  methods: {
    async fetchTickets(clear) {
      let options = {
        list: 'MyTickets_all',
        filter: 'all',
        query: this.listQuery,
        clear: clear
      };

      try {
        return await this.$store.dispatch('tickets/getList', options);
      } catch (e) {}
    },

    onClickTicketItem(item) {
      this.selectedTicket = item;

      let routeName = 'TicketSingle';

      if (item.ticket_type === 'PRODUCT') {
        routeName = 'ServiceProductTicketSingle';
      }

      setTimeout(() => {
        this.$router.push({
          name: routeName,
          params: { id: item.ticket_code }
        });
      }, 0);
    },

    openModal(ref) {
      this.$refs[ref].open();
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    setSkeletonLength() {
      this.skeletonLength = ((window.innerHeight - 180) % this.calculateRem()) + 1;
    },

    calculateRem() {
      const clientWidth = window.innerWidth;
      let rem = 12;

      if (clientWidth < 480) {
        rem = 12;
      } else if (480 <= clientWidth && clientWidth < 640) {
        rem = 13;
      } else if (640 <= clientWidth && clientWidth < 800) {
        rem = 14;
      } else {
        rem = 16;
      }

      return rem;
    },

    async fetchTickets(options = {}) {
      if (!this.$store?.state?.auth?.gid) return;

      let payload = {
        list: 'MyTickets_all',
        filter: 'all',
        query: this.listQuery,
        ...options
      };

      try {
        return await this.$store.dispatch('tickets/getList', payload);
      } catch (e) {}
    },

    loadMoreTickets() {
      if (!this.loadable) return;

      this.$store.commit('loading/SET_TRUE');
      this.listQuery.page += 1;
      this.fetchTickets()
        .then((res) =>
          setTimeout(() => {
            if (res?.data?.data?.items?.length > 0) {
              // this.tickets = utils.sortTicketList(this.tickets.concat(res.data.data.items));
              return;
            }
            this.loadable = false;
            this.listQuery.page -= 1;
          }, 10)
        )
        .finally(() =>
          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 10)
        );
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageBodyTitle = $(this.$refs.title);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageBodyTitle.offset().top < 0;

      if (!isHeaderCovered) {
        this.headerVisible = false;
      } else {
        this.headerVisible = true;
      }
    }
  },

  components: {
    UiTicket: TicketItem,
    UsageInfo,
    TicketDetail,
    NavBar
  }
};
</script>

<style lang="scss" scoped>
.nav-bar {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
</style>
