<template>
  <div class="ui-store for-host">
    <div class="container">
      <div class="image" :style="'background-image:url('+( storeImage )+')'"></div>
      <div class="text">
        <router-link :to="{ name: 'StoreSingle', params: { store_id: store.id }}">
          <div class="name">{{ store.store_name }}</div>
          <div class="address">{{ storeAddress }}</div>          
        </router-link>
        <div class="interface">
          <ui-solid-button color="primary" size="small"><router-link :to="{ name: 'HostStoreEdit', params: { id: store.id }}">{{ $__t( "상점 관리하기" ) }}</router-link></ui-solid-button>
          <ui-solid-button v-if="store.status === 'READY'" color="orange" size="small" @click="openPublic"><span>상점 오픈하기</span></ui-solid-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuideModal from '@/components/modules/LugModal/GuideModal.vue';
export default {
  components: { GuideModal },
  props: [ "store" ],

  computed: {
    storeAddress(){
      const lang = this.$store.state.lang;
      let address = this.store.store_address;
      let address_detail = this.store.store_address_detail;

      if(address_detail && typeof address_detail == 'string'){
        if(lang == 'ko'){
          address += ", " + address_detail.trim()
        }else{
          address = address_detail.trim() + ", " + address
        }
      }

      return address;
    },

    storeImage(){
      return this.store?.resources?.thumbnail_image?.resource_url || this.store?.resources?.thumbnail_image?.resource_thumbnail_url || "";
    }
  },

  methods: {
    async openPublic() {
      const password = prompt("비밀번호를 입력해주세요");

      if(typeof password === 'string') {
        try {
          await this.axios.put( this.$store.state.config.apiURL + `/v2/manager/stores/${this.store.id}/open`, { password }, { headers: this.$store.state.config.userHeaders });
          await this.$store.dispatch('stores/get', { id: this.store.id });
        }
        catch(e) {
          alert(e.message)
        }
      }
    }
  }
}
</script>
