<template>
  <div id="store-delivery">
    <div v-if="loading" style="width:100%; height: 100%; z-index:10000; display: flex; align-items: center; justify-content: center; position: fixed; top: 0;background-color: #000000; opacity: 0.6;">
      <div style="font-size: 2rem; text-align: center; color: #ffffff;">
        <i class="fa fa-spinner fa-pulse"></i>
        <div v-if="onReserve" style="text-align:center; line-height: 1.65; color: #ffffff; font-size: 1.25rem; font-weight:500;">
					{{ $__t('Reservation on progress.') }}
				</div>
      </div>
    </div>
    <div v-else class="page-body">
      <div id="reservation-form-step">
        <div class="container">
          <div class="need-login" v-if="!$store.state.auth.gid">
            <p>{{ $__t("You need to login to make a reservation.") }}</p>
            <ui-solid-button size="small"
              ><router-link :to="{ name: 'AuthLogin', query: { redirect: $router.currentRoute.fullPath } }">{{ $__t("login") }}</router-link></ui-solid-button
            >
          </div>
          <div class="payment-step"><img src="/img/payment-step-indicator-4.png" /></div>
          <div class="form">
            <div class="form-section payment-section delivery-section" id="payment">
              <div class="form-section-title">{{ $__t("Payment Information") }}</div>
              <div class="form-section-body">
                <ul>
                  <li>
                    <div v-if="selectedView === 'MyBillingCard'">
                      <p class="payment-card" style="margin-bottom: 1rem;">{{ $__t("Payment Card") }}</p>
                      <div class="card-button" @click="switchView('MyBillingReg')" style="margin-left:-2rem; width: 100vw;">
                        <img src="/img/card.png" srcset="/img/card@2x.png 2x, /img/card@3x.png 3x" class="card" />
                        <span class="text">{{ $__t("Credit/Debit Card") }}</span>
                        <img src="/img/arrow-right.png" srcset="/img/arrow-right@2x.png 2x, /img/arrow-right@3x.png 3x" class="arrow" />
                      </div>
                    </div>
                    <div v-else-if="selectedView === 'MyBillingReg'" class="payment-add">
                      <card-form ref="cardForm" @serialized="addCard" />
                    </div>
                    <div v-else-if="selectedView === 'MyBillingList'">
                      <div class="list-box" style="width: 100vw; margin-top: 2rem; margin-left:-2rem;">
                        <ul>
                          <li ref="cardSingle" v-for="(i, index) in billings" :key="index" :class="{ active: i.is_primary === 1 }">
                            <div class="card-button">
                              <img v-if="i.is_primary === 1" src="/img/card.png" srcset="/img/card@2x.png 2x, /img/card@3x.png 3x" class="card" style="box-shadow: 1px 1px 1px 0 rgba(30, 79, 117, 0.34);" />
                              <img v-else src="/img/card-disable.png" srcset="/img/card-disable@2x.png 2x, /img/card-disable@3x.png 3x" class="card" />
                              <span class="text">{{ i.card_name }}&nbsp;{{ i.card_number.substr(15, 4) }}</span>
                              <img @click="toggleKebabButton(i, index, $event)" src="/img/kebab-menu.png" srcset="/img/kebab-menu@2x.png 2x, /img/kebab-menu@3x.png 3x" class="kebab" />
                              <popup :ref="'modifyBillingPopup' + index" :items="popupMenu.items" :coordinates="popupMenu.coordinates" @clickItem="onClickPopupItem" :blur="true" />
                            </div>
                          </li>
                          <li>
                            <div class="add-card" @click="switchView('MyBillingReg')">
                              <span class="text">{{ $__t("Add Payment Card") }}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="selectedView !== 'MyBillingReg'" class="guide-body">
                <ul>
                  <li class="body-title">{{ $__t("[Card Registration & Configuration Guide]") }}</li>
                  <li class="dot">{{ $__t("Basic fee will be charged when you make your reservation and additional fee will be paid later by the hours of usage.") }}</li>
                  <li class="dot">{{ $__t("Adding card is to register your primary credit card or debit card.") }} {{ $__t("Once you register a card, you can use automatic payment, which leads to more convenient use of service.") }}</li>
                  <li class="dot">{{ $__t("You can configure primary card & delete card by clicking the icon ( ፧ ) on the right side of the added card") }}</li>
                </ul>
                <ul>
                  <li class="body-title">{{ $__t("If you have any questions, please contact the messenger at the bottom right.") }}</li>
                </ul>
              </div>
            </div>
            <div class="reservation-form-action form-section submit-section">
              <ui-solid-button v-if="selectedView === 'MyBillingReg'" button class="form-submit-button" display="block" size="medium">
                <button type="button" @click.stop="$refs.cardForm.$refs.registerCardform.onSubmit()">{{ $__t("저장") }}</button>
              </ui-solid-button>
              <ui-solid-button v-else class="form-submit-button" display="block" size="medium">                
                <button v-ga="$analytics.tracking.bind(this, 'booking_3', 'click', 'booking_3_pay_button')" type="submit" @click="submitForm(formData)">
                  {{ $__t("예약하기") }}
                </button>
              </ui-solid-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      ref="deleteCardModal"
      id="deleteCardModal"
      :header-label="$__t('Delete Payment Card')"
      :submit-button-label="$__t('Delete')"
      :cancel-button-label="$__t('Cancel')"
      @submit="
        deleteBilling();
        closeModal('deleteCardModal');
      "
    >
      <template v-slot:body>
        <div class="center-textbox">
          <span class="text">
            {{ $__t("You cannot use LugStay service without registered payment card.") }}
            <br />
            {{ $__t("Do you want to delete the card?") }}
          </span>
        </div>
      </template>
    </modal>

    <modal ref="primaryCardModal" id="primaryCardModal" :header-label="$__t('Register Primary Card')" :submit-button-label="$__t('닫기')" @submit="closeModal('primaryCardModal')">
      <template v-slot:body>
        <div class="center-textbox">
          <span class="text">{{ $__t("Please select a card as your primary payment method") }}</span>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import config from "@/config";
import countryCodes from "@/assets/json/country-codes.json";

export default {
  name: "DeliveryFormStep4",
  data() {
    let majorCountryCodes = ["CA", "US", "GB", "FR", "DE", "CN", "RU", "MN", "JP", "TW", "PH", "HK", "TH", "VN", "IN", "MY", "SG", "AU", "ID", "KR"];
    var _code = countryCodes
      .map((o) => {
        return { name: o.name, code: o.code };
      })
      .filter((o) => {
        return majorCountryCodes.includes(o.code);
      });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });
    _code.forEach(o => {
			if(o.name === 'Korea, Republic of'){
				_code.splice(_code.indexOf(o), 1)
				_code.unshift(o);
			}
      if(o.name === 'Others'){
				_code.splice(_code.indexOf(o), 1)
				_code.push(o);
			}
		})

    _code.push({ name: "Others", code: "ETC" });

    var _dial_code = countryCodes.map((o) => {
      return { name: o.name, code: o.dial_code };
    });
    _dial_code.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    _dial_code.forEach(o => {
			if(o.name === 'Korea, Republic of'){
				_dial_code.splice(_dial_code.indexOf(o), 1)
				_dial_code.unshift(o);
			}
      if(o.name === 'Others'){
				_dial_code.splice(_dial_code.indexOf(o), 1)
				_dial_code.push(o);
			}
		})

    return {
      state: {},
      saveCardInfo: true,
      countryCodes: countryCodes,
      schedules: null,
      formData: {
        user_iso_code: {
          label: this.$__t("국가"),
          placeholder: this.$__t("국가를 선택해주세요"),
          validation: [{ type: "required" }],
          value: "",
          items: _code.map((o) => {
            return { label: o.code + " (" + o.name + ")", value: o.code };
          }),
          error: "",
        },
        coupon_code: {
          value: "",
        },
        payMethod: {
          items: { label: this.$__t("Credit Card"), value: "card" },
          value: null,
        },        
      },
      couponData: false,
      calendarLoading: false,
      quantityFilterOpened: false,
      dateStartFilterOpened: false,
      dateEndFilterOpened: false,
      couponFilterOpened: true,

      dateStartError: null,
      dateEndError: null,

      availableTimes: [],
      availableDates: [],
      paymentData: {},
      reserve: null,

      selectedPopupIndex: "",
      selectedView: "",
      billings: {},
      primaryBilling: "",
      popupMenu: {
        coordinates: {
          clientX: "",
          clientY: "",
        },
        items: [this.$__t("Select as primary card"), this.$__t("Delete")],
      },
      selectedCard: {},
      loading: false,
      onReserve: false,

      data: ''
    };
  },
  computed: {
    currentIsoCode() {
      this.initializePaymethod();
      return this.$data.formData && this.$data.formData.user_iso_code && this.$data.formData.user_iso_code.value;
    },
  },
  watch: {
    selectedView(value, oldValue) {
      if (value === "MyBillingReg" && oldValue) {
        window.history.pushState({}, null, '');
      } else if (value && oldValue === "MyBillingReg") {
        this.switchView(value);
        if (location.hash) history.back();
      }
    },
  },

  async created() {
    window.addEventListener("popstate", this.viewSwitchHandler);

    await this.getBillingList();

    try{
      this.$data.data = await this.$store.dispatch("orders/reserve", { id: this.$route.query.reserve_id })
      this.$data.data.ticket_password = this.$route.query.ticket_password
    }
    catch(e){
      this.$store.commit("alert/ADD_ITEM", {message: this.$__t(e.message), status: 'error' })
    }

    if (Object.keys(this.billings).length > 0) {
      this.switchView("MyBillingList");
    } else {
      this.switchView("MyBillingCard");
    }
  },

  methods: {
    viewSwitchHandler() {
      this.getBillingList();
      if (Object.keys(this.billings).length > 0) {
        this.switchView("MyBillingList");
      } else {
        this.switchView("MyBillingCard");
      }
    },
    clickDirectCreditCard() {
      $("#payment").addClass("hidden");
      $("#direct-creditcard").removeClass("hidden");
    },
    initializePaymethod() {
      $(".checkbox").removeClass("on");
      this.formData.payMethod.value = null;
    },
    // on(code, event) {
    // 	$('.checkbox').removeClass('on');

    // 	let target = $(event.target);
    // 	if (!target.hasClass('payment-item')) {
    // 		target = target.parent();
    // 	}

    // 	target.children('.checkbox').addClass('on');
    // 	this.formData.payMethod.value = code;
    // },
    async submitForm(values) {
      this.loading = true;
      this.onReserve = true;
      
      const auth = this.$store.state.auth;

      let payload = {
        id : this.$data.data.reserve_id,
        reserve: {
          user_last_name: auth.user_last_name,
          user_first_name: auth.user_first_name,
          user_phone: auth.user_phone,
          user_email: auth.user_email || auth.account
        },
        billing: {
          user_last_name: auth.user_last_name,
          user_first_name: auth.user_first_name,
          user_phone: auth.user_phone,
          user_email: auth.user_email || auth.account,
          billing_id: this.primaryBilling.uid
        }
      }

      try{
       let data = await this.$store.dispatch('resourceDelivery/complete', payload );
       this.goToTicket(data.ticket_code)
      }
      catch(e){
        this.$store.commit("alert/ADD_ITEM", { message: this.$__t(e), status: 'error'});
      }
      finally{
        this.loading = false;
        this.onReserve = false;                
      }      

    },
    switchView(view) {
      if (view == "MyBillingReg") {
        this.$analytics.logTrack("track", "AddPaymentInfo", { content_category: "AddCardInfo", value: 1, currency: 'KRW' });
      }
      this.selectedView = view;
    },
    addCard(values) {
      this.loading = true;
      let data = {
        billing_gateway: values.user_iso_code === "KR" ? "NICEPAY" : "EXIMBAY",
        card_password: values.user_iso_code === "KR" ? values.cardPass : "",
        expiration_year: values.expDate.substr(2, 2),
        expiration_month: values.expDate.substr(0, 2),
        id_number: values.user_iso_code === "KR" ? values.birthDate : "",
        card_number: values.card,
        is_primary: "1",
        card_cvc: values.cvc,
      };

      this.$store
        .dispatch("billings/addCard", data)
        .then(async (res) => {
          if (res.data.data.status === "AUTHORIZED") {
            await this.getBillingList();
            if (this.selectedView === "MyBillingReg") {
              this.switchView("MyBillingList");
            }
          } else {
            this.$store.commit("alert/ADD_ITEM", {
              message: this.$__t("Invalid card information"),
              status: "error",
            });
          }
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.loading = false;
        })
        .catch((err) => {
          this.$store.commit("alert/ADD_ITEM", {
            message: this.$__t("Invalid card information"),
            status: "error",
          });
          this.loading = false;
        });
    },
    openModal(ref) {
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },
    getBillingList() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("billings/getCardList")
          .then((res) => {
            this.billings = res;
            if (Object.keys(this.billings).length > 0) {
              this.isPrimaryCardExists();
            } else {
              this.switchView("MyBillingCard");
            }
          })
          .catch((err) => {
            this.$log.log(err);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    toggleKebabButton(i, index, event) {
      this.selectedCard = i;
      this.selectedPopupIndex = index;
      let target = $(event.target);

      this.popupMenu.coordinates.clientX = target.position().left + target.width() / 2;
      this.popupMenu.coordinates.clientY = target.position().top + target.height() / 2;
      Object.keys(this.$refs).forEach((refName) => {
        if (refName.indexOf("modifyBillingPopup") !== -1) {
          if (this.$refs[refName].length) {
            this.$refs[refName][0].closePopup();
          }
        }
      });
      this.$refs["modifyBillingPopup" + this.selectedPopupIndex][0].openPopup();
    },
    deleteBilling() {
      this.$store
        .dispatch("billings/deleteCard", this.selectedCard)
        .then((res) => {
          if (res.data.status == "error") {
            this.$store.commit("alert/ADD_ITEM", { message: this.$__t(res.data.error), status: "error" });
            return;
          }
          this.getBillingList();
        })
        .catch((err) => {
          this.$store.commit("alert/ADD_ITEM", { message: err, status: "error" });
        });
    },
    updatePrimaryBilling() {
      this.$store
        .dispatch("billings/updatePrimaryCard", this.selectedCard)
        .then((res) => {
          this.getBillingList();
        })
        .catch((err) => {
          this.$log.log(err);
        });
    },
    onClickPopupItem(param) {
      if (param === this.$__t("Select as primary card")) {
        this.updatePrimaryBilling();
        this.$refs["modifyBillingPopup" + this.selectedPopupIndex][0].closePopup();
      } else if (param === this.$__t("Delete")) {
        let billings_length = Object.keys(this.billings).length;

        if (billings_length === 1) {
          this.openModal("deleteCardModal");
          this.$refs["modifyBillingPopup" + this.selectedPopupIndex][0].closePopup();
          return;
        }

        this.deleteBilling();
        this.$refs["modifyBillingPopup" + this.selectedPopupIndex][0].closePopup();
      }
    },
    isPrimaryCardExists() {
      let isPrimary = false;

      Object.keys(this.billings).forEach((o) => {
        if (this.billings[o].is_primary === 1) {
          isPrimary = true;
          this.primaryBilling = this.billings[o];
          this.formData.payMethod.value = this.billings[o].billing_gateway;
          this.formData.user_iso_code.value = this.formData.user_iso_code.value ? this.formData.user_iso_code.value : "KR";
        }
      });

      if (isPrimary === false) {
        setTimeout(() => {
          this.openModal("primaryCardModal");
        }, 1000);
        return;
      }
    },
    goToTicket(ticket_code) {
      let ticketUrl = this.$store.state.config.siteURL + "/myReservation/tickets/" + ticket_code;

      if (this.wni.isNative) {
        this.wni.execute("wnMoveURL", { url: ticketUrl });
        this.wni.execute("wnPopupClose", {});
      } else if (window.opener) {
        window.opener.location.href = ticketUrl;
        window.close();
      } else {
        this.$router.push({ name: "TicketSingle", params: { id: ticket_code } });
      }
    },

    userData(){
      return {        
        recipient_user_last_name: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_last_name" }) || "",
        recipient_user_first_name: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_first_name" }) || "",
        recipient_user_phone: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_phone" }) || "",
        sender_user_last_name: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_last_name" }) || "",
        sender_user_first_name: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_first_name" }) || "",
        sender_user_phone: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_phone" }) || "",      
      };
    }
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.viewSwitchHandler);
  },
};
</script>
