<template>
  <div class="includes" id="card-form">
    <div class="container">
      <div class="tab-area">
        <lug-tab ref="tab" :tab-items="tabItems" :activeIndex="activeTabIndex" @active="onActiveTab" />
      </div>
      <ui-form ref="form" @submit="submitForm" :formData="formDataOnActiveTab">
        <ui-form-list>
          <!--국가-->
          <ui-form-list-item>
            <lug-select
              v-model="formData.user_iso_code.value"
              :label="formData.user_iso_code.label"
              :items="formData.user_iso_code.items"
              :error="formData.user_iso_code.error"
              :placeholder="$__t('Please select the country where your payment method was issued.')"
            />
          </ui-form-list-item>
          <!--카드번호-->
          <ui-form-list-item>
            <lug-text-input
              ref="input-card"
              :value="formData.card.value"
              :error="formData.card.error"
              :placeholder="$__t('카드번호를 입력해주세요.')"
              :type="formData.card.type"
              :label="$__t('Card Number')"
              :inputmode="formData.card.inputmode"
              :mask="formData.card.mask"
              @input="
                formData.card.value = $event;
                onInput(formData.card, 'input-exp-date');
              "
            />
          </ui-form-list-item>

          <ui-form-list-multiple-item>
            <!--유효기간-->
            <lug-text-input
              ref="input-exp-date"
              :value="formData.expDate.value"
              :error="formData.expDate.error"
              size="medium"
              :placeholder="'MM / YY'"
              :type="formData.expDate.type"
              :label="$__t('Expiration Date')"
              :inputmode="formData.expDate.inputmode"
              :mask="formData.expDate.mask"
              @input="
                formData.expDate.value = $event;
                onInput(formData.expDate, 'input-cvc');
              "
            />
            <!--CVC-->
            <lug-text-input
              ref="input-cvc"
              :value="formData.cvc.value"
              :error="formData.cvc.error"
              :placeholder="$__t('숫자 3자리')"
              :maxlength="3"
              :label="'CVC'"
              :inputmode="formData.cvc.inputmode"
              :type="formData.cvc.type"
              :mask="formData.cvc.mask"
              @input="
                formData.cvc.value = $event;
                onInput(formData.cvc, 'input-card-pass');
              "
            />
          </ui-form-list-multiple-item>
          <ui-form-list-multiple-item>
            <!--카드 비밀번호-->
            <lug-text-input
              ref="input-card-pass"
              v-if="formData.user_iso_code.value === 'KR'"
              :value="formData.cardPass.value"
              :error="formData.cardPass.error"
              :placeholder="$__t('앞 2자리')"
              :type="formData.cardPass.type"
              :label="$__t('Card Password')"
              :inputmode="formData.cardPass.inputmode"
              :mask="formData.cardPass.mask"
              @input="
                formData.cardPass.value = $event;
                onInput(formData.cardPass, activeTabIndex === 0 ? 'input-birth-date' : 'input-company-code');
              "
            />
            <!--사업자 등록번호(법인카드일경우)-->
            <lug-text-input
              ref="input-company-code"
              v-if="activeTabIndex !== 0"
              v-model="formData.companyCode.value"
              :error="formData.companyCode.error"
              :type="formData.companyCode.type"
              :placeholder="$__t('10자리')"
              :maxlength="formData.companyCode.maxlength"
              :label="$__t('사업자 등록번호')"
              :inputmode="formData.companyCode.inputmode"
              :mask="formData.companyCode.mask"
            />
            <!--생년월일-->
            <lug-text-input
              ref="input-birth-date"
              v-if="formData.user_iso_code.value === 'KR'"
              v-model="formData.birthDate.value"
              :error="formData.birthDate.error"
              :placeholder="'YY.MM.DD'"
              :type="formData.birthDate.type"
              :label="$__t('Day of Birth')"
              :inputmode="formData.birthDate.inputmode"
              :mask="formData.birthDate.mask"
            />
          </ui-form-list-multiple-item>
        </ui-form-list>
        <div class="label-text-area">
          <div class="label-text-area__label">
            <img src="/img/icon/l-information.svg" />
          </div>
          <div class="label-text-area__text">
            {{ $__t('결제 수단 등록/변경 시 인증을 위해 100원 결제 후 즉시 자동 환불됩니다.') }}
          </div>
        </div>
        <div class="submit-area">
          <button type="submit" v-button :class="{ activated: activated }" :disabled="!activated">
            {{ $__t('등록하기') }}
          </button>
        </div>
      </ui-form>
    </div>
  </div>
</template>

<script>
import LugTab from '@/components/modules/LugTab/Body';
import countryCodes from '@/assets/json/country-codes.json';
import config from '@/config';

export default {
  name: 'CardForm',
  props: ['redirect'],

  data() {
    let majorCountryCodes = ['CA', 'US', 'GB', 'FR', 'DE', 'CN', 'RU', 'MN', 'JP', 'TW', 'PH', 'HK', 'TH', 'VN', 'IN', 'MY', 'SG', 'AU', 'ID', 'KR'];
    var _code = countryCodes
      .map((o) => {
        return { name: o.name, code: o.code };
      })
      .filter((o) => {
        return majorCountryCodes.includes(o.code);
      });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });
    _code.forEach((o) => {
      if (o.name === 'Korea, Republic of') {
        _code.splice(_code.indexOf(o), 1);
        _code.unshift(o);
      }

      if (o.name === 'Others') {
        _code.splice(_code.indexOf(o), 1);
        _code.push(o);
      }
    });

    _code.push({ name: 'Others', code: 'ETC' });

    return {
      formData: {
        card: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'tel',
          mask: '####-####-####-####',
          maxlength: 16
        },
        expDate: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'tel',
          mask: '##/##',
          maxlength: 4
        },
        cvc: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'password',
          mask: '###',
          maxlength: 3
        },
        birthDate: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'tel',
          mask: '##.##.##',
          maxlength: 6
        },
        companyCode: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'tel',
          mask: '###-##-#####'
        },
        cardPass: {
          value: '',
          // validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'password',
          mask: '##',
          maxlength: 2
        },
        user_iso_code: {
          value: '',
          items: _code.map((o) => {
            return { label: o.code + ' (' + o.name + ')', value: o.code };
          }),
          validation: [{ type: 'required' }],
          label: this.$__t('국가'),
          error: ''
        }
      },

      tabItems: [this.$__t('개인카드'), this.$__t('법인카드')],

      activeTabIndex: 0
    };
  },

  created() {
    let auth = this.$store.state.auth;

    if (auth.user_iso_code && auth.user_dial_code) {
      this.formData.user_iso_code.value = auth.user_iso_code;
    } else if (config.userLocale.indexOf('ko') !== -1) {
      this.formData.user_iso_code.value = 'KR';
    }
  },

  computed: {
    activated() {
      return Object.keys(this.formDataOnActiveTab).every((o) => {
        return !!this.formDataOnActiveTab[o].value;
      });
    },

    formDataOnActiveTab() {
      let { birthDate, companyCode, ...rest } = this.formData;

      if (this.formData.user_iso_code.value !== 'KR') {
        delete rest['cardPass'];
      }

      if (this.activeTabIndex === 0) {
        if (this.formData.user_iso_code.value === 'KR') {
          return { ...rest, birthDate };
        } else {
          return { ...rest };
        }
      } else if (this.activeTabIndex === 1) {
        return { ...rest, companyCode };
      }

      return this.formData;
    }
  },

  methods: {
    submitForm(values) {
      this.$store.commit('loading/SET_TRUE');
      let { card, cardPass, companyCode, cvc, expDate, user_iso_code, birthDate } = values;

      return this.$store
        .dispatch('billings/addCard', {
          billing_gateway: user_iso_code === 'KR' ? 'NICEPAY' : 'EXIMBAY',
          card_password: cardPass,
          expiration_year: expDate.substr(2, 2),
          expiration_month: expDate.substr(0, 2),
          id_number: birthDate || companyCode,
          card_number: card,
          is_primary: '1',
          card_cvc: cvc
        })
        .then((res) => {
          // console.log('res', res);
          if (res.data.data.status === 'AUTHORIZED') {
            this.$store.commit('notice/ADD_ITEM', {
              message: this.$__t('등록되었습니다 .')
            });
            this.$analytics.logEvent('add payment info');
            if (this.$props.redirect.redirect === 'home') {
              return this.$router.push({ name: 'Home' });
            }
            return this.$router.back();
          } else {
            this.$analytics.logEvent('error payment info');
            return this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t('Invalid card information'),
              status: 'error'
            });
          }
        })
        .catch((error) => {
          this.$analytics.logEvent('error payment info');
          if (error?.data?.error === '이미 등록된 카드 정보입니다.') {
            return this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t(error.data.error),
              status: 'error'
            });
          }

          this.$store.commit('alert/ADD_ITEM', {
            message: this.$__t('Invalid card information'),
            status: 'error'
          });
          this.$analytics.logEvent('error payment info', { error: error });
        })
        .finally(() => {
          setTimeout(() => this.$store.commit('loading/SET_FALSE'), 500);
        });
    },

    onActiveTab(index) {
      this.activeTabIndex = index;
    },

    onInput(form_property, focus_ref) {
      let { value, maxlength } = form_property;

      if (String(value).length === maxlength) {
        const TARGET_ELEMENT = this.$refs[focus_ref].$el.getElementsByTagName('input')[0];

        if (TARGET_ELEMENT) {
          setTimeout(() => {
            TARGET_ELEMENT.focus();
          }, 0);
        }
      }
    }
  },

  components: {
    LugTab
  }
};
</script>
