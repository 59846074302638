<template>
  <div class="ticket-action__form">
    <div class="ticket-action__form-header">
      <div class="ticket-action__form-header--primary" v-html="formHeaderTextPrimary"></div>
      <div class="ticket-action__form-header--secondary">
        {{ formheaderTextSecondary }}
      </div>
    </div>
    <div class="ticket-action__form-body">
      <template v-if="invited">
        <transition name="fade" mode="out-in">
          <div class="invite-info">
            <h3 class="invite-info__header">
              {{ ticket.store_name }}
            </h3>
            <p class="invite-info__detail">
              <span>{{ reserveDateTimeText }}</span
              ><br />
              <span>{{ ticketQuantityText }}</span>
            </p>
            <div class="invite-info__image-area">
              <div class="invite-info__image-wrapper">
                <template v-if="ticket.resources && ticket.resources.luggage_photo && ticket.resources.luggage_photo.resource_url">
                  <img :src="doc.resources.luggage_photo.resource_url" />
                </template>
                <img v-else src="/img/no-image.png" class="invite-info__image" />
              </div>
              <div class="invite-info__image-label">
                <span class="text">{{ ticket.ticket_code }}</span>
              </div>
            </div>
          </div>
        </transition>
      </template>
      <template v-else>
        <transition name="fade" mode="out-in">
          <ui-form ref="form" :formData="formData" @submit="onSubmit">
            <ui-form-list>
              <ui-form-list-item>
                <lug-text-input
                  :placeholder="formData.user_name.placeholder"
                  :type="formData.user_name.type"
                  :label="formData.user_name.label"
                  :error="formData.user_name.error"
                  v-model="formData.user_name.value"
                  :required="true"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <div class="lug-phone">
                  <label>
                    {{ formData.user_phone.label }}
                    <span style="color: red"> * </span>
                    <div class="body">
                      <lug-select
                        :placeholder="formData.user_dial_code.placeholder"
                        :type="formData.user_dial_code.type"
                        :items="formData.user_dial_code.items"
                        v-model="formData.user_dial_code.value"
                      />
                      <lug-text-input
                        :placeholder="formData.user_phone.placeholder"
                        :type="formData.user_phone.type"
                        :inputmode="formData.user_phone.input_mode"
                        v-model="formData.user_phone.value"
                        :mask="formData.user_phone.mask"
                      />
                    </div>
                  </label>
                  <div style="color: rgb(230, 20, 20); font-size: 0.875rem">
                    {{ formData.user_phone.error }}
                  </div>
                </div>
              </ui-form-list-item>
              <ui-form-list-item>
                <lug-text-input
                  :placeholder="formData.message.placeholder"
                  :type="formData.message.type"
                  :label="formData.message.label"
                  :error="formData.message.error"
                  :maxlength="50"
                  v-model="formData.message.value"
                />
              </ui-form-list-item>
            </ui-form-list>
          </ui-form>
        </transition>
      </template>
    </div>
    <div class="ticket-action__form-footer">
      <div class="footer__button-area">
        <template v-if="invited">
          <button v-button type="button" @click="$emit('close-modal')" class="button button--submit">
            {{ $__t('Confirm') | toUpperCase }}
          </button>
        </template>

        <template v-else>
          <button v-button type="button" @click="$emit('close-modal')" class="button button--cancel">
            {{ $__t('Cancel') | toUpperCase }}
          </button>
          <button v-button type="button" @click="submitForm" class="button button--submit">
            {{ $__t('Pass') | toUpperCase }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import countryCodes from '@/assets/json/country-codes.json';
import QrCode from '@/components/includes/QrCode';

export default {
  name: 'InviteForm',

  data() {
    return {
      invited: false,

      formData: {
        user_name: {
          type: 'text',
          label: this.$__t('성명'),
          placeholder: this.$__t('전달받는 사람의 이름'),
          value: '',
          validation: [{ type: 'required' }],
          error: ''
        },
        user_dial_code: {
          value: '+82',
          error: '',
          validation: [{ type: 'required' }],
          items: this.parseCountryCodes(countryCodes),
          label: this.$__t('국가 번호')
        },
        user_phone: {
          type: 'tel',
          label: this.$__t('휴대폰 번호'),
          placeholder: '010 1234 5678',
          validation: [{ type: 'required' }, { type: 'number' }],
          input_mode: 'number',
          error: '',
          value: '',
          mask: '###-####-####'
        },
        message: {
          type: 'text',
          placeholder: this.$__t('50자 내외'),
          label: this.$__t('Message to leave'),
          value: '',
          error: ''
        },
        password: {
          value: '9727'
        }
      }
    };
  },

  computed: {
    ticket() {
      return this.$store.getters['tickets/GET_ITEM']({ key: 'ticket_code', value: this.$route.params.id });
    },

    formHeaderTextPrimary() {
      if (this.invited) {
        return this.$__t('{user_name}님에게 티켓이 전달되었습니다.').replace('{user_name}', this.formData.user_name.value);
      }

      return this.$__t('Pass the Ticket');
    },

    formheaderTextSecondary() {
      if (this.invited) {
        return this.$__t('전화번호') + ': ' + this.formData.user_phone.value;
      }

      return this.$__t('Please write down the information who receives the ticket.');
    },

    reserveDateTimeText() {
      const momentDateStart = this.$moment(this.ticket.ticket_date_start);
      const momentDateEnd = this.$moment(this.ticket.ticket_date_end);
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      return text;
    },

    ticketQuantityText() {
      try {
        let value = '';

        if (this.ticket.ticket_quantity > 0) {
          let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.ticket.ticket_quantity}`;

          if (this.ticket.ticket_quantity === 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }

        if (this.ticket.ticket_over_quantity > 0) {
          let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.ticket.ticket_over_quantity}`;

          if (this.ticket.ticket_over_quantity > 0) quantityString = ' ' + quantityString;

          if (this.ticket.ticket_over_quantity === 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }

        return value;
      } catch (e) {
        return null;
      }
    }
  },

  methods: {
    submitForm() {
      return this.$refs.form.submit();
    },

    parseCountryCodes(countryCodes) {
      let _dial_code = countryCodes.map((o) => ({ label: `${o.dial_code} (${o.code})`, value: o.dial_code })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        if (o.name === 'Korea, Republic of') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        if (o.name === 'Others') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },

    async onSubmit(values) {
      this.$store.commit('loading/SET_TRUE');

      let { user_name, user_phone, message, password } = values || {};

      try {
        await this.$store.dispatch('tickets/share', {
          code: this.$route.params.id,
          data: {
            user_name,
            user_phone,
            message,
            password
          }
        });

        this.$analytics.logEvent('complete ticket delivery');

        this.invited = true;
        this.$emit('fetch-invitation');

        return this.$store.commit('alert/ADD_ITEM', {
          message: this.$__t('티켓이 전달되었습니다.'),
          status: 'success'
        });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: this.$__t('티켓이 전달되지 않았습니다.'), status: 'error' });
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    }
  },

  components: {
    QrCode
  }
};
</script>

<style scoped lang="scss">
.invite-info {
  border-top: dashed 1px #a9afb3;

  &__header {
    margin-top: unit(24);
    font-weight: bold;
    font-size: unit(16);
    line-height: 1.75;
    letter-spacing: -0.006em;
  }

  &__detail {
    margin-top: unit(4);
    font-size: unit(14);
    line-height: 1.43;
    color: #a9afb3;
  }

  &__image-area {
    padding: unit(16) 0;
  }

  &__image-wrapper {
    width: 100%;
    padding-bottom: 50%;
    border-radius: unit(3);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-label {
    padding: unit(6);
    text-align: center;

    .text {
      color: #a9afb3;
      line-height: unit(24);
      font-size: unit(16);
      font-weight: 500;
    }
  }
}

.ticket-action__form-body {
  padding-bottom: unit(48);
}

.ticket-action__form-header {
  padding-bottom: unit(12);
}
</style>
