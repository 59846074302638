var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-wrapper"},[_c('GmapMap',{ref:"map",staticStyle:{"position":"absolute","top":"0","right":"0","bottom":"0","left":"0"},attrs:{"center":{
      lat: 37.5729503,
      lng: 126.97935789999997,
    },"zoom":_vm.zoom,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      rotateControl: false,
      scrollwheel: true,
      fullscreenControl: false,
      autobindAllEvents: false,
      disableDefaultUi: true,
      minZoom: 10,
      maxZoom: 20,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    }},on:{"drag":_vm.onDrag,"click":_vm.onClick,"tilesloaded":_vm.onTilesLoaded,"dragstart":_vm.onDragStart,"dragend":_vm.onDragEnd,"center_changed":_vm.onCenterChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }