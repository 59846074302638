<template>
  <div id="iez-main">
    <div class="section lugstay-section">
      <div class="container">
        <div class="textarea">
          <div class="subtitle">
            {{
              $__t("Store Renting Spaces") +
                " " +
                $__t("Luggage Storage Service")
            }}
          </div>
          <div class="title">
            {{ $__t("LugStay") }}
          </div>
          <div class="subtext">
            {{
              $__t(
                "Keep your all kinds of items anytime, anywhere in local stores on your mobile phone."
              )
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="section necessary-moment-section">
      <div class="container">
        <div class="header">
          {{ $__t("The moments you need LugStay") }}
        </div>
        <div class="body">
          <lug-carousel
            slideType="slide"
            :arrow="false"
            :pager="true"
            :zoomable="true"
          >
            <lug-carousel-item>
              <div class="body">
                <div class="row">
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("shopping items become hassle")
                          | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-shopping.png" />
                    </div>
                  </div>
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("feel liberated while traveling")
                          | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-travel.png" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("give a precious thing to loved one")
                          | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-gift.png" />
                    </div>
                  </div>
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("receive a delivery safe") | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-delivery.png" />
                    </div>
                  </div>
                </div>
              </div>
            </lug-carousel-item>
            <lug-carousel-item>
              <div class="body">
                <div class="row">
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("plans to do on your way back home")
                          | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-wayback.png" />
                    </div>
                  </div>
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("be released from big items")
                          | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-large.png" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="container">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("safe second-hand deal") | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/iez-moment-secondhand.png" />
                    </div>
                  </div>
                  <div class="container darken" @click="onClickGuideItem">
                    <div class="text" :style="wordBreakByLanguage">
                      {{
                        $__t("{iez-main}.guide.text") | capitalizeFirstLetter
                      }}
                    </div>
                    <div class="image">
                      <img src="/img/lugstay-circle.png" />
                    </div>
                  </div>
                </div>
              </div>
            </lug-carousel-item>
          </lug-carousel>
        </div>
      </div>
    </div>

    <div class="section price-section">
      <div class="container">
        <div class="header">
          {{
            $__t("Use our service by hours of storage and sizes of your item.")
          }}
        </div>
        <div class="body">
          <div class="group">
            <div class="header">
              <div class="label">
                SMALL
              </div>
              <div class="price">
                {{ $__t("10 minutes_p") }}&nbsp;<strong
                  >100{{ $__t("won") }}</strong
                >
              </div>
            </div>
            <div class="body">
              <div class="wrapper">
                <img src="/img/iez-backpack.png" alt="backpack-image" />
              </div>
              <div class="wrapper">
                <img src="/img/iez-shopping-bag.png" alt="shopping-bag-image" />
              </div>
              <div class="wrapper">
                <img src="/img/iez-purse.png" alt="purse-image" />
              </div>
              <div class="wrapper">
                <img src="/img/iez-parcel.png" alt="parcel-image" />
              </div>
            </div>
            <div class="footer">
              <span
                >{{ $__t("basic fee") | capitalizeFirstLetter }} 2,000{{
                  $__t("won")
                }}
                / {{ $__t("max per day") }} 6,000{{ $__t("won") }}</span
              >
            </div>
          </div>
          <div class="group">
            <div class="header">
              <div class="label">
                LARGE
              </div>
              <div class="price">
                {{ $__t("10 minutes_p") }}&nbsp;<strong
                  >150{{ $__t("won") }}</strong
                >
              </div>
            </div>
            <div class="body">
              <div class="wrapper">
                <img src="/img/iez-suitcase.png" alt="suitcase-image" />
              </div>
              <div class="wrapper">
                <img src="/img/iez-stroller.png" alt="stroller-image" />
              </div>
              <div class="wrapper">
                <img
                  src="/img/iez-backpack-large.png"
                  alt="backpack-large-image"
                />
              </div>
              <div class="wrapper">
                <img src="/img/iez-scooter.png" alt="scooter-image" />
              </div>
            </div>
            <div class="footer">
              <span
                >{{ $__t("basic fee") | capitalizeFirstLetter }} 3,000{{
                  $__t("won")
                }}
                / {{ $__t("max per day") }} 9,000{{ $__t("won") }}</span
              >
            </div>
          </div>
        </div>
        <div class="footer">
          <span class="text" @click="openModal('priceGuideModal')">{{
            $__t("More details of the price")
          }}</span>
        </div>
      </div>
    </div>

    <div class="section pros-section">
      <div class="container">
        <div class="header">
          {{ $__t("Safe & Easy") }}
        </div>
        <div class="body">
          <div class="container">
            <img src="/img/iez-pros-01.png" alt="pros-01-image" />
            <div class="label" :style="wordBreakByLanguage">
              {{ $__t("쉽고 편한 사용성") }}
            </div>
          </div>
          <div class="container">
            <img src="/img/iez-pros-02.png" alt="pros-02-image" />
            <div class="label" :style="wordBreakByLanguage">
              {{ $__t("안전한 보관") }}
            </div>
          </div>
          <div class="container">
            <img src="/img/iez-pros-03.png" alt="pros-03-image" />
            <div class="label" :style="wordBreakByLanguage">
              {{ $__t("Simple Payment") }}
            </div>
          </div>
          <div class="container">
            <img src="/img/iez-pros-04.png" alt="pros-04-image" />
            <div class="label" :style="wordBreakByLanguage">
              {{ $__t("분실 파손 보험") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section review-section">
      <div class="container">
        <lug-carousel
          slideType="slide"
          :arrow="false"
          :pager="true"
          :zoomable="true"
        >
          <lug-carousel-item>
            <div class="review">
              <div class="image">
                <img
                  src="/img/iez-reviewer-male.png"
                  alt="reviewer-male-image"
                />
              </div>
              <div class="body">
                <div class="author">
                  {{ $__t("{iez-main}.review1.author") }}
                </div>
                <div class="article" :style="wordBreakByLanguage">
                  {{ $__t("{iez-main}.review1.article") }}
                </div>
                <div class="created-at">
                  2020.11.28
                </div>
              </div>
            </div>
          </lug-carousel-item>
          <lug-carousel-item>
            <div class="review">
              <div class="image">
                <img
                  src="/img/iez-reviewer-female.png"
                  alt="reviewer-female-image"
                />
              </div>
              <div class="body">
                <div class="author">
                  {{ $__t("{iez-main}.review2.author") }}
                </div>
                <div class="article" :style="wordBreakByLanguage">
                  {{ $__t("{iez-main}.review2.article") }}
                </div>
                <div class="created-at">
                  2020.10.27
                </div>
              </div>
            </div>
          </lug-carousel-item>
          <lug-carousel-item>
            <div class="review">
              <div class="image">
                <img
                  src="/img/iez-reviewer-male.png"
                  alt="reviewer-male-image"
                />
              </div>
              <div class="body">
                <div class="author">
                  {{ $__t("{iez-main}.review3.author") }}
                </div>
                <div class="article" :style="wordBreakByLanguage">
                  {{ $__t("{iez-main}.review3.article") }}
                </div>
                <div class="created-at">
                  2020.11.30
                </div>
              </div>
            </div>
          </lug-carousel-item>
        </lug-carousel>
      </div>
    </div>

    <div class="section bottom-section">
      <div class="container">
        <div class="text">
          <div class="secondary">
            {{ $__t("LugStay is") }}<br />{{
              $__t(
                "a space sharing mobile service you store your luggage safe and convenient in shops such as cafe restaurant. Simply book online and drop off your luggage wherever you go."
              )
            }}
          </div>
          <div class="primary">
            {{ $__t("{iez-main}.bottom-section.text") }}
          </div>
        </div>
      </div>
    </div>

    <div class="search-button">
      <ui-solid-button display="block" @click="$parent.onActiveTab(2)">
        <button type="button">{{ $__t("go keep my item") }}</button>
      </ui-solid-button>
    </div>

    <guide-modal
      id="priceGuideModal"
      ref="priceGuideModal"
      :headerTitle="$__t('Price Details')"
    >
      <template v-slot:body>
        <price-guide />
      </template>
    </guide-modal>

    <div class="coupon-modal" v-if="isShowCouponModal">
      <div class="coupon-modal-body">
        <div class="modal-close-button">
          <i class="fa fa-times" @click="closeCouponModal"></i>
        </div>
        <div class="modal-title-section" style="padding-top: 0;">
          {{ $__t("New coupon issued") }}
        </div>
        <div class="modal-image-section" style="margin-top: 1rem;">
          <div class="coupon-left">
            <div class="coupon-image" v-if="selectedCoupon">
              <div
                class="coupon-image-leftside"
                :class="{ fix: selectedCoupon.promotion_config.type == 'fix' }"
              ></div>
              <div
                class="coupon-image-body"
                :class="{ fix: selectedCoupon.promotion_config.type == 'fix' }"
              >
                <template v-if="selectedCoupon.promotion_config.type == 'fix'">
                  <div class="coupon-unit"></div>
                  <div class="coupon-amount">
                    {{
                      selectedCoupon.promotion_config.fix_amount.toLocaleString()
                    }}{{ $__t("won") }}
                  </div>
                </template>
                <template v-else>
                  <div class="coupon-rate-amount">
                    {{ selectedCoupon.promotion_config.rate_amount }}%
                  </div>
                </template>
              </div>
              <div style="position: absolute; top:-1rem; right: -1rem;">
                <div
                  class="badge circle"
                  style="background-color: red; border-radius: 1.25rem; width: 2.5rem; height: 2.5rem;"
                >
                  <div
                    style="width: 3rem; font-size: 0.5rem;top: 0.5rem;left: -0.25rem;font-weight: 600;"
                  >
                    NEW
                  </div>
                </div>
              </div>
            </div>
            <div class="coupon-image" v-else>
              <div class="coupon-image-leftside" :class="{ fix: true }"></div>
              <div class="coupon-image-body" :class="{ fix: true }">
                <div class="coupon-rate-amount" style="font-size: 1rem;">
                  COUPON
                </div>
              </div>
              <div style="position: absolute; top:-1rem; right: -1rem;">
                <div
                  class="badge circle"
                  style="background-color: red; border-radius: 1.25rem; width: 2.5rem; height: 2.5rem;"
                >
                  <div
                    style="width: 3rem; font-size: 0.5rem;top: 0.5rem;left: -0.25rem;font-weight: 600;"
                  >
                    NEW
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedCoupon" class="modal-description"></div>
        <div
          v-else
          class="modal-description"
          style="font-size: 1.4rem; line-height: 2rem;text-align: center; color: #333333;word-break: normal; word-wrap: break-spaces;"
        >
          {{ $__t("Sign in to see your discount offers.") }}
        </div>
        <div class="modal-button">
          <div
            @click="goToMyCoupons"
            class="round-button"
            v-if="selectedCoupon"
          >
            <span
              >{{ $__t("My Coupons") }}<i class="fa fa-angle-right"></i
            ></span>
          </div>
          <div
            @click="
              isShowCouponModal = !isShowCouponModal;
              $router.push({
                name: 'AuthLogin',
                query: { redirect: $router.currentRoute.fullPath },
              });
            "
            class="round-button"
            v-else
          >
            <span>{{ $__t("로그인") }}<i class="fa fa-angle-right"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LugCarousel from "@/components/modules/LugCarousel/Body";
import LugCarouselItem from "@/components/modules/LugCarousel/Item";
import PriceGuide from "@/components/pages/_/PriceGuide";

export default {
  data() {
    return {
      selectedCoupon: '',
      isShowCouponModal: false,
    };
  },

  computed: {
    auth() {
			return this.$store.state.auth.gid;
		},
    wordBreakByLanguage() {
      const lang = this.$store.state.lang;

      let value = {};

      if (lang === "ko" || lang === "en") {
        value["word-break"] = "keep-all";
      } else {
        value["word-break"] = "break-all";
      }

      return value;
    },
    campaignCode() {
      return this.$store.getters["userData/GET_ITEM"]({ key: "campaignCode" });
    },

    promotion() {
      return this.$store.getters["userData/GET_ITEM"]({ key: "promotion" });
    },
    coupons() {
      let coupons = this.$store.getters["resourceCoupons/GET_ITEMS_BY_LIST"]({
        list: "MyCoupons",
      });

      if (coupons && coupons.length > 0) {
        coupons = coupons.filter((e) => !e.used && !e.expired);
      }

      return coupons;
    }
  },

  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    onClickGuideItem() {
      this.$parent.activeTabIndex = 1;
      this.$parent.$refs.tab.onClickItem({
        item: this.$parent.$refs.tab.items[1],
        index: 1,
      });
    },

    async handleCouponViewed() {
      if (this.coupons.length == 0) return;

      let newCoupon,
        date_now = this.$moment(),
        isBelowWeek,
        couponsViewed;

      newCoupon = this.coupons.reduce((acc, cur) =>
        this.$moment(acc.created_at) > this.$moment(cur.created_at) ? acc : cur
      );

      isBelowWeek =
        this.$moment
          .duration(date_now.diff(this.$moment(newCoupon.created_at)))
          .asDays() <= 7;

      if (!isBelowWeek) {
        return this.$store.commit("resourceCoupons/SET_VIEWED_LIST_ITEMS", {
          list: "lugstay_coupons_viewed",
          data: this.coupons,
        });
      }

      couponsViewed = this.getCouponsViewed();

      if (
        couponsViewed.length > 0 &&
        couponsViewed.indexOf(newCoupon.uid.toString()) > -1
      )
        return;

      this.$data.selectedCoupon = newCoupon;
      this.$data.isShowCouponModal = true;

      this.$store.commit("resourceCoupons/SET_VIEWED_LIST_ITEMS", {
        list: "lugstay_coupons_viewed",
        data: this.coupons,
      });
    },

    async handleCampaignPromotion() {
      if (this.auth && this.campaignCode) {
        try {
          await this.axios.post(this.$store.state.config.apiURL + "/v2/campaigns/invite/" + this.campaignCode, {}, { headers: this.$store.state.config.userHeaders });
        } catch (e) {}

        let options = {
          list: "MyCoupons",
          query: {
            page: 1,
            size: 50,
            group: "available",
          },
          clear: true,
        };
        await this.$store.dispatch("resourceCoupons/getList", options);
      }
    },

    getCouponsViewed(){
			let couponsViewed = localStorage.getItem('lugstay_coupons_viewed');

			if(!couponsViewed) return [];

			couponsViewed = couponsViewed.split(',')

			return couponsViewed;
		},

    closeCouponModal() {
      this.isShowCouponModal = false;
    },
    
    goToMyCoupons() {
      this.closeCouponModal();
      this.$router.push({ name: "MypageCoupons" });
    },
  },

  created() {
    setTimeout( async () => {
      await this.handleCampaignPromotion();
      await this.handleCouponViewed();
    }, 100);
  },

  components: {
    LugCarousel,
    LugCarouselItem,
    PriceGuide,
  },
};
</script>
