const Amplitude = {
    name: "Amplitude",

    init(app, options = {}, callback = function () {
    }) {
        this._appKey = options.appKey;
        this._deviceId = "";
        this._identify = new amplitude.Identify();

        Object.defineProperties(app.prototype, {
            $amplitude: {
                get() {
                    return this;
                },
            },
        });

        callback();
    },

    setDeviceId(deviceId) {
        this._deviceId = deviceId;

        if (this._appKey) {
            amplitude.getInstance().setDeviceId(deviceId);
        }
    },

    setVersion(version) {
        if (this._appKey) {
            amplitude.getInstance().setVersionName(version);
        }
    },

    setUserId(userId) {
        if (this._appKey) {
            amplitude.getInstance().setUserId(userId);

            if (!this._deviceId) {
                if (userId === null) {
                    amplitude.getInstance().regenerateDeviceId();
                }
            }
        }
    },

    setLanguage(language) {
        if (this._appKey) {
            this._identify.set("language", language);
            amplitude.getInstance().identify(this._identify);
        }
    },

    startSession(options = {}) {
        const initOptions = {
            saveEvents: true,
            includeGclid: true,
            includeUtm: true,
            includeReferrer: true,
            sameSiteCookie: "None",
            secureCookie: true,
            saveParamsReferrerOncePerSession: false,
            ...options,
        };

        if (this._appKey) {
            amplitude.getInstance().init(this._appKey, null, initOptions);
            amplitude.getInstance().identify(this._identify);
        }
    },

    logEvent(eventName, options) {
        if (this._appKey) {
            amplitude.getInstance().logEvent(eventName, options);
        }
    },
};

export default Amplitude;
