<template>
  <div class="page home">
    <div class="home__header">
      <profile /> <toolbar />
    </div>

    <div class="home__body">
      <div class="container">
        <template v-if="loading">
          <loader />
        </template>

        <template v-else>
          <div class="section action-section">
            <luggage-action />
          </div>

          <div class="section content-section" v-if="contentList.length > 0">
            <banner-service-products :items="contentList" />
          </div>

          <div class="section usage-section">
            <ticket-slider :items="availableTickets" :status="['ISSUED', 'CHECK_IN']" />
          </div>
        </template>

        <div class="section info-section">
          <navigation />
        </div>
      </div>
    </div>

    <div class="home__footer"></div>

    <portal to="destination">
      <!-- 공지가 있을 때 활성화 -->
      <!-- <popup-user-notice /> -->
    </portal>
  </div>
</template>

<script>
import BannerServiceProducts from '@/v3/components/resources/home/BannerServiceProducts.vue';
import Loader from '@/v3/components/resources/home/Loader.vue';
import LuggageAction from '@/v3/components/resources/home/LuggageAction.vue';
import Navigation from '@/v3/components/resources/home/Navigation.vue';
import PopupUserNotice from '@/v3/components/resources/home/PopupUserNotice.vue';
import Profile from '@/v3/components/resources/home/Profile.vue';
import TicketSlider from '@/v3/components/resources/home/TicketSlider.vue';
import Toolbar from '@/v3/components/resources/home/Toolbar.vue';

export default {
  components: {
    TicketSlider,
    BannerServiceProducts,
    LuggageAction,
    Toolbar,
    Profile,
    Navigation,
    Loader,
    PopupUserNotice
  },

  data() {
    return {
      loading: false,

      serviceProduct: {
        page: 1,
        size: 10,
        list: 'Home_contentList'
      },
      ticketQuery: {
        page: 1,
        size: 10,
        group: 'available',
        list: 'MyTickets_available'
      },
      couponQuery: {
        page: 1,
        size: 10,
        group: 'available',
        list: 'MyCoupons_available'
      },
      billingQuery: {
        page: 1,
        size: 10,
        group: 'available',
        list: 'MyBillings_available'
      }
    };
  },
  computed: {
    isRouterChanged() {
      return this.$store.state.path.isRouterChanged;
    },

    isLoggedIn() {
      return this.$store.state.auth.gid > 0;
    },

    contentList() {
      return this.$store.getters['serviceProduct/GET_LIST']({
        list: 'Home_contentList'
      });
    },

    availableTickets() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'MyTickets_available'
      });
    }
  },
  methods: {
    async onLoad() {
      try {
        this.loading = true;

        let promises = [];
        promises.push(this.fetchServiceProducts());

        if (this.isLoggedIn) {
          promises.push(this.fetchTickets());
          promises.push(this.fetchBillings());
        }

        this.$store.dispatch("custom/checkNoticePopups");

        await Promise.race(promises);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    fetchServiceProducts() {
      return this.$store.dispatch('serviceProduct/list', {
        ...this.serviceProduct,
        list: this.serviceProduct.list,
        clear: true
      });
    },
    fetchTickets() {
      return this.$store.dispatch('tickets/getList', {
        list: 'MyTickets_available',
        filter: 'available',
        query: { ...this.ticketQuery },
        clear: true
      });
    },
    fetchCoupons() {
      return this.$store.dispatch('resourceCoupons/getList', {
        list: 'MyCoupons_available',
        query: { ...this.couponQuery },
        clear: true
      });
    },
    fetchBillings() {
      return this.$store.dispatch('billings/getCardList', {
        list: 'MyBillings_available',
        query: { ...this.billingQuery },
        clear: true
      });
    },
  },
  async created() {
    await this.onLoad();
  },
  mounted() {
    //
  }
};
</script>

<style lang="scss" scoped>
.home {
  @include fill-viewport-min-height;

  background: #f8fafb;
  max-width: $max-content-width;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: unit(32);

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: unit(16);
    min-width: 0;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }

    .section {
      width: 100%;
      padding: 0;

      &.action-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &.content-section {
        margin-top: unit(16);
        width: 100%;
      }

      &.usage-section {
        margin-top: unit(16);
        max-width: min($max-content-width, 100vw);

        .title {
          font-weight: 700;
          font-size: 18px;
          margin: 0 unit(16);
          line-height: unit(26);
        }
      }

      &.info-section {
        margin-top: unit(16);
        width: 100%;
      }
    }
  }

  &__footer {
  }
}
</style>
