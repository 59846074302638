<template>
  <transition name="slow-slide-down">
    <div class="checkout-dialog">
      <div class="checkout-dialog-container">
        <div class="body">
          <div class="image-area">
            <viewer :options="{ toolbar: false, title: false }">
              <div class="image-wrapper">
                <img
                  :src="data?.ticket?.resources?.luggage_photo?.resource_url"
                  v-if="
                    data?.ticket?.resources &&
                    data?.ticket?.resources?.luggage_photo &&
                    data?.ticket?.resources?.luggage_photo?.resource_url
                  "
                  class="luggage"
                />
                <img src="/img/no-image.png" class="luggage" v-else />
                <div
                  v-if="
                    data?.ticket?.resources &&
                    data?.ticket?.resources?.luggage_photo &&
                    data?.ticket?.resources?.luggage_photo?.resource_url
                  "
                  class="zoomable"
                />
              </div>
            </viewer>
          </div>
          <div class="message-area">
            {{ data?.message }}
            <div class="message-notice">
              {{ data?.notice }}
            </div>
          </div>
          <div class="submit-weighted-button-area">
            <button
              v-button
              type="button"
              class="action_cancel"
              @click="callback('cancel')"
            >
              {{ data?.cancel?.label }}
            </button>
            <button
              v-button
              type="button"
              class="action_ok"
              @click="callback('ok')"
              :disabled="checkoutButtonDisable"
            >
              {{ data?.ok?.label }}
            </button>
          </div>
        </div>
        <!-- <div class="footer">
          <div class="calendar-area">
            <VueCtkDateTimePicker
              id="checkoutDatePicker"
              locale="en"
              v-model="checkoutTime"
              :no-value-to-custom-elem="true"
              :min-date="minimumCheckoutDate"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="YYYY-MM-DD HH:mm:ss"
              label="YYYY-MM-DD HH:mm:ss"
              :no-label="true"
              :no-shortcuts="true"
              :no-button-now="true"
              minuteInterval="1"
              timeItemSize="lg"
              @validate="showCheckoutTime"
            >
              <span class="checkout">{{ $__t('Set check out time') }}</span>
            </VueCtkDateTimePicker>
            <div v-if="showFlag && showTime" class="checkout-time-selected">
              {{ showTime }}
              <input v-button type="button" @click="hideCheckoutTime" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import Viewer from "v-viewer";

export default {
  props: ["data", "checkoutButtonDisable"],
  data() {
    let date_now = this.$moment().format("YYYY-MM-DD HH:mm:ss");
    return {
      checkoutTime: date_now,
      // showFlag: false,
      showFlag: true,
      loading: false,
    };
  },
  computed: {
    showTime() {
      return this.$moment(this.checkoutTime).format("MM-DD HH:mm");
    },
    minimumCheckoutDate() {
      const check_in = this.$moment(this.data?.ticket?.datetime_check_in);

      return this.$moment(check_in).add(1, "minutes").format("YYYY-MM-DD HH:mm");
    },
  },
  methods: {
    callback(response) {
      this.loading = true;
      if (response === "ok" && this.data?.ticket?.issue_type === "DEPOSIT_ADVANCE") {
        this.$emit("makeCheckoutButtonDisable");
        let payload = {
          code: this.data?.ticket?.ticket_code,
          data: {
            checkout_time: this.showFlag && this.checkoutTime ? this.checkoutTime : "",
          },
        };
        this.$store
          .dispatch("tickets/checkoutAfter", payload)
          .then((res) => {
            this.$store.commit("tickets/SET_ITEM", { data: res });
            this.$store.commit("alert/ADD_ITEM", {
              message: "체크아웃되었습니다.",
              status: "success",
            });
            this.loading = false;
            if (this.$route.name == "HostTickets") {
              this.$parent.$parent.getListItems(true);
              this.$parent.$parent.getTicketStatusCount();
            } else if (this.$route.name == "HostCheck") {
              this.$router.push({ name: "HostTickets" });
            }
          })
          .catch((err) => {
            // console.log('err', err);
            this.$log.error(err);
            this.$store.commit("alert/ADD_ITEM", { message: err, status: "error" });
            this.loading = false;
          });
      } else {
        // console.log('else');
        // console.log('this.data[response]', this.data[response]);
        this.data[response].callback();
        this.loading = false;
      }
    },
    showCheckoutTime() {
      this.showFlag = true;
    },
    hideCheckoutTime() {
      this.showFlag = false;
    },
  },
};
</script>
