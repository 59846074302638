<template>
  <div id="ticket-receipt-page" class="page more-depth">
    <div class="container">
      <div ref="header" class="page-header">
        <div class="page-header__title">
          <h1 class="text">
            {{ $__t("{credit-card-slip}.title") }}
          </h1>
        </div>
        <div ref="download" class="page-header__link-area">
          <button type="button" @click="download">
            {{ $__t("{credit-card-slip}.download") }}
          </button>
        </div>
      </div>

      <div class="page-body">
        <div class="slip-info">
          <!-- 결제 카드 정보 -->
          <ul class="slip-info__list-section">
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.approved_number") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.typesofcards") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.card_number") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.typesoftransaction") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.general/installation") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.transaction_date") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.item") }}
              </div>
              <div class="secondary"></div>
            </li>
          </ul>

          <!-- 보관 상점 정보 -->
          <ul class="slip-info__list-section">
            <div class="slip-info__list-section__title">
              <h3>{{ $__t("{credit-card-slip}.key.store-info") }}</h3>
            </div>
            <li>
              <div class="primary">
                {{ $__t("상점 이름") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("대표자명") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("사업자등록번호") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("전화번호") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("사업장 주소") }}
              </div>
              <div class="secondary"></div>
            </li>
          </ul>

          <!-- 가맹점 정보 (PG) -->
          <ul class="slip-info__list-section">
            <div class="slip-info__list-section__title">
              <h3>{{ $__t("가맹점 정보") }}</h3>
            </div>
            <li>
              <div class="primary">
                {{ $__t("상점 이름") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("대표자명") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("사업자등록번호") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("전화번호") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("사업장 주소") }}
              </div>
              <div class="secondary"></div>
            </li>
          </ul>

          <!-- 금액 정보 -->
          <ul class="slip-info__list-section">
            <div class="slip-info__list-section__title">
              <h3>{{ $__t("{credit-card-slip}.title.price") }}</h3>
            </div>
            <li>
              <div class="primary">
                {{ $__t("{credit-card-slip}.key.approved_amount") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("Value of Supply") }}
              </div>
              <div class="secondary"></div>
            </li>
            <li>
              <div class="primary">
                {{ $__t("{card-slip}.key.vat") }}
              </div>
              <div class="secondary"></div>
            </li>
          </ul>
        </div>
      </div>

      <div class="page-footer"></div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { v4 as uuid } from "uuid";

export default {
  props: ["id"],

  data() {
    return {
      downloading: false,
    };
  },

  computed: {
    ticket() {
      return this.$store.getters["tickets/GET_ITEM"]({ key: "ticket_code", value: this.id });
    },

    receipt() {
      return this.$store.getters["orders/GET_ITEM"]({ key: "order_id", value: this.ticket?.receipt_id });
    },

    paidTotal() {
      return parseInt(this.ticket.paid_total || 0).toLocaleString() + this.$__t("won");
    },

    storeAddress() {
      const lang = this.$store.state.lang;
      let address = this.doc.store_address;
      let address_detail = this.doc.store_address_detail;

      if (address_detail) {
        if (lang == "ko") {
          address +
            " " +
            address_detail.ko +
            ", " +
            (address_detail.floor === "-1" ? "지하 1층" : address_detail.floor + "층");
        } else {
          address_detail.floor + "F, " + address_detail.base + address;
        }
      }

      return address;
    },
  },

  methods: {
    async download() {
      if (this.downloading) return;
      const el_download = this.$refs.download.cloneNode(true);

      try {
        this.$store.commit("loading/SET_TRUE");
        this.$refs.download.remove();

        await this.$nextTick;

        const canvas = await this.generateCanvas();
        return this.generatePdf(canvas);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.$refs.header.appendChild(el_download);
        this.$store.commit("loading/SET_FALSE");
        this.downloading = false;
      }
    },

    async generateCanvas() {
      return await html2canvas(this.$el, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight,
      });
    },

    generatePdf(canvas) {
      let filename = uuid()+".pdf";
      let imgData = canvas.toDataURL("image/png");
      let imgWidth = 210;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let pageHeight = imgWidth * 1.414;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 20) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      if(this.wni.isNative){
        return this.wni.execute("wnDownloadURL", { 
          url: doc.output('datauristring'),
          filename
        })
      }


      return doc.save(filename);
    },
  },
};
</script>
