<template>
	<div id="luggage-explanation">
		<div class="container">
			<div class="section">
				<div class="title">물품 종류</div>
				<div class="table">
					<div class="row">
						<div class="cell category">
							Small
						</div>
						<div class="cell">
							<div class="items">
								<div class="item">
									<div class="container">
										<img src="/img/item-backpack-small.png" />
										<div class="text">{{ $__t('Backpack') }}</div>
									</div>
								</div>

								<div class="item">
									<div class="container">
										<img src="/img/item-shoppingbag.png" />
										<div class="text">{{ $__t('Shopping Bag') }}</div>
									</div>
								</div>

								<div class="item">
									<div class="container">
										<img src="/img/item-parcel.png" />
										<div class="text">{{ $__t('Parcel') }}</div>
									</div>
								</div>

								<div class="item">
									<div class="container">
										<img src="/img/item-purse.png" />
										<div class="text">{{ $__t('Purse') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="cell category">
							Large
						</div>
						<div class="cell">
							<div class="items">
								<div class="item">
									<div class="container">
										<img src="/img/item-suitcase.png" />
										<div class="text">{{ $__t('Suitcase') }}</div>
										<div class="text small">({{ $__t('Checked/Carry-on') }})</div>
									</div>
								</div>

								<div class="item">
									<div class="container">
										<img src="/img/item-backpack-big.png" />
										<div class="text">{{ $__t('Travel Backpack') }}</div>
									</div>
								</div>

								<div class="item">
									<div class="container">
										<img src="/img/item-scooter.png" />
										<div class="text">{{ $__t('Kick Scooter') }}</div>
									</div>
								</div>

								<div class="item">
									<div class="container">
										<img src="/img/item-stroller.png" />
										<div class="text">{{ $__t('Stroller') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>
