<template>
  <div class="ticket-message" :class="{ 'ticket-message--activated': activated }">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{
            $route.name === 'ShareTicketSingle'
              ? ticket.status === 'CHECK_IN'
                ? $__t('전달완료')
                : $__t('찾기완료')
              : $__t('보관완료') | toUpperCase
          }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_check_in) | moment('HH:mm') }}
        </div>
      </div>
      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body">
            <div class="message-body__image-area">
              <div
                class="message-body__image-wrapper"
                :class="{ 'no-image': !ticket.resources || !ticket.resources.luggage_photo || !ticket.resources.luggage_photo.resource_url }"
              >
                <template v-if="ticket.resources && ticket.resources.luggage_photo && ticket.resources.luggage_photo.resource_url">
                  <img :src="ticket.resources.luggage_photo.resource_url" class="message-body__image" />
                </template>
                <img v-else src="/img/no-image.png" class="message-body__image" />
              </div>
              <div class="message-body__image-label">
                <span class="text">{{ ticket.ticket_code }}</span>
              </div>
            </div>
            <div class="message-body__contents">
              <h3 class="primary">
                {{ ticket.store_name }}
              </h3>
              <p class="secondary">
                <span>{{ reserveDateTimeText }}</span
                ><br />
                <span>{{ ticketQuantityText }}</span>
              </p>
              <button v-if="ticket.status === 'ISSUED'" type="button" @click="cancelReservation" class="cancel-button" v-button>
                {{ $__t('Cancel Booking') | capitalizeFirstLetter }}
              </button>
            </div>
            <div class="message-body__actions">
              <!-- <button
                v-button
                type="button"
                @click="
                  $analytics.logEvent('ticket delivery button');
                  $emit('open-invite');
                "
                :class="{ disabled: ticket.status !== 'CHECK_IN' || isInvited }"
                :disabled="ticket.status !== 'CHECK_IN' || isInvited"
                class="action-button"
              >
                {{ $__t('티켓 전달') | toUpperCase }}
              </button> -->
              <button v-button type="button" @click="$emit('open-find')" class="action-button">
                {{ $__t('상점 찾기') | toUpperCase }}
              </button>
              <button
                v-button
                type="button"
                @click="$emit('open-camera')"
                class="action-button"
                :class="{ uploadImage: ticket.resources && ticket.resources.luggage_photo && ticket.resources.luggage_photo.resource_url }"
                :disabled="ticket.resources && ticket.resources.luggage_photo && ticket.resources.luggage_photo.resource_url"
              >
                {{ $__t('보관 물품 촬영') | toUpperCase }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from '@/components/includes/QrCode';
import { directive as Viewer } from 'v-viewer';

export default {
  name: 'MessageCheckIn',

  props: {
    ticket: {
      type: Object,
      default: {}
    },

    order: {
      type: Object,
      default: {}
    },

    activated: {
      type: Boolean,
      default: false
    },

    invitation: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {};
  },

  computed: {
    onClickHeaderButton() {
      return null;
    },

    reserveDateTimeText() {
      const momentDateStart = this.$moment(this.ticket.ticket_date_start);
      const momentDateEnd = this.$moment(this.ticket.ticket_date_end);
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      return text;
    },

    checkInDateTimeText() {
      let text = '';

      if (this.ticket?.datetime_check_in) {
        const momentCheckIn = this.$moment(this.ticket.datetime_check_in);
        const momentNow = this.$moment();

        if (momentNow.format('YYYY-MM-DD') === momentCheckIn.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentCheckIn.format('HH:mm')}`;
        } else {
          text = `${momentCheckIn.format('MM/DD')}(${this.$__t('SSA_' + momentCheckIn.format('ddd').toUpperCase())}) ${momentCheckIn.format(
            'HH:mm'
          )}`;
        }
      }

      return text;
    },

    ticketQuantityText() {
      try {
        let value = '';
        //물품 단일화 버전
        if (this.ticket?.extras?.version) {
          if (this.ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t('물품')} ${this.ticket.ticket_quantity}`;

            if (this.ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t('item');
            } else {
              value += quantityString + this.$__t('items');
            }
          }
          //물품 단일화 전(기존 버전)
        } else {
          if (this.ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.ticket.ticket_quantity}`;

            if (this.ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t('item');
            } else {
              value += quantityString + this.$__t('items');
            }
          }

          if (this.ticket.ticket_over_quantity > 0) {
            let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.ticket.ticket_over_quantity}`;

            if (this.ticket.ticket_over_quantity > 0) quantityString = ' ' + quantityString;

            if (this.ticket.ticket_over_quantity === 1) {
              value += quantityString + this.$__t('item');
            } else {
              value += quantityString + this.$__t('items');
            }
          }
        }
        return value;
      } catch (e) {
        return null;
      }
    },

    isInvited() {
      const view_type = localStorage.getItem('__LUGSTAY__VIEW__TYPE__');

      if (view_type === 'iez') {
        return false;
      }

      if (Array.isArray(this.invitation) && this.invitation.length > 0) {
        return true;
      }

      return false;
    }
  },

  created() {},

  components: {
    QrCode
  },

  directives: {
    viewer: Viewer
  }
};
</script>

<style scoped lang="scss">
.message-body {
  &__image-area {
    margin: unit(6) 0;
    padding: unit(24);
    padding-bottom: 0;
    border-bottom: dashed 1px #a9afb3;
  }

  &__image-wrapper {
    width: 100%;
    padding-bottom: 50%;
    border-radius: unit(3);
  }
  .no-image {
    height: 0px;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-label {
    padding: unit(6);
    text-align: center;

    .text {
      color: #a9afb3;
      line-height: unit(24);
      font-size: unit(16);
      font-weight: 500;
    }
  }
  &__actions {
    .uploadImage {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
