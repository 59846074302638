<template>
  <div class="ui-card-selector">
    <div class="ui-card-selector-viewport" ref="viewport">
      <div class="ui-card-selector-frame" ref="frame">
        <ul class="ui-card-selector-list" ref="list" :style="`width: ${(this.items.length + 1) * 100}%`">
          <CardItem ref="card" v-for="(item, index) in items" :item="item" :key="`card-item-${index + 1}`" :class="{on: isCenter(index)}"/>
          <CardItem ref="card" />
          <!--  결제 카드 등록 -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from "./Item.vue";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    initialItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    let idx = 0;
    let dragEventNames = {
      start: ["mousedown", "touchstart"],
      move: ["mousemove", "touchmove"],
      end: ["mouseup", "touchend"],
    };

    let previousX = 0;
    let offsetX = 0;
    let position = 0;
    let itemWidth = 0;

    return {
      idx,
      dragEventNames,
      previousX,
      offsetX,
      position,
      itemWidth,
      itemNodes: [],
    };
  },

  watch: {
    idx: {
      handler(newValue) {
        this.onIdxChange(newValue);
      },
    },
  },

  computed: {
    userData() {
      return {
        pay_method: this.$store.getters["userData/GET_ITEM"]({ key: "pay_method" }),
      };
    },
  },

  mounted() {
    setTimeout(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize);
      this.bindEvent();
    }, 0);

    setTimeout(() => {
      this.setInitialIdx();
    }, 250);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onIdxChange(newIdx) {
      // this.itemNodes = this.$refs.list.querySelectorAll(".ui-card-selector-item");
      // for (var i = 0; i < this.itemNodes.length; i++) {
      //   this.itemNodes[i].classList.remove("on");
      // }

      if (newIdx > -1 && newIdx < this.items.length) {
        // this.itemNodes[newIdx].classList.add("on");
        this.$emit("update", this.items[newIdx]);
      } else {
        this.$emit("update", null);
      }
    },

    onResize() {
      const FRAME = this.$refs.frame;
      const FRAME_STYLE = getComputedStyle(FRAME);

      const innerHeight =
        FRAME.clientWidth - parseFloat(FRAME_STYLE.paddingLeft) - parseFloat(FRAME_STYLE.paddingRight);

      FRAME.style.height = innerHeight * 0.59 + "px";
      this.itemWidth = this.getItemWidth();

      this.position = -1 * this.idx * this.itemWidth;
      this.setPosition(0);
    },

    bindEvent() {
      this.$refs.viewport.addEventListener(
        this.dragEventNames.start[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragStart,
        { capture: true }
      );
    },

    bindDragStart(e) {
      e.stopPropagation();
      this.previousX = this.$store.state.config.isTouchDevice ? e.touches[0].clientX : e.x;
      this.previousY = this.$store.state.config.isTouchDevice ? e.touches[0].clientY : e.y;

      this.$refs.viewport.addEventListener(
        this.dragEventNames.move[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragMove,
        { passive: true }
      );
      this.$refs.viewport.addEventListener(
        this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragEnd
      );
    },

    bindDragMove(e) {
      this.offsetX = this.$store.state.config.isTouchDevice
        ? this.previousX - e.touches[0].clientX
        : this.previousX - e.x;
      this.offsetY = this.$store.state.config.isTouchDevice
        ? this.previousY - e.touches[0].clientY
        : this.previousY - e.y;

      if (
        this.offsetX > this.$refs.viewport.offsetWidth / 4 ||
        this.offsetX < (-1 * this.$refs.viewport.offsetWidth) / 4
      ) {
        this.$refs.viewport.dispatchEvent(
          new Event(this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0])
        );
        return;
      }

      this.position = -1 * this.offsetX + -1 * this.idx * this.itemWidth;

      this.setPosition(0);

      if (Math.abs(this.offsetX) > 2 * Math.abs(this.offsetY)) {
        document.body.style.overflow = "hidden";
      }
    },

    bindDragEnd(e) {
      if (this.offsetX > this.$refs.viewport.offsetWidth / 4) {
        this.incrementIndex();
      } else if (this.offsetX < (-1 * this.$refs.viewport.offsetWidth) / 4) {
        this.decrementIndex();
      }

      this.position = -1 * this.idx * this.itemWidth;

      this.offsetY = 0;
      document.body.style.overflow = "";

      this.setPosition(Math.abs(this.offsetX) / 500 + 0.1);

      this.$refs.viewport.removeEventListener(
        this.dragEventNames.move[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragMove
      );
      this.$refs.viewport.removeEventListener(
        this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragEnd
      );
    },

    incrementIndex() {
      this.idx = this.idx === this.items.length ? this.idx : this.idx + 1;
    },

    decrementIndex() {
      this.idx = this.idx === 0 ? this.idx : this.idx - 1;
    },

    setPosition(transformDuration = 0) {
      let itemPosition = `
        width: ${(this.items.length + 1) * 100}%;
        transition-timing-function: ease-out;
        transition: transform ${transformDuration}s;
        transform: translateX(${this.position}px);
      `;

      this.$refs.list.style.cssText = itemPosition;
    },

    getItemWidth() {
      return Array.isArray(this.$refs.card) ? this.$refs.card[0].$el.clientWidth : this.$refs.card.$el.clientWidth;
    },

    setInitialIdx() {
      let primaryBillingIndex = this.items.findIndex((o) => o.is_primary > 0);
      let userDataBillingIndex = this.items.findIndex((o) => o.uid === this.userData.pay_method.uid);

      this.idx = userDataBillingIndex > -1 ? userDataBillingIndex : (primaryBillingIndex > -1 ? primaryBillingIndex : 0);

      this.position = -1 * this.idx * this.itemWidth;
      this.setPosition(Math.abs(this.itemWidth) / 800 + 0.1);

      this.onIdxChange(this.idx);
    },

    onClickAddCard() {
      const IS_RESERVE_PAGE = this.$route.fullPath.includes("reserve");
      const IS_BILLING_PAGE = this.$route.fullPath.includes("billing");

      if (IS_RESERVE_PAGE) {
        return this.$router.push({ name: "ReserveAddPayMethod" });
      }

      if (IS_BILLING_PAGE) {
        return this.$router.push({ name: "MoreBillingAddPayMethod" });
      }
    },

    isCenter(index){
      return index === this.idx;
    }
  },

  components: {
    CardItem,
  },
};
</script>
