<template>
    <div class="page">
    </div>
</template>

<script>
    export default {
        created(){
            location.replace( '/campaign/codes/'+this.$route.meta.inviteCode );
        }
    };
</script>
