<template>
  <div class="ui-coupon for-guest" :class="{
    ISSUED: coupon.status == 'ISSUED',
    CHECK_IN: coupon.status == 'CHECK_IN',
    CHECK_OUT: coupon.status == 'CHECK_OUT',
    CANCELLED: coupon.status == 'CANCELLED',
    EXPIRED: isExpired,
  }">
    <div class="container">
      <ui-solid-button class="review-button" size="extra-small" v-if="coupon.status == 'CHECK_OUT' && !coupon.review"><button type="button" @click="openReviewForm">{{ $__t( "Review" ) }}</button></ui-solid-button>
      <router-link :to="{ name: 'MypageTicketSingle', params: { id: coupon.coupon_id } }">
        <div class="store">
          <div class="name"><div class="status">{{ isExpired ? $__t( "EXPIRED" ) : $__t( coupon.status ) }}</div>{{ coupon.store_name }}</div>
          <div class="address">{{ coupon.store_address }} ({{ coupon.store_zipcode }})</div>
        </div>
        <div class="reserve">
          <ul>
            <li>
              <div class="key">{{ $__t( "결제 일시" ) }}</div>
              <div class="value">
                <div class="payment-time">{{ coupon.timestamp | moment( "YYYY. MM. DD. HH:mm" ) }}</div>
              </div>
            </li>
            <li>
              <div class="key">{{ $__t( "기간" ) }}</div>
              <div class="value">
                <div class="duration-start">{{ coupon.coupon_date_start | moment( "YYYY. MM. DD. HH:mm" ) }} ~ {{ coupon.coupon_date_end | moment( "YYYY. MM. DD. HH:mm" ) }}</div>
              </div>
            </li>
            <li>
              <div class="key">{{ $__t("No. of items" ) }}</div>
              <div class="value">
                <div class="luggage-quantity">{{ coupon.coupon_quantity }}{{ $__t( "개" ) }}</div>
              </div>
            </li>
          </ul>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: [ "coupon" ],
  computed: {
    isExpired(){
      return this.coupon && this.$moment().isAfter( this.$moment( this.coupon.coupon_date_end ) ) && (this.coupon.status == "ISSUED" || this.coupon.status == 'EXPIRED');
    }
  },
  created(){
    this.$store.dispatch( "resourceReviews/getByTicket", { query: { store_id: this.coupon.store_id, coupon_id: this.coupon.coupon_id } }).then( res => {
      this.$store.commit( "tickets/SET_ITEM", {
        data: {
          coupon_id: this.coupon.coupon_id,
          review: res.data.data
        }
      });
    }).catch( err => {});
  },
  methods: {
    openReviewForm(){
      this.$store.commit( "modal/ADD_ITEM", { component: "ReviewForm", size: "small", payload: this.coupon })
    }
  },
}
</script>
