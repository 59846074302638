<template>
  <div>
    <div v-if="loading" class="loading">
      <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
    </div>
    <div v-else class="ticket-check-in-without-qr">
      <h1 class="header">{{ $__t("상점번호를 입력해주세요.") }}</h1>
      <p class="detail">{{ $__t("상점번호는 호스트님에게 문의해주세요") }} :)</p>
      <div class="input-area">
        <div class="title">{{ $__t("상점번호") }}</div>
        <input
          v-model="storeId"
          type="number"
          pattern="\d*"
          :placeholder="$__t('상점번호를 입력해주세요.')"
        />
        <div
          class="inquiry"
          v-html="
            $__t(
              `If the host doesn't know the store number, Please contact customer center`
            )
          "
        ></div>
      </div>
      <div class="button-area">
        <button class="cancel" @click="onClickCancel">{{ $__t("취소") }}</button>
        <button class="confirm" :class="{ activated: storeId }" @click="onClickConfirm">
          {{ $__t("확인") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import loadImage from 'blueimp-load-image';
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/loading-primary.json";

import Debug from "debug";
const debug = Debug("lugstay:web:components:includes:ticket:CheckInWithoutQr");

export default {
  props: ["ticket"],

  data() {
    return {
      loading: false,
      lottieOptions: {
        animationData: LottieLoading,
      },

      file: null,
      resource: null,
      imagePreview: "",
      storeId: null,
    };
  },
  watch: {},
  methods: {
    async onClickConfirm() {
      debug("onClickConfirm");

      if (parseInt(this.storeId) !== this.ticket.store_id) {
        alert("상점번호를 다시 입력하세요.");
        this.storeId = null;
        return;
      }

      this.doCheckIn();
    },

    onClickCancel() {
      debug("onClickCancel");

      this.onStatus("cancel");
      this.doClose();
    },

    onStatus(status) {
      debug("onStatus", status);

      this.$emit("status", status);
    },

    async doCheckIn() {
      debug("doCheckIn");

      try {
        await this.$store.dispatch("tickets/luggageCheckIn", {
          code: this.ticket.ticket_code,
        });
        this.onStatus("done");
      } catch (e) {
        console.error(e);
        debug("doCheckIn.error", e);
        this.onStatus("error");
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      } finally {
        this.doClose();
      }
    },

    doClose() {
      debug("doClose");

      this.$emit("close");
    },
  },
  components: {
    Lottie,
  },
};
</script>

<style scoped lang="scss">
.ticket-check-in-without-qr {
  background: $color-white;
  color: #101010;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  .header {
    margin-top: unit(24);
    font-size: unit(20);
    line-height: 1.45;
    font-weight: 600;
    text-align: left;
  }
  .detail {
    font-size: unit(16);
    margin: unit(5) 0 unit(40) 0;
    text-align: left;
    color: #404040;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .input-area {
    // text-align: center;
    .title {
      font-size: unit(18);
      font-weight: 600;
    }
    input {
      margin: 0 auto unit(150) auto;
      border-bottom: 1px solid #c8c8c8;
      height: unit(30);
      width: 100%;
      font-size: unit(20);
      &::placeholder {
        color: #c8c8c8;
        font-size: unit(18);
        font-weight: 400;
      }
      &:focus {
        border-color: #48d9eb;
      }
    }
    .inquiry {
      line-height: unit(24);
      color: #787878;
      font-size: unit(16);
      .call {
        color: #404040;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  .button-area {
    padding: unit(20) 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: unit(10);
    button {
      // width: 48%;
      height: unit(60);
      border-radius: unit(12);
      font-size: unit(14);
      color: #ffffff;
      &.confirm {
        width: 60%;
        background: #e0e0e0;
        &.activated {
          background: #48d9eb;
        }
      }
      &.cancel {
        width: 40%;
        background: #fefefe;
        color: #404040;
        border: 1px #e0e0e0 solid;
      }
    }
  }
}
</style>
