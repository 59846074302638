<template>
  <div class="ticket-slider">
    <div class="ticket-slider__viewport">
      <Carousel
        v-if="items.length > 0"
        class="ticket-slider__list"
        :style="listStyle"
        :increment-pager="true"
        :gap="16"
      >
        <CarouselItem
          v-for="(item, index) in items.filter((_) => status.indexOf(_.status) > -1)"
          :key="item.ticket_code"
          class="ticket-slider__list-item"
        >
          <router-link
            :to="{
              name:
                item.ticket_type === 'PRODUCT'
                  ? 'ServiceProductTicketSingle'
                  : 'TicketSingle',
              params: { id: item.ticket_code },
            }"
          >
            <div
              class="ticket-item ticket-item--activated"
              :class="{ on: index === idx }"
            >
              <div class="image-frame">
                <object :data="getEmoji(item)" type="image/svg+xml" />
              </div>
              <div class="info">
                <p class="primary" v-html="getPrimaryText(item)"></p>
                <p class="secondary" v-html="$__t(getSecondaryText(item))"></p>
              </div>

              <div class="indicator">
                <img src="/img/icon-arrow-right-black.png" />
              </div>
            </div>
          </router-link>
        </CarouselItem>
      </Carousel>

      <!-- 이용 가능한 티켓 아이템 없을 때 -->
      <template v-else>
        <div class="ticket-item ticket-item--empty">
          <div class="image-frame">
            <object data="/img/emoji/emoji-cry.svg" type="image/svg+xml" />
          </div>
          <p class="secondary" v-html="$__t(getSecondaryText())"></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/modules/LugCarousel/Body.vue";
import CarouselItem from "@/components/modules/LugCarousel/Item.vue";
import dates from "@/mixins/dates";
import _ from "lodash";

export default {
  components: {
    Carousel,
    CarouselItem,
  },

  props: {
    items: Array,
    status: {
      type: Array,
      default: ["ISSUED", "CHECK_IN"],
    },
    //status { ISSUED, CHECK_IN }
  },

  data() {
    var idx = 0;
    var dragEventNames = {
      start: ["mousedown", "touchstart"],
      move: ["mousemove", "touchmove"],
      end: ["mouseup", "touchend"],
    };
    var dragStartX = 0;
    var dragMoveX = 0;

    return {
      interval: null,
      momentNow: +new Date(),

      idx,
      dragEventNames,
      dragStartX,
      dragMoveX,

      eventBound: false,
    };
  },

  computed: {
    listStyle() {
      return {
        minWidth: this.items.length,
      };
    },
  },

  mixins: [dates],

  methods: {
    getPrimaryText(ticket) {
      // console.log('props', this.$props.items);
      // console.log('getPrimaryText ticket', ticket);
      if (!ticket) {
        return "";
      }

      const momentTicketDateStart = this.$moment(ticket.ticket_date_start);
      const momentCheckIn = this.$moment(ticket.datetime_check_in); // 한국 시간으로 들어온다.

      //@TODO : 분기처리
      //UMF o
      // console.log('ticket.store_name', ticket.store_name);
      if (ticket?.extra?.festival === 1) {
        return `${this.getDateLabel(momentTicketDateStart)}, ${ticket.store_name}`;
      }

      //UMF x
      if (ticket.status === "ISSUED") {
        const date = this.getDateLabel(momentTicketDateStart);
        const time = momentTicketDateStart.format("HH:mm");

        return this.$__t("{date} 에 보관 예정").replace("{date}", `${date} ${time}`);
      }

      if (ticket.status === "CHECK_IN") {
        const duration = this.$moment.duration(this.$moment.utc().diff(momentCheckIn)); // PH15H58M31
        const dateFormat =
          localStorage.getItem("lugstay_language") === "ko" ? "HH시간 mm분" : "HH:mm";
        const time = this.$moment.utc(duration.as("milliseconds")).format(dateFormat);
        // return `${this.$moment
        //   .utc(duration.as("milliseconds"))
        //   .format("HH시간 mm분")} 동안 보관중`;
        return this.$__t("{time} 동안 보관중").replace("{time}", `${time}`);
      }
      //UMF x
    },

    getSecondaryText(ticket) {
      try {
        let value = "";

        if (!ticket) {
          return this.$__t("현재 이용내역이 없습니다");
        }

        //물품 단일화 버전
        if (ticket?.extras?.version) {
          if (ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t("물품")} ${ticket.ticket_quantity}`;

            if (ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t("item");
            } else {
              value += quantityString + this.$__t("items");
            }
          }
          //물품 단일화 전(기존 버전)
        } else {
          if (ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t("{luggage-selector}.small")} ${
              ticket.ticket_quantity
            }`;

            if (ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t("item");
            } else {
              value += quantityString + this.$__t("items");
            }
          }

          if (ticket.ticket_over_quantity > 0) {
            let quantityString = `${this.$__t("{luggage-selector}.big")} ${
              ticket.ticket_over_quantity
            }`;

            if (ticket.ticket_over_quantity > 0) quantityString = " " + quantityString;

            if (ticket.ticket_over_quantity === 1) {
              value += quantityString + this.$__t("item");
            } else {
              value += quantityString + this.$__t("items");
            }
          }
        }
        return value;
      } catch (e) {
        return null;
      }
    },

    getEmoji(ticket) {
      if (!ticket) {
        return "/img/emoji/emoji-cry.svg";
      }

      if (ticket.status === "ISSUED") {
        return "/img/emoji/emoji-blank-balloon.svg";
      }

      if (ticket.status === "CHECK_IN") {
        return "/img/emoji/emoji-smile.svg";
      }

      return "/img/emoji/emoji-blank-balloon.svg";
    },
  },
};
</script>

<style lang="scss" scoped>
.lug-carousel-list::v-deep {
  padding: 0;
}

.ticket-slider {
  padding: 0;
  max-width: min($max-content-width, calc(100vh - unit(32)));

  &__viewport {
    width: 100%;
  }

  &__list::v-deep {
    .lug-carousel-list {
      padding-bottom: 0 !important;
    }
  }

  &__list-item {
    & > * {
      margin: 0 unit(16);
    }

    a {
      width: 100%;
    }
  }

  .ticket-item {
    display: flex;
    flex-direction: row;
    max-width: min($max-content-width, calc(100vh - unit(32)));
    height: unit(100);
    justify-content: flex-start;
    align-items: center;
    // border: 1px solid #e1e4e6;
    background: #ffffff;
    border-radius: unit(20);
    padding: unit(10) unit(14);
    position: relative;

    .indicator {
      position: absolute;
      right: unit(8);
      top: unit(40);

      img {
        width: unit(16);
        opacity: 0.25;
      }
    }

    .image-frame {
      background: $color-white;
      border-radius: 50px;
      width: unit(60);
      height: unit(60);
      margin-right: unit(12);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      object {
        @include fsa;
        pointer-events: none;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-right: unit(16);
    }

    .primary::v-deep {
      @include ellipsis(2);
      margin-top: unit(8);
      font-size: unit(16);
      line-height: 1.5;
      text-align: left;
      font-weight: 700;
      color: #292a2b;
    }

    .secondary {
      @include ellipsis(2);
      margin-top: unit(8);
      font-weight: 500;
      font-size: unit(14);
      line-height: 1.43;
      text-align: left;
      letter-spacing: -0.006em;
      color: #61676c;
    }

    &--activated {
      // box-shadow: unit(2) unit(2) unit(20) rgba(0, 0, 0, 0.06),
      //   unit(2) unit(2) unit(10) rgba(0, 0, 0, 0.04);
    }

    &--empty {
      margin: 0 unit(16);
    }
  }

  .empty-pager {
    height: unit(4);
    background: #eaeeef;
    border-radius: unit(10);
    margin: unit(16) unit(16) unit(2) unit(16);
  }

  .qr-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #48d9eb;
    width: unit(96);
    height: unit(33);
    border-radius: 20px;
    margin-top: unit(14);
    .qr {
      color: #ffffff;
      font-size: unit(14);
      font-weight: 700;
    }
  }
}
</style>
