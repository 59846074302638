<template>
  <div class="page" id="cs-guide-page">
    <div class="container">
      <HowToUse />
    </div>
  </div>
</template>

<script>
import HowToUse from '@/components/pages/v2/MoreHowToUse.vue';
export default {
  components: {
    HowToUse,
  },
};
</script>
