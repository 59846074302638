<template>
  <div id="ticket-detail">
    <div class="header">{{ $__t("Ticket Detail") }}</div>
    <div class="body">
      <dl v-if="ticket.ticket_type === 'LUGGAGE'">
        <dt class="key">{{ $__t("reservation schedule") | capitalizeFirstLetter }}</dt>
        <dd class="value">{{ reservationSchedule }}</dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'LUGGAGE'">
        <dt class="key">{{ $__t("check-in time") | capitalizeFirstLetter }}</dt>
        <dd class="value">{{ ticket.datetime_check_in ? $moment(ticket.datetime_check_in).format('MM-DD HH:mm') : ''}}</dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'LUGGAGE'">
        <dt class="key">{{ $__t("store name") | capitalizeFirstLetter }}</dt>
        <dd class="value">{{ ticket.store_name }}</dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'LUGGAGE'">
        <dt class="key">{{ $__t("store address") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <div class="text">{{ storeAddress }}</div>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t("reservation schedule") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.created_at | moment('YYYY-MM-DD HH:mm') }}</div>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t("{delivery-step2}.keyword.sendfrom") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.product_data.sender_place }}</div>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t( "{delivery-step2}.keyword.sender") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.product_data.sender_name ? ticket.product_data.sender_name : ticket.product_data.sender_first_name + " " + ticket.product_data.sender_last_name }}</div>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t("{delivery-step2}.keyword.receiveat") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.product_data.recipient_place }}</div>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t("{delivery-step2}.keyword.recipient") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.product_data.recipient_name ? ticket.product_data.recipient_name : ticket.product_data.recipient_first_name + " " + ticket.product_data.recipient_last_name }}</div>
        </dd>
      </dl>
      <dl>
        <dt class="key">{{ $__t("No. of items") | capitalizeFirstLetter }}</dt>
        <dd class="value">
          <span v-if="ticket.ticket_quantity">
            Small {{ticket.ticket_quantity}}개&nbsp;&nbsp;
          </span>
          <span v-if="ticket.ticket_over_quantity">
            Large {{ticket.ticket_over_quantity}}개
          </span>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t("Types of Items") }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.product_data.luggage_name }}</div>
        </dd>
      </dl>
      <dl v-if="ticket.ticket_type === 'DELIVERY'">
        <dt class="key">{{ $__t("{delivery-step3}.key.verifyticketpassword") }}</dt>
        <dd class="value">
          <div class="text">{{ ticket.product_data.password }}</div>
        </dd>
      </dl>
    </div>
    <div class="footer">
      <article>
        <ul>
          <li class="title">{{ $__t('Notice') }}</li>
          <li>{{ $__t('Storage and pick-up is only available during opening hours of the store.') }}{{ $__t('Please be sure to check the opening hours.') }}</li>
          <li>{{ $__t('Please check the opening hours cause you are not able to pick up your item on the same day, if not opening hours, visit again in another time.') }}</li>

          <li class="title">{{ $__t('Compensation Policy') }}</li>
          <li>{{ $__t('We selected partners, who have CCTV and extra storage space.') }} {{ $__t('In case of loss/damage, compensation is made up to 300,000 won, according to the compensation policy.') }}</li>

          <li class="title">{{ $__t('Cancellation & Refund') }}</li>
          <li>{{ $__t('Cancellation is available any time before the booked time.') }}</li>
          <li>{{ $__t("Unless you don't check-in in an hour, ticket will be expired and pre-paid basic fee will not be returned.") }}</li>
          <li>{{ $__t("Please check your reservation schedule before cancellation.") }}</li>
          <li>{{ $__t('Refund time for cancellation is as follows.') }}</li>
          <li>{{ $__t('If canceled on the date of reservation: instant refund.') }}</li>
          <li>{{ $__t('If canceled after the date of reservation: refund after 3-5 business days.') }}</li>
        </ul>
      </article>

      <ui-solid-button display="block" size="medium" color="brightblue" class="submit" @click="clickClose">
        <button type="button">{{$__t('Confirm')}}</button>
      </ui-solid-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ticket', 'store'],
  computed: {
    reservationSchedule(){
      return this.$moment(this.ticket.ticket_date_start).format('MM-DD HH:mm')+" ~ "+this.$moment(this.ticket.ticket_date_end).format('MM-DD HH:mm')
    },
    getTodaySchedule(){
      return this.store.time_table['SA_'+this.$moment().format('ddd').toUpperCase()].join(' ~ ')
    },
    storeAddress(){
      const lang = this.$store.state.lang;
      let address = this.store.store_address;
      let address_detail = this.store.store_address_detail

      if(address_detail){
        if(lang == 'ko'){
          address += ", " + address_detail.trim()
        }else{
          address = address_detail.trim() + ", " + address
        }
      }

      return address;
    }
  },
  methods: {
    clickClose(){
      window.history.go(-1)
    }
  }
}
</script>