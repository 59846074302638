<template>
  <div id="site-sidebar" :class="{ pc: !$store.state.config.isTouchDevice, [`site-sidebar--depth-${status.depth}`]: true }">
    <div class="container">
      <div class="site-sidebar-header">
        <template v-if="!isNaN(status.depth) && status.depth > 0">
          <div class="container">
            <button v-button class="back" type="button" @click="onBack">
              <i class="fas fa-arrow-left"></i>
            </button>
            <div class="title">{{ status.title }}</div>
          </div>
        </template>
      </div>
      <div class="site-sidebar-body">
        <keep-alive>
          <component :is="status.targetComponent" :is-sidebar="true" @close-sidebar="closeSidebar"/>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

//depth-0
import LugNavMenu from "@/components/pages/v2/_MoreList.vue";

//depth-1
import MoreGuides from "@/components/pages/v2/MoreGuides.vue";
import MorePolicy from "@/components/pages/v2/MorePolicy.vue";
import MoreLanguage from "@/components/pages/v2/MoreLanguage.vue";

export default {
  name: "Sidebar",

  data() {
    const mode = this.$store.state.config.mode;

    return {
      status: {
        depth: 0,
        targetComponent: "LugNavMenu",
        title: "",
      },

      beforeStatus: {
        depth: 0,
        targetComponent: "LugNavMenu",
        title: "",
      },
    };
  },

  mounted() {
    $("#site-sidebar .container").click((e) => {
      e.stopPropagation();
    });

    this.$el.addEventListener("click", this.checkIsSidebar);
    this.$watch("$route", (a, b) => {
      this.closeSidebar();
      this.$el.removeEventListener("click", this.checkIsSidebar);
    });
  },


  methods: {
    checkIsSidebar(e) {
      if (this.$store.state.config.isTouchDevice || e.which == 1) {
        this.closeSidebar();
      }
    },

    closeSidebar() {
      this.$emit("close");
    },

    switchTargetComponent({ targetComponent, depth = 0 }) {
      this.beforeStatus = _.cloneDeep(this.status);

      return (this.status = {
        targetComponent,
        depth,
      });
    },

    onBack() {
      let tempStatus = _.cloneDeep(this.status);
      this.status = _.cloneDeep(this.beforeStatus);
      this.beforeStatus = tempStatus;
    },
  },

  components: {
    LugNavMenu,
    MoreGuides,
    MorePolicy,
    MoreLanguage,
  },
};
</script>
