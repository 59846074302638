<template>
  <div class="resource banner-service-product content-slider">
    <div class="content-slider__viewport">
      <!--등록된 컨텐츠 o-->
      <Carousel v-if="items.length > 0" class="content-slider__list" :style="listStyle" :increment-pager="true" :delay-animation="0.1" :gap="16">
        <CarouselItem v-for="(item, index) in items" :key="item.id" class="content-slider__list-item">
          <router-link
            :to="{
              name: 'ServiceProduct',
              params: { service_id: item.uid, store_id: item.store_id }
            }"
          >
            <div class="content-item content-item--activated" :class="{ on: index === idx }" style="display: block" :style="itemStyle(item)">
              <!--backgroud넣는부분-->
              <p class="primary" v-if="item?.extras?.festival !== 1" v-html="getPrimaryText(item)"></p>
              <p class="secondary" v-if="item?.extras?.festival !== 1">{{ $__t(`${getSecondaryText(item)}`) }}</p>

              <button>
                <span>{{ $__t('구매하기') }}  <i class="fa fa-angle-right"></i></span>
              </button>
            </div>
          </router-link>
        </CarouselItem>
      </Carousel>

      <template v-else>
        <!--등록된 컨텐츠 x-->
        <div class="content-item content-item--empty">
          <div class="image-frame">
            <object data="/img/emoji/emoji-cry.svg" type="image/svg+xml" />
          </div>
          <p class="primary">{{ getPrimaryText() }}</p>
          <p class="secondary">{{ getSecondaryText() }}</p>

          <button>
            <span>{{ $__t('보관하기') }}</span>
          </button>
        </div>

        <div class="empty-pager"></div>
      </template>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/modules/LugCarousel/Body.vue';
import CarouselItem from '@/components/modules/LugCarousel/Item.vue';
import dates from '@/mixins/dates';

export default {
  components: {
    Carousel,
    CarouselItem
  },

  props: {
    items: Array
  },

  data() {
    var idx = 0;
    var dragEventNames = {
      start: ['mousedown', 'touchstart'],
      move: ['mousemove', 'touchmove'],
      end: ['mouseup', 'touchend']
    };
    var dragStartX = 0;
    var dragMoveX = 0;

    return {
      interval: null,
      momentNow: +new Date(),

      idx,
      dragEventNames,
      dragStartX,
      dragMoveX,

      eventBound: false
    };
  },

  computed: {
    listStyle() {
      return {
        minWidth: this.items.length
      };
    }
  },

  mixins: [dates],

  methods: {
    getPrimaryText(content) {
      // console.log('item', content);
      // console.log('language', typeof localStorage.getItem('lugstay_language'));
      if (!content) {
        return '등록된 컨텐츠가 없습니다';
      }
      return content.store_name_localed;
    },

    getSecondaryText(content) {
      if (!content) {
        return '';
      }
      return content.product_name;
    },

    itemStyle(item) {
      return {
        'background-image': `url(${this.getContentImage(item)})`,
        'background-size': 'cover'
      };
    },

    getContentImage(resources) {
      // console.log('resource', resources);
      // console.log('dfd', localStorage.getItem('lugstay_language'));
      // console.log('resources', resources);
      if (resources?.extras?.festival === 1) {
        if (localStorage.getItem('lugstay_language') === 'ko') {
          return resources?.resources?.banner_image?.kr;
        } else {
          return (
            resources?.resources?.banner_image?.en ||
            // 'https://lugstay.s3.ap-northeast-2.amazonaws.com/production/uploaded/sample_service_product_1.jpg'
            resources?.resources?.banner_image?.kr
          );
        }
      }
      return resources?.resources?.banner_image || 'https://lugstay.s3.ap-northeast-2.amazonaws.com/production/uploaded/sample_service_product_1.jpg';
    },
  },
  mounted() {
    // console.log('items', this.items);
  }
};
</script>

<style lang="scss" scoped>
.resource.banner-service-product {
  padding: 0 unit(16);
}

.lug-carousel-list::v-deep {
  padding: 0;
}

.content-slider {
  padding: 0;

  &__viewport {
    width: 100%;
  }

  &__list::v-deep {
    .lug-carousel-list {
      padding-bottom: 0 !important;
    }
  }

  &__list-item {
    & > * {
      // margin: 0 unit(16);
    }

    a {
      width: 100%;
    }
  }

  .content-item {
    // background-color: $color-white;
    width: 100%;
    height: unit(136);
    border: 1px solid #e1e4e6;
    border-radius: unit(20);
    padding: unit(10) unit(14);
    margin: auto;

    .image-frame {
      width: unit(30);
      height: unit(30);
      margin: auto;

      object {
        @include fsa;
        pointer-events: none;
      }
    }

    .primary::v-deep {
      @include ellipsis(2);
      margin-top: unit(8);
      font-size: unit(16);
      line-height: 24px;
      text-align: left;
      font-weight: 500;
      color: #ffffff;
    }

    .secondary {
      @include ellipsis(2);
      margin-top: unit(8);
      font-weight: 500;
      font-size: unit(18);
      line-height: unit(26);
      text-align: left;
      letter-spacing: -0.006em;
      color: #ffffff;
    }

    &--activated {
      // box-shadow: unit(2) unit(2) unit(20) rgba(0, 0, 0, 0.06), unit(2) unit(2) unit(10) rgba(0, 0, 0, 0.04);
      i {
        padding-left: unit(5);
      }
    }

    &--empty {
      margin: 0 unit(16);
    }

    position: relative;

    button {
      display: block;
      background-color: #2eddff;
      position: absolute;
      right: unit(16);
      bottom: unit(16);
      width: auto;
      height: unit(32);
      border-radius: unit(8);

      span {
        color: #1f1f1f;
        font-size: unit(11);
        font-weight: 600;
        padding: 0 unit(16);
      }
    }
  }

  .empty-pager {
    height: unit(4);
    background: #eaeeef;
    border-radius: unit(10);
    margin: unit(16) unit(16) unit(2) unit(16);
  }
}
</style>
