<template>
  <div id="store-delivery">
    <div
      v-if="loading"
      style="
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
      "
    >
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
    <div v-else class="page-body">
      <div id="reservation-form-step">
        <div class="container">
          <template>
            <div class="need-login" v-if="!$store.state.auth.gid">
              <p>{{ $__t("You need to login to make a reservation.") }}</p>
              <ui-solid-button size="small"
                ><router-link
                  :to="{
                    name: 'AuthLogin',
                    query: { redirect: $router.currentRoute.fullPath },
                  }"
                  >{{ $__t("login") }}</router-link
                ></ui-solid-button
              >
            </div>
            <div class="payment-step">
              <img src="/img/payment-step-indicator-3.png" />
            </div>
            <div class="form" v-if="$store.state.auth.gid">
              <ui-form ref="form">
                <div class="form-section information-section delivery-section">
                  <div class="form-section-title">
                    {{ $__t("{delivery-step3}.title") }}
                  </div>
                  <div class="list-body">
                    <div class="list-item ui-delivery-place-ticket">
                      <div class="container">
                        <div class="journey-arrow">
                          <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </div>
                        <div class="delivery-place-ticket">
                          <div class="container">
                            <div class="body">
                              <div class="title">
                                {{
                                  $__t(
                                    "{delivery-step3}.place_ticket.title.origin"
                                  )
                                }}
                              </div>
                              <div class="place-name">
                                {{
                                  data.product_data.sender_store_name ||
                                  data.product_data.sender_place ||
                                  ""
                                }}
                              </div>
                              <div class="place-button-area">
                                <button
                                  type="button"
                                  @click="
                                    searchOnGoogleMap(
                                      data.product_data.sender_address
                                    )
                                  "
                                >
                                  <img src="/img/icon-google-map.png" />
                                </button>
                                <button
                                  type="button"
                                  @click="
                                    searchOnNaverMap(
                                      data.product_data.sender_address
                                    )
                                  "
                                >
                                  <img src="/img/icon-naver-map.png" />
                                </button>
                              </div>
                            </div>
                            <div class="footer">
                              <div class="datetime-area">
                                <div class="date">
                                  {{
                                    parseDateTime(data.reserve_date_start).date
                                  }}
                                </div>
                                <div class="time">
                                  {{
                                    parseDateTime(data.reserve_date_start).time
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="delivery-place-ticket">
                          <div class="container">
                            <div class="body">
                              <div class="title">
                                {{
                                  $__t(
                                    "{delivery-step3}.place_ticket.title.destination"
                                  )
                                }}
                              </div>
                              <div class="place-name">
                                {{
                                  data.product_data.recipient_store_name ||
                                  data.product_data.recipient_place ||
                                  ""
                                }}
                              </div>
                              <div class="place-button-area">
                                <button
                                  type="button"
                                  @click="
                                    searchOnGoogleMap(
                                      data.product_data.recipient_address
                                    )
                                  "
                                >
                                  <img src="/img/icon-google-map.png" />
                                </button>
                                <button
                                  type="button"
                                  @click="
                                    searchOnNaverMap(
                                      data.product_data.recipient_address
                                    )
                                  "
                                >
                                  <img src="/img/icon-naver-map.png" />
                                </button>
                              </div>
                            </div>
                            <div class="footer">
                              <div class="datetime-area">
                                <div class="date">
                                  {{
                                    parseDateTime(data.reserve_date_end).date
                                  }}
                                </div>
                                <div class="time">
                                  {{
                                    parseDateTime(data.reserve_date_end).time
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-item shadow">
                      <div class="row">
                        <div class="key">
                          {{ $__t("No. of items") }}
                        </div>
                        <div class="value">
                          <span
                            class="wrapper"
                            v-if="data.product_data.quantity_small > 0"
                            ><img
                              src="/img/luggage-small.png"
                              class="luggage"
                            />
                            {{ data.product_data.quantity_small
                            }}{{
                              data.product_data.quantity_small > 1
                                ? $__t("items")
                                : $__t("item")
                            }}&nbsp;&nbsp;&nbsp;</span
                          >
                          <span
                            class="wrapper"
                            v-if="data.product_data.quantity_big > 0"
                            ><img src="/img/luggage-big.png" class="luggage" />
                            {{ data.product_data.quantity_big
                            }}{{
                              data.product_data.quantity_big > 1
                                ? $__t("items")
                                : $__t("item")
                            }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="key">
                          {{ $__t("{delivery-step3}.key.typeofitems") }}
                        </div>
                        <div class="value">
                          {{ data.product_data.luggage_name }}
                        </div>
                      </div>
                    </div>
                    <div class="list-item shadow">
                      <div class="row">
                        <div class="key">
                          {{ $__t("{delivery-step2}.keyword.sender") }}
                        </div>
                        <div class="value">
                          {{ data.product_data.sender_name }}
                        </div>
                      </div>
                      <div class="row direction-column">
                        <div class="row sub">
                          <div class="key font-size-small font-weight-normal">
                            {{ $__t("전화번호") }}
                          </div>
                          <div class="value text-decoration-underline">
                            <a
                              :href="`tel:${data.product_data.sender_phone}`"
                              >{{ data.product_data.sender_phone }}</a
                            >
                          </div>
                        </div>
                        <div class="row sub">
                          <div class="key font-size-small font-weight-normal">
                            {{ $__t("주소") }}
                          </div>
                          <div
                            class="value font-color-black font-weight-normal"
                          >
                            {{ data.product_data.sender_address }}
                            {{ data.product_data.sender_address_detail }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="key">
                          {{ $__t("{delivery-step2}.keyword.recipient") }}
                        </div>
                        <div class="value">
                          {{ data.product_data.recipient_name }}
                        </div>
                      </div>
                      <div class="row direction-column">
                        <div class="row sub">
                          <div class="key font-size-small font-weight-normal">
                            {{ $__t("전화번호") }}
                          </div>
                          <div class="value text-decoration-underline">
                            <a
                              :href="`tel:${data.product_data.recipient_phone}`"
                              >{{ data.product_data.recipient_phone }}</a
                            >
                          </div>
                        </div>
                        <div class="row sub">
                          <div class="key font-size-small font-weight-normal">
                            {{ $__t("주소") }}
                          </div>
                          <div
                            class="value font-color-black font-weight-normal"
                          >
                            {{ data.product_data.recipient_address }}
                            {{ data.product_data.recipient_address_detail }}
                          </div>
                        </div>
                        <div class="row medium">
                          <div class="key font-weight-little-bolder">
                            {{
                              $__t("{delivery-step3}.key.verifyticketpassword")
                            }}
                          </div>
                          <div class="value font-size-large">
                            {{ data.product_data.password }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-item">
                      <div class="row">
                        <div class="key">
                          {{ $__t("Estimated Payment") }}
                        </div>
                        <div class="value">
                          {{
                            data.predicted_amount.toLocaleString() + $__t("won")
                          }}
                        </div>
                      </div>
                      <div class="row direction-column">
                        <div class="row sub">
                          <div class="key">
                            {{ $__t("Order amount") }}
                          </div>
                          <div class="value">
                            {{
                              data.order_amount
                                ? data.order_amount.toLocaleString()
                                : data.predicted_amount.toLocaleString()
                            }}{{ $__t("won") }}
                          </div>
                        </div>
                        <div class="row sub">
                          <div class="key">
                            {{ $__t("할인 총액") }}
                          </div>
                          <div class="value">
                            {{ data.discounted_amount || "0" + $__t("won") }}
                          </div>
                        </div>
                        <div class="row sub">
                          <div class="key">
                            {{ $__t("Discount amount") }}
                          </div>
                          <div class="value">
                            {{ data.disctounted_amount || "0" + $__t("won") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="delivery-form-action submit-section">
                    <ui-solid-button
                      color="brightblue"
                      display="block"
                      @click="submitForm"
                      :disabled="isValidJourney ? false : true"
                    >
                      <button type="button">{{ $__t("Next") }}</button>
                    </ui-solid-button>
                  </div>
                </div>
              </ui-form>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";

export default {
  inheritAttrs: false,
  name: "DeliveryFormStep3",
  data() {
    return {
      params: "",
      data: "",
      loading: true,
    };
  },
  computed: {
    isValidJourney() {
      return true;
    },
  },
  async created() {
    this.$data.params = this.$route.params;

    try {
      await this.getReceipt(this.$route.query.reserve_id);
    } catch (e) {
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getReceipt(id) {
      try {
        let res = await this.$store.dispatch("orders/order", { id });

        this.$data.data = res;
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e),
          status: "error",
        });
      }
    },
    parseDateTime(datetime) {
      let value = this.$moment(datetime).format("YYYY-MM-DD_HH:mm");

      value = value.split("_");
      return {
        date: value[0],
        time: value[1],
      };
    },
    searchOnGoogleMap(address) {
      const addressEncoded = encodeURI(address);

      if (this.wni.isNative) {
        this.wni.execute("wnOpenURL", {
          url: `https://www.google.com/maps/search/?api=1&query=${addressEncoded}`,
        });
      } else {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${addressEncoded}`,
          "_blank"
        );
      }
    },
    searchOnNaverMap(address) {
      const addressEncoded = encodeURI(address);

      let url = `nmap://search?query=${address}&appname=${this.$store.state.config.siteURL}`;

      if (window && window.navigator && window.navigator.userAgent) {
        let userAgent = window.navigator.userAgent;
        let osType = "etc";
        if (userAgent.match(/iphone|ipad|ipod/i)) {
          osType = "ios";
        } else if (userAgent.match(/android/i)) {
          osType = "android";
        }

        if (this.wni.isNative) {
          if (osType == "ios") {
            this.wni.execute("wnOpenURL", { url });
          } else if (osType == "android") {
            url =
              url.replace("nmap://", "intent://") +
              "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
            this.wni.execute("wnOpenURL", { url });
          }
        } else {
          if (osType == "ios") {
            this.openNaverMapInIphoneBrowser(url);
          } else if (osType == "android") {
            url =
              url.replace("nmap://", "intent://") +
              "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
            location.href = url;
          } else {
            location.href = url;
          }
        }
      }
    },

    openNaverMapInIphoneBrowser(url) {
      this.$store.commit("loading/SET_TRUE");

      window.location.href = url;

      setTimeout(() => {
        try {
          if (window.location.href.indexOf("nmap") < 0) {
            console.log("NO_EXISTS_DATA #1");
            throw new Error("NO_EXISTS_DATA");
          }
        } catch (e) {
          let confirmed = confirm(
            this.$__t("Naver map is not installed, so go to the App Store.")
          );

          if (confirmed) {
            location.href = "http://itunes.apple.com/app/id311867728?mt=8";
          }
        } finally {
          this.$store.commit("loading/SET_FALSE");
        }
      }, 0);
    },

    submitForm() {
      this.$log.log("submitForm");

      this.$router.push({
        name: "StoreDeliveryStep4",
        query: this.$route.query,
      });
    },
  },
};
</script>
