<template>
  <div id="delivery-select">
    <div class="container">
      <ul>
        <li class="type" v-for="item in deliveryList.filter( item => !item.blocked )" :key="item.label">
          <div class="item" :class="{selected: selectedItem === item, disabled : item.disabled}" @click="item.disabled ? '' : $emit('select', item)">
            <div class="button">
              <img class="icon" :src="item.disabled ? item.invalidSrc : item.validSrc">
              <span class="label">{{ item.label + (item.disabled ? ` (${$__t('{delivery-select}.item.preparing')})` : '')}}</span>
              <img 
                :src="`/img/icon-question-${selectedItem === item ? 'blue' : 'gray'}.png`" 
                class="right" 
                @click.stop="handleClickQuestionIcon(item)"
                :style="{cursor: item.disabled ? 'default' : ''}"
                />
            </div>
            <div class="textarea">
              {{ item.text }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deliveryList: [
        {
          key: 'airport',
          validSrc : '/img/icon-delivery-airport.png',
          invalidSrc: '/img/icon-delivery-airport-invalid.png',
          label : this.$__t('{delivery-select}.type.airport'),
          text : '',
          blocked: true
        },
        { 
          key: 'quick',
          validSrc : '/img/icon-delivery-quick.png',
          invalidSrc: '/img/icon-delivery-quick-invalid.png',
          label : this.$__t('{delivery-select}.type.quick'),
          text : ''
        },
        {
          key: 'normal',
          validSrc : '/img/icon-delivery-normal.png',
          invalidSrc: '/img/icon-delivery-normal-invalid.png',
          label : this.$__t('{delivery-select}.type.normal'),
          text : '',
          disabled: true
        },
        {
          key: 'large',
          validSrc : '/img/icon-delivery-large.png',
          invalidSrc: '/img/icon-delivery-large-invalid.png',
          label : this.$__t('{delivery-select}.type.large'),
          text : '',
          blocked: true
        },
      ],
      selectedItem: ''
    }
  },
  computed: {

  },
  methods: {
    handleClickQuestionIcon( item ){
      if(item.disabled){
        return;
      }

      if(this.selectedItem === item){
        this.selectedItem = ''
      }else{
        this.selectedItem = item;
      }
    }
  }
}
</script>
