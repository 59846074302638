<template>
  <div class="resource profile">
    <router-link :to="profileRoute">
      <span class="profile__text">
        <template v-if="isLoggedIn">
          {{ profile.user_name }}
        </template>
        <template v-else>
          {{ $__t('로그인') }}
        </template>
      </span>
    </router-link>

    <modal id="login-modal" ref="login-modal" submitButtonLabel="전화번호 인증하기" @submit="onSubmit">
      <template v-slot:body>
        <div class="text">
          <div class="title">로그인 방식 변경</div>
          <div class="context">
            럭스테이 로그인이<br />
            <span class="strong">전화번호 로그인으로 변경</span>되었습니다.
            <br />
            <br />
            기존 고객님의 경우 계정 동기화를 위해<br />
            <span class="strong">전화번호 인증</span>을 진행해 주세요 :)
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <button>전화번호 인증하기</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.gid > 0;
    },

    profileRoute() {
      if (this.isLoggedIn) {
        return { name: 'Mypage' };
      }

      return { name: 'AuthLogin' };
    },

    profile() {
      return this.$store.state.auth;
    }
  },
  methods: {
    async onSubmit() {
      this.$refs['login-modal'].close();
      await onClickGoLoginPage();
    },

    async moveToLogin() {
      await this.$store.dispatch('auth/redirect', {
        redirect: '/login/existingusers'
      });
    }
  },
  async mounted() {
    let res = await this.$store.dispatch('auth/authPhoneCheck');
    if (res.is_necessary === true) {
      this.$refs['login-modal'].open();
    }
  }
};
</script>

<style lang="scss">
.profile {
  flex: 0 1 unit(128);
  @include ellipsis(1);

  &__text {
    font-weight: bold;
    font-size: unit(18);
    letter-spacing: -0.006em;
    text-decoration: underline;
  }
}
</style>
