<template>
  <ul class="resource toolbar">
    <li class="toolbar-item">
      <router-link :to="{ name: 'Cs' }">
        <object :data="require('@/assets/icons/svg/help.svg')" />
      </router-link>
    </li>
    <li class="toolbar-item">
      <router-link :to="{ name: 'CsNotices' }">
        <object :data="require('@/assets/icons/svg/notice.svg')" />
      </router-link>
    </li>
    <li class="toolbar-item">
      <button type="button" @click="onClickLanguage">
        <object :data="languageIcon" />
      </button>
    </li>

    <portal to="destination">
      <ui-slider
        id="language-selector-slider"
        ref="language-selector-slider"
        :bgClick="true"
        :background="true"
      >
        <LanguageSelector />
      </ui-slider>
    </portal>
  </ul>
</template>

<script>
import UiSlider from "@/components/modules/Slider/Body.vue";
import LanguageSelector from "@/components/resources/language/LanguageSelector.vue";

export default {
  components: {
    UiSlider,
    LanguageSelector,
  },
  computed: {
    languageIcon() {
      let icon = null;

      switch (this.$store.state.lang) {
        case "ko":
          icon = require("@/assets/icons/svg/flag-korea.svg");
          break;
        // case 'en':
        case "base":
          icon = require("@/assets/icons/svg/flag-us.svg");
          break;
        // case 'cn':
        //   icon = require('@/assets/icons/svg/flag-china.svg');
        //   break;
        // case 'hk':
        //   icon = require('@/assets/icons/svg/flag-china.svg');
        //   break;
        // case 'jp':
        //   icon = require('@/assets/icons/svg/flag-japan.svg');
        //   break;
        // case 'vn':
        //   icon = require('@/assets/icons/svg/flag-vietnam.svg');
        //   break;
        default:
          icon = require("@/assets/icons/svg/flag-korea.svg");
          break;
      }

      return icon;
    },
  },
  methods: {
    onClickLanguage() {
      const ref = this.$refs["language-selector-slider"];
      // console.log( "ref", ref );

      if (ref) {
        ref.open();
      }
    },
  },
};
</script>

<style lang="scss">
.resource.toolbar {
  flex: 0;
  white-space: nowrap;
  text-align: right;

  .toolbar-item {
    display: inline-block;
    width: unit(30);
    height: unit(30);

    & + .toolbar-item {
      margin-left: unit(16);
    }

    a {
      @include fsa;
    }

    object {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    button {
      cursor: pointer;
    }
  }
}
</style>
