<template>
  <div id="root">
    <app-under-construct v-if="isServerDown" />

    <div id="app" v-else>
      <keep-alive v-if="loaded">
        <router-view />
      </keep-alive>

      <portal-target name="destination"></portal-target>

      <div v-if="!loaded">
        <div class="loader">
          <i class="fa fa-spinner fa-pulse"></i>
        </div>
      </div>

      <guide-modal id="consentTermsOfUseModal" ref="consentTermsOfUseModal" :noBgClick="true" :noCloseButton="true" name="consentTermsOfUseModal">
        <template v-slot:body>
          <terms-of-use-form @submit="closeModal('consentTermsOfUseModal')" />
        </template>
      </guide-modal>

      <div v-if="$store.state.developerOption" id="log-frame">
        <ul>
          <li v-for="(item, index) in $store.getters['log/GET_ITEMS']" :key="index + ' ' + item" v-html="item" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import { WNInterface } from 'vue-wni';
import AppUnderConstruct from '@/v3/pages/app/AppUnderConstruct.vue';

export default {
  computed: {
    isServerDown() {
      return this.$store.state.serverAvailable === false;
    },
    loaded() {
      return this.sessionReady;
    },
    topLayerPopup() {
      return this.$store.getters['popup/GET_LAST_ITEM']();
    }
  },
  methods: {
    loadDeviceInfo() {
      return new Promise((resolve, reject) => {
        try {
          if (this.wni.isNative) {
            WNInterface.execute('wnAppInfo', {
              callback: WNInterface.cb((e) => {
                this.$analytics.setDeviceId(e.deviceId);
                resolve();
              })
            });
          } else {
            resolve();
          }
        } catch (e) {
          this.$log.error(e);
          reject(e);
        }
      });
    },
    onAppInit() {
      this.debug('onAppInit');
      if (this.wni.isNative) {
        this.wni.execute('wnPushBadgeNumber', { number: 0 });
        this.$analytics.logEvent('app session start');
      }
      this.$store
        .dispatch('app/device', {})
        .then((data) => {
          this.onStartSession();
        })
        .catch((e) => {
          this.debug(e.message);
          this.onStartSession();
        });
    },
    onStartSession() {
      this.debug('onStartSession');
      this.onRefreshSession(() => {
        this.onLoadedSession();
        if (this.wni.isNative === true) {
          this.onPushRegister(({ error }) => {
            if (error) {
              this.onError(error);
            }
          });
        }
      });
    },
    onRefreshSession(callback) {
      this.debug('onRefreshSession');
      this.$store
        .dispatch('auth/refresh')
        .then((res) => {
          if (res.data) {
            let { data, error } = res.data;
            this.$analytics.setUserId(data.profile.gid);
            if (!error) {
              if (data.profile.agreed !== true) {
                this.openModal('consentTermsOfUseModal');
              }
            }
          }
          if (this.$store.state.auth.host_id > 0) {
            this.$store.dispatch('host/refresh').then(() => {
              callback();
            });
          } else {
            callback();
          }
        })
        .catch((e) => {
          this.onError(e);
          callback();
        });
    },
    onLoadedSession() {
      this.debug('onLoadedSession');
      this.$data.sessionReady = true;
      this.checkReferrer();
    },
    onPushRegister(callback) {
      this.debug('onPushRegister');
      this.wni.onRemoteNotification((info) => {
        this.debug('onRemoteNotification', info);
      });
      this.wni.execute('wnPushRegister', {
        callback: this.wni.cb(async (result) => {
          this.debug('result', result);
          let { status, push_token } = result;
          if (status && status.toLowerCase() === 'granted') {
            this.wni.execute('wnAppInfo', {
              callback: this.wni.cb((result) => {
                this.$store
                  .dispatch('app/pushRegister', {
                    push_token,
                    device_info: result
                  })
                  .then((data) => {
                    callback({ status });
                  });
              })
            });
          }
        })
      });
    },
    handleIezHome() {
      const view_type = localStorage.getItem('__LUGSTAY__VIEW__TYPE__');
      if (view_type === 'iez') {
        this.$router.replace({ name: 'Iez' });
      }
    },
    // loadGoogleMap() {
    //   //googleMapLocale
    //   // ko: 'ko',
    //   // en: 'en',
    //   // cn: 'zh-CN',
    //   // hk: 'zh-HK',
    //   // jp: 'ja',
    //   // vn: 'vi'
    //   const { Loader } = require("google-maps");
    //   let googleMapLocale = this.$store.state.lang;
    //   if (googleMapLocale === "cn") googleMapLocale = "zh-CN";
    //   else if (googleMapLocale === "hk") googleMapLocale = "zh-HK";
    //   else if (googleMapLocale === "jp") googleMapLocale = "ja";
    //   else if (googleMapLocale === "vn") googleMapLocale = "vi";
    //   const loader = new Loader(config.get("googleMapsApiKey"), {
    //     libraries: ["places", "geometry"],
    //     version: "weekly",
    //     language: googleMapLocale,
    //   });
    //   loader
    //     .load()
    //     .then((google) => {
    //       this.onLoadedGoogleMap();
    //     })
    //     .catch((err) => this.$log.log(err));
    // },
    onLoadedGoogleMap() {
      this.debug('onLoadedGoogleMap');
      this.$data.mapReady = true;
    },
    onDynamicLink() {
      this.wni.onLink((e) => {
        this.$log.log({ tag: 'onDynamicLink', message: JSON.stringify(e || {}) });
        if (!e) {
          return;
        }
        try {
          this.$store.commit('loading/SET_TRUE');
          let { deeplink, url } = e;
          let urlObject = new URL(url);
          let { pathname, search } = urlObject;
          if (deeplink === 'https://link.lugstay.com/lugstay-3710' || deeplink === 'https://link.lugstay.com/lugstay-3712' || deeplink === 'https://link.lugstay.com/lugstay-3713') {
            // 
            this.wni.execute("wnOpenURL", {
              url: url
            });
            return;
          }
          return setTimeout(() => {
            return this.$router.push(pathname + search);
          }, 1000);
        } catch (e) {
          this.$log.error(e);
        } finally {
          setTimeout(() => this.$store.commit('loading/SET_FALSE'), 1000);
        }
      });
    },
    onCustom() {
      this.wni.onCustom((e) => {
        let { type } = e || {};
        if (type === 'remote_config') {
          this.onRemoteConfig(e);
        }
      });
    },
    // onBack: _.debounce(function() {
    //   this.wni.onBack(() => {
    //     this.$log.log('onBack!!!!!!!')
    //     if(!this.topLayerPopup) {
    //       return this.$router.back();
    //     }
    //   })
    // }, 100),
    onBack() {
      this.wni.onBack(() => {
        if (this.topLayerPopup) {
          return this.topLayerPopup.context.close();
        }
        if (this.$route.name === 'Home') {
          return (this.clickFinishAppCount += 1);
        }
        return this.$router.back();
      });
    },
    onRemoteConfig(json = {}) {
      this.onTesterGroup(json['ab_tester_group']);
    },
    onTesterGroup(group) {
      if (group === 'default') {
        return;
      }
      if (group === 'test_target') {
        this.$store.commit('SET_TRUE', { key: 'test_target' });
      }
    },
    onError(e) {
      this.debug('onError : ' + e.message);
      this.$store.commit('alert/ADD_ITEM', {
        message: e.message,
        status: 'error'
      });
    },
    checkReferrer() {
      const referrer = document.referrer;
      const examples = ['naver', 'facebook', 'google'];
      let matchedExample = examples.find((example) => referrer.indexOf(example) > -1);
      matchedExample = matchedExample ? matchedExample : 'direct';
      if (!this.wni.isNative) {
        this.$analytics.logEvent('web session start', {
          referrer: matchedExample
        });
      }
    },
    pcAllowed(to) {
      let { allowPc } = to.meta || {};
      if (this.$store.state.isPreview) {
        return true;
      }
      if (!this.$store.state.config.isTouchDevice && allowPc !== true) {
        return false;
      }
      return true;
    },
    openModal(ref) {
      this.$refs[ref].open();
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },
    async onCode() {
      const code = this.$route?.query?.reward_code;
      if (code) {
        this.$store.dispatch('userData/set', { campaignCode: code });
        await this.axios.post(
          this.$store.state.config.apiURL + '/v2/campaigns/invite/' + code,
          {},
          { headers: this.$store.state.config.userHeaders }
        );
        return this.axios.get(this.$store.state.config.apiURL + '/v2/campaigns/invite/' + code).then(({ data }) => {
          let campaign_code = data?.data?.campaign?.campaign_code || '';
          let promotion = data?.data?.promotion;
          this.$store.dispatch('userData/set', { campaignCode: campaign_code, promotion });
        });
      }
    }
  },
  data() {
    return {
      sessionReady: true,
      isSetLanguage: localStorage.getItem('lugstay_language'),
      clickFinishAppCount: 0
    };
  },
  created() {
    if (window.Kakao && !window.Kakao?.__initialized) {
      window.Kakao.init(config.kakaoAppKey);
      window.Kakao.__initialized = true;
    }
    window.fbAsyncInit = function () {
      FB.init({
        appId: config.facebookApiAppId,
        autoLogAppEvents: true,
        xfbml: true,
        cookie: true,
        version: 'v12.0',
        redirect_uri: config.siteURL + '/auth/facebook/callback'
      });
      FB.AppEvents.logPageView();
    };
    // this.loadGoogleMap();
    this.$analytics.startSession();
    this.$analytics.setVersion(config.version);
    if (this.wni.isNative) {
      this.wni.onReady((e) => {
        this.onAppInit();
      });
    } else {
      this.onAppInit();
    }
    setTimeout(async () => {
      await this.loadDeviceInfo();
    }, 0);
  },
  mounted() {
    if (this.wni.isNative) {
      this.onDynamicLink();
      this.onCustom();
      if (this.wni.isAndroid) {
        this.onBack();
        this.$watch('clickFinishAppCount', (count) => {
          if (count >= 2) {
            this.wni.execute('wnAppClose');
          }
        });
      }
    }
    this.onCode();
  },
  components: {
    AppUnderConstruct
  }
};
</script>

<style scoped lang="scss">

.loader {
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}

#log-frame {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
  z-index: 10000;

  * {
    font-size: 0.825rem;
    line-height: 1.5;
    color: #292a2b;
    pointer-events: none;
  }
}
</style>
