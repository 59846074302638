<template>
  <div class="page" id="host-page">
    <div class="container">
      <div v-if="loading" class="loading">
        <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
      </div>
      <template v-else>
        <div class="page-header">
          <div class="btn-container">
            <button type="button" class="page-header__nav-button" @click="$router.back()">
              <img src="/img/icon/header-back.svg" />
            </button>
          </div>
          <div class="btn-container btn-container--grow">
            <button v-if="$route.name === 'Host'" type="button" class="page-header__logo-button">
              <img src="/img/logotype.svg" />
            </button>
            <h1 v-else class="header-text">
              <span class="">{{ $__t('호스트 페이지') }}</span>
            </h1>
          </div>
          <div class="btn-container">
            <button
              v-if="$route.name !== 'HostProfile'"
              type="button"
              class="page-header__nav-button"
              @click="$router.push({ name: 'HostProfile' }).catch(() => {})"
            >
              <i class="far fa-user-circle"></i>
            </button>
          </div>
        </div>
        <div class="page-body">
          <!--홈에서 '상점 관리하기'클릭 시, QR코드와 함께 나오는 화면-->
          <template v-if="$route.name === 'Host'">
            <div class="qr-selector" v-if="activatedStore">
              <div class="qr-selector__image-frame">
                <p class="qr-selector__text">{{ activatedStore.store_name }}</p>
                <qr-code v-if="activatedStore" :code="getRedirectCode(activatedStore)" :options="{ download: true }" />
                <p class="qr-selector__text">상점 번호 : {{ activatedStore.id }}</p>
              </div>
              <div class="qr-selector__select-container">
                <select id="qr-select" v-model="activatedStore" class="qr-selector__select">
                  <option disabled value="">상점을 선택해주세요</option>
                  <option v-for="(store, idx) in stores" :key="'store-' + idx" :value="store">
                    {{ storeName(store) }}
                  </option>
                </select>
                <i class="fa fa-angle-down"></i>
              </div>
            </div>
            <div class="nav-link">
              <router-link :to="{ name: 'HostTickets' }">
                <div class="container">
                  <div class="image">
                    <img src="/img/host-menu-icon-02.png" />
                    <badge type="circle" bg-color="#ffab24" v-if="tickets.length">{{ tickets.length }}</badge>
                  </div>
                  <div class="text">
                    <div class="title">공간 및 예약관리</div>
                    <div class="description">호스트님의 공간과 고객의 예약을 관리하실 수 있습니다.</div>
                  </div>
                  <div class="icon">
                    <i class="fa fa-angle-right"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </template>
          <!--'공간 및 예약관리'클릭 시 화면-->
          <template>
            <div v-if="$route.meta.subpageHeaderVisible" class="subpage-header">
              <ul class="subpage-list">
                <router-link :to="{ name: 'HostTickets' }" replace
                  ><li :class="{ on: $route.name.indexOf('HostTicket') == 0 }">
                    {{ $__t('보관 현황') }}
                  </li></router-link
                >
                <router-link :to="{ name: 'HostStores' }" replace
                  ><li :class="{ on: $route.name.indexOf('HostStore') == 0 }">
                    {{ $__t('나의 상점') }}
                  </li></router-link
                >
                <router-link :to="{ name: 'HostNotices' }" replace
                  ><li :class="{ on: $route.name.indexOf('HostNotice') == 0 }">
                    {{ $__t('공지사항') }}
                  </li></router-link
                >
                <router-link :to="{ name: 'HostSettlements' }" replace
                  ><li :class="{ on: $route.name.indexOf('HostSettlements') == 0 }">
                    {{ $__t('Settlement Information') }}
                  </li></router-link
                >
              </ul>
            </div>
            <router-view />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/modules/Badge/Badge';
import QrCode from '@/components/includes/QrCode';
import Lottie from 'vue-lottie';
import LottieLoading from '@/assets/lottie/loading-primary.json';
import config from '@/config';

export default {
  data() {
    return {
      paging: {
        page: 1,
        size: 10
      },
      activatedStore: null,

      loading: true,

      lottieOptions: {
        animationData: LottieLoading
      }
    };
  },

  computed: {
    stores() {
      return this.$store.getters['stores/GET_ITEMS_BY_LIST']({
        list: 'MyStores'
      });
    },

    tickets() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'HostTickets'
      });
    }
  },

  async mounted() {
    this.$analytics.logEvent('host landing page view');

    this.wni.execute('wnAppChangeStatusBar', {
      'status-bar-style': 'dark',
      'background-color': '#ffffff'
    });

    await Promise.all([this.getTicketList(), this.getStoreList()]);
    this.activatedStore = this.stores[0];
    // console.log('this.activatedStore', this.activatedStore);
    this.loading = false;
  },

  methods: {
    getStoreList() {
      return this.$store.dispatch('stores/getListForHost', {
        query: this.paging,
        list: 'MyStores'
      });
    },

    getTicketList() {
      return this.$store.dispatch('tickets/getListForHost', {
        query: this.paging,
        list: 'HostTickets',
        filter: 'available'
      });
    },

    storeName(store) {
      try {
        if (typeof store.store_name === 'object') {
          return store.store_name[this.$store.state.lang] || store.store_name['base'] || store.store_name['ko'];
        }
        return store.store_name;
      } catch (e) {
        (7)[u];
        return store.store_name;
      }
    },

    getRedirectCode({ id }) {
      // http://localhost:8080/r/https%3A%2F%2Flink.staging.lugstay.com%2F%3Flink%3Dhttps%3A%2F%2Fstaging.lugstay.com%2Fr%2FS53&apn=com.lugstay.app&ibi=com.lugstay.app
      try {
        const SITE_URL = config.get('siteURL');
        const APP_URL = config.get('appURL');
        const APN = config.get('androidPackageName');
        const IBI = config.get('iosBundleId');
        const code = `${SITE_URL}/r/${encodeURIComponent(`${APP_URL}/?link=${SITE_URL}/r/S${id}&apn=${APN}&ibi=${IBI}`)}`;

        return `${SITE_URL}/r/${encodeURIComponent(`${APP_URL}/?link=${SITE_URL}/r/S${id}&apn=${APN}&ibi=${IBI}`)}`;
      } catch (e) {
        console.error(e);
      }
    }
  },

  components: {
    Badge,
    QrCode,
    Lottie
  }
};
</script>

<style scoped lang="scss">
#host-page {
  @include container('& > .container', $site-width-f);

  & > .container {
    @include fill-viewport-min-height;
    background-color: $color-white;

    .loading {
      padding: unit(30) 0;
    }

    .page-header {
      position: sticky;
      top: 0;
      padding: 0;
      height: unit(56);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: $color-white;
      z-index: 1;

      .btn-container {
        width: unit(56);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--grow {
          flex: 1 0 unit(56);
        }

        .header-text {
          font-size: unit(14);
          text-align: center;
          font-weight: 600;
        }
      }

      &__nav-button {
        padding: unit(16);
        float: none;
        padding: 0;
        display: inline-flex;
        align-items: center;
        position: absolute;
        width: unit(56);
        font-size: 1.75 * $unit;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        cursor: pointer;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        * {
          flex: 1;
        }
      }

      &__logo-button {
        height: 100%;
        padding: unit(6) unit(16);

        img {
          width: unit(120);
          height: 100%;
          object-fit: contain;
          margin: auto;
        }
      }
    }

    .page-body {
      .subpage-header {
        padding: unit(20) unit(20);
        background: #008fff;
        text-align: center;
        position: sticky;
        top: unit(56);
        z-index: 1;

        &__title {
          font-size: unit(24);
          font-weight: bold;
          color: $color-white;
        }

        .subpage-list {
          border-radius: unit(24);
          display: flex;
          justify-content: center;
          background: $color-white;

          li {
            display: inline-block;
            margin: 0 unit(8);
            font-weight: 500;
            font-size: unit(14);
            color: #a9afb3;

            &.on {
              color: #008fff;
            }
          }
        }
      }
      .qr-selector {
        margin: unit(30) 0;
        padding: unit(36) unit(60) unit(12) unit(60);
        width: 100%;

        &::before {
          content: '';
          width: 100%;
          padding-bottom: 100%;
        }

        &__text {
          margin: unit(12) 0;
          font-size: unit(14);
          line-height: 1.5;
          color: #a9afb3;
          text-align: center;

          strong {
            font-weight: bold;
          }
        }

        &__select-container {
          padding: unit(12);

          i {
            position: absolute;
            top: 50%;
            left: calc(50% + #{unit(75)} - #{unit(24)});
            transform: translate(0, -50%);
            color: $color-black;
          }
        }

        &__select {
          width: unit(150);
          font-size: unit(14);
          color: $color-black;
          border-radius: unit(10);
          padding: unit(3) unit(6);
          padding-right: unit(36);
          margin: auto;
          @include ellipsis(1);
          @include shadow-box;
        }

        &__image-frame {
          max-width: $site-width-i;
          margin: auto;
          padding: unit(8) unit(48) unit(8) unit(48);
          border-top-left-radius: unit(14);
          border-top-right-radius: unit(14);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16), 4px 8px 28px rgba(0, 0, 0, 0.08);
        }
      }

      .nav-link {
        .container {
          display: flex;
          padding: unit(16);
          width: calc(100% - #{unit(32)});
          left: unit(16);
          @include shadow-box;

          .image {
            flex: 0 0 3.5 * $unit;
            text-align: center;

            img {
              display: inline-block;
              height: 3.5 * $unit;
              vertical-align: bottom;
            }

            .ui-badge {
              position: absolute;
              top: 0;
            }

            .badge {
              position: absolute;
              left: 50%;
              margin-left: 0.5 * $unit;
            }
          }

          .text {
            flex: 1;
            padding-left: unit(18);

            .title {
              color: #008fff;
              font-weight: 500;
              font-size: unit(14);
            }

            .description {
              word-wrap: break-word;
              word-break: keep-all;
              line-height: 1.75;
              font-size: $font-size-normal;
              font-weight: 500;
            }
          }

          .icon {
            flex: 0;
            display: flex;
            align-items: center;
            font-size: unit(24);
            text-align: center;
            -webkit-text-stroke: unit(2) $color-white;

            i {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
