<template>
    <div id="promotion-home">
        <div class="container">
            <div class="promotion-welcome-box">
                <img src="/img/img-speaker.png" class="speaker"/>
                <div class="title">
                    {{$__t("Promote for us")}}
                </div>
                <div class="sub-title">
                    {{$__t("Be the LugStay Promotion Ambassador and make money !")}}
                </div>
                <div class="description">
                    {{$__t("Share exclusive links, earn cash rewards by introducing users or recommend merchant to join LugStay !")}}
                </div>
                <div class="button-area">
                    <ui-solid-button @click="goToContents"><span>Start</span></ui-solid-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        // props: [ "code" ],
        data(){
            return {
            };
        },
        computed: {
        },
        created(){
        },
        methods: {
            goToContents() {
                if( this.$store.state.auth.gid ){
                    this.$router.push({ name: "PromotionContents" });
                }else{
                    this.$router.push({ name: "AuthLogin", query: { redirect: this.$router.currentRoute.fullPath } });
                }
            }
        }
    }
</script>
