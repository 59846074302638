<template>
	<div id="coupon-selector-form">
		<span class="key-coupon"
			>{{ $__t('쿠폰') }}
			<!--            <img src="/img/icon-question-circle.png">-->
		</span>
		<span class="value-coupon">
			<div class="coupon-counts">
				<div class="coupon-count">
					{{ $__t('available coupons') }} <span class="num">{{ couponAvailableCount }}</span>
				</div>
				<div class="coupon-count">
					{{ $__t('coupons you have') }} <span class="num">{{ couponAvailableCount }}</span>
				</div>
			</div>
			<div class="coupon-select-button">
				<ui-solid-button @click="showCouponSelector"
					><span>{{ $__t('select a coupon') }}</span></ui-solid-button
				>
				<div id="coupon-selector" class="select-modal" v-if="isShowCouponSelector">
					<div class="selector-body">
						<div class="label">{{ $__t('select a discount coupon') | firstLetterToUpperCase }}<i class="fa fa-times" @click="closeCouponSelector"></i></div>
						<div class="options">
							<div class="option" v-for="(coupon, i) in coupons" @click="selectCoupon(coupon)">
								<div class="radio-button" :class="selectedCoupon && coupon.uid == selectedCoupon.uid ? 'active' : ''"></div>
								<div class="item-title">{{ coupon.coupon_name }}</div>
								<div class="item-content" v-if="coupon.promotion_config.type == 'rate'">
                  {{ coupon.promotion_config.rate_amount }}% {{ $__t('{coupon-selector}할인.1') | firstLetterToUpperCase}}
                </div>
								<div class="item-content" v-else>
                  {{ coupon.promotion_config.fix_amount }}{{$__t('won')}} {{ $__t('{coupon-selector}할인.1') | firstLetterToUpperCase}}
                </div>
								<div class="item-exp-date">{{ $__t("Expiration Date") }} {{ coupon.coupon_date_due_no_expiration ? $__t('none') : $moment(coupon.coupon_date_due).format('YYYY.MM.DD') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</span>
		<div class="selected-coupon" v-if="selectedCoupon">[{{ $__t('selected') }}] {{ selectedCoupon.coupon_name }} <i class="fa fa-times" @click="removeSelectedCoupon"></i></div>
    <slot/>
	</div>
</template>

<script>
export default {
	components: {},
	props: [],
	data() {
		return {
			isShowCouponSelector: false,
			selectedCoupon: null,
			couponAvailableCount: 0,
			listQuery: {
				page: 1,
				size: 50,
				group: 'available',
			},
		};
	},
	watch: {},
	computed: {
		coupons() {
			return this.$store.getters['resourceCoupons/GET_ITEMS_BY_LIST']({ list: 'MyCoupons' });
		},
		clickedFrom() {
			if (this.$route.name === 'StoreSingle' || this.$route.name === 'StoreReserveFormStep1') return true;
			else return null;
		},
	},
	methods: {
		showCouponSelector() {
			if (this.clickedFrom) this.$analytics.tracking.call(this, 'booking_1', 'click', 'booking_1_coupon_select');

			this.$store
				.dispatch('resourceCoupons/getList', { list: 'MyCoupons', query: this.listQuery, clear: true })
				.then((res) => {
					this.couponAvailableCount = res.data.data.total;
					if (!this.coupons || this.coupons.length == 0) {
						alert(this.$__t('No coupons available'));
						return;
					}

					this.isShowCouponSelector = true;
				})
				.catch((error) => {
					alert(this.$__t('No coupons available'));
				});
		},
		closeCouponSelector() {
			this.isShowCouponSelector = false;
		},
		selectCoupon(coupon) {
      this.selectedCoupon = coupon;
      this.closeCouponSelector();
      this.$emit('selectCoupon', coupon);
		},
		removeSelectedCoupon() {
      this.selectedCoupon = null;
      this.$emit('removeSelectedCoupon');
		},
		setCouponCountOf() {
			this.$store.dispatch('resourceCoupons/getList', { list: 'MyCoupons', query: this.listQuery, clear: true }).then((res) => {
				this.couponAvailableCount = res.data.data.total;
			});
		},
	},
	async created() {
		this.setCouponCountOf();
	},
};
</script>
