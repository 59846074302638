<template>
  <div id="Condition-page" class="search-condition">
    <div class="container">
      <div class="header">
        <div class="title">{{ $__t('이용시간 설정') }}</div>
        <!-- <div class="primary">
          {{ headerTextPrimary }}
        </div> -->
        <!-- <div class="secondary">
          {{ headerTextSecondary }}
        </div> -->
      </div>
      <div class="body">
        <ul>
          <!--맡길 시간-->
          <li :class="{ activated: activatedListItem === 'date_start' }" ref="date_start">
            <div class="tab" @click="onClickListItem('date_start')">
              <div class="key">
                {{ $__t('{store-search}.date_start') }}
              </div>
              <div class="value">
                <button type="button">
                  <template v-if="dateStartErrorString">
                    <span style="color: red; font-weight: bold">{{ dateStartErrorString }}</span>
                  </template>
                  <template v-else>
                    {{ formData.date_start.label }}
                    {{ $moment(formData.date_start.value).format('HH:mm') }}
                  </template>
                  <i class="fa fa-angle-down"></i>
                </button>
              </div>
            </div>
            <div class="transitioned-item">
              <div class="container">
                <date-time-picker
                  id="start-date-time-picker"
                  :value="formData.date_start.value"
                  :minDate="minStartDate"
                  @select="onSelect('date_start', $event)"
                />
              </div>
            </div>
          </li>
          <!-- 찾는 시간 -->
          <li :class="{ activated: activatedListItem === 'date_end' }" ref="date_end">
            <div class="tab" @click="onClickListItem('date_end')">
              <div class="key">
                {{ $__t('{store-search}.date_end') }}
              </div>
              <div class="value">
                <button type="button">
                  <template v-if="dateEndErrorString">
                    <span style="color: red; font-weight: bold">{{ dateEndErrorString }}</span>
                  </template>
                  <template v-else>
                    {{ formData.date_end.label }}
                    {{ $moment(formData.date_end.value).format('HH:mm') }}
                  </template>
                  <i class="fa fa-angle-down"></i>
                </button>
              </div>
            </div>
            <div class="transitioned-item">
              <div class="container">
                <date-time-picker
                  id="end-date-time-picker"
                  :value="formData.date_end.value"
                  :minDate="minEndDate"
                  @select="onSelect('date_end', $event)"
                />
              </div>
            </div>
          </li>
          <!-- 물품 개수 -->
          <li
            :class="{
              activated: activatedListItem === 'item_count',
              readonly: $attrs.id === 'ticket-edit'
            }"
            ref="item_count"
          >
            <div class="tab" @click="onClickListItem('item_count')">
              <div class="key">
                {{ $__t('물품 개수') }}
              </div>
              <div class="value">
                <button type="button">
                  <template v-if="quantityErrorString">
                    <span style="color: red; font-weight: bold">{{ quantityErrorString }}</span>
                  </template>
                  <template v-else>
                    {{ formData.quantity_small + formData.quantity_big }}
                    {{ formData.quantity_small + formData.quantity_big > 1 ? $__t('items') : $__t('item') }}
                  </template>
                  <i class="fa fa-angle-down"></i>
                </button>
              </div>
              <div class="text-button-area">
                <button
                  type="button"
                  @click.stop="
                    $analytics.logEvent('time setting price guide');
                    openRef('priceLuggageModal');
                  "
                >
                  <img src="/img/icon-question-mark.svg" />
                  <span class="text">{{ $__t('Types of items and Price') }}</span>
                </button>
              </div>
            </div>
            <div class="transitioned-item">
              <div class="container luggage">
                <ul>
                  <!-- <li class="small">
                    <div class="key">
                      <span class="text">{{ $__t('{luggage-selector}.small') }}</span>
                    </div>
                    <div class="value">
                      <button type="button" id="button-quantity-small-decrement" @click.stop="subQuantity('quantity_small', $event)">
                        <i class="fas fa-minus"></i>
                      </button>
                      <input type="tel" :value="formData.quantity_small" @input="onInputQuantity('quantity_small', $event)" />
                      <button type="button" id="button-quantity-small-increment" @click.stop="addQuantity('quantity_small', $event)">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </li>
                  <li class="large">
                    <div class="key">
                      <span class="text">{{ $__t('{luggage-selector}.big') }}</span>
                    </div>
                    <div class="value">
                      <button type="button" id="button-quantity-big-decrement" @click.stop="subQuantity('quantity_big', $event)">
                        <i class="fas fa-minus"></i>
                      </button>
                      <input type="tel" :value="formData.quantity_big" @input="onInputQuantity('quantity_big', $event)" />
                      <button type="button" id="button-quantity-big-increment" @click.stop="addQuantity('quantity_big', $event)">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </li> -->
                  <li class="small">
                    <div class="value">
                      <button type="button" id="button-quantity-small-decrement" @click.stop="subQuantity('quantity_small', $event)">
                        <i class="fas fa-minus"></i>
                      </button>
                      <input type="tel" :value="formData.quantity_small" @input="onInputQuantity('quantity_small', $event)" />
                      <button type="button" id="button-quantity-small-increment" @click.stop="addQuantity('quantity_small', $event)">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer_and_message">
        <!-- <div v-if="show" class="message">
          <div class="text-area">위 시간은 사용 예상시간이에요. <br />실제 이용 시간만큼 결제되니 안심하고 사용하세요 :)</div>
          <button
            class="remove-button"
            v-ga="$analytics.tracking.bind(this, 'search_condition', 'click', 'close_timesetting_tipbox')"
            @click="messageShow"
          >
            <img src="/img/x-button.svg" alt="closebutton" />
          </button>
        </div> -->
        <div class="footer">
          <!-- <div class="text-area">
            <div class="secondary">
              <span>{{ $__t('예상결제금액') | capitalizeFirstLetter }}</span
              >&nbsp;
              <img
                src="/img/icon-question.svg"
                alt="더보기"
                @click="
                  $analytics.logEvent('time setting estimated price');
                  openRef('estimated-price-slider');
                "
              />
            </div>
            <div class="primary">{{ estimatedPrice.toLocaleString() }}{{ $__t('won') }}</div>
          </div> -->
          <div class="button-area">
            <button class="btn-cancel" @click="onClickCancel">{{ $__t('취소') }}</button>
            <button
              type="button"
              @click="estimatedPrice !== 0 && !dateStartErrorString && !dateEndErrorString && !quantityErrorString ? submitForm() : ''"
              class="btn-confirm"
              :class="{
                activated: estimatedPrice !== 0 && !dateStartErrorString && !dateEndErrorString && !quantityErrorString
              }"
            >
              <!-- {{ $__t('선택완료') }} -->
              <span class="key">{{ $__t('Expected Payment') }}</span> {{ $utils.numberFormat(estimatedPrice) }} {{ $__t('원') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <guide-modal id="priceLuggageModal" ref="priceLuggageModal" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <price @openPriceModal="openRef('priceGuideModal')" />
      </template>
    </guide-modal>
    <guide-modal id="priceGuideModal" :noBgClick="true" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
      <template v-slot:body>
        <price-guide />
      </template>
    </guide-modal>

    <ui-slider id="estimated-price-slider" ref="estimated-price-slider" :bgClick="true">
      <estimated-price :item="{ headerTextSecondary, formData, estimatedPrice }" @close-slider="closeRef('estimated-price-slider')" />
    </ui-slider>
  </div>
</template>

<script>
import Price from '@/components/pages/_/Price';
import PriceGuide from '@/components/pages/_/PriceGuide';
import EstimatedPrice from '@/components/includes/EstimatedPrice';
import utils from '@/utils';
import _ from 'lodash';
import UiSlider from '@/components/modules/Slider/Body.vue';

const DEPOSIT_FEE = 5000;
export default {
  props: {
    center: {
      type: Object
    },
    activatedStore: {
      type: Object
    },
    callback: {
      type: Function,
      default: () => {}
    },
    order: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    let defaultStartDateTimeString = utils.getDefaultBookingDateTime(this.$moment().format('YYYY-MM-DD HH:mm'));
    let defaultEndDateTimeString = utils.getDefaultBookingDateTime(this.$moment().add(10, 'm').format('YYYY-MM-DD HH:mm'));
    let show = true;

    return {
      activatedListItem: '',
      weekDays: [
        this.$__t('SSA_SUN'),
        this.$__t('SSA_MON'),
        this.$__t('SSA_TUE'),
        this.$__t('SSA_WED'),
        this.$__t('SSA_THU'),
        this.$__t('SSA_FRI'),
        this.$__t('SSA_SAT')
      ],

      isDateChanged: false,

      formData: {
        form_id: 'reserve_form',
        date_start: {
          label: this.getLabelOfDates(defaultStartDateTimeString),
          value: defaultStartDateTimeString
        },
        date_end: {
          label: this.getLabelOfDates(defaultEndDateTimeString),
          value: defaultEndDateTimeString
        },
        quantity_small: parseInt(this.$route.query?.quantity_small) || 0,
        quantity_big: parseInt(this.$route.query?.quantity_big) || 0,
        search_text: ''
      },

      initalDateStart: '',
      initialDateEnd: '',

      dateStartErrorString: '',
      dateEndErrorString: '',
      quantityErrorString: '',
      show,

      minStartDate: utils.getClosestValidDateTime()
    };
  },

  computed: {
    minEndDate() {
      return this.$moment(this.formData.date_start.value).add(10, 'm').format('YYYY-MM-DD HH:mm');
    },

    reserveFormState() {
      return this.$store.getters['resourceForm/GET_ITEM']({
        key: 'form_id',
        value: 'reserve_form'
      });
    },
    isReserveFormStateValid() {
      return (
        this.reserveFormState?.date_start &&
        this.reserveFormState?.date_end &&
        (this.reserveFormState?.quantity_big || this.reserveFormState?.quantity_small)
      );
    },
    isConditionChanged() {
      let { date_start, date_end, quantity_small, quantity_big } = this.reserveFormState || {};

      return !(
        (this.formData.date_start.value === date_start || this.formData.date_start.value === date_start?.value) &&
        (this.formData.date_end.value === date_end || this.formData.date_end.value === date_end?.value) &&
        this.formData.quantity_small === quantity_small &&
        this.formData.quantity_big === quantity_big
      );
    },
    cachedMap() {
      return this.$store.getters['resourceMap/GET_MAP'];
    },
    disabledHoursOfStartDate() {
      return this.getDisabledHoursOf(this.formData.date_start.value);
    },
    disabledMinutesOfStartDate() {
      return this.getDisabledMinutesOf(this.formData.date_start.value);
    },
    disabledHoursOfEndDate() {
      return this.getDisabledHoursOf(this.formData.date_end.value);
    },
    disabledMinutesOfEndDate() {
      return this.getDisabledMinutesOf(this.formData.date_end.value);
    },
    headerTextPrimary() {
      let dateDiff = this.$moment(this.formData.date_start.value).diff(this.$moment(this.formData.date_end.value));

      let minutes = Math.abs(this.$moment.duration(dateDiff).minutes());
      let hours = Math.abs(this.$moment.duration(dateDiff).hours());
      let days = Math.floor(Math.abs(this.$moment.duration(dateDiff).asDays()));

      let value = '';

      if (days > 0) {
        value += ' ' + days + this.$__t('d');
      }

      if (hours > 0) {
        value += ' ' + hours + this.$__t('_h');
      }

      if (minutes > 0) {
        value += ' ' + minutes + this.$__t('m');
      }

      return this.isDateChanged ? this.$__t('total') + value + ' ' + this.$__t('use') : this.$__t('이용시간 및 물품 개수 설정하기');
    },

    headerTextSecondary() {
      const momentDateStart = this.$moment(this.formData.date_start.value);
      const momentDateEnd = this.$moment(this.formData.date_end.value);
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      text = this.ticketQuantity ? text + ' / ' + this.ticketQuantity : text;

      return text;
    },

    estimatedPrice() {
      // let total_base_price = this.formData.quantity_small * 2000 + this.formData.quantity_big * 3000;
      let total_base_price = this.formData.quantity_small * 2500;
      let total_additional_price = 0;

      // 예약 시간
      let duration = this.$moment.duration(
        this.$moment(this.formData.date_end.value, 'YYYY-MM-DD HH:mm').diff(this.$moment(this.formData.date_start.value, 'YYYY-MM-DD HH:mm:ss'))
      );
      let durationMinutes = duration.asMinutes();
      let durationDays = duration.asDays();

      if (durationMinutes - 60 <= 0) {
        // 1시간 이전
        total_additional_price = 0;
      } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
        // 1시간 이후 7시간 30분 이전
        //가격정책 변경 전
        // total_additional_price =
        //   100 * Math.ceil((durationMinutes - 60) / 10) * this.formData.quantity_small +
        //   150 * Math.ceil((durationMinutes - 60) / 10) * this.formData.quantity_big;

        //가격정책 변경 후
        total_additional_price = 150 * Math.ceil((durationMinutes - 60) / 10) * this.formData.quantity_small;
      } else if (450 < durationMinutes && durationMinutes <= 1440) {
        //7시간 30분 초과 24시간 이하
        //정책변경전
        // total_additional_price = 6000 * this.formData.quantity_small + 9000 * this.formData.quantity_big - total_base_price;

        //정책변경후
        total_additional_price = 8500 * this.formData.quantity_small - total_base_price;
      } else if (1440 < durationMinutes) {
        //24시간 초과
        //가격정책 변경 전
        // total_additional_price =
        //   (6000 + Math.ceil(durationDays - 1) * 4000) * this.formData.quantity_small +
        //   (9000 + Math.ceil(durationDays - 1) * 6000) * this.formData.quantity_big -
        //   total_base_price;

        //가격정책 변경 후
        total_additional_price = (8500 + Math.ceil(durationDays - 1) * 5000) * this.formData.quantity_small - total_base_price;
      }

      let price = total_base_price + total_additional_price;

      return isNaN(price) ? 0 : price;
    },

    ticketQuantity() {
      let value = '';

      if (this.formData.quantity_small > 0) {
        let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.formData.quantity_small}`;

        if (this.formData.quantity_small > 1) {
          value += quantityString + this.$__t('item');
        } else {
          value += quantityString + this.$__t('items');
        }
      }

      if (this.formData.quantity_big > 0) {
        let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.formData.quantity_big}`;

        if (this.formData.quantity_big > 0) quantityString = ' ' + quantityString;

        if (this.formData.quantity_big > 1) {
          value += quantityString + this.$__t('item');
        } else {
          value += quantityString + this.$__t('items');
        }
      }

      return value;
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.checkAvailableDates();

        if (['StoreSingle', 'ReserveOrder'].includes(this.$route.name)) {
          this.checkReserveAvailable();
        }

        this.isDateChanged = true;
      },
      deep: true
    }
  },

  created() {
    this.loadReserveFormState();
  },

  methods: {
    messageShow() {
      this.show = false;
    },
    loadReserveFormState() {
      if (!this.isReserveFormStateValid) {
        this.formData.quantity_small = 0;
        this.formData.quantity_big = 0;
        return;
      }

      this.formData.search_text = this.reserveFormState.search_text;
      this.formData.quantity_big = parseInt(this.reserveFormState.quantity_big) || 0;
      this.formData.quantity_small = parseInt(this.reserveFormState.quantity_small) || 0;
      this.formData.date_start.value = this.reserveFormState.date_start?.value || this.reserveFormState.date_start || this.formData.date_start;
      this.formData.date_start.label = this.reserveFormState.date_start?.label || this.getLabelOfDates(this.formData.date_start.value);
      this.formData.date_end.value = this.reserveFormState.date_end?.value || this.reserveFormState.date_end || this.formData.date_end;
      this.formData.date_end.label = this.reserveFormState.date_end?.label || this.getLabelOfDates(this.formData.date_end.value);
    },

    onClickListItem(item) {
      switch (item) {
        case 'date_start':
          this.activatedListItem = this.activatedListItem === 'date_start' ? '' : 'date_start';
          break;

        case 'date_end':
          this.activatedListItem = this.activatedListItem === 'date_end' ? '' : 'date_end';
          break;

        case 'item_count':
          this.activatedListItem = this.activatedListItem === 'item_count' ? '' : 'item_count';
          break;
      }
    },
    onClickCancel() {
      this.$emit('close');
    },
    onMouseDown(which) {
      switch (which) {
        case 'small':
          let largeListElement = this.$el.querySelector('li.large');
          largeListElement.classList.add('faded');
          largeListElement.querySelector('.spinner-area').style.display = 'none';
          break;

        case 'large':
          let smallListElement = this.$el.querySelector('li.small');
          smallListElement.classList.add('faded');
          smallListElement.querySelector('.spinner-area').style.display = 'none';
          break;
      }
    },

    onMouseUp(e) {
      this.$el.querySelectorAll('.transitioned-item .luggage li').forEach((node) => node.classList.remove('faded'));
      this.$el.querySelectorAll('.transitioned-item .luggage li .spinner-area').forEach((node) => (node.style.display = 'block'));
    },

    onSelect(which, value) {
      this.formData[which] = value;
    },

    openRef(ref) {
      this.$refs[ref].open(ref);
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    checkAvailableDates: _.debounce(async function () {
      const momentDateStart = this.$moment(this.formData.date_start.value, 'YYYY-MM-DD HH:mm');
      const momentDateEnd = this.$moment(this.formData.date_end.value, 'YYYY-MM-DD HH:mm');

      // if (utils.isBeforeThan(momentDateStart, new Date())) {
      //   const defaultStartDateTimeString = utils.getClosestValidDateTime(this.$moment().format('YYYY-MM-DD HH:mm'));
      //   const defaultEndDateTimeString = utils.getClosestValidDateTime(
      //     this.$moment().add(10, 'm').format('YYYY-MM-DD HH:mm')
      //   );

      //   this.formData.date_start.label = this.getLabelOfDates(defaultStartDateTimeString);
      //   this.formData.date_start.value = defaultStartDateTimeString;
      //   this.formData.date_end.label = this.getLabelOfDates(defaultEndDateTimeString);
      //   this.formData.date_end.value = defaultEndDateTimeString;
      // }
      if (momentDateStart.isSameOrAfter(momentDateEnd)) {
        this.formData.date_end.value = momentDateStart.add(10, 'm').format('YYYY-MM-DD HH:mm');
        this.formData.date_end.label = this.getLabelOfDates(this.formData.date_end.value);
      }

      // todo
    }, 0),

    async submitForm() {
      this.callback();

      if (!this.isConditionChanged) {
        return this.$emit('close');
      }

      this.$store.commit('loading/SET_TRUE');

      return setTimeout(async () => {
        this.$analytics.logEvent('time luggage mobile setting', {
          date_start: this.formData.date_start.value,
          date_end: this.formData.date_end.value,
          small: this.formData.quantity_small,
          large: this.formData.quantity_big
        });

        this.$store.commit('resourceForm/SET_ITEM', { data: this.formData });

        if (this.$route.name === 'StoreSingle') {
          this.$emit('close');

          return this.$router
            .replace({
              name: 'StoreSingle',
              query: {
                date_start: this.formData.date_start.value,
                date_end: this.formData.date_end.value,
                quantity_small: this.formData.quantity_small,
                quantity_big: this.formData.quantity_big
              }
            })
            .catch(() => this.$emit('close'))
            .finally(() => this.$store.commit('loading/SET_FALSE'));
        } else if (this.$route.name === 'ReserveOrder' || this.$route.name === 'TicketSingle') {
          const ACTION = this.$route.name === 'ReserveOrder' ? 'update' : 'change';
          this.$emit('close');

          return this.$store
            .dispatch(
              `orders/${ACTION}`,
              ACTION === 'update'
                ? {
                    type: this.order?.product_data?.store_type,
                    id: this.order?.order_id || this.$route.params.order_id,
                    start_date: this.formData.date_start.value,
                    end_date: this.formData.date_end.value,
                    quantity: this.formData.quantity_small,
                    over_quantity: this.formData.quantity_big,
                    pass_code: this.$route.query['pass-code']
                  }
                : {
                    id: this.order?.order_id || this.$route.params.order_id,
                    start_date: this.formData.date_start.value,
                    end_date: this.formData.date_end.value,
                    quantity_small: this.formData.quantity_small,
                    quantity_big: this.formData.quantity_big
                  }
            )
            .then((response) => {
              if (ACTION === 'change') {
                this.$store.commit('notice/ADD_ITEM', {
                  message: this.$__t('{reserve-change}.message.success')
                });
                this.$emit('fetch-ticket');
                this.$emit('fetch-order');
                this.$emit('close-modal');
              }

              this.$store.commit('resourceForm/SET_ITEM', {
                data: {
                  form_id: 'reserve_form',
                  date_start: response.reserve_date_start,
                  date_end: response.reserve_date_end,
                  quantity_small: response.reserve_quantity,
                  quantity_big: response.reserve_over_quantity,
                  reserve_info_visible: ACTION === 'update'
                }
              });
            })
            .catch((error) => {
              this.$store.commit('alert/ADD_ITEM', {
                message: error,
                status: 'error'
              });
            })
            .finally(() => {
              setTimeout(() => {
                this.$emit('close');
                this.$store.commit('loading/SET_FALSE');
              }, 1000);
            });
        } else {
          await this.$store.dispatch('stores/clear', {
            list: 'SearchedStores'
          });

          this.$store.commit('resourceForm/SET_ITEM', {
            data: {
              form_id: 'reserve_form',
              date_start: this.formData.date_start.value,
              date_end: this.formData.date_end.value,
              small: this.formData.quantity_small,
              large: this.formData.quantity_big
            }
          });

          this.$emit('change');

          setTimeout(() => {
            this.$emit('close');
            this.$store.commit('loading/SET_FALSE');
          }, 1000);
        }
      }, 500);
    },

    checkReserveAvailable: _.debounce(async function () {
      let query = {
        store_id: parseInt(this.$route.params.store_id || this.reserveFormState.store_id),
        start_date: this.formData.date_start.value,
        end_date: this.formData.date_end.value,
        quantity: this.formData.quantity_small,
        over_quantity: this.formData.quantity_big
      };

      try {
        await this.$store.dispatch('orders/check', { query });
        this.clearErrorString();
      } catch (e) {
        this.onUnavaiable(e);
      }
    }, 0),

    onUnavaiable(error) {
      this.clearErrorString();

      error.split(',').forEach((errorString) => {
        if (errorString.includes('INVALID_START_DATE')) this.dateStartErrorString = this.$__t('It is not opening time');
        if (errorString.includes('INVALID_END_DATE')) this.dateEndErrorString = this.$__t('It is not opening time');
        if (errorString.includes('CLOSED_START_DATE')) this.dateStartErrorString = this.$__t('{error-message}.closed.temporal');
        if (errorString.includes('CLOSED_END_DATE')) this.dateEndErrorString = this.$__t('{error-message}.closed.temporal');
        if (errorString.includes('CLOSED')) this.quantityErrorString = this.$__t('OUT_OF_STOCK');
        if (errorString.includes('OUT_OF_STOCK')) this.quantityErrorString = this.$__t('OUT_OF_STOCK');
        if (errorString.includes('OUT_OF_BIGABLE_STOCK')) this.quantityErrorString = this.$__t('OUT_OF_STOCK');
      });
    },

    clearErrorString() {
      this.dateStartErrorString = '';
      this.dateEndErrorString = '';
      this.quantityErrorString = '';
    },

    getLabelOfDates(dateString) {
      let date = this.$moment(dateString);
      let weekdays = [
        this.$__t('SSA_SUN'),
        this.$__t('SSA_MON'),
        this.$__t('SSA_TUE'),
        this.$__t('SSA_WED'),
        this.$__t('SSA_THU'),
        this.$__t('SSA_FRI'),
        this.$__t('SSA_SAT')
      ];

      let formattedDate = date.format('MM/DD');
      let day = date.day();

      if (date.format('MM/DD') === this.$moment().format('MM/DD')) {
        formattedDate = this.$__t('{date-time-picker}.today');
      } else {
        formattedDate += ' (' + weekdays[day] + ')';
      }

      return formattedDate;
    },

    onInputQuantity(target, e) {
      let stringValue = e.target.value ? String(e.target.value) : '0';

      if (stringValue.length > 2) {
        stringValue = stringValue.substr(0, 2);
      }

      this.formData[target] = Number(stringValue);
      this.$forceUpdate();
    },

    addQuantity(target, e) {
      let targetQuantity = target;

      if (this.formData[targetQuantity] >= 99) return;

      this.formData[targetQuantity] = this.formData[targetQuantity] + 1;
    },

    subQuantity(target, e) {
      let targetQuantity = target;

      if (this.formData[targetQuantity] <= 0) return;

      this.formData[targetQuantity] = this.formData[targetQuantity] - 1;
    }
  },

  components: {
    Price,
    PriceGuide,
    EstimatedPrice,
    UiSlider
  }
};
</script>
<style lang="scss" scoped>
.start-date-time-message {
  color: #878d91;
  font-size: unit(12);
  padding-top: unit(30);
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafb;
  width: 90%;
  height: unit(80);
  border-radius: unit(30);
  margin: 0 auto unit(20) auto;
  padding: unit(14) unit(16) unit(14) unit(16);
  gap: unit(10);
  .text-area {
    flex: 1 1 auto;
    line-height: 1.5; //폰트사이즈에 비례해서.
    font-weight: 500;
    font-size: 11; // 0.625rem === 10; unit(브라우저에서는)!
    word-break: break-all;
    word-wrap: break-word;
  }
  .emoji-area {
    flex: 0 0 unit(30);
    width: unit(30);

    .bravo_emoji {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .remove-button {
    flex: 0 0 unit(24);
    width: unit(24);
    height: unit(24);
  }
}
</style>
