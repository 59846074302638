<template>
  <div id="iez">
    <div class="container">
      <div v-if="loading" style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center;">
        <i class="fa fa-spinner fa-pulse"></i>
      </div>
      <div class="tab-area">
        <lug-tab ref="tab" class="iez" :tab-items="tabItems" :activeIndex="activeTabIndex" @active="onActiveTab"/>
      </div>
      <keep-alive>
        <component :is="currentComponent" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import LugTab from "@/components/modules/LugTab/Body"
import IezMain from "@/components/pages/iez/IezMain"
import IezGuide from "@/components/pages/iez/IezGuide"

export default {
  data(){
    return {
      tabItems : [this.$__t("{iez}.tab.home").toUpperCase(), this.$__t("{iez}.tab.guide").toUpperCase(), this.$__t("{iez}.tab.searcharound").toUpperCase()],
      loading: false,
      activeTabIndex: 0,
      isSetLanguage: localStorage.getItem('lugstay_language')
    }
  },

  computed: {
    currentComponent(){
      let component = ''

      switch (this.activeTabIndex){
        case 0:
          component = 'IezMain'
          break;

        case 1:
          component = 'IezGuide'
          break;

        case 2:
          component = 'IezStores'
          break;
      }

      return component
    },
  },

  async mounted(){

  },

  methods: {
    onActiveTab( index ){
      if(index == 2){
        this.$router.push({ name: 'Stores' , query: this.$route.query})
      }
      this.activeTabIndex = index;
    }
  },

  components: {
    LugTab,
    IezMain,
    IezGuide
  }
}
</script>