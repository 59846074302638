<template>
  <div id="reserve-pay-methods" class="page more-depth">
    <div class="container">
      <ui-form @submit="submitForm" :formData="formData">
        <div class="page-header">
          <div class="title">{{ $__t("결제수단 선택") }}</div>
          <div class="secondary">
            {{ $__t("{billing}.header.secondary") }}
          </div>
        </div>

        <div class="page-body">
          <div class="section card-selector">
            <card-selector :items="billings" @update="updatePaymentMethod" />
          </div>

          <div v-if="isReserve" class="section coupon">
            <ui-form-list class="form-body">
              <!--보유쿠폰-->
              <ui-form-list-item>
                <v2-coupon-select
                  :label="formData.couponCode.label"
                  :error="formData.couponCode.error"
                  :placeholder="couponCodePlaceholder"
                  v-model="formData.couponCode.value"
                  :items="coupons"
                  :disabled="!!(formData.discountCode.value || formData.pass.value)"
                />
              </ui-form-list-item>
              <!--이용권-->
              <ui-form-list-item>
                <v2-coupon-select
                  :label="formData.pass.label"
                  :error="formData.pass.error"
                  :placeholder="formData.pass.placeholder"
                  v-model="formData.pass.value"
                  :items="passItems"
                  :disabled="!!(formData.discountCode.value || formData.couponCode.value)"
                />
              </ui-form-list-item>
              <!--할인코드-->
              <ui-form-list-item>
                <v2-textbox
                  :placeholder="formData.discountCode.placeholder"
                  :label="formData.discountCode.label"
                  :error="formData.discountCode.error"
                  v-model="formData.discountCode.value"
                  :disabled="
                    !!(
                      formData.couponCode.value?.coupon_code?.length > 0 ||
                      formData.pass.value
                    )
                  "
                />
              </ui-form-list-item>
            </ui-form-list>
          </div>
        </div>

        <div class="page-footer">
          <button
            class="ui-submit-button"
            type="submit"
            :disabled="!formData.payMethod.value"
            v-button
            :class="{ activated: formData.payMethod.value }"
          >
            <span class="ui-submit-button__text">{{ $__t("적용하기") }}</span>
          </button>
        </div>
      </ui-form>
    </div>
  </div>
</template>

<script>
import CardSelector from "@/components/modules/CardSelector/Body.vue";
import utils from "@/utils";

export default {
  name: "ReservePayMethods",

  props: ["order_id"],

  data() {
    return {
      formData: {
        payMethod: {
          validation: [
            {
              type: "required",
            },
          ],
          value: {},
        },
        couponCode: {
          label: this.$__t("Available coupon"),
          placeholder: this.$__t("쿠폰 코드"),
          value: "",
          error: "",
        },
        discountCode: {
          label: this.$__t("할인코드"),
          placeholder: this.$__t("할인코드 입력"),
          value: "",
          error: "",
        },
        pass: {
          label: this.$__t("이용권"),
          placeholder: this.$__t("이용권을 선택해주세요"),
          value: "",
          error: "",
        },
      },

      listQuery: {
        payMethod: {
          page: 1,
          size: 10,
        },

        couponCode: {
          page: 1,
          size: 10,
          group: "available",
        },
      },
    };
  },

  created() {
    if (!this.order) {
      this.loadOrderData();
    }

    this.loadUserData();
    //here
    this.getCoupons();

    if (!(this.billings.length && this.coupons.length)) {
      this.$store.commit("loading/SET_TRUE");
      Promise.all([this.getPayMethods(), this.getCoupons(), this.fetchPasses()])
        .then(() => {
          this.formData.payMethod.value = this.primaryBilling;
        })
        .finally(() => this.$store.commit("loading/SET_FALSE"));
    }
    if (this.selectedCoupon) {
      this.formData.couponCode.value = this.selectedCoupon;
    }
  },

  mounted() {
    this.$analytics.logEvent("payment page view");
  },

  computed: {
    selectedCoupon() {
      return this.$store.getters["resourceForm/GET_ITEM"]({
        key: "form_id",
        value: "reserve_form_status",
      })?.coupon_code;
    },
    billings() {
      return Object.keys(this.$store.state.billings.items)
        .map((o) => this.$store.state.billings.items[o])
        .sort((a, b) => {
          const A_CREATD_AT = this.$moment(a.created_at);
          const B_CREATD_AT = this.$moment(b.created_at);

          if (A_CREATD_AT.isBefore(B_CREATD_AT)) {
            return 1;
          }

          if (B_CREATD_AT.isBefore(A_CREATD_AT)) {
            return -1;
          }

          return 0;
        });
    },

    coupons() {
      return this.$store.getters["resourceCoupons/GET_ITEMS_BY_LIST"]({
        list: "MyCoupons_available",
      });
    },

    couponCodePlaceholder() {
      return this.$__t("사용가능 쿠폰 {#1}장 / 보유쿠폰 {#2}장")
        .replace("{#1}", this.coupons?.filter((coupon) => !coupon?.expired).length)
        .replace("{#2}", this.coupons?.length);
    },

    primaryBilling() {
      return this.billings.find((billing) => billing.is_primary > 0);
    },

    order() {
      return this.$store.getters["orders/GET_ITEM"]({
        key: "reserve_id",
        value: this.order_id,
      });
    },

    isReserve() {
      return this.$router.currentRoute.fullPath.includes("/reserve/");
    },

    passItems() {
      return this.$store.getters["resourceProducts/GET_ITEMS_BY_LIST"]({
        list: "MyPasses",
      }).filter((o) => o.status === "PREPARED" || o.status === "STARTED");
    },
  },

  methods: {
    async loadOrderData() {
      try {
        this.$store.commit("loading/SET_TRUE");
        return await this.$store.dispatch("orders/reserve", {
          id: this.order_id,
        });
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e.message),
          status: "error",
        });
      } finally {
        setTimeout(() => {
          this.$store.commit("loading/SET_FALSE");
        }, 1000);
      }
    },

    async getPayMethods() {
      try {
        return await this.$store.dispatch("billings/getCardList", {
          query: this.listQuery.payMethod,
        });
      } catch (e) {}
    },

    userData() {
      return {
        pay_method: this.$store.getters["userData/GET_ITEM"]({
          key: "pay_method",
        }),
        coupon_code: this.$store.getters["userData/GET_ITEM"]({
          key: "coupon_code",
        }),
        discount_code: this.$store.getters["userData/GET_ITEM"]({
          key: "discount_code",
        }),
      };
    },

    loadUserData() {
      let userData = this.userData();

      this.formData.couponCode.value = this.coupons?.find(
        (o) => o?.uid === userData?.coupon_code?.uid
      );
      this.formData.discountCode.value = userData?.discount_code;
    },

    async getCoupons() {
      try {
        return await this.$store.dispatch("resourceCoupons/getList", {
          list: "MyCoupons_available",
          query: this.listQuery.couponCode,
        });
      } catch (e) {}
    },

    async fetchPasses() {
      let options = {
        list: "MyPasses",
        query: {
          page: 1,
          size: 10,
          group: "available",
          type: "ALL",
        },
      };

      try {
        await this.$store.dispatch("resourceProducts/getMyList", options);
      } catch (e) {
        this.$log.error(e);
      }
    },

    updatePaymentMethod(method = {}) {
      this.formData.payMethod.value = method;
    },

    async submitForm(values) {
      this.$analytics.logEvent("apply payment info");
      this.$store.commit("loading/SET_TRUE");

      let { couponCode, discountCode, payMethod } = values;
      payMethod = utils.parseJSON(payMethod);
      const uid = payMethod.uid;
      this.$store.dispatch("billings/updatePrimaryCard", { uid });
      couponCode = utils.parseJSON(couponCode);

      if (this.$router.currentRoute.fullPath.includes("/reserve/")) {
        // /reserve/와 /purchase/의 차이..?
        this.onReserve({ payMethod, couponCode, discountCode });
      } else if (this.$router.currentRoute.fullPath.includes("/purchase/")) {
        this.onPurchase({ payMethod, couponCode, discountCode });
      }

      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE");
      }, 1000);
    },

    async onReserve({ payMethod, couponCode, discountCode }) {
      try {
        await this.$store.dispatch("orders/update", {
          type: this.order?.product_data?.store_type === "LOCKER" ? "locker" : "luggage",
          id: this.$route.params.order_id,
          start_date: this.order.reserve_date_start,
          start_datetime: this.order.reserve_date_start,
          end_date: this.order.reserve_date_end,
          quantity: parseInt(this.order.reserve_quantity || 0),
          over_quantity: parseInt(this.order.reserve_over_quantity || 0),
          coupon_code: couponCode.coupon_code,
          discount_code: discountCode,
          pass_code: this.formData.pass.value.pass_code,
        });

        this.$store.commit("resourceForm/SET_ITEM", {
          data: {
            form_id: "reserve_form_status",
            pay_method: payMethod,
            coupon_code: couponCode || "",
            discount_code: discountCode || "",
          },
        });

        return this.$router.back();
      } catch (e) {
        console.error(e);

        if (e.message.includes("DATE") || e.message.includes("STOCK")) {
          this.$store.commit("resourceForm/SET_ITEM", {
            data: {
              form_id: "reserve_form_status",
              pay_method: payMethod,
              coupon_code: couponCode || "",
              discount_code: discountCode || "",
            },
          });

          this.$router.back();
        }

        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e.message),
          status: "error",
        });
        this.formData.couponCode.value = "";
        this.formData.discountCode.value = "";
      }
    },

    async onPurchase({ payMethod, couponCode, discountCode }) {
      try {
        await this.$store.dispatch("orders/purchaseUpdate", {
          id: this.$route.params.order_id,
          quantity: 1,
          coupon_code: couponCode.coupon_code,
          discount_code: discountCode,
        });

        this.$store.commit("resourceForm/SET_ITEM", {
          data: {
            form_id: "purchase_form",
            pay_method: payMethod,
            coupon_code: couponCode || "",
            discount_code: discountCode || "",
          },
        });

        return this.$router.back();
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e.message),
          status: "error",
        });
        this.formData.couponCode.value = "";
        this.formData.discountCode.value = "";
      }
    },
  },

  components: {
    CardSelector,
  },
};
</script>

<style scoped lang="scss">
.ui-select {
  height: 4.167rem;
  border: solid 1px #ced3d6;
  font-size: 1.16667rem;
  line-height: 1.875;
  border-radius: 3px;
}
</style>
