<template>
  <div class="language-selector">
    <h1 class="language-selector__title">
      {{ $__t("언어 설정") }}
    </h1>
    <ul class="language-selector__list">
      <li
        v-button
        class="language-selector__list-item"
        v-for="item in languageList"
        :key="item.name"
        @click="switchLanguage(item.id)"
        :class="{ on: item.id === storeLang }"
      >
        <object :data="item.icon" class="icon" />
        <span class="text"> {{ item.name }} </span>
        <object v-if="item.id === storeLang" data="/img/icon-checked.svg" class="check" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",

  data() {
    return {
      languageList: [
        {
          id: "ko",
          name: "한국어",
          icon: require("@/assets/icons/svg/flag-korea.svg"),
        },
        {
          // id: "en",
          id: "base",
          name: "English (US)",
          icon: require("@/assets/icons/svg/flag-us.svg"),
        },
        // {
        //   id: 'cn',
        //   name: '中文(簡体)',
        //   icon: require('@/assets/icons/svg/flag-china.svg'),
        // },
        // {
        //   id: 'hk',
        //   name: '中文(繁體)',
        //   icon: require('@/assets/icons/svg/flag-china.svg'),
        // },
        // {
        //   id: 'jp',
        //   name: '日本語',
        //   icon: require('@/assets/icons/svg/flag-japan.svg'),
        // },
        // {
        //   id: 'vn',
        //   name: 'Tiếng Việt',
        //   icon: require('@/assets/icons/svg/flag-vietnam.svg'),
        // },
      ],
    };
  },

  computed: {
    storeLang() {
      return this.$store.state.lang;
    },
  },

  methods: {
    switchLanguage(locale) {
      this.$store.commit("loading/SET_TRUE");
      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE"), 1000;
      });

      this.$store.commit("SET_LANGUAGE", locale);

      const languageSet = {
        ko: "ko",
        en: "En",
        hk: "ch_traditional",
        cn: "ch_Simplified Chinese",
        jp: "jp",
        vn: "vietnamese",
        system: "system",
      };

      this.$analytics.logEvent("language change", { language: languageSet[locale] });

      // Language.setLanguage(locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selector {
  padding: unit(20) 0;

  &__title {
    font-size: unit(16);
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.006em;
  }

  &__list {
    margin-top: unit(46);
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: unit(12);
    margin: unit(36) 0;

    .icon {
      flex: 0 0 unit(24);
      width: unit(24);
      height: unit(24);
      object-fit: contain;
      pointer-events: none;
    }

    .text {
      margin-left: unit(12);
      flex: 1;
      font-weight: 400;
      font-size: unit(14);
      line-height: 1.43;
    }

    .check {
      flex: 0 0 unit(24);
      width: unit(24);
      height: unit(24);
      object-fit: contain;
      pointer-events: none;
    }

    &.on {
      .text {
        color: $color-primary;
      }
    }
  }
}
</style>
