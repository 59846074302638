<template>
  <div class="app-footer">
    <div class="app-footer__container">
      <ul class="app-footer__list">
        <li class="app-footer__list-item" v-for="(item, index) in listItems" :key="`footer-list-${index}`">
          <button type="button" @click="onClickTabButton(index)" v-ripple="'rgba( 206, 212, 218, 0.35 )'">
            <img :src="getIconSource(index)" :alt="`Footer Icon ${index}`" />
            <div class="text">
              {{ getText(index) }}
            </div>
          </button>
        </li>
      </ul>
      <div class="app-footer__qr-code">
        <button type="button" @click="openQrCodeReader" class="qr-code__button" v-ripple="'rgba( 206, 212, 218, 0.35 )'">
          <img src="/img/icon/qr-code.svg" alt="qr-code" class="qr-code__image" />
          <span class="qr-code__text"> QR코드 스캔 </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    let listItems = ['home', 'tickets', 'more'];

    const hashStringIndex = listItems.indexOf(window.location.hash.replace('#', ''));

    return {
      listItems,
      selectedListIndex: hashStringIndex > -1 ? hashStringIndex : 1
    };
  },

  mounted() {
    window.addEventListener('replaceState', this.onReplaceState);
  },

  beforeDestroy() {
    window.removeEventListener('replaceState', this.onReplaceState);
  },

  methods: {
    getIconSource(index) {
      let source = '';

      switch (index) {
        case 0:
          source = '/img/icon/home.svg';
          // if (this.selectedListIndex === 1) source = "/img/icon-home-white.svg";
          break;

        case 1:
          source = '/img/icon/usage.svg';
          // if (this.selectedListIndex === 0) source = "/img/icon-list-white.svg";
          break;

        case 2:
          source = '/img/icon/hamburger.svg';
          // if (this.selectedListIndex === 2) source = "/img/icon-more-white.svg";
          break;
      }

      return source;
    },

    getText(index) {
      let text = '';

      switch (index) {
        case 0:
          text = this.$__t('Home');
          break;

        case 1:
          text = this.$__t('Usage');
          break;

        case 2:
          text = this.$__t('More');
          break;
      }

      return text;
    },

    onClickTabButton(index) {
      this.selectedListIndex = index;

      if (index === 1) {
        this.$analytics.logEvent('booking list button app');
      }

      setTimeout(() => {
        this.$emit('select', { hash: this.listItems[index] });
      }, 0);
    },

    onHashChange() {
      const PAGES = [
        {
          name: 'Tickets',
          regExp: /\/tickets/,
          component: 'tickets'
        },
        {
          name: 'Home',
          regExp: /\/home/,
          component: 'home'
        },
        {
          name: 'More',
          regExp: /\/more/,
          component: 'more'
        }
      ];

      const hashStringIndex = PAGES.findIndex((page) => page.regExp.test(location.hash));

      return (this.selectedListIndex = hashStringIndex > -1 ? hashStringIndex : 1);
    },

    openQrCodeReader() {
      setTimeout(() => {
        this.$analytics.logEvent('complete home qr scan');
        this.$store.dispatch('qrCodeScanner/open', {
          type: 'onetime-callback',
          callback: async ({ status, code, store_id, error }) => {
            try {
              if (status === 'ERROR') {
                throw error;
              }

              if (status === 'SUCCESS') {
                this.$router.push({
                  name: 'Stores',
                  query: { autoSearch: 'target', sid: store_id }
                });
              }
            } catch (e) {
              this.$store.commit('alert/ADD_ITEM', {
                message: e.toString(),
                status: 'error'
              });
            }
          }
        });
      }, 300);
    }
  }
};
</script>
