var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-coupon for-guest",class:{
  ISSUED: _vm.coupon.status == 'ISSUED',
  CHECK_IN: _vm.coupon.status == 'CHECK_IN',
  CHECK_OUT: _vm.coupon.status == 'CHECK_OUT',
  CANCELLED: _vm.coupon.status == 'CANCELLED',
  EXPIRED: _vm.isExpired,
}},[_c('div',{staticClass:"container"},[(_vm.coupon.status == 'CHECK_OUT' && !_vm.coupon.review)?_c('ui-solid-button',{staticClass:"review-button",attrs:{"size":"extra-small"}},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.openReviewForm}},[_vm._v(_vm._s(_vm.$__t( "Review" )))])]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'MypageTicketSingle', params: { id: _vm.coupon.coupon_id } }}},[_c('div',{staticClass:"store"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.isExpired ? _vm.$__t( "EXPIRED" ) : _vm.$__t( _vm.coupon.status )))]),_vm._v(_vm._s(_vm.coupon.store_name))]),_c('div',{staticClass:"address"},[_vm._v(_vm._s(_vm.coupon.store_address)+" ("+_vm._s(_vm.coupon.store_zipcode)+")")])]),_c('div',{staticClass:"reserve"},[_c('ul',[_c('li',[_c('div',{staticClass:"key"},[_vm._v(_vm._s(_vm.$__t( "결제 일시" )))]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"payment-time"},[_vm._v(_vm._s(_vm._f("moment")(_vm.coupon.timestamp, "YYYY. MM. DD. HH:mm" )))])])]),_c('li',[_c('div',{staticClass:"key"},[_vm._v(_vm._s(_vm.$__t( "기간" )))]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"duration-start"},[_vm._v(_vm._s(_vm._f("moment")(_vm.coupon.coupon_date_start, "YYYY. MM. DD. HH:mm" ))+" ~ "+_vm._s(_vm._f("moment")(_vm.coupon.coupon_date_end, "YYYY. MM. DD. HH:mm" )))])])]),_c('li',[_c('div',{staticClass:"key"},[_vm._v(_vm._s(_vm.$__t("No. of items" )))]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"luggage-quantity"},[_vm._v(_vm._s(_vm.coupon.coupon_quantity)+_vm._s(_vm.$__t( "개" )))])])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }