<template>
  <div class="subpage" id="mypage-interest-stores">
    <div class="container">

      <div class="store-list" v-if="docs.length > 0">
        <div class="container">
          <ul class="item-list">
            <li class="item" v-for="doc in docs"><store-list-item :store="doc" :key="doc.id" /></li>
          </ul>
          <ui-text-button display="block" color="primary" class="list-more" @click="getMoreListItems" v-if="listLoadable">
            <button type="button">{{ $__t( "더 불러오기" ) }}</button>
          </ui-text-button>
        </div>
      </div>

      <div class="store-list-empty" v-else>
        <div class="container">
          <div class="title">{{ $__t( "아직 관심 있는 상점이 없으신가요?" ) }}</div>
          <ui-solid-button color="primary" size="small">
            <router-link :to="{ name: 'Stores' }">
              {{ $__t( "상점 검색하기" ) }}
            </router-link>
          </ui-solid-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import StoreListItem from "@/components/resources/store/ForGuest";
export default {
  components: {
    StoreListItem
  },
  data(){
    return {
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 12
      }
    };
  },
  computed: {
    docs(){
      return this.$store.getters[ "stores/GET_ITEMS_BY_LIST" ]({ list: "MyInterestStores" });
    }
  },
  mounted() {
    this.getListItems();
  },
  methods: {
    getListItems(){
      this.$store.dispatch( "stores/getInterestList", { list: "MyInterestStores", query: this.listQuery }).then( res => {
        if( this.docs.length == res.data.data.total || res.data.data.items.length == 0 ){
          this.listLoadable = false;
        }else{
          this.listLoadable = true;
        };
      });
    },
    getMoreListItems(){
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    }
  }
}
</script>
