<template>
  <div class="page store-single">
    <template v-if="store">
      <div class="container">
        <div style="visibility: hidden; z-index: -1; height: 0">
          <input
            type="text"
            id="clipboard-store-address"
            :value="store.store_address"
            readonly
          />
          <input
            type="text"
            id="clipboard-site-url"
            :value="$router.currentRoute.fullPath"
            readonly
          />
        </div>

        <div
          ref="header"
          class="site-header more-depth"
          :class="{ 'site-header--white': storeNameHidden }"
        >
          <div class="header">
            <button
              v-button
              class="header__button--left"
              type="button"
              @click="onClickBack"
            >
              <img
                v-if="$route.query.from === 'ticket'"
                src="/img/icon/header-close-white.svg"
                style="width: 24px; height: 24px; object-fit: contain; margin: auto"
              />
              <template v-else>
                <img v-if="storeNameHidden" src="@/assets/icons/svg/arrow-left.svg" />
                <img v-else src="@/assets/icons/svg/arrow-white.svg" />
              </template>
            </button>
            <div class="title" v-show="storeNameHidden">
              {{ store.store_name }}
            </div>
            <button
              v-if="$route.query.from !== 'ticket'"
              v-button
              class="header__button--right"
              type="button"
              @click="openSidebar"
            >
              <img v-if="storeNameHidden" src="@/assets/icons/svg/hamburger.svg" />
              <img v-else src="@/assets/icons/svg/hamburger-white.svg" />
            </button>
          </div>
        </div>

        <div ref="body" class="page-body">
          <div class="store-images" :style="{ height: carouselHeight + 'px' }">
            <lug-carousel :increment-pager="true">
              <lug-carousel-item
                v-viewer="{ toolbar: false, title: false, movable: false }"
              >
                <img
                  :src="storePrimaryImage"
                  class="image"
                  onerror="this.onerror=null;this.src='/img/default-lugstay-circle.png'"
                  :style="{
                    objectFit: storePrimaryImage.includes('default-lugstay')
                      ? 'contain'
                      : 'cover',
                  }"
                />
              </lug-carousel-item>
              <template v-for="(source, index) in storeSecondaryImages">
                <lug-carousel-item
                  v-viewer="{ toolbar: false, title: false, movable: false }"
                  :key="`store-image-${index}`"
                >
                  <img
                    :src="source"
                    class="image"
                    :style="{
                      objectFit: source.includes('default-lugstay') ? 'contain' : 'cover',
                    }"
                  />
                </lug-carousel-item>
              </template>
            </lug-carousel>
          </div>

          <div class="store-info">
            <div class="section store-info">
              <div class="section-body">
                <div class="store-name-address">
                  <h1 ref="store-name" class="store-name">
                    <!-- {{ storeName }} -->
                    {{ store?.store_name }}
                  </h1>
                  <span class="store-address">{{ store.store_address_localed }}</span>
                </div>
                <div class="button-area">
                  <button
                    type="button"
                    v-button
                    class="store-info__button"
                    @click="$router.push({ name: 'Cs' })"
                  >
                    <img src="@/assets/icons/svg/phone.svg" class="icon" />
                  </button>
                  <button
                    type="button"
                    v-button
                    class="store-info__button"
                    @click="openRef('map-connect-slider')"
                  >
                    <img src="@/assets/icons/svg/address.svg" class="icon" />
                  </button>
                </div>
              </div>
              <div class="section-footer">
                <ul class="facility-list">
                  <li class="facility-item" v-for="item in facilities" :key="item">
                    {{ $__t(item) }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="section time-table">
              <h2 class="title">{{ $__t("영업시간") }}</h2>
              <div class="card table">
                <ul class="table-row">
                  <template v-for="(weekKey, index) in displayWeeks.slice(0, 2)">
                    <li class="table-cell" :key="`weekKey-${index}`">
                      <div class="day">{{ $__t(weekKey) }}</div>
                      {{
                        store.time_table && store.time_table[weekKey]
                          ? displayTimeTable(store.time_table[weekKey])
                          : $__t("Day Off")
                      }}
                    </li>
                  </template>
                </ul>
                <ul class="table-row">
                  <template v-for="(weekKey, index) in displayWeeks.slice(2, 4)">
                    <li class="table-cell" :key="`weekKey-${index}`">
                      <div class="day">{{ $__t(weekKey) }}</div>
                      {{
                        store.time_table && store.time_table[weekKey]
                          ? displayTimeTable(store.time_table[weekKey])
                          : $__t("Day Off")
                      }}
                    </li>
                  </template>
                </ul>
                <ul class="table-row">
                  <template v-for="(weekKey, index) in displayWeeks.slice(4, 6)">
                    <li class="table-cell" :key="`weekKey-${index}`">
                      <div class="day">{{ $__t(weekKey) }}</div>
                      {{
                        store.time_table && store.time_table[weekKey]
                          ? displayTimeTable(store.time_table[weekKey])
                          : $__t("Day Off")
                      }}
                    </li>
                  </template>
                </ul>
                <ul class="table-row">
                  <template v-for="(weekKey, index) in displayWeeks.slice(6)">
                    <li class="table-cell red" :key="`weekKey-${index}`">
                      <div class="day">{{ $__t(weekKey) }}</div>
                      {{
                        store.time_table && store.time_table[weekKey]
                          ? displayTimeTable(store.time_table[weekKey])
                          : $__t("Day Off")
                      }}
                    </li>
                  </template>
                </ul>
              </div>
            </div>

            <div v-if="isStore" class="section facility">
              <h2 class="title">{{ $__t("시설현황") }}</h2>
              <div class="card">
                <ul class="facility-list">
                  <li
                    v-for="(value, name) in storeFacilities"
                    class="facility-item"
                    :class="{ activated: facilityExists(name) }"
                    :key="name"
                  >
                    <img
                      :src="
                        facilityExists(name) ? value.image.activated : value.image.default
                      "
                    />
                    <span class="text">{{
                      $__t(value.text) | firstLetterToUpperCase
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="isStore" class="section notice">
              <h2 class="title">{{ $__t("럭스테이 안전 보상 서비스") }}</h2>
              <div class="card flex">
                <div class="image-area">
                  <img src="/img/store-cctv.png" alt="store-cctv" />
                </div>
                <div class="text-area">
                  <h3 class="primary">{{ $__t("CCTV 보유 상점") }}</h3>
                  <p class="secondary">
                    {{ $__t("CCTV가 있는 상점만 선별하며, 별도의 공간에 보관됩니다.") }}
                  </p>
                </div>
              </div>

              <div class="card flex">
                <div class="image-area">
                  <img src="/img/insure-onehundred.svg" alt="store-insurance" />
                </div>
                <div class="text-area">
                  <h3 class="primary">{{ $__t("분실 파손 보험") }}</h3>
                  <p class="secondary">
                    {{
                      $__t("물품의 분실이나 파손 시 최대 100만원 까지 보상해 드립니다.")
                    }}
                  </p>
                </div>
              </div>

              <div class="card flex">
                <div class="image-area">
                  <img src="/img/store-photo.png" alt="store-photo" />
                </div>
                <div class="text-area">
                  <h3 class="primary">{{ $__t("수화물 사진 촬영") }}</h3>
                  <p class="secondary">
                    {{
                      $__t(
                        "물품 사진은 고객이 직접 촬영하며 호스트와 고객에게 공유 됩니다."
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="isLocker" class="section locker-price">
              <h2 class="title">{{ $__t("보관 요금") }}</h2>
              <div class="card">
                <div class="table">
                  <div class="table-header">
                    <div class="table-cell bg-gray black">{{ $__t("구분") }}</div>
                    <div class="table-cell black">S</div>
                    <div class="table-cell bg-gray black">M</div>
                    <div class="table-cell black">L</div>
                    <!-- <div class="table-cell bg-gray black">XL</div> -->
                  </div>
                  <div class="table-row">
                    <div class="table-cell bg-gray black">{{ $__t("기본 2시간") }}</div>
                    <div class="table-cell">1,000{{ $__t("원") }}</div>
                    <div class="table-cell bg-gray">1,500{{ $__t("원") }}</div>
                    <div class="table-cell">2,000{{ $__t("원") }}</div>
                    <!-- <div class="table-cell bg-gray">3,000원</div> -->
                  </div>
                  <div class="table-row">
                    <div class="table-cell bg-gray black">
                      {{ $__t("추가 1시간당") }}
                      <br />
                      ({{ $__t("12시간까지") }})
                    </div>
                    <div class="table-cell">200{{ $__t("원씩") }}</div>
                    <div class="table-cell bg-gray">300{{ $__t("원씩") }}</div>
                    <div class="table-cell">400{{ $__t("원씩") }}</div>
                    <!-- <div class="table-cell bg-gray">500원씩</div> -->
                  </div>
                  <div class="table-row">
                    <div
                      class="table-cell bg-gray black"
                      v-html="$__t('12시간 이후 추가요금')"
                    ></div>
                    <div class="table-cell">1,000{{ $__t("원") }}</div>
                    <div class="table-cell bg-gray">1,500{{ $__t("원") }}</div>
                    <div class="table-cell">2,000{{ $__t("원") }}</div>
                    <!-- <div class="table-cell bg-gray">2,500원</div> -->
                  </div>
                </div>

                <div class="table fixed" style="margin-top: 1rem">
                  <div class="table-header">
                    <div class="table-cell bg-gray black">{{ $__t("구분") }}</div>
                    <div class="table-cell black">S</div>
                    <div class="table-cell bg-gray black">M</div>
                    <div class="table-cell black">L</div>
                    <!-- <div class="table-cell bg-gray black">XL</div> -->
                  </div>
                  <div class="table-row">
                    <div class="table-cell bg-gray black">~ 2{{ $__t("시간") }}</div>
                    <div class="table-cell">1,000{{ $__t("원") }}</div>
                    <div class="table-cell bg-gray">1,500{{ $__t("원") }}</div>
                    <div class="table-cell">2,000{{ $__t("원") }}</div>
                    <!-- <div class="table-cell bg-gray">3,000원</div> -->
                  </div>
                  <div class="table-row">
                    <div class="table-cell bg-gray black">~ 3{{ $__t("시간") }}</div>
                    <div class="table-cell">1,200{{ $__t("원") }}</div>
                    <div class="table-cell bg-gray">1,800{{ $__t("원") }}</div>
                    <div class="table-cell">2,400{{ $__t("원") }}</div>
                    <!-- <div class="table-cell bg-gray">3,500원</div> -->
                  </div>
                  <div class="table-row">
                    <div class="table-cell bg-gray black">
                      ~ 4{{ $__t("시간") }}
                      <p style="line-height: 0.5; padding: 0.25rem 0">·<br />·<br />·</p>
                      ~ 12{{ $__t("시간") }}
                    </div>
                    <div class="table-cell">
                      1,400{{ $__t("원") }}
                      <p style="line-height: 0.5; padding: 0.25rem 0">·<br />·<br />·</p>
                      3,000{{ $__t("원") }}
                    </div>
                    <div class="table-cell bg-gray">
                      2,100{{ $__t("원") }}
                      <p style="line-height: 0.5; padding: 0.25rem 0">·<br />·<br />·</p>
                      4,500{{ $__t("원") }}
                    </div>
                    <div class="table-cell">
                      2,800{{ $__t("원") }}
                      <p style="line-height: 0.5; padding: 0.25rem 0">·<br />·<br />·</p>
                      6,000{{ $__t("원") }}
                    </div>
                    <!-- <div class="table-cell bg-gray">
                      4,000원
                      <p style="line-height: 0.5; padding: 0.25rem 0">·<br />·<br />·</p>
                      8,000원
                    </div> -->
                  </div>
                  <div class="table-row">
                    <div class="table-cell bg-gray black">~ 24{{ $__t("시간") }}</div>
                    <div class="table-cell">4,000{{ $__t("원") }}</div>
                    <div class="table-cell bg-gray">6,000{{ $__t("원") }}</div>
                    <div class="table-cell">8,000{{ $__t("원") }}</div>
                    <!-- <div class="table-cell bg-gray">10,500원</div> -->
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isLocker" class="section locker-guide">
              <h2 class="title">{{ $__t("이용안내") }}</h2>
              <div class="card">
                <p
                  class="locker-guide__text"
                  v-html="
                    $__t(
                      'Please come to your reserved locker within 1 hour from the current time, press the Open Locker button'
                    )
                  "
                ></p>
                <p class="locker-guide__text">
                  {{ $__t("1시간 이내 보관하지 않을 시 예약은 자동 취소됩니다.") }}
                </p>
              </div>
            </div>

            <!--환불규정-->
            <div class="section notice">
              <h2 class="title">{{ $__t("Refund Policy") }}</h2>
              <div class="card flex">
                <div class="text-area">
                  <ul class="refund_list">
                    <li>
                      {{ $__t("'호스트'의 귀책사유로 인해 물품 보관이 불가할 경우") }}:
                      <span class="strong">{{ $__t("100% 환불") }}</span>
                    </li>
                    <li>
                      {{ $__t("예약시 설정한 '보관 시간' 이전") }}:
                      <span class="strong">{{ $__t("100% 환불") }}</span>
                    </li>
                    <li>
                      {{ $__t("예약시 설정한 '보관 시간' 이후") }}:
                      <span class="strong">{{ $__t("환불 불가") }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- <div
              class="section product-list"
              v-if="store.store_name === '하와제주'"
            >
              <h2 class="title">{{ $__t("서비스 상품") }}</h2>
              <div class="card">
                <ul>
                  <li>
                    <div class="product__image--title--price">
                      <div class="product-image">
                        <img src="@/assets/icons/svg/HawaJeju_product.svg" />
                      </div>
                      <div class="product-title-price">
                        <div class="title">
                          썬베드 1인 패키지 [물담배 + 썬베드 +음료1 + 라커]
                          2시간 이용권
                        </div>
                        <div class="price">70,000원</div>
                      </div>
                    </div>
                    <div class="button">
                      <router-link
                        :to="{
                          name: 'StoreSingleService',
                          params: { service_id: 1, store_id: 3358 },
                        }"
                      >
                        <button>
                          <span class="text-area">구매하기</span>
                        </button>
                      </router-link>
                    </div>
                  </li>
                  <li>
                    <div class="product__image--title--price">
                      <div class="product-image">
                        <img src="@/assets/icons/svg/HawaJeju_product.svg" />
                      </div>
                      <div class="product-title-price">
                        <div class="title">
                          썬베드 1인 패키지 [물담배 + 썬베드 +음료1 + 라커]
                          2시간 이용권
                        </div>
                        <div class="price">70,000원</div>
                      </div>
                    </div>
                    <div class="button">
                      <button>
                        <span class="text-area">구매하기</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>

        <div v-if="$route.query.from !== 'ticket' && isStore" class="page-footer">
          <div class="text-area">
            <div class="secondary" @click="openRef('condition-modal')">
              {{ ticketQuantity }}<br />
              {{ usageDateTimeString }}
            </div>
            <div class="primary">
              <strong>{{ calculatedPrice.toLocaleString() }} {{ $__t("won") }}</strong>
            </div>
          </div>
          <div class="button-area">
            <button
              v-button
              type="button"
              class="button bookmark-button"
              @click="onClickAction('bookmark')"
            >
              <img v-if="isInterestedStore" src="@/assets/icons/svg/bookmark-blue.svg" />
              <img v-else src="@/assets/icons/svg/bookmark.svg" />
            </button>
            <button
              type="button"
              class="button submit-button"
              :class="{ activated: calculatedPrice > 0 }"
              @click="calculatedPrice > 0 ? submit() : ''"
            >
              {{ $__t("보관하기") }}
            </button>
          </div>
        </div>

        <div v-if="$route.query.from !== 'ticket' && isLocker" class="page-footer locker">
          <div class="button-area">
            <button
              v-button
              type="button"
              class="button bookmark-button"
              @click="onClickAction('bookmark')"
            >
              <img v-if="isInterestedStore" src="@/assets/icons/svg/bookmark-blue.svg" />
              <img v-else src="@/assets/icons/svg/bookmark.svg" />
            </button>

            <button
              type="button"
              class="button submit-button activated"
              @click="openRef('locker-box-select-slider')"
            >
              {{ $__t("보관함 선택하기") }}
            </button>
          </div>
        </div>
      </div>
    </template>

    <guide-modal id="condition-modal" ref="condition-modal" :transparent="true">
      <template v-slot:body>
        <Condition @close="closeRef('condition-modal')" />
      </template>
    </guide-modal>

    <ui-slider
      id="map-connect-slider"
      ref="map-connect-slider"
      :bgClick="true"
      :background="true"
    >
      <MapConnect :store="store" @close="closeRef('map-connect-slider')" />
    </ui-slider>

    <ui-slider
      id="locker-box-select-slider"
      ref="locker-box-select-slider"
      :background="true"
    >
      <LockerBoxSelector
        ref="box-select"
        :locker="store"
        @close="closeRef('locker-box-select-slider')"
        @submit="onSubmitBoxChoice"
      />
    </ui-slider>
  </div>
</template>

<script>
import utils from "@/utils";
import LugCarousel from "@/components/modules/LugCarousel/Body";
import LugCarouselItem from "@/components/modules/LugCarousel/Item";
import store_facilities from "@/assets/json/store-facilities.json";
import Condition from "@/components/pages/store/StoresSearchCondition";
import Clipboard from "clipboard";
import _ from "lodash";
import BoxSelect from "@/components/includes/Locker/BoxSelect.vue";
import UiSlider from "@/components/modules/Slider/Body.vue";
import MapConnect from "@/components/resources/map/MapConnect.vue";
import LockerBoxSelector from "@/components/resources/locker/LockerReserveBoxSelector.vue";

export default {
  name: "StoreSingle",

  props: ["store_id"],

  data() {
    let defaultStartDateTimeString = utils.getClosestValidDateTime(
      this.$moment().format("YYYY-MM-DD HH:mm")
    );
    let defaultEndDateTimeString = this.$moment(defaultStartDateTimeString)
      .add(10, "m")
      .format("YYYY-MM-DD HH:mm");

    return {
      formData: {
        quantity_big:
          parseInt(this.$route.query.quantity_big) ||
          parseInt(this.$route.params.formData?.quantity_big) ||
          0,
        quantity_small:
          parseInt(this.$route.query.quantity_small) ||
          parseInt(this.$route.params.formData?.quantity_small) ||
          0,
        date_start: {
          label: this.getLabelOfDates(defaultStartDateTimeString),
          value: defaultStartDateTimeString,
        },
        date_end: {
          label: this.getLabelOfDates(defaultEndDateTimeString),
          value: defaultEndDateTimeString,
        },
        selected_box: {
          value: null,
        },
      },

      storeNameHidden: false,
      carouselHeight: 0,
      storeFacilities: store_facilities,
      calculatedPrice: 0,
      headerCovered: false,

      displayWeeks: [
        "SA_MON",
        "SA_TUE",
        "SA_WED",
        "SA_THU",
        "SA_FRI",
        "SA_SAT",
        "SA_SUN",
        "SA_HOL",
      ],

      popupVisibility: {
        map: false,
        share: false,
      },

      clipboard: null,

      availableStore: true,
      locker: null,

      selectedPass: null,
      passAgreed: false,
      storePasses: [],
    };
  },

  watch: {
    storeNameHidden: {
      handler(value) {
        this.wni.execute("wnAppChangeStatusBar", {
          "status-bar-style": "dark",
          "background-color": "#ffffff",
        });
      },
      immediate: true,
    },
  },

  created() {
    this.$store.commit("stores/UNSET_ITEM", {
      data: { id: this.store_id },
    });
    this.setReserveFormStateFromQuery();

    setTimeout(async () => {
      this.loadReserveFormState();
      await this.calculatePrice();

      if (this.$store.state.auth.gid) {
        await this.$store.dispatch("stores/getInterest", {
          id: parseInt(this.store_id),
          list: "MyInterestStores",
        });
      }
    }, 0);
  },

  async mounted() {
    try {
      this.$store.commit("loading/SET_TRUE");
      await this.fetchStore();
      await this.fetchPasses();

      if (this.store && this.store.store_type === "LOCKER") {
        this.locker = this.store.extra.locker;
      }

      await this.$nextTick();
      setTimeout(() => {
        this.setCarouselHeight();
      }, 0);

      window.addEventListener("resize", this.setCarouselHeight);
      window.addEventListener("scroll", this.toggleHeaderTitleVisibility);

      this.amplitudeTracker("created");
    } catch (e) {
      console.error(e);
    } finally {
      const callbackFuncName = this.$route.query.callback;
      const orderType = this.$route.query.type;
      this.$store.commit("loading/SET_FALSE");

      if (callbackFuncName) {
        if (typeof this[callbackFuncName] === "function") {
          this.$store.commit("loading/SET_TRUE", { cover: true });
          let query = { ...this.$route.query };
          delete query.callback;
          delete query.type;
          window.history.replaceState(
            {},
            "",
            this.$route.path + utils.generateQueryString(query)
          );
          await this[callbackFuncName](orderType ?? null);
          this.$store.commit("loading/SET_FALSE");
        }
      }
    }
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener("scroll", this.toggleHeaderTitleVisibility);
    window.removeEventListener("resize", this.setCarouselHeight);
  },

  computed: {
    languageSetting() {
      return localStorage.getItem("lugstay_language");
    },
    // storeName() {
    //   if (this.languageSetting === "ko") {
    //     return this.store?.store_name_locales?.ko;
    //   } else {
    //     if (!this.store?.store_name_locales?.base) {
    //       return this.store?.store_name_locales?.ko;
    //     }
    //     return this.store?.store_name_locales?.base;
    //   }
    // },
    isLocker() {
      return this.store?.store_type === "LOCKER";
    },

    isStore() {
      return !this.isLocker || this.store?.store_type === "STORE";
    },

    reserveFormState() {
      return this.$store.getters["resourceForm/GET_ITEM"]({
        key: "form_id",
        value: "reserve_form",
      });
    },

    isReserveFormStateValid() {
      return (
        this.reserveFormState?.date_start &&
        this.reserveFormState?.date_end &&
        (this.reserveFormState?.quantity_big || this.reserveFormState?.quantity_small)
      );
    },

    myPasses() {
      return this.$store.getters["resourceProducts/GET_ITEMS_BY_LIST"]({
        list: "MyPasses",
      });
    },

    store() {
      return this.$store.getters["stores/GET_ITEM"]({
        key: "id",
        value: parseInt(this.store_id),
      });
    },

    isInterestedStore() {
      return !!this.$store.getters["stores/GET_ITEMS_BY_LIST"]({
        list: "MyInterestStores",
      }).find((store) => store.id === parseInt(this.store_id));
    },

    usageTimeHeader() {
      let dateDiff = this.$moment(this.formData.date_start.value).diff(
        this.$moment(this.formData.date_end.value)
      );

      let minutes = Math.abs(this.$moment.duration(dateDiff).minutes());
      let hours = Math.abs(this.$moment.duration(dateDiff).hours());
      let days = Math.floor(Math.abs(this.$moment.duration(dateDiff).asDays()));

      let value = "";

      if (days > 0) {
        value += " " + days + this.$__t("d");
      }

      if (hours > 0) {
        value += " " + hours + this.$__t("_h");
      }

      if (minutes > 0) {
        value += " " + minutes + this.$__t("m");
      }

      return this.$__t("total") + value + " " + this.$__t("use");
    },

    usageTimeText() {
      const momentStartDate = this.$moment(this.formData.date_start.value);
      const momentEndDate = this.$moment(this.formData.date_end.value);

      let value = "";

      if (momentStartDate.format("YYYY-MM-DD") === this.$moment().format("YYYY-MM-DD")) {
        if (momentStartDate.format("YYYY-MM-DD") === momentEndDate.format("YYYY-MM-DD")) {
          value +=
            this.$__t("{date-time-picker}.today") +
            " " +
            momentStartDate.format("HH:mm") +
            " ~ " +
            momentEndDate.format("HH:mm");
        } else {
          value +=
            this.$__t("{date-time-picker}.today") +
            " " +
            momentStartDate.format("HH:mm") +
            " ~ " +
            momentEndDate.format("MM/DD HH:mm");
        }
      } else if (
        momentStartDate.format("YYYY-MM-DD") !== momentEndDate.format("YYYY-MM-DD")
      ) {
        value +=
          momentStartDate.format("MM/DD HH:mm") +
          " ~ " +
          momentEndDate.format("MM/DD HH:mm");
      } else {
        value +=
          momentStartDate.format("MM/DD HH:mm") + " ~ " + momentEndDate.format("HH:mm");
      }

      value += `${!!this.ticketQuantity ? " / " + this.ticketQuantity : ""}`;

      return value;
    },

    ticketQuantity() {
      let value = "";

      if (this.formData.quantity_small > 0) {
        // let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.formData.quantity_small}`;
        let quantityString = `${this.$__t("물품")} ${this.formData.quantity_small}`;

        if (this.formData.quantity_small === 1) {
          value += quantityString + this.$__t("item");
        } else {
          value += quantityString + this.$__t("items");
        }
      }

      if (this.formData.quantity_big > 0) {
        let quantityString = `${this.$__t("{luggage-selector}.big")} ${
          this.formData.quantity_big
        }`;

        if (this.formData.quantity_big > 0) quantityString = " " + quantityString;

        if (this.formData.quantity_big === 1) {
          value += quantityString + this.$__t("item");
        } else {
          value += quantityString + this.$__t("items");
        }
      }

      return value;
    },

    storePrimaryImage() {
      return (
        this.store?.resources?.thumbnail_image?.resource_url ||
        (this.store?.resources?.thumbnail_image?.resource_thumbnail_url || "").replace(
          "/thumb/",
          "/"
        )
      );
    },

    storeSecondaryImages() {
      let sources = [];

      const DETAIL_IMAGES = this.store?.resources?.detail_images;
      const DIRECTIONS_IMAGES = this.store?.resources?.directions_images;

      [DETAIL_IMAGES, DIRECTIONS_IMAGES].forEach((imageArray) =>
        imageArray
          ? imageArray.forEach((image) =>
              image?.resource_url ? sources.push(image.resource_url) : {}
            )
          : {}
      );

      return sources;
    },

    usageDateTimeString() {
      const momentDateStart = this.$moment(this.formData.date_start.value);
      const momentDateEnd = this.$moment(this.formData.date_end.value);
      const momentNow = this.$moment();

      let text = "";

      if (momentDateStart.format("YYYY-MM-DD") === momentDateEnd.format("YYYY-MM-DD")) {
        if (momentNow.format("YYYY-MM-DD") === momentDateStart.format("YYYY-MM-DD")) {
          text = `${this.$__t("{date-time-picker}.today")} ${momentDateStart.format(
            "HH:mm"
          )} - ${momentDateEnd.format("HH:mm")}`;
        } else {
          text = `${momentDateStart.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateStart.format("ddd").toUpperCase()
          )}) ${momentDateStart.format("HH:mm")} - ${momentDateEnd.format("HH:mm")}`;
        }
      } else {
        if (momentNow.format("YYYY-MM-DD") === momentDateStart.format("YYYY-MM-DD")) {
          text = `${this.$__t("{date-time-picker}.today")} ${momentDateStart.format(
            "HH:mm"
          )} - ${momentDateEnd.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateEnd.format("ddd").toUpperCase()
          )}) ${momentDateEnd.format("HH:mm")}`;
        } else {
          text = `${momentDateStart.format("MM/DD")}(${this.$__t(
            "SSA_" + momentDateStart.format("ddd").toUpperCase()
          )}) ${momentDateStart.format("HH:mm")} - ${momentDateEnd.format(
            "MM/DD"
          )}(${this.$__t(
            "SSA_" + momentDateEnd.format("ddd").toUpperCase()
          )}) ${momentDateEnd.format("HH:mm")}`;
        }
      }

      return text;
    },

    datesAvailable() {
      return this.$moment(this.formData.date_start.value).isBefore(this.$moment());
    },

    filteredPasses() {
      // console.log('this.storePasses.length', this.storePasses[0]);
      if (
        this.myPasses.length > 0 &&
        this.storePasses.length > 0 &&
        this.storePasses[0] !== null
      ) {
        return this.myPasses.filter(
          (o) =>
            (o.status === "PREPARED" || o.status === "STARTED") &&
            this.storePasses.find((p) => {
              // console.log('p', p);
              p.id === o.product_id || p._id === o.product_id;
            }) &&
            this.$moment().isSameOrBefore(this.$moment(o.pass_date_end))
        );
      } else {
        return [];
      }
    },

    elevatorText() {
      if (!this.store) return null;

      if (Number(this.store.floor) === 1) return null;

      let text = this.$__t("SF_ELEVATOR") + " ";

      text +=
        this.store?.facilities?.toLowerCase().indexOf("elevator") > -1
          ? this.$__t("있음")
          : this.$__t("없음");

      return text;
    },

    facilities() {
      return [
        this.elevatorText,
        this.store.store_type === "STORE" ? "Store" : "Locker",
        this.store.store_category,
        this.store.floor ? this.store.floor + this.$__t("층") : null,
      ].filter((_) => !!_);
    },
  },

  methods: {
    setReserveFormStateFromQuery() {
      let payloadData = Object.assign(
        {},
        { form_id: "reserve_form" },
        this.$route.query.date_start
          ? { date_start: this.$route.query.date_start }
          : null,
        this.$route.query.date_end ? { date_end: this.$route.query.date_end } : null,
        this.$route.query.quantity_small
          ? { quantity_small: parseInt(this.$route.query.quantity_small) }
          : null,
        this.$route.query.quantity_big
          ? { quantity_big: parseInt(this.$route.query.quantity_big) }
          : null
      );

      this.$store.commit("resourceForm/SET_ITEM", {
        data: payloadData,
      });
    },

    loadReserveFormState() {
      if (!this.isReserveFormStateValid) {
        this.formData.quantity_small = 0;
        this.formData.quantity_big = 0;
        return;
      }

      this.formData.search_text = this.reserveFormState.search_text;
      this.formData.quantity_big = parseInt(this.reserveFormState.quantity_big) || 0;
      this.formData.quantity_small = parseInt(this.reserveFormState.quantity_small) || 0;
      this.formData.date_start.label =
        this.reserveFormState.date_start?.label || this.formData.date_start.label;
      this.formData.date_start.value =
        this.reserveFormState.date_start?.value ||
        this.reserveFormState.date_start ||
        this.formData.date_start;
      this.formData.date_end.label =
        this.reserveFormState.date_end?.label || this.formData.date_end.label;
      this.formData.date_end.value =
        this.reserveFormState.date_end?.value ||
        this.reserveFormState.date_end ||
        this.formData.date_end;
    },

    async fetchStore() {
      let res = null;
      try {
        res = await this.$store.dispatch("stores/get", {
          id: parseInt(this.store_id),
        });
      } catch (e) {
        if (e.message.includes("NO_EXISTS_DATA")) {
          this.$router.replace("/");
          this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
        }
      }

      return res;
    },

    setCarouselHeight() {
      let height = 0;

      try {
        height = this.$refs.body?.clientWidth * 0.90625;
      } catch (e) {
        height = window.innerWidth > 480 ? 480 * 0.90625 : window.innerWidth * 0.90625;
      }

      this.carouselHeight = height;
    },

    getLabelOfDates(dateString) {
      let date = this.$moment(dateString);
      let weekdays = [
        this.$__t("SSA_SUN"),
        this.$__t("SSA_MON"),
        this.$__t("SSA_TUE"),
        this.$__t("SSA_WED"),
        this.$__t("SSA_THU"),
        this.$__t("SSA_FRI"),
        this.$__t("SSA_SAT"),
      ];

      let formattedDate = date.format("MM/DD");
      let day = date.day();

      if (date.format("MM/DD") === this.$moment().format("MM/DD")) {
        formattedDate = this.$__t("{date-time-picker}.today");
      } else {
        formattedDate += " (" + weekdays[day] + ")";
      }

      return formattedDate;
    },

    displayTimeTable(timeTableItem) {
      let displayValue = "";

      if (timeTableItem) {
        displayValue += timeTableItem[0];

        let endHour = timeTableItem[1].split(":")[0];
        let endMin = timeTableItem[1].split(":")[1];

        if (endHour > 24) {
          endHour -= 24;
          if (endHour < 10) endHour = "0" + endHour;
        }

        displayValue += "~" + endHour + ":" + endMin;
      } else {
        displayValue = this.$__t("Day Off");
      }

      return displayValue;
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeader = $(this.$refs.header);
      const $storeName = $(this.$refs["store-name"]);

      this.storeNameHidden =
        $storeName.offset().top + $storeName.outerHeight() <
        window.scrollY + $pageHeader.outerHeight();
    },

    onClickAction(action) {
      if (action === "phone") {
        this.$analytics.logEvent("store call");
        location.href = "tel:+82-218779727";
      } else if (action === "bookmark") {
        this.$analytics.logEvent("save store");
        if (!this.$store?.state?.auth?.gid) {
          return this.$router.push({
            name: "AuthLogin",
            query: {
              redirect: location.origin + "/stores/" + this.store_id,
              queryString: { ...this.formData },
            },
          });
        }
        this.$store
          .dispatch(`stores/${this.isInterestedStore ? "delete" : "add"}Interest`, {
            id: parseInt(this.store_id),
            list: "MyInterestStores",
          })
          .then((res) => {
            if (Boolean(res.interested) === true) {
              this.$store.commit("notice/ADD_ITEM", {
                message: this.$__t("관심상점으로 저장이 완료되었습니다."),
                icon: "/img/icon-bookmark-blue.svg",
              });
            }
          });
      } else if (["map", "share"].includes(action)) {
        Object.keys(this.popupVisibility).forEach(
          (o) => (this.popupVisibility[o] = o === action ? true : false)
        );

        setTimeout(() => {
          this.clipboard = new Clipboard(".copy-btn");
          this.clipboard.on("success", (e) => {
            e.clearSelection();
            this.$store.commit("notice/ADD_ITEM", {
              message: this.$__t("복사되었습니다."),
            });
          });
        }, 100);
      }

      return;
    },

    async calculatePrice() {
      if (this.store?.store_type === "LOCKER") {
        let priceBySize = 1000;

        switch (this.formData.selected_box.value?.locker_size) {
          case "S":
            priceBySize = 1000;
            break;
          case "M":
            priceBySize = 1500;
            break;
          case "L":
            priceBySize = 2000;
            break;
          case "XL":
            priceBySize = 3000;
            break;
          default:
            priceBySize = 0;
        }

        return (this.calculatedPrice = priceBySize);
      }

      // let total_base_price = this.formData.quantity_small * 2000 + this.formData.quantity_big * 3000;
      let total_base_price = this.formData.quantity_small * 2500;
      let total_additional_price = 0;

      const duration = this.$moment.duration(
        this.$moment(this.formData.date_end.value).diff(
          this.$moment(this.formData.date_start.value)
        )
      );

      let durationMinutes = duration.asMinutes();
      let durationDays = duration.asDays();

      if (durationMinutes - 60 <= 0) {
        // 1시간 이전
        total_additional_price = 0;
      } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
        // 1시간 이후 7시간 30분 이전
        //가격정책 변경 전
        // total_additional_price =
        //   100 * Math.ceil((durationMinutes - 60) / 10) * this.formData.quantity_small +
        //   150 * Math.ceil((durationMinutes - 60) / 10) * this.formData.quantity_big;

        //가격정책 변경 후
        total_additional_price =
          150 * Math.ceil((durationMinutes - 60) / 10) * this.formData.quantity_small;
      } else if (450 < durationMinutes && durationMinutes <= 1440) {
        //7시간 30분 초과 24시간 이하
        //가격정책 변경 전
        // total_additional_price = 6000 * this.formData.quantity_small + 9000 * this.formData.quantity_big - total_base_price;

        //가격정책 변경 후
        total_additional_price = 8500 * this.formData.quantity_small - total_base_price;
      } else if (1440 < durationMinutes) {
        //24시간 초과
        //가격정책 변경 전
        // total_additional_price =
        //   (6000 + Math.ceil(durationDays - 1) * 4000) * this.formData.quantity_small +
        //   (9000 + Math.ceil(durationDays - 1) * 6000) * this.formData.quantity_big -
        //   total_base_price;

        //가격정책 변경 후
        total_additional_price =
          (8500 + Math.ceil(durationDays - 1) * 5000) * this.formData.quantity_small -
          total_base_price;
      }

      let price = total_base_price + total_additional_price;

      this.calculatedPrice = isNaN(price) ? 0 : price;
    },

    onClickMapAction(method) {
      if (method === "copy") {
        this.$analytics.logEvent("link to map", { "link to": "url" });
        this.$copyText(this.store.store_address).then((e) => {
          this.$store.commit("notice/ADD_ITEM", {
            message: this.$__t("복사되었습니다."),
          });
        }, {});
      } else {
        this.$analytics.logEvent("link to map", { "link to": method });

        switch (method) {
          case "naver":
            this.openNaverMap(
              `nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`
            );
            break;

          case "google":
            if (this.wni.isNative) {
              this.wni.execute("wnOpenURL", {
                url: `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,
              });
            } else {
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,
                "_blank"
              );
            }
            break;
        }
      }
    },

    onClickShareAction(method) {
      if (method === "copy") {
        this.$analytics.logEvent("share store information", {
          "link to": "url",
        });
        this.$copyText(location.href).then((e) => {
          this.$store.commit("notice/ADD_ITEM", {
            message: this.$__t("복사되었습니다."),
          });
        }, {});
      } else {
        this.executeShare(method);
      }
    },

    openNaverMap(url) {
      const encodedUrl = encodeURI(url);

      if (this.wni.isNative) {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          this.wni.execute("wnOpenURL", { url: url });
          setTimeout(() => {
            if (!document.hidden) {
              const _url =
                url.replace("nmap://", "intent://") +
                "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
              return this.wni.execute("wnOpenURL", { url: _url });
            }
          }, 2000);
        }
      } else {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          url =
            url.replace("nmap://", "intent://") +
            "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
          location.href = url;
        } else {
          location.href = url;
        }
      }
    },

    openNaverMapInIphoneBrowser(url) {
      this.$store.commit("loading/SET_TRUE");

      window.location.href = url;

      setTimeout(() => {
        try {
          if (!document.hidden) {
            throw new Error("1102:NO_EXISTS_DATA");
          }
        } catch (e) {
          let confirmed = confirm(
            this.$__t("Naver map is not installed, so go to the App Store.")
          );

          if (confirmed) {
            location.href = "http://itunes.apple.com/app/id311867728?mt=8";
          }
        } finally {
          this.$store.commit("loading/SET_FALSE");
        }
      }, 1000);
    },

    executeShare(method) {
      let shareURL = "";

      switch (method) {
        case "naver-blog":
          this.$analytics.logEvent("share store information", {
            "link to": "naver",
          });
          shareURL =
            "http://blog.naver.com/openapi/share?url=" +
            location.origin +
            location.pathname;
          break;

        case "line":
          this.$analytics.logEvent("share store information", {
            "link to": "line",
          });
          shareURL = "http://line.me/R/msg/text?" + location.origin + location.pathname;
          break;

        case "facebook":
          this.$analytics.logEvent("share store information", {
            "link to": "facebook",
          });
          shareURL =
            "https://www.facebook.com/sharer/sharer.php?u=" +
            location.origin +
            location.pathname;
          break;
      }

      if (this.wni.isNative) {
        this.wni.execute("wnOpenURL", { url: shareURL });
      } else {
        window.open(shareURL);
      }
    },

    facilityExists(key) {
      if (key === "SF_CCTV") return true;
      if (key === "SF_24HOUR" && !!this.store?.extra?.time_24hour) return true;
      if (key === "SF_ENGLISH" && this.store.multilingual_service?.includes("SL_EN"))
        return true;

      const STORE_SF = this.store?.facilities;
      const STORE_SE = this.store?.etc_service;

      let targetString = STORE_SF
        ? STORE_SF + (STORE_SE ? STORE_SE : "")
        : STORE_SE
        ? STORE_SE
        : "";

      const targetExists = targetString.toLowerCase().indexOf(key.toLowerCase()) > -1;

      return targetExists
        ? true
        : key === "SF_FITTING_ROOM" &&
            targetString.toLowerCase().indexOf("SF_FITTIN_GROOM".toLowerCase()) > -1;
    },

    amplitudeTracker(which) {
      let store_location = this.store.store_address.split(" ");

      if (store_location[0] === "경기")
        store_location = store_location[0] + " " + store_location[1];
      else store_location = store_location[0];

      switch (which) {
        case "created":
          this.$analytics.logEvent("store detail page view", {
            "store id": parseInt(this.store_id),
            "store name": this.store.store_name,
            "store location": store_location,
          });
          break;

        case "reserve":
          this.$analytics.logEvent("booking button", {
            "store id": parseInt(this.store_id),
            "store name": this.store.store_name,
            "store location": store_location,
          });
          break;
      }
    },

    async onSubmitBoxChoice(box) {
      this.$store.commit("loading/SET_TRUE");
      try {
        await this.onBoxSelect(box);
        await this.submit("DELAYED");
      } catch (e) {
        console.error(e);
        this.$refs["box-select"].setAvailability(false);
      } finally {
        this.$store.commit("loading/SET_FALSE");
      }
    },

    async submit(type) {
      // 보관하기 클릭 시,
      try {
        this.$analytics.logTrack("track", "InitiateCheckout");
        this.amplitudeTracker("reserve");

        if (!this.$store.state.auth.gid) {
          let queryString = utils.generateQueryString({
            date_start: this.formData.date_start.value,
            date_end: this.formData.date_end.value,
            quantity_small: this.formData.quantity_small,
            quantity_big: this.formData.quantity_big,
            locker_id: this.formData.selected_box?.value?.id,
            locker_size: this.formData.selected_box?.value?.locker_size,
            callback: "order",
            type: type,
          });

          return this.$router.push({
            name: "AuthLogin",
            query: { redirect: this.$router.currentRoute.path + queryString },
          });
        }

        let data = await this.$store.dispatch("orders/check", {
          type: this.store.store_type === "LOCKER" ? "locker" : "luggage",
          query: {
            store_id: this.store_id,
            start_date: this.formData.date_start.value,
            end_date: this.formData.date_end.value,
            quantity: this.formData.quantity_small,
            over_quantity: this.formData.quantity_big,
            locker_size: this.formData.selected_box?.value?.locker_size,
            locker_id: this.formData.selected_box?.value?.id,
          },
        });
        // console.log('data', data);
        this.storePasses = data.products || [];
        // console.log('this.storePasses', this.storePasses);
        // this.storePasses = [{ id: 1, pass_code: 'FFPER' }, { id: 2, pass_code: 'GVIXR' }]
        // console.log('this.filteredPasses', this.filteredPasses);
        if (this.filteredPasses.length > 0) {
          this.selectedPass = this.filteredPasses[0];

          return this.$refs["pass-slider"].open();
        }
        // }

        await this.order(type); // 여기서 order함수 실행
      } catch (e) {
        console.error(e);
      }
    },

    async fetchPasses() {
      if (!this.$store.state.auth.gid) return;

      try {
        return await this.$store.dispatch("resourceProducts/getMyList", {
          list: "MyPasses",
          query: {
            page: 1,
            size: 10,
            group: "available",
            type: "FREE_PASS",
          },
        });
      } catch (e) {
        this.$log.error(e);
      }
    },

    order(type, options = {}) {
      const { pass } = options;

      this.$store.commit("loading/SET_TRUE");

      return new Promise((resolve, reject) => {
        if (this.store.status !== "PUBLIC") {
          reject("해당 상점은 예약이 불가능한 상점입니다.");
        }

        setTimeout(() => {
          let payloadData = {
            store_id: this.store_id,
            start_date: this.formData.date_start.value,
            end_date: this.formData.date_end.value,
            quantity: this.formData.quantity_small,
            over_quantity: this.formData.quantity_big,
          };

          if (this.store?.store_type === "LOCKER") {
            if (
              !this.formData.selected_box?.value?.locker_number &&
              this.$route.query.selected_box
            ) {
              reject(
                this.$store.commit("alert/ADD_ITEM", {
                  status: "error",
                  message: "보관함을 선택해주세요.",
                })
              );
            }

            delete payloadData.over_quantity;
            delete payloadData.start_date;
            delete payloadData.end_date;

            payloadData.type = type ? type.toUpperCase() : null;
            payloadData.locker_id = this.formData.selected_box?.value?.id || this.$route.query.locker_id;
            payloadData.locker_size = this.formData.selected_box?.value?.locker_size || this.$route.query.locker_size;
            payloadData.quantity = 1;
            payloadData.start_datetime = this.$moment().format("YYYY-MM-DD HH:mm");
            payloadData.order_type = type;
          }

          this.$store
            .dispatch("orders/new", {
              type: this.store?.store_type === "LOCKER" ? "locker" : "luggage",
              data: payloadData,
            })
            .then(resolve)
            .catch(reject);
        }, 1000);
      })
        .then((res) => {
          this.$store.commit("loading/SET_FALSE");

          let query = {
            "order-type": type,
          };

          if (type === "locker") {
            query["box-price"] = this.calculatedPrice;
          }

          if (pass) {
            query["pass-code"] = pass.pass_code;
          }

          this.$router.push({
            name: this.store?.store_type === "STORE" ? "ReserveOrder" : "LockerReserve",
            params: { order_id: res?.data?.data?.reserve_id },
            query,
          });
        })
        .catch((e) => {
          console.log( e );

          if (e.data) {
            const error = e.data.error || '현재는 예약할 수 없습니다.';

            this.$store.commit("alert/ADD_ITEM", {
              message: this.$__t(error),
              status: "error",
            });
          }
          else {
            this.$store.commit("alert/ADD_ITEM", {
              message: this.$__t(e),
              status: "error",
            });
          }
        })
        .finally(() => {
          this.$store.commit("loading/SET_FALSE");
        });
    },

    openSidebar() {
      this.$parent.toggleSidebar();
    },

    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    onClickBack() {
      this.$router.go(-1);
    },

    async onBoxSelect(box) {
      if (!box) {
        this.calculatedPrice = 0;
        return (this.formData.selected_box.value = box);
      }

      this.formData.selected_box.value = box;

      // if (!this.$store.state.auth.gid) {
      //   let queryString = utils.generateQueryString({
      //     date_start: this.formData.date_start.value,
      //     date_end: this.formData.date_end.value,
      //     quantity_small: this.formData.quantity_small,
      //     quantity_big: this.formData.quantity_big,
      //     selected_box: this.formData.selected_box.value,
      //   });

      //   return this.$router.push({
      //     name: 'AuthLogin',
      //     query: { redirect: this.$router.currentRoute.fullPath + queryString },
      //   });
      // }

      const query = {
        store_id: this.store_id,
        locker_id: box.id,
        locker_size: box.locker_size,
      };

      await this.$store.dispatch("orders/check", {
        type: this.store.store_type,
        query,
      });

      await utils.wait(500);

      this.$refs["box-select"].setAvailability(true);

      await this.calculatePrice();
    },

    boxSizeText(size) {
      return size + " 사이즈";
    },
  },

  components: {
    LugCarousel,
    LugCarouselItem,
    Condition,
    BoxSelect,
    UiSlider,
    MapConnect,
    LockerBoxSelector,
  },
};
</script>

<style lang="scss" scoped>
.page.store-single {
  @include container("& > .container", $max-content-width);

  * {
    @include no-selection-highlight;
  }

  & > .container {
    background-color: #f1f5f5;

    .site-header {
      background-color: transparent;
      position: fixed;

      .title {
        margin: unit(20) auto auto auto;
        color: $color-white;
      }

      &--white {
        background-color: $color-white;
        box-shadow: 0px unit(4) unit(12) rgba(0, 0, 0, 0.16),
          unit(4) unit(8) unit(28) rgba(0, 0, 0, 0.08);

        .title {
          color: $color-black;
        }
      }
    }

    .page-body {
      .store-images {
        .lug-carousel::v-deep {
          .lug-carousel-viewport {
            height: 100% !important;

            .lug-carousel-list {
              padding: 0;

              .lug-carousel-item {
                height: 100%;
              }
            }

            .lug-carousel-pager-zoomable {
              & > * {
                margin: 0 unit(7);
                bottom: unit(30);
                width: unit(8) !important;
                height: unit(8) !important;
              }

              .on {
                background-color: #1890ff;
                border-radius: 50% !important;
              }
            }
          }
        }

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 37%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.7) -21.26%,
            rgba(0, 0, 0, 0) 68.81%
          );
          z-index: 1;
        }
      }

      .usage-time-area {
        height: 0;

        .time-banner-area {
          bottom: 0;
          width: calc(100% - #{unit(32)});
          transform: translateY(-50%);
          margin: auto;
        }
      }

      .store-info {
        padding: 0;

        .section {
          & + .section {
            margin-top: unit(8);
          }

          h1 {
            font-weight: 500;
            font-size: unit(20);
            line-height: 1.7;
            color: $color-gray-9;
          }

          h2 {
            font-size: unit(14);
            line-height: 1.6;
            color: $color-gray-9;
            margin-bottom: unit(6);
            // font-weight: 500;
          }

          h3 {
            font-size: unit(15);
            line-height: 1.5;
            color: $color-gray-9;
            font-weight: 500;
          }

          &.store-info {
            padding: unit(20) unit(16);
            background: $color-white;
            border-radius: 0px 0px unit(20) unit(20);

            .section-body {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: unit(16);

              .store-name {
                font-weight: bold;
                font-size: unit(16);
                line-height: 1.38;
                letter-spacing: -0.006em;
              }
              .store-address {
                color: #61676c;
                font-size: unit(12);
              }
              .button-area {
                display: flex;
                gap: unit(8);

                .store-info__button {
                  width: unit(40);
                  height: unit(40);
                  border: 1px solid #e1e4e6;
                  box-sizing: border-box;
                  border-radius: unit(100);
                  background: $color-white;

                  .icon {
                    width: unit(24);
                    height: unit(24);
                    object-fit: contain;
                    line-height: unit(40);
                    margin: auto;
                  }
                }
              }
            }

            .section-footer {
              margin-top: unit(12);

              .facility-list {
                width: 100%;
                overflow-x: auto;
                scroll-behavior: smooth;
              }

              .facility-item {
                background: rgba(0, 143, 255, 0.06);
                border-radius: unit(8);
                margin-right: unit(8);
                display: inline-block;
                width: auto;
                padding: unit(2) unit(6);
                font-size: unit(12);
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: -0.006em;
                color: #61676c;

                &:last-of-type {
                  margin: 0;
                }
              }
            }
          }

          &.time-table {
            margin-top: unit(12);
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .card {
              background: #ffffff;
              border-radius: unit(20);
              padding: unit(22) unit(16);
            }

            .table-row {
              & + .table-row {
                .table-cell {
                  border-top: solid 1px #e1e4e6;
                }
              }

              .table-cell {
                padding: unit(10) 0;
                text-align: left;
                font-weight: 400;
                font-size: unit(14);
                line-height: 1.43;
                letter-spacing: -0.006em;

                .day {
                  text-align: left;
                  font-weight: 500;
                  font-size: unit(14);
                  line-height: 1.43;
                  letter-spacing: -0.006em;
                  color: #292a2b;
                }

                &.red {
                  color: #ff5d5d;
                }
              }

              &:first-of-type {
                .table-cell {
                  padding-top: 0;
                }
              }

              &:last-of-type {
                .table-cell {
                  padding-bottom: 0;
                }
              }
            }
          }

          &.facility {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .card {
              background: #ffffff;
              border-radius: unit(20);
              padding: unit(18) unit(16);

              .facility-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .facility-item {
                  width: calc((100% - #{unit(12)}) / 2);
                  display: flex;
                  align-items: flex-start;
                  margin: unit(12) 0;

                  img {
                    flex: 0 0 unit(18);
                    width: unit(18);
                    height: auto;
                    display: inline-block;
                    top: 0;

                    & + * {
                      margin-left: unit(4);
                    }
                  }

                  .text {
                    flex: 1;
                    font-size: unit(14);
                    line-height: 150%;
                    color: #a9afb3;
                  }

                  &.activated {
                    .text {
                      color: $color-black;
                    }
                  }
                }
              }
            }
          }

          &.notice {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .image-area {
              width: unit(60);
              height: unit(60);
              flex: 0 0 unit(60);
            }

            .text-area {
              padding-left: unit(8);

              .primary {
                @include ellipsis(1);
                font-size: unit(14);
                font-weight: 500;
              }

              .secondary {
                margin-top: unit(2);
                @include ellipsis(2);
                font-size: unit(14);
                line-height: 1.43;
                color: $color-gray-9;
              }
              .refund_list {
                font-size: unit(14);
                line-height: 1.43;
                .strong {
                  font-weight: bold;
                }
              }
            }

            .card + .card {
              margin-top: unit(10);
            }
          }

          &.locker-price {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .table {
              border-collapse: collapse;
              table-layout: fixed;
              width: 100%;

              .table-header {
                .table-cell {
                  height: unit(44);
                  vertical-align: middle;
                  // border: solid 1px #dbdbdb;
                }
              }

              .table-row {
                & > .table-cell:first-of-type {
                  min-width: unit(70);
                }

                &:last-child {
                  border-bottom: solid 1px #eaeeef;
                }
              }

              .table-cell {
                border-top: solid 1px #eaeeef;
                border-right: solid 1px #eaeeef;
                vertical-align: middle;
                font-weight: 400;
                font-size: unit(10);
                line-height: 1.5;
                height: unit(44);
                color: #61676c;
                text-align: center;
                letter-spacing: -0.006em;
                word-wrap: break-word;
                word-break: keep-all;
                padding: unit(8) unit(2);

                &:first-of-type {
                  border-left: solid 1px #eaeeef;
                }

                &.bg-gray {
                  background: #f1f5f5;
                }

                &.black {
                  color: $color-black;
                  font-weight: 500;
                }
              }
            }
          }

          &.locker-guide {
            padding: unit(16);

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }

            .locker-guide__text::v-deep {
              font-weight: 400;
              font-size: unit(14);
              line-height: 1.43;

              strong {
                font-weight: bold;
              }

              & + .locker-guide__text {
                margin-top: unit(28);
              }
            }
          }
          &.product-list {
            padding: unit(16);

            ul {
              li {
                margin-bottom: unit(20);

                .product__image--title--price {
                  display: flex;

                  .product-image {
                    width: unit(68);
                    height: unit(68);
                    border-radius: 24px;
                    flex: 0 0 unit(68);
                  }
                  .product-title-price {
                    padding-left: unit(15);

                    .title {
                      font-size: unit(14);
                      font-weight: 400;
                      line-height: unit(20);
                      padding-left: 0;
                    }
                    .price {
                      font-size: unit(16);
                      font-weight: 600;
                    }
                  }
                }
                .button {
                  display: flex;
                  justify-content: flex-end;

                  button {
                    text-align: center;
                    background-color: #48d9eb;
                    border-radius: 24px;
                    width: unit(130);
                    height: unit(32);
                    .text-area {
                      color: white;
                    }
                  }
                }
              }
            }

            .title {
              font-size: unit(16);
              font-weight: bold;
              line-height: 1.38;
              letter-spacing: -0.006em;
              padding-left: unit(12);
            }
          }

          .card {
            padding: unit(22) unit(16);
            background-color: $color-white;
            border-radius: unit(20);

            &.table {
              display: table;
              table-layout: fixed;
              width: 100%;

              .table-row {
                display: table-row;
              }

              .table-cell {
                display: table-cell;
              }
            }

            &.flex {
              display: flex;
            }
          }
        }
      }
    }

    .page-footer {
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      max-width: $max-content-width;
      bottom: 0;
      margin-top: unit(44);
      box-shadow: 0px unit(1) unit(5) #a9afb3;
      border-radius: unit(10) unit(10) 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: unit(140);
      padding: unit(20) unit(16) unit(16) unit(16);
      background: #ffffff;
      box-shadow: 0px unit(4) unit(12) rgba(0, 0, 0, 0.16),
        unit(4) unit(8) unit(28) rgba(0, 0, 0, 0.08);
      border-radius: unit(20) unit(20) 0px 0px;
      z-index: 2;

      .text-area {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;
        gap: unit(16);
        flex: 0 0 unit(40);

        * {
          font-size: unit(11);
          line-height: unit(16);
        }

        .primary {
          color: $color-gray-9;

          strong {
            font-weight: 500;
            color: $color-black;
            font-weight: 700;
            font-size: unit(16);
            line-height: 1.38;
            /* or 138% */
            letter-spacing: -0.006em;
          }
        }

        .secondary {
          font-size: unit(14);
          font-weight: 400;
          line-height: 1.43;
          text-decoration-line: underline;
          color: #61676c;
        }
      }

      .button-area {
        display: flex;
        align-items: stretch;
        gap: unit(12);
        width: 100%;
        height: unit(50);
        margin-top: unit(14);

        .button {
          &.bookmark-button {
            flex: 0 0 unit(46);
            background: $color-white;
            border: 1px solid #e1e4e6;
            border-radius: unit(16);

            img {
              width: unit(24);
              height: unit(24);
              object-fit: contain;
              margin: auto;
            }
          }

          &.submit-button {
            flex: 1;
            padding: unit(15) unit(16);
            color: $color-white;
            background: #a9afb3;
            border-radius: unit(16);
            font-size: unit(14);
            line-height: 1.43;
            font-weight: bold;

            &.activated {
              background-color: #48d9eb;
              cursor: pointer;
            }
          }
        }
      }

      &.locker {
        display: block;
        height: unit(86);
        padding: unit(20) unit(16);

        .button-area {
          margin: 0;

          .submit-button {
            background: $color-primary;
          }
        }
      }
    }
  }
}

.site-header {
  .header__button--right {
    img {
      width: unit(24) !important;
      height: unit(24) !important;
      object-fit: contain;
      margin: auto;
    }
  }
}

.lug-carousel::v-deep {
  .lug-carousel-slide-bar {
    width: unit(120) !important;
    bottom: unit(14);
    border-radius: unit(18);

    span {
      border-radius: 0;

      &.on {
        border-radius: unit(18);
      }
    }
  }
}
</style>
