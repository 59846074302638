<template>
  <div id="price">
    <div class="container">
      <div class="page-header">
        <div class="container">
          <div class="page-header__title">{{ $__t('price guide') | capitalizeFirstLetter }}</div>
        </div>
      </div>

      <div class="page-body">
        <div class="container">
          <div class="pricing">
            <div class="title" ref="title">
              <!-- {{ $__t('Types of items and Price') }} -->
              {{ $__t('Price') }}
            </div>

            <!-- <div class="section type">
              <div class="title">{{ $__t('Types of Items') }}</div>
              <div class="table">
                <div class="row">
                  <div class="cell category">Small</div>
                  <div class="cell">
                    <div class="items">
                      <div class="item">
                        <div class="container">
                          <img src="/img/item-backpack-small.png" />
                          <div class="text">{{ $__t('Backpack') }}</div>
                        </div>
                      </div>

                      <div class="item">
                        <div class="container">
                          <img src="/img/item-shoppingbag.png" />
                          <div class="text">{{ $__t('Shopping Bag') }}</div>
                        </div>
                      </div>

                      <div class="item">
                        <div class="container">
                          <img src="/img/item-parcel.png" />
                          <div class="text">{{ $__t('Parcel') }}</div>
                        </div>
                      </div>

                      <div class="item">
                        <div class="container">
                          <img src="/img/item-purse.png" />
                          <div class="text">{{ $__t('Purse') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="cell category">Large</div>
                  <div class="cell">
                    <div class="items">
                      <div class="item">
                        <div class="container">
                          <img src="/img/item-suitcase.png" />
                          <div class="text">{{ $__t('Suitcase') }}</div>
                          <div class="text small">({{ $__t('Checked/Carry-on') }})</div>
                        </div>
                      </div>

                      <div class="item">
                        <div class="container">
                          <img src="/img/item-backpack-big.png" />
                          <div class="text">{{ $__t('Travel Backpack') }}</div>
                        </div>
                      </div>

                      <div class="item">
                        <div class="container">
                          <img src="/img/item-scooter.png" />
                          <div class="text">{{ $__t('Kick Scooter') }}</div>
                        </div>
                      </div>

                      <div class="item">
                        <div class="container">
                          <img src="/img/item-stroller.png" />
                          <div class="text">{{ $__t('Stroller') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="section price">
              <!-- <div class="title">{{ $__t('Price') }}</div> -->
              <div class="table price">
                <div class="row">
                  <div class="cell">&nbsp;</div>
                  <div class="cell">
                    <strong>{{ $__t('first 1 hour') }}</strong>
                    <div class="detail">({{ $__t('basic fee') | capitalizeFirstLetter }})</div>
                  </div>
                  <div class="cell">
                    <strong>{{ $__t('over 1 hour') }}</strong>
                    <div class="detail">({{ $__t('per 10 minutes') }})</div>
                  </div>
                  <div class="cell">
                    <strong>{{ `8${$__t('시간')}~24${$__t('시간')}` }}</strong>
                    <div class="detail">({{ $__t('fixed') }})</div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="cell">Small</div>
                  <div class="cell">2,000{{ $__t('won') }}</div>
                  <div class="cell">100{{ $__t('won') }}</div>
                  <div class="cell">6,000{{ $__t('won') }}</div>
                </div>
                <div class="row">
                  <div class="cell">Large</div>
                  <div class="cell">3,000{{ $__t('won') }}</div>
                  <div class="cell">150{{ $__t('won') }}</div>
                  <div class="cell">9,000{{ $__t('won') }}</div>
                </div> -->
                <div class="row">
                  <div class="cell">{{ $__t('요금') }}</div>
                  <div class="cell">2,500{{ $__t('won') }}</div>
                  <div class="cell">150{{ $__t('won') }}</div>
                  <div class="cell">8,500{{ $__t('won') }}</div>
                </div>
              </div>

              <div class="table price additional">
                <div class="row">
                  <div class="cell">&nbsp;</div>
                  <div class="cell">
                    <strong>{{ $__t('additional charge after 24 hours') }}</strong>
                    <div class="detail">({{ $__t('basic fee') | capitalizeFirstLetter }})</div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="cell">Small</div>
                  <div class="cell">4,000{{ $__t('won') }} / +24h</div>
                </div>
                <div class="row">
                  <div class="cell">Large</div>
                  <div class="cell">6,000{{ $__t('won') }} / +24h</div>
                </div> -->
                <div class="row">
                  <div class="cell">{{ $__t('요금') }}</div>
                  <div class="cell">5,000{{ $__t('won') }} / +24h</div>
                </div>
              </div>
              <div class="caption">
                <!-- <span @click="openModal('priceGuideModal')" class="link"> -->
                <!--here-->
                <span @click="openPriceModal()" class="link"> *{{ $__t('More details of the price') }} <img src="/img/question.svg" /></span>
              </div>
            </div>
          </div>
          <div class="notice">
          <!--이용 요금 안내-->
            <ul>
              <li class="body-title">{{ $__t('[Price Guide]') }}</li>
              <!-- <li class="dot">{{ $__t('보증금') }} : 5,000{{ $__t('won') }}</li> -->
              <li class="dot">{{ $__t('Basic fee(first 1 hour)') }}</li>
              <!-- <li class="sub">Small : 2,000{{ $__t('won') }}</li>
              <li class="sub">Large : 3,000{{ $__t('won') }}</li> -->
              <li class="sub">2,500{{ $__t('won') }}</li>
              <li class="sub">{{ $__t('Fixed 1 day charge for over 24 hours') }}</li>
              <li class="dot">{{ $__t('Additional Charge') }}</li>
              <li class="sub">
                {{ $__t('There will be additional charge every 10 minutes before 8 hours, fixed charge over 8 hours to 24 hours.') }}
              </li>
              <!-- <li class="sub">Small : 100{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li>
              <li class="sub">Large : 150{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li> -->
              <li class="sub">150{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li>
              <li class="dot">{{ $__t('fixed charge after 24 hours') }}</li>
              <li class="sub">5,000{{ $__t('won') }} / +24h</li>
              <!-- <li class="sub">Small : 4,000{{ $__t('won') }} / +24h</li>
              <li class="sub">Large : 6,000{{ $__t('won') }} / +24h</li> -->
            </ul>
            <!-- <ul>
              <li class="no-margin">
                {{ $__t('ex) Made a reservation for 3 hours to keep 1 Small item, 2 Large items') }}
              </li>
              <li class="no-margin" style="font-weight: bold">Small 1{{ $__t('개') }}</li>
              <li class="no-margin">
                2,000{{ $__t('won') }}({{ $__t('basic fee') }}) + 1,200{{ $__t('won') }}(100{{ $__t('won') }}/10{{ $__t('m') }} x 90{{ $__t('m') }}) =
                3,200{{ $__t('won') }}
              </li>
              <li class="no-margin" style="font-weight: bold">Large 2{{ $__t('개') }}</li>
              <li class="no-margin">
                3,000{{ $__t('won') }}({{ $__t('basic fee') }}) x 2 + 1,350{{ $__t('won') }}(150{{ $__t('won') }}/10{{ $__t('m') }} x 90{{
                  $__t('m')
                }}) x 2 = 8,700{{ $__t('won') }}
              </li>
              <li class="no-margin" style="font-weight: bold">3,200{{ $__t('won') }} + 8,700{{ $__t('won') }} = 11,900{{ $__t('won') }}</li>
            </ul> -->

            <!--유의사항-->
            <ul>
              <li class="body-title">{{ $__t('[Notice]') }}</li>
              <li class="dot no-margin">
                {{ $__t('티켓 예약시 예상 이용금액이 선결제됩니다.') }}
              </li>
              <li class="dot">
                {{
                  $__t(
                    '티켓 정상 이용 완료시 실제 체크인, 체크아웃 시간에 맞춰 사용요금이 결제된 후 선결제금액은 전액 환불됩니다.'
                  )
                }}
              </li>
              <li class="dot">
                {{ $__t('물품보관 이용료를 상점에서 직접 결제하는 것은 불가합니다.') }}
              </li>
              <li class="no-margin">
                {{ $__t('앱이나 웹을 통한 예약절차 및 결제를 거치지 않고 발생하는 문제는 럭스테이에서 책임지지 않습니다.') }}
              </li>
              <li class="dot">
                {{ $__t('상점 영업시간 내에만 물품 보관 및 찾기가 가능합니다. 이용 전 영업시간을 반드시 확인해주세요.') }}
              </li>
            </ul>

            <!--취소 및 환불규정-->
            <ul>
              <li class="body-title">{{ $__t('[Cancellation & Refund]') }}</li>
              <li class="dot no-margin">{{ $__t('Cancellation is available any time before the booked time.') }}</li>
              <li class="dot">{{ $__t('예약 시 설정한 ‘찾는 시간’까지 체크인 처리가 완료되지 않을 경우 티켓은 만료 처리됩니다.') }}</li>
              <!-- <li class="dot">
                {{ $__t('이용 예약 시간으로부터 1시간 이내에 체크인 처리가 완료되지 않을 경우, 티켓은 만료처리됩니다.') }}
              </li> -->
              <li class="dot">
                {{ $__t('티켓 만료시 선결제금액은 환불되지 않습니다.') }}
              </li>
              <li class="no-margin">
                {{ $__t('상점의 사유로 인해 물품보관 서비스를 이용하지 못하신 경우 고객센터로 연락주세요.') }}
              </li>
              <li class="dot">{{ $__t('예약 취소시 환불에 걸리는 시간은 아래와 같습니다.') }}</li>
              <li class="no-margin">{{ $__t('If canceled on the date of reservation: instant refund.') }}</li>
              <li class="no-margin">
                {{ $__t('If canceled after the date of reservation: refund after 3-5 business days.') }}
              </li>

              <li class="dot">
                {{ $__t('If you have any questions, please contact the messenger at the bottom right.') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <guide-modal id="priceGuideModal" :noBgClick="true" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
      <template v-slot:body>
        <price-guide />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import PriceGuide from '@/components/pages/_/PriceGuide';

export default {
  components: {
    PriceGuide
  },
  data() {
    return {};
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    openPriceModal() {
      // this.$emit('openPriceModal');
      this.openModal('priceGuideModal');
    },
    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageBodyTitle = $(this.$refs.title);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageBodyTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    }
  }
};
</script>
