<template>
  <div class="ticket-message" :class="{ 'ticket-message--activated': activated }">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{
            $route.name === 'ShareTicketSingle'
              ? ticket.status === 'CHECK_IN'
                ? $__t('전달완료')
                : $__t('찾기완료')
              : $__t('보관완료') | toUpperCase
          }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_check_in) | moment('HH:mm') }}
        </div>
      </div>
      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body">
            <div class="message-body__contents">
              <h3 class="primary">
                {{ ticket.store_name }}
              </h3>
              <p class="secondary">
                <span>{{ lockerBoxInfoText }}</span
                ><br />
                <span>{{ reserveDateTimeText }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from '@/components/includes/QrCode';
import dates from '@/mixins/dates';
import { directive as Viewer } from 'v-viewer';

export default {
  name: 'MessageCheckIn',

  props: {
    ticket: {
      type: Object,
      default: {},
    },

    order: {
      type: Object,
      default: {},
    },

    activated: {
      type: Boolean,
      default: false,
    },

    invitation: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [dates],

  data() {
    return {};
  },

  computed: {
    reserveDateTimeText() {
      const ticketExpiration = this.$moment(this.ticket?.datetime_check_in);

      return `${this.getDateLabel(ticketExpiration)} ${ticketExpiration.format('HH:mm')}에 보관`;
    },

    lockerBoxInfoText() {
      return `보관함 ${this.order?.product_data?.locker_number} • ${this.order?.product_data?.locker_size}`;
    },
  },

  created() {},

  components: {
    QrCode,
  },

  directives: {
    viewer: Viewer,
  },
};
</script>

<style scoped lang="scss">
.message-body {
  &__image-area {
    margin: unit(6) 0;
    padding: unit(24);
    padding-bottom: 0;
    border-bottom: dashed 1px #a9afb3;
  }

  &__image-wrapper {
    width: 100%;
    padding-bottom: 50%;
    border-radius: unit(3);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-label {
    padding: unit(6);
    text-align: center;

    .text {
      color: #a9afb3;
      line-height: unit(24);
      font-size: unit(16);
      font-weight: 500;
    }
  }
}
</style>
