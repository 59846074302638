var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"site-wrapper"}},[(_vm.wni.isNative && _vm.$store.state.config.mode !== 'production')?_c('div',{staticClass:"debug-header"},[_c('div',{staticClass:"debug-header__text"},[_vm._v(_vm._s(_vm.$store.state.config.mode)+" "+_vm._s(_vm.$store.state.config.version))])]):_vm._e(),(_vm.headerVisible)?_c('site-header',{attrs:{"type":_vm.$store.state.config.isTouchDevice
        ? _vm.$route.params.headerType || (_vm.$route.meta && _vm.$route.meta.headerType ? _vm.$route.meta.headerType : 'default')
        : 'pc-header',"title":_vm.$route.meta && _vm.$route.meta.title ? _vm.$route.meta.title : ''}}):_vm._e(),_c('div',{class:{
      'is-mobile': _vm.isTouchDevice,
      'hide-header': !_vm.headerVisible,
      'include-tab': _vm.$route.meta && _vm.$route.meta.headerType === 'iez',
      'more-depth': _vm.$route.meta.headerType === 'more-depth' || _vm.$route.params.headerType === 'more-depth'
    },attrs:{"id":"site-container"}},[_c('router-view',{key:_vm.$router.currentRoute.fullPath})],1),_c('transition',{attrs:{"name":"sidebar"}},[(_vm.sidebarOpened)?_c('site-sidebar',{on:{"close":_vm.closeSidebar}}):_vm._e()],1),_c('site-loading'),_c('site-modal'),_c('site-dialog'),_c('site-alert'),_c('site-toast'),_c('site-notice'),_c('qr-code-scanner'),(_vm.footerVisible)?_c('site-footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }