<template>
  <div id="image-link-banner">
    <div class="banner-image" :style="{'background-image': 'url('+imgUrl+')'}" @click="clickImage"/>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: "",
      required: true
    },
    imgUrl: {
      type: String,
      default: "",
      required: true
    },
    id: {
      type: String,
      default: "",
      required: true
    }
  },
  methods: {
    clickImage() {
      this.$analytics.tracking.call(this, 'banner', 'click', this.id )
      window.open(this.link);
    }
  }
}
</script>
