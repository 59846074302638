<template>
  <div class="subpage" id="host-stores">
    <div class="container">
      <div class="section" id="host-store-interface-section" v-if="docs.length > 0">
        <div class="container">
          <ui-solid-button color="primary" size="small">
            <router-link :to="{ name: 'HostStoreCreate' }"
              ><i class="fa fa-plus-circle"></i>{{ $__t('상점 추가하기') }}</router-link
            >
          </ui-solid-button>
        </div>
      </div>

      <div class="section" id="host-store-list-section">
        <div class="container">
          <div class="store-list" v-if="docs.length > 0">
            <div class="container">
              <ul class="item-list">
                <li class="item" v-for="doc in docs"><store-list-item :store="doc" :key="doc.id" /></li>
              </ul>
              <ui-text-button
                display="block"
                color="primary"
                class="list-more"
                @click="getMoreListItems"
                v-if="listLoadable"
              >
                <button type="button">{{ $__t('더 불러오기') }}</button>
              </ui-text-button>
            </div>
          </div>

          <div class="store-list-empty" v-else>
            <div class="container">
              <div class="title">{{ $__t('아직 상점을 추가하지 않으셨나요?') }}</div>
              <ui-solid-button color="primary" size="small">
                <router-link :to="{ name: 'HostStoreCreate' }"
                  ><i class="fa fa-plus-circle"></i>{{ $__t('상점 추가하기') }}</router-link
                >
              </ui-solid-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreListItem from '@/components/resources/store/ForHost';
export default {
  components: {
    storeListItem: StoreListItem,
  },
  data() {
    return {
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 10,
      },
    };
  },
  computed: {
    docs() {
      return this.$store.getters['stores/GET_ITEMS_BY_LIST']({ list: 'MyStores' });
    },
  },
  created() {
    this.getListItems();
  },
  methods: {
    showStoreForm() {
      this.$store.commit('modal/ADD_ITEM', { component: 'StoreForm', size: 'small', class: 'for-modal' });
    },
    getListItems() {
      this.$store.dispatch('stores/getListForHost', { list: 'MyStores', query: this.listQuery }).then((res) => {
        if (this.docs.length == res.data.data.total || res.data.data.items.length == 0) {
          this.listLoadable = false;
        } else {
          this.listLoadable = true;
        }
      });
    },
    getMoreListItems() {
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    },
  },
};
</script>
