var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slow-slide-down"}},[_c('div',{staticClass:"checkout-dialog"},[_c('div',{staticClass:"checkout-dialog-container"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"image-area"},[_c('viewer',{attrs:{"options":{ toolbar: false, title: false }}},[_c('div',{staticClass:"image-wrapper"},[(
                  _vm.data?.ticket?.resources &&
                  _vm.data?.ticket?.resources?.luggage_photo &&
                  _vm.data?.ticket?.resources?.luggage_photo?.resource_url
                )?_c('img',{staticClass:"luggage",attrs:{"src":_vm.data?.ticket?.resources?.luggage_photo?.resource_url}}):_c('img',{staticClass:"luggage",attrs:{"src":"/img/no-image.png"}}),(
                  _vm.data?.ticket?.resources &&
                  _vm.data?.ticket?.resources?.luggage_photo &&
                  _vm.data?.ticket?.resources?.luggage_photo?.resource_url
                )?_c('div',{staticClass:"zoomable"}):_vm._e()])])],1),_c('div',{staticClass:"message-area"},[_vm._v(" "+_vm._s(_vm.data?.message)+" "),_c('div',{staticClass:"message-notice"},[_vm._v(" "+_vm._s(_vm.data?.notice)+" ")])]),_c('div',{staticClass:"submit-weighted-button-area"},[_c('button',{directives:[{name:"button",rawName:"v-button"}],staticClass:"action_cancel",attrs:{"type":"button"},on:{"click":function($event){return _vm.callback('cancel')}}},[_vm._v(" "+_vm._s(_vm.data?.cancel?.label)+" ")]),_c('button',{directives:[{name:"button",rawName:"v-button"}],staticClass:"action_ok",attrs:{"type":"button","disabled":_vm.checkoutButtonDisable},on:{"click":function($event){return _vm.callback('ok')}}},[_vm._v(" "+_vm._s(_vm.data?.ok?.label)+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }