export default {
  methods: {
    getDateLabel(dateTime) {
      let momentDateTime = this.$moment(dateTime);

      const weekDays = [
        this.$__t('SSA_SUN'),
        this.$__t('SSA_MON'),
        this.$__t('SSA_TUE'),
        this.$__t('SSA_WED'),
        this.$__t('SSA_THU'),
        this.$__t('SSA_FRI'),
        this.$__t('SSA_SAT'),
      ];

      let formattedDate = momentDateTime.format('MM/DD');
      let day = momentDateTime.day();

      if (momentDateTime.isSame(new Date(), 'day')) {
        formattedDate = this.$__t('{date-time-picker}.today');
      } else {
        formattedDate += ' (' + weekDays[day] + ')';
      }

      return formattedDate;
    },
  },
};
