<template>
  <div class="resoruce loader">
    <lottie :options="lottieOptions" :height="80" :width="80" />
  </div>
</template>

<script>
import LottieLoading from '@/assets/lottie/loading-primary.json';
import Lottie from 'vue-lottie';

export default {
  components: {
    Lottie,
  },
  data() {
    return {
      lottieOptions: {
        animationData: LottieLoading
      },
    }
  }
}
</script>

