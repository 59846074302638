<template>
  <div class="ui-multiple-day-picker" :data-datetime="model">
    <div class="label" v-if="label">{{ label }}</div>
    <div class="calendar">
      <div class="calendar-area">
        <div class="calendar-header" ref="calendarHeader">
          <button class="calendar-header-controll calendar-header-prev" type="button" @click="changePreviousMonth"><i class="fa fa-angle-left"></i></button>
          <div class="calendar-header-current-label"><span class="year">{{ currentCalendarYear }}년</span> <span class="month">{{ currentCalendarMonth + 1 }}월</span></div>
          <button class="calendar-header-controll calendar-header-next" type="button" @click="changeNextMonth"><i class="fa fa-angle-right"></i></button>
        </div>
        <div class="calendar-body" ref="calendarBody">
          <ul>
            <li v-for="i in 7" class="day-header">{{ $moment().day( i-1 ).format( "ddd" ) }}</li>
            <li v-for="i in previousCalendarDays" :class="dayClass( previousCalendarYear, previousCalendarMonth, i )" @click="changePreviousMonth" class="day-previous">{{ i }}</li>
            <li v-for="i in currentCalendarDays" :class="dayClass( currentCalendarYear, currentCalendarMonth, i )" @click="toggleSelectedDay( currentCalendarYear, currentCalendarMonth, i )">{{ i }}</li>
            <li v-for="i in nextCalendarDays" :class="dayClass( nextCalendarYear, nextCalendarMonth, i )" @click="changeNextMonth" class="day-next">{{ i }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    minuteStep: {
      default: 60
    },
    start: {},
    end: {},
    availableDates: {},
    highlighted: {},
    format: {
      default: "YYYY-MM-DD"
    },
    label: {}
  },
  data(){
    var currentCalendar = this.$moment( this.value[0] );
    var calendarOpened = false;
    return {
      currentCalendar,
      calendarOpened
    };
  },
  computed: {
    model: {
      get(){
        // return this.value.map( o => this.$moment( o ) );
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
      }
    },
    currentCalendarYear(){
      return this.currentCalendar.year();
    },
    currentCalendarMonth(){
      return this.currentCalendar.month();
    },
    currentCalendarDays(){
      return this.currentCalendar.daysInMonth();
    },
    previousCalendar(){
      return this.$moment( this.currentCalendar ).subtract( 1, "months" );
    },
    previousCalendarYear(){
      return this.previousCalendar.year();
    },
    previousCalendarMonth(){
      return this.previousCalendar.month();
    },
    previousCalendarDays(){
      var days = [];
      for( var i = this.previousCalendar.endOf( "month" ).day(); i >= 0; i-- ){
        days.push( this.previousCalendar.daysInMonth() - i );
      };
      return days;
    },
    previousCalendarEndDay(){
      return [
        this.previousCalendar.day(),
        this.previousCalendar.format( "D" )
      ];
    },
    nextCalendar(){
      return this.$moment( this.currentCalendar ).add( 1, "months" );
    },
    nextCalendarYear(){
      return this.nextCalendar.year();
    },
    nextCalendarMonth(){
      return this.nextCalendar.month();
    },
    nextCalendarDays(){
      var days = [];
      for( var i = 0; i < 6-this.currentCalendar.endOf( "month" ).day(); i++ ){
        days.push( i+1 );
      };
      return days;
    },
  },
  methods: {
    changeThisMonth(){
      this.currentCalendar = this.$moment( this.value );
      this.$emit( "month_changed", this.$moment( this.currentCalendar ).date( 1 ) );
    },
    changeNextMonth(){
      this.currentCalendar = this.$moment( this.currentCalendar ).add( 1, "months" );
      this.$emit( "month_changed", this.$moment( this.currentCalendar ).date( 1 ) );
    },
    changePreviousMonth(){
      this.currentCalendar = this.$moment( this.currentCalendar ).subtract( 1, "months" );
      this.$emit( "month_changed", this.$moment( this.currentCalendar ).date( 1 ) );
    },
    toggleSelectedDay( year, month, day ){
      if( this.isAvailableDay( year, month, day ) ){
        var day = this.$moment().year( year ).month( month ).date( day ).format( "YYYY-MM-DD" );
        var value = JSON.parse( JSON.stringify( this.value ) ).map( o => this.$moment( o ).format( "YYYY-MM-DD" ) );
        if( value.indexOf( day ) >= 0 ){
          value = value.filter( o => o != day );
        }else{
          value.push( day );
        };

        this.$emit( "input", value.map( o => this.$moment( o ) ) );
      };
    },

    isAvailableDay( year, month, day, hour, minute ){
      var target;
      var result = true;

      if( year._isAMomentObject ){
        target = this.$moment( year );
      }else{
        target = this.$moment( this.value ).year( year ).month( month ).date( day );
      };
      if( this.availableDates && this.availableDates.length > 0 ){
        this.availableDates.forEach( o => {
          if( typeof o == "function" ){
            result = o( target );
          }else{
            result = o.format( "YYYY-MM-DD" ) == target.format( "YYYY-MM-DD" );
          };
        });
      };
      if( this.start ){
        if( this.start().format( "YYYY-MM-DD" ) > target.format( "YYYY-MM-DD" ) ){
          result = false;
        };
      };

      return result;
    },


    dayClass( year, month, day ){
      var day = this.$moment({ year, month, day });
      var classes = [];
      if( day.format( "YYYY-MM-DD" ) == this.$moment().format( "YYYY-MM-DD" ) ){
        classes.push( "day-today" );
      };
      if( this.value ){
        var value = JSON.parse( JSON.stringify( this.value ) ).map( o => this.$moment( o ).format( "YYYY-MM-DD" ) );
        if( value.indexOf( day.format( "YYYY-MM-DD" ) ) >= 0 ){
          classes.push( "day-selected" );
        };
      };
      if( !this.isAvailableDay( day ) ){
        classes.push( "day-disabled" )
      };
      if( this.highlighted && this.highlighted.length >= 1 ){
        this.highlighted.forEach( o => {
          if( typeof o == "function" ){
            if( o( day ) ) classes.push( "day-highlighted" );
          }else{
            if( o.format( "YYYY-MM-DD" ) == day.format( "YYYY-MM-DD" ) ) classes.push( "day-highlighted" );
          };
        });
      };
      return classes;
      return null;
    },
  },
}
</script>
