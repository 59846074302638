<template>
  <div id="mypage-ticket-single">
    <div
      v-if="doc"
      class="container"
      :class="{ after: doc.status == 'CHECK_OUT' || doc.status == 'CANCELLED' }"
    >
      <div
        v-if="
          doc.ticket_type == 'LUGGAGE' &&
          ((doc.status === 'ISSUED' && !isExpired) ||
            doc.status === 'CHECK_IN' ||
            isExpired)
        "
        class="ticket"
      >
        <div class="ticket-process" :class="`${doc.status}`">
          <ul v-if="isExpired" class="expired">
            <li>
              <div class="checked">
                <img
                  src="/img/circle-blue-one.svg"
                  v-if="doc.status === 'ISSUED'"
                />
                <img src="/img/circle-lightblue-one.svg" v-else />
              </div>
              <dl class="step">
                <dt class="title">{{ $__t("Expired Ticket") }}</dt>
                <dd class="description small">
                  {{
                    $__t(
                      "- The ticket will be expired when you don't Check In within 1 hour from your booking time."
                    )
                  }}<br />
                  {{
                    $__t(
                      "- Prepaid basic fee charged on booking will be not returned."
                    )
                  }}
                </dd>
              </dl>
            </li>
          </ul>
          <ul v-else :class="doc.status">
            <li :class="{ activated: doc.status === 'ISSUED' }">
              <div class="checked">
                <img
                  src="/img/circle-blue-one.svg"
                  v-if="doc.status === 'ISSUED'"
                />
                <img src="/img/circle-lightblue-one.svg" v-else />
              </div>
              <dl class="step">
                <dt class="title">{{ $__t("Drop Off") }}</dt>
                <dd class="description">
                  <span class="text">{{
                    $__t(
                      "Show this QR ticket to the host. Make sure you push the STOP button when you pick up the item."
                    )
                  }}</span>
                </dd>
              </dl>
              <div class="manual-button">
                <div
                  class="text-button"
                  @click="
                    doc.status == 'ISSUED' ? openModal('userGuideModal') : ''
                  "
                  :style="{
                    cursor: doc.status != 'ISSUED' ? 'not-allowed' : '',
                  }"
                >
                  {{ $__t("how to") }}
                  <img
                    src="/img/arrow-black-right.png"
                    v-if="doc.status === 'ISSUED'"
                  />
                  <img src="/img/arrow-gray-right.png" v-else />
                </div>
              </div>
              <ui-border-arrow-button
                color="blue"
                size="little-small"
                display="inline"
                class="store-button"
                @click="goToStore"
                >{{ $__t("go to the store") }}</ui-border-arrow-button
              >
            </li>
            <li :class="{ activated: doc.status === 'CHECK_IN' }">
              <div class="checked">
                <img
                  src="/img/circle-blue-two.svg"
                  v-if="doc.status === 'CHECK_IN'"
                />
                <img src="/img/circle-gray-two.svg" v-else />
              </div>
              <dl class="step">
                <dt class="title">{{ $__t("Pick Up") }}</dt>
                <dd class="description">
                  <span class="text">{{
                    $__t(
                      "Push the STOP button and proceed check-out when you pick up your item."
                    )
                  }}</span>
                </dd>
                <div class="manual-button">
                  <div
                    class="text-button"
                    @click="
                      doc.status == 'CHECK_IN'
                        ? openModal('userGuideModal')
                        : ''
                    "
                    :style="{
                      cursor: doc.status != 'CHECK_IN' ? 'not-allowed' : '',
                    }"
                  >
                    {{ $__t("how to") }}
                    <img
                      src="/img/arrow-black-right.png"
                      v-if="doc.status === 'CHECK_IN'"
                    />
                    <img src="/img/arrow-gray-right.png" v-else />
                  </div>
                </div>
                <div class="stop-watch">
                  <div class="calculation">
                    <span class="time"
                      ><strong>{{ timeLeft.split("/")[0] || "0" }}</strong
                      >{{ $__t("d") }}</span
                    >
                    <span class="time">
                      <strong>{{ timeLeft.split("/")[1] || "0" }}</strong
                      >{{ $__t("h") }}&nbsp;
                      <strong>{{ timeLeft.split("/")[2] || "0" }}</strong
                      >{{ $__t("m") }}&nbsp;
                      <strong>{{ timeLeft.split("/")[3] || "0" }}</strong
                      >{{ $__t("s") }}
                    </span>
                    <div class="price" v-if="doc.status === 'CHECK_IN'">
                      {{ $__t("Usage Amount") }} :
                      {{
                        price && price.total
                          ? price.total.toLocaleString() + $__t("won")
                          : ""
                      }}
                      &nbsp;<span class="price-discounted">{{
                        doc.order &&
                        doc.order.promotion &&
                        doc.order.promotion.promotion_id
                          ? $__t("discounted").toUpperCase()
                          : ""
                      }}</span>
                    </div>
                  </div>
                  <ui-solid-button
                    size="medium"
                    :color="doc.status == 'CHECK_IN' ? 'red' : 'disabled'"
                    display="inline"
                    class="stop-button"
                    @click="
                      doc.status == 'CHECK_IN' ? openModal('checkoutModal') : ''
                    "
                    ><button type="button">
                      {{ $__t("stop") }}
                    </button></ui-solid-button
                  >
                </div>
              </dl>
            </li>
            <li>
              <div class="checked">
                <img src="/img/circle-gray-three.svg" />
              </div>
              <dl class="step">
                <dt class="title">
                  {{ $__t("Usage") + " • " + $__t("후기") }}
                </dt>
              </dl>
            </li>
          </ul>
        </div>
      </div>
      <div
        ref="receipt"
        v-else-if="doc.status === 'CHECK_OUT' || doc.status === 'CANCELLED'"
        class="receipt"
        :style="receiptStyleObject"
      >
        <div
          class="receipt-text"
          :class="{ cancelled: doc.status === 'CANCELLED' }"
        >
          <div class="store-name">
            <span
              v-if="doc.ticket_type == 'LUGGAGE' || doc.ticket_type == 'PAID'"
              class="text"
            >
              {{
                doc.status === "CHECK_OUT"
                  ? doc.store_name
                  : $__t("Cancellation")
              }}
            </span>
            <span v-else class="text">
              {{
                doc.status === "CHECK_OUT"
                  ? sender_store.store_name ||
                    recipient_store.store_name ||
                    JSON.stringify(doc.product_data).sender_place
                  : $__t("Cancellation")
              }}
            </span>
          </div>
          <div v-if="doc.status === 'CANCELLED'" class="store-name-sub">
            {{ $__t("상점 이름") }}: {{ doc.store_name }}<br />{{
              $__t("Datetime ticket issued")
            }}:
            {{ $moment(doc.datetime_issued).format("YYYY-MM-DD") }}
          </div>
          <div v-else-if="isExpired" class="store-name-sub">
            {{ $__t("상점 이름") }} : {{ doc.store_name }}<br />{{
              $__t("Datetime ticket issued")
            }}:
            {{ $moment(doc.datetime_issued).format("YYYY-MM-DD") }}
          </div>
          <div v-if="doc.status === 'CHECK_OUT'" class="usage-button">
            <ui-border-arrow-button
              color="blue"
              size="little-small"
              display="inline"
              class="store-button"
              @click="openModal('usageModal')"
              >{{
                $__t("{mypage-ticket-single}.checkout.button.usage")
              }}</ui-border-arrow-button
            >
          </div>
        </div>
      </div>
      <div v-else class="ticket delivery">
        <div class="ticket-process">
          <ul
            :class="{
              STEP1: isDeliveryStep1Activated,
              STEP2: isDeliveryStep2Activated,
              STEP3: isDeliveryStep3Activated,
            }"
          >
            <li :class="{ activated: isDeliveryStep1Activated }">
              <div class="checked">
                <img
                  src="/img/circle-blue-one.svg"
                  v-if="isDeliveryStep1Activated"
                />
                <img src="/img/circle-gray-one.svg" v-else />
              </div>
              <dl class="step">
                <dt class="title">
                  <div class="text">
                    {{ $__t("{mypage-ticket-single}.delivery.step1.title") }}
                  </div>
                  <div class="button">
                    <ui-border-arrow-button
                      color="blue"
                      size="little-small"
                      display="block"
                      class="title-button"
                      :disabled="
                        !isDeliveryStep1Activated ||
                        (isDeliveryStep1Activated && !sender_store && !courier)
                      "
                      @click="
                        sender_store
                          ? openModal('storeInformationModal')
                          : openModal('courierInfoModal')
                      "
                    >
                      <button type="button" v-if="sender_store">
                        {{ $__t("go to the store") }}
                      </button>
                      <button
                        type="button"
                        v-else-if="isBeforeStatusThan('APPROVE')"
                      >
                        {{
                          $__t(
                            "{mypage-ticket-single}.delivery.step1.button.assigning"
                          )
                        }}
                      </button>
                      <button type="button" v-else>
                        {{
                          $__t(
                            "{mypage-ticket-single}.delivery.step1.button.approve"
                          )
                        }}
                      </button>
                    </ui-border-arrow-button>
                  </div>
                </dt>
                <dd class="description">
                  <div v-if="isItemImageExists">
                    <div class="text" v-if="sender_store">
                      {{
                        $__t(
                          "{mypage-ticket-single}.delivery.step1.description.store"
                        )
                      }}
                    </div>
                    <div class="text" v-else>
                      {{
                        $__t(
                          "{mypage-ticket-single}.delivery.step1.description.elsewhere"
                        )
                      }}
                    </div>
                  </div>

                  <div v-else class="text">
                    {{
                      $__t(
                        "{mypage-ticket-single}.delivery.step1.description.before_taking_photo"
                      )
                    }}
                  </div>

                  <div
                    class="image-button"
                    v-if="
                      (!sender_store && isBeforeStatusThan('PICK_UP')) ||
                      (sender_store && isBeforeStatusThan('CHECK_IN'))
                    "
                  >
                    <ui-border-arrow-button
                      v-if="isItemImageExists"
                      color="blue"
                      size="little-large"
                      fontColor="black"
                      display="block"
                      @click="openModal('itemImageModal')"
                    >
                      <button type="button">
                        <img src="/img/icon-camera.png" />{{
                          $__t(
                            "{mypage-ticket-single}.button.item_image.confirm"
                          )
                        }}
                      </button>
                    </ui-border-arrow-button>

                    <ui-border-arrow-button
                      v-else
                      color="blue"
                      size="little-large"
                      fontColor="black"
                      display="block"
                    >
                      <button type="button">
                        <img src="/img/icon-camera.png" /><span class="text">{{
                          $__t("Upload image of the item")
                        }}</span>
                      </button>
                      <luggage-photo-uploader
                        ref="photo-uploader"
                        :ticket="doc"
                      />
                    </ui-border-arrow-button>
                  </div>
                </dd>
              </dl>
            </li>
            <li :class="{ activated: isDeliveryStep2Activated }">
              <div class="checked">
                <img
                  src="/img/circle-blue-two.svg"
                  v-if="isDeliveryStep2Activated"
                />
                <img src="/img/circle-gray-two.svg" v-else />
              </div>
              <dl class="step">
                <dt class="title">
                  <div class="text">
                    {{ $__t("{mypage-ticket-single}.delivery.step2.title") }}
                  </div>
                  <div class="button">
                    <ui-border-arrow-button
                      color="blue"
                      size="medium"
                      display="block"
                      class="title-button"
                      :disabled="!isDeliveryStep2Activated"
                      @click="
                        $router.push({ name: 'DeliveryStatus', params: { id } })
                      "
                    >
                      <button type="button">
                        {{
                          $__t("{mypage-ticket-single}.delivery.step2.button")
                        }}
                      </button>
                    </ui-border-arrow-button>
                  </div>
                </dt>
                <dd class="description">
                  <span class="text">{{
                    $__t("{mypage-ticket-single}.delivery.step2.description")
                  }}</span>
                </dd>
              </dl>
            </li>
            <li :class="{ activated: isDeliveryStep3Activated }">
              <div class="checked">
                <img
                  src="/img/circle-blue-three.svg"
                  v-if="doc.status === 'DROP_OFF'"
                />
                <img src="/img/circle-gray-three.svg" v-else />
              </div>
              <dl class="step">
                <dt class="title">
                  <div class="text">
                    {{ $__t("{mypage-ticket-single}.delivery.step3.title") }}
                  </div>
                  <div class="button">
                    <ui-border-arrow-button
                      color="blue"
                      size="little-small"
                      display="block"
                      class="title-button"
                      :disabled="doc.status != 'DROP_OFF'"
                      @click="
                        recipient_store
                          ? goToStore('recipient')
                          : openModal('courierInfoModal')
                      "
                    >
                      <button type="button" v-if="recipient_store">
                        {{ $__t("go to the store") }}
                      </button>
                      <button type="button" v-else>
                        {{
                          $__t("{mypage-ticket-single}.delivery.step3.button")
                        }}
                      </button>
                    </ui-border-arrow-button>
                  </div>
                </dt>
                <dd v-if="recipient_store" class="description">
                  {{
                    $__t(
                      "{mypage-ticket-single}.delivery.step3.store-description"
                    )
                  }}
                  <div class="stop-button">
                    <ui-solid-button
                      size="little-small"
                      :color="doc.status == 'DROP_OFF' ? 'red' : 'orange'"
                      display="inline"
                      class="stop-button"
                      @click="doc.status == 'DROP_OFF' ? checkout() : ''"
                    >
                      <button type="button">
                        {{ $__t("{share-ticket}.pick_up.button") }}
                      </button>
                    </ui-solid-button>
                  </div>
                </dd>
                <dd v-else class="description">
                  {{
                    $__t(
                      "{mypage-ticket-single}.delivery.step3.elsewhere-description"
                    )
                  }}
                  <div class="stop-button">
                    <ui-solid-button
                      size="little-small"
                      :color="doc.status == 'DROP_OFF' ? 'red' : 'orange'"
                      display="inline"
                      class="stop-button"
                      @click="doc.status == 'DROP_OFF' ? checkout() : ''"
                    >
                      <button type="button">
                        {{ $__t("{share-ticket}.pick_up.button") }}
                      </button>
                    </ui-solid-button>
                  </div>
                </dd>
              </dl>
            </li>
            <li>
              <div class="checked">
                <img src="/img/circle-gray-four.svg" />
              </div>
              <dl class="step">
                <dt class="title">
                  {{ $__t("Usage") + " • " + $__t("후기") }}
                </dt>
              </dl>
            </li>
          </ul>
        </div>
      </div>

      <div
        ref="board"
        class="bottom-white-board"
        :class="{
          checkout: doc.status === 'CHECK_OUT' || doc.status === 'CANCELLED',
          delivery: doc.ticket_type == 'DELIVERY',
        }"
      >
        <div
          v-if="isBeforeStatusThan('CHECK_OUT') || isExpired"
          class="button-div"
        >
          <div class="text-button" @click="openModal('ticketDetailModal')">
            {{ $__t("Ticket Detail") }}
            <img src="/img/arrow-black-right.png" />
          </div>
          <div
            v-if="doc.ticket_type == 'LUGGAGE'"
            class="text-button"
            :class="{ disabled: doc.status !== 'CHECK_IN' }"
            @click="
              doc.status === 'CHECK_IN' ? openModal('shareTicketModal') : ''
            "
          >
            <img src="/img/share-black.png" />
            {{ $__t("Pass the Ticket") }}
          </div>
        </div>
        <div
          v-if="isBeforeStatusThan('CHECK_OUT') || isExpired"
          class="image-area"
        >
          <div v-if="showItemImage" class="photo">
            <div
              v-viewer="{ toolbar: false, title: false }"
              v-for="src in [doc.resources.luggage_photo.resource_url]"
              :key="src"
              class="img-wrapper"
            >
              <img :src="src" :key="src" />
            </div>
            <div class="zoomable" />
          </div>
          <div
            v-else-if="doc.status == 'CHECK_IN' && doc.ticket_type == 'LUGGAGE'"
            class="photo no-image"
          >
            <img src="/img/no-image.png" />
          </div>
          <div v-else class="qrcode">
            <qr-code :code="doc.ticket_code" />
          </div>
          <div v-if="isBeforeStatusThan('CHECK_OUT') || isExpired" class="code">
            {{ doc.ticket_code }}
          </div>
        </div>
        <div
          v-if="doc.status === 'CHECK_OUT' || doc.status === 'CANCELLED'"
          class="checkout-after"
        >
          <div v-if="doc.status === 'CHECK_OUT'" class="checkout-after-review">
            <div class="primary">
              {{ $__t("How was your experience with LugStay?") }}
            </div>
            <div class="secondary">
              {{ $__t("Sharing your experience") }}<br />{{
                $__t("will help us make the service better.")
              }}
            </div>
          </div>
          <div v-if="doc.status === 'CANCELLED'" class="cancellation-section">
            <div class="title">
              {{ $__t("예약 내용") }}
              <span class="detail">
                {{ $__t("Dropped Off by") }}
              </span>
            </div>
            <div class="contents">
              <ul>
                <li>
                  <div class="primary">
                    <span class="text">{{ $__t("Name") }}</span>
                  </div>
                  <div class="secondary">
                    <span class="text">{{ doc.user_name }}</span>
                  </div>
                </li>
                <li>
                  <div class="primary">
                    <span class="text">{{ $__t("Booked Time") }}</span>
                  </div>
                  <div class="secondary">
                    <span class="text"
                      >{{
                        $moment(doc.ticket_date_start).format("MM-DD HH:mm")
                      }}
                      ~
                      {{ $moment(doc.ticket_date_end).format("MM-DD HH:mm") }}
                    </span>
                  </div>
                </li>
                <li>
                  <div class="primary">
                    <span v-if="isExpired" class="text">{{
                      $__t("Datetime ticket expired")
                    }}</span>
                    <span v-else class="text">{{ $__t("Cancelled at") }}</span>
                  </div>
                  <div class="secondary">
                    <span v-if="isExpired" class="text">{{
                      $moment(doc.ticket_date_end).format("YYYY-MM-DD HH:mm")
                    }}</span>
                    <span v-else class="text">{{
                      $moment(doc.datetime_cancelled).format("MM-DD HH:mm")
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="primary">
                    <span class="text">{{ $__t("No. of items") }}</span>
                  </div>
                  <div class="secondary">
                    <span v-if="doc.ticket_quantity > 0" class="wrapper">
                      <img src="/img/luggage-small.png" />
                      <span class="text"
                        >{{ doc.ticket_quantity }}
                        {{
                          doc.ticket_quantity > 1 ? $__t("개") : $__t("item")
                        }}</span
                      >
                    </span>
                    <span v-if="doc.ticket_over_quantity > 0" class="wrapper">
                      <img src="/img/luggage-big.png" />
                      <span class="text"
                        >{{ doc.ticket_over_quantity }}
                        {{
                          doc.ticket_over_quantity > 1
                            ? $__t("개")
                            : $__t("item")
                        }}</span
                      >
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="doc.status === 'CANCELLED'" class="cancellation-section">
            <div class="title">
              <span class="text"
                >{{ $__t("Cancel") }}/{{ $__t("Refund") }}
                {{ $__t("Information") }}</span
              >
            </div>
            <div class="contents">
              <ul>
                <li>
                  <div class="primary">
                    <span class="text">{{ $__t("결제 수단") }}</span>
                  </div>
                  <div class="secondary">
                    <span class="text gray">{{
                      doc.billing_card && doc.billing_card.card_name
                        ? doc.billing_card.card_name
                        : null || doc.order.payment_method
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="primary">
                    <span class="text">{{ $__t("결제 총액") }}</span>
                  </div>
                  <div class="secondary">
                    <span class="text gray"
                      >{{ doc.paid_total }}{{ $__t("won") }}</span
                    >
                  </div>
                </li>
                <li>
                  <div class="primary">
                    <span class="text"
                      ><strong>{{ $__t("Refund Amount") }}</strong></span
                    >
                  </div>
                  <div class="secondary">
                    <span class="text"
                      ><strong
                        >- {{ doc.order.refund_total || doc.paid_total
                        }}{{ $__t("won") }}</strong
                      ></span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="doc.status === 'CHECK_OUT'" class="review-section">
            <rating-select v-model="review.rate" :disabled="review.exist" />
            <div class="comment-area">
              :
              <textarea
                v-model="review.message"
                :placeholder="`${doc.store_name}, ${$__t('how was it?')}`"
                :readonly="review.exist"
              />
              <div v-if="review.exist" class="text-button">
                <span @click="editReview" class="button">
                  <span class="text">{{ $__t("Modify") }}</span>
                </span>
                <span @click="deleteReview" class="button">
                  <span class="text">{{ $__t("Delete") }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="button-section"
          :class="{ disabled: !review.message && doc.status === 'CHECK_OUT' }"
        >
          <router-link v-if="review.exist" :to="{ name: 'Home' }"
            ><button type="button">{{ $__t("메인화면") }}</button></router-link
          >
          <button
            v-if="!review.exist && doc.status === 'CHECK_OUT'"
            @click="submitReview"
            type="button"
            :disabled="review.exist || !review.message"
          >
            {{ $__t("후기 작성") }}
          </button>
          <button
            v-if="doc.status === 'CANCELLED'"
            type="button"
            @click="$router.back()"
          >
            {{ $__t("닫기") }}
          </button>
        </div>
      </div>
    </div>

    <modal
      id="share-ticket-modal"
      ref="shareTicketModal"
      :header-label="$__t('Pass the Ticket')"
      :weighted="true"
      :cancelButtonLabel="$__t('Cancel')"
      :submitButtonLabel="$__t('Pass')"
      @submit="$refs.shareform.onSubmit()"
      @modalClosed="resetFormdata"
    >
      <template v-slot:body>
        <div class="container">
          <div class="info">
            <span class="text"
              >{{ $__t("Do you want to pass the QR ticket?") }}<br />{{
                $__t(
                  "Please write down the information who receives the ticket."
                )
              }}</span
            >
          </div>
          <ui-form ref="shareform" @submit="onShareSubmit" :formData="formData">
            <div class="share-form">
              <div id="shareForm">
                <ui-form-list-item>
                  <img
                    src="/img/icon-info@3x.png"
                    style="
                      position: absolute;
                      right: 1rem;
                      top: 1.25rem;
                      width: 1.5rem;
                      height: 1.5rem;
                      z-index: 1;
                      cursor: pointer;
                    "
                    @click="openModal('passInfoModal')"
                  />
                  <ui-textbox
                    v-model="formData.password.value"
                    :placeholder="formData.password.placeholder"
                    :type="formData.password.type"
                    :label="formData.password.label"
                    :error="formData.password.error"
                    :maxLength="'4'"
                  />
                </ui-form-list-item>
                <ui-form-list-multiple-item>
                  <ui-textbox
                    v-model="formData.user_first_name.value"
                    :placeholder="formData.user_first_name.placeholder"
                    :type="formData.user_first_name.type"
                    :label="formData.user_first_name.label"
                    :error="formData.user_first_name.error"
                  />

                  <ui-textbox
                    v-model="formData.user_last_name.value"
                    :placeholder="formData.user_last_name.placeholder"
                    :type="formData.user_last_name.type"
                    :label="formData.user_last_name.label"
                    :error="formData.user_last_name.error"
                  />
                </ui-form-list-multiple-item>

                <ui-form-list-multiple-item>
                  <ui-auto-complete
                    v-model="formData.user_dial_code.value"
                    :placeholder="formData.user_dial_code.placeholder"
                    type="dial"
                    :label="formData.user_dial_code.label"
                    :error="formData.user_dial_code.error"
                    :items="formData.user_dial_code.items"
                  />
                  <ui-textbox
                    v-model="formData.user_phone.value"
                    :placeholder="formData.user_phone.placeholder"
                    :type="formData.user_phone.type"
                    :label="formData.user_phone.label"
                    :error="formData.user_phone.error"
                    maxLength="15"
                  />
                </ui-form-list-multiple-item>
                <ui-form-list-item>
                  <ui-textarea
                    :placeholder="formData.message.placeholder"
                    :label="formData.message.label"
                    :error="formData.message.error"
                    v-model="formData.message.value"
                  />
                </ui-form-list-item>
              </div>
            </div>
          </ui-form>
        </div>
      </template>
    </modal>

    <modal
      ref="passInfoModal"
      id="passInfoModal"
      :submitButtonLabel="$__t('Confirm')"
      @submit="$refs.passInfoModal.closeModal()"
    >
      <template v-slot:body>
        <div class="pass-info">
          <ul>
            <li>
              <div class="img">
                <img
                  src="/img/icon-check.png"
                  style="width: 1.25rem; height: 1.25rem; display: inline-block"
                />
              </div>
              <div class="text">
                <span>{{
                  $__t("QR ticket will be passed by text message.")
                }}</span>
              </div>
            </li>
            <li>
              <div class="img">
                <img
                  src="/img/icon-check.png"
                  style="width: 1.25rem; height: 1.25rem; display: inline-block"
                />
              </div>
              <div class="text">
                <span>{{
                  $__t(
                    "Let 'em know the password to open the QR ticket for verification."
                  )
                }}</span>
              </div>
            </li>
            <li>
              <div class="img">
                <img
                  src="/img/icon-check.png"
                  style="width: 1.25rem; height: 1.25rem; display: inline-block"
                />
              </div>
              <div class="text">
                <span>{{
                  $__t(
                    "The password is not included in the ticket for security"
                  )
                }}</span>
              </div>
            </li>
            <li>
              <div class="img">
                <img
                  src="/img/icon-check.png"
                  style="width: 1.25rem; height: 1.25rem; display: inline-block"
                />
              </div>
              <div class="text">
                <span>{{ $__t("Use password length of 4") }}</span>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </modal>

    <guide-modal id="storeInformationModal" ref="storeInformationModal">
      <template v-slot:body>
        <reserved-store-info
          :store="
            doc.ticket_type == 'DELIVERY'
              ? isBeforeStatusThan('CHECK_IN')
                ? sender_store
                : recipient_store
              : store
          "
        />
      </template>
    </guide-modal>

    <full-modal id="ticketDetailModal" ref="ticketDetailModal">
      <template v-slot:body>
        <ticket-detail
          :ticket="doc"
          :store="store"
          @close="closeModal('ticketDetailModal')"
        />
      </template>
    </full-modal>

    <full-modal id="checkoutModal" ref="checkoutModal">
      <template v-slot:body>
        <checkout
          :ticket="doc"
          :duration="primitiveDuration"
          :price="price"
          @success="checkoutSuccess"
          @close="closeModal('checkoutModal')"
        />
      </template>
    </full-modal>

    <guide-modal
      id="userGuideModal"
      ref="userGuideModal"
      :arrow="true"
      name="userGuideModal"
    >
      <template v-slot:body>
        <user-guide :page="doc.status == 'CHECK_IN' ? 2 : 1" :carousel="true" />
      </template>
    </guide-modal>

    <guide-modal id="usageModal" ref="usageModal" name="usageModal">
      <template v-slot:body>
        <usage-info :code="id" />
      </template>
    </guide-modal>

    <modal
      id="courierInfoModal"
      ref="courierInfoModal"
      :headerLabel="
        $__t('{mypage-ticket-single}.delivery.step1.button.approve')
      "
      :submitButtonLabel="$__t('닫기')"
      @submit="closeModal('courierInfoModal')"
    >
      <template v-slot:body>
        <div class="courier-info">
          <div class="row">
            <div class="key">
              {{ $__t("{courier-info}.key.company_name") }}
            </div>
            <div class="value">
              {{ courier.delivery_company }}
            </div>
          </div>
          <div class="row">
            <div class="key">
              {{ $__t("{usage-info}.delivery.key.delivery_guy") }}
            </div>
            <div class="value">
              {{ courier.courier_name }}
            </div>
          </div>
          <div class="row">
            <div class="key">
              {{ $__t("{usage-info}.delivery.key.contact") }}
            </div>
            <div class="value">
              {{ courier.courier_phone }}
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal
      id="itemImageModal"
      ref="itemImageModal"
      :headerLabel="$__t('{mypage-ticket-single}.button.item_image.confirm')"
      :submitButtonLabel="$__t('닫기')"
      @submit="closeModal('itemImageModal')"
    >
      <template v-slot:body>
        <img
          :src="doc.resources.luggage_photo.resource_url"
          class="item-image"
        />
      </template>
    </modal>

    <site-footer />
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);

import utils from "@/utils";
import QrCode from "@/components/includes/QrCode";
import ReviewForm from "@/components/includes/ReviewForm";
import countryCodes from "@/assets/json/country-codes.json";
import RatingSelect from "@/components/modules/LugForm/RatingSelect";
import ChannelTalk from "@/components/modules/ChannelTalk";
import Badge from "@/components/modules/Badge/Badge";
import ReservedStoreInfo from "@/components/pages/_/ReservedStoreInfo";
import TicketDetail from "@/components/pages/_/TicketDetail";
import Checkout from "@/components/pages/_/Checkout.vue";
import SiteFooter from "@/components/outline/Footer";
import UserGuide from "@/components/pages/guide/UserGuide";
import UsageInfo from "@/components/pages/_/UsageInfo";
import LuggagePhotoUploader from "@/components/modules/RichForm/LuggagePhotoUploader";

export default {
  name: "MypageTicketSingle",
  components: {
    QrCode,
    ReviewForm,
    RatingSelect,
    Badge,
    ReservedStoreInfo,
    TicketDetail,
    Checkout,
    SiteFooter,
    UserGuide,
    UsageInfo,
    LuggagePhotoUploader,
  },
  props: ["id"],
  data() {
    var _dial_code = countryCodes.map((o) => {
      return { name: o.name, code: o.dial_code };
    });
    _dial_code.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    _dial_code.forEach((o) => {
      if (o.name === "Korea, Republic of") {
        _dial_code.splice(_dial_code.indexOf(o), 1);
        _dial_code.unshift(o);
      }
      if (o.name === "Others") {
        _dial_code.splice(_dial_code.indexOf(o), 1);
        _dial_code.push(o);
      }
    });
    return {
      timer: null,
      priceTimer: null,
      statusTimer: null,
      durationTimer: null,
      timeLeft: "",
      primitiveDuration: "",
      store: null,
      loading: true,
      formData: {
        user_last_name: {
          type: "text",
          label: this.$__t("성"),
          placeholder: this.$__t("Korean or English"),
          validation: [{ type: "required" }, { type: "koreanOrEnglish" }],
          value: "",
          error: "",
        },
        user_first_name: {
          type: "text",
          label: this.$__t("이름"),
          placeholder: this.$__t("Korean or English"),
          validation: [{ type: "required" }, { type: "koreanOrEnglish" }],
          value: "",
          error: "",
        },
        user_dial_code: {
          label: this.$__t("국가 번호"),
          placeholder: this.$__t("국가 번호를 선택해주세요"),
          validation: [{ type: "required" }],
          value: "",
          items: _dial_code.map((o) => {
            return { label: o.code + " (" + o.name + ")", value: o.code };
          }),
          error: "",
        },
        user_phone: {
          type: "text",
          label: this.$__t("휴대폰 번호"),
          placeholder: this.$__t("휴대폰 번호를 입력해주세요"),
          validation: [{ type: "required" }, { type: "number" }],
          value: "",
          error: "",
        },
        password: {
          type: "password",
          label: this.$__t("비밀번호"),
          placeholder: this.$__t("Enter Password"),
          validation: [{ type: "required" }, { type: "password" }],
          value: "",
          error: "",
        },
        message: {
          type: "text",
          label: this.$__t("Message to leave"),
          placeholder: this.$__t("Leave a message to the ticket receiver."),
          // validation: [{ type: 'required' }],
          value: "",
          error: "",
        },
      },
      review: {
        rate: "",
        message: "",
        exist: false,
        uid: "",
      },
      reviewListQuery: {
        page: 1,
        size: 10000,
      },

      shareUsers: [],
      billing: "",
      price: "",

      showAllSchedule: false,
      displayWeeks: [
        "SA_MON",
        "SA_TUE",
        "SA_WED",
        "SA_THU",
        "SA_FRI",
        "SA_SAT",
        "SA_SUN",
      ], // SA_HOL

      ticket: null,
      upperAreaHeight: 0,

      courier: null,

      luggageStatusList: [
        "ISSUED",
        "CHECK_IN",
        "CHECK_OUT",
        "CANCELLED",
        "EXPIRED",
      ],
      deliveryStatusList: [
        "ISSUED",
        "CHECK_IN",
        "ASSIGNING",
        "APPROVE",
        "PICK_UP",
        "DROP_OFF",
        "CHECK_OUT",
        "CANCELLED",
        "EXPIRED",
      ],
    };
  },
  watch: {
    doc: {
      async handler(value, oldValue) {
        if (!(value && oldValue)) return;

        if (value.status != oldValue.status) {
          if (
            value.ticket_type == "DELIVERY" &&
            this.isDeliveryStep1Activated
          ) {
            await this.getCourierInfo();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    isExpired() {
      return this.doc.status == "EXPIRED";
    },
    duration() {
      let check_in = this.$moment(this.doc.datetime_check_in);
      let check_out = this.$moment(this.doc.datetime_check_out);
      let date_start = this.$moment(this.doc.ticket_date_start);

      let duration = this.$moment(date_start).isAfter(check_in)
        ? this.$moment.duration(check_out.diff(check_in))
        : this.$moment.duration(check_out.diff(date_start));

      let formula = `${duration.seconds()}${this.$__t("s")}`;
      if (duration.minutes() != 0) {
        formula = `${duration.minutes()}${this.$__t("m")} ` + formula;
      }
      if (duration.hours() != 0) {
        formula = `${duration.hours()}${this.$__t("h")} ` + formula;
      }
      if (Math.floor(duration.asDays()) != 0) {
        formula =
          `${Math.floor(duration.asDays())}${this.$__t("d")} ` + formula;
      }

      return formula;
    },
    doc() {
      return this.$store.getters["tickets/GET_ITEM"]({
        key: "ticket_code",
        value: this.id,
      });
    },
    isItemImageExists() {
      return this.doc.resources &&
        this.doc.resources.luggage_photo &&
        this.doc.resources.luggage_photo.resource_url
        ? true
        : false;
    },
    showItemImage() {
      if (this.isItemImageExists) {
        if (this.doc.ticket_type == "LUGGAGE") {
          return true;
        } else if (this.doc.ticket_type == "DELIVERY") {
          if (this.sender_store) {
            return !this.isBeforeStatusThan("CHECK_IN") ? true : false;
          } else {
            return !this.isBeforeStatusThan("PICK_UP") ? true : false;
          }
        }
      } else {
        return false;
      }
    },
    receiptStyleObject() {
      const issue_type = this.doc.ticket_type;

      let styleObject = {
        height: this.$data.upperAreaHeight + "px",
      };

      if (issue_type == "DELIVERY") {
        if (this.sender_store) {
          styleObject.backgroundImage =
            "url(" +
            (this.sender_store.resources.thumbnail_image.resource_url || "") +
            ")";
          styleObject.backgroundColor = this.sender_store.resources
            .thumbnail_image.resource_url
            ? ""
            : "#000000";
        } else if (this.recipient_store) {
          styleObject.backgroundImage =
            "url(" +
            (this.recipient_store.resources.thumbnail_image.resource_url ||
              "") +
            ")";
          styleObject.backgroundColor = this.recipient_store.resources
            .thumbnail_image.resource_url
            ? ""
            : "#000000";
        }
      } else if (["LUGGAGE", "PAY_LATER"].includes(issue_type)) {
        styleObject.backgroundImage =
          "url(" +
          (this.store?.resources?.thumbnail_image?.resource_url ||
            this.store?.resources?.thumbnail_image?.resource_thumbnail_url ||
            "/img/default-lugstay-circle.png") +
          ")";
      }

      return styleObject;
    },
    sender_store() {
      const value = this.$store.getters["stores/GET_ITEMS_BY_LIST"]({
        list: "sender_store",
      });

      if (value.length > 0) {
        return value[0];
      }
    },
    recipient_store() {
      const value = this.$store.getters["stores/GET_ITEMS_BY_LIST"]({
        list: "recipient_store",
      });

      if (value.length > 0) {
        return value[0];
      }
    },
    getStoreToViewInformation() {
      if (this.doc.ticket_type == "LUGGAGE") return this.store;
      else if (this.doc.ticket_type == "DELIVERY") {
        if (this.sender_store && this.isBeforeStatusThan("PICK_UP"))
          return this.sender_store;
        else if (this.recipient_store && this.doc.status == "DROP_OFF")
          return this.recipient_store;
      }
    },
    isDeliveryStep1Activated() {
      if (this.sender_store) {
        return this.doc.status == "ISSUED" ? true : false;
      } else {
        return this.isBeforeStatusThan("PICK_UP") ? true : false;
      }
    },
    isDeliveryStep2Activated() {
      if (this.sender_store) {
        return !this.isBeforeStatusThan("CHECK_IN") &&
          this.isBeforeStatusThan("DROP_OFF")
          ? true
          : false;
      } else {
        return this.doc.status == "PICK_UP" ? true : false;
      }
    },
    isDeliveryStep3Activated() {
      return this.doc.status == "DROP_OFF" ? true : false;
    },
  },
  async created() {
    await this.getTicketStatus();
    await this.getReceipt();
    await this.startTimerOnCreated();
    await this.getStores();
    await this.initiateLuggageTimer();
    await this.getStoresReviewCounts();
    await this.getBilling();
    await this.getTicketReview();
    await this.getShareUsers();
    await this.getCourierInfo();

    this.$store.commit("loading/SET_FALSE");
  },
  mounted() {
    this.$analytics.logEvent("ticket confirm page view");

    ChannelTalk.hide();
    window.addEventListener("resize", this.onResize);
    this.$nextTick(async () => {
      await this.$store.dispatch("interval/set", {
        key: "scroll_timer",
        callback: this.scrollToActivatedItem,
        interval: 5000,
      });
    });
  },
  updated() {
    this.onResize();
  },
  async beforeDestroy() {
    await this.$store.dispatch("interval/clear");
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openModal(ref) {
      if (ref == "shareTicketModal" && this.shareUsers.length) {
        //찾는 사람 한 명만 설정가능하게 핸들링.
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t("You've already passed the ticket."),
          status: "error",
        });
        return;
      }

      if (ref == "shareTicketModal" && this.doc.status !== "CHECK_IN") {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t("물품을 맡기시기 전에는 티켓을 전달할 수 없습니다."),
          status: "error",
        });
        return;
      }

      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },

    openMap() {
      let url = `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`;
      if (this.wni.isNative) this.wni.execute("wnOpenURL", { url: url });
      else window.open(url);
    },
    openReviewForm() {
      this.$store.commit("modal/ADD_ITEM", {
        component: "ReviewForm",
        size: "small",
        payload: this.doc,
      });
    },
    deleteReview() {
      this.$store.commit("dialog/ADD_ITEM", {
        message: this.$__t("리뷰를 삭제하시겠습니까?"),
        true: {
          label: this.$__t("예"),
          callback: () => {
            this.$store
              .dispatch("resourceReviews/delete", { id: this.review.uid })
              .then((res) => {
                this.$delete(
                  this.$store.state.tickets.items[this.doc.ticket_id],
                  "review"
                );
                this.$store.commit("alert/ADD_ITEM", {
                  message: "리뷰가 삭제되었습니다.",
                  status: "success",
                });
                this.clearReviewForm();
              })
              .catch((err) => {
                this.$store.commit("alert/ADD_ITEM", {
                  message: err,
                  status: "error",
                });
              });
          },
        },
        false: {
          label: this.$__t("아니오"),
          callback: () => {},
        },
      });
    },
    subShareUser(share, index) {
      let payload = {
        code: this.doc.ticket_code,
        id: share.uid,
      };

      this.$store
        .dispatch("tickets/deleteShare", payload)
        .then((res) => {
          this.$delete(this.shareUsers, index);
        });
    },
    scrollToError() {
      setTimeout(() => {
        if ($(".ui-form-list-item .error").length >= 0) {
          let $error = $(".error");
          if ($error.length > 0) {
            $("html, body").scrollTop(
              $($error[0]).offset().top - $("#site-header").innerHeight()
            );
          }
        }
      }, 0);
    },
    onShareSubmit(values) {
      let data = {
        password: values.password,
        user_first_name: values.user_first_name,
        user_last_name: values.user_last_name,
        user_phone: values.user_phone,
        message: values.message,
      };

      if (
        !this.formData.user_dial_code.items.some(
          (e) => e.value === values.user_dial_code
        )
      ) {
        this.formData.user_dial_code.error = this.$__t(
          "Please select a existing value among the list."
        );
        return false;
      }

      this.$store
        .dispatch(
          "tickets/share",
          { code: this.doc.ticket_code, data: data },
          { headers: this.$store.state.config.userHeaders }
        )
        .then((data) => {
          this.$store.commit("alert/ADD_ITEM", {
            message: this.$__t("티켓이 전달되었습니다."),
            status: "success",
          });
          this.resetFormdata();
          this.$store
            .dispatch("tickets/getShare", {
              code: this.doc.ticket_code,
            })
            .then((data) => {
              if (data.data.data.items.length > 0) {
                this.shareUsers = data.data.data.items;
              }
            })
            .catch((err) => {
              this.$log.log(err);
            });
        })
        .catch((err) => {
          this.$store.commit("alert/ADD_ITEM", {
            message: this.$__t("티켓이 전달되지 않았습니다."),
            status: "error",
          });
          this.$log.log(err);
        });

      this.closeModal("shareTicketModal");
    },
    resetFormdata() {
      this.formData.user_last_name.value = "";
      this.formData.user_last_name.error = "";
      this.formData.user_first_name.value = "";
      this.formData.user_first_name.error = "";
      this.formData.user_dial_code.value = "";
      this.formData.user_dial_code.error = "";
      this.formData.user_phone.value = "";
      this.formData.user_phone.error = "";
      this.formData.password.value = "";
      this.formData.password.error = "";
      this.formData.message.value = "";
      this.formData.message.error = "";
    },
    async toggleDurationTimer(on_off) {
      switch (on_off) {
        case "on":
          let options = {
            key: "duration_timer",
            callback: async () => {
              let check_in = this.$moment(this.doc.datetime_check_in);
              let check_out = this.$moment(this.doc.datetime_check_out);
              let date_start = this.$moment(this.doc.ticket_date_start);
              let now = this.$moment();

              let duration = this.$moment(date_start).isAfter(check_in)
                ? this.$moment.duration(now.diff(check_in))
                : this.$moment.duration(now.diff(date_start));
              this.primitiveDuration = duration;

              if ((duration.minutes() % 10 === 0, duration.seconds() === 1)) {
                await this.getPrice();
              }

              this.timeLeft = `${Math.floor(
                duration.asDays()
              )}/${duration.hours()}/${duration.minutes()}/${duration.seconds()}`;
            },
            interval: 1000,
          };

          await this.$store.dispatch("interval/set", options);

          break;

        case "off":
          this.$store.dispatch("interval/unset", { key: "duration_timer" });
          break;
      }
    },
    async getPrice() {
      let payload = {
        id: this.store.id,
        query: {
          start_date: this.$moment(this.doc.datetime_check_in).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          end_date: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
          small_quantity: this.doc.ticket_quantity.toString(),
          big_quantity: this.doc.ticket_over_quantity.toString(),
          promotion_id:
            this.doc.order.promotion && this.doc.order.promotion.promotion_id
              ? this.doc.order.promotion.promotion_id
              : "",
        },
      };

      try {
        let res = await this.$store.dispatch(
          "stores/predictPrice",
          payload
        );

        let { data, error } = res.data;

        if (data && !error) {
          this.price = data;
        }
      } catch (e) {}
    },
    submitReview() {
      let data = {
        store_id: this.doc.store_id,
        ticket_id: this.doc.ticket_id,
        rate: this.review.rate,
        message: this.review.message,
      };

      this.$store
        .dispatch("resourceReviews/getByTicket", { code: this.id })
        .then((res) => {
          this.$store
            .dispatch("resourceReviews/put", {
              id: this.review.uid,
              data: data,
            })
            .then((res) => {
              let { error, data } = res.data;
              if (!error && data) {
                this.$store.commit("alert/ADD_ITEM", {
                  message: this.$__t("We appreciate your review"),
                  status: "success",
                });
                this.review.uid = data.uid;
                this.review.rate = data.extras.rate;
                this.review.message = data.extras.message;
                this.review.exist = true;
              }
            })
            .catch((err) => {
              this.$store.commit("alert/ADD_ITEM", {
                message: this.$__t(err),
                status: "error",
              });
            });
        })
        .catch((err) => {
          this.$store
            .dispatch("resourceReviews/post", { data })
            .then((res) => {
              let { error, data } = res.data;
              if (!error && data) {
                this.$store.commit("alert/ADD_ITEM", {
                  message: this.$__t("We appreciate your review"),
                  status: "success",
                });
                this.review.uid = data.uid;
                this.review.rate = data.extras.rate;
                this.review.message = data.extras.message;
                this.review.exist = true;
              }
            })
            .catch((err) => {
              this.$store.commit("alert/ADD_ITEM", {
                message: this.$__t(err),
                status: "error",
              });
            });
        });
    },
    editReview() {
      this.review.exist = false;
    },
    clearReviewForm() {
      this.review.exist = false;
      this.review.rate = "";
      this.review.message = "";
      this.review.uid = "";
    },

    async getBilling() {
      if (!this.doc.reserve.billing_id) return;

      try {
        let res = await this.$store.dispatch("billings/getCard", {
          id: this.doc.reserve.billing_id,
        });

        this.$data.billing = res.data.data;
      } catch (e) {
        this.$log.error(e);
      }
    },

    goToTickets() {
      this.$router.push({ name: "MypageTickets" });
    },

    goToStore() {
      this.openModal("storeInformationModal");
    },

    async getStoresReviewCounts() {
      const issue_type = this.doc.ticket_type;
      let res, sender_store_id, recipient_store_id;

      try {
        if (["LUGGAGE", "PAY_LATER"].includes(issue_type)) {
          res = await this.$store.dispatch("resourceReviews/getList", {
            list: this.store.id + "-reviews",
            query: this.reviewListQuery,
            id: this.store.id,
          });
          this.$data.store.reviews = res.data.data.total;
        } else if (issue_type == "DELIVERY") {
          sender_store_id = utils.parseJSON(
            this.doc.product_data
          ).sender_store_id;
          recipient_store_id = utils.parseJSON(
            this.doc.product_data
          ).recipient_store_id;

          if (sender_store_id > 0) {
            res = await this.$store.dispatch("resourceReviews/getList", {
              list: sender_store_id + "-reviews",
              query: this.reviewListQuery,
              id: sender_store_id,
            });

            let data = {
              id: sender_store_id,
              data: {
                reviews: res.data.data.total,
              },
            };

            this.$store.commit("stores/UPDATE_ITEM", { data: data });
          }

          if (recipient_store_id > 0) {
            res = await this.$store.dispatch("resourceReviews/getList", {
              list: recipient_store_id + "-reviews",
              query: this.reviewListQuery,
              id: recipient_store_id,
            });

            let data = {
              id: recipient_store_id,
              data: {
                reviews: res.data.data.total,
              },
            };

            this.$store.commit("stores/UPDATE_ITEM", { data: data });
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    },

    async checkoutSuccess() {
      this.closeModal("checkoutModal");

      this.$store.commit("loading/SET_TRUE");

      try {
        this.$store.commit("tickets/CLEAR_ITEMS");
        this.$store.commit("tickets/CLEAR_ALL_LIST");

        const TICKETS_ALL = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_all",
          filter: "all",
          query: { page: 1, size: 10 },
        });

        const TICKETS_AVAILABLE = this.$store.dispatch(
          "tickets/getList",
          {
            list: "MyTickets_available",
            filter: "available",
            query: { page: 1, size: 10 },
          }
        );

        const TICKET_THIS = this.$store.dispatch("tickets/get", {
          id: this.id,
        });

        await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE, TICKET_THIS]);

        return setTimeout(() => this.$store.commit("loading/SET_FALSE"), 100);
      } catch (e) {}
    },

    async getTicketStatus() {
      try {
        let res = await this.$store.dispatch("tickets/get", {
          id: this.id,
        });

        return res.data.data;
      } catch (e) {
        alert(this.$__t("페이지에 접근할 권한이 없습니다."));
        this.$router.back();
      }
    },

    async getTicketReview() {
      let res;

      try {
        res = await this.$store.dispatch("resourceReviews/getByTicket", {
          code: this.id,
        });

        res = res.data.data;

        this.$store.commit("tickets/SET_ITEM", {
          data: {
            ticket_id: this.id,
            review: res,
          },
        });

        this.$data.review.rate = res.extras.rate;
        this.$data.review.message = res.extras.message;
        this.$data.review.uid = res.uid;

        if (this.$data.review.message) this.$data.review.exist = true;
      } catch (e) {
        this.$log.error(e);
      }
    },

    async startTimerOnCreated() {
      try {
        if (this.isBeforeStatusThan("CHECK_OUT")) {
          let options = {
            key: "status_timer",
            callback: async () => {
              await this.getTicketStatus();
              await this.getReceipt();
            },
            interval: 3000,
          };

          await this.$store.dispatch("interval/set", options);
        }
      } catch (e) {
        this.$log.error(e);
      }
    },

    async getStores() {
      const issue_type = this.doc.ticket_type;
      let res, sender_store_id, recipient_store_id;

      try {
        if (issue_type == "DELIVERY") {
          sender_store_id = utils.parseJSON(
            this.doc.product_data
          ).sender_store_id;
          recipient_store_id = utils.parseJSON(
            this.doc.product_data
          ).recipient_store_id;

          if (sender_store_id > 0) {
            res = await this.$store.dispatch("stores/get", {
              id: sender_store_id,
              list: "sender_store",
            });
          }

          if (recipient_store_id > 0) {
            res = await this.$store.dispatch("stores/get", {
              id: recipient_store_id,
              list: "recipient_store",
            });
          }
        } else if (["LUGGAGE", "PAY_LATER"].includes(issue_type)) {
          res = await this.$store.dispatch("stores/get", {
            id: this.doc.store_id,
          });
          this.$data.store = res.data.data;
        }
      } catch (e) {
        this.$log.error(e);
      }
    },

    async getShareUsers() {
      let res;

      try {
        res = await this.$store.dispatch("tickets/getShare", {
          code: this.id,
        });

        this.$data.shareUsers = res.data.data.items;
      } catch (e) {
        this.$log.error(e);
      }
    },

    async initiateLuggageTimer() {
      if (this.doc.ticket_type != "LUGGAGE") return;

      try {
        if (this.doc.status == "CHECK_IN") {
          await this.getPrice();
          await this.toggleDurationTimer("on");
        }
      } catch (e) {
        this.$log.error(e);
      }
    },

    async getCourierInfo() {
      if (this.doc.ticket_type != "DELIVERY") return;
      if (this.isBeforeStatusThan("APPROVE")) return;

      try {
        let res = await this.$store.dispatch("resourceDelivery/getCourier", {
          code: this.doc.ticket_code,
        });
        this.$data.courier = res;
      } catch (e) {
        this.$log.error(e);
      }
    },

    isBeforeStatusThan(targetStatus) {
      const issue_type = this.doc.ticket_type;
      const currentStatus = this.doc.status;

      let ticketStatusIndex, targetStatusIndex;

      if (issue_type == "DELIVERY") {
        ticketStatusIndex =
          this.$data.deliveryStatusList.indexOf(currentStatus);
        targetStatusIndex = this.$data.deliveryStatusList.indexOf(targetStatus);
      } else if (["LUGGAGE", "PAY_LATER"].includes(issue_type)) {
        ticketStatusIndex = this.$data.luggageStatusList.indexOf(currentStatus);
        targetStatusIndex = this.$data.luggageStatusList.indexOf(targetStatus);
      }

      return ticketStatusIndex < targetStatusIndex;
    },

    propsForStoreInformationModal(which) {
      if (!which) return this.$data.store;

      if (which == "sender") return this.$data.sender_store;
      else if (which == "recipient") return this.$data.recipient_store;
    },

    async checkout() {
      let payload = {
        code: this.doc.ticket_code,
      };
      this.$store.commit("loading/SET_TRUE");

      try {
        await this.$store.dispatch("tickets/checkoutAfter", payload);
        await this.fetchTickets();
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t("체크아웃되었습니다."),
          status: "success",
        });
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e),
          status: "error",
        });
      } finally {
        this.$store.commit("loading/SET_FALSE");
      }
    },

    async fetchTickets(options = {}) {
      try {
        this.$store.commit("tickets/CLEAR_ITEMS");
        this.$store.commit("tickets/CLEAR_ALL_LIST");
        //clear all tickets

        const TICKETS_ALL = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_all",
          filter: "all",
          query: { page: 1, size: 10 },
        });

        const TICKETS_AVAILABLE = this.$store.dispatch(
          "tickets/getList",
          {
            list: "MyTickets_available",
            filter: "available",
            query: { page: 1, size: 10 },
          }
        );

        return await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE]);
      } catch (e) {
        //catch
      }
    },

    scrollToActivatedItem() {
      if (!this.isBeforeStatusThan("CHECK_OUT")) return;

      setTimeout(() => {
        const activatedList = document.querySelectorAll("ul li.activated");
        if (activatedList.length > 0) {
          const elOffset = $(activatedList[0]).offset().top;
          const elHeight = $(activatedList[0]).height();
          const windowHeight = $(window).height();

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }

          $(".ticket, .ticket-process").animate({ scrollTop: offset }, 200);
        }
      }, 0);
    },

    onResize() {
      this.$nextTick(() => {
        if (!this.$refs.receipt) {
          return;
        }

        const innerWidth = window.innerWidth;

        if (innerWidth >= 1120) return (this.$data.upperAreaHeight = 400);

        const boardSize = this.$refs.board.getBoundingClientRect();
        const documentSize = this.$el.getBoundingClientRect();

        const calculatedHeight = documentSize.height - boardSize.height + 24;
        this.$data.upperAreaHeight = calculatedHeight;
      });
    },

    async getReceipt() {
      await this.$store.dispatch("orders/order", {
        id: this.doc.receipt_id,
      });
    },
  },
};
</script>
