<template>
  <div id="more-billings" class="page" :class="{ 'more-depth': $route.meta.headerType === 'more-depth' }">
    <div class="container">
      <div class="page-header">
        <div class="page-header__row">
          <div class="page-header__title">{{ $__t('Payment Card') }}</div>
          <div class="page-header__link-area">
            <router-link :to="{ name: 'MoreBillingAddPayMethod' }">
              <span>{{ $__t('카드 추가') }}</span>
            </router-link>
          </div>
        </div>
        <div class="page-header__row">
          <div class="page-header__secondary">
            {{ $__t('{billing}.header.secondary') }}
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="section card-frame">
          <CardItem ref="frame" :item="primaryBilling" />
        </div>
        <div class="section card-list">
          <ul>
            <li
              v-for="(item, index) in billings"
              :key="`billing-${index + 1}`"
              class="card-item"
              :class="{
                activated: primaryBilling && item.uid === primaryBilling.uid
              }"
            >
              <div class="container">
                <div class="check" @click="updatePrimaryBilling(item.uid)">
                  <input
                    type="checkbox"
                    :value="primaryBilling ? primaryBilling.uid : 0"
                    :checked="primaryBilling && item.uid === primaryBilling.uid"
                    class="check"
                    readonly
                  />
                  <i class="fas fa-check"></i>
                </div>
                <div class="text" @click="updatePrimaryBilling(item.uid)">{{ parseCardName(item.card_name) }} {{ item.card_number }}</div>
                <button type="button" class="close" @click="deleteBilling(item.uid)">
                  <img src="/img/modal-close@3x.png" />
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div v-if="checkShow()" class="button-area">
          <button type="button" @click="onClickPaymentRedirect">
            <span>현장 결제 진행하기</span>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from '@/components/modules/CardSelector/Item.vue';

export default {
  name: 'MoreBillings',

  components: {
    CardItem
  },

  data() {
    return {
      listQuery: {
        page: 1,
        size: 10
      },

      selectedBilling: null
    };
  },

  created() {
    this.$store.commit('loading/SET_TRUE');
    this.getPayMethods().finally(() => this.$store.commit('loading/SET_FALSE'));
  },

  mounted() {
    this.$analytics.logEvent('payment page view');

    setTimeout(() => {
      this.onResize();
      window.addEventListener('resize', this.onResize);
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  computed: {
    billings() {
      return Object.keys(this.$store.state.billings.items)
        .map((o) => this.$store.state.billings.items[o])
        .sort((a, b) => {
          const A_CREATD_AT = this.$moment(a.created_at);
          const B_CREATD_AT = this.$moment(b.created_at);

          if (A_CREATD_AT.isBefore(B_CREATD_AT)) {
            return 1;
          }

          if (B_CREATD_AT.isBefore(A_CREATD_AT)) {
            return -1;
          }

          return 0;
        });
    },

    primaryBilling() {
      return this.billings.find((billing) => billing.is_primary > 0);
    }
  },

  methods: {
    // checkShow() {
    //   const { tester } = this.$store?.state?.auth;
    //   return tester;
    // },
    onClickPaymentRedirect() {
      location.href = `https://app.lugstay.com/payment?user_id=${this.$store?.state?.auth?.gid}`;
    },
    async getPayMethods(clear) {
      try {
        if (clear) {
          this.$store.commit('billings/CLEAR_ITEMS');
        }

        return await this.$store.dispatch('billings/getCardList', {
          query: this.listQuery
        });
      } catch (e) {}
    },

    onResize() {
      const FRAME = this.$refs.frame.$el;
      const FRAME_STYLE = getComputedStyle(FRAME);

      const innerHeight = FRAME.clientWidth - parseFloat(FRAME_STYLE.paddingLeft) - parseFloat(FRAME_STYLE.paddingRight);

      FRAME.style.height = innerHeight * 0.59 + 'px';
    },

    parseCardName(card_name) {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(card_name);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return card_name;
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header .page-header__title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    },

    updatePrimaryBilling(uid) {
      this.$store.commit('loading/SET_TRUE');
      return this.$store
        .dispatch('billings/updatePrimaryCard', { uid })
        .finally(() => this.getPayMethods().finally(() => this.$store.commit('loading/SET_FALSE')));
    },

    deleteBilling(uid) {
      this.$store.commit('loading/SET_TRUE');

      return setTimeout(() => {
        return this.$store
          .dispatch('billings/deleteCard', { uid })
          .then((res) => {
            if (res.data.status == 'error') {
              return this.$store.commit('alert/ADD_ITEM', {
                message: this.$__t(res.data.error),
                status: 'error'
              });
            }
          })
          .catch((err) =>
            this.$store.commit('alert/ADD_ITEM', {
              message: err,
              status: 'error'
            })
          )
          .finally(() => this.getPayMethods(true).finally(() => this.$store.commit('loading/SET_FALSE')));
      }, 1000);
    }
  },

  components: {
    CardItem
  }
};
</script>
<style lang="scss" scoped>
.button-area > button {
  border: 1px solid #008fff;
  background-color: #ffffff;
  border-radius: unit(30);
  font-size: unit(14);
  color: #008fff;
  padding: unit(14) unit(34);
  width: 200px;
  margin: 0 auto;
}
</style>
