<template>
  <div id="estimated-price">
    <div class="container" v-if="item">
      <div class="header">
        <h1 class="primary">{{ $__t('예상결제금액') }}</h1>
        <p class="secondary" v-html="$__t('{estimated-price}.header.secondary')" />
      </div>
      <div class="body">
        <div class="section">
          <h2 class="section__header">
            {{ $__t('보관정보') }}
          </h2>
          <ul class="list">
            <li class="item">
              <div class="key">
                {{ $__t('{estimated-price}.section.list.key.DATE_INFO') }}
              </div>
              <div class="value">
                {{ dateInfo }}
              </div>
            </li>
            <li class="item">
              <div class="key">
                {{ $__t('Items to be stored') }}
              </div>
              <div class="value" :class="{ 'value--empty': item.formData.quantity_small + item.formData.quantity_big < 1 }">
                {{ itemInfo }}
              </div>
            </li>
          </ul>
        </div>
        <div class="section">
          <h2 class="section__header">
            {{ $__t('{estimated-price}.section.header.PRICE_INFO') }}
          </h2>
          <ul class="list">
            <li class="item">
              <div class="key">
                {{ $__t('{estimated-price}.section.list.key.BASIC_FEE') }}
              </div>
              <div class="value">
                <span class="value--medium">{{ basicPrice.toLocaleString() }}</span
                >{{ $__t('won') }}
              </div>
            </li>
            <!-- <li class="item">
              <div class="key">
                {{ $__t('deposit') }}
              </div>
              <div class="value"><span class="value--medium">+ {{ deposit.toLocaleString() }}</span>{{ $__t('won') }}</div>
            </li> -->
            <li class="item">
              <div class="key">
                {{ $__t('Additional Charge') }}
              </div>
              <div class="value">
                <span class="value--medium">{{ additionalPrice.toLocaleString() }}</span
                >{{ $__t('won') }}
              </div>
            </li>
          </ul>
        </div>
        <div class="section">
          <ul class="list">
            <li class="item">
              <div class="key">{{ $__t('{estimated-price}.section.list.key.TOTAL_FEE') }}</div>
              <div class="value">
                <span class="value--large">{{ (this.item.estimatedPrice || 0).toLocaleString() }}</span
                >{{ $__t('won') }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer">
        <ul>
          <!-- <li>{{ $__t('{estimated-price}.footer.list.item.1') }}</li> -->
          <li>{{ $__t('{estimated-price}.footer.list.item.2') }}</li>
          <li>{{ $__t('{estimated-price}.footer.list.item.3') }}</li>
        </ul>
        <div class="button-area">
          <button type="button" class="close-button" @click="$emit('close-slider')">
            {{ $__t('닫기') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],

  computed: {
    dateInfo() {
      const momentDateStart = this.$moment(this.item.formData.date_start.value);
      const momentDateEnd = this.$moment(this.item.formData.date_end.value);
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      return text;
    },

    itemInfo() {
      if (this.item.formData.quantity_small + this.item.formData.quantity_big < 1) return this.$__t('{estimated-price}.section.list.value.DATE_INFO');
      let value = '';

      if (this.item.formData.quantity_small > 0) {
        let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.item.formData.quantity_small}`;

        if (this.item.formData.quantity_small > 1) {
          value += quantityString + this.$__t('item');
        } else {
          value += quantityString + this.$__t('items');
        }
      }

      if (this.item.formData.quantity_big > 0) {
        let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.item.formData.quantity_big}`;

        if (this.item.formData.quantity_big > 0) quantityString = ' ' + quantityString;

        if (this.item.formData.quantity_big > 1) {
          value += quantityString + this.$__t('item');
        } else {
          value += quantityString + this.$__t('items');
        }
      }

      return value;
    },

    basicPrice() {
      let { quantity_small, quantity_big } = this.item.formData;

      return quantity_small * 2500 + quantity_big * 3000;
    },

    additionalPrice() {
      return this.item.estimatedPrice - this.basicPrice;
    },

    deposit() {
      return 5000;
    },

    duration() {
      let duration = this.$moment.duration(
        this.$moment(this.item.formData.date_end.value, 'YYYY-MM-DD HH:mm').diff(
          this.$moment(this.item.formData.date_start.value, 'YYYY-MM-DD HH:mm:ss')
        )
      );

      return duration.asMinutes();
    },

    additionalChargeDetail() {
      let { quantity_small, quantity_big } = this.item.formData;

      let value = '';

      if (quantity_small) {
        value += `${this.$__t('{luggage-selector}.small')} ${quantity_small}${
          quantity_small > 1 ? this.$__t('items') : this.$__t('item')
        } X (100/${this.$__t('per 10 minutes')} X ${this.duration}${this.$__t('m')})`;
      }

      if (quantity_big) {
        value += `<br/>${this.$__t('{luggage-selector}.big')} ${quantity_big}${
          quantity_big > 1 ? this.$__t('items') : this.$__t('item')
        } X (150/${this.$__t('per 10 minutes')} X ${this.duration}${this.$__t('m')})`;
      }

      return value;
    }
  }
};
</script>
