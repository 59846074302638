<template>
  <div id="agree-terms" class="includes">
    <div class="container">
      <div class="include-header">
        <h4>{{ $__t("구매조건 확인 및 결제대행 서비스 약관 동의") }}</h4>
      </div>
      <div class="include-body">
        <ul>
          <li>
            <span class="text"
              >[{{ $__t("{terms-of-use-form}.necessary") }}] {{ $__t("개인정보 수집 및 이용동의") }}</span
            >
            <router-link :to="{ name: 'CsPrivacyPolicy', params: { headerType: 'more-depth' } }" class="link">
              {{ $__t("보기") }}
            </router-link>
          </li>
          <li>
            <span class="text"
              >[{{ $__t("{terms-of-use-form}.necessary") }}] {{ $__t("개인정보 제3자 제공동의") }}</span
            >
            <router-link
              :to="{ name: 'CsPrivacyPolicyTheThird', params: { headerType: 'more-depth', store_name } }"
              class="link"
            >
              {{ $__t("보기") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["store_name"],
};
</script>
