<template>
  <div class="how-to-use book">
    <section class="how-to-use__section">
      <div class="image-frame">
        <img src="/img/guide-ticket-1.png" alt="Guide Booking" class="image" />
      </div>
      <p
        class="description"
        v-html="
          $__t(
            'Press the QR code scan button at home or select the reservation details from Usage details below.'
          )
        "
      ></p>
    </section>
    <section class="how-to-use__section">
      <div class="image-frame">
        <img src="/img/guide-ticket-2.png" alt="Guide Booking" class="image" />
      </div>
      <p
        class="description"
        v-html="
          $__t(
            'When you leave your items and retrieve them, please take a picture of the QR code in the store.'
          )
        "
      ></p>
    </section>
    <section class="how-to-use__section">
      <div class="image-frame">
        <img src="/img/guide-ticket-3.png" alt="Guide Booking" class="image" />
      </div>
      <p class="description">{{ $__t("맡길 물품 사진을 찍어주세요.") }}</p>
    </section>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.how-to-use {
  padding: unit(40) unit(16);

  p {
    font-size: unit(14);
    line-height: 1.43;

    strong {
      font-weight: bold;
    }
  }

  &__section + &__section {
    margin-top: unit(50);
  }

  .image-frame {
    width: 100%;
    padding-bottom: 100%;

    .image {
      @include fsa;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .description {
    margin-top: unit(20);
    padding-right: unit(24);
  }

  .callout-container {
    margin-top: unit(16);
    background: rgba(0, 143, 255, 0.1);
    border-radius: unit(10);
    padding: unit(20) unit(14);
  }
}
</style>
