<template>
  <div
    class="ui-ticket for-guest"
    :class="{
      ISSUED: ticket && ticket.status && ticket.status === 'ISSUED',
      CHECK_IN: ticket && ticket.status && ticket.status === 'CHECK_IN',
      ASSIGNING: ticket && ticket.status && ticket.status === 'ASSIGNING',
      APPROVE: ticket && ticket.status && ticket.status === 'APPROVE',
      PICK_UP: ticket && ticket.status && ticket.status === 'PICK_UP',
      DROP_OFF: ticket && ticket.status && ticket.status === 'DROP_OFF',
      CHECK_OUT: ticket && ticket.status && ticket.status === 'CHECK_OUT',
      CANCELLED: ticket && ticket.status && ticket.status === 'CANCELLED',
      EXPIRED: isExpired,
    }"
  >
    <template v-if="ticket">
      <div class="container">
        <div
          class="ticket-status-label"
          :class="[
            {
              ISSUED: ticket.status && ticket.status === 'ISSUED',
              CHECK_IN: ticket.status && ticket.status === 'CHECK_IN',
              ASSIGNING: ticket.status && ticket.status === 'ASSIGNING',
              APPROVE: ticket.status && ticket.status === 'APPROVE',
              PICK_UP: ticket.status && ticket.status === 'PICK_UP',
              DROP_OFF: ticket.status && ticket.status === 'DROP_OFF',
              CHECK_OUT: ticket.status && ticket.status === 'CHECK_OUT',
              CANCELLED: ticket.status && ticket.status === 'CANCELLED',
              EXPIRED: isExpired,
              ENG: $store.state.lang === 'en',
            },
          ]"
        >
          {{ ticketStatus | toUpperCase }}
        </div>
        <div class="store">
          <img
            v-if="ticket.store_resources && ticket.store_resources.thumbnail_image"
            :src="
              ticket.store_resources.thumbnail_image.resource_thumbnail_url ||
              ticket.store_resources.thumbnail_image.resource_url
            "
          />
          <img v-else src="/img/no-image.png" />
          <div class="name">
            {{ ticket.store_name }}
          </div>
          <div class="button-area">
            <ui-solid-button size="wide">
              <button type="button" @click="navigateTo('store')">{{ $__t("detail info") }}</button>
            </ui-solid-button>
          </div>
        </div>
        <div class="reserve">
          <div class="dateInfo" v-if="ticket">
            {{ $moment(ticket.ticket_date_start).format($__t("M. D. HH:mm")) }} ~
            {{ $moment(ticket.ticket_date_end).format($__t("M. D. HH:mm")) }} /
            {{ ticket.ticket_quantity + ticket.ticket_over_quantity }} {{ $__t("개") }}
          </div>
          <div class="statusInfo">
            <ui-border-round-button
              size="wide"
              color="gray"
              :disabled="ticket.status !== 'ISSUED'"
              @click="$router.push({ name: 'CancelTicket', params: { id: ticket.ticket_code }})"
            >
              <button type="button" :disabled="ticket.status !== 'ISSUED'">{{ $__t("예약 취소하기") }}</button>
            </ui-border-round-button>
          </div>
          <ui-solid-button size="wide">
            <button type="button" @click="navigateTo('ticket')">{{ $__t("view ticket") }}</button>
          </ui-solid-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    ticket: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isExpired() {
      return this.ticket?.status == "EXPIRED";
    },
    ticketStatus() {
      if (this.ticket?.status === "ISSUED") return this.$__t("예약 완료");
      else if (this.ticket?.status === "CHECK_IN") return this.$__t("checked in");
      else return this.$__t(this.ticket?.status);
    },
  },
  methods: {
    openReviewForm() {
      this.$store.commit("modal/ADD_ITEM", { component: "ReviewForm", size: "small", payload: this.ticket });
    },
    navigateTo(destination) {
      switch (destination) {
        case "store":
          this.$router.push({ name: "StoreSingle", params: { store_id: this.ticket?.store_id } });
          break;
        case "ticket":
          this.$router.push({ name: "TicketSingle", params: { id: this.ticket?.ticket_code } });
          break;
      }
    },
  },
};
</script>
