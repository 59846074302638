<template>
	<div id="more-coupons">
		<div class="container">
			<Coupons />
		</div>
	</div>
</template>

<script>
import Coupons from "@/components/pages/mypage/MypageCoupons.vue"

export default {
	data(){
		return {}
	},

	components: {
		Coupons
	}
}
</script>