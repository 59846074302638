<template>
  <div class="more-list" @click.stop="">
    <div class="container">
      <div class="body">
        <ul class="list-section">
          <li
            v-for="(item, index) in data.items"
            :key="`${data.title}-item-${index}`"
            :class="{ active: item?.id === selectedLanguage }"
          >
            <button
              type="button"
              v-button
              @click="item?.action()"
              :class="{ 'link-button': item?.link === true }"
            >
              <span>{{ item?.title | capitalizeFirstLetter }}</span>
              <img v-if="item?.link === true" alt="arrow" src="/img/arrow-right@3x.png" />
            </button>
            <img
              v-if="item?.id === selectedLanguage"
              alt="checked"
              src="/img/icon-checked.svg"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Language } from "@/modules/language/index";
import config from "@/config";

export default {
  data() {
    const lang = this.$store.state.lang;

    return {
      data: {
        title: this.$__t("언어 설정"),
        items: [
          {
            id: "system",
            title: this.$__t("시스템 설정 언어"),
            action: () => this.switchLanguage("system"),
          },
          { id: "ko", title: "한국어", action: () => this.switchLanguage("ko") },
          { id: "base", title: "English", action: () => this.switchLanguage("base") },
          // { id: "en", title: "English", action: () => this.switchLanguage("en") },
          // { id: "cn", title: "中文(簡体)", action: () => this.switchLanguage("cn") },
          // { id: "hk", title: "中文(繁體)", action: () => this.switchLanguage("hk") },
          // { id: "jp", title: "日本語", action: () => this.switchLanguage("jp") },
          // { id: "vn", title: "Tiếng Việt", action: () => this.switchLanguage("vn") },
        ],
      },

      selectedLanguage: lang,
    };
  },

  // watch: {
  //   selectedLanguage(oldValue, newValue) {
  //     if (oldValue && newValue) {
  //       this.reloadGoogleMap();
  //     }
  //   },
  // },

  methods: {
    switchLanguage(locale) {
      this.$store.commit("loading/SET_TRUE");
      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE"), 1000;
      });

      this.$store.commit("SET_LANGUAGE", locale);

      const languageSet = {
        ko: "ko",
        en: "En",
        hk: "ch_traditional",
        cn: "ch_Simplified Chinese",
        jp: "jp",
        vn: "vietnamese",
        system: "system",
      };

      this.$analytics.logEvent("language change", { language: languageSet[locale] });

      Language.setLanguage(locale);

      this.selectedLanguage = locale;
    },

    // async reloadGoogleMap() {
    //   this.$store.commit('alert/ADD_ITEM', { message: "페이지가 새로고침 됩니다.", status: 'alert'});
    //   await this.$utils.wait(1000);

    //   document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach((script, index) => {
    //     script.remove();
    //   });

    //   setTimeout(() => {
    //     //googleMapLocale
    //     // ko: 'ko',
    //     // en: 'en',
    //     // cn: 'zh-CN',
    //     // hk: 'zh-HK',
    //     // jp: 'ja',
    //     // vn: 'vi'

    //     const { Loader } = require("google-maps");

    //     let googleMapLocale = this.$store.state.lang;
    //     if (googleMapLocale === "cn") googleMapLocale = "zh-CN";
    //     else if (googleMapLocale === "hk") googleMapLocale = "zh-HK";
    //     else if (googleMapLocale === "jp") googleMapLocale = "ja";
    //     else if (googleMapLocale === "vn") googleMapLocale = "vi";

    //     const loader = new Loader(config.get("googleMapsApiKey"), {
    //       libraries: ["places", "geometry"],
    //       version: "weekly",
    //       language: googleMapLocale,
    //     });

    //     loader
    //       .load()
    //       .then(() => {
    //         this.$router.go(this.$router.currentRoute.fullPath);
    //       })
    //       .catch((err) => this.$log.log(err));
    //   }, 100);
    // },
  },
};
</script>
