<template>
  <div id="mypage-ticket-single-slip">
    <div id="mypage-ticket-single-usage">
      <div class="container">

        <div class="section">
          <div class="container">
            <div class="title">
              {{ $__t("{credit-card-slip}.title") }}
            </div>
          </div>
        </div>

        <div class="section no-shadow">
          <div class="container">
            <dl class="primary">
              <dt>{{ $__t("{credit-card-slip}.key.store-info") }}</dt>
            </dl>
          </div>
        </div>

        <div class="section">
          <div class="container border-top">
            <dl>
              <dt>{{ $__t("{card-slip}.store-name") }}</dt>
              <dd>{{ $__t("__회사명") }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("대표") }}</dt>
              <dd>{{ $__t("__대표") }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("사업자 등록번호") }}</dt>
              <dd>{{ $__t("__사업자 등록번호") }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("전화번호") }}</dt>
              <dd><span>02-1877-9727</span><br/><span style="color: #999999;">{{ $__t("{cs-phone}.available.hours") }}</span></dd>
            </dl>
            <dl>
              <dt>{{ $__t("주소") }}</dt>
              <dd>{{ $__t('__본사') }}</dd>
            </dl>
            <dl>
              <dt>URL</dt>
              <dd>www.lugstay.com</dd>
            </dl>
          </div>
        </div>

        <div class="section no-shadow">
          <div class="container">
            <dl class="primary">
              <dt>{{ $__t("{card-slip}.key.payment_info") }}</dt>
            </dl>
          </div>
        </div>

        <div class="section no-shadow">
          <div class="container border-top">
            <dl class="emph">
              <dt>{{ $__t('{usage-info}.key.total_payment') }}</dt>
              <dd>{{ data.paid_total.toLocaleString() + $__t('won')  }}</dd>
            </dl>
            <dl class="secondary">
              <dt>{{ $__t('{card-slip}.key.price') }}</dt>
              <dd>{{ Math.round((data.paid_total / 1.1)).toLocaleString() + $__t('won') }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t('{card-slip}.key.vat') }}</dt>
              <dd>{{ Math.round((data.paid_total - (data.paid_total / 1.1 ))).toLocaleString() + $__t('won') }}</dd>
            </dl>
          </div>
        </div>

        <div class="section">
          <div class="container border-top">
            <dl>
              <dt>{{ $__t("{card-slip}.key.typesofcards") }}</dt>
              <dd>{{ data.data.card_type }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.card_number") }}</dt>
              <dd>{{ data.data.card_num }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.buyer") }}</dt>
              <dd>{{ data.data.buyer }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.item") }}</dt>
              <dd>{{ data.data.type }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.typesoftransaction") }}</dt>
              <dd>{{ data.transaction_name }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.general/installation") }}</dt>
              <dd>{{ $__t("{card-slip}.payment.type.general") }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.approved_number") }}</dt>
              <dd>{{ data.data.auth_code }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("{card-slip}.key.transaction_date") }}</dt>
              <dd>{{ data.created_at | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
            </dl>
          </div>
        </div>

        <div class="section no-shadow">
          <div class="container">
            <dl class="primary">
              <dt>{{ $__t("{card-slip}.title.payment_agency_info") }}</dt>
              <dd></dd>
            </dl>
          </div>
        </div>

        <div class="section no-shadow">
          <div class="container border-top">
            <dl class="secondary">
              <dt>{{ $__t("{card-slip}.store-name") }}</dt>
              <dd v-if="data.type == 'NICEPAY'" >{{ $__t('{card-slip}.nicepay.company_name') }}</dd>
              <dd v-if="data.type == 'EXIMBAY'" >{{ $__t('{card-slip}.eximbay.company_name') }}</dd>
            </dl>
            <dl>
              <dt>{{ $__t("대표") }}</dt>
              <dd v-if="data.type == 'NICEPAY'">황윤경</dd>
              <dd v-if="data.type == 'EXIMBAY'">김준헌</dd>
            </dl>
            <dl>
              <dt>{{ $__t("사업자 등록번호") }}</dt>
              <dd v-if="data.type == 'NICEPAY'">815-81-00527</dd>
              <dd v-if="data.type == 'EXIMBAY'">113-86-37262</dd>
            </dl>
            <dl>
              <dt>{{ $__t("전화번호") }}</dt>
              <dd v-if="data.type == 'NICEPAY'">1661-0808</dd>
              <dd v-if="data.type == 'EXIMBAY'"></dd>
            </dl>
            <dl>
              <dt>{{ $__t("주소") }}</dt>
              <dd v-if="data.type == 'NICEPAY'">서울특별시 마포구 마포대로 217 (아현동)</dd>
              <dd v-if="data.type == 'EXIMBAY'">서울특별시 구로구 디지털로32가길 16, 1301호(구로동, 파트너스타워2차) | 08393</dd>
            </dl>
          </div>
        </div>
        
        <!-- <div class="section">
          <div class="container">
            <dl class="primary">
              <dt>{{ $__t("{usage-info}.key.item_info") }}</dt>
            </dl>
            <dl v-if="doc.ticket_quantity" class="secondary">
              <dt>
                Small
              </dt>
              <dd>{{ doc.ticket_quantity + (doc.ticket_quantity > 1 ? $__t("items") : $__t("item")) }}</dd>
            </dl>
            <dl>
              <dt>
                Large
              </dt>
              <dd>{{ doc.ticket_over_quantity + (doc.ticket_over_quantity > 1 ? $__t("items") : $__t("item")) }}</dd>
            </dl>
          </div>
        </div>

        <div class="section">
          <div class="container">
            <dl class="primary">
              <dt>{{ $__t("{usage-info}.key.total_payment") }}</dt>
              <dd class="color-primary">{{ paidTotal }}</dd>
            </dl>
            <dl class="secondary" v-if="doc.ticket_type === 'LUGGAGE'">
              <dt>
                {{ $__t("basic charge") | capitalizeFirstLetter }}
                <button type="button" @click="showSlip('basic')">
                  <img src="/img/icon-receipt-blue.png" />
                </button>
              </dt>
              <dd>{{ basicLuggageCharge }}</dd>
            </dl>
            <dl v-if="doc.ticket_type === 'LUGGAGE'">
              <dt>
                {{ $__t("Additional Charge") | capitalizeFirstLetter }}
                <button type="button" @click="showSlip('additional')">
                  <img src="/img/icon-receipt-blue.png" />
                </button>
              </dt>
              <dd>{{ additionalLuggageCharge }}</dd>
            </dl>
            <dl v-if="doc.ticket_type === 'LUGGAGE'">
              <dt>
                {{ $__t("{usage-info}.discount_coupon.total") }}
              </dt>
              <dd>{{ doc.order.promotion_coupon_code ? doc.order.payment_discount.toLocaleString() + $__t("won") : 0 + $__t("won") }}</dd>
            </dl>
            <dl v-if="doc.ticket_type === 'LUGGAGE'">
              <dt>
                {{ $__t("{usage-info}.discount_code.total") }}
              </dt>
              <dd>{{ doc.order.promotion_discount_code ? doc.order.payment_discount.toLocaleString() + $__t("won") : 0 + $__t("won") }}</dd>
            </dl>
          </div>
        </div> -->
      </div>
      <!-- container -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],

  data(){
    return{

    }
  },

  created(){

  },

};
</script>
