<template>
  <div class="resource navigation">
    <div class="card info-card">
      <ul class="info-list">
        <!--사용가능쿠폰-->
        <li class="info-item">
          <div class="container" @click="onClickCoupon">
            <img src="/img/icon/icon_coupon.svg" class="image" />
            <span class="info-label">{{ $__t('쿠폰') }}</span>
          </div>
        </li>

        <!--발급티켓-->
        <li class="info-item" :class="{ activated: isActivated('usage') }">
          <router-link :to="{ name: 'Usage' }">
            <div class="container">
              <img src="/img/icon/icon_list.svg" class="image" />
              <span class="info-label">{{ $__t('이용내역') }}</span>
            </div>
          </router-link>
        </li>

        <!--대표결제수단-->
        <li class="info-item">
          <div class="container" @click="onClickBillings">
            <img src="/img/icon/icon_card.svg" class="image" />
            <span class="info-label">{{ $__t('결제수단') }}</span>
          </div>
        </li>

        <!--상점관리하기-->
        <li class="info-item" v-if="isHostLoggedIn">
          <!-- <router-link :to="{ name: 'Host' }"> -->
            <div class="container" @click="onClickHost">
              <img src="/img/icon/icon_store_manage.svg" class="image" />
              <span class="info-label">{{ $__t('상점 관리하기') }}</span>
            </div>
          <!-- </router-link> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.gid > 0;
    },

    isHostLoggedIn() {
      return this.$store.state.auth.host_id > 0;
    }
  },
  methods: {
    isActivated(nav) {
      return this.$route.name.toLowerCase() === nav.toLowerCase();
    },
    onClickCoupon() {
      if (!this.isLoggedIn) {
        return this.$router.push({
          name: 'AuthLogin',
          query: { redirect: this.$router.currentRoute.path }
        });
      }

      return this.$router.push({
        name: 'MypageCoupons'
      });
    },
    onClickBillings() {
      if (!this.isLoggedIn) {
        return this.$router.push({
          name: 'AuthLogin',
          query: { redirect: this.$router.currentRoute.path }
        });
      }

      return this.$router.push({
        name: 'BillingListAndChangePrimary'
      });
    },
    onClickHost() {
      if (this.$store.state.config.mode === "production") {
        this.$router.push({
          name: 'Host'
        });
        return;
      }

      let accessToken = this.$store.state.config.getAccessToken();
      let url = this.$store.state.config.get('hostURL');
      if (url.indexOf("?") !== -1) {
        url = url + '&token=' + accessToken;
      }
      else {
        url = url + '?token=' + accessToken;
      }

      window.location.href = url;
    }
  }
};
</script>

<style lang="scss">
.resource.navigation {
  padding: 0 unit(16);

  .card {
    border-radius: unit(20);

    &.event-card {
      margin-top: unit(8);
      background: $color-white;
      box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.06), 2px 2px 10px rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: unit(10) unit(12);
      gap: unit(6);

      .event-card__icon {
        flex: 0;
        width: unit(18);
        height: unit(18);
        object-fit: contain;
        display: inline-block;
        line-height: unit(18);
      }

      .event-card__text {
        flex: 1;
        font-size: unit(14);
        letter-spacing: -0.006em;
      }

      .event-card__arrow {
        flex: 0;
        width: unit(24);
        height: unit(24);
        font-size: unit(22);
        line-height: unit(24);
        color: #a9afb3;
        -webkit-text-stroke: unit(1) $color-white;
      }
    }

    &.info-card {
      background: #ffffff;
      border-radius: 24px;

      .info-list {
        display: table;
        table-layout: fixed;
        width: 100%;
      }

      .info-item {
        display: table-cell;

        .container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: unit(16) unit(8);
          text-align: center;
          .info-label {
            color: #292a2b;
            font-weight: 700;
            font-size: unit(12);
          }
          .image {
            width: unit(24);
            height: unit(24);
          }
        }
      }

      .info-label {
        font-size: unit(12);
        line-height: 1.5;
        letter-spacing: -0.006em;
        color: #878d91;
      }

      .info-value {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: unit(6);
        margin-top: unit(8);
        font-size: unit(16);
        line-height: 1.38;
        letter-spacing: -0.006em;
        text-decoration-line: underline;
        color: #292a2b;

        img {
          font-size: unit(16);
          line-height: 1.38;
        }
      }
    }

    &.nav-card {
      color: $color-black;
      display: flex;
      gap: unit(1);
      justify-content: space-between;
      align-items: center;
      height: unit(64);
      padding: unit(12) unit(16);
      border: 1px solid #e1e4e6;

      .text {
        flex: 1;
        font-size: unit(13);
        // font-weight: bold;
        // color: inherit;
      }

      .icon {
        flex: 0;
        width: unit(20);
        height: unit(20);
        vertical-align: middle;
        // line-height: unit(64);
        // font-size: unit(26);
        // -webkit-text-stroke: unit(2) $color-white;
        // color: inherit;
      }

      &--primary {
        color: #292a2b;
        background: rgba(72, 217, 235, 0.16);
        border: none;
        font-size: 14px;
        width: unit(137);
        height: unit(40);
        // i {
        //   -webkit-text-stroke: unit(2) $color-primary;
        // }
      }
    }
  }
}
</style>
