<template>
  <div class="subpage" id="host-store-edit">
    <div class="nav-button">
      <button type="button" display="block" @click="$router.back()" class="back-button">
        <i class="fa fa-chevron-left"></i><span>뒤로가기</span>
      </button>
    </div>
    <div class="container">
      <store-form mode="edit" :id="id" />
    </div>
  </div>
</template>

<script>
import StoreForm from '@/components/includes/StoreForm';
export default {
  components: {
    StoreForm,
  },
  props: ['id'],
};
</script>
