<template>
    <div id="host-landing">
        <div class="container">
            <div class="button-area"><ui-solid-button display="block" @click="hostSubmit"><span>{{$__t("상점 소개하러 가기")}}</span></ui-solid-button></div>
            <div class="carousel">
                <carousel slideType="slide" :arrow="true" :pager="true">
                    <carousel-item :style="'background-image:url(/img/hostEvent/img1.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img2.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img3.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img4.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img5.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img6.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img7.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img8.jpg)'"></carousel-item>
                    <carousel-item :style="'background-image:url(/img/hostEvent/img9.jpg)'">
                        <div @click="hostSubmit" style="width:100%;height:100%;position: absolute;left:0;top:0"></div>
                    </carousel-item>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import Carousel from '@/components/modules/Carousel/Body';
import CarouselItem from "@/components/modules/Carousel/Item";

export default {
    name: 'HostEvent',
    components:{
        Carousel,
        CarouselItem
    },
    methods:{
        hostSubmit(){
            this.$analytics.tracking.call(this, 'hostSubmit', 'click', 'hostLanding' );

            let url="https://forms.gle/TXQwqB4Zmh9jLG6v6"

            if(this.wni.isNative) this.wni.execute('wnOpenURL', {url: url});
            else window.open(url);
        }
    }
}
</script>