<template>
  <div class="page" id="auth-callback-page">
    <div class="container">

      google callback
      {{ $route.query }}

    </div>
  </div>
</template>

<script>
export default {
  created(){
    return new Promise( ( resolve, reject ) => {
      return this.axios.post( this.$store.state.config.apiURL + "/v2/passport/auth/google", { access_token: this.$route.query.access_token }).then( res => {
        if( res.data.status == "success" ){
          commit( "SET_AUTH", res.data.data );
          resolve( res );
        }else{
          reject( res );
        };
      }).catch( reject );
    }).finally( res => {
      location.replace( "/" );
    });
  }

};
</script>
