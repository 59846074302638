<template>
  <div id="site-footer">
    <div class="top-menu">
      <div class="container">
        <ul>
          <li class="menu-item">
            <span @click="onClick('terms_of_use')">{{ $__t('{terms-of-use-form}.terms_of_use.label') }}</span>
          </li>
          <li class="menu-item">
            <span @click="onClick('privacy_policy')">{{ $__t('개인정보 처리방침') }}</span>
          </li>
          <li class="menu-item">
            <span @click="onClick('guide')">{{ $__t('서비스 가이드') }}</span>
          </li>
          <li class="menu-item">
            <span @click="onClick('notice')">{{ $__t('공지사항') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="contents">
      <div class="container">
        <!--왼쪽-->
        <div class="cs-area">
          <div class="primary">
            <span class="title">{{ $__t('C/S') }}</span>
            <br />
            <span class="phone">{{ $__t('__전화번호') }}</span>
            <!-- <div class="message">{{ $__t('주말에는 카카오톡, 채널톡을 이용한 단순 문의만 가능합니다.') }}</div> -->
          </div>
          <div class="secondary">
            <ul>
              <li class="title">{{ $__t('고객센터 운영시간') }}</li>
              <li>
                <div>{{ $__t('평일') }} 10:00 ~ 17:30</div>
              </li>
              <li>
                <!-- <div>{{ $__t('토, 일요일') }} 10:00 ~ 17:00</div> -->
                <div class="lunchtime">{{ $__t('점심시간') }} 12:00 ~ 13:30 ({{ $__t('공휴일, 임시공휴일 휴무') }})</div>
              </li>
            </ul>
          </div>
          <div class="link-area">
            <ul>
              <li @click="onClick('facebook')">
                <img src="/img/sns-icon-facebook.png" />
              </li>
              <li @click="onClick('instagram')">
                <img src="/img/sns-icon-instagram.png" />
              </li>
              <li @click="onClick('naver_blog')">
                <img src="/img/sns-icon-naver-blog.png" />
              </li>
              <li @click="onClick('youtube')">
                <img src="/img/sns-icon-youtube.png" />
              </li>
            </ul>
          </div>
        </div>
        <!--오른쪽-->
        <div class="info-area">
          <div class="misc">
            <div class="email">
              <div class="item">{{ $__t('일반문의') }} <span class="strong">support@lugstay.com</span></div>
              <div class="item">{{ $__t('비즈니스제휴') }} <span class="strong">biz@lugstay.com</span></div>
            </div>
            <ul>
              <li class="item">
                {{ $__t('__회사명') }}
              </li>
              <li class="item">{{ $__t('사업자 등록번호') }} : {{ $__t('__사업자 등록번호') }}</li>
              <li class="item clear-after">{{ $__t('대표') }} : {{ $__t('__대표') }}</li>
              <li class="item clear-after">{{ $__t('통신판매업 신고번호') }} : {{ $__t('__통신판매업 신고번호') }}</li>
              <li class="item clear-after">{{ $__t('본사') }} : {{ $__t('__본사') }}</li>
              <li class="item clear-after">{{ $__t('지사') }} : {{ $__t('__지사') }}</li>
            </ul>
          </div>
          <div class="copyright">
            <span>©2018 BLUEWHALE COMPANY INC. All Right Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    clickedFrom() {
      if (this.$route.name === 'Home') return 'home';
      else return null;
    }

    // isForIosAppInspection(){
    // 	if(this.wni.isNative){
    // 		const deviceInfo = utils.parseJSON(this.$store.state.config.userHeaders["x-device-info"]);

    // 		return (deviceInfo.os_type === 'ios') && (deviceInfo.app_version ===  "1.2.29")
    // 	}

    // 	return false;
    // }
  },
  methods: {
    onClick(param) {
      let routeName = '';

      switch (param) {
        case 'privacy_policy':
          routeName = 'CsPrivacyPolicy';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
            this.$router.push({ name: 'CsPrivacyPolicy' }).catch((err) => {});
          }
          break;
        case 'terms_of_use':
          routeName = 'CsTermsOfUse';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
            this.$router.push({ name: 'CsTermsOfUse' }).catch((err) => {});
          }
          break;
        case 'guide':
          routeName = 'CsGuide';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
            this.$router.push({ name: 'CsGuide' }).catch((err) => {});
          }
          break;
        case 'notice':
          routeName = 'CsNotices';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);

            console.log( "#notice" );
            this.$router.push({ name: 'CsNotices' }).catch((err) => {
              console.error(err);
            });
          }
          break;
        case 'faq':
          routeName = 'CsFaq';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', 'bottom_' + param);
            this.$router.push({ name: 'CsFaq' }).catch((err) => {});
          }
          break;
        case 'facebook':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.facebook.com/LugStay.official/'
            });
          } else {
            window.open('https://www.facebook.com/LugStay.official/');
          }
          break;
        case 'instagram':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.instagram.com/lugstay.official/'
            });
          } else {
            window.open('https://www.instagram.com/lugstay.official/');
          }
          break;
        case 'naver_blog':
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://blog.naver.com/lugstay'
            });
          } else {
            window.open('https://blog.naver.com/lugstay');
          }
          break;
        case 'youtube': {
          if (this.wni.isNative) {
            this.wni.execute('wnPopupOpen', {
              url: 'https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured'
            });
          } else {
            window.open('https://www.youtube.com/channel/UC6EABmILCH2ulEa4QVZwGbg/featured');
          }
          break;
        }
        case 'business_aliance':
          location.href = 'mailto:ljk@lugstay.com';
          break;
      }
    }
  }
};
</script>
