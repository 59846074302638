<template>
  <div class="page" id="mypage-page" :class="{ 'more-depth': $route.meta && $route.meta.headerType === 'more-depth' }">
    <div class="container">
      <div v-if="$route.name !== 'MypageCoupons'" class="page-header">
        <div class="container">
          <div class="page-header__title">{{ $__t('마이페이지') }}</div>
          <div class="logout-button" @click="logout()">{{ $__t('로그아웃') }}</div>
          <div class="tab">
            <ul>
              <!--              <li :class="{ on: $route.name.indexOf( 'MypageTicket' ) == 0 }"><router-link :to="{ name: 'MypageTickets' }"><i class="fa fa-calendar-alt"></i>{{ $__t( "예약" ) }}</router-link></li>-->
              <li :class="{ on: $route.name == 'MypageInterestStores' }">
                <router-link :to="{ name: 'MypageInterestStores' }" replace
                  ><i class="fa fa-star"></i>{{ $__t('관심') }}</router-link
                >
              </li>
              <!-- <li :class="{ on: $route.name == 'MypageCoupons' }"><router-link :to="{ name: 'MypageCoupons' }" replace><i class="fa fa-ticket-alt"></i>{{ $__t( "쿠폰" ) }}</router-link></li> -->
              <li :class="{ on: $route.name == 'MypageProfile' || $route.name == 'MypageChangePassword' }">
                <router-link :to="{ name: 'MypageProfile' }" replace
                  ><i class="fa fa-user"></i>{{ $__t('프로필') }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="page-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.commit('SET_TRUE', { key: 'appIntroViewed' });
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header .page-header__title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },
};
</script>
