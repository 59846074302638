var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-to-use book"},[_c('section',{staticClass:"how-to-use__section"},[_vm._m(0),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$__t(
          'Click the Set the usage time and number of luggage button to input the usage time and the number of luggage.'
        )
      )}})]),_c('section',{staticClass:"how-to-use__section"},[_vm._m(1),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$__t(
          'After selecting an available store, click the Storage button on the store details page.'
        )
      )}})]),_c('section',{staticClass:"how-to-use__section"},[_vm._m(2),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.$__t(
          'After checking the storage information, payment method, and payment details, please click the Reservation button at the bottom.'
        )
      )}})]),_c('div',{staticClass:"callout-container"},[_c('p',{staticClass:"callout-container__text",domProps:{"innerHTML":_vm._s(
        _vm.$__t(
          'To prevent non-use, after the estimated amount is paid in advance at the time of booking, the amount paid in advance will be canceled immediately after completing baggage claim, and the actual amount used will be finally paid.'
        )
      )}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-frame"},[_c('img',{staticClass:"image",attrs:{"src":"/img/guide-book-1.png","alt":"Guide Booking"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-frame"},[_c('img',{staticClass:"image",attrs:{"src":"/img/guide-book-2.png","alt":"Guide Booking"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-frame"},[_c('img',{staticClass:"image",attrs:{"src":"/img/guide-book-3.png","alt":"Guide Booking"}})])
}]

export { render, staticRenderFns }