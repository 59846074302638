import loadScript from "load-script";

const Allbigdat = {
  init(app, options = {}, callback = function() {}) {
    this._custom = options.custom;
    this._public = options.public;

    if (this._custom) {
      loadScript(`//saas.allbigdat.com/custom?ABD=${this._custom}`, (error, script) => {
        if (error) {
          callback(error, script);
          return;
        }

        callback(error, script);
      });
    }

    if (this._public) {
      loadScript(`//saas.allbigdat.com/public?ABD=${this._public}`, (error, script) => {
        if (error) {
          callback(error, script);
          return;
        }

        callback(error, script);
      });
    }
  },
};

export default Allbigdat;
