var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page more-depth",attrs:{"id":"mypage-coupon-page"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__title"},[_vm._v(_vm._s(_vm.$__t("이용권"))+"/"+_vm._s(_vm.$__t("쿠폰 내역")))])]),_c('div',{staticClass:"page-body"},[_c('ul',{staticClass:"tab-list"},[_c('li',{staticClass:"tab-item",class:{ activated: _vm.currentTab === 'all' },on:{"click":function($event){_vm.currentTab = 'all'}}},[_vm._v(" "+_vm._s(_vm.$__t("전체"))+" ")]),_c('li',{staticClass:"tab-item",class:{ activated: _vm.currentTab === 'pass' },on:{"click":function($event){_vm.currentTab = 'pass'}}},[_vm._v(" "+_vm._s(_vm.$__t("이용권"))+" ")]),_c('li',{staticClass:"tab-item",class:{ activated: _vm.currentTab === 'coupon' },on:{"click":function($event){_vm.currentTab = 'coupon'}}},[_vm._v(" "+_vm._s(_vm.$__t("쿠폰"))+" ")])]),(_vm.targetItems?.length > 0)?_c('ul',{staticClass:"coupon-list"},_vm._l((_vm.targetItems),function(item){return _c('li',{key:item.id,staticClass:"coupon-item"},[_c('router-link',{attrs:{"to":{ name: 'Stores' }}},[_c('div',{staticClass:"ui-coupon__for-guest",class:{
                disabled: !(
                  item.status === 'PREPARED' ||
                  item.status === 'STARTED' ||
                  (item.used === false && item.expired === false)
                ),
              }},[_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.getStatusText(item)))]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s((item.coupon_code ? _vm.$__t("쿠폰") + " " + item.coupon_name : null) || item.product_name || (item.pass_code ? _vm.$__t("프리패스 이용권") + " " + item.pass_code : ""))+" ")]),(
                  item.status === 'PREPARED' ||
                  item.status === 'STARTED' ||
                  (item.used === false && item.expired === false)
                )?_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$__t("보관 가능 상점 보러가기"))+" "),_c('i',{staticClass:"fas fa-arrow-right"})]):_vm._e()])])],1)}),0):_c('div',{staticClass:"empty-list"},[_c('p',[_vm._v(_vm._s(_vm.$__t("데이터가 존재하지 않습니다")))])])])]),_c('ui-slider',{ref:"code-register-slider",attrs:{"id":"code-register-slider","bgClick":true}},[_c('div',{staticClass:"code-register"},[_c('div',{staticClass:"code-register__body"},[_c('ui-textbox',{attrs:{"type":_vm.formData.registrable.type,"label":_vm.formData.registrable.label,"placeholder":_vm.formData.registrable.placeholder,"error":_vm.formData.registrable.error},model:{value:(_vm.formData.registrable.value),callback:function ($$v) {_vm.$set(_vm.formData.registrable, "value", $$v)},expression:"formData.registrable.value"}})],1),_c('div',{staticClass:"code-register__footer"},[_c('div',{staticClass:"code-register__submit-area"},[_c('button',{staticClass:"ui-submit-button activated",attrs:{"type":"button"},on:{"click":_vm.onClickRegister}},[_c('span',{staticClass:"ui-submit-button__text"},[_vm._v(" "+_vm._s(_vm.$__t("등록하기"))+" ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }