<template>
  <div class="subpage" id="host-ticket-single">
    <div class="container">
      <div class="ticket-detail" v-if="doc && initiated">
        <transition name="fade" mode="out-in">
          <div class="container">
            <div class="store">
              <div class="ticket-buttons">
                <ui-solid-button
                  class="list-button"
                  color="white"
                  display="block"
                  @click="$router.push({ name: 'HostTickets' })"
                  ><i class="fa fa-chevron-left"></i><span>Back to list</span></ui-solid-button
                >
              </div>
            </div>
            <div class="host-ticket-header">
              <div class="name">
                {{ doc.reserve.user_name || doc.reserve.user_first_name + " " + doc.reserve.user_last_name }}
              </div>
              <div class="email">{{ doc.reserve.user_email }}</div>
              <div class="dateInfo">
                {{ this.$moment(doc.ticket_date_start).format($__t("M. D. HH:mm")) }} ~
                {{ this.$moment(doc.ticket_date_end).format($__t("M. D. HH:mm")) }} /
                {{ doc.ticket_quantity + doc.ticket_over_quantity }} {{ $__t("개") }}
              </div>
            </div>

            <div
              class="photo"
              v-if="
                doc.status == 'CHECK_IN' ||
                (doc.resources && doc.resources.luggage_photo && doc.resources.luggage_photo.resource_url)
              "
            >
              <template v-if="doc.resources && doc.resources.luggage_photo && doc.resources.luggage_photo.resource_url">
                <viewer
                  :images="[doc.resources.luggage_photo.resource_url]"
                  :options="{ toolbar: false, title: false }"
                >
                  <template v-for="src in [doc.resources.luggage_photo.resource_url]">
                    <div class="img-wrapper">
                      <img :src="src" :key="src" />
                      <div class="zoomable" />
                    </div>
                  </template>
                </viewer>
              </template>
              <template v-else>
                <template v-if="doc.status == 'CHECK_IN'">
                  <luggage-photo-uploader :ticket="doc" :placeholder="$__t('Upload a image of the item')" />
                </template>
              </template>
            </div>
            <div class="photo" v-else>
              <div>
                <img src="/img/no-image.png" style="width: 100%" />
              </div>
            </div>
            <hr style="margin: 0; border: solid 0.5px #ebebeb" />
            <div v-if="filteredShareUsers.length > 0" class="reserve-header" style="padding-top: 2rem">
              <p>
                {{ $__t("Pick Up by") }}
              </p>
            </div>
            <div :key="item.user_phone" v-for="item in filteredShareUsers">
              <div class="reserve">
                <ul>
                  <li>
                    <div class="key">{{ $__t("이름") }}</div>
                    <div class="value">{{ item.user_name || item.user_first_name + " " + item.user_last_name }}</div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <div class="key">{{ $__t("휴대폰 번호") }}</div>
                    <div class="value">{{ item.user_phone }}</div>
                  </li>
                </ul>
                <ul style="border-bottom: 0 !important">
                  <li>
                    <div class="key">{{ $__t("찾는 시각") }}</div>
                    <div class="value">{{ doc.ticket_date_end }}</div>
                  </li>
                </ul>
              </div>
            </div>
            <template v-if="filteredShareUsers.length > 1">
              <div v-if="!show_more" class="showMore" @click="showMore()">
                {{ $__t("More") }}<img src="/img/button-04.png" />
              </div>
              <div v-else class="showMore" @click="showMore()">{{ $__t("닫기") }}<img src="/img/button-05.png" /></div>
            </template>

            <div class="reserve-header" :style="{ paddingTop: filteredShareUsers.length < 1 ? '2rem' : '' }">
              <p style="font-weight: bold">{{ $__t("Dropped Off by") }}</p>
            </div>

            <div class="reserve">
              <ul>
                <li>
                  <div class="key">{{ $__t("이름") }}</div>
                  <div v-if="filteredShareUsers.length > 0" class="value" style="color: #333333">
                    {{ doc.reserve.user_name || doc.reserve.user_first_name + " " + doc.reserve.user_last_name }}
                  </div>
                  <div v-else class="value">
                    {{ doc.reserve.user_name || doc.reserve.user_first_name + " " + doc.reserve.user_last_name }}
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <div class="key">{{ $__t("휴대폰 번호") }}</div>
                  <div v-if="filteredShareUsers.length > 0" class="value" style="color: #333333">
                    {{ doc.reserve.user_phone }}
                  </div>
                  <div v-else class="value">{{ doc.reserve.user_phone }}</div>
                </li>
              </ul>
              <ul>
                <li>
                  <div class="key">{{ $__t("맡기는 시각") }}</div>
                  <div v-if="filteredShareUsers.length > 0" class="value" style="color: #333333">
                    <div class="duration-start">{{ doc.ticket_date_start | moment("YYYY-MM-DD HH:mm") }}</div>
                  </div>
                  <div v-else class="value">
                    <div class="duration-start">{{ doc.ticket_date_start | moment("YYYY-MM-DD HH:mm") }}</div>
                  </div>
                </li>
              </ul>
              <ul v-if="!(filteredShareUsers.length > 0)">
                <li>
                  <div class="key">{{ $__t("찾는 시각") }}</div>
                  <div class="value">
                    <div class="duration-end">{{ doc.ticket_date_end | moment("YYYY-MM-DD HH:mm") }}</div>
                  </div>
                </li>
              </ul>
              <ul style="border-bottom: 0 !important">
                <li>
                  <div class="key">{{ $__t("No. of items") }}</div>
                  <div class="value" :style="{ color: filteredShareUsers.length > 0 ? '#333333' : '' }">
                    <!-- <span v-if="doc.ticket_quantity > 0" class="wrapper">
                      <img src="/img/luggage-small.png" />
                      <span class="text">Small {{ doc.ticket_quantity }}개</span>&nbsp;
                    </span>
                    <span v-if="doc.ticket_over_quantity > 0" class="wrapper">
                      <img src="/img/luggage-big.png" />
                      <span class="text">Large {{ doc.ticket_over_quantity }}개</span>
                    </span> -->
                    <span class="wrapper">
                      <img src="/img/luggage-small.png" />
                      <span class="text"> {{ doc.ticket_quantity + doc.ticket_over_quantity }} {{ $__t("개") }}</span>&nbsp;
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            
            <div v-if="doc.status === 'CHECK_OUT'" class="payment">
              <ul>
                <li class="payment__list-item">
                  <div class="key">{{ $__t("{check-out-info}.payment_amount") }}</div>
                  <div class="value">{{ doc.order.payment_origin > 0 ? doc.order.payment_origin.toLocaleString() : 0 }}{{ $__t('won') }}</div>
                </li>
              </ul>
            </div>

            <!-- <div class="guide">
              <ul>
                <li>[{{ $__t("Check in/out instruction") | capitalizeFirstLetter }}]</li>
                <li>{{ $__t("Scan QR ticket from the guest to initate check-in, take an image of the item.") }}</li>
                <li>{{ $__t("Check image of the item in the ticket, hand over the item to the guest.") }}</li>
              </ul>
              <ul>
                <li>{{ $__t("[Notice]") }}</li>
                <li>
                  -{{
                    $__t(
                      "Please be sure to scan QR ticket from the guest to check-in. If not the price will not be squared up."
                    )
                  }}
                </li>
                <li>
                  -{{ $__t("Take a image of the item after check-in.") }}
                  {{ $__t("The image itself is a promise of secured storing and can be used for covering accidents.") }}
                </li>
                <li>
                  -{{ $__t("LugStay service price is charged on real-time.") }}
                  {{
                    $__t(
                      `Let guest know how to proceed check-out otherwise price can be keep charged if guest doesn't press the STOP button and no check-out`
                    )
                  }}
                </li>
                <li>
                  -{{
                    $__t("If you're unable to keep the item for unavoidable plans, please contact customer service.")
                  }}
                </li>
              </ul>
              <ul>
                <li>{{ $__t("[Cancellation & Refund]") }}</li>
                <li>
                  -
                  {{
                    $__t(
                      "예약한 시간으로부터 1시간 이내로 체크인하지 않으면, 선결제된 기본료는 환불이 안되니 반드시 숙지 부탁드립니다."
                    )
                  }}
                </li>
                <li style="margin-top: 1rem">
                  - {{ $__t("Cancellation is available any time before the booked time.") }}
                </li>
                <li>-{{ $__t("Refund time for cancellation is as follows.") }}</li>
                <li>{{ $__t("If canceled on the date of reservation: instant refund.") }}</li>
                <li>{{ $__t("If canceled after the date of reservation: refund after 3-5 business days.") }}</li>
              </ul>
              <ul>
                <li>{{ $__t("[Compensation Policy]") }}</li>
                <li>{{ $__t("We selected partners, who have CCTV and extra storage space.") }}</li>
                <li>
                  {{
                    $__t(
                      "In case of loss/damage, compensation is made up to 300,000 won, according to the compensation policy."
                    )
                  }}
                </li>
              </ul>
              <ul>
                <li>{{ $__t("If you have any questions, please contact the messenger at the bottom right.") }}</li>
              </ul>
            </div> -->
          </div>
        </transition>
      </div>
      <div v-else style="min-height: calc(100vh-6rem);padding:0 1.333rem;background-color:#fffff;">
        <template v-for="n in 10">
          <div style="padding: 1rem 0" :key="`${n}-1`">
            <PuSkeleton :count="1" :loading="true" height="2.5rem" />
          </div>
          <div style="padding: 1rem 0" :key="`${n}-2`">
            <PuSkeleton :count="1" :loading="true" height="1.5rem" />
          </div>
          <div style="padding: 1rem 0" :key="`${n}-3`">
            <PuSkeleton :count="1" :loading="true" height="2.75rem" />
          </div>
          <div style="padding-top: 1rem" :key="`${n}-4`">
            <PuSkeleton :count="1" :loading="true" height="3.5rem" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);

import LuggagePhotoUploader from "@/components/modules/RichForm/LuggagePhotoUploader";
export default {
  components: {
    LuggagePhotoUploader,
  },
  props: ["code"],
  data() {
    return {
      initiated: false,
      // hostTicket: null,
      getShareData: [],
      show_more: false,
    };
  },
  computed: {
    doc() {
      return this.$store.getters["tickets/GET_ITEM"]({ key: "ticket_code", value: this.code });
    },
    isExpired() {
      return this.doc.status == "EXPIRED";
    },
    filteredShareUsers() {
      return this.$data.getShareData.length > 1
        ? this.$data.getShareData.filter((o) => o.user_phone != this.doc.reserve.user_phone)
        : this.$data.getShareData;
    },
  },
  created() {
    this.$store
      .dispatch("tickets/getByCode", { code: this.code })
      .then((res) => {
        this.initiated = true;
      })
      .catch((err) => {
        if (err == "INVALID_PERMISSION") {
          alert(this.$__t("페이지에 접근할 권한이 없습니다."));
          window.location = this.$store.state.config.siteURL;
        }
      });

    this.$store
      .dispatch("tickets/getSharebyManager", { code: this.code })
      .then((data) => {
        if (data.data.data.items.length > 0) {
          this.getShareData = data.data.data.items;
          this.show_more = 1;
        }
      })
      .catch((err) => {
        this.$log.log(err);
      });
  },
  methods: {
    toggleShowMore() {
      this.$data.show_more = !this.$data.show_more;
    },
  },
};
</script>
