<template>
  <div id="cancel-reservation">
    <div class="container">
      <template v-if="doc">
        <div class="store">
          <img
            class="store-image"
            :src="
              (doc.store_resources &&
                doc.store_resources.thumbnail_image.resource_thumbnail_url) ||
              doc.store_resources.thumbnail_image.resource_url
            "
            alt="상점 사진"
          />
          <div class="store-name">
            {{ doc.store_name }}
            <div class="reservation-date pc">
              <img src="/img/icon-start-date.png" />
              <span class="datetime">
                {{ doc.ticket_date_start }}
              </span>
              <em>~</em>
              <img src="/img/icon-end-date.png" />
              <span class="datetime">
                {{ doc.ticket_date_end }}
              </span>
            </div>
          </div>
        </div>

        <div class="reservation">
          <div class="reservation-date mobile">
            <img src="/img/icon-start-date.png" />
            <span class="datetime">
              {{ doc.ticket_date_start }}
            </span>
            <em>~</em>
            <img src="/img/icon-end-date.png" />
            <span class="datetime">
              {{ doc.ticket_date_end }}
            </span>
          </div>

          <div class="reservation-receipt">
            <dl class="primary">
              <dt>{{ $__t("결제 총액") }}</dt>
              <dd>
                {{ doc.paid_total ? doc.paid_total.toLocaleString() + $__t("won") : "" }}
              </dd>
            </dl>
            <dl class="secondary">
              <dt v-if="doc.ticket_quantity">
                Small {{ doc.ticket_quantity + $__t("개") }}
              </dt>
              <dd v-if="doc.ticket_quantity">
                {{ (2000 * doc.ticket_quantity).toLocaleString() + $__t("won") }}
              </dd>
            </dl>
            <dl>
              <dt v-if="doc.ticket_over_quantity">
                Large {{ doc.ticket_over_quantity + $__t("개") }}
              </dt>
              <dd v-if="doc.ticket_over_quantity">
                {{ (3000 * doc.ticket_over_quantity).toLocaleString() + $__t("won") }}
              </dd>
            </dl>
          </div>

          <div class="reservation-receipt">
            <dl class="primary">
              <dt>{{ $__t("Refund Amount") }}</dt>
              <dd class="color-primary">
                {{ doc.paid_total ? doc.paid_total.toLocaleString() + $__t("won") : "" }}
              </dd>
            </dl>
          </div>
        </div>

        <div class="cancel-reason">
          <div class="title">
            {{ $__t("Please select one of the reasons for cancellation of booking") }}
          </div>

          <div class="select">
            <div class="options">
              <div class="option" v-for="(reason, i) in formData.cancel_reason" :key="i">
                <input v-model="selectedReason" :id="i" :value="i" type="radio" />
                <i @click="selectedReason = i" class="fas fa-check"></i>
                <label class="label" :for="i">{{ reason.label }}</label>
                <input
                  v-if="reason.detail !== undefined"
                  v-model.trim="reason.detail"
                  :placeholder="reason.placeholder"
                  type="text"
                  @focus="
                    selectedReason = i;
                    reason.error = '';
                  "
                />
                <div v-if="reason.error" v-html="reason.error" class="error">
                  {{ reason.error }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cancel-refund-information">
          <div class="title" @click="isInfoBoxOpened = !isInfoBoxOpened">
            {{ $__t("Cancellation/Refund Policy Guide") }}
            <img v-if="!isInfoBoxOpened" src="/img/arrow-right@3x.png" class="arrow" />
            <img v-else src="/img/arrow-blue-right.png" class="arrow rotate" />
          </div>
          <div class="content" v-if="isInfoBoxOpened">
            <ul>
              <li>
                •
                {{
                  $__t(
                    "Payment will be returned fully on cancellation before the booked time."
                  )
                }}
              </li>
              <li>• {{ $__t("Refund time for cancellation is as follows.") }}</li>
              <li>
                - {{ $__t("If canceled on the date of reservation: instant refund.") }}
              </li>
              <li>
                -
                {{
                  $__t(
                    "If canceled after the date of reservation: refund after 3-5 business days."
                  )
                }}
              </li>
            </ul>
          </div>
        </div>

        <div class="confirmation">
          {{ $__t("Do you want to cancel the reservation?") }}
        </div>

        <div class="submit-weighted-button-area">
          <button type="button" class="false" @click="$emit('close')">
            {{ $__t("아니오") }}
          </button>
          <button type="button" class="true" @click="submitForm">
            {{ $__t("cancel reservation") | capitalizeFirstLetter }}
          </button>
        </div>
      </template>
    </div>

    <modal
      ref="selectReasonInformModal"
      :headerLabel="$__t('예약 취소하기')"
      :submitButtonLabel="$__t('Confirm')"
      @submit="closeModal('selectReasonInformModal')"
    >
      <template v-slot:body>
        <div class="alert">
          {{ $__t("Please select a reason for cancellation") }}
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  props: ["code"],

  data() {
    let formData;

    formData = {
      cancel_reason: {
        misinput_date: {
          value: "cancel_reason.misinput-date",
          label: this.$__t("cancel_reason.misinput-date"),
        },
        mistinput_time: {
          value: "cancel_reason.mistinput-time",
          label: this.$__t("cancel_reason.misinput-time"),
        },
        wrong_store: {
          value: "cancel_reason.wrong-store",
          label: this.$__t("cancel_reason.wrong-store"),
        },
        plan_changed: {
          value: "cancel_reason.plan-changed",
          label: this.$__t("cancel_reason.plan-changed"),
        },
        host_unavailable: {
          value: "cancel_reason.host-unavailable",
          label: this.$__t("cancel_reason.host-unavailable"),
        },
        etc: {
          value: "cancel_reason.etc",
          label: this.$__t("cancel_reason.etc") + " : ",
          detail: "",
          error: "",
          placeholder: this.$__t("Please type a reason for cancellation."),
        },
      },
    };

    return {
      formData,
      isInfoBoxOpened: false,
      selectedReason: "",
    };
  },

  computed: {
    doc() {
      return this.$store.getters["tickets/GET_ITEM"]({
        key: "ticket_code",
        value: this.code,
      });
    },
  },

  watch: {
    selectedReason(value) {
      this.formData.cancel_reason.etc.error = "";
    },
  },

  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    submitForm() {
      if (!this.selectedReason) {
        this.openModal("selectReasonInformModal");
        return;
      }

      if (this.selectedReason === "etc" && !this.formData.cancel_reason.etc.detail) {
        this.formData.cancel_reason.etc.error = this.$__t("__VALIDATION_ERROR_REQUIRED");
        return;
      }

      this.formData.cancel_reason.etc.error = "";
      this.$emit("submit", this.formData.cancel_reason[this.selectedReason]);
    },
  },
};
</script>
