<template>
  <div class="page" id="host-notices-single-page">
    <div class="container" v-if="doc">
      <div class="nav-button">
        <button type="button" display="block" @click="$router.back()" class="back-button">
          <i class="fa fa-chevron-left"></i><span>뒤로가기</span>
        </button>
      </div>

      <div class="page-header">
        <div class="title">{{ doc.post_title }}</div>
        <div class="datetime">{{ $moment(doc.created_at).format('YYYY-MM-DD') || $moment().format('YYYY-MM-DD') }}</div>
      </div>

      <div class="page-body">
        <div class="container" v-html="doc.post_content"></div>
      </div>
    </div>
    <div class="notice-none" v-else>
      <div class="title">{{ $__t('게시물이 없습니다.') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['id'],
  computed: {
    doc() {
      return this.$store.getters['resourceHostNotices/GET_ITEM']({ key: 'post_id', value: this.id });
    },
  },
  created() {
    this.$store.dispatch('resourceHostNotices/get', { list: 'Notices', id: this.id });
  },
};
</script>
