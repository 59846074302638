<template>
  <div class="page" id="auth-callback-page">
    <div class="container">

    </div>
  </div>
</template>

<script>
export default {
  created(){
    var accessToken = this.$route.hash.split( "#access_token=" ).join( "" );
    accessToken = accessToken.split( "&" )[0];
    return new Promise( ( resolve, reject ) => {
      if( this.$route.query.error ){
        reject();
      }else{
        return this.axios.post( this.$store.state.config.apiURL + "/v2/passport/auth/facebook", { access_token: accessToken }).then( res => {
          if( res.data.status == "success" ){
            this.$store.commit( "auth/SET_AUTH", res.data.data );
            resolve( res );
          }else{
            reject( res );
          };
        }).catch( reject );
      };
    }).then(() => {
      location.replace( "/" );
    }).catch( () => {
      location.replace( "/login" );
    });
  }

};
</script>
