<template>
  <div class="check-out-unavailable">
    <p class="text">{{ $__t("물품 보관 완료 10분 뒤부터 사용가능합니다.") }}</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.check-out-unavailable {
  background-color: #fff;
  
  min-height: unit(100);
  padding: unit(24) unit(16);
  display: flex;
  align-items: center;

  .text {
    font-size: unit(14);
    font-weight: 500;
    line-height: 1.5;
    color: #878d91;
  }
}
</style>
