import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import { WNInterface } from 'vue-wni';
import store from '@/store';

import Layout from '@/containers/Layout';

import webRoutes from './routes/web.js';
import nativeRoutes from './routes/native.js';
import customRoutes from './routes/custom.js';
import renewalRoutes from './routes';

import navigationGuard from './navigation.guard';

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: Layout,
      children: [...(WNInterface.isNative ? nativeRoutes : webRoutes), ...customRoutes, ...renewalRoutes]
    },
    { path: '*', redirect: '/' }
  ],
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  if (!(from && from.name)) {
    await store.dispatch('auth/refresh');
  }

  if (to.hash) {
    if (from && from.name === to.name && from.fullPath === to.fullPath) {
      return next(false);
    }
  }

  navigationGuard.changeStatusBar(to, from);
  navigationGuard.checkPermission(to, from);
  return navigationGuard.permissionHandler(next);
});

export default router;
