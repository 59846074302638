<template>
  <div class="includes form" id="store-form">
    <div class="form-header">
      <div class="title">{{ mode == 'edit' ? $__t('상점 관리하기') : $__t('상점 추가하기') }}</div>
      <div class="description" v-if="mode == 'create'">
        <p>{{ $__t('신청이 완료되면, 럭스테이에서 바로 연락드려 자세히 안내해 드리겠습니다.') }}</p>
      </div>
    </div>
    <div class="form-body">
      <ui-form @submit="submitForm" @error="scrollToError" :formData="formData" ref="form">
        <ul>
          <li class="form-section">
            <div class="form-section-title" @click="toggleFormSection(1)">
              {{ $__t('기본 정보') }}<i class="fa" :class="'fa-angle-' + (formSectionOpened.indexOf(1) >= 0 ? 'up' : 'down')"></i>
            </div>
            <div class="form-section-body" v-show="formSectionOpened.indexOf(1) >= 0">
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.store_name.placeholder"
                  :type="formData.store_name.type"
                  :label="formData.store_name.label"
                  :error="formData.store_name.error"
                  v-model="formData.store_name.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.store_phone.placeholder"
                  :type="formData.store_phone.type"
                  :label="formData.store_phone.label"
                  :error="formData.store_phone.error"
                  v-model="formData.store_phone.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-select
                  :placeholder="formData.store_category.placeholder"
                  :label="formData.store_category.label"
                  :error="formData.store_category.error"
                  v-model="formData.store_category.value"
                  :items="formData.store_category.items"
                />
              </ui-form-list-item>
              <!-- <ui-form-list-item>
                              <ui-textbox :placeholder="formData.store_managers.placeholder" :type="formData.store_managers.type" :label="formData.store_managers.label" :error="formData.store_managers.error" v-model="formData.store_managers.value" />
                              <div class="guide">{{ $__t( "매니져 계정의 이메일 주소를 ,(쉼표)로 구분하여 정확히 입력해주세요" ) }}</div>
                            </ui-form-list-item> -->
              <ui-form-list-item>
                <ui-textarea
                  :placeholder="formData.store_descript.placeholder"
                  :label="formData.store_descript.label"
                  :error="formData.store_descript.error"
                  v-model="formData.store_descript.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <image-uploader
                  :placeholder="formData.thumbnail_image.placeholder"
                  :label="formData.thumbnail_image.label"
                  :tag="formData.thumbnail_image.tag"
                  :error="formData.thumbnail_image.error"
                  v-model="formData.thumbnail_image.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <multiple-image-uploader
                  :placeholder="formData.detail_images.placeholder"
                  :label="formData.detail_images.label"
                  :tag="formData.detail_images.tag"
                  :error="formData.detail_images.error"
                  v-model="formData.detail_images.value"
                />
              </ui-form-list-item>
            </div>
          </li>
          <li class="form-section">
            <div class="form-section-title" @click="toggleFormSection(2)">
              {{ $__t('위치 정보') }}<i class="fa" :class="'fa-angle-' + (formSectionOpened.indexOf(2) >= 0 ? 'up' : 'down')"></i>
            </div>
            <div class="form-section-body" v-show="formSectionOpened.indexOf(2) >= 0">
              <ui-form-list-item>
                <PostCodeSelect
                  ref="postCodeSelect"
                  v-model="formData.store_zipcode.value"
                  :name="formData.store_zipcode.label"
                  :placeholder="formData.store_zipcode.placeholder"
                  :type="formData.store_zipcode.type"
                  :label="formData.store_zipcode.label"
                  @input="formData.store_zipcode.value = $event"
                  @complete="onComplete"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.store_address.placeholder"
                  :type="formData.store_address.type"
                  :label="formData.store_address.label"
                  :readonly="true"
                  :error="formData.store_address.error"
                  v-model="storeAddress"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.store_address_detail.placeholder"
                  :type="formData.store_address_detail.type"
                  :label="formData.store_address_detail.label"
                  :error="formData.store_address_detail.error"
                  v-model="formData.store_address_detail.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.district_main.placeholder"
                  :type="formData.district_main.type"
                  :label="formData.district_main.label"
                  :readonly="true"
                  :error="formData.district_main.error"
                  v-model="formData.district_main.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox
                  :placeholder="formData.district_sub.placeholder"
                  :type="formData.district_sub.type"
                  :label="formData.district_sub.label"
                  :readonly="true"
                  :error="formData.district_sub.error"
                  v-model="formData.district_sub.value"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <multiple-image-uploader
                  :placeholder="formData.directions_images.placeholder"
                  :label="formData.directions_images.label"
                  :tag="formData.directions_images.tag"
                  :error="formData.directions_images.error"
                  v-model="formData.directions_images.value"
                  :max="4"
                />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textarea
                  :placeholder="formData.store_directions.placeholder"
                  :label="formData.store_directions.label"
                  :error="formData.store_directions.error"
                  v-model="formData.store_directions.value"
                />
              </ui-form-list-item>
            </div>
          </li>
          <li class="form-section">
            <div class="form-section-title" @click="toggleFormSection(3)">
              {{ $__t('이용 정보') }}<i class="fa" :class="'fa-angle-' + (formSectionOpened.indexOf(3) >= 0 ? 'up' : 'down')"></i>
            </div>
            <div class="form-section-body" v-show="formSectionOpened.indexOf(3) >= 0">
              <ui-form-list-item>
                <ui-quantity-select
                  v-model="formData.lug_limit.value"
                  :label="formData.lug_limit.label"
                  :placeholder="formData.lug_limit.placeholder"
                  :min="0"
                />
              </ui-form-list-item>
              <!-- <ui-form-list-item>
                                <ui-quantity-select v-model="formData.lug_bigable_limit.value" :label="formData.lug_bigable_limit.label" :placeholder="formData.lug_bigable_limit.placeholder" :min="0" />
                            </ui-form-list-item> -->
              <ui-form-list-item>
                <ui-checkbox v-model="formData.time_24hour.value" :label="formData.time_24hour.label" />
              </ui-form-list-item>
              <ui-form-list-item v-show="!formData.time_24hour.value">
                <schedule-select v-model="formData.time_table.value" :label="formData.time_table.label" :check="formData.time_24hour.value" />
              </ui-form-list-item>
              <!-- <ui-form-list-item>
                              <ui-checkbox :label="formData.facilities.label" :error="formData.facilities.error" v-model="formData.facilities.value" :items="formData.facilities.items" />
                            </ui-form-list-item> -->
            </div>
          </li>
          <li class="form-section" v-if="$props.mode === 'edit'">
            <div class="form-section-title" @click="toggleFormSection(4)">
              {{ $__t('Close Dates') }}<i class="fa" :class="'fa-angle-' + (formSectionOpened.indexOf(4) >= 0 ? 'up' : 'down')"></i>
            </div>
            <div class="form-section-body" v-show="formSectionOpened.indexOf(4) >= 0">
              <div>
                <ul>
                  <li v-for="close in closeDates">
                    <span style="font-size: 16px; vertical-align: top; margin-right: 10px; margin-left: 30px"
                      ><i class="fa fa-calendar-alt" style="color: #228be6; vertical-align: text-bottom" /> &nbsp;{{ close.reserve_date_start }} ~
                      {{ close.reserve_date_end }}</span
                    ><a @click="deleteCloseDate(close.schedule_id)">
                      <i class="fa fa-trash" style="color: #b3b3b3; vertical-align: text-bottom"></i
                    ></a>
                  </li>
                  <li v-if="closeDates.length == 0">
                    <small>{{ $__t('No Data Available') }}</small>
                  </li>
                </ul>
              </div>
              <ui-text-button display="block" color="primary" class="list-more" @click="getMoreListItems" v-if="listLoadable">
                <button type="button">{{ $__t('더 불러오기') }}</button>
              </ui-text-button>
              <ui-text-button display="block" color="red"
                ><button type="button" @click="openDisableDaysForm" style="padding-top: 2rem; padding-bottom: 0">
                  {{ $__t('예약 불가 날짜 신청하기') }}
                </button></ui-text-button
              >
            </div>
          </li>
          <li class="form-section">
            <ui-solid-button class="form-submit-button" display="block" color="primary"
              ><button type="submit">
                {{ mode == 'edit' ? $__t('상점 정보 저장하기') : $__t('상점 추가하기') }}
              </button></ui-solid-button
            >
          </li>
        </ul>
      </ui-form>
    </div>
  </div>
</template>

<script>
import MultipleDaySelect from '@/components/modules/RichForm/MultipleDaySelect';
import ScheduleSelect from '@/components/modules/RichForm/ScheduleSelect';
import ImageUploader from '@/components/modules/RichForm/ImageUploader';
import MultipleImageUploader from '@/components/modules/RichForm/MultipleImageUploader';
import List from '@/components/modules/RichForm/List';
import { v4 as uuid } from 'uuid';
import PostCodeSelect from '@/components/modules/PostCodeSelect/Body';
import utils from '@/utils';

export default {
  components: {
    MultipleDaySelect,
    ScheduleSelect,
    ImageUploader,
    MultipleImageUploader,
    List,
    PostCodeSelect
  },
  props: ['mode', 'id', 'preset'],
  data() {
    return {
      formData: {
        store_name: {
          type: 'text',
          label: this.$__t('상점 이름'),
          placeholder: this.$__t('상점의 이름을 입력해주세요'),
          validation: [{ type: 'required' }],
          value: '',
          error: ''
        },
        store_phone: {
          type: 'text',
          label: this.$__t('상점 전화번호'),
          placeholder: this.$__t('상점의 전화번호를 입력해주세요'),
          validation: [{ type: 'required' }],
          value: '',
          error: ''
        },
        store_category: {
          label: this.$__t('상점 분류'),
          placeholder: this.$__t('상점의 분류를 선택해주세요'),
          validation: [{ type: 'required' }],
          items: [
            { label: this.$__t('음식점'), value: 'SC_RESTAURANT' },
            { label: this.$__t('편의점'), value: 'SC_CONVENIENCE' },
            { label: this.$__t('카페'), value: 'SC_CAFE' },
            { label: this.$__t('기타'), value: 'SC_ETC' }
          ],
          value: '',
          error: ''
        },
        store_descript: {
          type: 'text',
          label: this.$__t('상점 소개'),
          placeholder: this.$__t('상점의 소개글을 입력해주세요'),
          validation: [{ type: 'required' }],
          value: '',
          error: ''
        },
        thumbnail_image: {
          label: this.$__t('상점 썸네일 이미지'),
          placeholder: this.$__t('상점의 썸네일 이미지를 업로드해주세요'),
          value: {},
          error: '',
          tag: 'thumbnail_image'
        },
        detail_images: {
          label: this.$__t('상점 상세 이미지'),
          placeholder: this.$__t('상점 상세 이미지를 업로드해주세요'),
          value: [{}],
          error: '',
          tag: 'detail_images'
        },
        store_address: {
          type: 'text',
          label: this.$__t('상점 주소'),
          placeholder: this.$__t('상점의 주소를 입력해주세요'),
          validation: [{ type: 'required' }],
          value: '',
          error: ''
        },
        store_english_address: {
          type: 'text',
          validation: [],
          value: ''
        },
        geo_latitude: {
          type: 'text',
          validation: [],
          value: ''
        },
        geo_longitude: {
          type: 'text',
          validation: [],
          value: ''
        },
        store_address_detail: {
          type: 'text',
          label: this.$__t('store-form.store-address-detail.label'),
          placeholder: this.$__t('store-form.store-address-detail.placeholder'),
          validation: [],
          value: '',
          error: ''
        },
        store_zipcode: {
          type: 'text',
          label: this.$__t('상점 우편번호'),
          placeholder: this.$__t('상점의 우편번호를 입력해주세요'),
          validation: [{ type: 'required' }],
          value: '',
          error: ''
        },
        directions_images: {
          label: this.$__t('상점 찾아오는 길'),
          placeholder: this.$__t('상점에 찾아오는 길 이미지를 업로드해주세요'),
          value: [{}],
          error: '',
          tag: 'directions_images'
        },
        district_main: {
          label: this.$__t('시 / 도'),
          placeholder: '',
          value: '',
          error: ''
        },
        district_sub: {
          label: this.$__t('군 / 구 / 동'),
          placeholder: '',
          value: '',
          error: ''
        },
        store_directions: {
          label: this.$__t('상점 찾아오는 길 상세 설명'),
          placeholder: this.$__t('찾아오는 길의 상세 설명글을 입력해주세요'),
          value: '',
          error: ''
        },
        lug_limit: {
          type: 'text',
          label: this.$__t('물품 보관 최대 수량'),
          placeholder: this.$__t('최대 보관 가능한 물품 개수를 입력해주세요'),
          validation: [{ type: 'required' }],
          value: 1,
          error: ''
        },
        resource_key: {
          value: uuid()
        },
        base_price_unit: {
          value: 1
        },
        base_price: {
          value: 8
        },
        time_24hour: {
          label: this.$__t('Open 24 hours'),
          value: ''
        },
        time_table: {
          label: this.$__t('상점 이용 가능 시간'),
          value: ''
        },
        tag: {
          value: ''
        }
      },
      formSectionOpened: [],
      listQuery: {
        page: 1,
        size: 10
      },
      listLoadable: false,
      initialStoreAddress: ''
    };
  },
  computed: {
    doc() {
      return this.$store.getters['stores/GET_ITEM']({ key: 'id', value: this.id });
    },
    closeDates() {
      return this.$store.getters['resourceCloseDates/GET_ITEMS_BY_LIST']({ list: 'closeDates' });
    },
    lang() {
      return this.$store.state.lang;
    },
    storeAddress() {
      let value = '';

      try {
        value = utils.parseJSON(this.formData.store_address.value)[this.lang === 'ko' ? 'ko' : 'base'];
      } catch (e) {
        if (e.message.includes('Unexpected') && e.message.includes('JSON')) {
          return this.formData.store_address.value;
        }
      } finally {
        return value || this.formData.store_address.value;
      }
    }
  },
  created() {
    this.loadStoreInfo();
    this.getCloseListItems();
  },
  activated() {
    this.loadStoreInfo();
  },
  methods: {
    loadStoreInfo() {
      if (this.mode == 'edit') {
        this.$store
          .dispatch('stores/get', { id: this.id })
          .then((res) => {
            Object.keys(this.doc).forEach((o) => {
              if (this.formData[o]) {
                if (o == 'facilities') {
                  this.formData[o].value = this.doc[o].split(',');
                } else if (o == 'store_address') {
                  this.formData[o].value = this.doc[o];
                  this.initialStoreAddress = this.doc[o];
                } else if (o == 'store_address_detail') {
                  if (typeof this.doc[o] === 'object' && this.doc[o]) {
                    let isAllEmpty = Object.keys(this.doc.store_address_detail).every((lang) => this.doc.store_address_detail[lang] === '');

                    if (isAllEmpty) {
                      this.formData.store_address_detail.value = '';
                    } else {
                      this.formData.store_address_detail.value = this.doc[o][this.lang] ? this.doc[o][this.lang] : '';
                    }
                  } else {
                    this.formData[o].value = this.doc[o];
                  }
                } else {
                  this.formData[o].value = this.doc[o];
                }
              }
            });

            // 물품 개수
            // if( this.doc.lug_bigable_limit ){
            //     this.formData.lug_limit.value = parseInt( this.doc.lug_limit ) - parseInt( this.doc.lug_bigable_limit );
            //     this.formData.lug_bigable_limit.value = parseInt( this.doc.lug_bigable_limit );
            // }else{
            //     this.formData.lug_limit.value = parseInt( this.doc.lug_limit );
            //     this.formData.lug_bigable_limit.value = 0;
            // };

            this.formData.lug_limit.value = parseInt(this.doc.lug_limit);

            // this.formData.store_managers.value = this.doc.extra.store_managers && this.doc.extra.store_managers.length > 0 ? this.doc.extra.store_managers.join( ", " ) : "";
            // this.formData.store_disabled_days.value = this.doc.extra.store_disabled_days ? this.doc.extra.store_disabled_days : [];
            this.formData.district_main.value = this.doc.extra.district_main;
            this.formData.district_sub.value = this.doc.extra.district_sub;
            this.formData.time_24hour.value = this.doc.extra.time_24hour;
            this.formData.store_directions.value = this.doc.extra.store_directions;
            this.formData.detail_images.value = this.doc.resources.detail_images;
            this.formData.thumbnail_image.value = this.doc.resources.thumbnail_image;
            this.formData.directions_images.value = this.doc.resources.directions_images;
          })
          .catch((err) => {
            this.$store.commit('alert/ADD_ITEM', { message: err, status: 'error' });
          });
        this.formSectionOpened = [];
      } else {
        this.formSectionOpened = [1, 2, 3, 4];
      }
    },
    toggleFormSection(index) {
      if (this.formSectionOpened.indexOf(index) >= 0) {
        this.formSectionOpened.splice(this.formSectionOpened.indexOf(index), 1);
      } else {
        this.formSectionOpened.push(index);
      }
    },
    submitForm(values) {
      //임시로 v2와 연동하기 위한 절차
      if (this.doc) {
        values.notify_email = this.doc.notify_email ? this.doc.notify_email : 'dummy';
        values.notify_phone = this.doc.notify_phone ? this.doc.notify_phone : 'dummy';
        values.geo_latitude = values.geo_latitude ? values.geo_latitude : 'dummy';
        values.geo_longitude = values.geo_longitude ? values.geo_longitude : 'dummy';
        values.facilities = this.doc.facilities ? this.doc.facilities : 'dummy';
        values.tags = this.doc.tags ? this.doc.tags : '1';
      } else {
        values.notify_email = 'dummy';
        values.notify_phone = 'dummy';
        values.facilities = 'dummy';
        values.tags = 'dummy';
      }

      if (this.initialStoreAddress === values.store_address) delete values.store_address;

      var action =
        this.mode == 'edit'
          ? this.$store.dispatch('stores/put', { id: this.id, data: values, list: 'MyStores' })
          : this.$store.dispatch('stores/post', { data: values, list: 'MyStores' });
      action
        .then((res) => {
          this.$router.replace({ name: 'HostStores' });
          this.$store.commit('alert/ADD_ITEM', {
            message: this.mode == 'edit' ? this.$__t('상점 정보가 업데이트되었습니다.') : this.$__t('상점이 생성되었습니다.'),
            status: 'success'
          });
        })
        .catch((err) => {
          this.$store.commit('alert/ADD_ITEM', { message: err, status: 'error' });
        });
    },
    scrollToError() {
      this.formSectionOpened = [1, 2, 3, 4];
      setTimeout(() => {
        const errorList = document.querySelectorAll('form .error');

        // console.log('errorList', errorList[0]);

        if (errorList.length > 0) {
          const elOffsetTop = errorList[0].offsetTop;
          const elOffsetLeft = errorList[0].offsetLeft;
          const elHeight = errorList[0].offsetHeight;
          const windowHeight = window.innerHeight;

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffsetTop - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffsetTop;
          }

          window.scroll({
            top: offset,
            left: elOffsetLeft,
            behavior: 'smooth'
          });
        }
      }, 0);
    },
    openDisableDaysForm() {
      this.$store.commit('modal/ADD_ITEM', { component: 'CloseForm', size: 'small', payload: { id: this.id } });
      this.toggleFormSection(4);
    },
    getCloseListItems() {
      if (!this.id) {
        return;
      }

      this.$store.dispatch('resourceCloseDates/getList', { id: this.id, list: 'closeDates', query: this.listQuery }).then((res) => {
        if (this.closeDates.length == res.data.data.total || res.data.data.items.length == 0) {
          this.listLoadable = false;
        } else {
          this.listLoadable = true;
        }
      });
    },
    getMoreListItems() {
      this.listQuery.page = this.listQuery.page + 1;
      this.getCloseListItems();
    },
    deleteCloseDate(orderId) {
      this.$store.dispatch('resourceCloseDates/delete', { id: this.id, list: 'closeDates', query: { schedule_id: orderId } }).then((res) => {
        this.getCloseListItems();
      });
    },

    async onComplete(data) {
      let address_english,
        district_main,
        district_sub,
        {
          address,
          roadAddress,
          roadAddressEnglish,
          roadname,
          roadnameEnglish,
          jibunAddress,
          jibunAddressEnglish,
          sido,
          sidoEnglish,
          sigungu,
          sigunguEnglish,
          bname1,
          bname1English,
          bname2,
          bname2English,
          userSelectedType,
          geoCodeProvider,
          geo_latitude,
          geo_longitude
        } = data;

      try {
        if (!(geo_latitude > 0 && geo_longitude > 0)) {
          let res = await this.axios.get(
            this.$store.state.config.apiURL + `/v2/stores/geocode/inkorea?address=${address || roadAddress || jibunAddress}`
          );

          geo_latitude = res?.data?.data?.geometry?.location?.lat || 0;
          geo_longitude = res?.data?.data?.geometry?.location?.lng || 0;
        }

        if (userSelectedType === 'J') {
          address = jibunAddress;
          address_english = jibunAddressEnglish;
        } else if (userSelectedType === 'R') {
          address = roadAddress;
          address_english = roadAddressEnglish;
        }
        this.formData.store_address_detail.value = '';
        this.formData.geo_latitude.value = geo_latitude;
        this.formData.geo_longitude.value = geo_longitude;
        this.formData.store_english_address.value = address_english;

        this.formData.store_address.value = JSON.stringify({
          ko: address,
          base: address_english,
          roadAddress,
          roadAddressEnglish,
          roadname,
          roadnameEnglish,
          jibunAddress,
          jibunAddressEnglish,
          sido,
          sidoEnglish,
          sigungu,
          sigunguEnglish,
          eupMyeon: bname1,
          eupMyeonEnglish: bname1English,
          dongRi: bname2,
          dongRiEnglish: bname2English
        });

        district_main = sido;
        district_sub = sigungu;

        let siInSigungu = sigungu.split(' ').filter((o) => o[o.length - 1] == '시');

        if (siInSigungu.length > 0) {
          district_main += ' ' + siInSigungu;

          district_sub = sigungu.replace(siInSigungu, '').trim();

          if (district_sub == '') {
            district_sub = bname1 || bname2;
          }
        }

        this.formData.district_main.value = district_main;
        this.formData.district_sub.value = district_sub;
      } catch (e) {
        this.$log.error(e);
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      }
    }
  }
};
</script>

<style lang="scss">
#store-form {
  background-color: #fff;
}
</style>