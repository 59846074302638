<template>
  <div v-if="ticket.status" :class="[ticket.status, ticket.ticket_type]" class="lug-ticket-list-item">
    <div class="container" @click.prevent="$emit('click')">
      <div class="text-area">
        <div class="label">{{ label }}</div>
        <div class="primary">
          {{ primary }}
        </div>
        <div class="secondary">
          <div class="ticket-time">
            {{ ticketTime }}
          </div>
          <div class="ticket-quantity">
            {{ ticketQuantity }}
          </div>
        </div>
        <!-- <div class="button">
          <template v-if="buttonText">
            <button type="button" @click.stop.prevent="onClickButton">
              <span>{{ buttonText }}</span>
            </button>
          </template>
        </div> -->
      </div>
      <div class="image-area">
        <div class="frame">
          <!-- <template v-if="resourceImage">
            <div class="luggageImg">
              <img :src="resourceImage" alt="럭스테이 물품 사진" />
            </div>
          </template> -->
          <!--Festival o-->
          <template v-if="ticket?.extra?.festival === 1">
            <div class="luggageImg">
              <img :src="ticket?.store_resources?.featured_images[0]" alt="" />
            </div>
          </template>
          <!--Festival x-->
          <template v-else>
            <qr-code :code="ticket.ticket_code"></qr-code>
          </template>
        </div>
        <div v-if="ticket.ticket_type === 'LOCKER'" class="image-area__text">
          {{ $__t('{message-locker}.qrcode.PREPARING') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from '@/components/includes/QrCode';

export default {
  name: 'TicketItem',

  props: {
    ticket: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    // console.log('ticket', this.ticket);
  },

  data() {
    return {};
  },

  computed: {
    label() {
      let label = '';

      if (this.ticket.status === 'ISSUED') label = this.$__t('예약완료');
      else if (this.ticket.status === 'CHECK_IN') label = this.$__t('보관완료');
      else if (this.ticket.status === 'CHECK_OUT') label = this.$__t('찾기완료');
      else if (this.ticket.status === 'CANCELLED') label = this.$__t('취소됨');
      else label = this.$__t('EXPIRED');

      return label;
    },

    primary() {
      return this.ticket?.store_name ?? '';
    },

    ticketTime() {
      // console.log('this.ticket', this.ticket);
      let value = '';

      const dateStartString = this.ticket?.ticket_date_start
        ? this.$moment(this.ticket.ticket_date_start).format(this.$__t('MM월 DD일 HH:mm'))
        : null;
      const dateEndString = this.ticket?.ticket_date_end ? this.$moment(this.ticket.ticket_date_end).format(this.$__t('MM월 DD일 HH:mm')) : null;

      value = `${dateStartString} ~ ${dateEndString}`;

      return value;
    },

    ticketQuantity() {
      let value = '';
      if (this.ticket?.extras?.version) {
        if (this.ticket?.ticket_quantity > 0) {
          let quantityString = `${this.$__t('물품')} ${this.ticket.ticket_quantity}`;

          if (this.ticket.ticket_quantity === 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }
      } else {
        if (this.ticket?.ticket_quantity > 0) {
          let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.ticket.ticket_quantity}`;

          if (this.ticket.ticket_quantity === 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }

        if (this.ticket?.ticket_over_quantity > 0) {
          let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.ticket.ticket_over_quantity}`;

          if (this.ticket.ticket_quantity > 0) quantityString = ' ' + quantityString;

          if (this.ticket.ticket_over_quantity === 1) {
            value += quantityString + this.$__t('item');
          } else {
            value += quantityString + this.$__t('items');
          }
        }
      }

      return value;
    },

    resourceImage() {
      return this.ticket?.resources?.luggage_photo?.resource_url;
    },

    buttonText() {
      const ticketStatus = this.ticket?.status;
      let value = '';

      if (ticketStatus === 'ISSUED') {
        if (this.ticket?.extra?.festival !== 1) {
          value = this.$__t('Cancel Booking');
        }
      }
      return value;
    }
  },

  methods: {
    onClickButton() {
      const ticketStatus = this.ticket?.status;

      if (ticketStatus === 'ISSUED') {
        this.$router.push({ name: 'CancelTicket', params: { id: this.ticket.ticket_code } });
      } else if (ticketStatus === 'CHECK_OUT') {
        this.$emit('store-single', this.ticket);
      }
    }
  },

  components: {
    QrCode
  }
};
</script>
