<template>
	<div v-if="opened" id="luggage-selector" @click.stop="clickClose">
		<div class="container" @click.stop="">
			<i class="fa fa-times" @click.stop="clickClose"></i>
			<div class="luggage-selector">
				<ul>
					<li>
						<div class="luggage-label">
							<img src="/img/luggage-small.png" class="luggage" />
							<span class="luggage-text">Small</span>
						</div>
						<div class="luggage-value"><img src="/img/minus-circle@3x.png" @click="decreaseSmallLuggage" class="button" />{{ luggageCount.quantity_small }}<img src="/img/plus-circle@3x.png" @click="increaseSmallLuggage" class="button" /></div>
					</li>
          <li>
						<div class="luggage-label">
							<img src="/img/luggage-big.png" class="luggage" />
							<span class="luggage-text">Large</span>
						</div>
						<div class="luggage-value"><img src="/img/minus-circle@3x.png" @click="decreaseBigLuggage" class="button" />{{ luggageCount.quantity_big }}<img src="/img/plus-circle@3x.png" @click="increaseBigLuggage" class="button" /></div>
					</li>
				</ul>
			</div>
			<div class="information">
				<span @click="openPriceLuggage"><span class="text">{{ $__t('Types of items and Price') }}</span> <img src="/img/question@3x.png"/></span>
			</div>
			<div class="submit-button">
				<ui-solid-button color="primary" display="block" size="small" @click="onSubmit">
					<button type="button">
						<span> {{ $__t('Confirm') }} </span>
					</button>
				</ui-solid-button>
			</div>
		</div>
    <transition name="slide-down">
			<luggage-popup ref="luggagePopup" />
		</transition>
	</div>
</template>

<script>
import LuggageExplanation from '@/components/pages/luggage/LuggageExplanation';
import Price from '@/components/pages/_/Price';
import LuggagePopup from '@/components/pages/luggage/LuggagePopup';

export default {
  components: {
    LuggageExplanation,
    Price,
    LuggagePopup
  },
	props: ['counts'],
	data() {
		return {
			opened: false,
			luggageCount: {
				quantity_small: this.$props.counts.quantity_small,
				quantity_big: this.$props.counts.quantity_big,
			},
		};
	},
	methods: {
		eventHandler(e) {
			if (e.target.location.hash != '#luggage') {
				this.opened = false;
			}
		},
		openLuggage() {
			this.opened = true;
			window.history.pushState({}, null, '#luggage');
			window.addEventListener('popstate', this.eventHandler);

			this.luggageCount.quantity_small = this.$props.counts.quantity_small;
			this.luggageCount.quantity_big = this.$props.counts.quantity_big;
		},
		closeLuggage() {
			this.opened = false;
      window.removeEventListener('popstate', this.eventHandler);
		},
		clickClose() {
			window.history.go(-1);
		},
		onSubmit() {
			this.$emit('onSelected', this.luggageCount);
			window.history.go(-1);
			this.closeLuggage();
		},
		decreaseBigLuggage() {
			if (this.luggageCount.quantity_big > 0) {
				this.luggageCount.quantity_big -= 1;
			}
		},
		increaseBigLuggage() {
			if (this.luggageCount.quantity_big < 99) {
				this.luggageCount.quantity_big += 1;
			}
		},
		decreaseSmallLuggage() {
			if (this.luggageCount.quantity_small > 0) {
				this.luggageCount.quantity_small -= 1;
			}
		},
		increaseSmallLuggage() {
			if (this.luggageCount.quantity_small < 99) {
				this.luggageCount.quantity_small += 1;
			}
		},
		openModal(ref) {
			this.$refs[ref].open(ref);
		},
		closeModal(ref) {
			this.$refs[ref].close();
    },
    openPriceLuggage(){
      this.$refs.luggagePopup.openLuggage();
    }
	},
};
</script>
