<template>
  <div id="store-delivery">
    <div class="page-body">
      <div id="reservation-form-step">
        <div class="ticket-list" v-if="loading" style="position:fixed; width:100%; height: 100%; z-index:10000; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0,0,0,0.51);">
          <div style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center;">
            <i class="fa fa-spinner fa-pulse"></i>
            <div style="white-spce: nowrap; font-size: 2rem; position: fixed; top: 50%; left: 50%; transform: translate(-50%, 50%)" v-if="invoiceLoading">
              {{ $__t('{delivery-step2}.invoiceloading') }}
            </div>
          </div>          
        </div>
        <div v-else class="container">
          <template>
            <div class="need-login" v-if="!$store.state.auth.gid">
              <p>{{ $__t("You need to login to make a reservation.") }}</p>
              <ui-solid-button size="small"
                ><router-link
                  :to="{
                    name: 'AuthLogin',
                    query: { redirect: $router.currentRoute.fullPath },
                  }"
                  >{{ $__t("login") }}</router-link
                ></ui-solid-button
              >
            </div>
            <div class="payment-step">
              <img src="/img/payment-step-indicator-2.png" />
            </div>
            <div class="form" v-if="$store.state.auth.gid">
              <ui-form ref="form" @submit="submitForm" @error="scrollToError" :formData="filteredFormData" ignoreSubmit="{true}">
                <div class="form-section information-section delivery-section">
                  <div class="form-section-title">
                    {{ $__t("{delivery-step2}.title") }}
                  </div>
                  <div class="form-section-body">
                    <div class="title">
                      {{ $__t("{delivery-step2}.keyword.sender") }}
                    </div>
                    <div class="line"></div>
                    <ul>
                      <ui-form-list-multiple-item>
                        <ui-textbox v-model="formData.sender_user_first_name.value" :placeholder="formData.sender_user_first_name.placeholder" :type="formData.sender_user_first_name.type" :label="formData.sender_user_first_name.label" :error="formData.sender_user_first_name.error" />

                        <ui-textbox v-model="formData.sender_user_last_name.value" :placeholder="formData.sender_user_last_name.placeholder" :type="formData.sender_user_last_name.type" :label="formData.sender_user_last_name.label" :error="formData.sender_user_last_name.error" />
                      </ui-form-list-multiple-item>

                      <ui-form-list-multiple-item>
                        <ui-auto-complete v-model="formData.sender_user_dial_code.value" type="dial" :placeholder="formData.sender_user_dial_code.placeholder" :label="formData.sender_user_dial_code.label" :error="formData.sender_user_dial_code.error" :items="formData.sender_user_dial_code.items" />
                        <ui-textbox v-model="formData.sender_user_phone.value" :placeholder="formData.sender_user_phone.placeholder" :type="formData.sender_user_phone.type" :label="formData.sender_user_phone.label" :error="formData.sender_user_phone.error" maxLength="15" />
                      </ui-form-list-multiple-item>
                    </ul>
                    <div class="section minor">
                      <div class="title small">
                        {{ $__t("{delivery-step2}.keyword.sendfrom") }}
                      </div>
                      <div class="type">
                        <ui-solid-button v-if="dispatchAt == 'store'" ref="sendfromstore" display="inline" size="small" color="brightblue" @click="handleTypeButton('sendfromstore')"
                          ><button type="button">
                            {{ $__t("{delivery-step2}.button.sendfromstore") }}
                          </button></ui-solid-button
                        >
                        <ui-border-round-button v-else ref="sendfromstore" display="inline" size="small" color="disabled" @click="handleTypeButton('sendfromstore')"
                          ><button type="button">
                            {{ $__t("{delivery-step2}.button.sendfromstore") }}
                          </button></ui-border-round-button
                        >
                        <ui-solid-button v-if="dispatchAt == 'elsewhere'" ref="sendfromelsewhere" display="inline" size="small" color="brightblue" @click="handleTypeButton('sendfromelsewhere')"
                          ><button type="button">
                            {{ $__t("{delivery-step2}.button.sendfromelsewhere") }}
                          </button></ui-solid-button
                        >
                        <ui-border-round-button v-else ref="sendfromelsewhere" display="inline" size="small" color="disabled" @click="handleTypeButton('sendfromelsewhere')"
                          ><button type="button">
                            {{ $__t("{delivery-step2}.button.sendfromelsewhere") }}
                          </button></ui-border-round-button
                        >
                        <div v-if="dispatchAtError" class="error">
                          {{ dispatchAtError }}
                        </div>
                      </div>
                      <div v-if="dispatchAt != ''" class="store-information">
                        <div v-if="dispatchAt == 'store' && origin_store" class="search-input">
                          <img src="/img/icon-search-blue.png" class="icon search" />
                          <input class="store" :value="origin_store.store_name" ref="searchStoreInput" type="text" @focus="onFocusStore('origin')" :placeholder="formData.origin_store_name.placeholder" readonly />
                          <img src="/img/modal-close.png" @click="deleteStore('origin')" class="icon delete" />
                        </div>
                        <ul>
                          <ui-form-list-multiple-item v-if="dispatchAt == 'elsewhere'">
                            <PostCodeSelect ref="postCodeSelect" :searchIcon="true" v-model="formData.origin_custom_zipcode.value" :name="formData.origin_custom_zipcode.label" :placeholder="formData.origin_custom_zipcode.placeholder" :type="formData.origin_custom_zipcode.type" :label="formData.origin_custom_zipcode.label" :error="formData.origin_custom_zipcode.error" @complete="onComplete('origin', $event)" />
                          </ui-form-list-multiple-item>
                          <ui-form-list-multiple-item>
                            <ui-textbox v-if="dispatchAt == 'store'" v-model="formData.origin_store_zipcode.value" :placeholder="formData.origin_store_zipcode.placeholder" :type="formData.origin_store_zipcode.type" :label="formData.origin_store_zipcode.label" :error="formData.origin_store_zipcode.error" :readonly="true" />
                          </ui-form-list-multiple-item>
                          <ui-form-list-item>
                            <ui-textbox v-if="dispatchAt == 'store'" v-model="formData.origin_store_address.value" :placeholder="formData.origin_store_address.placeholder" :type="formData.origin_store_address.type" :label="formData.origin_store_address.label" :error="formData.origin_store_address.error" :readonly="true" />
                            <ui-textbox v-else :value="originCustomAddress" :placeholder="formData.origin_custom_address.placeholder" :type="formData.origin_custom_address.type" :label="formData.origin_custom_address.label" :error="formData.origin_custom_address.error" :readonly="true" />
                          </ui-form-list-item>
                          <ui-form-list-item>
                            <ui-textbox v-if="dispatchAt == 'store'" v-model="formData.origin_store_address_detail.value" :placeholder="formData.origin_store_address_detail.placeholder" :type="formData.origin_store_address_detail.type" :label="formData.origin_store_address_detail.label" :error="formData.origin_store_address_detail.error" :readonly="true" />
                            <ui-textbox v-else v-model="formData.origin_custom_address_detail.value" :placeholder="formData.origin_custom_address_detail.placeholder" :type="formData.origin_custom_address_detail.type" :label="formData.origin_custom_address_detail.label" :error="formData.origin_custom_address_detail.error" :readonly="!formData.origin_custom_address.value"/>
                          </ui-form-list-item>
                        </ul>
                      </div>
                    </div>
                    <div class="section">
                      <div class="title">
                        {{ $__t("{delivery-step2}.keyword.recipient") }}
                      </div>
                      <div class="line" />
                      <ul>
                        <ui-form-list-item style="margin-bottom:1rem">
                          <ui-checkbox v-model="formData.identical_information.value" :label="formData.identical_information.label" :error="formData.identical_information.error" />
                        </ui-form-list-item>
                        <ui-form-list-multiple-item>
                          <ui-textbox v-model="formData.recipient_user_first_name.value" :placeholder="formData.recipient_user_first_name.placeholder" :type="formData.recipient_user_first_name.type" :label="formData.recipient_user_first_name.label" :error="formData.recipient_user_first_name.error" />

                          <ui-textbox v-model="formData.recipient_user_last_name.value" :placeholder="formData.recipient_user_last_name.placeholder" :type="formData.recipient_user_last_name.type" :label="formData.recipient_user_last_name.label" :error="formData.recipient_user_last_name.error" />
                        </ui-form-list-multiple-item>

                        <ui-form-list-multiple-item>
                          <ui-auto-complete v-model="formData.recipient_user_dial_code.value" type="dial" :placeholder="formData.recipient_user_dial_code.placeholder" :label="formData.recipient_user_dial_code.label" :error="formData.recipient_user_dial_code.error" :items="formData.recipient_user_dial_code.items" />
                          <ui-textbox v-model="formData.recipient_user_phone.value" :placeholder="formData.recipient_user_phone.placeholder" :type="formData.recipient_user_phone.type" :label="formData.recipient_user_phone.label" :error="formData.recipient_user_phone.error" maxLength="15" />
                        </ui-form-list-multiple-item>

                        <ui-form-list-item>
                          <ui-textbox v-model="formData.ticket_password.value" :placeholder="formData.ticket_password.placeholder" :type="formData.ticket_password.type" :label="formData.ticket_password.label" :error="formData.ticket_password.error" autocomplete="new-password" maxLength="4" iconSrc="/img/icon-lock-blue.png"/>
                        </ui-form-list-item>
                      </ul>
                      <div class="section minor">
                        <div class="title small">
                          {{ $__t("{delivery-step2}.keyword.receiveat") }}
                        </div>
                        <div class="type">
                          <ui-solid-button v-if="receiveAt == 'store'" ref="receiveatstore" display="inline" size="small" color="brightblue" @click="handleTypeButton('receiveatstore')"
                            ><button type="button">
                              {{ $__t("{delivery-step2}.button.receiveatstore") }}
                            </button></ui-solid-button
                          >
                          <ui-border-round-button v-else ref="receiveatstore" display="inline" size="small" color="disabled" @click="handleTypeButton('receiveatstore')"
                            ><button type="button">
                              {{ $__t("{delivery-step2}.button.receiveatstore") }}
                            </button></ui-border-round-button
                          >
                          <ui-solid-button v-if="receiveAt == 'elsewhere'" ref="receiveatelsewhere" display="inline" size="small" color="brightblue" @click="handleTypeButton('receiveatelsewhere')"
                            ><button type="button">
                              {{ $__t("{delivery-step2}.button.receiveatelsewhere") }}
                            </button></ui-solid-button
                          >
                          <ui-border-round-button v-else ref="receiveatelsewhere" display="inline" size="small" color="disabled" @click="handleTypeButton('receiveatelsewhere')"
                            ><button type="button">
                              {{ $__t("{delivery-step2}.button.receiveatelsewhere") }}
                            </button></ui-border-round-button
                          >
                          <div v-if="receiveAtError" class="error">
                            {{ receiveAtError }}
                          </div>
                        </div>                        
                        <div v-if="receiveAt != ''" class="store-information">
                          <div v-if="receiveAt == 'store' && destination_store" class="search-input">
                            <img src="/img/icon-search-blue.png" class="icon search" />
                            <input class="store" :value="destination_store.store_name" ref="searchStoreInput" type="text" @focus="onFocusStore('destination')" :placeholder="formData.destination_store_name.placeholder" readonly />
                            <img src="/img/modal-close.png" @click="deleteStore('destination')" class="icon delete" />
                          </div>
                          <ul>
                            <ui-form-list-multiple-item v-if="receiveAt == 'elsewhere'">
                              <PostCodeSelect
                                ref="postCodeSelect"
                                :searchIcon="true"
                                v-model="formData.destination_custom_zipcode.value"
                                :name="formData.destination_custom_zipcode.label"
                                :placeholder="formData.destination_custom_zipcode.placeholder"
                                :type="formData.destination_custom_zipcode.type"
                                :label="formData.destination_custom_zipcode.label"
                                :error="formData.destination_custom_zipcode.error"
                                @complete="onComplete('destination', $event)"
                              />
                            </ui-form-list-multiple-item>
                            <ui-form-list-multiple-item>
                              <ui-textbox v-if="receiveAt == 'store'" v-model="formData.destination_store_zipcode.value" :placeholder="formData.destination_store_zipcode.placeholder" :type="formData.destination_store_zipcode.type" :label="formData.destination_store_zipcode.label" :error="formData.destination_store_zipcode.error" :readonly="true" />
                            </ui-form-list-multiple-item>
                            <ui-form-list-item>
                              <ui-textbox v-if="receiveAt == 'store'" v-model="formData.destination_store_address.value" :placeholder="formData.destination_store_address.placeholder" :type="formData.destination_store_address.type" :label="formData.destination_store_address.label" :error="formData.destination_store_address.error" :readonly="true" />
                              <ui-textbox v-else :value="destinationCustomAddress" :placeholder="formData.destination_custom_address.placeholder" :type="formData.destination_custom_address.type" :label="formData.destination_custom_address.label" :error="formData.destination_custom_address.error" :readonly="true" />
                            </ui-form-list-item>
                            <ui-form-list-item>
                              <ui-textbox v-if="receiveAt == 'store'" v-model="formData.destination_store_address_detail.value" :placeholder="formData.destination_store_address_detail.placeholder" :type="formData.destination_store_address_detail.type" :label="formData.destination_store_address_detail.label" :error="formData.destination_store_address_detail.error" :readonly="true" />
                              <ui-textbox v-else v-model="formData.destination_custom_address_detail.value" :placeholder="formData.destination_custom_address_detail.placeholder" :type="formData.destination_custom_address_detail.type" :label="formData.destination_custom_address_detail.label" :error="formData.destination_custom_address_detail.error" :readonly="!formData.destination_custom_address.value"/>
                            </ui-form-list-item>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="delivery-form-action submit-section">
                  <ui-solid-button color="brightblue" display="block" @click="$refs.form.submit()" :disabled="isValidJourney ? false : true">
                    <button type="button">{{ $__t("Next") }}</button>
                  </ui-solid-button>
                </div>
              </ui-form>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import countryCodes from "@/assets/json/country-codes.json";
import CouponApplier from "@/components/modules/RichForm/CouponApplier";
import PostCodeSelect from "@/components/modules/PostCodeSelect/Body";

export default {
  inheritAttrs: false,
  name: "DeliveryFormStep2",
  components: {
    CouponApplier,
    PostCodeSelect,
  },
  data() {
    var _code = countryCodes.map((o) => {
      return { name: o.name, code: o.code };
    });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });
    _code.forEach(o => {
			if(o.name === 'Korea, Republic of'){
				_code.splice(_code.indexOf(o), 1)
				_code.unshift(o);
			}
      if(o.name === 'Others'){
				_code.splice(_code.indexOf(o), 1)
				_code.push(o);
			}
		})

    var _dial_code = countryCodes.map((o) => {
      return { name: o.name, code: o.dial_code };
    });
    _dial_code.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    _dial_code.forEach(o => {
			if(o.name === 'Korea, Republic of'){
				_dial_code.splice(_dial_code.indexOf(o), 1)
				_dial_code.unshift(o);
			}
      if(o.name === 'Others'){
				_dial_code.splice(_dial_code.indexOf(o), 1)
				_dial_code.push(o);
			}
		})

    let userData = this.userData();

    return {
      loading: false,
      reserve: null,
      state: {},
      countryCodes: countryCodes,
      schedules: null,
      formData: {
        date_start: this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm'),
        date_end: this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm'),
        update_information: {
          label: this.$__t("계정 정보를 업데이트하겠습니다."),
          value: true,
          error: "",
        },
        sender_user_last_name: {
          type: "text",
          label: this.$__t("성"),
          placeholder: this.$__t("Korean or English"),
          validation: [{ type: "required" }, { type: "koreanOrEnglish" }],
          value: userData.sender_user_last_name,
          error: "",
        },
        sender_user_first_name: {
          type: "text",
          label: this.$__t("이름"),
          placeholder: this.$__t("Korean or English"),
          validation: [{ type: "required" }, { type: "koreanOrEnglish" }],
          value: userData.sender_user_first_name,
          error: "",
        },
        sender_user_dial_code: {
          label: this.$__t("국가 번호"),
          placeholder: this.$__t("국가 번호를 선택해주세요"),
          validation: [{ type: "required" }],
          items: _dial_code.map((o) => {
            return { label: o.code + " (" + o.name + ")", value: o.code };
          }),
          value: userData.sender_user_dial_code,
          error: "",
        },
        sender_user_phone: {
          type: "text",
          label: this.$__t("휴대폰 번호"),
          placeholder: this.$__t("휴대폰 번호를 입력해주세요"),
          validation: [{ type: "required" }, { type: "number" }],
          value: userData.sender_user_phone,
          error: "",
        },
        ticket_password: {
          type: "text",
          label: this.$__t("{delivery-step3}.key.verifyticketpassword"),
          placeholder: this.$__t("Use password length of 4"),
          validation: [{ type: "required" }, { type: "number" }, { type: "length", length: 4}],
          value: userData.ticket_password,
          error: "",
        },
        identical_information: {
          label: this.$__t("{delivery-step2}.checkbox.label.identical_information"),
          value: userData.identical_information,
          error: "",
        },
        recipient_user_last_name: {
          type: "text",
          label: this.$__t("성"),
          placeholder: this.$__t("Korean or English"),
          validation: [{ type: "required" }, { type: "koreanOrEnglish" }],
          value: userData.recipient_user_last_name,
          error: "",
        },
        recipient_user_first_name: {
          type: "text",
          label: this.$__t("이름"),
          placeholder: this.$__t("Korean or English"),
          validation: [{ type: "required" }, { type: "koreanOrEnglish" }],
          value: userData.recipient_user_first_name,
          error: "",
        },
        recipient_user_dial_code: {
          label: this.$__t("국가 번호"),
          placeholder: this.$__t("국가 번호를 선택해주세요"),
          validation: [{ type: "required" }],
          items: _dial_code.map((o) => {
            return { label: o.code + " (" + o.name + ")", value: o.code };
          }),
          value: userData.recipient_user_dial_code,
          error: "",
        },
        recipient_user_phone: {
          type: "text",
          label: this.$__t("휴대폰 번호"),
          placeholder: this.$__t("휴대폰 번호를 입력해주세요"),
          validation: [{ type: "required" }, { type: "number" }],
          value: userData.recipient_user_phone,
          error: "",
        },
        quantitySmall: userData.quantity_small,
				quantityBig: userData.quantity_big,
				date_start: this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm'),
				date_end: this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm'),
				coupon_code: {
					value: '',
				}
      },

      dispatchAt: "",
      receiveAt: "",
      dispatchAtError: '',
      receiveAtError: '',

      couponData: false,
      calendarLoading: false,
      quantityFilterOpened: false,
      dateStartFilterOpened: false,
      dateEndFilterOpened: false,
      couponFilterOpened: true,

      dateStartError: null,
      dateEndError: null,

      availableTimes: [],
      availableDates: [],
      paymentData: {},

      loading: true,
      invoiceLoading: false,

      origin_store: {},
      destination_store: {},

      origin_store_name: {
        type: "text",
        label: this.$__t("{delivery.step2}.store_name.label"),
        placeholder: this.$__t("{delivery.step2}.store_name.placeholder"),
        value: "",
        error: "",
      },
      origin_store_zipcode: {
        type: "text",
        label: this.$__t("상점 우편번호"),
        placeholder: this.$__t("{delivery.step2}.store_zipcode.placeholder"),
        validation: [{ type: "required" }],
        value: "",
        error: "",
      },
      origin_store_address: {
        type: "text",
        label: this.$__t("주소"),
        placeholder: this.$__t("{delivery.step2}.store_address.placeholder"),
        validation: [{ type: "required" }],
        value: "",
        error: "",
      },
      origin_store_address_detail: {
        type: "text",
        label: this.$__t("store-form.store-address-detail.label"),
        placeholder: this.$__t("{delivery.step2}.store_address_detail.placeholder"),
        value: "",
        error: "",
      },
      origin_custom_name: {
        type: "text",
        label: this.$__t("{delivery.step2}.custom_name.label"),
        placeholder: this.$__t("{delivery.step2}.custom_name.placeholder"),
        value: "",
        error: "",
      },
      origin_custom_zipcode: {
        type: "text",
        label: this.$__t("상점 우편번호"),
        placeholder: this.$__t("{delivery.step2}.custom_zipcode.placeholder"),
        validation: [{ type: "required" }],
        value: userData.origin_custom_zipcode,
        error: "",
      },
      origin_custom_address: {
        type: "text",
        label: this.$__t("주소"),
        placeholder: this.$__t("{delivery.step2}.custom_address.placeholder"),
        value: userData.origin_custom_address,
        error: "",
      },
      origin_custom_address_detail: {
        type: "text",
        label: this.$__t("store-form.store-address-detail.label"),
        placeholder: this.$__t("{delivery.step2}.origin_custom_address_detail.placeholder"),
        validation: [{ type: "required" }],
        value: userData.origin_custom_address_detail,
        error: "",
      },
      destination_store_name: {
        type: "text",
        label: this.$__t("{delivery.step2}.store_name.label"),
        placeholder: this.$__t("{delivery.step2}.store_name.placeholder"),
        value: "",
        error: "",
      },
      destination_store_zipcode: {
        type: "text",
        label: this.$__t("상점 우편번호"),
        placeholder: this.$__t("{delivery.step2}.store_zipcode.placeholder"),
        validation: [{ type: "required" }],
        value: "",
        error: "",
      },
      destination_store_address: {
        type: "text",
        label: this.$__t("주소"),
        placeholder: this.$__t("{delivery.step2}.store_address.placeholder"),
        validation: [{ type: "required" }],
        value: "",
        error: "",
      },
      destination_store_address_detail: {
        type: "text",
        label: this.$__t("store-form.store-address-detail.label"),
        placeholder: this.$__t("{delivery.step2}.store_address_detail.placeholder"),
        value: "",
        error: "",
      },
      destination_custom_name: {
        type: "text",
        label: this.$__t("{delivery.step2}.custom_name.label"),
        placeholder: this.$__t("{delivery.step2}.custom_name.placeholder"),
        value: "",
        error: "",
      },
      destination_custom_zipcode: {
        type: "text",
        label: this.$__t("상점 우편번호"),
        placeholder: this.$__t("{delivery.step2}.custom_zipcode.placeholder"),
        validation: [{ type: "required" }],
        value: userData.custom_zipcode,
        error: "",
      },
      destination_custom_address: {
        type: "text",
        label: this.$__t("주소"),
        placeholder: this.$__t("{delivery.step2}.custom_address.placeholder"),
        value: userData.custom_address,
        error: "",
      },
      destination_custom_address_detail: {
        type: "text",
        label: this.$__t("store-form.store-address-detail.label"),
        placeholder: this.$__t("{delivery.step2}.destination_custom_address_detail.placeholder"),
        validation: [{ type: "required" }],
        value: userData.custom_address_detail,
        error: "",
      },
    };
  },
  watch: {
    formData: {
      handler: _.debounce(function() {
        if (this.formData.identical_information.value == true) {
          this.formData.recipient_user_last_name.value = this.formData.sender_user_last_name.value;
          this.formData.recipient_user_first_name.value = this.formData.sender_user_first_name.value;
          this.formData.recipient_user_dial_code.value = this.formData.sender_user_dial_code.value;
          this.formData.recipient_user_phone.value = this.formData.sender_user_phone.value;
        } else {
          this.formData.recipient_user_last_name.value = "";
          this.formData.recipient_user_first_name.value = "";
          this.formData.recipient_user_dial_code.value = "";
          this.formData.recipient_user_phone.value = "";
        }

        this.onChangeFormData();
      }, 100),
      deep: true,
    },
    dispatchAt: {
      handler(value) {
        this.onChangeOrigin(value);
      },
    },
    receiveAt: {
      handler(value) {
        this.onChangeDestination(value);
      },
    },
  },
  computed: {
    filteredFormData() {
      let filteredFormData = null;

      if (this.dispatchAt == "store") {
        filteredFormData = this.onChangeOrigin("store");
      }
      if (this.dispatchAt == "elsewhere") {
        filteredFormData = this.onChangeOrigin("elsewhere");
      }
      if (this.receiveAt == "store") {
        filteredFormData = this.onChangeDestination("store");
      }
      if (this.receiveAt == "elsewhere") {
        filteredFormData = this.onChangeDestination("elsewhere");
      }

      return filteredFormData;
    },
    isValidJourney(){
      if(!this.$data.dispatchAt || !this.$data.receiveAt) return false;

      if(this.$data.dispatchAt === this.$data.receiveAt){

        if(this.$data.dispatchAt == 'store'){ // 상점에서 상점
          if(this.$data.origin_store.id == this.$data.destination_store.id){
            this.$store.commit('alert/ADD_ITEM', {message: this.$__t("{delivery-step2}.alert.same_store"), status: 'error'})
            return false;
          }

          return true;

        }else{ // 원하는 곳에서 원하는 곳
          if((this.formData.origin_custom_address.value == this.formData.destination_custom_address.value) && (this.formData.origin_custom_address_detail.value == this.formData.destination_custom_address_detail.value) ){
            this.$store.commit('alert/ADD_ITEM', {message: this.$__t("{delivery-step2}.alert.same_place"), status: 'error'})
            return false;
          }
          
          return true;
        }

      }

      if(this.$data.dispatchAtError || this.$data.receiveAtError){
        return false;
      }

      return true;
    },
    lang(){
        return this.$store.state.lang;
    },
    originCustomAddress(){
      let value = this.formData.origin_custom_address.value;

      try{
        if(this.lang == 'ko'){
          value = value['ko']
        }else{
            value = value['base']
        }

        return value
      }catch(e){ return }
    },
    destinationCustomAddress(){
      let value = this.formData.destination_custom_address.value;

      try{
        if(this.lang == 'ko'){
          value = value['ko']
        }else{
            value = value['base']
        }

        return value
      }catch(e){ return }
    }
  },
  methods: {
    onClickSubmit() {
      this.$refs.form.submit();
    },

    submit() {
      this.$refs.form.submit();
    },

    async submitForm(values) {
      this.invoiceLoading = true;
      if(this.$data.dispatchAt == null || this.$data.dispatchAt == ''){        
        this.$data.dispatchAtError = this.$__t('{delivery-step2}.error.dispatchAt')            
      }else{
        this.$data.dispatchAtError = ''
      }

      if(this.$data.receiveAt == null || this.$data.receiveAt == ''){
        this.$data.receiveAtError = this.$__t('{delivery-step2}.error.receiveAt')        
      }else{
        this.$data.receiveAtError = ''
      }

      if(this.$data.receiveAtError || this.$data.dispatchAtError){
        this.scrollToError();
        return;
      }

      if (!this.formData.sender_user_dial_code.items.some((e) => e.value === values.sender_user_dial_code)) {
        this.formData.sender_user_dial_code.error = this.$__t("Please select a existing value among the list.");
        return false;
      }
      if (!this.formData.recipient_user_dial_code.items.some((e) => e.value === values.recipient_user_dial_code)) {
        this.formData.recipient_user_dial_code.error = this.$__t("Please select a existing value among the list.");
        return false;
      }

      if (this.checkName(values)) return;

      this.loading = true;

      let data = {
        sender_store_id: values.origin_store_zipcode ? parseInt(this.$route.query.store_for_origin) : 0,
        sender_zipcode: values.origin_store_zipcode ? values.origin_store_zipcode : values.origin_custom_zipcode,
        sender_address: values.origin_store_address ? values.origin_store_address : values.origin_custom_address,
        sender_address_detail: values.origin_store_address_detail ? values.origin_store_address_detail : values.origin_custom_address_detail,
        recipient_store_id: values.destination_store_zipcode ? parseInt(this.$route.query.store_for_destination) : 0,
        recipient_zipcode: values.destination_store_zipcode ? values.destination_store_zipcode : values.destination_custom_zipcode,
        recipient_address: values.destination_store_address ? values.destination_store_address : values.destination_custom_address,
        recipient_address_detail: values.destination_store_address_detail ? values.destination_store_address_detail : values.destination_custom_address_detail,

        sender_first_name: values.sender_user_first_name,
        sender_last_name: values.sender_user_last_name,
        sender_phone: values.sender_user_phone,
        
        recipient_first_name: values.recipient_user_first_name,
        recipient_last_name: values.recipient_user_last_name,
        recipient_phone: values.recipient_user_phone,
        
        password: this.formData.ticket_password.value,
        start_date_time: this.formData.date_start,
        end_date_time: this.formData.date_end,
        quantity_small: this.formData.quantitySmall,
        quantity_big: this.formData.quantityBig,
        luggage_name: this.$route.query.item_type,
        delivery_type: this.$route.query.delivery_type.toUpperCase()
        // coupon_code: this.formData.coupon_code,
        // discount_code: this.formData.discount_code,
      }

      try{
        data = await this.$store.dispatch('resourceDelivery/new', data )        
        
        let query = {}

        query.reserve_id = data.reserve_id;
        
        this.$router.push({name: 'StoreDeliveryStep3', query: query, params: data })
      }
      catch(e){        
        this.$store.commit( "alert/ADD_ITEM", { message: this.$__t(e), status: "error" })
      }
      finally{
        this.invoiceLoading = false;
        this.loading = false;
      }
      
    },

    checkName(values) {
      var regType = /^[가-힣|A-Za-z]*$/;
      var error = null;

      if (!values.sender_user_first_name) {
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.sender_user_first_name.error = error.message;
      } else if (!regType.test(values.sender_user_first_name)) {
        this.formData.sender_user_first_name.value = "";
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.sender_user_first_name.error = error.message;
      } else {
        this.$data.formData.sender_user_first_name.error = null;
      }

      if (!values.sender_user_last_name) {
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.sender_user_last_name.error = error.message;
      } else if (!regType.test(values.sender_user_last_name)) {
        this.formData.sender_user_last_name.value = "";
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.sender_user_last_name.error = error.message;
      } else {
        this.$data.formData.sender_user_last_name.error = null;
      }

      if (!values.recipient_user_first_name) {
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.recipient_user_first_name.error = error.message;
      } else if (!regType.test(values.recipient_user_first_name)) {
        this.formData.recipient_user_first_name.value = "";
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.recipient_user_first_name.error = error.message;
      } else {
        this.$data.formData.recipient_user_first_name.error = null;
      }

      if (!values.recipient_user_last_name) {
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.recipient_user_last_name.error = error.message;
      } else if (!regType.test(values.recipient_user_last_name)) {
        this.formData.recipient_user_last_name.value = "";
        error = new Error(this.$__t("Please insert Korean or English"));
        this.$data.formData.recipient_user_last_name.error = error.message;
      } else {
        this.$data.formData.recipient_user_last_name.error = null;
      }

      return error;
    },

    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ui-form-list-item div.error')
        if(errorList.length >= 0){     

          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();
          
          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
          }
          else {
            offset = elOffset;
          }

           $('html, body').animate({scrollTop:offset}, 200);
        }        
      }, 0)
    },

    async loadSchedules() {
      var schedules = [];

      this.$data.calendarLoading = true;

      try {
        let res = await this.axios.get(this.$store.state.config.apiURL + `/v2/stores/${this.store.id}/schedule`);
        let { data, error } = res.data;
        if (data && !error) {
          data.schedules.forEach((o) => {
            if (o.status == "available") {
              if (
                this.$moment(o.date)
                  .startOf("day")
                  .isSameOrAfter(this.$moment().startOf("day"))
              ) {
                o.times.forEach((p) => {
                  if (
                    p.status == "available" &&
                    this.$moment()
                      .startOf("hour")
                      .isSameOrBefore(this.$moment(o.date + " " + p.time))
                  ) {
                    schedules.push(o.date + " " + p.time);
                  }
                });
              }
            }
          });
        }
      } catch (e) {
        this.$log.error(e);
      }

      this.$data.schedules = schedules;
      this.$data.calendarLoading = false;
    },
    openModal(ref) {
      this.$refs[ref].open(ref);
    },

    handleTypeButton(ref) {
      switch (ref) {
        case "sendfromstore":          
          this.$data.dispatchAt = "store";
          break;
        case "sendfromelsewhere":          
          this.$data.dispatchAt = "elsewhere";
          break;
        case "receiveatstore":
          this.$data.receiveAt = "store";
          break;
        case "receiveatelsewhere":          
          this.$data.receiveAt = "elsewhere";
          break;
      }
    },

    onFocusStore(option) {
      let query = {...this.$route.query, which: option, from: "StoreDeliveryStep2", autoSearch : 'my' };

      switch(option){
        case 'origin':          
          query.opened_on = this.formData.date_start;
        break;
        case 'destination':          
          query.opened_on = this.formData.date_end;
        break;
      }

      this.$router.push({ name: "Stores", query: query });
    },

    userData() {
      let quantity_small = this.$store.getters["userData/GET_ITEM"]({
        key: "quantity_small",
      });
      let quantity_big = this.$store.getters["userData/GET_ITEM"]({ key: "quantity_big" }) + quantity_small == 0 ? 1 : this.$store.getters["userData/GET_ITEM"]({ key: "quantity_big" });

      return {
        quantity_small: quantity_small,
        quantity_big: quantity_big,
        date_start: this.$store.getters["userData/GET_ITEM"]({ key: "date_start" }) || '',
        date_end: this.$store.getters["userData/GET_ITEM"]({ key: "date_end" }) || '',
        search_text: this.$store.getters["userData/GET_ITEM"]({ key: "search_text" }) ? this.$store.getters["userData/GET_ITEM"]({ key: "search_text" }) : "",
        item_type: this.$store.getters["userData/GET_ITEM"]({ key: "item_type" }) || "",
        identical_information: this.$store.getters["userData/GET_ITEM"]({ key: "identical_information" }) ? JSON.parse(this.$store.getters["userData/GET_ITEM"]({ key: "identical_information" })) : false,
        origin_custom_address: this.$store.getters["userData/GET_ITEM"]({ key: "origin_custom_address" }) || "",
        origin_custom_address_detail: this.$store.getters["userData/GET_ITEM"]({ key: "origin_custom_address_detail" }) || "",
        origin_custom_zipcode: this.$store.getters["userData/GET_ITEM"]({ key: "origin_custom_zipcode" }) || "",
        destination_custom_address: this.$store.getters["userData/GET_ITEM"]({ key: "destination_custom_address" }) || "",
        destination_custom_address_detail: this.$store.getters["userData/GET_ITEM"]({ key: "destination_custom_address_detail" }) || "",
        destination_custom_zipcode: this.$store.getters["userData/GET_ITEM"]({ key: "destination_custom_zipcode" }) || "",
        recipient_user_last_name: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_last_name" }) || "",
        recipient_user_first_name: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_first_name" }) || "",
        recipient_user_phone: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_phone" }) || "",
        recipient_user_dial_code: this.$store.getters["userData/GET_ITEM"]({ key: "recipient_user_dial_code"}) || "",
        sender_user_last_name: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_last_name" }) || "",
        sender_user_first_name: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_first_name" }) || "",
        sender_user_phone: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_phone" }) || "",
        sender_user_dial_code: this.$store.getters["userData/GET_ITEM"]({ key: "sender_user_dial_code"}) || "",
        ticket_password: this.$store.getters["userData/GET_ITEM"]({key : "ticket_password"}) || ""
      };
    },

    onChangeFormData(e) {
      const data = {
        identical_information: this.formData.identical_information.value,
        origin_custom_address: this.formData.origin_custom_address ? this.formData.origin_custom_address.value : "",
        origin_custom_address_detail: this.formData.origin_custom_address_detail ? this.formData.origin_custom_address_detail.value : "",
        origin_custom_zipcode: this.formData.origin_custom_zipcode ? this.formData.origin_custom_zipcode.value : "",
        destination_custom_address: this.formData.destination_custom_address ? this.formData.destination_custom_address.value : "",
        destination_custom_address_detail: this.formData.destination_custom_address_detail ? this.formData.destination_custom_address_detail.value : "",
        destination_custom_zipcode: this.formData.destination_custom_zipcode ? this.formData.destination_custom_zipcode.value : "",
        sender_user_last_name: this.formData.sender_user_last_name ? this.formData.sender_user_last_name.value : '',
        sender_user_first_name: this.formData.sender_user_first_name ? this.formData.sender_user_first_name.value : '',
        sender_user_phone: this.formData.sender_user_phone? this.formData.sender_user_phone.value : '',
        sender_user_dial_code: this.formData.sender_user_dial_code ? this.formData.sender_user_dial_code.value : '',
        recipient_user_last_name: this.formData.recipient_user_last_name ? this.formData.recipient_user_last_name.value  : '',
        recipient_user_first_name: this.formData.recipient_user_first_name ? this.formData.recipient_user_first_name.value : '',
        recipient_user_phone: this.formData.recipient_user_phone? this.formData.recipient_user_phone.value : '',
        recipient_user_dial_code: this.formData.recipient_user_dial_code ? this.formData.recipient_user_dial_code.value : '',
        ticket_password: this.formData.ticket_password ? this.formData.ticket_password.value  : ''
      };

      this.$store.dispatch("userData/set", data);
    },

    onComplete(which, $event) {
      let address,
        address_english,
        {
          roadAddress,
          roadAddressEnglish,
          roadname,
          roadnameEnglish,
          jibunAddress,
          jibunAddressEnglish,
          sido,
          sidoEnglish,
          sigungu,
          sigunguEnglish,
          bname1,
          bname1English,
          bname2,
          bname2English,
          userSelectedType,
          geoCodeProvider,
          geo_latitude,
          geo_longitude,
        } = $event;

        geo_latitude = parseFloat(geo_latitude)
        geo_longitude = parseFloat(geo_longitude)

        if(geo_latitude <= 0 && geo_longitude <= 0){
          this.$store.commit("alert/ADD_ITEM", {message: this.$__t("{delivery-step2}.error.unavailable_location"), status: "error"})
          return;
        }

      try {
        if (userSelectedType === "J") {
          address = jibunAddress;
          address_english = jibunAddressEnglish;
        } else if (userSelectedType === "R") {
          address = roadAddress;
          address_english = roadAddressEnglish;
        }

        this.formData[which+'_custom_address']['value'] = {
          ko: address,
          base: address_english,
          roadAddress, 
          roadAddressEnglish, 
          roadname,
          roadnameEnglish,
          jibunAddress, 
          jibunAddressEnglish,
          sido,
          sidoEnglish,
          sigungu,
          sigunguEnglish,
          eupMyeon: bname1,
          eupMyeonEnglish: bname1English,
          dongRi: bname2,
          dongRiEnglish: bname2English,
          latitude: geo_latitude,
          longitude: geo_longitude
        };

        this.formData[which+'_custom_address_detail']['value'] = "";

      } catch (e) {
        this.$log.log(e);
        this.$store.commit("alert/ADD_ITEM", { message: this.$__t(e), status: "error" });
      }
    },

    onChangeOrigin(value) {
      if (value == "store") {
        this.formData.origin_store_name = this.$data.origin_store_name;
        this.formData.origin_store_zipcode = this.$data.origin_store_zipcode;
        this.formData.origin_store_address = this.$data.origin_store_address;
        this.formData.origin_store_address_detail = this.$data.origin_store_address_detail;

        this.formData.origin_store_name.value = this.origin_store.store_name;
        this.formData.origin_store_zipcode.value = this.origin_store.store_zipcode;
        this.formData.origin_store_address.value = this.origin_store.store_address;
        this.formData.origin_store_address_detail.value = this.origin_store.store_address_detail;

        delete this.formData.origin_custom_name;
        delete this.formData.origin_custom_zipcode;
        delete this.formData.origin_custom_address;
        delete this.formData.origin_custom_address_detail;

      } else {
        this.formData.origin_custom_name = this.$data.origin_custom_name;
        this.formData.origin_custom_zipcode = this.$data.origin_custom_zipcode;
        this.formData.origin_custom_address = this.$data.origin_custom_address;
        this.formData.origin_custom_address_detail = this.$data.origin_custom_address_detail;

        delete this.formData.origin_store_name;
        delete this.formData.origin_store_zipcode;
        delete this.formData.origin_store_address;
        delete this.formData.origin_store_address_detail;
      }

      return this.formData;
    },

    onChangeDestination(value) {
      if (value == "store") {
        this.formData.destination_store_name = this.$data.destination_store_name;
        this.formData.destination_store_zipcode = this.$data.destination_store_zipcode;
        this.formData.destination_store_address = this.$data.destination_store_address;
        this.formData.destination_store_address_detail = this.$data.destination_store_address_detail;

        this.formData.destination_store_name.value = this.destination_store.store_name;
        this.formData.destination_store_zipcode.value = this.destination_store.store_zipcode;
        this.formData.destination_store_address.value = this.destination_store.store_address;
        this.formData.destination_store_address_detail.value = this.destination_store.store_address_detail;

        delete this.formData.destination_custom_name;
        delete this.formData.destination_custom_zipcode;
        delete this.formData.destination_custom_address;
        delete this.formData.destination_custom_address_detail;

      } else {
        this.formData.destination_custom_name = this.$data.destination_custom_name;
        this.formData.destination_custom_zipcode = this.$data.destination_custom_zipcode;
        this.formData.destination_custom_address = this.$data.destination_custom_address;
        this.formData.destination_custom_address_detail = this.$data.destination_custom_address_detail;

        delete this.formData.destination_store_name;
        delete this.formData.destination_store_zipcode;
        delete this.formData.destination_store_address;
        delete this.formData.destination_store_address_detail;
      }

      return this.formData;
    },

    async getStore( id ){
      let store = await this.$store.dispatch('stores/get', { id })

      let { data } = store.data;

      return data;
    },

    deleteStore(which){
      switch(which){
        case 'origin':          
          this.formData.origin_store_name.value = '';
          this.formData.origin_store_zipcode.value = '';
          this.formData.origin_store_address.value = '';
          this.formData.origin_store_address_detail.value = '';
          
          this.$data.origin_store = {};
          this.$data.origin_store.store_name = ''

        break;
        case 'destination':
          this.formData.destination_store_name.value = '';
          this.formData.destination_store_zipcode.value = '';
          this.formData.destination_store_address.value = '';
          this.formData.destination_store_address_detail.value = '';

          this.$data.destination_store = {};
          this.$data.destination_store.store_name = ''
        break;
      }
    },
  },
  async created() {
    let auth = this.$store.state.auth;    

    const routeQuery = this.$route.query;

    // account
    this.formData.sender_user_first_name.value = auth.user_first_name;
    this.formData.sender_user_last_name.value = auth.user_last_name;
    this.formData.sender_user_phone.value = auth.user_phone || "";
    // this.formData.sender_user_email.value = auth.user_email || '';

    if (auth.user_dial_code) {
      this.formData.sender_user_dial_code.value = auth.user_dial_code;
    } else if (config.userLocale.indexOf("ko") !== -1) {
      this.formData.sender_user_dial_code.value = "+82";
    }

    if (Object.keys(routeQuery).indexOf("store_for_origin") > -1) {
      this.$data.origin_store = await this.getStore(routeQuery["store_for_origin"]);
      this.$data.dispatchAt = "store";
      this.$data.receiveAt = "store"
    }
    if (Object.keys(routeQuery).indexOf("store_for_destination") > -1) {
      this.$data.destination_store = await this.getStore(routeQuery["store_for_destination"]);
      this.$data.dispatchAt = "store"
      this.$data.receiveAt = "store";
    }

    setTimeout(() => {
      this.$data.loading = false;
    }, 500)
    // await this.loadSchedules();

  },
};
</script>
