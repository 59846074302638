<template>
  <div class="page" id="auth-send-reset-password-page">
    <div class="container">

      <div class="form">
        <div class="form-header">
          <div class="title">{{ $__t( "비밀번호 찾기" ) }}</div>
          <div class="description">
            <p>{{ $__t( "가입하신 이메일 계정을 입력하시면, 비밀번호 초기화를 위한 메일이 전송됩니다." ) }}</p>
          </div>
        </div>
        <div class="form-body">
          <ui-form @submit="submitForm" :formData="formData" ref="form">
            <ui-form-list>
              <ui-form-list-item>
                <ui-textbox v-ga="$analytics.tracking.bind(this, 'login', 'focus', 'login_find_password_input_email' )" :placeholder="formData.user_email.placeholder" :type="formData.user_email.type" :label="formData.user_email.label" :error="formData.user_email.error" v-model="formData.user_email.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-solid-button v-ga="$analytics.tracking.bind(this, 'login', 'focus', 'login_find_password_reset_button' )" display="block"><button type="submit"><i class="fa fa-envelope"></i>{{ $__t( "비밀번호 초기화 이메일 발송하기" ) }}</button></ui-solid-button>
              </ui-form-list-item>
            </ui-form-list>
          </ui-form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    var formData = {
      user_email: {
        type: "text",
        label: this.$__t( "이메일 주소" ),
        placeholder: this.$__t( "가입하신 이메일 주소를 입력해주세요" ),
        validation: [
          { type: "required" },
          { type: "email" }
        ],
        value: "",
        error: ""
      },
    };
    return {
      formData
    };
  },
  methods: {
    submitForm( values ){
      this.$store.dispatch( "auth/sendReset", values ).then( res => {
        this.$refs.form.resetForm();
        this.$router.replace({ name: "Home" });
        this.$router.replace({ name: "AuthLogin", query: { redirect: this.$router.currentRoute.fullPath } });
        this.$store.commit( "alert/ADD_ITEM", { message: this.$__t("이메일이 발송되었습니다. 메일함을 확인해주세요"), status: "success" });
      }).catch( err => {
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
  }
}
</script>
