<template>
  <div class="ticket-message">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{ $__t('예약만료') | toUpperCase }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_expired) | moment('HH:mm') }}
        </div>
      </div>
      <div class="ticket-message__body">
        <div class="message-container">
          <div class="message-body">
            <div class="message-body__contents">
              <h3 class="primary">
                {{ ticket.store_name }}
              </h3>
              <p class="secondary">
                <span>{{ reserveDateTimeText }}</span
                ><br />
                <span>{{ ticketQuantityText }}</span>
              </p>
            </div>
            <div class="message-body__extras">
              <h3 class="primary">
                {{ $__t('만료사유') }}
              </h3>
              <div class="secondary">{{ $__t('{expired_reason}') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessageExpired',

  props: {
    ticket: {
      type: Object,
      default: {}
    },

    order: {
      type: Object,
      default: {}
    },

    activated: {
      type: Boolean,
      default: false
    },

    invitation: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {};
  },

  computed: {
    reserveDateTimeText() {
      const momentDateStart = this.$moment(this.ticket.ticket_date_start);
      const momentDateEnd = this.$moment(this.ticket.ticket_date_end);
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      return text;
    },

    ticketQuantityText() {
      try {
        let value = '';
        //물품 단일화 버전
        if (this.ticket?.extras?.version) {
          if (this.ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t('물품')} ${this.ticket.ticket_quantity}`;

            if (this.ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t('item');
            } else {
              value += quantityString + this.$__t('items');
            }
          }
          //물품 단일화 전(기존 버전)
        } else {
          if (this.ticket.ticket_quantity > 0) {
            let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.ticket.ticket_quantity}`;

            if (this.ticket.ticket_quantity === 1) {
              value += quantityString + this.$__t('item');
            } else {
              value += quantityString + this.$__t('items');
            }
          }

          if (this.ticket.ticket_over_quantity > 0) {
            let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.ticket.ticket_over_quantity}`;

            if (this.ticket.ticket_over_quantity > 0) quantityString = ' ' + quantityString;

            if (this.ticket.ticket_over_quantity === 1) {
              value += quantityString + this.$__t('item');
            } else {
              value += quantityString + this.$__t('items');
            }
          }
        }
        return value;
      } catch (e) {
        return null;
      }
    }
  }
};
</script>
