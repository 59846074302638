<template>
  <div class="ticket-action__form">
    <div class="ticket-action__form-header">
      <div class="ticket-action__form-header--primary" v-html="formHeaderTextPrimary"></div>
      <!-- <div
        class="ticket-action__form-header--secondary"
        v-html="$__t('{ticket-action__form-header--secondary}.check_out')"
      /> -->
    </div>
    <div class="ticket-action__form-body">
      <transition name="fade" mode="out-in">
        <template v-if="loaded">
          <div class="check-out-info">
            <div class="check-out-info__time">
              {{ checkOutInfoUsageTime }}
            </div>
            <div class="section">
              <h2 class="section__header">
                {{ $__t('보관정보') }}
              </h2>
              <ul class="list">
                <li class="item">
                  <div class="key">
                    {{ $__t('{estimated-price}.section.list.key.DATE_INFO') }}
                  </div>
                  <div class="value">
                    {{ dateInfo }}
                  </div>
                </li>
                <li class="item" v-if="!isLocker">
                  <div class="key">
                    {{ $__t('Items to be stored') }}
                  </div>
                  <div class="value" :class="{ 'value--empty': ticket.ticket_quantity + ticket.ticket_over_quantity < 1 }">
                    {{ itemInfo }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="section">
              <h2 class="section__header">
                {{ $__t('{estimated-price}.section.header.PRICE_INFO') }}
              </h2>
              <ul class="list">
                <li class="item">
                  <div class="key">
                    {{ $__t('{estimated-price}.section.list.key.BASIC_FEE') }}
                  </div>
                  <div class="value">
                    <span v-if="isLocker" class="value--medium">{{ lockerBasicPrice.toLocaleString() }}</span>
                    <span v-else class="value--medium">{{ totalBasicPrice.toLocaleString() }}</span>
                    {{ $__t('won') }}
                  </div>
                </li>
                <li class="item">
                  <div class="key">
                    {{ $__t('Additional Charge') }}
                  </div>
                  <div class="value">
                    <span v-if="isLocker" class="value--medium">{{ lockerAdditionalPrice.toLocaleString() }}</span>
                    <span v-else class="value--medium">+ {{ totalAdditionalPrice.toLocaleString() }}</span>
                    {{ $__t('won') }}
                  </div>
                </li>
                <li class="item">
                  <div class="key">{{ $__t('쿠폰') | capitalizeFirstLetter }}·{{ $__t('코드') }}</div>
                  <div class="value">
                    <span v-if="isLocker" class="value--medium">{{ discountAmount.toLocaleString() }}</span>
                    <span v-else class="value--medium">- {{ discountAmount.toLocaleString() }}</span>
                    {{ $__t('won') }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="section">
              <ul class="list">
                <li class="item">
                  <div class="key">{{ $__t('{estimated-price}.section.list.key.TOTAL_FEE') }}</div>
                  <div class="value">
                    <span class="value--large">{{ (totalPrice || 0).toLocaleString() }}</span
                    >{{ $__t('won') }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>
    </div>
    <div class="ticket-action__form-footer">
      <div class="footer__button-area">
        <button v-button type="button" @click="$emit('close-slider')" class="button button--cancel">
          {{ $__t('Cancel') | toUpperCase }}
        </button>
        <button v-button type="button" @click.prevent="confirm" class="button button--submit" :disabled="isProcessing">
          {{ $__t('Confirm') | toUpperCase }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Object,
      default: () => {}
    },

    ticket: {
      type: Object,
      default: () => {}
    },

    order: {
      type: Object,
      default: () => {}
    },

    account: {
      type: String,
      default: ''
    },

    password: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loaded: false,
      isProcessing: false,
      price: null
    };
  },

  watch: {
    checkOutInfoUsageTime(value, oldValue) {
      if (value !== oldValue) {
        this.fetchPrice().then(() => {});
      }
    }
  },

  computed: {
    isLocker() {
      return this.ticket?.ticket_type === 'LOCKER' || this.order?.product_data?.store_type === 'LOCKER';
    },

    dateInfo() {
      const momentDateStart = this.$moment(this.ticket.datetime_check_in);
      const momentDateEnd = this.$moment();
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      return text;
    },

    itemInfo() {
      if (this.ticket.ticket_quantity + this.ticket.ticket_over_quantity < 1) return this.$__t('{estimated-price}.section.list.value.DATE_INFO');
      let value = '';

      if (this.ticket.ticket_quantity > 0) {
        // let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.ticket.ticket_quantity}`;
        let quantityString = `${this.$__t('물품 개수')} ${this.ticket.ticket_quantity}`;

        if (this.ticket.ticket_quantity > 1) {
          value += quantityString + this.$__t('item');
        } else {
          value += quantityString + this.$__t('items');
        }
      }

      if (this.ticket.ticket_over_quantity > 0) {
        let quantityString = `${this.$__t('{luggage-selector}.big')} ${this.ticket.ticket_over_quantity}`;

        if (this.ticket.ticket_over_quantity > 0) quantityString = ' ' + quantityString;

        if (this.ticket.ticket_over_quantity > 1) {
          value += quantityString + this.$__t('item');
        } else {
          value += quantityString + this.$__t('items');
        }
      }

      return value;
    },

    deposit() {
      return 5000;
    },

    totalPrice() {
      return this.price?.total || 0;
    },

    discountAmount() {
      return this.price?.discount || this.order?.payment?.discount || 0;
    },

    smallBasicPrice() {
      return 2500 * parseInt(this.ticket?.ticket_quantity || 0);
    },

    largeBasicPrice() {
      return 3000 * parseInt(this.ticket?.ticket_over_quantity || 0);
    },

    totalBasicPrice() {
      return 2500 * parseInt(this.ticket?.ticket_quantity || 0) + 3000 * parseInt(this.ticket?.ticket_over_quantity || 0);
    },

    smallAdditionalPrice() {
      let durationMinutes = this.duration.asMinutes();
      let durationDays = this.duration.asDays();

      if (durationMinutes - 60 <= 0) {
        // 1시간 이전
        return 0;
      } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
        // 1시간 이후 7시간 30분 이전
        //가격 정책 전
        // return 100 * Math.ceil((durationMinutes - 60) / 10) * this.ticket?.ticket_quantity;

        //가격 정책 후
        return 150 * Math.ceil((durationMinutes - 60) / 10) * this.ticket?.ticket_quantity;
      } else if (450 < durationMinutes && durationMinutes <= 1440) {
        //7시간 30분 초과 24시간 이하
        //가격 정책 전
        // return 6000 * this.ticket?.ticket_quantity - this.smallBasicPrice;

        //가격 정책 후
        return 8500 * this.ticket?.ticket_quantity - this.smallBasicPrice;
      } else if (1440 < durationMinutes) {
        //24시간 초과
        //가격 정책 전
        // return (6000 + Math.ceil(durationDays - 1) * 4000) * this.ticket?.ticket_quantity - this.smallBasicPrice;

        //가격 정책 후
        return (8500 + Math.ceil(durationDays - 1) * 5000) * this.ticket?.ticket_quantity - this.smallBasicPrice;
      }
    },

    largeAdditionalPrice() {
      let durationMinutes = this.duration.asMinutes();
      let durationDays = this.duration.asDays();

      if (durationMinutes - 60 <= 0) {
        // 1시간 이전
        return 0;
      } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
        // 1시간 이후 7시간 30분 이전
        return 150 * Math.ceil((durationMinutes - 60) / 10) * this.ticket?.ticket_over_quantity;
      } else if (450 < durationMinutes && durationMinutes <= 1440) {
        //7시간 30분 초과 24시간 이하
        return 9000 * this.ticket?.ticket_over_quantity - this.largeBasicPrice;
      } else if (1440 < durationMinutes) {
        //24시간 초과
        return (9000 + Math.ceil(durationDays - 1) * 6000) * this.ticket?.ticket_over_quantity - this.largeBasicPrice;
      }
    },

    totalAdditionalPrice() {
      return this.smallAdditionalPrice + this.largeAdditionalPrice;
    },

    lockerBasicPrice() {
      let price = 0;

      switch (this.order?.product_data?.locker_size) {
        case 'S':
          price = 1000;
          break;
        case 'M':
          price = 1500;
          break;
        case 'L':
          price = 2000;
          break;
        case 'XL':
          price = 3000;
          break;
      }

      return price;
    },

    lockerAdditionalPrice() {
      if (this.duration) {
        let durationMinutes = this.duration.asMinutes();

        const unitPrice =
          this.order?.product_data?.locker_size === 'XL'
            ? 500
            : this.order?.product_data?.locker_size === 'L'
            ? 400
            : this.order?.product_data?.locker_size === 'M'
            ? 300
            : this.order?.product_data?.locker_size === 'S'
            ? 200
            : 0;

        const afterHalfDayPrice =
          this.order?.product_data?.locker_size === 'XL'
            ? 2500
            : this.order?.product_data?.locker_size === 'L'
            ? 2000
            : this.order?.product_data?.locker_size === 'M'
            ? 1500
            : this.order?.product_data?.locker_size === 'S'
            ? 1000
            : 0;

        let price = 0;

        if (durationMinutes <= 120) {
          price = 0;
        } else if (durationMinutes > 120 && durationMinutes <= 720) {
          price = Math.ceil((durationMinutes - 120) / 60) * unitPrice;
        } else {
          price = 10 * unitPrice + Math.ceil((durationMinutes - 720) / 720) * afterHalfDayPrice;
        }

        return price;
      }
    },

    formHeaderTextPrimary() {
      return this.$__t('{ticket-action__form-header--primary}.check_out');
    },

    checkOutInfoUsageTime() {
      let minutes = Math.abs(this.duration.minutes());
      let hours = Math.abs(this.duration.hours());
      let days = Math.floor(Math.abs(this.duration.asDays()));

      let value = '';

      if (days > 0) {
        value += ' ' + days + this.$__t('d');
      }

      if (hours > 0) {
        value += ' ' + hours + this.$__t('_h');
      }

      if (minutes > 0) {
        value += ' ' + minutes + this.$__t('m');
      }

      return this.$__t('total') + (value || 0 + this.$__t('m')) + ' ' + this.$__t('use');
    },

    quantitySmallText() {
      let text = '';

      text +=
        parseInt(this.ticket?.ticket_quantity || 0) > 0
          ? `${this.$__t('{luggage-selector}.small')} ${parseInt(this.ticket?.ticket_quantity || 0)}${
              parseInt(this.ticket?.ticket_quantity || 0) > 1 ? this.$__t('items') : this.$__t('item')
            } X (100/${this.$__t('per 10 minutes')} X ${this.toTenMinutes(this.duration)}${this.$__t('m')})`
          : '';

      return text;
    },

    quantityBigText() {
      let text = '';

      text +=
        parseInt(this.ticket?.ticket_over_quantity || 0) > 0
          ? `${this.$__t('{luggage-selector}.big')} ${parseInt(this.ticket?.ticket_over_quantity || 0)}${
              parseInt(this.ticket?.ticket_over_quantity || 0) > 1 ? this.$__t('items') : this.$__t('item')
            } X (150/${this.$__t('per 10 minutes')} X ${this.toTenMinutes(this.duration)}${this.$__t('m')})`
          : '';

      return text;
    }
  },

  methods: {
    async fetchPrice() {
      let payload = {
        id: this.ticket?.store_id,
        query: {
          start_date: this.$moment(this.ticket?.datetime_check_in).format('YYYY-MM-DD HH:mm:ss'),
          end_date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
          small_quantity: parseInt(this.ticket?.ticket_quantity || 0),
          big_quantity: parseInt(this.ticket?.ticket_over_quantity || 0),
          promotion_id: this.order?.promotion.promotion_id,
          pass_code: this.order?.promotion?.pass_code,
          start_datetime: this.$moment(this.ticket?.datetime_check_in).format('YYYY-MM-DD HH:mm:ss'),
          end_datetime: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
          locker_size: this.ticket.extras.locker_size
        },
        type: this.ticket?.ticket_type === 'LUGGAGE' ? 'stores' : this.ticket?.ticket_type
      };

      try {
        let res = await this.$store.dispatch('stores/predictPrice', payload);

        let { data } = res.data;

        this.price = data;
      } catch (e) {
        console.error(e);
        this.$log.error(e);
      }
    },

    toTenMinutes(duration) {
      return Math.ceil(this.$moment.duration(duration).asMinutes() / 10) * 10 - 60 > 0
        ? Math.ceil(this.$moment.duration(duration).asMinutes() / 10) * 10 - 60
        : 0;
    },

    async checkOutStoreTicket() {
      const payload = {
        type: this.ticket.ticket_type,
        code: this.ticket.ticket_code,
        data: {
          account: this.account,
          password: this.password
        }
      };

      await this.$store.dispatch(`tickets/${payload.data?.account ? 'checkoutbyShare' : 'checkoutAfter'}`, {
        ...payload,
        options: { clearGroup: true }
      });
    },

    async checkOutLockerTicket() {
      const payload = {
        type: this.ticket.ticket_type,
        code: this.ticket.ticket_code
      };

      await this.$store.dispatch('tickets/checkoutAfter', payload);
    },

    async confirm() {
      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;
      this.$store.commit('loading/SET_TRUE');

      try {
        if (this.isLocker) {
          await this.checkOutLockerTicket();
        } else {
          await this.checkOutStoreTicket();
        }

        this.$analytics.logTrack('track', 'Purchase', { currency: 'KRW', value: this.totalPrice });
        this.$analytics.logEvent('pick up confirm');
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        setTimeout(() => {
          this.$store.commit('loading/SET_FALSE');
          this.isProcessing = false;
          this.$emit('fetch-order');
          this.$emit('fetch-ticket');
          this.$emit('close-slider');
        }, 100);
      }
    }
  },
  created() {
    this.fetchPrice().then((res) => {
      
    });
  },
  mounted() {
    this.fetchPrice().then(() => {
      this.loaded = true;
    });
  }
};
</script>
