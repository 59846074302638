<template>
  <div class="page" id="cs-faq-page" :class="{ 'more-depth': $route.meta && $route.meta.headerType === 'more-depth' }">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">{{ $__t('자주 찾는 질문') }}</div>
        <div class="tab">
          <ul>
            <li :class="{ on: mode == 'guest' }" @click="shiftMode('guest')">{{ $__t('고객') }}</li>
            <li :class="{ on: mode == 'host' }" @click="shiftMode('host')">{{ $__t('호스트') }}</li>
          </ul>
        </div>
      </div>

      <div class="page-body">
        <div class="toggle-list">
          <ul class="list">
            <template v-if="mode == 'guest'">
              <li class="list-item" :class="{ on: opened.indexOf(0) >= 0 }">
                <div class="title" @click="toggleItem(0)">{{ $__t('What kind of service is LugStay?') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(0) >= 0">• {{ $__t('{cs-faq}고객1-1') }}</div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(1) >= 0 }">
                <div class="title" @click="toggleItem(1)">{{ $__t('{cs-faq}고객3-1') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(1) >= 0">
                  <ul>
                    <li class="body-title">{{ $__t('[Price Guide]') }}</li>
                    <!-- <li class="dot">·{{ $__t('보증금') }} : 5,000{{ $__t('won') }}</li> -->
                    <li class="dot">·{{ $__t('Basic fee(first 1 hour)') }}</li>
                    <!-- <li style="padding-left: 1rem">Small : 2,000{{ $__t('won') }}</li>
                    <li style="padding-left: 1rem">Large : 3,000{{ $__t('won') }}</li> -->
                    <li style="padding-left: 1rem">2,500{{ $__t('won') }}</li>
                    <li style="padding-left: 1rem">{{ $__t('Fixed 1 day charge for over 24 hours') }}</li>
                    <li class="dot">·{{ $__t('Additional Charge') }}</li>
                    <li style="padding-left: 1rem">
                      {{ $__t('There will be additional charge every 10 minutes before 8 hours, fixed charge over 8 hours to 24 hours.') }}
                    </li>
                    <!-- <li style="padding-left: 1rem">Small : 100{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li>
                    <li style="padding-left: 1rem">Large : 150{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li> -->
                    <li style="padding-left: 1rem">150{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li>
                    <li class="dot">·{{ $__t('fixed charge after 24 hours') }}</li>
                    <!-- <li style="padding-left: 1rem">Small : 4,000{{ $__t('won') }} / +24h</li>
                    <li style="padding-left: 1rem">Large : 6,000{{ $__t('won') }} / +24h</li> -->
                    <li style="padding-left: 1rem">5,000{{ $__t('won') }} / +24h</li>
                  </ul>
                  <!-- <ul style="margin-top: 2rem">
                    <li class="no-margin">
                      {{ $__t('ex) Made a reservation for 3 hours to keep 1 Small item, 2 Large items') }}
                    </li>
                    <li class="no-margin" style="font-weight: bold">Small 1{{ $__t('개') }}</li>
                    <li class="no-margin">
                      2,000{{ $__t('won') }}({{ $__t('basic fee') }}) + 1,200{{ $__t('won') }}(100{{ $__t('won') }}/10{{ $__t('m') }} x 90{{
                        $__t('m')
                      }}) = 3,200{{ $__t('won') }}
                    </li>
                    <li class="no-margin" style="font-weight: bold">Large 2{{ $__t('개') }}</li>
                    <li class="no-margin">
                      3,000{{ $__t('won') }}({{ $__t('basic fee') }}) x 2 + 1,350{{ $__t('won') }}(150{{ $__t('won') }}/10{{ $__t('m') }} x 90{{
                        $__t('m')
                      }}) x 2 = 8,700{{ $__t('won') }}
                    </li>
                    <li class="no-margin" style="font-weight: bold">3,200{{ $__t('won') }} + 8,700{{ $__t('won') }} = 11,900{{ $__t('won') }}</li>
                  </ul> -->
                </div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(2) >= 0 }">
                <div class="title" @click="toggleItem(2)">{{ $__t('How it works') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(2) >= 0">
                  <div>{{ $__t('{cs-faq}고객2-1') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-2') }}</div>
                  <div>{{ $__t('2. Pick one of our partners on LugStay website.') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-4') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-4-1') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-4-2') }}</div>
                  <div style="margin-top: 1rem">[{{ $__t('Pick Up') }}]</div>
                  <div>{{ $__t('{cs-faq}고객2-6') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-7') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-8') }}</div>
                  <div>{{ $__t('{cs-faq}고객2-9') }}</div>
                </div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(6) >= 0 }">
                <div class="title" @click="toggleItem(6)">{{ $__t('{cs-faq}고객6-1') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(6) >= 0">
                  <div>
                    {{ $__t('There is no load size or weight limit. (ex: backpacks, shoulder bags, carriers, kick scooters, strollers, etc.)') }}
                  </div>
                  <div>{{ $__t('{cs-faq}고객7-3') }}</div>
                  <div style="margin-top: 1rem">{{ $__t('1) food or drink') }}</div>
                  <div>{{ $__t('refrigerated foods or foods that smell.') }}</div>
                  <div>{{ $__t('2) valuables') }}</div>
                  <div>{{ $__t('ID card, passport, cash, high-value goods.') }}</div>
                  <div>{{ $__t('3) Illegal items which such as stolen, smuggled items') }}</div>
                  <div>{{ $__t('4) Items hard to be taken care of such as Nature plants or animals') }}</div>
                  <!-- <div class="dot" style="margin-top: 1rem" @click="openModal('luggageExplanationModal')">
                    <span class="link">{{ $__t('{cs-faq}고객6-2') }}</span>
                  </div> -->
                </div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(3) >= 0 }">
                <div class="title" @click="toggleItem(3)">{{ $__t('Is it stored safe?') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(3) >= 0">
                  <div class="dot">{{ $__t('{cs-faq}고객4-1') }}</div>
                  <div class="dot">{{ $__t('{cs-faq}고객4-2') }}</div>
                </div>
              </li>
              <!-- <li class="list-item" :class="{ on: opened.indexOf(5) >= 0 }">
                <div class="title" @click="toggleItem(5)">
                  {{ $__t('{cs-faq}고객5-1') }}<i class="fa fa-angle-down"></i>
                </div>
                <div class="body" v-show="opened.indexOf(5) >= 0">
                  <div class="dot">{{ $__t('{cs-faq}고객5-2') }}</div>
                  <div class="dot">{{ $__t('{cs-faq}고객5-3') }}</div>
                </div>
              </li> -->
              <!-- <li class="list-item" :class="{ on: opened.indexOf(8) >= 0 }">
                <div class="title" @click="toggleItem(8)">
                  {{ $__t('{cs-faq}고객8-1') }}<i class="fa fa-angle-down"></i>
                </div>
                <div class="body" v-show="opened.indexOf(8) >= 0">
                  <div>{{ $__t('{cs-faq}고객8-2') }}</div>
                </div>
              </li> -->
              <li class="list-item" :class="{ on: opened.indexOf(7) >= 0 }">
                <div class="title" @click="toggleItem(7)">{{ $__t('{cs-faq}고객7-1') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(7) >= 0">
                  <div class="dot">{{ $__t('{cs-faq}고객7-2') }}</div>
                  <div class="dot">{{ $__t('{cs-faq}고객7-2-1') }}</div>
                </div>
              </li>
              <!-- <li class="list-item" :class="{ on: opened.indexOf(9) >= 0 }">
                <div class="title" @click="toggleItem(9)">
                  {{ $__t('{cs-faq}고객9-1') }}<i class="fa fa-angle-down"></i>
                </div>
                <div class="body" v-show="opened.indexOf(9) >= 0">
                  <ul>
                    <li class="body-title">{{ $__t('[Price Guide]') }}</li>
                    <li class="dot">· {{ $__t('보증금') }} : 5,000{{ $__t('won') }}</li>
                    <li class="dot">· {{ $__t('Basic fee(first 1 hour)') }}</li>
                    <li style="padding-left: 1rem">Small : 2,000{{ $__t('won') }}</li>
                    <li style="padding-left: 1rem">Large : 3,000{{ $__t('won') }}</li>
                    <li style="padding-left: 1rem">{{ $__t('Fixed 1 day charge for over 24 hours') }}</li>
                    <li class="dot">· {{ $__t('Additional Charge') }}</li>
                    <li style="padding-left: 1rem">
                      {{
                        $__t(
                          'There will be additional charge every 10 minutes before 8 hours, fixed charge over 8 hours to 24 hours.'
                        )
                      }}
                    </li>
                    <li style="padding-left: 1rem">Small : 100{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li>
                    <li style="padding-left: 1rem">Large : 150{{ $__t('won') }} / {{ $__t('10 minutes_p') }}</li>
                    <li class="dot">{{ $__t('fixed charge after 24 hours') }}</li>
                    <li style="padding-left: 1rem">Small : 4,000{{ $__t('won') }} / +24h</li>
                    <li style="padding-left: 1rem">Large : 6,000{{ $__t('won') }} / +24h</li>
                  </ul>
                </div>
              </li> -->
              <!-- <li class="list-item" :class="{ on: opened.indexOf(10) >= 0 }">
                <div class="title" @click="toggleItem(10)">
                  {{ $__t('{cs-faq}고객10-1') }}<i class="fa fa-angle-down"></i>
                </div>
                <div class="body" v-show="opened.indexOf(10) >= 0">
                  <div class="dot">{{ $__t('{cs-faq}고객10-2') }}</div>
                  <div class="dot">{{ $__t('{cs-faq}고객10-3') }}</div>
                </div>
              </li> -->
              <li class="list-item" :class="{ on: opened.indexOf(11) >= 0 }">
                <div class="title" @click="toggleItem(11)">{{ $__t('{cs-faq}고객11-1') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(11) >= 0">
                  <div>{{ $__t('{cs-faq}고객11-2') }}</div>
                </div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(12) >= 0 }">
                <div class="title" @click="toggleItem(12)">{{ $__t('Cancellation & Refund') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(12) >= 0">
                  <div class="dot">{{ $__t('Cancellation is available any time before the booked time.') }}</div>
                  <div class="dot">
                    <!-- {{
                      $__t(
                        'When passed the book time, cancellation is unavailable and unless you check in an hour from book time, the ticket will be expired'
                      )
                    }} -->
                    {{ $__t('예약 시 설정한 ‘찾는 시간’까지 체크인 처리가 완료되지 않을 경우 티켓은 만료 처리됩니다.') }}
                  </div>
                  <div class="dot">
                    {{ $__t('Prepaid charge will not be refunded due to its expiration') }}
                  </div>
                  <div class="dot">
                    {{
                      $__t(
                        'When you were not able to use Ticket due to the situation of store, make sure to contact C/S for refund only by contacting C/S can make it happen'
                      )
                    }}
                  </div>
                  <div class="dot">·{{ $__t('Please check your reservation schedule before cancellation.') }}</div>
                  <div style="margin-top: 1rem" class="dot">
                    {{ $__t('Refund time for cancellation is as follows.') }}
                  </div>
                  <div style="padding-left: 1rem">
                    {{ $__t('If canceled on the date of reservation: instant refund.') }}
                  </div>
                  <div style="padding-left: 1rem">
                    {{ $__t('If canceled after the date of reservation: refund after 3-5 business days.') }}
                  </div>
                </div>
              </li>
            </template>

            <template v-if="mode == 'host'">
              <li class="list-item" :class="{ on: opened.indexOf(14) >= 0 }">
                <div class="title" @click="toggleItem(14)">
                  {{ $__t('럭스테이 호스트로 가입하고 싶습니다. 어떻게 해야 하는지요?') }}<i class="fa fa-angle-down"></i>
                </div>
                <div class="body" v-show="opened.indexOf(14) >= 0">{{ $__t('{cs-faq}호스트1-2') }}</div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(15) >= 0 }">
                <div class="title" @click="toggleItem(15)">{{ $__t('{cs-faq}호스트2-1') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(15) >= 0">
                  <div>{{ $__t('{cs-faq}호스트2-2') }}</div>
                  <div>{{ $__t('{cs-faq}호스트2-3') }}</div>
                  <div>{{ $__t('{cs-faq}호스트2-4') }}</div>
                </div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(16) >= 0 }">
                <div class="title" @click="toggleItem(16)">
                  {{ $__t('럭스테이 호스트가 해야 할 일은 무엇인가요?') }}<i class="fa fa-angle-down"></i>
                </div>
                <div class="body" v-show="opened.indexOf(16) >= 0">
                  <div>{{ $__t('{cs-faq}호스트3-1') }}</div>
                  <div>{{ $__t('{cs-faq}호스트3-2') }}</div>
                </div>
              </li>
              <li class="list-item" :class="{ on: opened.indexOf(17) >= 0 }">
                <div class="title" @click="toggleItem(17)">{{ $__t('{cs-faq}호스트4-1') }}<i class="fa fa-angle-down"></i></div>
                <div class="body" v-show="opened.indexOf(17) >= 0">
                  <div>{{ $__t('{cs-faq}호스트4-2') }}</div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <guide-modal id="priceGuideModal" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
      <template v-slot:body>
        <price-guide />
      </template>
    </guide-modal>

    <guide-modal id="luggage-explanation" ref="luggageExplanationModal">
      <template v-slot:body>
        <luggage-explanation />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import PriceGuide from '@/components/pages/_/PriceGuide';
import LuggageExplanation from '@/components/pages/luggage/LuggageExplanation';

export default {
  components: {
    PriceGuide,
    LuggageExplanation
  },
  data() {
    return {
      mode: 'guest',
      opened: []
    };
  },
  watch: {
    mode() {
      this.opened = [];
    }
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    shiftMode(mode) {
      this.mode = mode;
    },
    toggleItem(index) {
      if (this.opened.indexOf(index) >= 0) {
        this.opened = this.opened.filter((o) => o != index);
      } else {
        this.opened.push(index);
      }
    },
    openModal(ref) {
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header .page-header__title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    }
  }
};
</script>
