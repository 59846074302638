<template>
	<div class="lug-rating-select">
		<div class="body" ref="hover">
			<div class="background">
				<img src="/img/star-off.png"/>
				<img src="/img/star-off.png"/>
				<img src="/img/star-off.png"/>
				<img src="/img/star-off.png"/>
				<img src="/img/star-off.png"/>
			</div>
			<div class="point" v-if="model">
				<img src="/img/star-on.png" v-for="i in parseInt(model / 2)" :key="i" />
				<img src="/img/star-half.png" v-if="model / 2 != parseInt(model / 2)" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {},
		disabled: { type: Boolean, default: true },
	},
	computed: {
		model: {
			get() {
				if (this.value) {
					return this.value;
				} else {
					this.$emit('input', 10);
				}
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
  watch: {
    disabled(value){
      if (value) {
        if(this.$refs.hover) this.$refs.hover.removeEventListener('mousedown', this.mousedownEventListener);
			} else {
				if(this.$refs.hover) this.$refs.hover.addEventListener('mousedown', this.mousedownEventListener);
			}
    }

  },
  mounted(){
    setTimeout(() => {
			if (this.disabled) {
				this.$refs.hover.removeEventListener('mousedown', this.mousedownEventListener);
			} else {
				this.$refs.hover.addEventListener('mousedown', this.mousedownEventListener);
			}
		}, 0);
  },
	methods: {
		mousedownEventListener(e) {
			var bound = this.$refs.hover.getBoundingClientRect();
			var point = 0;
			point = (e.clientX - bound.left) / bound.width;
			point = point * 10;
			point = point.toFixed(1);
			point = Math.ceil(point);
			this.$emit('input', point);
		},
	},
};
</script>
