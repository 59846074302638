<template>
  <div id="qr-code-redirect-page" class="page">
    <div class="container">
      <div v-if="loading" class="loading">
        <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
        <p class="loading__text">QR코드를 분석하는 중입니다</p>
      </div>
      <div v-else class="target-items">
        <h1 class="header">
          <strong>체크인·체크아웃</strong>을 진행할 티켓을 선택해주세요
        </h1>
        <ul class="target-items__list">
          <li
            v-for="ticket in tickets"
            :key="ticket.ticket_code"
            class="target-items__item"
          >
            <ui-ticket :ticket="ticket" @click="onClickTicket(ticket)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/loading-primary.json";
import TicketItem from "@/components/resources/ticket/TicketItem";
import { isValidHttpURL } from "@/helpers";

export default {
  data() {
    return {
      code: this.$route.query.code,
      from: this.$route.query.from,
      mode: "unset",

      storeId: "",
      ticketCode: "",

      ticketQuery: {
        page: 1,
        size: 10,
      },

      lottieOptions: {
        animationData: LottieLoading,
      },

      loading: true,

      tickets: [],
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth?.gid > 0;
    },

    isHost() {
      return this.$store.state.auth?.host_id > 0;
    },

    forGuest() {
      return this.$store.getters["tickets/GET_ITEMS_BY_LIST"]({
        list: "MyTickets_available",
      });
    },

    forHost() {
      return this.$store.getters["tickets/GET_ITEMS_BY_LIST"]({
        list: "HostTickets_available",
      });
    },
  },

  async mounted() {
    this.$store.dispatch("qrCodeScanner/close", {});
    this.setMode();
    this.onMode();
  },

  methods: {
    setMode() {
      if (!this.code) return;

      if (isValidHttpURL(this.code)) this.mode = "url";
      else if (this.code.startsWith("T")) this.mode = "ticket";
      else if (this.code.startsWith("S")) this.mode = "store";
    },

    onMode() {
      if (this.mode === "unset") {
        this.onModeUnset();
        return;
      }

      if (this.mode === "url") {
        this.onModeUrl();
        return;
      }

      if (this.mode === "store") {
        this.storeId = this.code.replace("S", "");
        this.onModeStore();
        return;
      }

      if (this.mode === "ticket") {
        this.onModeTicket();
        return;
      }
    },

    onModeUnset() {
      alert("알 수 없는 QR코드입니다.");
      this.$router.back();
    },

    onModeUrl() {
      const url = new URL(this.code);
      const link = url.searchParams.get("link");

      if (link) {
        const linkUrl = new URL(link);
        this.$router.replace(linkUrl.pathname + linkUrl.search);
      }
    },

    onModeTicket() {
      this.$store.commit("alert/ADD_ITEM", {
        message: this.$__t(
          "럭스테이 티켓 QR코드 기능은 현재 지원하지 않습니다. 상점의 QR코드를 통해 이용해주세요."
        ),
        status: "error",
      });
      this.$router.replace("/");
    },

    onModeStore() {
      if (this.isLoggedIn) {
        this.setTickets().then(() => {
          if (this.tickets.length > 0) {
            this.ticketAction();
            return;
          }

          this.$router.replace({
            name: "Stores",
            query: { autoSearch: "target", sid: this.storeId },
          });
          return;
        });
        return;
      }

      this.$router.replace({
        name: "Stores",
        query: { autoSearch: "target", sid: this.storeId },
      });
    },

    login() {
      this.$store.commit("modal/ADD_ITEM", {
        component: "LoginForm",
        size: "small",
        class: "for-modal",
        redirect: this.$router.currentRoute.fullPath,
      });
    },

    fetchTicketsByAuthType() {
      if (this.mode === "ticket") {
        if (this.isHost) {
          return this.fetchHostTicketList();
        }

        alert("상점의 QR코드를 스캔하여 체크인·체크아웃을 진행해주세요.");
      }

      if (this.mode === "store") {
        return this.fetchTicketList();
      }

      return Promise.resolve([]);
    },

    async fetchTicketList() {
      try {
        const response = await this.$store.dispatch("tickets/getList", {
          list: "MyTickets_available",
          filter: "available",
          query: { ...this.ticketQuery },
        });

        return response.data.data?.items;
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "info" });
      }
    },

    async fetchHostTicketList() {
      try {
        const response = await this.$store.dispatch("tickets/getListForHost", {
          list: "HostTickets_available",
          filter: "available",
          query: { ...this.ticketQuery },
        });

        return response.data.data?.items;
      } catch (e) {}
    },

    async setTickets() {
      const items = await this.fetchTicketsByAuthType();
      this.tickets = items.filter((ticket) => {
        if (this.mode === "ticket") return ticket.ticket_code === this.code;
        else if (this.mode === "store")
          return (
            (ticket.store_id || 0).toString() == this.code.replace("S", "")
          );
      });
    },

    ticketAction() {
      this.loading = false;
      if (this.tickets.length === 1) {
        const ticketStatus = this.tickets[0].status;
        const ticketCode = this.tickets[0].ticket_code;

        return this.isHost
          ? this.$router
              .replace({
                name: "HostTickets",
                query: { from: this.$data.from, method: "qr_code" },
              })
              .then(() => {
                setTimeout(() => {
                  this.$store.commit("alert/ADD_ITEM", {
                    message:
                      ticketStatus === "ISSUED"
                        ? "체크인 버튼을 눌러주세요."
                        : "체크아웃 버튼을 눌러주세요",
                    status: "alert",
                  });
                });
              })
          : this.$router
              .replace({
                name: "TicketSingle",
                params: { id: ticketCode },
                query: {
                  from: this.$data.from,
                  method: "qr_code",
                  action: ticketStatus === "ISSUED" ? "CHECK_IN" : "CHECK_OUT",
                },
              })
              .then((o) => console.log("router changed", ticketCode));
      }
    },

    onClickTicket(ticket = {}) {
      this.$router.replace({
        name: "TicketSingle",
        params: { id: ticket.ticket_code },
        query: {
          from: this.$data.from,
          action: ticket.status === "ISSUED" ? "CHECK_IN" : "CHECK_OUT",
          method: "qr_code",
        },
      });
    },
  },

  components: {
    Lottie,
    UiTicket: TicketItem,
  },
};
</script>

<style scoped lang="scss">
.page {
  @include fill-viewport-min-height;
  background: $color-white;

  .container {
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loading {
      &__text {
        color: #a9afb3;
        font-size: unit(14);
        text-align: center;
        line-height: 1.43;
        font-weight: 500;
      }
    }

    .target-items {
      flex: 1;

      .header {
        font-size: unit(22);
        font-weight: 500;
        padding: unit(30) unit(16);
        line-height: 1.5;

        strong {
          font-weight: bold;
        }
      }

      &__list {
        padding: unit(16);
      }

      &__item {
        + * {
          margin-top: unit(12);
        }
      }
    }
  }
}
</style>
