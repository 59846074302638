import loadScript from "load-script";

const KakaoPixel = {
  init(app, options = {}, callback = function () {}) {
    this._trackingId = options.trackingId;

    if (this._trackingId) {
      loadScript(`//t1.daumcdn.net/adfit/static/kp.js`, (error, script) => {
        this._instance = kakaoPixel(this._trackingId);
        if (error) {
          callback(error, script);
          return;
        }

        callback(error, script);
      });
    }
  },

  signUp() {
    if (this._trackingId) {
      this._instance.signUp();
    }
	},

  participation() {
    if (this._trackingId) {
      this._instance.participation();
    }
  },

  pageView() {
    if (this._trackingId) {
      this._instance.pageView();
    }
	},
};

export default KakaoPixel;
