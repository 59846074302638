<template>
  <div id="more-how-to-use">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title" ref="title">
          <h1 class="text">
            {{ $__t("어떻게 사용하나요?") }}
          </h1>
          <img class="image" src="/img/icon-how.svg" />
        </div>
        <div class="tab-area" ref="tab">
          <lug-tab
            ref="tab"
            :tab-items="tabItems"
            :activeIndex="activeTabIndex"
            @active="onActiveTab"
          />
        </div>
      </div>

      <div class="page-body">
        <keep-alive>
          <component :is="targetComponent" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import LugTab from "@/components/modules/LugTab/Body";
import Book from "@/components/includes/HowToUse/Book";
import Ticket from "@/components/includes/HowToUse/Ticket";
import Price from "@/components/pages/_/Price";

export default {
  data() {
    return {
      tabItems: [
        this.$__t("예약하기").toUpperCase(),
        this.$__t("보관 & 찾기").toUpperCase(),
        this.$__t("이용가격").toUpperCase(),
      ],
      activeTabIndex: 0,
      components: ["Book", "Ticket", "Price"],

      headerVisible: null,
    };
  },

  watch: {
    headerVisible: {
      handler(value) {
        const $siteHeaderTitle = $(
          "#site-header > .container > .header > .title"
        );
        const $siteHeader = $("#site-header");

        if (value === true) {
          $siteHeaderTitle.removeClass("off");
          $siteHeader.addClass("shadow");
        } else if (value === false) {
          $siteHeaderTitle.addClass("off");
          $siteHeader.removeClass("shadow");
        }
      },
    },
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener("scroll", this.toggleHeaderTitleVisibility);
    }, 0);

    this.wni.execute("wnAppChangeStatusBar", {
      "status-bar-style": "dark",
      "background-color": "#ffffff",
    });
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener("scroll", this.toggleHeaderTitleVisibility);
  },

  computed: {
    targetComponent() {
      return this.components[this.activeTabIndex];
    },
  },

  methods: {
    onActiveTab(index) {
      this.activeTabIndex = index;
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageBodyTitle = $(this.$refs.title);
      const $siteHeaderTitle = $(
        "#site-header > .container > .header > .title"
      );

      if (clear === true) {
        $siteHeaderTitle.removeClass("off");
        return;
      }

      const isHeaderCovered = $pageBodyTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        this.headerVisible = false;
        $(this.$refs.tab).removeClass("fixed");
      } else {
        this.headerVisible = true;
        $(this.$refs.tab).addClass("fixed");
      }
    },
  },

  components: {
    LugTab,
    Book,
    Ticket,
    Price,
  },
};
</script>
