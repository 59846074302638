<template>
  <div class="place-search">
    <transition name="fade" mode="out-in">
      <div v-if="loaded" class="place-search__header">
        <div class="place-search__button-area">
          <button v-button type="button" @click="close">
            <img src="/img/icon/header-back-arrow.svg" />
          </button>
        </div>
        <div class="place-search__input-area">
          <ui-form :formData="formData" ref="form" @submit="confirm">
            <div class="place-search__input-wrapper">
              <input
                type="text"
                @input="formData.search_text.value = $event.target.value"
                :value="formData.search_text.value"
                @keyup="predictPlace($event.target.value)"
                :placeholder="formData.search_text.placeholder"
                class="place-search__input"
                :class="{ error: formData.search_text.error }"
              />
              <button
                v-if="formData.search_text.value"
                type="button"
                class="place-search__input-button"
                @click="clearInput"
              >
                <img src="/img/icon/search-clear.svg" />
              </button>
            </div>
          </ui-form>
        </div>
      </div>
    </transition>

    <div class="place-search__body">
      <transition name="fade" mode="out-in">
        <ul v-if="loaded" class="search-list">
          <template v-if="formData.search_text.value">
            <li
              v-button
              class="search-item"
              v-for="(item, index) in prediction"
              :key="index"
              @click="onClickPredictItem(item)"
              v-html="formatText(item)"
            ></li>
          </template>
          <template v-else>
            <li class="history-item" v-for="(item, index) in searchHistory" :key="index">
              <div v-button class="history-item--text" @click="onClickHistoryItem(item)">{{ item }}</div>
              <button v-button type="button" class="history-item--button" @click="removeHistory(item, index)">
                <img src="/img/icon/search-clear.svg" />
              </button>
            </li>
            <button
              v-if="searchHistory.length > 0"
              type="button"
              v-button
              @click="clearHistory"
              class="search-list__button-clear"
            >
              {{ $__t("전체") }} {{ $__t("삭제") }}
            </button>
          </template>
        </ul>
      </transition>
    </div>

    <div class="place-search_footer" ref="footer"></div>
  </div>
</template>

<script>
import _ from "lodash";

const LOCAL_STORAGE_KEY = "__LUGSTAY__SEARCH__HISTORY";

export default {
  props: {
    callback: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      formData: {
        form_id: "reserve_form",
        search_text: {
          type: "text",
          validation: { type: "required" },
          value: "",
          error: "",
          placeholder: this.$__t("{place-search}.input.placeholder"),
        },
        search_location: {
          type: "text",
          value: "",
        },
      },

      prediction: [],
      searchHistory: [],

      loaded: false,

      googleAutoCompleteService: null,
      googlePlacesService: null,
    };
  },

  created() {
    this.getSearchHistory();
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true;

      this.$nextTick(() => {
        this.googleAutoCompleteService = new google.maps.places.AutocompleteService();
        this.googlePlacesService = new google.maps.places.PlacesService(this.$refs.footer);
      });
    }, 0);
  },

  methods: {
    close() {
      return (this.$parent.keywordSearchOpened = false);
    },

    getSearchHistory() {
      let value = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (value && value.length > 0) {
        this.searchHistory = value.split(",");
      }
    },

    setSearchHistory(text) {
      const textAlreadyExistsIndex = this.searchHistory.indexOf(text);

      if (textAlreadyExistsIndex > -1) {
        this.searchHistory.splice(textAlreadyExistsIndex, 1);
      }

      this.searchHistory.unshift(text);

      if (this.searchHistory.length > 10) {
        this.searchHistory.splice(10, this.searchHistory.length - 10);
      }

      return localStorage.setItem(LOCAL_STORAGE_KEY, this.searchHistory);
    },

    removeHistory(item, index) {
      this.searchHistory.splice(index, 1);
      return localStorage.setItem(LOCAL_STORAGE_KEY, this.searchHistory);
    },

    clearHistory() {
      this.searchHistory = [];
      return localStorage.removeItem(LOCAL_STORAGE_KEY);
    },

    predictPlace(text) {
      return new Promise((resolve, reject) => {
        this.googleAutoCompleteService.getPlacePredictions(
          { input: text, componentRestrictions: { country: "kr" } },
          (prediction, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(prediction);
            }
          }
        );
      }).then((prediction) => {
        this.prediction = prediction;
      });
    },

    clearInput() {
      this.formData.search_text.value = "";
      this.prediction = [];
    },

    colorMatchingWordWithSearchText(text) {
      const REG_EXP = new RegExp(this.formData.search_text.value, "gi");

      return `<div class="prediction-item--primary">${text.replace(
        REG_EXP,
        (match) => `<span style="color: #008fff;">${match}</span>`
      )}</div>`;
    },

    formatText(item) {
      let { structured_formatting } = item || {};
      let { main_text, secondary_text } = structured_formatting || {};

      return `<div class="prediction-item">
                ${this.colorMatchingWordWithSearchText(main_text || "")}
                <div class="prediction-item--secondary">${secondary_text || ""}</div>
              </div>`;
    },

    onClickPredictItem(item) {
      let { main_text, description } = item?.structured_formatting || {};

      this.formData.search_text.value = main_text;
      this.formData.search_location.value = description;

      return this.$refs.form.submit();
    },

    onClickHistoryItem(item) {
      this.formData.search_text.value = item;

      return this.$refs.form.submit();
    },

    confirm(values) {
      this.$store.commit("loading/SET_TRUE");
      let { search_text, search_location } = values || {};

      this.setSearchHistory(search_text);

      this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: this.formData.form_id,
          search_text,
          keyword: search_location || search_text
        },
      });

      this.close();
      this.$store.commit("loading/SET_FALSE");
      this.$analytics.logEvent("complete search", { "search word": search_text });
      this.callback();
    },
  },
};
</script>
