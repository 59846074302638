<template>
  <div class="page" id="auth-callback-page">
    <div class="container">


    </div>
  </div>
</template>

<script>
export default {};
</script>
