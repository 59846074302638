<template>
  <div class="lug-tab">
    <div class="container">
      <div
        v-for="(item, index) in items"
        :key="item.text"
        class="lug-tab__item"
        :class="{ hover: item.hover, focus: item.focus }"
        @click="onClickItem({ item, index })"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabItems: {
      type: Array,
      default: [],
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      items: [],
    };
  },

  watch: {
    activeIndex(value) {
      this.$data.items.forEach((o, i) => {
        if (value == i) {
          o.focus = true;
          this.$emit('active', i);
        } else {
          o.focus = false;
        }
      });
    },
  },

  created() {
    this.$data.items = this.mapItems(this.tabItems);
    this.$data.activeItem = 0;
  },

  methods: {
    onClickItem(options = {}) {
      let { item, index } = options;

      this.$data.items.forEach((o, i) => {
        if (index == i) {
          o.focus = true;
          this.$emit('active', i);
        } else {
          o.focus = false;
        }
      });
    },

    mapItems(items) {
      let mapItems = items.map((o, index) => {
        return {
          text: o,
          hover: false,
          focus: index == 0 ? true : false,
        };
      });

      return mapItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.lug-tab {
  & > .container {
    overflow: auto;
    overscroll-behavior-x: contain;
    background-color: $color-white;
    height: 100%;
    padding: unit(9) 0;
    transition: all 0.2s;
    white-space: nowrap;

    .lug-tab__item {
      font-size: unit(16);
      line-height: unit(26);
      text-align: center;
      color: #a9afb3;
      display: inline-block;
      transition: all 0.2s;
      cursor: pointer;
      padding: 0 unit(3);

      & + .lug-tab__item {
        margin-left: unit(40);
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: unit(4);
        bottom: unit(-4);
        left: 50%;
        transform: translate(-50%, 100%);
        background-color: transparent;
      }

      &.hover {
        color: $color-primary-5;

        &::after {
          background-color: $color-primary-5;
        }
      }

      &.focus {
        color: #1890ff;

        &::after {
          background-color: #1890ff;
        }
      }
    }
  }

  &.iez {
    .lug-tab {
      &__item {
        color: $color-gray-5;

        &.hover,
        &.focus {
          color: #89c7d4;

          &::after {
            background-color: #89c7d4;
          }
        }
      }
    }
  }
}
</style>
