<template>
  <div
    class="page"
    id="cs-privacy-policy-page"
    :class="{
      'more-depth':
        ($route.meta && $route.meta.headerType === 'more-depth') || $route.params.headerType === 'more-depth',
    }"
  >
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">{{ $__t('개인정보 처리방침') }}</div>
      </div>

      <div class="page-body">
        <!-- <div v-for="(doc, i) in docs" :class="'depth-' + (i.split('-').length - 1)">{{ doc[$data.lang] }}</div> -->
        <vue-markdown :source="source" class="markdown"> </vue-markdown>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import PrivacyPolicy from '@/assets/json/privacy-policy.json';
export default {
  components: {
    VueMarkdown,
  },
  data() {
    return {
      docs: PrivacyPolicy,
      lang: this.$store.state.lang === 'ko' ? 'ko' : 'en',

      source: `주식회사 블루웨일컴퍼니 (이하 ‘회사'라고 함)는 회사가 제공하고자 하는 서비스(이하 ‘회사 서비스’)를 이용하는 개인(이하 ‘이용자’ 또는 ‘개인’)의 정보(이하 ‘개인정보’)를 보호하기 위해, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신방법') 등 관련 법령을 준수하고, 서비스 이용자의 개인정보 보호 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침(이하 ‘본 방침’)을 수립합니다.

## 제1조 개인정보의 수집 및 이용 목적

1. 회사는 이용자의 사전 동의 없이는 이용자의 개인 정보를 공개하지 않으며, 다음과 같은 목적을 위하여 개인정보를 수집하고 이용합니다.
    1. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산견적서·요청서 정보 제공 및 프로필 광고노출, 멤버십 등 기본적인 서비스 제공, 서비스 제공에 관한 계약 체결·유지·이행·관리·개선 및 서비스 제공에 따른 요금 정산 및 컨텐츠 서비스 이용/제공, 신규 서비스 개발, 구매 및 요금결제, 물품 배송 또는 청구지 등 발송, 본인 인증 및 금융서비스, 요금 추심
    2. 회원관리회원제 서비스에 따른 본인 확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 회원 가입∙유지∙탈퇴 의사 확인, 연령확인, 법령상 의무 이행, 법령 및 약관 위반 여부에 관한 조사, 고객 센터 운영 불만처리 등 민원 처리, 고지사항 및 공지사항 전달
    3. 마케팅 및 광고에 활용신규 서비스(제품) 개발 및 특화, 뉴스레터, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 마케팅 및 광고 등에 활용, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계, 신규 상품 안내 텔레마케팅
2. 수집하는 개인정보 항목
    
    (1) (회원가입을 위한 정보)
    
    회사는 이용자의 회사 서비스에 대한 회원가입을 위하여 다음과 같은 정보를 수집합니다.
    
    1. 필수 수집 정보
    
    고객: 이메일, 비밀번호, 성, 이름, 휴대전화 번호, 거주국가
    
    호스트: 이메일, 비밀번호, 성,이름, 상점(상호,주소, 전화번호, 운영시간)
    
    2. 선택 수집 정보
    
    고객:프로필 사진, 성별, 생년월일, 주소, SNS로그인 정보
    
    호스트:프로필 사진, 성별, 생년월일, 주소, SNS로그인 정보
    
    (2) (본인인증을 위한 정보)
    
    회사는 이용자의 본인인증을 위하여 다음과 같은 정보를 수집합니다.
    
    1.필수 수집 정보
    
    휴대폰 번호, 이메일주소, 이름, 비밀번호, 거주하는 국가
    
    2.선택 수집 정보
    
    생년월일, 성별, 본인확인값(CI, DI), 이동통신사, 아이핀정보 (아이핀 확인 시), 내/외국인 여부
    
    (3) (법정대리인 동의를 위한 정보)
    
    회사는 법정대리인의 동의가 필요한 경우 법정대리인의 동의를 위하여 다음과 같은 정보를 수집합니다.
    
    1. 필수 수집 정보
    
    보호자 이름, 보호자 생년월일, 보호자 성별, 보호자 휴대폰 번호, 거주하는 국가
    
    2. 선택 수집 정보
    
    보호자 내/외국인 여부, 보호자 이동통신사 정보, 보호자 아이핀 정보
    
    (4) (결제서비스를 위한 정보)
    
    회사는 이용자에게 회사의 결제 서비스 제공을 위하여 다음과 같은 정보를 수집합니다.
    
    1. 필수 수집 정보
    
    카드사 이름, 카드번호, 카드비밀번호, 유효기간, CVC, 4DBC, 이메일, 성, 이름
    
    2. 선택 수집 정보
    
    생년월일
    
    (5) (회사 서비스 제공을 위한 정보)
    
    회사는 이용자에게 회사의 서비스를 제공하기 위하여 다음과 같은 정보를 수집합니다.
    
    1. 필수 수집 정보
    
    이메일, 비밀번호, 성, 이름, 휴대전화 번호, 거주국가, 공간/상점명, 공간 크기(평수), 주소, 공간 용도
    
    2. 선택 수집 정보
    
    프로필 사진, 성별, 생년월일, 주소, SNS로그인 정보
    
    (실물상품 지급 시) 이름, 휴대전화 번호, 주소, 생년월일
    
    (모바일상품 지급 시) 휴대전화 번호, 생년월일
    
    (제세공과금 처리 시) 이름, 주민등록 번호, 휴대전화 번호, 주소, 이메일 주소
    
    ※ 회사서비스 : 회사의 이용약관에 따른 회사 서비스를 비롯하여 회사 서비스 제공을 위한 마케팅이나 이벤트 관리, 이용자의 보안, 프라이버시 등을 위한 서비스 이용환경을 위한 서비스가 있습니다.
    
    (6) (서비스 이용 및 부정 이용 확인을 위한 정보)
    
    회사는 이용자의 서비스 이용 및 부정이용의 확인 및 분석을 위하여 다음과 같은 정보를 수집합니다.
    
    1. 필수 수집 정보
    
    서비스 이용기록, 쿠키, 접속지 정보, 기기정보, 기기 브라우저 정보
    
    ※ 부정이용 : 회원탈퇴 후 재가입, 상품구매 후 구매취소 등을 반복적으로 행하는 등 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제상 이익을 불·편법적으로 수취하는 행위, 이용약관 등에서 금지하고 있는 행위, 명의도용 등의 불·편법행위 등을 말합니다. 수집되는 정보는 회사 서비스 이용에 따른 통계∙분석에 이용될 수 있습니다.
    

## 제2조(개인정보 처리의 원칙)

개인정보 관련 법령 및 본 방침에 따라 회사는 이용자의 개인정보를 수집할 수 있으며 수집된 개인정보는 개인의 동의가 있는 경우에 한해 제3자에게 제공될 수 있습니다. 단, 법령의 규정 등에 의해 적법하게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3자에게 제공할 수도 있습니다.

## 제3조(본 방침의 공개)

① 회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 있도록 회사 홈페이지 첫화면 또는 첫 화면과의 연결화면을 통해 본 방침을 공개하고 있습니다.

② 회사는 제1항에 따라 본 방침을 공개하는 경우 글자 크기, 색상 등을 활용하여 이용자가 본 방침을 쉽게 확인할 수 있도록 합니다.

## 제4조(본 방침의 변경)

① 본 방침은 개인정보 관련 법령, 지침, 고시 또는 정부나 회사 서비스의 정책이나 내용의 변경에 따라 개정될 수 있습니다.

② 회사는 제1항에 따라 본 방침을 개정하는 경우 다음 각 호 하나 이상의 방법으로 공지합니다.

1. 회사가 운영하는 인터넷 홈페이지의 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지하는 방법

2. 서면·모사전송·전자우편 또는 이와 비슷한 방법으로 이용자에게 공지하는 방법

③ 회사는 제2항의 공지는 본 방침의 개정의 시행일로부터 최소 7일 이전에 공지합니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 공지합니다.

## 제5조(개인정보 수집 방법)

회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.

1. 이용자가 회사의 홈페이지에 자신의 개인정보를 입력하는 방식

2. 어플리케이션 등 회사가 제공하는 홈페이지 외의 서비스를 통해 이용자가 자신의 개인정보를 입력하는 방식

3. 회사명의 발송의 이메일을 수신한 이용자가 이에 대해 응답하는 방식

4. 이용자가 고객센터의 상담, 게시판에서의 활동 등 회사의 서비스를 이용하는 과정에서 이용자가 입력하는 방식

## 제6조(개인정보 제3자 제공 금지)

회사는 개인정보의 수집·이용목적에서 고지한 범위에서 개인정보를 이용하며, 이용자의 사전 동의 없이 개인정보 수집이용 목적범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다.

## 제7조(사전동의 등에 따른 개인정보의 제공)

① 회사는 개인정보 제3자 제공 금지에도 불구하고, 이용자가 사전에 공개하거나 다음 각호 사항에 대하여 동의한 경우에는 제3자에게 개인정보를 제공할 수 있습니다. 다만 이 경우에도 회사는 관련 법령 내에서 최소한으로 개인정보를 제공합니다.

가. 회사 호스트에게 물품 보관 및 찾기를 위해 고객의 이름, 이메일 정보를 제공

나. 회사 고객에게 물품보관 및 찾기를 위해 호스트 상점의 주소, 위치, 상호명, 전화번호를 제공

② 회사는 전항의 제3자 제공 관계에 변화가 있거나 제3자 제공 관계가 종결될 때도 같은 절차에 의해 이용자에게 고지 및 동의를 구합니다.

## 제8조(법령 등에 따른 개인정보의 제공)

회사는 다음 각호의 어느 하나에 해당하는 경우에는 이용자의 동의 없이 이용자의 개인정보를 수집·이용할 수 있습니다.

1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적·기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우

2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우

3. 법령에 개인정보 제공에 관한 특별한 규정이 있는 경우

## 제9조(개인정보의 처리 위탁)

① 회사는 원활한 서비스 제공과 효과적인 업무를 처리하기 위하여 다음 각 호와 같이 개인정보를 처리 위탁하고 있습니다.

1.[아임포트],[엑심베이][페이팔][라인]에게 [글로벌 신용카드결제, 메신저 결제, 중국/ 일본/동남아 특화결제]를 위하여 [개인정보이용기간]동안 개인정보처리 를 위탁함

② 회사는 수탁자들이 위탁한 개인정보를 안전하게 처리하고 있는지 지속적으로 관리 감독하고 있으며, 수탁업무가 종료된 때에 수탁자가 보유하고 있는 개인정보는 즉시 파기하게 하고 있습니다.

## 제10조(개인정보의 보유 및 이용기간)

① 회사는 이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성을 위한 기간동안 개인정보를 보유 및 이용합니다.

② 전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관합니다.

## 제11조(법령에 따른 개인정보의 보유 및 이용기간)

회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.

1. 전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유정보 및 보유기간

- 계약 또는 청약철회 등에 관한 기록 : 5년
- 대금결제 및 재화 등의 공급에 관한 기록 : 5년
- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
- 표시•광고에 관한 기록 : 6개월

2. 통신비밀보호법에 따른 보유정보 및 보유기간

- 웹사이트 로그 기록 자료 : 3개월

3. 전자금융거래법에 따른 보유정보 및 보유기간

- 전자금융거래에 관한 기록 : 5년

4. 위치정보의 보호 및 이용 등에 관한 법률

- 개인위치정보에 관한 기록 : 6개월

## 제12조(개인정보의 파기원칙)

회사는 원칙적으로 이용자의 개인정보 처리 목적의 달성, 보유·이용기간의 경과 등 개인정보가 필요하지 않을 경우에는 해당 정보를 지체 없이 파기합니다.

## 제13조(서비스 미이용자에 대한 개인정보 처리)

① 회사는 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 원칙적으로 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장 합니다.

② 회사는 장기 미이용 이용자의 개인정보는 별도로 분리되어 안전하게 보관하게 되며, 해당 이용자의 통지는 분리 보관 처리 일을 기준으로 최소 30일 이전에 전자우편주소로 전송됩니다.

③ 장기 미이용 이용자는 회사가 미이용자 DB를 별도로 분리하기 전에 계속 서비스를 이용하고자 하는 경우 웹사이트(이하 ‘모바일앱’ 포함)에 로그인 하시면 됩니다.

④ 장기 미이용 이용자는 웹사이트에 로그인할 경우 이용자의 동의에 따라 본인의 계정을 복원할 수 있습니다.

⑤ 회사는 분리보관된 개인정보를 4년간 보관 후 지체없이 파기합니다.

## 제14조(개인정보파기철자)

① 이용자가 회원가입 등을 위해 입력한 정보는 개인정보 처리 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기 되어집니다.

② 회사는 파기 사유가 발생한 개인정보를 개인정보보호 책임자의 승인절차를 거쳐 파기 합니다.

## 제15조(개인정보파기방법)

회사는 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이로 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.

## 제16조(광고정보의 전송 조치)

① 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 이용자의 명시적인 사전동의를 받습니다. 다만, 다음 각호 어느 하나에 해당하는 경우에는 사전 동의를 받지 않습니다

1. 회사가 재화등의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한 경우, 거래가 종료된 날로부터 6개월 이내에 회사가 처리하고 수신자와 거래한 것과 동종의 재화등에 대한 영리목적의 광고성 정보를 전송하려는 경우

2. 「방문판매 등에 관한 법률」에 따른 전화권유판매자가 육성으로 수신자에게 개인정보의 수집출처를 고지하고 전화권유를 하는 경우

② 회사는 전항에도 불구하고 수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하지 않으며 수신거부 및 수신동의 철회에 대한 처리 결과를 알립니다.

③ 회사는 오후 9시부터 그 다음 날 오전 8시까지의 시간에 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우에는 제1항에도 불구하고 그 수신자로부터 별도의 사전 동의를 받습니다.

④ 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음 각 호의 사항 등을 광고성 정보에 구체적으로 밝힙니다.

1. 회사명 및 연락처

2. 수신의 거부 또는 수신동의의 철회 의사표시에 관한 사항의 표시

⑤ 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음 각 호의 어느 하나에 해당하는 조치를 하지 않습니다.

1. 광고성 정보 수신자의 수신거부 또는 수신동의의 철회를 회피·방해하는 조치

2. 숫자·부호 또는 문자를 조합하여 전화번호·전자우편주소 등 수신자의 연락처를 자동으로 만들어 내는 조치

3. 영리목적의 광고성 정보를 전송할 목적으로 전화번호 또는 전자우편주소를 자동으로 등록하는 조치

4. 광고성 정보 전송자의 신원이나 광고 전송 출처를 감추기 위한 각종 조치

5. 영리목적의 광고성 정보를 전송할 목적으로 수신자를 기망하여 회신을 유도하는 각종 조치

## 제17조(아동의 개인정보보호)

① 회사는 만 14세미만 아동의 개인정보 보호를 위하여 만 14세 이상의 이용자에 한하여 회원가입을 허용합니다.

② 제1항에도 불구하고 회사는 이용자가 만 14세 미만의 아동일 경우에는, 그 아동의 법정대리인으로부터 그 아동의 개인정보 수집, 이용, 제공 등의 동의를 그 아동의 법정대리인으로부터 받습니다.

③ 제2항의 경우 회사는 그 법정대리인의 이름, 생년월일, 성별, 중복가입확인정보(ID), 휴대폰번호 등을 추가로 수집합니다.

## 제18조(개인정보수집철회등)

① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 개인정보수집 동의 철회를 요청할수 있습니다.

② 이용자 및 법정 대리인은 자신의 가입정보 수집등에 대한 동의를 철회하기 위해서는 개인정보보호책임자 또는 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 회사는 지체 없이 조치하겠습니다.

[개인정보 보호 문의 연락처]

개인정보 보호 담당자

- 성명: 이용욱

-전화번호: +82 1877-9727

-이메일: lyu@lugstay.com

## 제19조(개인정보 정보변경등)

① 이용자는 회사에게 전조의 방법을 통해 개인정보의 오류에 대한 정정을 요청할 수 있습니다.

② 회사는 전항의 경우에 개인정보의 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않으며 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.

## 제20조(이용자의 의무)

① 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.

② 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌 받을 수 있습니다.

③ 이용자는 전자우편주소, 비밀번호 등에 대한 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여 할 수 없습니다.

## 제21조(회사의 개인정보 관리)

회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.

## 제22조(삭제된 정보의 처리)

회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 회사가 수집하는 개인정보의 보유 및 이용기간에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

## 제23조(비밀번호의 암호화)

이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

## 제24조(해킹 등에 대비한 대책)

① 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.

② 회사는 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다.

④ 회사는 민감한 개인정보(를 수집 및 보유하고 있는 경우)를 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.

## 제25조(개인정보 처리 최소화 및 교육)

회사는 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.

제26조(개인정보보호 전담담당부서 운영)

회사는 개인정보의 보호를 위해 개인정보보호 전담부서를 운영하고 있으며, 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.

## 제27조(개인정보유출등에 대한 조치

① 회사는 개인정보의 분실·도난·유출(이하 유출등이라 한다) 사실을 안 때에는 지체 없이 다음 각 호의 모든 사항을 해당 이용자에게 알리고 방송통신위원회 또는 한국인터넷진흥원에 신고합니다.

1. 유출등이 된 개인정보 항목

2. 유출등이 발생한 시점

3. 이용자가 취할 수 있는 조치

4. 정보통신서비스 제공자등의 대응 조치

5. 이용자가 상담 등을 접수할 수 있는 부서 및 연락처

## 제28조(개인정보 유출등에 대한 조치의 예외)

회사는 전조에도 불구하고 이용자의 연락처를 알 수 없는 등 정당한 사유가 있는 경우에는 회사의 홈페이지에 30일 이상 게시하는 방법으로 전조의 통지를 갈음하는 조치를 취할 수 있습니다

## 제29조(이용자의 쿠키 설치 선택권)

① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.

② 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.

## 제38조(쿠키설치허용지정방법)

쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.

가. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.

나. [개인정보 탭]을 클릭합니다.

다. [고급]설정에서 설정하시면 됩니다.

제39조(회사의개인정보보호책임자지정)

회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.

가. 개인정보 보호 책임자

- 성명: 오상혁
- 전화번호: +82 1877-9727
- 이메일: david.oh@lugstay.com

나. 개인정보 보호 담당자

- 담당부서: 서비스 개발
- 성명: 이용욱
- 전화번호: +82 1877-9727
- 이메일: lyu@lugstay.com

## 제40조(이용자의 민원처리)

이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보 보호 책임자에게 신고할 수 있습니다. 회사는 이용자의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

## 제41조(이용자의기관문의)

이용자는 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의할 수 있습니다.

▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)

- 소관업무 : 개인정보 침해사실 신고, 상담 신청
- 홈페이지 : privacy.kisa.or.kr
- 전화 : (국번없이) 118
- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층

▶ 개인정보 분쟁조정위원회

- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정
- 홈페이지 : www.kopico.go.kr
- 전화 : 1833-6972
- 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층

▶ 대검찰청 사이버범죄수사단 : 02-3480-2000 (www.spo.go.kr)

▶ 경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)

### 부칙

제1조 본 방침은 2022. 04. 13.부터 시행됩니다.

제2조 개정 전 개인정보처리방침은 공지사항을 통하여 확인할 수 있습니다.

### 이용자의 동의

아래의 사람은 본 방침의 내용을 숙지하였는바, 본 방침의 동의란에 대한 체크표시로 본인의 개인정보 수집 및 제3자 제공에 대한 동의를 갈음합니다.`,
    };
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header .page-header__title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.markdown::v-deep {
  h1 {
    font-size: 22px;
    font-weight: bold;
  }

  h2 {
    margin-top: 24px;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }

  h3 {
    margin-top: 24px;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
  }

  ol {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  ul > li {
    line-height: 1.5;
  }

  li > p {
    font-size: 14px;
    color: #878d91;
  }
}
</style>
