<template>
  <div class="page" id="auth-reset-password-page">
    <div class="container">

      <div class="form" :class="{ initiated }">
        <div class="form-header">
          <div class="title">{{ $__t( "비밀번호 초기화하기" ) }}</div>
          <div class="description">
            <p>{{ $__t( "새로운 로그인 비밀번호를 설정합니다." ) }}</p>
          </div>
        </div>
        <div class="form-body">
          <ui-form @submit="submitForm" :formData="formData" ref="form">
            <ui-form-list>
              <ui-form-list-item>
                <ui-textbox :placeholder="formData.password.placeholder" :type="formData.password.type" :label="formData.password.label" :error="formData.password.error" v-model="formData.password.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-textbox :placeholder="formData.passwordConfirm.placeholder" :type="formData.passwordConfirm.type" :label="formData.passwordConfirm.label" :error="formData.passwordConfirm.error" v-model="formData.passwordConfirm.value" />
              </ui-form-list-item>
              <ui-form-list-item>
                <ui-solid-button display="block"><button type="submit"><i class="fa fa-envelope"></i>{{ $__t( "비밀번호 변경하기" ) }}</button></ui-solid-button>
              </ui-form-list-item>
            </ui-form-list>
          </ui-form>
        </div>
        <div class="form-footer">
          <p>{{ $__t( "이미 럭스테이의 회원이신가요?" ) }} <router-link :to="{ name: 'AuthLogin', query: { redirect: $router.currentRoute.fullPath } }">{{ $__t( "로그인" ) }}</router-link></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    var initiated = false;
    var formData = {
      password: {
        type: "password",
        label: this.$__t( "새 비밀번호" ),
        placeholder: this.$__t( "새 비밀번호를 입력해주세요" ),
        validation: [
          { type: "required" },{type: 'passwordLength'},
        ],
        value: "",
        error: ""
      },
      passwordConfirm: {
        type: "password",
        label: this.$__t( "새 비밀번호 확인" ),
        placeholder: this.$__t( "새 비밀번호를 한 번 더 입력해주세요" ),
        validation: [
          { type: "matchField:password" },
        ],
        value: "",
        error: ""
      },
    };
    return {
      initiated,
      formData
    };
  },
  created(){
    setTimeout( () => {
      if( this.$route.query.token ){
        this.initiated = true;
      }else{
        this.initiated = false;
        this.$store.commit( "alert/ADD_ITEM", { message: "1001:INSUFFICIENT_PARAMS", status: "error" });
        this.$router.replace({ name: "Home" });
      };
    }, 0);
  },
  methods: {
    submitForm( values ){
      this.$store.dispatch( "auth/reset", { token: this.$route.query.token ? this.$route.query.token : null, password: values.password }).then( res => {
        this.$refs.form.resetForm();
        location.replace( "/login" );
        this.$store.commit( "alert/ADD_ITEM", { message: "비밀번호가 설정되었습니다.", status: "success" });
      }).catch( err => {
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
  }
}
</script>
