<template>
  <table>
    <thead>
      <tr>
        <td>&nbsp;</td>
        <!-- <td><strong>Small</strong></td>
        <td><strong>Large</strong></td> -->
        <td>
          <strong>{{ $__t('요금') }}</strong>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {{ $__t('1 hour') }} <span class="detail">({{ $__t('basic fee') | capitalizeFirstLetter }})</span>
        </td>
        <!-- <td>2,000{{ $__t('won') }}</td>
        <td>3,000{{ $__t('won') }}</td> -->
        <td>2,500{{ $__t('won') }}</td>
      </tr>
      <tr class="bg-gray">
        <td>{{ $__t('per 10 minutes') }}</td>
        <!-- <td>100{{ $__t('won') }}</td>
        <td>150{{ $__t('won') }}</td> -->
        <td>150{{ $__t('won') }}</td>
      </tr>
      <tr>
        <td>2{{ $__t('시간') }}</td>
        <!-- <td>2,600{{ $__t('won') }}</td>
        <td>3,900{{ $__t('won') }}</td> -->
        <td>3,400{{ $__t('won') }}</td>
      </tr>
      <tr>
        <td class="linger">
          3{{ $__t('시간') }}
          <table>
            <tbody>
              <tr>
                <td>፧</td>
              </tr>
            </tbody>
          </table>
          7{{ $__t('시간') }}
        </td>
        <!-- <td>
          3,200{{ $__t('won') }}
          <table>
            <tbody>
              <tr>
                <td>፧</td>
              </tr>
            </tbody>
          </table>
          5,600{{ $__t('won') }}
        </td>
        <td>
          4,800{{ $__t('won') }}
          <table>
            <tbody>
              <tr>
                <td>፧</td>
              </tr>
            </tbody>
          </table>
          8,400{{ $__t('won') }}
        </td> -->
        <td>
          4,300{{ $__t('won') }}
          <table>
            <tbody>
              <tr>
                <td>፧</td>
              </tr>
            </tbody>
          </table>
          7,900{{ $__t('won') }}
        </td>
      </tr>
      <tr>
        <td>
          8{{ $__t('시간') }}~24{{ $__t('시간') }}
          <span class="detail block">({{ $__t('fixed') }})</span>
        </td>
        <!-- <td>6,000{{ $__t('won') }}</td>
        <td>9,000{{ $__t('won') }}</td> -->
        <td>8,500{{ $__t('won') }}</td>
      </tr>
      <tr>
        <td>
          {{ $__t('fixed charge after 24 hours') }}<span class="detail block">({{ $__t('per 24 hours') }})</span>
        </td>
        <!-- <td>4,000{{ $__t('won') }}</td>
        <td>6,000{{ $__t('won') }}</td> -->
        <td>5,000{{ $__t('won') }}/ +24h</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {};
</script>
