<template>
  <div class="lug-nav-menu" :class="{ 'lug-nav-menu--sidebar': isSidebar }">
    <div class="lug-nav-menu__container" @click.stop="() => {}">
      <div
        class="lug-nav-menu__my-page-area"
        :class="{ 'no-auth': !$store.state.auth.gid }"
      >
        <div class="lug-nav-menu__my-page-item lug-nav-menu__my-page-item--primary">
          <span @click="onClickMenu('profile')">
            {{ authPrimaryText | capitalizeFirstLetter }}
          </span>
        </div>
        <div class="lug-nav-menu__my-page-item lug-nav-menu__my-page-item--secondary">
          <template v-if="$store.state.auth.gid">
            {{ authSecondaryText }}
          </template>
          <template v-else>
            {{ authSecondaryText | capitalizeFirstLetter }}
          </template>
        </div>
        <div v-if="$store.state.auth.gid" class="lug-nav-menu__my-page-item coupon-card">
          <div
            class="coupon-card__card-item"
            v-button
            @click="onClickMenu('MypageCoupons')"
          >
            <div class="card-item-content">
              <div class="card-item-content__label">
                {{ $__t("사용가능") }} {{ $__t("이용권") }}/{{ $__t("쿠폰") }}
              </div>
              <div class="card-item-content__value">
                <strong>{{ targetItems.length }} </strong>{{ $__t("개") }}
              </div>
            </div>
            <div class="card-item-icon">
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
          <!-- <div class="coupon-card__card-item">
            <div class="card-item-content">
              <div class="card-item-content__label">포인트</div>
              <div class="card-item-content__value"><strong>0</strong> 포인트</div>
            </div>
            <div class="card-item-icon">
              <i class="fa fa-angle-right"></i>
            </div>
          </div> -->
        </div>
      </div>
      <div class="lug-nav-menu__card">
        <ul class="lug-nav-menu__list">
          <!-- <li class="lug-nav-menu__list-item" v-button @click="onClickMenu('MoreBillings')" :class="{ disabled: !$store.state.auth.gid }">
            <span>{{ $__t('Payment Card') | capitalizeFirstLetter }}</span>
          </li> -->
          <li
            class="lug-nav-menu__list-item"
            v-button
            @click="onClickMenu('Usage')"
            :class="{ disabled: !$store.state.auth.gid }"
          >
            <span>{{ $__t("Usage") | capitalizeFirstLetter }}</span>
          </li>
          <li
            class="lug-nav-menu__list-item"
            v-button
            :class="{ disabled: !$store.state.auth.gid }"
            @click="onClickMenu('MypageCoupons')"
          >
            <span>{{
              ($__t("쿠폰") + "/" + $__t("이용권")) | capitalizeFirstLetter
            }}</span>
          </li>
          <li
            class="lug-nav-menu__list-item"
            v-button
            @click="onClickMenu($store.state.auth.host_id ? 'Host' : 'HostLanding')"
          >
            <template v-if="$store.state.auth.host_id">
              <span>{{ $__t("호스트 페이지") | capitalizeFirstLetter }}</span>
            </template>
            <template v-else>
              <span>{{ $__t("호스트 신청") | capitalizeFirstLetter }}</span>
            </template>
          </li>
        </ul>
      </div>
      <div class="lug-nav-menu__card">
        <ul class="lug-nav-menu__list">
          <li class="lug-nav-menu__list-item" v-button @click="onClickMenu('CsNotices')">
            <span>{{ $__t("공지사항") | capitalizeFirstLetter }}</span>
          </li>
          <li
            class="lug-nav-menu__list-item"
            v-button
            @click="openMenuDepth('MoreGuides')"
          >
            <span>{{ $__t("이용안내") | capitalizeFirstLetter }}</span>
          </li>
          <li
            class="lug-nav-menu__list-item"
            v-button
            @click="openMenuDepth('MorePolicy')"
          >
            <span>{{ $__t("약관 및 정책") | capitalizeFirstLetter }}</span>
          </li>
          <li
            class="lug-nav-menu__list-item"
            v-button
            @click="openMenuDepth('MoreLanguage')"
          >
            <span>{{ $__t("언어 설정") | capitalizeFirstLetter }}</span>
          </li>
        </ul>
      </div>
      <div class="lug-nav-menu__section">
        <div class="section-menu__text section-menu__text--primary">
          {{ $__t("C/S") | capitalizeFirstLetter }}
        </div>
        <div class="section-menu__text section-menu__text--secondary">
          {{ $__t("__고객센터 운영시간") | capitalizeFirstLetter }}
        </div>
        <ul class="section-menu__link-list">
          <li
            v-button
            @click="$analytics.logEvent('snb click', { menu: 'call' })"
            class="section-menu__link-item"
          >
            <a href="tel:+82-218779727">
              <div class="card">
                <span class="text">1877-9727</span>
                <img alt="cs phone" src="/img/icon-cs-phone.png" />
              </div>
            </a>
          </li>
          <li v-button @click="openChannelTalk" class="section-menu__link-item">
            <div class="card">
              <span class="text">1:1 {{ $__t("채팅") }}</span>
              <img alt="cs phone" src="/img/icon-cs-talk.png" />
            </div>
          </li>
        </ul>
      </div>
      <p
        style="
          line-height: 2.5rem;
          color: #878d91;
          text-align: right;
          margin-right: 1.333rem;
        "
      >
        v.{{ $store.state.config.version }}
      </p>
      <ul class="company-info">
        <li>(주)블루웨일컴퍼니</li>
        <li>사업자 등록번호 : 689-87-01050</li>
        <li>통신판매업 신고번호 : 제 2018-서울서초-2432호</li>
        <li>본사 : 서울 종로구 청계천로 85 삼일빌딩 9층 블루웨일컴퍼니</li>
        <li>지사 : 제주특별자치도 제주시 중앙로 217, 제주벤처마루 4층(이도이동)</li>
      </ul>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import Footer from "@/components/outline/v2/Footer.vue";

export default {
  props: ["isSidebar"],
  name: "MoreList",

  data() {
    return {
      innerWidth: window.innerWidth,
      depth: 0,
      selectedMenu: "",
      selectedLang: "",
      onTouchVersionTimer: null,
    };
  },

  created() {
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.addEventListener("resize", this.onResize);
  },

  computed: {
    isLogin() {
      return !!this.$store.state?.auth?.gid;
    },

    userName() {
      let shortName = this.$store.state.auth?.user_name;
      let fullName =
        this.$store.state.auth?.user_last_name && this.$store.state.auth?.user_last_name
          ? this.$store.state.auth?.user_last_name +
            " " +
            this.$store.state.auth?.user_last_name
          : "";

      return shortName || fullName;
    },

    authPrimaryText() {
      return this.userName || this.$__t("로그인");
    },

    authSecondaryText() {
      return (
        this.$store.state.auth?.user_email ||
        this.$store.state.auth?.account ||
        this.$__t("럭스테이로 편하게 보관하세요.")
      );
    },

    toUsage() {
      if (this.wni.isNative) {
        return { name: "MoreTickets" };
      }

      return { name: "MyReservation" };
    },

    targetItems() {
      const coupons = this.$store.getters["resourceCoupons/GET_ITEMS_BY_LIST"]({
        list: "MyCoupons",
      });
      const passes = this.$store.getters["resourceProducts/GET_ITEMS_BY_LIST"]({
        list: "MyPasses",
      });

      return [...passes, ...coupons];
    },
  },

  methods: {
    openMenuDepth(menu) {
      let menuLowerCased = menu.toLowerCase();
      let event = "";

      if (menuLowerCased.includes("guide")) {
        event = "service guide";
      } else if (menuLowerCased.includes("policy")) {
        event = "policy";
      } else if (menuLowerCased.includes("language")) {
        event = "language";
      }

      this.$analytics.logEvent("snb click", { menu: event });

      if (!!this.isSidebar) {
        return this.$parent.switchTargetComponent({
          targetComponent: menu,
          depth: 1,
        });
      }

      this.$router.push({ name: menu }).then(() => {});
    },

    openChannelTalk() {
      this.$analytics.logEvent("snb click", { menu: "live chat" });
      this.$emit("close-sidebar");

      if (this.wni.isNative) {
        return this.wni.execute("wnPopupOpen", {
          url: `https://lugstay.channel.io`,
        });
      }

      return this.$channelTalk.openChat();
    },

    onResize(e) {
      this.innerWidth = window.innerWidth;
    },

    setLanguage(locale) {
      if (!this.$store.state.auth.gid) {
        localStorage.setItem("lugstay_language", locale);
        window.location = utils.removeURLParameter(window.location.href, "language");
        this.$analytics.setLanguage(locale);
        this.$router.go("/");
        return;
      }

      this.$store
        .dispatch("auth/edit", {
          user_language: locale,
        })
        .finally(() => {
          localStorage.setItem("lugstay_language", locale);
          window.location = utils.removeURLParameter(window.location.href, "language");
          this.$analytics.setLanguage(locale);
          this.$router.go("/");
        });
    },

    onClickMenu(menu) {
      this.$emit("close-sidebar");

      setTimeout(() => {
        switch (menu) {
          case "MoreBillings":
            this.$analytics.logEvent("snb click", { menu: "billing" });
            this.$router.push({ name: "MoreBillings" }).catch(() => {});
            break;

          case "Usage":
            this.$analytics.logEvent("snb click", { menu: "my booking" });
            this.$router.push(this.toUsage).catch(() => {});
            break;

          case "MypageCoupons":
            this.$analytics.logEvent("snb click", { menu: "my coupons" });
            this.$router.push({ name: "MypageCoupons" }).catch(() => {});
            break;

          case "Shop":
            this.$router.push({ name: "Shop" }).catch(() => {});
            break;

          case "Host":
            this.$analytics.logEvent("snb click", { menu: "host" });
            this.$router.push({ name: "Host" }).catch(() => {});
            break;

          case "HostLanding":
            this.$analytics.logEvent("snb click", { menu: "become host" });
            this.$router.push({ name: "HostLanding" }).catch(() => {});
            break;

          case "profile":
            this.$analytics.logEvent("snb click", {
              menu: this.isLogin ? "my page" : "login",
            });
            this.$router
              .push({
                name: this.isLogin ? "MypageProfile" : "AuthLogin",
                query: {
                  redirect: !this.isLogin ? this.$router.currentRoute.fullPath : "",
                },
              })
              .catch(() => {});
            break;

          case "CsNotices":
            this.$analytics.logEvent("snb click", { menu: "notice" });
            this.$router.push({ name: "CsNotices" }).catch(() => {});
            break;
        }
      }, 0);
    },

    onClickBack() {
      this.depth -= 1;
      this.$router.back();
    },

    onTouchStartVersion() {
      this.timer = setTimeout(() => {
        let confirmed = confirm(
          this.$__t(
            "개발자 옵션을 " +
              (this.$store.state.developerOption ? "비활성화" : "활성화") +
              "하시겠습니까?"
          )
        );

        if (confirmed) {
          this.$store.commit("TOGGLE_STATUS", { key: "developerOption" });
        }
      }, 2000);
    },

    onTouchEndVersion() {
      clearTimeout(this.timer);
      this.timer = null;
    },
  },

  components: {
    Footer,
  },
};
</script>
