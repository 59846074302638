<template>
	<div id="share-ticket-single">
		<!-- <div class="on-pc header">{{ $__t('My Ticket') }}</div> -->
		<div v-if="isLoading" style="width:100%; height: 100%; z-index:10000; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(255,255,255);">
			<div style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center;">
				<i class="fa fa-spinner fa-pulse"></i>
			</div>
		</div>
		<div v-if="!doc && ticket" id="share-auth">
			<div class="container">
        <div class="information" v-if="(ticket.issue_type == 'DELIVERY' || ticket.issue_type == 'DELIVERY_O') && isBeforeStatusThan('CHECK_IN')" v-html="deliveryDropHeader" />
				<div class="information" v-else-if="(ticket.issue_type == 'DELIVERY' || ticket.issue_type == 'DELIVERY_O') && !isBeforeStatusThan('CHECK_IN')" v-html="deliveryReceiveHeader" />
        <div class="information" v-else-if="ticket.issue_type == 'RESERVE_O'"  v-html="deliveryDropPickHeader" />
				<div class="information" v-else v-html="passedFromHeader" />
				<div class="share-pass-info">
					{{ $__t('Insert Ticket Password') }}
				</div>
				<div class="share-pass">
					{{ $__t('Insert Password') }}
				</div>
				<div id="share-pass-div" @click="inputFocus" class="share-pass-input">
					<div style="cursor: pointer;"><span v-if="pass && pass[0]" style="font-size: 3.75rem;">·</span></div>
					<div style="cursor: pointer;"><span v-if="pass && pass[1]" style="font-size: 3.75rem;">·</span></div>
					<div style="cursor: pointer;"><span v-if="pass && pass[2]" style="font-size: 3.75rem;">·</span></div>
					<div style="cursor: pointer;"><span v-if="pass && pass[3]" style="font-size: 3.75rem;">·</span></div>
					<input ref="input" inputmode="numeric" style=" position:absolute; top:4rem; border:none; background:transparent color:#ffffff" type="text" v-model="pass" maxlength="4" />
				</div>
				<div class="share-pass-information">{{ $__t('Password exists for the security/confidentiality.') }}<br />{{ $__t('Ask the password from who passed the ticket to you.') }}</div>
			</div>
		</div>
		<div v-if="doc" id="sharepage-ticket-single" style="padding-bottom: 0;">
			<div class="container">
				<div class="body">
					<div class="introduction">
						<div v-if="doc.status === 'ISSUED'" class="alert">*{{ $__t('{ticket-single}.alert.checkin.qrcode') }}</div>
            <div v-else class="alert">*{{ $__t('Please make sure to press STOP button after you pick up your item.') }}</div>
						<div class="about">
							{{ $__t('hi') | capitalizeFirstLetter }}!<br />
							{{ $__t('Store Renting Spaces') }} {{ $__t('Luggage Storage Service') }}<br />
							{{ $__t('LugStay_') }}
						</div>
						<template v-if="doc.issue_type == 'DELIVERY' || doc.issue_type == 'DELIVERY_O'">
							<p v-if="isBeforeStatusThan('CHECK_IN')" class="origin" v-html="deliveryDropHeader" />
							<p v-else class="origin" v-html="deliveryReceiveHeader" />
						</template>
						<template v-else>
							<template v-if="doc.issue_type == 'RESERVE'">
								<p class="origin" v-html="passedFrom" />
							</template>
							<template v-else>
								<p class="origin" v-html="deliveryDropPickHeader" />
							</template>
						</template>
					</div>
					<div class="box message">
            <div v-if="doc.status === 'ISSUED'" class="qrcode">
              <qr-code :code="doc.ticket_code" />
							<ui-border-arrow-button v-if="doc.store_id" color="white" size="medium" display="inline" class="store-button" @click="goToStore">
								{{ $__t('go to the store') }}
							</ui-border-arrow-button>
            </div>
						<div v-else class="photo">
							<template v-if="doc.resources && doc.resources.luggage_photo && doc.resources.luggage_photo.resource_url">
								<div v-viewer="{ toolbar: false, title: false }">
									<div v-for="src in [doc.resources.luggage_photo.resource_url]" :key="src" class="img-wrapper">
										<img :src="src" :key="src" />
										<div class="zoomable" />
									</div>
								</div>
							</template>
							<img src="/img/no-image.png" class="luggage" v-else />
							<ui-border-arrow-button v-if="doc.store_id || doc.recipient_store_id" color="white" size="medium" display="inline" class="store-button" @click="goToStore">
								{{ $__t('go to the store') }}
							</ui-border-arrow-button>
							<div class="expired-stamp" v-if="doc.status === 'EXPIRED' || doc.status == 'CANCELLED' || doc.status == 'CHECK_OUT'">{{ $__t('_' + doc.status) }}</div>
						</div>
						<div class="code">
							{{ doc.ticket_code }}
						</div>
						<div v-if="doc.ticket_type === 'LUGGAGE' && doc.share.message" class="textbox">
							{{ doc.share.message }}
						</div>
					</div>

					<div class="box luggage">
						<div class="reservation">
							<dl>
								<dt class="key">{{ $__t('booked time') | capitalizeFirstLetter }}</dt>
								<dd class="value">{{ $moment(doc.ticket_date_start).format('MM-DD HH:mm') + ' ~ ' + $moment(doc.ticket_date_end).format('MM-DD HH:mm') }}</dd>
							</dl>
							<dl>
                <dt v-if="doc.status === 'ISSUED'" class="key">{{ $__t('Items to be stored') }}</dt>
								<dt v-else class="key">{{ $__t('stored item') }}</dt>
								<dd class="value">
									{{ doc.ticket_quantity ? 'Small ' + doc.ticket_quantity + (doc.ticket_quantity > 1 ? $__t('개') : $__t('item')) : '' }}
									{{ doc.ticket_over_quantity ? 'Large ' + doc.ticket_over_quantity + (doc.ticket_over_quantity > 1 ? $__t('개') : $__t('item')) : '' }}
								</dd>
							</dl>
							<ui-solid-button v-if="doc.ticket_type === 'DELIVERY' && !isBeforeStatusThan('DROP_OFF')" :color="doc.status == 'DROP_OFF' ? 'red' : 'disabled'" :disabled="doc.status !== 'DROP_OFF'" display="block" class="stop-button" @click="checkout"
								><button type="button">{{ $__t('{share-ticket}.pick_up.button') }}</button></ui-solid-button
							>
							<ui-border-arrow-button v-else-if="doc.ticket_type === 'DELIVERY' && isBeforeStatusThan('DROP_OFF')" display="block" size="little-large" class="stop-button" @click="$router.push({ name: 'DeliveryStatus', params: {id : doc.ticket_code} })"
								><button type="button">{{ $__t('{usage-info}.button.delivery.status') }}</button></ui-border-arrow-button
							>
						</div>
						<div v-if="doc.ticket_type === 'LUGGAGE' && doc.status !== 'ISSUED'" class="price">
							<dl>
								<dt class="key">{{ $__t('usage time') | capitalizeFirstLetter }}</dt>
								<dd class="value">{{ timePassing }}</dd>
							</dl>
							<dl>
								<dt class="key">{{ $__t('usage price') | capitalizeFirstLetter }}</dt>
								<dd class="value">{{ price && price.total ? price.total.toLocaleString()+$__t('won') : (doc && doc.paid_total ? doc.paid_total.toLocaleString()+$__t('won') : '') }}</dd>
							</dl>
							<ui-border-arrow-button color="black" size="medium" display="inline" class="price-button" @click="openModal('priceGuideModal')">
								{{ $__t('Price') }}
							</ui-border-arrow-button>

							<ui-solid-button :color="doc.status == 'CHECK_IN' ? 'red' : 'disabled'" display="inline" class="stop-button" @click="openModal('checkoutModal')"
								><button type="button">{{ $__t('stop') }}</button></ui-solid-button
							>
						</div>
					</div>
				</div>
				<div class="footer">
					<ul>
						<ul v-if="doc.status === 'ISSUED'">
							<li class="title">{{ $__t('{ticket-single}.guide.checkin.title') }}</li>
							<li>1) {{ $__t("{ticket-single}.guide.checkin.content.1") }}</li>
							<li>2) {{ $__t("{ticket-single}.guide.checkin.content.2") }}</li>
						</ul>

						<li class="title">{{ $__t('How to pick up?') }}</li>
						<li>1) {{ $__t('Push the GO TO THE STORE button above the item image verify the location of the store and visit the store.') }}</li>
						<li>2) {{ $__t('Show the item image in the ticket and receive the stored item.') }}</li>
						<li>3) {{ $__t('Please make sure to press STOP button after you pick up your item.') }} {{ $__t('When you push STOP button, you can see check-out page with price details.') }}</li>
						<li>4) {{ $__t("Unless 'STOP' - 'CHECK OUT' procedure is done, there will be additional charge. Please make sure to check-out after you receive the item.") }}</li>

						<li class="title">{{ $__t('Notice') }}</li>
						<li>-{{ $__t('Storage and pick-up is only available during opening hours of the store.') }} {{ $__t('Please be sure to check the opening hours.') }}</li>

						<li class="title">{{ $__t('Compensation Policy') }}</li>
						<li>
							{{ $__t('We selected partners, who have CCTV and extra storage space.') }}
							{{ $__t('In case of loss/damage, compensation is made up to 300,000 won, according to the compensation policy.') }}
						</li>

						<li class="title">{{ $__t('What kind of service is LugStay?') }}</li>
						<li>1) {{ $__t('Store and pick up item freely by sharing spaces.') }}</li>
						<li>2) {{ $__t('Stored item will be kept secured.') }}</li>
						<li>3) {{ $__t('The item I dropped off can be picked up by someone else.') }}</li>
					</ul>
				</div>
			</div>
			<div class="unactive" v-if="doc && (doc.status === 'CANCELLED' || doc.status === 'EXPIRED' || doc.status == 'CHECK_OUT')" />
		</div>
		<!-- <site-footer class="on-pc"></site-footer> -->

		<guide-modal id="priceGuideModal" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
			<template v-slot:body>
				<price-guide />
			</template>
		</guide-modal>

		<full-modal id="checkoutModal" ref="checkoutModal">
			<template v-slot:body>
				<checkout :ticket="doc" :duration="duration" :price="price" :account="$route.query.phone" :password="pass" @success="checkoutSuccess" @close="closeModal('checkoutModal')"/>
			</template>
		</full-modal>
	</div>
</template>

<script>
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import Vue from 'vue';
Vue.use(Viewer);

import QrCode from '@/components/includes/QrCode';
import SiteFooter from '@/components/outline/Footer';
import PriceGuide from '@/components/pages/_/PriceGuide';
import Checkout from '@/components/pages/_/Checkout';
import FullModal from '@/components/modules/LugModal/FullModal';

export default {
	name: 'ShareTicketSingle',
	inheritAttrs: false,
	components: {
		QrCode,
		SiteFooter,
		PriceGuide,
		Checkout,
		FullModal,
	},
	data() {
		return {
			doc: null,
			ticket: null,
			share: null,
			code: null,
			isLoading: false,
			pass: '',
			timeLeft: '',
			price: '',
			duration: '',
			
			luggageStatusList: ["ISSUED", "CHECK_IN", "CHECK_OUT", "CANCELLED", "EXPIRED"],
      		deliveryStatusList: ["CANCELLED", "ISSUED", "CHECK_IN", "ASSIGNING", "APPROVE", "PICK_UP", "DROP_OFF", "CHECK_OUT", "EXPIRED"],
		};
  },
	async created() {
    await this.getTicketData(this.$route.params.code);

    this.$nextTick( () => {
      this.inputFocus();

			if(this.$route.query.type === 'iez'){
				this.pass = '9727'; //iez 유저들 티켓 바로 확인
			}
    })
	},
	mounted() {
    window.addEventListener('popstate', this.onBack);
  },
	async beforeDestroy() {
    await this.$store.dispatch('interval/clear');
		await this.toggleDurationTimer('off');
		window.removeEventListener('popstate', this.onBack);
	},
	computed: {
		timePassing() {
			let time = '';
			time += this.timeLeft.split('/')[0] || '0';
			time += this.$__t('D') + ' ';
			time += this.timeLeft.split('/')[1] || '0';
			time += this.$__t('h') + ' ';
			time += this.timeLeft.split('/')[2] || '0';
			time += this.$__t('m') + ' ';
			time += this.timeLeft.split('/')[3] || '0';
			time += this.$__t('s');

			return this.doc.status != 'CHECK_OUT' ? time : this.getfinalDuration;
    },

    getfinalDuration() {
			const check_in = this.$moment(this.doc.datetime_check_in);
			const date_start = this.$moment(this.doc.ticket_date_start);
			const check_out = this.$moment(this.doc.datetime_check_out);

			const duration = this.$moment(date_start).isAfter(check_in) ? this.$moment.duration(check_out.diff(check_in)) : this.$moment.duration(check_out.diff(date_start));
			const flooredDuration = `${Math.floor(duration.asDays())}/${duration.hours()}/${duration.minutes()}/${duration.seconds()}`;

			let time = '';
			time += flooredDuration.split('/')[0] || '0';
			time += this.$__t('D') + ' ';
			time += flooredDuration.split('/')[1] || '0';
			time += this.$__t('h') + ' ';
			time += flooredDuration.split('/')[2] || '0';
			time += this.$__t('m') + ' ';
			time += flooredDuration.split('/')[3] || '0';
			time += this.$__t('s');

			return time;
    },

		passedFrom() {
			const lang = this.$store.state.lang;

			switch (lang) {
				case 'ko':
					return `${this.doc.user_name}님이 ${this.doc.store_name}에서 물품을 찾을 수 있는 티켓을 전달하셨습니다`;
					break;
				case 'en':
					return `${this.doc.user_name} forwarded a ticket to retrieve the item on ${this.doc.store_name}`;
					break;
				case 'cn':
					return `${this.doc.user_name}转发了一张票证以在${this.doc.store_name}上检索该物品。`;
					break;
				case 'hk':
					return `${this.doc.user_name}轉發了一張票證以在${this.doc.store_name}上檢索該物品。`;
					break;
				case 'jp':
					return `${this.doc.user_name}さんが${this.doc.store_name}で物品を見つけることができるチケットを渡しました`;
					break;
				case 'vn':
					return `${this.doc.user_name} đã chuyển tiếp một phiếu để lấy vật phẩm trên ${this.doc.store_name}`;
					break;
			}
		},

		passedFromHeader() {
			const lang = this.$store.state.lang;
			if (this.ticket) {
				switch (lang) {
					case 'ko':
						return `<strong>${this.ticket.reserve_user_name.split(" ").reverse().join("")}</strong>님이 <strong>${this.ticket.store_name}</strong>에서<br />물품을 찾을 수 있는 티켓을 전달하셨습니다`;
						break;
					case 'en':
						return `<strong>${this.ticket.reserve_user_name}</strong> forwarded a ticket to retrieve the item on <strong>${this.ticket.store_name}</strong>`;
						break;
					case 'cn':
						return `<strong>${this.ticket.reserve_user_name}</strong>转发了一张票证以在<strong>${this.ticket.store_name}</strong>上检索该物品。`;
						break;
					case 'hk':
						return `<strong>${this.ticket.reserve_user_name}</strong>轉發了一張票證以在<strong>${this.ticket.store_name}</strong>上檢索該物品。`;
						break;
					case 'jp':
						return `<strong>${this.ticket.reserve_user_name}</strong>さんが<strong>${this.ticket.store_name}</strong>で物品を見つけることができるチケットを渡しました`;
						break;
					case 'vn':
						return `<strong>${this.ticket.reserve_user_name}</strong> đã chuyển tiếp một phiếu để lấy vật phẩm trên <strong>${this.ticket.store_name}</strong>`;
						break;
				}
			}
		},

    deliveryDropHeader(){
      const lang = this.$store.state.lang;
			if (this.ticket) {
				let sendertPlace = this.ticket.sender_store_id ? this.ticket.sender_store_name : this.ticket.sender_place;

				switch (lang) {
					case 'ko':
						return `<strong>${this.ticket.reserve_user_name.split(" ").reverse().join("")}</strong>님이 <strong>${sendertPlace}</strong>에서 물품을 배송할 수 있는 티켓이 전달되었습니다.`;
						break;
					case 'en':
						return `A ticket for <strong>${this.ticket.reserve_user_name}</strong>to ship the item at <strong>${sendertPlace}</strong> has been delivered.`;
						break;
					case 'cn':
						return `已交付<strong>${this.ticket.reserve_user_name}</strong>用于在<strong>${sendertPlace}</strong>处运送物品的票证。`;
						break;
					case 'hk':
						return `已交付<strong>${this.ticket.reserve_user_name}</strong>用於在<strong>${sendertPlace}</strong>處運送物品的票證。`;
						break;
					case 'jp':
						return `<strong>${this.ticket.reserve_user_name.split(" ").reverse().join("")}</strong>様<strong>${sendertPlace}</strong>で物品を出荷することができるチケットが渡されました。`;
						break;
					case 'vn':
						return `Một phiếu cho <strong>${this.ticket.reserve_user_name}</strong> để chuyển hàng tại <strong>${sendertPlace}</strong> đã được giao.`;
						break;
				}
			}
		},
		
		deliveryReceiveHeader(){
      const lang = this.$store.state.lang;
			if (this.ticket) {
				let recipientPlace = this.ticket.recipient_store_id ? this.ticket.recipient_store_name : this.ticket.recipient_place;

				switch (lang) {
					case 'ko':
						return `<strong>${this.ticket.reserve_user_name.split(" ").reverse().join("")}</strong>님이 <strong>${recipientPlace}</strong>에서 물품을 받을 수 있는 티켓을 전달하셨습니다.`;
						break;
					case 'en':
						return `<strong>${this.ticket.reserve_user_name}</strong> has delivered a ticket to receive items at <strong>${recipientPlace}</strong>.`;
						break;
					case 'cn':
						return `<strong>${this.ticket.reserve_user_name}</strong>已交付票证，用于在<strong>${recipientPlace}</strong>处接收物品。`;
						break;
					case 'hk':
						return `<strong>${this.ticket.reserve_user_name}</strong>已交付票證，用於在<strong>${recipientPlace}</strong>處接收物品。`;
						break;
					case 'jp':
						return `<strong>${this.ticket.reserve_user_name.split(" ").reverse().join("")}</strong>様<strong>${recipientPlace}</strong>で商品を受け取ることができるチケットを渡しました。`;
						break;
					case 'vn':
						return `<strong>${this.ticket.reserve_user_name}</strong> đã giao phiếu nhận hàng tại <strong>${recipientPlace}</strong>.`;
						break;
				}
			}
    },

    deliveryDropPickHeader(){

      const lang = this.$store.state.lang;
			if (this.ticket) {
				switch (lang) {
					case 'ko':
						return `<strong>${this.ticket.store_name}</strong>에서<br />물품을 맡기고 찾을 수 있는 티켓입니다.`;
						break;
					case 'en':
						return `This is a ticket that you can drop in and find at <strong>${this.ticket.store_name}</strong>`;
						break;
					case 'cn':
						return `这是一张票，您可以在<strong>${this.ticket.store_name}</strong>下车并找到。`;
						break;
					case 'hk':
						return `這是一張票，您可以在<strong>${this.ticket.store_name}</strong>下車並找到。`;
						break;
					case 'jp':
						return `<strong>${this.ticket.store_name}</strong>で物品を預けて見つけることができるチケットです。`;
						break;
					case 'vn':
						return `Đây là một vé mà bạn có thể đến và tìm tại <strong>${this.ticket.store_name}</strong>。`;
						break;
				}
			}
    }
	},
	watch: {
		async pass(value) {
			if (value.length === 4) {
				try{
					await this.authShare();

					let options = {
						key: 'status_timer',
						callback: async () => {
							let res = await this.$store.dispatch('tickets/shareAccess', { account: this.$route.query.phone, password: this.pass, code: this.$route.params.code });

							let { data, error} = res.data;

							if(data && !error){
								this.doc = data;

								if(this.doc.status !== 'CHECK_IN'){
									await this.toggleDurationTimer('off')
								}

							}
						},
						interval: 3000
					}

					await this.$store.dispatch('interval/set', options)
				}
				catch(e){}
      }
		},
  },
	methods: {
    async authShare(){
      this.isLoading = true;

      try{
        let res = await this.$store.dispatch('tickets/shareAccess', { account: this.$route.query.phone, password: this.pass, code: this.$route.params.code });

        let { data, error} = res.data;

        if(data && !error){
          setTimeout(async () => {

            $('.page-header').addClass('white');

            this.doc = data;

            if (this.doc.status == 'CHECK_IN') {
              await this.toggleDurationTimer('on');
            }

            this.$store.dispatch('userData/set', { infoAvailableStore: [this.doc.store_id, this.doc.recipient_store_id] });

          }, 500)
        }else{
          this.$store.commit('alert/ADD_ITEM', { message: error, status: 'error' });
					this.pass = '';
					this.$refs.input.focus();
        }
      }
      catch(e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
        this.pass = '';
				this.$refs.input.focus();
				throw new Error(e)
      }finally{
        this.isLoading = false;
      }
    },
		inputFocus() {
			this.$refs.input.focus();
		},
		async toggleDurationTimer(on_off) {
			switch (on_off) {
				case 'on':

					let options = {
						key: 'duration_timer',
						callback: async () => {
							let check_in = this.$moment(this.doc.datetime_check_in);
							let date_start = this.$moment(this.doc.ticket_date_start);
							let now = this.$moment();

							let duration = this.$moment(date_start).isAfter(check_in) ? this.$moment.duration(now.diff(check_in)) : this.$moment.duration(now.diff(date_start));
							this.duration = duration;

							if((duration.minutes() % 10 === 0 ), (duration.seconds() === 1)){
								await this.getPrice();
							}

							this.timeLeft = `${Math.floor(duration.asDays())}/${duration.hours()}/${duration.minutes()}/${duration.seconds()}`;
						},
						interval: 1000
					}

					await this.$store.dispatch('interval/set', options)

          await this.getPrice();

        break;

				case 'off':
          await this.$store.dispatch('interval/unset', { key : 'duration_timer' } )
        break;
			}
		},

		async getPrice() {
			let payload = {
				id: this.doc.store_id,
				query: {
					start_date: this.$moment(this.doc.datetime_check_in).format('YYYY-MM-DD HH:mm:ss'),
					end_date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
					small_quantity: this.doc.ticket_quantity.toString(),
					big_quantity: this.doc.ticket_over_quantity.toString(),
					promotion_id: this.doc.order.promotion && this.doc.order.promotion.promotion_id ? this.doc.order.promotion.promotion_id : '',
				},
      };

      try{
        let res = await this.$store.dispatch('stores/predictPrice', payload);

        let { data, error } = res.data;

        if( data && !error ) {
          this.price = data;
        }
      }
      catch(e){}
		},

		openModal(ref) {
			this.$refs[ref].open(ref);
		},
		closeModal(ref) {
			this.$refs[ref].close();
		},

		goToStore() {
			let store_id;

			if(this.isBeforeStatusThan('CHECK_IN')){
				store_id = this.doc.store_id
			}else{
				store_id = this.doc.recipient_store_id
			}

			this.$router.push({ name: 'StoreSingle', params: { store_id: store_id } });
		},

		onBack(e) {
			if (e.target.location.hash != 'detail') {
				this.doc = null;
				$('.page-header').removeClass('white');
			}
		},

		async getTicketData(code) {
			let data = await this.$store.dispatch('tickets/getByShare', { code: code });
			this.ticket = data;
		},

		async checkout(){
			this.$data.isLoading = true;

			const payload = {
				code: this.doc.ticket_code,
				data: {
					account: this.$route.query.phone, 
					password: this.pass
				}
			}

			try{
				let res = await this.$store.dispatch('tickets/checkoutbyShare', payload);
				this.$store.commit('alert/ADD_ITEM',{message: this.$__t('{share-ticket}.alert.message.checkout.success'), status: 'success'})
			}
			catch(e){
				this.$store.commit('alert/ADD_ITEM',{message: this.$__t(e), status: 'error'})
			}finally{
				this.$data.isLoading = false;
			}
		},

		async checkoutSuccess() {
			try {
				await this.authShare();
			} catch (e) {
			} finally {
				this.closeModal('checkoutModal');
			}
		},

		isBeforeStatusThan(targetStatus) {
      const issue_type = this.doc ? this.doc.issue_type : this.ticket.issue_type;
      const currentStatus = this.doc ? this.doc.status : this.ticket.status;

      let ticketStatusIndex, targetStatusIndex;

      if (issue_type.includes("DELIVERY")) {
        ticketStatusIndex = this.$data.deliveryStatusList.indexOf(currentStatus);
        targetStatusIndex = this.$data.deliveryStatusList.indexOf(targetStatus);
      } else if (issue_type.includes("RESERVE")) {
        ticketStatusIndex = this.$data.luggageStatusList.indexOf(currentStatus);
        targetStatusIndex = this.$data.luggageStatusList.indexOf(targetStatus);
      }

      return ticketStatusIndex < targetStatusIndex;
    },
	},
};
</script>
