<template>
  <div class="resource luggage-action">
    <div class="title">{{ $__t('원하는 상점에') }}<br />{{ $__t('안전히 맡기세요') }}</div>
    <router-link :to="{ name: 'Stores', query: { zoom: 17 } }">
      <button class="store-search-btn">
        <img src="/img/icon/search-lens-white.svg" alt="" class="lens-img" />
        <span class="store-search-btn__text">{{ $__t('보관하러 가기') }}</span>
      </button>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.resource.luggage-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 40px 40px;
  padding: unit(32) 0;

  .title {
    text-align: center;
    color: #101010;
    font-size: unit(24);
    font-weight: 600;
    margin-top: unit(20);
    line-height: 1.5;
    margin-bottom: unit(20);
    margin-top: auto;
  }
  a {
    margin-bottom: auto;
  }
  .store-search-btn {
    background: linear-gradient(92.27deg, #4d4dff -72.85%, #48d9eb 110.49%);
    width: unit(170);
    height: unit(60);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: unit(3);
    .lens-img {
      display: inline-block;
    }
    &__text {
      color: $color-white;
      font-size: unit(16);
      font-weight: 600;
    }
  }

  .action-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: unit(16);
  }

  .action-item {
    flex: 1;

    ~ .action-item {
      margin: unit(10) 0;
    }

    .btn-link {
      width: 100%;
      border-radius: unit(20);
      background-color: rgba(72, 217, 235, 0.16);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: unit(8) unit(16);

      > span {
        text-align: center;
        margin-left: unit(8);
      }
    }
  }
}
</style>
