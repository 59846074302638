<template>
  <div class="ui-qrcode" :class="{ downloadable: options && options.download }">
    <canvas ref="qrcode"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode';
const EVENTS = {
  START: ['mousedown', 'touchstart'],
  END: ['mouseup', 'touchend'],
};

export default {
  props: ['code', 'options'],

  data() {
    return {
      tag: 'qr_code',
      resource_url: '',
    };
  },

  computed: {
    canvas() {
      return this.$refs.qrcode;
    },
  },

  mounted() {
    this.$watch(
      'code',
      (a, b) => {
        QRCode.toCanvas(
          this.canvas,
          this.code,
          {
            margin: 0,
            width: 768,
          },
          (err) => {
            if (err) this.$log.log(err);
          }
        );
      },
      {
        immediate: true,
      }
    );

    if (this.options?.download) {
      this.addDownloadListener();
    }
  },

  beforeDestroy() {
    if (this._downloadLink) {
      this._downloadLink.remove();
      this._downloadLink = null;
    }
  },

  methods: {
    addDownloadListener() {
      this.canvas.addEventListener(EVENTS.START[this.$store.state.config.isTouchDevice ? 1 : 0], (e) => {
        if (!this.code) return;
        this.canvas.addEventListener(EVENTS.END[this.$store.state.config.isTouchDevice ? 1 : 0], (e) => {
          this._pressTimer = null;
          clearTimeout(this._pressTimer);
        });

        this._pressTimer = setTimeout(this.downloadHandler, 500);
      });
    },

    async uploadImage(dataUrl) {
      const file = this.convertDataToFile(dataUrl);
      const resourceFormData = new FormData();
      resourceFormData.append('key', this.$store.state.auth.resource_key);
      resourceFormData.append('tag', this.tag);
      resourceFormData.append('file', file);

      let response = await this.axios.post(this.$store.state.config.apiURL + '/v2/resources/upload', resourceFormData, {
        headers: this.$store.state.config.userHeaders,
      });

      this.resource_url = response.data?.data?.resources[0]?.resource_url;
    },

    async downloadHandler() {
      const dataUrl = this.canvas.toDataURL(this.mimeType, 1.0);

      if (this.wni.isNative) {
        await this.uploadImage(dataUrl);
        return this.nativeDownload();
      }

      this._downloadLink = document.createElement('a');
      this._downloadLink.download = dataUrl;
      this._downloadLink.href = dataUrl;
      this._downloadLink.click();
    },

    nativeDownload() {
      this.wni.execute('wnOpenURL', {
        url: this.$store.state.config.siteURL + '/image-download?resource_url=' + this.resource_url,
      });
    },

    convertDataToFile(dataUrl) {
      const blob = this.$utils.dataURItoBlob(dataUrl);
      let imageFile;
      try {
        imageFile = new File([blob], `${this.code}_${+new Date()}`, { type: 'image/jpeg' });
      } catch (e) {
        if (typeof Object.assign != 'function') {
          this.objectAssignPolyfill();
        }
        imageFile = Object.assign(blob, `${this.code}_${+new Date()}`, { type: 'image/jpeg' });
      }

      return imageFile;
    },

    objectAssignPolyfill() {
      Object.assign = function (target) {
        'use strict';
        if (target == null) {
          throw new TypeError('Cannot convert undefined or null to object');
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
          var source = arguments[index];
          if (source != null) {
            for (var key in source) {
              if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
              }
            }
          }
        }
        return target;
      };
    },
  },
};
</script>
