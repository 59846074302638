<template>
  <div class="map-wrapper">
    <GmapMap
      ref="map"
      :center="{
        lat: 37.5729503,
        lng: 126.97935789999997,
      }"
      :zoom="zoom"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        rotateControl: false,
        scrollwheel: true,
        fullscreenControl: false,
        autobindAllEvents: false,
        disableDefaultUi: true,
        minZoom: 10,
        maxZoom: 20,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      }"
      style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
      @drag="onDrag"
      @click="onClick"
      @tilesloaded="onTilesLoaded"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
      @center_changed="onCenterChanged"
    >
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  props: {
    locations: {
      type: Array,
      default: () => [],
    },

    activatedLocation: {
      type: Object,
      default: () => {},
    },

    zoom: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      markers: [],
      activatedMarker: null,
      mapInstance: null,
    };
  },

  watch: {
    locations(newLocations = [], oldLocations = []) {
      const removableLocations = oldLocations.filter(
        (_old) => !newLocations.find((_new) => _new.id === _old.id)
      );

      removableLocations.forEach((location) => {
        const markerIndex = this.markers.findIndex((marker) => marker.id === location.id);

        if (markerIndex > -1) {
          this.unsetMarker(this.markers[markerIndex]);
          this.markers.splice(markerIndex, 1);
        }
      });

      newLocations.forEach((location) => {
        const markerExists = !!this.markers.find((marker) => marker.id === location.id);

        if (!markerExists) {
          return this.createMarker(location)
            .then((marker) => {
              this.setMarkerIcon(marker, location);
              this.$emit("drawn");
            })
            .catch((e) => console.error(e));
        }

        this.$emit("drawn");
      });
    },

    activatedLocation(newLocation, oldLocation = {}) {
      const oldActivatedMarker = this.markers.find(
        (marker) => marker?.id === oldLocation?.id
      );
      const newActivatedMarker = this.markers.find(
        (marker) => marker?.id === newLocation?.id
      );
      this.activatedMarker = newActivatedMarker;

      setTimeout(() => {
        if (oldActivatedMarker) {
          this.setMarkerIcon(oldActivatedMarker, oldLocation);
        }

        if (newActivatedMarker) {
          this.setMarkerIcon(newActivatedMarker, newLocation);
        }
      }, 0);
    },
  },

  computed: {
    mapResources() {
      return this.$store.state.resourceMap;
    },

    google: gmapApi,
  },

  mounted() {
    this.loadMap().then((mapInstance) => {
      this.mapInstance = mapInstance;

      setTimeout(() => {
        this.$emit("map-load");
        this.mapInstance.addListener("zoom_changed", this.onZoomChanged);
      }, 500);
    });
  },
  //sql-map

  methods: {
    loadMap() {
      return new Promise((resolve, reject) => {
        this.$refs.map.$mapPromise
          .then((instance) => {
            this.$store.commit("resourceMap/SET_MAP", {
              data: {
                map: instance,
              },
            });

            resolve(instance);
          })
          .catch(reject);
      });
    },

    onTilesLoaded() {
      this.$emit("tilesloaded");
    },

    onDragStart() {
      this.$emit("dragstart");
    },

    onDrag() {
      this.$emit("drag");
    },

    onDragEnd() {
      this.$emit("dragend");
    },

    onIdle() {
      this.$emit("idle");
    },

    onZoomChanged(zoom) {
      this.$store.commit("resourceMap/SET_ZOOM", {
        data: {
          zoom,
        },
      });

      this.$emit("zoom_changed");
    },

    onCenterChanged(e) {
      this.$emit("center_changed", e);
    },

    onClick() {
      this.$emit("click");
    },

    onClickMarker(marker) {
      this.$emit("marker-click", marker);
      this.mapInstance.setCenter(marker.position);
    },

    onDragEnd(e) {
      this.$emit("dragend", e);
    },

    onIdle(e) {
      this.$emit("idle", e);
    },

    onCenterChange(e) {
      var center = this.mapInstance.getCenter();

      this.$store.commit("resourceMap/SET_CENTER", {
        data: {
          center: {
            lat: center.lat(),
            lng: center.lng(),
          },
        },
      });

      this.$emit("center_changed", e);
    },

    createMarker(location) {
      return new Promise((resolve, reject) => {
        const { id, geo_latitude, geo_longitude, store_type, free_pass, bookable } =
          location || {};
        const position = new this.google.maps.LatLng(geo_latitude, geo_longitude);

        let options = {
          position,
          map: this.mapInstance,
          id,
          zIndex: this.markerZIndex({ bookable, marker: { id } }),
        };

        const marker = new this.google.maps.Marker(options);

        marker.addListener("click", (event) => {
          this.$emit("marker-click", location);
          this.mapInstance.setCenter(position);
        });

        this.markers.push(marker);

        resolve(marker);
      });
    },

    unsetMarker(marker) {
      marker.setMap(null);
    },

    setMarkerIcon(marker, location = {}) {
      marker.setIcon({
        url: this.markerUrl({ ...location, marker }),
        scaledSize: this.markerScale(marker),
        labelOrigin: new this.google.maps.Point(18, 3),
      });

      marker.setZIndex(this.markerZIndex({ ...location, marker }));
    },

    markerUrl({ bookable = false, store_type = "STORE", marker, store_name }) {
      const targetPath = (target) => `/img/marker/${target}.svg`;
      const junction = "-";
      const vendor = store_name.indexOf("코레일") > -1 ? "storyway" : "default";
      const space_type = store_type === "LOCKER" ? "locker" : "space";
      const available = bookable ? "available" : "";
      const activated = this.activatedMarker?.id === marker.id ? "activated" : "";
      const target = [vendor, space_type, available, activated]
        .map((o, i) => {
          if (i === 0) return o;
          if (o) return junction + o;
        })
        .join("");

      return targetPath(target);
    },

    markerZIndex({ bookable = false, marker }) {
      return marker.id === this.activatedMarker?.id ? 3 : bookable ? 2 : 1;
    },

    markerScale(marker = {}) {
      if (this.activatedMarker && this.activatedMarker.id === marker.id) {
        return new this.google.maps.Size(54, 62);
      }

      return new this.google.maps.Size(32, 38);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .map-canvas {
    height: 100%;
  }
}
</style>
