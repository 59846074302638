<template>
  <div class="locker-size-info">
    <h1 class="locker-size-info__header">라커 크기 정보</h1>
    <ul class="locker-size-info__tab-list">
      <li class="locker-size-info__tab-item" :class="{ on: activatedTab === 'S' }" @click="activatedTab = 'S'">
        <span class="text"> S 사이즈 </span>
      </li>
      <li class="locker-size-info__tab-item" :class="{ on: activatedTab === 'M' }" @click="activatedTab = 'M'">
        <span class="text"> M 사이즈 </span>
      </li>
      <li class="locker-size-info__tab-item" :class="{ on: activatedTab === 'L' }" @click="activatedTab = 'L'">
        <span class="text"> L 사이즈 </span>
      </li>
    </ul>
    <div class="locker-size-info__frame">
      <object class="locker-size-info__image" :data="lockerSizeInfoImage" />
    </div>
    <div class="locker-size-info__list">
      <div v-show="activatedTab === 'S'">
        <h4 class="item-title">S 사이즈</h4>
        <p class="item-description">50cm(가로) x 24cm(세로) x 54cm(깊이)</p>
        <p class="item-example">예) 책가방, 노트북 등</p>
      </div>
      <div v-show="activatedTab === 'M'">
        <h4 class="item-title">M 사이즈</h4>
        <p class="item-description">50cm(가로) x 45cm(세로) x 54cm(깊이)</p>
        <p class="item-example">예) 20인치 기내용 캐리어 등</p>
      </div>
      <div v-show="activatedTab === 'L'">
        <h4 class="item-title">L 사이즈</h4>
        <p class="item-description">50cm(가로) x 93cm(세로) x 54cm(깊이)</p>
        <p class="item-example">예) 28인치 캐리어 이하 등</p>
      </div>
    </div>
    <div class="locker-size-info__button-area">
      <button type="button" v-button class="close-btn" @click="$emit('close')">
        {{ $__t('닫기') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activatedTab: 'S',
    };
  },

  computed: {
    lockerSizeInfoImage() {
      const lockerSizeInfoImage = {
        S: '/img/locker-size-info-small.svg',
        M: '/img/locker-size-info-medium.svg',
        L: '/img/locker-size-info-large.svg',
      };

      return lockerSizeInfoImage[this.activatedTab];
    },
  },
};
</script>

<style lang="scss" scoped>
.locker-size-info {
  padding: unit(14) 0;

  &__header {
    font-weight: 700;
    font-size: unit(16);
    padding-bottom: unit(30);
    line-height: 1.38;
    text-align: center;
    letter-spacing: -0.006em;
  }

  &__tab-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: unit(8);
  }

  &__tab-item {
    background: $color-white;
    border: 1px solid #ced3d6;
    border-radius: unit(16);
    flex: 1;
    padding: unit(8) unit(20);
    text-align: center;

    .text {
      font-weight: 500;
      font-size: unit(14);
      line-height: 1.43;
      text-align: center;
      letter-spacing: -0.006em;
    }

    &.on {
      background: rgba(0, 143, 255, 0.08);
      border: 1px solid #008fff;
      border-radius: unit(16);
    }
  }

  &__frame {
    margin-top: unit(40);
    padding: 0 unit(44);
    min-height: 340px;
  }

  &__image {
    width: 100%;
    margin: auto;
  }

  &__list {
    padding: unit(16) 0;

    li + li {
      margin-top: unit(32);
    }

    .item-title {
      font-weight: 500;
      font-size: unit(14);
      line-height: 1.43;
      letter-spacing: -0.006em;
    }

    .item-content {
      font-weight: 400;
      font-size: unit(12);
      line-height: 1.5;
      letter-spacing: -0.006em;
      color: #61676c;
      margin-top: unit(6);
    }

    .item-example {
      margin-top: unit(14);
      font-weight: 400;
      font-size: unit(14);
      line-height: 1.43;
      letter-spacing: -0.006em;
    }
  }

  &__button-area {
    margin-top: unit(16);

    .close-btn {
      height: unit(50);
      border: 1px solid #e1e4e6;
      border-radius: unit(16);
    }
  }
}
</style>
