<template>
  <div class="page" id="auth-callback-page">
    
  </div>
</template>

<script>
export default {
  created() {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(
          `${this.$store.state.config.apiURL}/v2/passport/auth/naver/oauth/callback?state=${this.$route.query.state}&code=${this.$route.query.code}`
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.$store.dispatch('auth/setAuth', res.data.data);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((res) => {
          alert(JSON.stringify(res));
        });
    }).finally((res) => {
      const location = opener ? opener.location : window.location;
      location.replace(this.$route.query.redirect || '/');

      if (opener) {
        self.close();
      }
    });
  }
};
</script>
