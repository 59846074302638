<template>
  <div class="ui-schedule-select">
    <div class="label" v-if="label">{{ label }}</div>
    <div class="body">
      <ul v-if="model">
        <li>
          <ui-checkbox v-model="table.SA_MON.open" :label="$__t( 'SA_MON' )" :disabled="this.check"/>
          <ui-select v-model="table.SA_MON.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_MON.open" />
          <ui-select v-model="table.SA_MON.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_MON.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_TUE.open" :label="$__t( 'SA_TUE' )" />
          <ui-select v-model="table.SA_TUE.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_TUE.open" />
          <ui-select v-model="table.SA_TUE.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_TUE.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_WED.open" :label="$__t( 'SA_WED' )" />
          <ui-select v-model="table.SA_WED.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_WED.open" />
          <ui-select v-model="table.SA_WED.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_WED.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_THU.open" :label="$__t( 'SA_THU' )" />
          <ui-select v-model="table.SA_THU.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_THU.open" />
          <ui-select v-model="table.SA_THU.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_THU.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_FRI.open" :label="$__t( 'SA_FRI' )" />
          <ui-select v-model="table.SA_FRI.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_FRI.open" />
          <ui-select v-model="table.SA_FRI.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_FRI.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_SAT.open" :label="$__t( 'SA_SAT' )" />
          <ui-select v-model="table.SA_SAT.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_SAT.open" />
          <ui-select v-model="table.SA_SAT.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_SAT.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_SUN.open" :label="$__t( 'SA_SUN' )" />
          <ui-select v-model="table.SA_SUN.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_SUN.open" />
          <ui-select v-model="table.SA_SUN.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_SUN.open" />
        </li>
        <li>
          <ui-checkbox v-model="table.SA_HOL.open" :label="$__t( 'SA_HOL')" />
          <ui-select v-model="table.SA_HOL.start" :label="$__t( '오픈 시각' )" :items="timesStart" :disabled="!table.SA_HOL.open" />
          <ui-select v-model="table.SA_HOL.end" :label="$__t( '마감 시각' )" :items="timesEnd" :disabled="!table.SA_HOL.open" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: [ "value", "label", "check" ],
  data(){
    var table = {
      "SA_MON" : { open: true, start: "00:00", end: "01:00" },
      "SA_TUE" : { open: true, start: "00:00", end: "01:00" },
      "SA_WED" : { open: true, start: "00:00", end: "01:00" },
      "SA_THU" : { open: true, start: "00:00", end: "01:00" },
      "SA_FRI" : { open: true, start: "00:00", end: "01:00" },
      "SA_SAT" : { open: true, start: "00:00", end: "01:00" },
      "SA_SUN" : { open: true, start: "00:00", end: "01:00" },
      "SA_HOL" : { open: true, start: "00:00", end: "01:00" },
    };
    var days = Object.keys( table );
    var timesStart = [
      { label: "00:00", value: "00:00" },{ label: "01:00", value: "01:00" },{ label: "02:00", value: "02:00" },{ label: "03:00", value: "03:00" },
      { label: "04:00", value: "04:00" },{ label: "05:00", value: "05:00" },{ label: "06:00", value: "06:00" },{ label: "07:00", value: "07:00" },
      { label: "08:00", value: "08:00" },{ label: "09:00", value: "09:00" },{ label: "10:00", value: "10:00" },{ label: "11:00", value: "11:00" },
      { label: "12:00", value: "12:00" },{ label: "13:00", value: "13:00" },{ label: "14:00", value: "14:00" },{ label: "15:00", value: "15:00" },
      { label: "16:00", value: "16:00" },{ label: "17:00", value: "17:00" },{ label: "18:00", value: "18:00" },{ label: "19:00", value: "19:00" },
      { label: "20:00", value: "20:00" },{ label: "21:00", value: "21:00" },{ label: "22:00", value: "22:00" },{ label: "23:00", value: "23:00" },
    ];
    var timesEnd = [
      { label: "01:00", value: "01:00" },{ label: "02:00", value: "02:00" },{ label: "03:00", value: "03:00" },{ label: "04:00", value: "04:00" },
      { label: "05:00", value: "05:00" },{ label: "06:00", value: "06:00" },{ label: "07:00", value: "07:00" },{ label: "08:00", value: "08:00" },
      { label: "09:00", value: "09:00" },{ label: "10:00", value: "10:00" },{ label: "11:00", value: "11:00" },{ label: "12:00", value: "12:00" },
      { label: "13:00", value: "13:00" },{ label: "14:00", value: "14:00" },{ label: "15:00", value: "15:00" },{ label: "16:00", value: "16:00" },
      { label: "17:00", value: "17:00" },{ label: "18:00", value: "18:00" },{ label: "19:00", value: "19:00" },{ label: "20:00", value: "20:00" },
      { label: "21:00", value: "21:00" },{ label: "22:00", value: "22:00" },{ label: "23:00", value: "23:00" },{ label: "24:00", value: "24:00" },
    ];
    return {
      table,
      days,
      timesStart,
      timesEnd,
    }
  },
  computed: {
    model: {
      get(){
        if( this.value ){
          this.days.forEach( o => {
            if( this.value[ o ] ){
              this.table[o].open = true;
              this.table[o].start = this.value[o][0];
              if( parseInt( this.table[o].end ) > 24 ){
                this.table[o].end = ( parseInt( this.table[o].end ) - 24 < 10 ? "0" : "" ) + ( parseInt( this.table[o].end ) - 24 ) + ":00";
              }else{
                this.table[o].end = this.value[o][1];
              }
            }else{
              this.table[o].open = false;
              this.table[o].start = "00:00";
              this.table[o].end = "01:00";
            };
          });
          return this.value;
        }else{
          this.$emit( "input", {
            "SA_MON" : [ "00:00", "01:00" ],
            "SA_TUE" : [ "00:00", "01:00" ],
            "SA_WED" : [ "00:00", "01:00" ],
            "SA_THU" : [ "00:00", "01:00" ],
            "SA_FRI" : [ "00:00", "01:00" ],
            "SA_SAT" : [ "00:00", "01:00" ],
            "SA_SUN" : [ "00:00", "01:00" ],
            "SA_HOL" : [ "00:00", "01:00" ],
          });
        };
      },
      set( value ){
        this.$emit( "input", value );
      }
    }
  },
  created(){
    this.$watch( "table", value => {
      var model = {};
      Object.keys( value ).forEach( o => {
        if( value[o].open ){
          model[o] = [ value[o].start, value[o].end ];
        };
      });
      this.$emit( "input", model );
    }, { immediate: true, deep: true });
  }
};
</script>
