<template>
  <div id="is-host-active-callback">
    <div v-if="loading" class="loading">
      <lottie :options="lottieOptions" :height="200" :width="200"></lottie>
    </div>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/lottie-map-loading.json";

const RESPONSE_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};

export default {
  props: ["id"],
  name: "SetHostStatusCallback",

  data() {
    return {
      activateCheck: null,
      loading: true,

      lottieOptions: {
        animationData: LottieLoading,
      },
    };
  },

  mounted() {
    this.activateCheck = this.$route.query["activateCheck"];

    if (!this.activateCheck) {
      this.$store.commit("alert/ADD_ITEM", {
        message: this.$__t("INSUFFICIENT_PARAMS"),
        status: "error",
      });
      setTimeout(() => {
        this.$router.replace({ name: "Home" });
      }, 1500);
      return;
    }

    this.callback();
  },

  methods: {
    async callback() {
      this.loading = true;
      this.axios
        .put(`${this.$store.state.config.apiURL}/v2/stores/${this.id}`, {
          activated: this.activateCheck,
        })
        .then((res) => {
          if (res.data.status === RESPONSE_STATUS.SUCCESS) {
            setTimeout(() => {
              alert("정상적으로 처리 완료되었습니다. 감사합니다.");
              this.loading = false;
              this.$router.replace({ name: "Home" });
            }, 500);
          } else if (res.data.error === "ALREADY_UPDATED_DATA") {
            setTimeout(() => {
              alert("이미 처리가 완료되었습니다.");
              this.loading = false;
              this.$router.replace({ name: "Home" });
            }, 500);
          } else {
            setTimeout(() => {
              alert("오류가 발생하였습니다. 고객센터로 문의 바랍니다.");
              this.loading = false;
              this.$router.replace({ name: "Home" });
            }, 500);
          }
        });
    },
  },

  components: {
    Lottie,
  },
};
</script>

<style scoped lang="scss">
#is-host-active-callback {
  height: 100vh;
  background: #ffffff;
}

.loading {
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
