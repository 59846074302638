<template>
  <div class="subpage" id="host-notices-single-page">
    <div class="container">

      <div class="page-header">
        <div class="title">{{ doc.post_title }}</div>
        <div class="datetime">{{ doc.created_at | moment( "YYYY-MM-DD" ) }}</div>
      </div>

      <div class="page-body">
        <div class="container" v-html="doc.post_content"></div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: [ "id" ],
  computed: {
    doc(){
      return this.$store.getters[ "resourceNotices/GET_ITEM" ]({ key: "post_id", value: this.id });
    }
  },
  async created(){
    await this.$store.dispatch( "resourceNotices/get", { id: this.id });
    this.handleNoticeViewed();
  },
  methods: {
    getNoticesViewed(){
      let noticesViewed = localStorage.getItem('lugstay_notices_viewed');

      if(!noticesViewed) return [];

      noticesViewed = noticesViewed.split(',')

      return noticesViewed;
    },
    
    handleNoticeViewed(){
      if(!this.doc) return;
      
      let noticesViewed = this.getNoticesViewed();
      
      if(noticesViewed.length > 0 && noticesViewed.indexOf(this.doc.post_id.toString()) > -1) return;
      this.$store.commit('resourceNotices/SET_VIEWED_LIST_ITEM', { list: 'lugstay_notices_viewed', data: this.doc } )
    }
  }
}
</script>
