<template>
  <div class="subpage" id="host-check-page">
    <div
      v-if="loading"
      style="
        z-index: 1700;
        position: fixed;
        font-size: 2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #333333;
      "
    >
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
    <div class="container">
      <div class="section" id="host-check-qrcode-section">
        <div class="container">
          <div class="preview-wrap" :class="{ processing: scannerProcessing }" v-if="scannerMode == 'web'">
            <canvas class="preview-canvas" ref="previewCanvas" :class="{ 'is-camera': scannerIsTouchDevice }"></canvas>
            <video class="preview-video" ref="previewVideo" muted autoplay playsinline></video>
            <div class="preview-guide">
              <div class="guide-item guide-top-left"></div>
              <div class="guide-item guide-top-right"></div>
              <div class="guide-item guide-bottom-right"></div>
              <div class="guide-item guide-bottom-left"></div>
              <p class="guide-text">{{ $__t('여기에 QR코드를 스캔하세요.') }}</p>
            </div>
          </div>

          <div class="preview-alert" v-if="scannerMode == 'native'">
            {{ $__t('QR코드를 스캔해서 처리해보세요') }}

            <button @click="openQRCodeReader">{{ $__t('스캔') }}</button>
          </div>

          <div class="preview-alert" v-if="scannerMode == 'disable'">
            {{ $__t('Either camera access has been prevented by user or lack of browser support for scanner.') }}
            {{ $__t('Put in text code manually down the form below.') }}
            <!--            <button @click="openAppSetting" v-if="wni.isNative">{{ $__t("Setting")}}</button>-->
          </div>
        </div>
      </div>

      <div class="section" id="host-check-text-code-section">
        <div class="container">
          <div class="form">
            <div class="form-header">
              <div class="title">{{ $__t('텍스트 코드로 처리하기') }}</div>
              <div class="description">
                <p>{{ $__t('QR티켓을 스캔할 수 없으신가요? 티켓 QR코드 하단에 있는 텍스트 코드를 직접 입력하여 처리해보세요.') }}</p>
              </div>
            </div>
            <div class="form-body">
              <ui-form @submit="submitForm" :formData="formData" ref="form">
                <ui-form-list>
                  <ui-form-list-item>
                    <ui-textbox
                      :placeholder="formData.code.placeholder"
                      :type="formData.code.type"
                      :label="formData.code.label"
                      :error="formData.code.error"
                      v-model="formData.code.value"
                    />
                  </ui-form-list-item>
                  <ui-form-list-item>
                    <ui-solid-button display="block" color="primary"
                      ><button type="submit">{{ $__t('텍스트 코드로 처리하기') }}</button></ui-solid-button
                    >
                  </ui-form-list-item>
                </ui-form-list>
              </ui-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="slow-slide-down">
      <checkout-dialog :data="DialogItem" v-if="flag"></checkout-dialog>
    </transition>

    <modal id="luggagePhotoUploadModal" ref="luggagePhotoUploadModal" :headerLabel="$__t('Checked in completed!')">
      <template v-slot:body>
        <div class="primary">
          {{ $__t("Take an image of the guest's item(s)!") }}
        </div>
        <div class="secondary">
          {{ $__t('The image itself is a promise of secured storing and can be used for covering accidents.') }}
        </div>
        <div class="card-button">
          <img src="/img/icon-camera.png" class="logo" />
          <div class="text">{{ $__t('Upload image of the item') }}</div>
          <img src="/img/arrow-right.png" srcset="/img/arrow-right@2x.png 2x, /img/arrow-right@3x.png 3x" class="arrow" />
          <luggage-photo-uploader :ticket="ticketForImageUpload" @set-resource="goToTicket(ticketForImageUpload.ticket_code)" />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import jsQR from 'jsqr';
import CheckoutDialog from '@/components/modules/Dialog/CheckoutDialog';
import LuggagePhotoUploader from '@/components/modules/RichForm/LuggagePhotoUploader';

export default {
  data() {
    return {
      scanner: null,
      scannerProcessing: false,
      scannerMode: 'unset',
      scannerCanvas: null,
      scannerCanvasContext: null,
      scannerVideo: null,
      scannerDrawer: null,
      scannerIsTouchDevice: null,
      scannerInitiated: false,
      formData: {
        code: {
          type: 'text',
          label: this.$__t('텍스트 코드'),
          placeholder: this.$__t('QR코드 하단의 텍스트 코드를 입력해주세요'),
          validation: [{ type: 'required' }],
          value: '',
          error: ''
        }
      },
      DialogItem: {},
      flag: false,
      ticketForImageUpload: null,
      loading: false
    };
  },
  components: {
    CheckoutDialog,
    LuggagePhotoUploader
  },
  beforeDestroy() {
    this.stopScanner();
  },
  mounted() {
    if (this.wni.isNative) {
      this.scannerMode = 'native';
    } else {
      this.initScanner();
    }
  },
  deactivated() {
    this.stopScanner();
  },
  activated() {
    if (this.scannerCanvas && this.scannerInitiated) {
      this.initScanner();
    }
  },
  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },
    openQRCodeReader() {
      this.wni.execute('wnPermissionVideoAccess', {
        callback: this.wni.cb((result) => {
          let { status } = result;

          // @TODO: Permission
          // this.wni.execute('wnOpenAppSetting', { type: 'general' });

          if (status.toLowerCase() === 'granted') {
            this.wni.execute('wnQRCodeScan', {
              text: this.$__t('여기에 QR코드를 스캔하세요.'),
              callback: this.wni.cb((result) => {
                if (result) {
                  this.detactedQrcode(result.code);
                }
              })
            });
          } else {
            this.scannerMode = 'disable';
          }
        })
      });
    },
    openAppSetting() {
      alert('openAppSetting()');
      this.wni.execute('wnOpenAppSetting', { type: 'general' });
      alert('openAppSetting() end');
    },

    initScanner() {
      this.$log.log('initScanner');

      try {
        // navigator.permissions.query(PermissionDescriptor).then(function(permissionStatus) { ... })
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'environment' } })
          .then((res) => {
            this.scannerInitiated = true;
            this.scannerMode = 'web';

            setTimeout(() => {
              this.scannerCanvas = this.$refs.previewCanvas;
              this.scannerCanvasContext = this.scannerCanvas.getContext('2d');
              this.scannerVideo = this.$refs.previewVideo;
              this.scannerIsTouchDevice = this.$store.state.config.isTouchDevice;

              this.scannerVideo.srcObject = res;
              this.scannerUsable = true;
              this.startScanner();
            }, 0);
          })
          .catch((err) => {
            this.$log.log(err);
            this.stopScanner();
            this.$store.commit('alert/ADD_ITEM', { message: err.toString(), status: 'error' });
            this.scannerUsable = false;
            this.scannerMode = 'disable';
          });
      } catch (err) {
        this.$log.log(err);
        this.stopScanner();
        this.$store.commit('alert/ADD_ITEM', { message: err.toString(), status: 'error' });
        this.scannerUsable = false;
        this.scannerMode = 'disable';
      }
    },

    startScanner(timing) {
      this.$log.log('startScanner');

      if (this.scannerUsable) {
        if (timing) {
          setTimeout(() => {
            clearInterval(this.scannerDrawer);
            if (this.scannerVideo) {
              this.scannerVideo.play();
            }
            this.scannerDrawer = setInterval(this.captureVideoToCanvas, 1000 / 24);
            this.scannerProcessing = true;
          }, timing);
        } else {
          clearInterval(this.scannerDrawer);
          if (this.scannerVideo) {
            this.scannerVideo.play();
          }
          this.scannerDrawer = setInterval(this.captureVideoToCanvas, 1000 / 24);
          this.scannerProcessing = true;
        }
      }
    },
    pauseScanner(timing) {
      this.$log.log('pauseScanner');

      if (timing) {
        setTimeout(() => {
          clearInterval(this.scannerDrawer);
          if (this.scannerVideo) {
            this.scannerVideo.pause();
          }
          this.scannerProcessing = false;
        }, timing);
      } else {
        clearInterval(this.scannerDrawer);
        if (this.scannerVideo) {
          this.scannerVideo.pause();
        }
        this.scannerProcessing = false;
      }
    },
    stopScanner() {
      try {
        this.scannerVideo.srcObject.getVideoTracks()[0].stop();
        this.scannerVideo.srcObject = null;
        clearInterval(this.scannerDrawer);
      } catch (e) {}
    },
    captureVideoToCanvas() {
      // this.$log.log( 'captureVideoToCanvas' )

      var canvasBound = this.scannerCanvas.getBoundingClientRect();
      this.scannerCanvas.width = canvasBound.width;
      this.scannerCanvas.height = canvasBound.height;
      this.scannerCanvasContext.drawImage(
        this.$refs.previewVideo,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? 0 : this.scannerVideo.videoWidth / 2 - this.scannerVideo.videoHeight / 2,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? this.scannerVideo.videoHeight / 2 - this.scannerVideo.videoWidth / 2 : 0,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? this.scannerVideo.videoWidth : this.scannerVideo.videoHeight,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? this.scannerVideo.videoWidth : this.scannerVideo.videoHeight,
        0,
        0,
        canvasBound.width,
        canvasBound.height
      );
      var imageData = this.scannerCanvasContext.getImageData(0, 0, canvasBound.width, canvasBound.height);
      var code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        if (code.data) {
          this.detactedQrcode(code.data);
        }
      }
    },
    detactedQrcode(code) {
      this.pauseScanner();
      this.formData.code.value = code;
      this.$refs.form.submitForm();
    },
    checkIn(code) {
      this.loading = true;

      setTimeout(() => {
        this.$store
          .dispatch('tickets/managerCheckIn', { code })
          .then((res) => {
            this.$store.commit('alert/ADD_ITEM', { message: this.$__t('체크인되었습니다.'), status: 'success' });
            this.ticketForImageUpload = res.data.data;
            this.openModal('luggagePhotoUploadModal');
            this.$store.commit('tickets/SET_ITEM', { data: res.data.data });
          })
          .catch((err) => {
            this.$store.commit('alert/ADD_ITEM', { message: err, status: 'error' });
          })
          .finally(() => {
            this.loading = false;
          });
      }, 1000);
    },
    checkOut(code, data) {
      if (data.issue_type == 'PAID') {
        this.$store
          .dispatch('tickets/checkOut', { code })
          .then((res) => {
            this.$store.commit('tickets/SET_ITEM', { data: res.data.data });
            this.$store.commit('alert/ADD_ITEM', { message: this.$__t('체크아웃되었습니다.'), status: 'success' });
          })
          .catch((err) => {
            this.$store.commit('alert/ADD_ITEM', { message: res, status: 'error' });
          });
      } else if (data.issue_type === 'RESERVE' || data.issue_type === 'PAY_LATER') {
        this.$store.dispatch('tickets/checkoutAfter', { code: code, checkout_time: checkoutTime }).then((res) => {
          this.$store.commit('tickets/SET_ITEM', { data: res.data.data });
          this.$store.commit('alert/ADD_ITEM', { message: this.$__t('체크아웃되었습니다.'), status: 'success' });
        });
      }
    },
    goToTicket(code) {
      this.closeModal('luggagePhotoUploadModal');
      this.$router.push({ name: 'HostTicketSingle', params: { code: code }, query: {} });
    },
    submitForm(values) {
      this.$store
        .dispatch('tickets/getByCode', { code: values.code.toUpperCase() })
        .then((res) => {
          let ticket = res.data.data;

          if (ticket.status == 'ISSUED') {
            this.checkIn(values.code.toUpperCase());
          } else if (ticket.status == 'CHECK_IN') {
            this.flag = true;
            $('#ch-plugin-launcher').addClass('hidden');
            this.DialogItem.ticket = this.$store.getters['tickets/GET_ITEMS']({ key: 'ticket_code', value: values.code.toUpperCase() })[0];
            this.DialogItem.message =
              ticket.reserve.user_last_name +
              ' ' +
              ticket.reserve.user_first_name +
              ' ' +
              this.$__t('님이 예약하신 티켓입니다.') +
              ' ' +
              this.$__t('체크아웃 하시겠습니까?');
            this.DialogItem.message =
              (ticket.reserve.user_name || ticket.reserve.user_last_name + ' ' + ticket.reserve.user_first_name) +
              ' ' +
              this.$__t('님이 예약하신 티켓입니다.') +
              ' ' +
              this.$__t((ticket.status == 'CHECK_IN' ? '체크아웃' : '체크인') + '하시겠습니까?');
            this.DialogItem.true = {
              label: this.$__t('예'),
              callback: async (res) => {
                this.$store.commit('loading/SET_TRUE');

                await this.checkOut(values.code.toUpperCase(), ticket);
                setTimeout(() => {
                  this.$store.commit('loading/SET_FALSE');
                }, 1000);

                this.flag = false;
                $('#ch-plugin-launcher').removeClass('hidden');
              }
            };
            this.DialogItem.false = {
              label: this.$__t('아니오'),
              callback: () => {
                this.flag = false;
                $('#ch-plugin-launcher').removeClass('hidden');
              }
            };
          } else if (ticket.status == 'CANCELLED') {
            this.$store.commit('alert/ADD_ITEM', { message: this.$__t('취소된 티켓입니다.'), status: 'error' });
          } else if (ticket.status == 'EXPIRED') {
            this.$store.commit('alert/ADD_ITEM', { message: this.$__t('Expired Ticket'), status: 'error' });
          } else {
            this.$store.commit('alert/ADD_ITEM', { message: this.$__t('이미 처리된 티켓입니다.'), status: 'error' });
          }
        })
        .catch((err) => {
          this.$log.error(err);
          this.$store.commit('alert/ADD_ITEM', { message: this.$__t(err), status: 'error' });
        })
        .finally(() => {
          this.startScanner(2000);
        });
    }
  }
};
</script>
