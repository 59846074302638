<template>
  <li class="ui-card-selector-item">
    <div
      class="container"
      :style="item ? vendorStyle : {}"
      :class="{ empty: !item }"
      @click.prevent="item ? '' : onClickAddCard()"
    >
      <template v-if="item">
        <div class="label">
          {{ parseCardName }}
        </div>
        <div class="value" v-if="parseCardName !== '카카오페이'">
          **** **** **** {{ item.card_number.substring(item.card_number.length - 4) }}
        </div>
      </template>
      <template v-else>
        <div class="primary">
          <img src="/img/icon-add.svg" />
          <span class="text">{{ $__t("결제수단 등록") }}</span>
        </div>
      </template>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      vendor: {
        kookmin: {
          title: "국민카드",
          reg: ["kb", "국민"],
          backgroundColor: "#FCAF17",
          borderColor: "#FFDF92",
        },
        shinhan: {
          title: "신한카드",
          reg: ["shinhan", "신한"],
          backgroundColor: "#0B1966",
          borderColor: "#6F7ECD",
        },
        hyundai: {
          title: "현대카드",
          reg: ["hyundai", "현대"],
          backgroundColor: "#000000",
          borderColor: "#969696",
        },
        lotte: {
          title: "롯데카드",
          reg: ["lotte", "롯데"],
          backgroundColor: "#DA291C",
          borderColor: "#FFAEA8",
        },
        hana: {
          title: "하나카드",
          reg: ["hana", "하나"],
          backgroundColor: "#008C8C",
          borderColor: "#91EBEB",
        },
        samsung: {
          title: "삼성카드",
          reg: ["samsung", "삼성"],
          backgroundColor: "#0C4DA2",
          borderColor: "#B8D3F7",
        },
        bc: {
          title: "BC카드",
          reg: ["bc", "비씨"],
          backgroundColor: "#FA3246",
          borderColor: "#FFBEC5",
        },
        woori: {
          title: "우리카드",
          reg: ["woori", "우리"],
          backgroundColor: "#20C4F4",
          borderColor: "#B0EDFF",
        },
      },
    };
  },

  computed: {
    parseCardName() {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(this.item.card_name);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return this.item.card_name;
    },

    vendorStyle() {
      let styleObject = {
        backgroundColor: "#008fff",
        borderColor: "#008fff",
      };

      if (this.item?.card_name) {
        let key = Object.keys(this.vendor).find((o) =>
          this.vendor[o].reg.some(
            (r) => this.item.card_name.toLowerCase().indexOf(r) > -1
          )
        );

        styleObject.backgroundColor = this.vendor[key]?.backgroundColor;
        styleObject.borderColor = this.vendor[key]?.borderColor;
      }

      return styleObject;
    },
  },

  methods: {
    onClickAddCard() {
      const IS_RESERVE_PAGE = this.$route.fullPath.includes("reserve");
      const IS_BILLING_PAGE = this.$route.fullPath.includes("billing");
      const IS_PURCHASE_PAGE = this.$route.fullPath.includes("purchase");

      // @TODO : ReserveAddPayMethod와 MoreBillingAddPayMethod가 같은것같은데?
      if (IS_RESERVE_PAGE) {
        // return this.$router.push({ name: 'ReserveAddPayMethod' });
        return this.$router.push({ name: "SelectPaymentForAdd" });
      }

      if (IS_BILLING_PAGE) {
        return this.$router.push({ name: "MoreBillingAddPayMethod" });
      }

      if (IS_PURCHASE_PAGE) {
        return this.$router.push({ name: "PurchaseAddPayMethod" });
      }
    },
  },
};
</script>
