<template>
	<div id="reservation-form-step">
		<div class="container">
			<div class="under-construction" v-if="store && store.status == 'READY'">
				<p>{{ $__t('이 상점은 현재 서비스 준비 중입니다.') }}</p>
			</div>
			<template v-if="store && store.status == 'PUBLIC'">
				<div class="need-login" v-if="!$store.state.auth.gid">
					<p>{{ $__t('You need to login to make a reservation.') }}</p>
					<ui-solid-button size="small"
						><router-link :to="{ name: 'AuthLogin', query: { redirect: $router.currentRoute.fullPath } }">{{ $__t('login') }}</router-link></ui-solid-button
					>
				</div>
				<div class="payment-step">
          <img src="/img/payment-step-indicator-1.png" />
        </div>
				<div class="form" v-if="$store.state.auth.gid">
					<div class="form-section reserve-section delivery-section">
						<div class="form-section-title">{{ $__t('예약 내용') }}</div>
						<div class="form-section-body">
							<ul>
								<li class="store-card">
									<div class="image" :style="'background-image:url(' + (store.resources.thumbnail_image ? store.resources.thumbnail_image.resource_url : '') + ')'">
										<img v-if="this.store.extra && this.store.extra.time_24hour" src="/img/icon-24h-orange.png" style="width:24px;height:24px;left:5px;" />
									</div>
									<div class="store-contents">
										<div class="category">
											<span>{{ $__t(store.store_category) }}</span>
										</div>
										<div class="store-name">
											<span>{{ store.store_name }}</span>
										</div>
									</div>
								</li>
								<li class="item">
									<div class="opener" v-ga="$analytics.tracking.bind(this, 'booking_1', 'click', 'booking_1_check_in')">
										<span class="key">{{ $__t('맡기는 시각') }}</span>
										<span class="value" :class="{ error: dateStartError, on: this.formData.date_start }">
											<VueCtkDateTimePicker
												id="searchStartDate"
												locale="en"
												v-model="formData.date_start"
												:min-date="$moment().format('YYYY-MM-DD HH:mm')"
												format="YYYY-MM-DD HH:mm"
												formatted="YYYY-MM-DD HH:mm"
												label="YYYY.MM.DD"
												:no-label="true"
												:no-shortcuts="true"
												:no-button-now="true"
												:disabled-dates="disabledDatesOnOrigin"
												:disabled-hours="disabledHoursOfStartDate"
												:disabled-minutes="disabledMinutesOfStartDate"
												minuteInterval="10"
												timeItemSize="lg"
											>
												<input type="text" :value="$moment(formData.date_start).format('YYYY-MM-DD HH:mm')" ref="inputStartDate" id="input-start-date" placeholder="YYYY.MM.DD" readonly />
											</VueCtkDateTimePicker>
											<i class="fa fa-angle-down" @click.stop="$refs.inputStartDate.click()"></i>
										</span>
										<div class="error" v-if="dateStartError" @click.stop="$refs.inputStartDate.click()">{{ dateStartError }}</div>
									</div>
								</li>
								<li class="item">
									<div class="opener" v-ga="$analytics.tracking.bind(this, 'booking_1', 'click', 'booking_1_check_out')">
										<span class="key">{{ $__t('찾는 시각') }}</span>
										<span class="value" :class="{ error: dateEndError, on: this.formData.date_start }">
											<VueCtkDateTimePicker
												id="searchStartDate"
												locale="en"
												v-model="formData.date_end"
												:min-date="
													$moment(formData.date_start)
														.add(10, 'minutes')
														.format('YYYY-MM-DD HH:mm')
												"
                        :max-date="$moment(formData.date_start.value).add(2, 'months').format('YYYY-MM-DD HH:mm')"
												format="YYYY-MM-DD HH:mm"
												formatted="YYYY-MM-DD HH:mm"
												label="YYYY-MM-DD HH:mm"
												:no-label="true"
												:no-shortcuts="true"
												:no-button-now="true"
												:disabled-dates="disabledDatesOnDestination"
												:disabled-hours="disabledHoursOfEndDate"
												:disabled-minutes="disabledMinutesOfEndDate"
												minuteInterval="10"
												timeItemSize="lg"
											>
												<input type="text" :value="$moment(formData.date_end).format('YYYY-MM-DD HH:mm')" ref="inputEndDate" id="input-end-date" placeholder="YYYY.MM.DD" readonly />
											</VueCtkDateTimePicker>
											<i class="fa fa-angle-down" @click.stop="$refs.inputEndDate.click()"></i>
										</span>
										<div class="error" v-if="dateEndError" @click.stop="$refs.inputEndDate.click()">{{ dateEndError }}</div>
									</div>
								</li>
								<li class="item">
									<div class="opener" @click.stop="openLuggageSelector">
										<span class="key">{{ $__t("No. of items") }}</span>
										<span class="value2" style="cursor:pointer;">
											<span class="luggageCount">
												<img v-if="formData.quantitySmall > 0 && formData.quantityBig == 0" src="/img/luggage-small@3x.png" class="luggage-icon">
												<img v-else-if="formData.quantitySmall == 0 && formData.quantityBig > 0" src="/img/luggage-big.png" class="luggage-icon">
												<img v-else src="/img/luggage-both.png" class="luggage-icon" />
												<span v-text="formData.quantitySmall + formData.quantityBig" />
												{{ formData.quantitySmall + formData.quantityBig < 2 ? $__t('item') : $__t('items') }}
											</span>
											<i class="fa fa-angle-down"></i>
										</span>
										<div class="error" v-if="quantityError" style="padding-right: 0;margin-top: 5px;">{{ quantityError }}</div>
									</div>
								</li>
                <li class="item">
									<div class="opener">
										<div class="key">{{ $__t("Types of Items") }}</div>
										<div>
                      <ui-textbox :placeholder="formData.itemType.placeholder" v-model="formData.itemType.value" type='text' size='medium' maxLength="15" :error="formData.itemType.error" :label="formData.itemType.label"/>
										</div>
									</div>
								</li>
                <li class="item">
                  <div class="alert">
                    <ul>
                      <li class="header">[{{ $__t('Notice') }}]</li>
                      <li>•{{ $__t('{delivery-step1}.notice.notice.1') }} {{ $__t('Please be sure to check the opening hours.') }}</li>
                      <li class="body">{{ $__t('{delivery-step1}.notice.notice.1-1') }}</li>
                    </ul>
										<ul>
											<li>{{ $__t('{delivery-step1}.notice.notice.2') }}</li>
										</ul>
										<ul>
											<li>* {{ $__t('{delivery-step1}.keyword.restricted') }}</li>
											<li class="body">1. {{ $__t('{delivery-step1}.notice.notice.3-1') }}</li>
											<li class="body">2. {{ $__t('{delivery-step1}.notice.notice.3-2') }}</li>
										</ul>
										<ul>
											<li class="header">{{ $__t('[Cancellation & Refund]') }}</li>
											<li>* {{ $__t('{delivery-step1}.notice.notice.4-1') }}</li>
											<li>* {{ $__t('Cancellation is available any time before the booked time.') }}</li>
											<li class="body">{{ $__t('Refund time for cancellation is as follows.') }}</li>
											<li class="body">{{ $__t('If canceled on the date of reservation: instant refund.') }}</li>
											<li class="body">{{ $__t('If canceled after the date of reservation: refund after 3-5 business days.') }}</li>
										</ul>
										<ul>
											<li>* {{ $__t('If you have any questions, please contact the messenger at the bottom right.') }}</li>
										</ul>
                  </div>
                </li>
							</ul>
						</div>
					</div>
          <div class="delivery-form-action submit-section">
            <ui-solid-button color="brightblue" display="block" @click="submitForm" :disabled="isValidJourney ? false : true">
              <button type="button">{{ $__t('Next') }}</button>
            </ui-solid-button>
          </div>
				</div>
			</template>
		</div>
    <guide-modal id="priceGuideModal" ref="priceGuideModal" :headerTitle="$__t('Price Details')">
			<template v-slot:body>
				<price-guide/>
			</template>
		</guide-modal>
		<transition name="slide-down">
			<luggage-selector ref="luggageSelector" @onSelected="handleLuggage" :counts="{ quantity_small: formData.quantitySmall, quantity_big: formData.quantityBig }" />
		</transition>
	</div>
</template>

<script>
import config from '@/config';
import countryCodes from '@/assets/json/country-codes.json';
import CouponApplier from '@/components/modules/RichForm/CouponApplier';
import CouponSelector from '../modules/RichForm/CouponSelector';
import LuggageSelector from '@/components/pages/luggage/LuggageSelector';
import PriceGuide from '@/components/pages/_/PriceGuide';

export default {
	inheritAttrs: false,
	name: 'DeliveryFormStep1',
	components: {
		CouponApplier,
		CouponSelector,
    LuggageSelector,
    PriceGuide
	},
	props: ['store'],
	data() {
    let userData = this.userData();

		return {
			state: {},
			isShowCouponSelector: false,
			selectedCoupon: null,
			countryCodes: countryCodes,
			schedules: null,
			disabledSchedules: [],
			disabledDates: [],
			disabledDateHours: [],
			formData: {
				searchText: userData.search_text,
				itemType:{
					type: 'text',
					label: this.$__t('{delivery-step1}.itemType.label'),
					placeholder: this.$__t('{delivery-step1}.itemType.placeholder'),
					value: userData.item_type,
					error: ''
				},
				quantitySmall: userData.quantity_small,
				quantityBig: userData.quantity_big,
				date_start: this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm'),
				date_end: this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm'),
				coupon_code: {
					value: '',
				}
			},
			couponData: false,
			calendarLoading: false,
			quantityFilterOpened: false,
			dateStartFilterOpened: false,
			dateEndFilterOpened: false,
			couponFilterOpened: true,

			dateStartError: null,
			dateEndError: null,
			quantityError: null,
			couponError: null,
			discountError: null,

			availableTimes: [],
			availableDates: [],
			paymentData: {},
			couponAvailableCount: 0,

			totalOrderAmount: 0,
			couponAmount: 0,
			discountAmount: 0,
			predictedPrice: 0,
		};
	},
	mounted() {
    let userData = this.userData();

		this.formData.date_start = this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm');
		this.formData.date_end = this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm');
		this.formData.quantitySmall = userData.quantity_small;
		this.formData.quantityBig = userData.quantity_big;
		this.formData.itemType.value = userData.item_type;    

    if (window.location.hash == '#luggage') {
			this.$refs.luggageSelector.opened = true;
		}
  },
	watch: {
		$route(to, form) {
      let userData = this.userData();

			this.formData.date_start = this.$moment(userData.date_start).format('YYYY-MM-DD HH:mm');
			this.formData.date_end = this.$moment(userData.date_end).format('YYYY-MM-DD HH:mm');
			this.formData.quantitySmall = userData.quantity_small;
			this.formData.quantityBig = userData.quantity_big;
		},
		formData: {
			handler: _.debounce(function() {
				let date_start = this.$moment(this.formData.date_start);
				let date_end = this.$moment(this.formData.date_end);
				if (date_start.isSameOrAfter(date_end)) {
					let date_end = this.$moment(date_start);
					for (let i = 1; i < 6 * 24 * 7; i++) {
						//7주일 이내에 가장 가까운 영업시간을 찾는다.
						date_end = date_end.add(10, 'minutes');
						if (this.disabledSchedules.indexOf(date_end.format('YYYY-MM-DD HH:mm')) > -1) continue;
						break;
					}
					this.formData.date_end = date_end.format('YYYY-MM-DD HH:mm:ss');
				}

				if (this.couponData) {
					payload.query.promotion_id = this.couponData.promotion_id;
				}

				if (this.selectedCoupon) {
					payload.query.promotion_id = this.selectedCoupon.promotion_id;
				}
				
				this.onChangeFormData();
			}, 100),
			deep: true
		},
		selectedCoupon: {
			handler: _.debounce(function() {
				let payload = {
					id: this.store.id,
					query: {
						start_date: this.$moment(this.formData.date_start).format('YYYY-MM-DD HH:mm:ss'),
						end_date: this.$moment(this.formData.date_end).format('YYYY-MM-DD HH:mm:ss'),
						small_quantity: this.formData.quantitySmall.toString(),
						big_quantity: this.formData.quantityBig.toString(),
						promotion_id: this.selectedCoupon && this.selectedCoupon.promotion_id ? this.selectedCoupon.promotion_id : '',
					},
				};
				this.$store.dispatch('stores/predictPrice', payload).then((res) => {
					let { data, error } = res.data;
					if (data && !error) {
						this.predictedPrice = data;
					}
				});
			}, 100),
			deep: true
		},
	},
	computed: {
		isValidJourney(){
			if(this.dateStartError || this.dateEndError || this.quantityError || this.couponError || this.discountError) return false;

			if(this.$moment(this.formData.date_start).isBefore(new Date())){
				this.dateStartError = this.$__t('INVALID_START_DATE')
				return false;
			}

			if(this.$moment(this.formData.date_end).isBefore(new Date())){
				this.dateEndError = this.$__t('INVALID_END_DATE')
				return false;
			}

			return true;
		},
		disabledHoursOfStartDate() {
			if(this.$route.query.store_for_origin){
				return this.getDisabledHoursOf(this.formData.date_start);
			}
		},
		disabledMinutesOfStartDate() {
			if(this.$route.query.store_for_origin){
				return this.getDisabledMinutesOf(this.formData.date_start);
			}
		},
		disabledHoursOfEndDate() {
			if(this.$route.query.store_for_destination){
				return this.getDisabledHoursOf(this.formData.date_end);
			}
		},
		disabledMinutesOfEndDate() {
			if(this.$route.query.store_for_destination){
				return this.getDisabledMinutesOf(this.formData.date_end);
			}
		},
		storeTimes() {
			if (this.store.extra && this.store.extra.time_24hour) {
				return this.$__t('Open 24 hours');
			}
			let text = (
				this.store.time_table[
					'SA_' +
						this.$moment()
							.format('ddd')
							.toUpperCase()
				] || []
			).join(' ~ ');
			if (!text) {
				return this.$__t('Day Off');
			}
			return text;
		},
		currentIsoCode() {
			return this.$data.formData && this.$data.formData.user_iso_code && this.$data.formData.user_iso_code.value;
		},
		disabledDatesOnOrigin(){
			let disabledDates = this.$data.disabledDates;

			if(this.$route.query.store_for_origin){
				return disabledDates;
			}
		},
		disabledDatesOnDestination(){
			let disabledDates = this.$data.disabledDates;

			if(this.$route.query.store_for_destination){
				return disabledDates;
			}
		}

		// totalAmount() {
		// 	if (this.predictedPrice) {
		// 		this.totalOrderAmount = `${this.predictedPrice.origin.toLocaleString()}`+this.$__t('won');
		// 		return `${this.predictedPrice.total.toLocaleString()}`+this.$__t('won');
		// 	}
		// },
	},
	methods: {
		getDisabledHoursOf(dateFormString) {
			let results = [];
			let hourString = '00';
			for (let hour = 0; hour < 24; hour++) {
				if (hour < 10) hourString = '0' + hour;
				else hourString = '' + hour;
				let checkItem = this.$moment(dateFormString).format('YYYY-MM-DD') + ' ' + hourString;
				if (this.disabledDateHours.indexOf(checkItem) > -1) {
					results.push(hourString);
				}
			}

			return results;
		},
		getDisabledMinutesOf(dateFormString) {
			let results = [];
			let minutesString = '00';
			for (let minutes = 0; minutes < 60; minutes += 10) {
				if (minutes < 10) minutesString = '0' + minutes;
				else minutesString = '' + minutes;
				let checkItem = this.$moment(dateFormString).format('YYYY-MM-DD HH:') + minutesString;
				if (this.disabledSchedules.indexOf(checkItem) > -1) {
					results.push(minutesString);
				}
			}

			return results;
		},
		onClickSubmit() {
			this.$refs.form.submit();
		},
		userData() {
      let quantity_small = this.$store.getters['userData/GET_ITEM']({ key: 'quantity_small' })
      let quantity_big = this.$store.getters['userData/GET_ITEM']({ key: 'quantity_big' }) + quantity_small == 0 ? 1 : this.$store.getters['userData/GET_ITEM']({ key: 'quantity_big' })

			return {
				quantity_small: quantity_small,
				quantity_big: quantity_big,
				date_start: this.$store.getters['userData/GET_ITEM']({ key: 'date_start' }) ? this.$store.getters['userData/GET_ITEM']({ key: 'date_start' }) : this.$moment(),
				date_end: this.$store.getters['userData/GET_ITEM']({ key: 'date_end' }) ? this.$store.getters['userData/GET_ITEM']({ key: 'date_end' }) : this.$moment(),
				search_text: this.$store.getters['userData/GET_ITEM']({ key: 'search_text' }) ? this.$store.getters['userData/GET_ITEM']({ key: 'search_text' }) : '',
				item_type: this.$store.getters['userData/GET_ITEM']({ key: 'item_type' }) || ''
			};
		},

		submit() {
			this.$refs.form.submit();
		},

		onChangeFormData(e) {
			this.$log.log('[onChangeFormData]');
			var data = {
				date_start: this.$moment(this.formData.date_start).format('YYYY-MM-DD HH:mm'),
				date_end: this.$moment(this.formData.date_end).format('YYYY-MM-DD HH:mm'),
				quantity_small: parseInt(this.formData.quantitySmall),
				quantity_big: parseInt(this.formData.quantityBig),
				search_text: this.formData.searchText,
				item_type: this.formData.itemType.value
			};

			this.$store.dispatch('userData/set', data);

			this.checkAvailable();
		},

		applyDiscount(couponData) {
			if (this.selectedCoupon) {
				alert(this.$__t('coupons and discount codes cannot be applied at the same time.'));
				this.formData.coupon_code.value = '';
				return;
			}
			this.couponData = couponData;
			this.formData.coupon_code.value = couponData ? couponData.discount_code : '';
		},

		async submitForm() {
			if(this.formData.itemType.value.trim() == ''){
				this.scrollToError();
				this.formData.itemType.error = this.$__t('{delivery-step1}.itemtype.error')
				return
			}
			if (!await this.checkAvailable()) {
				this.scrollToError();
				return;
			}

			this.onChangeFormData();

			var data = {
				store_id: this.store.id.toString(),
				start_date: this.$moment(this.formData.date_start).format('YYYY-MM-DD HH:mm'),
				end_date: this.$moment(this.formData.date_end).format('YYYY-MM-DD HH:mm'),
				quantity: this.formData.quantitySmall.toString(),
				over_quantity: this.formData.quantityBig.toString(),
				coupon_code: this.selectedCoupon && this.selectedCoupon.coupon_code ? this.selectedCoupon.coupon_code : '',
			};

			if (this.couponData) {
				data.discount_code = this.couponData.discount_code;
			}

			if (this.selectedCoupon) {
				data.coupon_code = this.selectedCoupon.coupon_code;
			}

			await this.axios.post(this.$store.state.config.apiURL + '/v2/stores/' + this.store.id + '/interest', {}, { headers: this.$store.state.config.userHeaders });

			this.$router.push({ name: 'StoreDeliveryStep2', params: { couponData: this.couponData }, query: {item_type: this.formData.itemType.value, ...this.$route.query} });
		},

    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.error div.error')
        if(errorList.length >= 0 && errorList[0] != undefined){
					
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();
          
          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
          }
          else {
            offset = elOffset;
          }
					
          $('html, body').animate({scrollTop:offset}, 200);
        }        
      }, 0)
    },

		checkAvailable(popover) {
			return new Promise((resolve, reject) => {
				this.dateStartError = null;
				this.dateEndError = null;
				this.quantityError = null;
				this.couponError = null;
        this.discountError = null;

        if( this.formData.quantitySmall + this.formData.quantityBig < 1){
          this.quantityError = this.$__t('Set a number of your items');
          reject(false);
        }

				let dateStartMoment = this.$moment(this.formData.date_start);
				let dateEndMoment = this.$moment(this.formData.date_end);


				let query = {
					store_id: parseInt(this.$route.query.route_store),
					start_date: dateStartMoment.format('YYYY-MM-DD HH:mm'),
					end_date: dateEndMoment.format('YYYY-MM-DD HH:mm'),
					quantity: this.formData.quantitySmall.toString(),
					over_quantity: this.formData.quantityBig.toString(),
				};

				if(this.$route.query.route_store == this.$route.query.store_for_origin){
					query.end_date = query.start_date
				}else if(this.$route.query.route_store == this.$route.query.store_for_destination){
					query.start_date = query.end_date
				}

				if (this.formData.coupon_code.value) query.discount_code = this.formData.coupon_code.value;

				this.$store
					.dispatch('orders/check', { query })
					.then((res) => {
						resolve(this.$data.dateStartError || this.$data.dateEndError ? false : true);
					})
					.catch((error) => {
						this.$log.error(error);
						if (error == 'DISABLED') {
							resolve(true);
							return;
						}

						if (error == 'CLOSED') this.quantityError = this.$__t('OUT_OF_STOCK');
						else if (error == 'OUT_OF_STOCK') this.quantityError = this.$__t(error);
						else if (error == 'OUT_OF_BIGABLE_STOCK') this.quantityError = this.$__t(error);
						else if (error == 'INVALID_START_DATE') {
							if(this.$route.query.store_for_origin){
								this.dateStartError = this.$__t(error);
							}else if(this.$route.query.store_for_destination){
								this.dateEndError = this.$__t(error)
							}
						}
            else if (error == 'INVALID_END_DATE') {
							if(this.$route.query.store_for_origin){
								this.dateStartError = this.$__t(error);
							}else if(this.$route.query.store_for_destination){
								this.dateEndError = this.$__t(error)
							}
						}
            else if (error == 'CLOSED_START_DATE') this.dateStartError = this.$__t('It\'s a temporal day off the day you selected.')
            else if (error == 'CLOSED_END_DATE') this.dateEndError = this.$__t('It\'s a temporal day off the day you selected.')
						else if (error.indexOf('INVALID_PROMOTION') != -1) {
							if (this.selectedCoupon && !this.formData.coupon_code.value) this.couponError = this.$__t('Unavailable coupon');
							else if (!this.selectedCoupon && this.formData.coupon_code.value) this.discountError = this.$__t('Unavailable discount code');
						} else if (error.indexOf('USED_PROMOTION') != -1) {
							if (this.selectedCoupon && !this.formData.coupon_code.value) this.couponError = this.$__t('used');
							else if (!this.selectedCoupon && this.formData.coupon_code.value) this.discountError = this.$__t('used');
            }

						resolve(false);

					});
			});
		},

		async loadSchedules() {
			try {
				let res = await this.axios.get(this.$store.state.config.apiURL + `/v2/stores/${this.$route.query.route_store}/schedule`);
				let { data, error } = res.data;
				if (data && !error) {
					data.schedules.forEach((d) => {
						if (d.status == 'disabled') {
							this.disabledDates.push(d.date);
						}

						let prevItem = null;
						let count = 0;
						d.times.forEach((t) => {
							let item = d.date + ' ' + t.time;
							if (
								this.$moment(item)
									.startOf('hour')
									.isSame(this.$moment(prevItem).startOf('hour'))
							) {
								count++;
							} else {
								count = 0;
							}

							if (count == 5) {
								this.disabledDateHours.push(
									this.$moment(item)
										.startOf('hour')
										.format('YYYY-MM-DD HH')
								);
							}

							this.disabledSchedules.push(item);
							prevItem = item;
						});
					});
				}
			} catch (e) {
				this.$log.error(e);
			}
		},
		selectCoupon(coupon) {
			if (this.couponData) {
				alert(this.$__t('coupons and discount codes cannot be applied at the same time.'));
				this.selectedCoupon = null;
				this.$refs.couponSelector.removeSelectedCoupon();
				return;
			}
			this.selectedCoupon = coupon;
		},
		removeSelectedCoupon() {
			this.selectedCoupon = null;
		},
		handleLuggage(values) {
			this.formData.quantitySmall = values.quantity_small;
			this.formData.quantityBig = values.quantity_big;
		},
		openLuggageSelector() {
			this.$refs.luggageSelector.openLuggage();
		},
    openModal( ref ){
      this.$refs[ref].open(ref);
    }
	},
	async created() {
		await this.loadSchedules();
		await this.checkAvailable();
	},
};
</script>
