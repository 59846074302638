<template>
  <div class="ui-multiple-image-uploader">
    <div class="label" v-if="label">{{ label }}</div>
    <div class="body" v-if="model">
      <div class="item" v-for="i in model.length">
        <image-uploader v-model="model[i-1]" :tag="tag" :placeholder="placeholder" />
        <ul class="item-interface">
          <li><div @click="deleteItem( i )"><i class="fa fa-times"></i></div></li>
          <li><div @click="upItem( i )"><i class="fa fa-arrow-up"></i></div></li>
          <li><div @click="downItem( i )"><i class="fa fa-arrow-down"></i></div></li>
        </ul>
      </div>
      <ui-text-button size="small" v-if="!max || model.length < max"><button type="button" @click="addItem"><i class="fa fa-plus-circle"></i>{{ $__t( "추가하기" ) }}</button></ui-text-button>
    </div>
  </div>
</template>

<script>
import ImageUploader from "@/components/modules/RichForm/ImageUploader";
export default {
  components: {
    ImageUploader
  },
  props: [ "value", "label", "placeholder", "tag", "max" ],
  data(){
    return {}
  },
  computed: {
    model: {
      get(){
        if( !this.value ){
          this.$emit( "input", [{}]);
        }else{
          return this.value;
        };
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
      }
    }
  },
  methods: {
    deleteItem( i ){
      if( this.value.length > 1 ){
        this.value.splice( ( i - 1 ), 1 );
      };
    },
    addItem(){
      this.value.push({});
    },
    upItem( i ){
      if( i > 1 ){
        var item = this.value.splice( ( i - 1 ), 1 )[0];
        this.value.splice( i-2, 0, item );
      };
    },
    downItem( i ){
      if( i < this.value.length ){
        var item = this.value.splice( ( i - 1 ), 1 )[0];
        this.value.splice( i, 0, item );
      };
    }
  }
}
</script>
