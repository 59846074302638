<template>
  <div id="iez-landing">
    <div class="container">
      <div
        style="font-size: 2rem; width: 2rem; height: 2rem; position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center"
      >
        <i class="fa fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';

export default {
  data() {
    return {};
  },

  created() {
    this.setViewTypeInLocalStorage();
  },

  mounted() {
    this.$nextTick(async () => {
      await this.setAuthInfo();
      await this.setAccessLog();
      await this.handleInviteCode();

      let query = {};
      const sid = await this.getSid();

      if (sid) {
        query.sid = sid;
        query.autoSearch = 'target';
      }

      this.$router.push({ name: 'Iez', query: query });
    });
  },

  computed: {
    isViewTypeIez() {
      return this.$store.getters['userData/GET_ITEM']({ name: '__LUGSTAY__VIEW__TYPE__', noDefault: true }) == 'iez';
    },
    campaignCode() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'campaignCode' });
    },

    promotion() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'promotion' });
    }
  },

  methods: {
    setViewType() {
      this.$store.dispatch('userData/set', { name: '__LUGSTAY__VIEW__TYPE__', value: 'iez', expires: 60 * 60 * 24 * 7 });
    },

    setViewTypeInLocalStorage() {
      localStorage.setItem('__LUGSTAY__VIEW__TYPE__', 'iez');
    },

    async setAuthInfo() {
      let ieasy_data = decodeURIComponent(this.$route.query.uid);

      if (ieasy_data) {
        try {
          let res = await this.axios.post(
            this.$store.state.config.apiURL + '/v2/passport/auth/ieasy/register',
            { ieasy_data },
            { headers: this.$store.state.config.userHeaders }
          );

          if (res.data.status == 'success') {
            const { access_token } = res.data.data;
            
            this.$store.commit('auth/SET_AUTH', res.data.data);

            config.setAccessToken(access_token);

            await this.createInviteCouponForIezUser();
          }
        } catch (e) {}
      }
    },

    async getSid() {
      let sid = this.$route.query.sid;

      return sid;
    },

    async setAccessLog() {
      let payload = {
        user_header: this.$store.state.config.userHeaders
      };

      if (this.$store.state.auth.gid) payload.user_id = this.$store.state.auth.gid;

      try {
        await this.axios.post(this.$store.state.config.apiURL + '/v2/iez/accessLog', payload);
      } catch (e) {}
    },

    async handleInviteCode() {
      if (this.campaignCode && this.promotion) return;

      const inviteCode = this.$store.state.config.iezInviteCode;

      let res = await this.axios.get(this.$store.state.config.apiURL + '/v2/campaigns/invite/' + inviteCode);

      let { data, error } = res.data;

      if (!error) {
        this.$store.dispatch('userData/set', { campaignCode: data.campaign.campaign_code, promotion: data.promotion });
      }
    },

    async createInviteCouponForIezUser() {
      if (this.promotion && this.campaignCode) {
        await this.axios.post(
          this.$store.state.config.apiURL + '/v2/campaigns/invite/' + this.campaignCode,
          {},
          { headers: this.$store.state.config.userHeaders }
        );
      }
    }
  }
};
</script>
