<template>
  <div class="box-select">
    <h1 class="box-select__header">
      <span>{{ $__t("이용할 라커 선택") }}</span>
      <button type="button" @click="onClickQuestion" v-button>
        <img src="/img/question.svg" />
      </button>
    </h1>
    <ul class="legend-list">
      <li class="legend-item">
        <span class="color color--available" />
        <span class="text"> {{ $__t("이용 가능") }} </span>
      </li>
      <li class="legend-item">
        <span class="color color--using" />
        <span class="text"> {{ $__t("이용 중") }} </span>
      </li>
      <li class="legend-item">
        <span class="color color--disabled" />
        <span class="text"> {{ $__t("사용불가") }} </span>
      </li>
    </ul>

    <LockerBoxSelector
      :value="selectedBox"
      @input="selectedBox = $event"
      :data="boxList"
      class="locker-box-selector"
    />

    <div class="box-select__locker-info">
      <template v-if="selectedBox">
        <div class="info box-info">
          <span class="primary">
            {{ $__t("보관함") }} {{ selectedBox.locker_number }} •
            {{ selectedBox.locker_size }} </span
          ><br />
          <span class="secondary">{{ LOCKER_SIZE[selectedBox.locker_size] }}</span>
        </div>
        <div class="info price-info">
          <span class="primary">{{ LOCKER_PRICE[selectedBox.locker_size] }} </span
          ><span class="secondary">{{ $__t("won") }}</span>
        </div>
      </template>
    </div>

    <div class="box-select__actions">
      <button type="button" class="btn btn--close" @click="onClickClose">
        {{ $__t("닫기") }}
      </button>
      <button
        type="button"
        class="btn btn--submit"
        :class="{ activated: selectedBox }"
        @click="onClickSubmit"
      >
        {{ $__t("예약하기") }}
      </button>
    </div>

    <UiSlider id="locker-size-info-modal" ref="locker-size-info-modal" :background="true">
      <LockerSizeInfo @close="closeRef('locker-size-info-modal')" />
    </UiSlider>
  </div>
</template>

<script>
import UiSlider from "@/components/modules/Slider/Body.vue";
import LockerSizeInfo from "@/components/resources/locker/LockerSizeInfo.vue";
import LockerBoxSelector from "@/components/resources/locker/LockerBoxSelector.vue";

export default {
  components: {
    LockerSizeInfo,
    LockerBoxSelector,
    UiSlider,
  },

  props: {
    locker: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const LOCKER_SIZE = {
      S: "50cm(W) x 24cm(H) x 54cm(D)",
      M: "50cm(W) x 45cm(H) x 54cm(D)",
      L: "50cm(W) x 93cm(H) x 54cm(D)",
      XL: "50cm(W) x 93cm 이상(H) x 54cm(D)",
    };

    const LOCKER_PRICE = {
      S: `1,000`,
      M: `1,500`,
      L: `2,000`,
      XL: `3,000`,
    };

    return {
      available: false,
      selectedBox: null,
      LOCKER_SIZE,
      LOCKER_PRICE,
    };
  },

  methods: {
    async setAvailability(flag) {
      if (flag === undefined) {
        return;
      }

      if (flag) {
        await this.$utils.wait(250);
        this.available = flag;
      }

      if (!flag) {
        this.available = flag;
        this.selectedBox = null;
      }
    },

    openRef(ref) {
      this.$refs[ref].open(ref);
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    onClickQuestion() {
      this.openRef("locker-size-info-modal");
    },

    onClickClose() {
      this.$emit("close");
    },

    onClickSubmit() {
      if (!this.selectedBox) return;

      this.$emit("submit", this.selectedBox);
    },
  },

  computed: {
    boxList() {
      if (
        Array.isArray(this.locker) &&
        this.locker.length > 0 &&
        this.locker[0].locker_id
      ) {
        return this.locker;
      }

      const lockerBoxList = this.locker?.extra?.locker || [];

      return lockerBoxList;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-select {
  padding: unit(14) 0;

  &__header {
    text-align: center;
    font-weight: bold;
    font-size: unit(16);
    line-height: 1.38;
    text-align: center;
    letter-spacing: -0.006em;

    button {
      display: inline-block;
      vertical-align: text-bottom;
      top: 0;
      right: unit(-12);
      width: unit(20);
      height: unit(20);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .legend-list {
    margin-top: unit(32);
    text-align: center;

    .legend-item {
      display: inline-flex;
      align-items: center;
      gap: unit(4);
      margin: 0 unit(6);

      .color {
        display: inline-block;
        width: unit(16);
        height: unit(14);
        background: #e1e4e6;
        border-radius: unit(6);

        &--available {
          background: $color-primary;
        }

        &--using {
          background: #a9afb3;
        }

        &--disabled {
          background: #e1e4e6;
        }
      }

      .text {
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        letter-spacing: -0.006em;
        color: #61676c;
      }
    }
  }

  .locker-box-selector::v-deep {
    margin-top: unit(16);

    .locker-viewport {
      height: unit(348);
    }
  }

  &__locker-info {
    height: unit(40);
    margin-top: unit(20);
    line-height: 1.45;
    display: flex;
    gap: unit(12);
    align-items: flex-end;
    justify-content: space-between;

    .info {
      min-width: 0;

      .primary {
        font-weight: 500;
        font-size: unit(14);
        letter-spacing: -0.006em;
        color: #61676c;
      }

      .secondary {
        font-size: unit(10);
        font-weight: 400;
        color: #61676c;
      }

      &.price-info {
        .primary {
          font-weight: bold;
          font-size: unit(16);
          letter-spacing: -0.006em;
        }

        .secondary {
          font-size: unit(16);
          font-weight: 400;
        }
      }
    }
  }

  &__actions {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: $color-white;
    padding-top: unit(16);
    display: flex;
    gap: unit(10);

    .btn {
      padding: unit(15) unit(16);
      font-weight: 700;
      font-size: unit(14);
      line-height: 1.43;
      letter-spacing: -0.006em;
      color: #a9afb3;
      height: unit(50);
      border-radius: unit(16);

      &--close {
        flex: 0 0 unit(80);
        border: 1px solid #e1e4e6;
      }

      &--submit {
        flex: 1;
        background: #a9afb3;
        color: $color-white;
        cursor: not-allowed;

        &.activated {
          background: $color-primary;
          color: $color-white;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
