<template>
  <div class="subpage" id="host-tickets">
    <div class="search-dates">
      <div>
        <input type="date" placeholder="YYYY-MM-DD" v-model="searchStartDate" />
      </div>
      ~
      <div>
        <input type="date" placeholder="YYYY-MM-DD" v-model="searchEndDate" />
      </div>
    </div>
    <div class="solid-button" @click="searchByDates">
      <span>{{ $__t('조회') }}</span>
    </div>
    <div class="reservation-status-section">
      <div class="reservation-status">
        <div id="status-all" class="reservation-status-item all active" style="margin-right: 1rem" @click="selectStatus('all')">
          <div class="circle">{{ statusCount.total }}</div>
          <div class="label">{{ $__t('전체') }}</div>
        </div>
      </div>
      <div class="reservation-status main-process">
        <div id="status-issued" class="reservation-status-item" @click="selectStatus('issued')">
          <div class="circle">{{ statusCount.issued }}</div>
          <div class="label">{{ $__t('체크인 전') }}</div>
        </div>
      </div>
      <div class="reservation-status main-process">
        <i class="fa fa-caret-right reservation-status-item"></i>
        <div id="status-checkin" class="reservation-status-item" @click="selectStatus('checkin')">
          <div class="circle">{{ statusCount.checkin }}</div>
          <div class="label">{{ $__t('체크인 됨') }}</div>
        </div>
      </div>
      <div class="reservation-status main-process">
        <i class="fa fa-caret-right reservation-status-item"></i>
        <div id="status-checkout" class="reservation-status-item" @click="selectStatus('checkout')">
          <div class="circle">{{ statusCount.checkout }}</div>
          <div class="label">{{ $__t('체크아웃 됨') }}</div>
        </div>
      </div>
      <div class="reservation-status" style="border-right: none">
        <div id="status-completed" class="reservation-status-item" @click="selectStatus('completed')">
          <div class="circle">{{ statusCount.cancelled + statusCount.expired }}</div>
          <div class="label">{{ $__t('취소/만료') }}</div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="search-host-tickets">
        <i class="fa fa-search"></i>
        <input type="text" v-model="listQuery.keyword" />
        <div class="solid-button" @click="getListItems(true)">
          <span>{{ $__t('search') }}</span>
        </div>
      </div>
      <div class="section" id="host-ticket-list-section" style="margin-top: 10px">
        <div class="container">
          <div class="ticket-list" v-if="docs.length > 0">
            <div class="container">
              <ul class="item-list">
                <li class="item" v-for="doc in docs">
                  <ticket-list-item :ticket="doc" @check="onTicketStatusCheck(doc)" @checked-in="onTicketCheckedIn($event)" />
                </li>
              </ul>
              <ui-text-button display="block" color="primary" class="list-more" @click="getMoreListItems" v-if="listLoadable">
                <button type="button">{{ $__t('더 불러오기') }}</button>
              </ui-text-button>
            </div>
          </div>

          <div class="ticket-list-empty" v-else>
            <div
              v-if="isLoading"
              style="font-size: 2rem; width: 2rem; height: 2rem; top: 0; left: 0; right: 0; bottom: 0; margin: auto; text-align: center"
            >
              <i class="fa fa-spinner fa-pulse"></i>
            </div>
            <div class="container" v-else>
              <div class="title">{{ $__t('아직 예약내역이 존재하지 않습니다.') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="wni.isNative" style="margin-bottom: 10rem"></div>

    <modal id="luggagePhotoUploadModal" ref="luggagePhotoUploadModal" :headerLabel="$__t('Checked in completed!')">
      <template v-slot:body>
        <div class="primary">
          {{ $__t("Take an image of the guest's item(s)!") }}
        </div>
        <div class="secondary">
          {{ $__t('The image itself is a promise of secured storing and can be used for covering accidents.') }}
        </div>
        <div class="card-button">
          <!-- <img src="/img/icon-camera.png" class="logo" />
          <div class="text">{{ $__t('Upload image of the item') }}</div>
          <img
            src="/img/arrow-right.png"
            srcset="/img/arrow-right@2x.png 2x, /img/arrow-right@3x.png 3x"
            class="arrow"
          /> -->
          <luggage-photo-uploader
            :ticket="ticketForImageUpload"
            @loading="$store.commit('loading/SET_TRUE')"
            @set-resource="goToTicket(ticketForImageUpload.ticket_code)"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// import Vue from 'vue'
import TicketListItem from '@/components/resources/ticket/ForHost';
// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
// import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import LuggagePhotoUploader from '@/components/modules/RichForm/LuggagePhotoUploader';

export default {
  components: {
    TicketListItem,
    // VueCtkDateTimePicker
    LuggagePhotoUploader
  },
  data() {
    return {
      formData: {
        store: {
          label: '상점',
          placeholder: '상점을 선택해주세요',
          items: [{ label: '전체', value: 'all' }],
          value: 'all',
          error: ''
        },
        order: {
          label: '정렬',
          placeholder: '정렬 순서를 선택해주세요',
          items: [
            { label: '예약일', value: 'create' },
            { label: '맡기는 시각', value: 'check_in' },
            { label: '찾는 시각', value: 'check_out' }
          ],
          value: 'create',
          error: ''
        }
      },
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 10,
        keyword: '',
        date_start: this.$moment(new Date(), 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'),
        date_end: this.$moment(new Date(), 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD')
      },

      searchStartDate: this.$moment(new Date(), 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'),
      searchEndDate: this.$moment(new Date(), 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'),

      status: 'all',
      statusCount: {
        total: 0,
        issued: 0,
        checkin: 0,
        checkout: 0,
        cancelled: 0,
        available: 0,
        expired: 0,
        completed: 0
      },
      isLoading: false,

      ticketForImageUpload: null,
      loading: false
    };
  },
  created() {
    this.getListItems(true);
    this.getTicketStatusCount();
  },
  watch: {
    status(v) {
      this.listQuery.page = 1;
      this.listQuery.size = 10;
      this.getListItems(true);
    },
    searchStartDate(value) {
      let date_start = this.$moment(this.searchStartDate, 'YYYY-MM-DD');
      let date_end = this.$moment(this.searchEndDate, 'YYYY-MM-DD');
      if (date_start.isAfter(date_end, 'day')) {
        this.searchEndDate = this.$moment(date_start, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
      }
    },
    searchEndDate(value) {
      let date_start = this.$moment(this.searchStartDate, 'YYYY-MM-DD');
      let date_end = this.$moment(this.searchEndDate, 'YYYY-MM-DD');
      if (date_start.isAfter(date_end, 'day')) {
        this.searchStartDate = this.$moment(date_end, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
      }
    }
  },
  computed: {
    docs() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({ list: 'HostTickets' });
    }
  },
  methods: {
    openModal(ref) {
      this.$refs[ref].open(ref);
    },
    closeModal(ref) {
      this.$refs[ref].close();
    },
    submitForm(values) {
      this.$store.commit('tickets/CLEAR_LIST', { list: 'HostTickets' });
      this.listQuery.page = 1;
      this.getListItems();
    },
    getListItems(clear) {
      this.isLoading = true;
      return this.$store
        .dispatch('tickets/getListForHost', {
          list: 'HostTickets',
          query: this.listQuery,
          filter: this.status,
          clear: clear
        })
        .then((res) => {
          this.isLoading = false;
          if (this.docs.length == res.data.data.total || res.data.data.items.length == 0) {
            this.listLoadable = false;
          } else {
            this.listLoadable = true;
          }

          this.getTicketStatusCount();
        });
    },
    getMoreListItems() {
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    },
    selectStatus(status) {
      this.status = status;
      $('.reservation-status-item').removeClass('active');
      $('#status-' + status).addClass('active');
    },
    getTicketStatusCount() {
      var queryString = '?';
      if (this.listQuery) {
        Object.keys(this.listQuery).forEach((o) => {
          if (o == 'page' || o == 'size') return;
          if (this.listQuery[o]) queryString += o + '=' + this.listQuery[o] + '&';
        });
      }

      this.axios
        .get(this.$store.state.config.apiURL + '/v2/manager/tickets/counting' + queryString, {
          headers: this.$store.state.config.userHeaders
        })
        .then(({ data }) => {
          this.statusCount = data.data;
        })
        .catch((error) => {
          this.$store.commit('alert/ADD_ITEM', { message: error, status: 'error' });
        });
    },
    searchByDates() {
      if (!this.searchStartDate) {
        alert('검색 시작일을 선택해 주세요.');
        return;
      }

      if (!this.searchEndDate) {
        alert('검색 종료일을 선택해 주세요.');
        return;
      }

      this.listQuery.page = 1;
      this.listQuery.size = 10;

      this.listQuery.date_start = this.searchStartDate;
      this.listQuery.date_end = this.searchEndDate;

      this.getListItems(true);
      this.getTicketStatusCount();
    },
    goToTicket(code) {
      this.$refs.luggagePhotoUploadModal.remove();

      this.$router.push({ name: 'HostTicketSingle', params: { code: code }, query: {} }).finally(() =>
        setTimeout(() => {
          this.$store.commit('loading/SET_FALSE');
        })
      );
    },

    setCheckedInItem(data) {
      this.ticketForImageUpload = data;
      this.openModal('luggagePhotoUploadModal');
    },

    onTicketStatusCheck(ticket) {
      this.ticketForImageUpload = ticket;
    },

    onTicketCheckedIn(data) {
      this.loading = false;
      // this.setCheckedInItem(data);
    }
  }
};
</script>
