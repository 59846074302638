var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-ticket for-guest",class:{
    ISSUED: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'ISSUED',
    CHECK_IN: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'CHECK_IN',
    ASSIGNING: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'ASSIGNING',
    APPROVE: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'APPROVE',
    PICK_UP: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'PICK_UP',
    DROP_OFF: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'DROP_OFF',
    CHECK_OUT: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'CHECK_OUT',
    CANCELLED: _vm.ticket && _vm.ticket.status && _vm.ticket.status === 'CANCELLED',
    EXPIRED: _vm.isExpired,
  }},[(_vm.ticket)?[_c('div',{staticClass:"container"},[_c('div',{staticClass:"ticket-status-label",class:[
          {
            ISSUED: _vm.ticket.status && _vm.ticket.status === 'ISSUED',
            CHECK_IN: _vm.ticket.status && _vm.ticket.status === 'CHECK_IN',
            ASSIGNING: _vm.ticket.status && _vm.ticket.status === 'ASSIGNING',
            APPROVE: _vm.ticket.status && _vm.ticket.status === 'APPROVE',
            PICK_UP: _vm.ticket.status && _vm.ticket.status === 'PICK_UP',
            DROP_OFF: _vm.ticket.status && _vm.ticket.status === 'DROP_OFF',
            CHECK_OUT: _vm.ticket.status && _vm.ticket.status === 'CHECK_OUT',
            CANCELLED: _vm.ticket.status && _vm.ticket.status === 'CANCELLED',
            EXPIRED: _vm.isExpired,
            ENG: _vm.$store.state.lang === 'en',
          },
        ]},[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(_vm.ticketStatus))+" ")]),_c('div',{staticClass:"store"},[(_vm.ticket.store_resources && _vm.ticket.store_resources.thumbnail_image)?_c('img',{attrs:{"src":_vm.ticket.store_resources.thumbnail_image.resource_thumbnail_url ||
            _vm.ticket.store_resources.thumbnail_image.resource_url}}):_c('img',{attrs:{"src":"/img/no-image.png"}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.ticket.store_name)+" ")]),_c('div',{staticClass:"button-area"},[_c('ui-solid-button',{attrs:{"size":"wide"}},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.navigateTo('store')}}},[_vm._v(_vm._s(_vm.$__t("detail info")))])])],1)]),_c('div',{staticClass:"reserve"},[(_vm.ticket)?_c('div',{staticClass:"dateInfo"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.ticket.ticket_date_start).format(_vm.$__t("M. D. HH:mm")))+" ~ "+_vm._s(_vm.$moment(_vm.ticket.ticket_date_end).format(_vm.$__t("M. D. HH:mm")))+" / "+_vm._s(_vm.ticket.ticket_quantity + _vm.ticket.ticket_over_quantity)+" "+_vm._s(_vm.$__t("개"))+" ")]):_vm._e(),_c('div',{staticClass:"statusInfo"},[_c('ui-border-round-button',{attrs:{"size":"wide","color":"gray","disabled":_vm.ticket.status !== 'ISSUED'},on:{"click":function($event){return _vm.$router.push({ name: 'CancelTicket', params: { id: _vm.ticket.ticket_code }})}}},[_c('button',{attrs:{"type":"button","disabled":_vm.ticket.status !== 'ISSUED'}},[_vm._v(_vm._s(_vm.$__t("예약 취소하기")))])])],1),_c('ui-solid-button',{attrs:{"size":"wide"}},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.navigateTo('ticket')}}},[_vm._v(_vm._s(_vm.$__t("view ticket")))])])],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }