<template>
  <div class="page" id="cs-notices-page">
    <div class="container">
      <div class="page-header">
        <div class="title">{{ $__t('공지사항') }}</div>
        <div class="secondary">{{ $__t('럭스테이의 새로운 소식을 확인하세요.') }}</div>
      </div>

      <div class="page-body">
        <div class="notice-list" v-if="docs.length > 0">
          <div class="container">
            <ul class="item-list">
              <li class="list-item" :class="{ on: opened.indexOf(doc.post_id) >= 0 }" v-for="doc in docs">
                <router-link :to="{ name: 'CsNoticesSingle', params: { id: doc.post_id } }">
                  <div class="title" @click="toggleItem(doc.post_id)">
                    <div class="container">
                      <div class="title">
                        {{ doc.post_title }}
                        <div v-if="isNewItem(doc)" class="ui-post-badge new">new</div>
                      </div>
                      <div class="datetime">{{ doc.created_at | moment('YYYY-MM-DD') }}</div>
                      <i class="fa fa-angle-right"></i>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
            <ui-text-button
              display="block"
              color="primary"
              class="list-more"
              @click="getMoreListItems"
              v-if="listLoadable"
            >
              <button type="button">{{ $__t('더 불러오기') }}</button>
            </ui-text-button>
          </div>
        </div>
        <div class="notice-list-empty" v-else>
          <div class="container">
            <div class="title">{{ $__t('불러오는 중') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      opened: [],
      listLoadable: false,
      listQuery: {
        page: 1,
        size: 12,
      },
    };
  },
  computed: {
    docs() {
      return this.$store.getters['resourceNotices/GET_ITEMS_BY_LIST']({ list: 'Notices' });
    },
    unreadNotices() {
      const date_now = this.$moment();
      const newNoticesInAWeek = this.docs.filter(
        (o) => this.$moment.duration(date_now.diff(this.$moment(o.created_at))).asDays() <= 7
      );

      const noticesViewed = this.getNoticesViewed();

      if (noticesViewed.length == 0) return newNoticesInAWeek;

      const readNotices = newNoticesInAWeek.filter((o) => noticesViewed.indexOf(o.post_id.toString()) > -1);

      this.$store.commit('resourceNotices/SET_LIST_ITEMS', { list: 'lugstay_notices_viewed', data: readNotices });

      const unreadNotices = newNoticesInAWeek.filter((o) => noticesViewed.indexOf(o.post_id.toString()) < 0);

      return unreadNotices;
    },
  },
  created() {
    this.getListItems();
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    getListItems() {
      this.$store.dispatch('resourceNotices/getList', { list: 'Notices', query: this.listQuery }).then((res) => {
        if (this.docs.length == res.data.data.total || res.data.data.items.length == 0) {
          this.listLoadable = false;
        } else {
          this.listLoadable = true;
        }
      });
    },
    getMoreListItems() {
      this.listQuery.page = this.listQuery.page + 1;
      this.getListItems();
    },
    toggleItem(index) {
      if (this.opened.indexOf(index) >= 0) {
        this.opened = this.opened.filter((o) => o != index);
      } else {
        this.opened.push(index);
      }
    },
    getNoticesViewed() {
      let noticesViewed = localStorage.getItem('lugstay_notices_viewed');

      if (!noticesViewed) return [];

      noticesViewed = noticesViewed.split(',');

      return noticesViewed;
    },
    isNewItem(item) {
      if (this.unreadNotices.indexOf(item) > -1) {
        return true;
      } else {
        return false;
      }
    },

    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header > .title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top - $pageHeaderTitle.outerHeight() < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    },
  },
};
</script>
