const isTouchDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WNEmulator|WNInterface/i.test(navigator.userAgent);

import Layout from '@/containers/Layout';
import Blank from '@/containers/Blank';

import PageMoreBillings from '@/components/pages/v2/MoreBillings';
import PagePrice from '@/components/pages/_/Price';
import PagePromotionHome from '@/components/pages/promotion/PromotionHome';
import PagePromotionCodeProcess from '@/components/pages/promotion/PromotionCodeProcess';
import PagePromotionContents from '@/components/pages/promotion/PromotionContents';
import PageHostLanding from '@/components/pages/v2/HostLanding';
import PageHostLandingLeadAds from '@/components/pages/v2/HostLandingLeadAds';

import PageIezLanding from '@/components/pages/iez/IezLanding';
import PageIez from '@/components/pages/iez/Iez';

import PageHostGuide from '@/components/pages/host/HostGuide';
import PageHostEvent from '@/components/pages/host/HostEvent';
import PageAffiliateCompany from '@/components/pages/_/AffiliateCompany';

import PageAuthLogin from '@/components/pages/auth/AuthLogin';
import PageAuthLoginForExistingUsers from '@/components/pages/auth/AuthLoginForExistingUsers';
import PageAuthSendResetPassword from '@/components/pages/auth/AuthSendResetPassword';
import PageAuthResetPassword from '@/components/pages/auth/AuthResetPassword';
import PageAuthDelete from '@/components/pages/auth/AuthDelete';
import PageAuthFacebookCallback from '@/components/pages/auth/AuthFacebookCallback';
import PageAuthGoogleCallback from '@/components/pages/auth/AuthGoogleCallback';
import PageAuthInstagramCallback from '@/components/pages/auth/AuthInstagramCallback';
import PageAuthNaverCallback from '@/components/pages/auth/AuthNaverCallback';
import PageAuthWechatCallback from '@/components/pages/auth/AuthWechatCallback';
import PageAuthAppleCallback from '@/components/pages/auth/AuthAppleCallback';

import PageMypageTickets from '@/components/pages/mypage/MypageTickets';
import PageMypageTicketSingle from '@/components/pages/mypage/MypageTicketSingle';
import PageMypageTicketSingleV2 from '@/components/pages/v2/TicketSingle.vue';
import PageTicketSingleReceipt from '@/components/pages/v2/TicketSingleReceipt';

import PageCancelTicket from '@/components/pages/v2/CancelTicket';
import PageMypageInterestStores from '@/components/pages/mypage/MypageInterestStores';
// import PageMypageCoupons from "@/components/pages/mypage/MypageCoupons";
import PageMypageProfile from '@/components/pages/mypage/MypageProfile';
import PageMypageChangePassword from '@/components/pages/mypage/MypageChangePassword';
import PageMypage from '@/components/pages/mypage/Mypage';
import PageMypageCoupons from '@/components/pages/v2/MypageCoupons';
import PageMyReservation from '@/components/pages/mypage/MyReservation';

import PageQrCodeCallback from '@/components/pages/_/QrCodeCallback';

import PageHost from '@/components/pages/host/Host';
import PageHostCheck from '@/components/pages/host/HostCheck';
import PageHostTickets from '@/components/pages/host/HostTickets';
import PageHostTicketSingle from '@/components/pages/host/HostTicketSingle';
import PageHostStores from '@/components/pages/host/HostStores';
import PageHostStoreCreate from '@/components/pages/host/HostStoreCreate';
import PageHostStoreEdit from '@/components/pages/host/HostStoreEdit';
import PageHostNotices from '@/components/pages/host/HostNotices';
import PageHostNoticesSingle from '@/components/pages/host/HostNoticesSingle';

import PageReserve from '@/components/pages/v2/Reserve.vue';
import PageReserveOrder from '@/components/pages/v2/ReserveOrder.vue';
import PageReservePayMethods from '@/components/pages/v2/ReservePayMethods.vue';
import PageReserveAddPayMethod from '@/components/pages/v2/ReserveAddPayMethod.vue';

import PageStoreDelivery from '@/components/pages/store/StoreDelivery';
import PageStoreDeliveryStep0 from '@/components/includes/DeliveryFormStep0';
import PageStoreDeliveryStep1 from '@/components/includes/DeliveryFormStep1';
import PageStoreDeliveryStep2 from '@/components/includes/DeliveryFormStep2';
import PageStoreDeliveryStep3 from '@/components/includes/DeliveryFormStep3';
import PageStoreDeliveryStep4 from '@/components/includes/DeliveryFormStep4';
import PageDeliveryStatus from '@/components/pages/_/DeliveryStatus';

import PageCsPrivacyPolicy from '@/components/pages/cs/CsPrivacyPolicy';
import PageCsPrivacyPolicyTheThird from '@/components/pages/cs/CsPrivacyPolicyTheThird';
import PageCsTermsOfUse from '@/components/pages/cs/CsTermsOfUse';
import PageCsFaq from '@/components/pages/cs/CsFaq';
import PageCsNotices from '@/components/pages/cs/CsNotices';
import PageCsNoticesSingle from '@/components/pages/cs/CsNoticesSingle';
import PageCsGuide from '@/components/pages/cs/CsGuide';
import PageCsHost from '@/components/pages/cs/CsHost';

import PageIncheonSurvey from '@/components/pages/_/IncheonSurvey';

import PageHostSetStatusCallback from '@/components/pages/host/HostSetStatusCallback.vue';
import PageStoreSetStatusCallback from '@/components/pages/store/StoreSetStatusCallback.vue';

export default [
  {
    name: 'Home',
    path: '/',
    component: isTouchDevice ? () => import('@/components/pages/landings/DefaultLandingPage') : () => import('@/components/pages/v2/PcLanding'),
    default: true,
    meta: { hideHeader: !isTouchDevice, hideSidebar: !isTouchDevice, allowPc: true, reserve_form: true }
  },
  {
    name: 'MobileHome',
    path: '/home',
    component: () => import('@/components/pages/landings/MobileLandingPage.vue'),
    meta: { fetchData: true, hideHeader: true, hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'HowToUse',
    path: '/how-to-use',
    component: () => import('@/components/pages/v2/MoreHowToUse'),
    meta: { headerType: 'more-depth', title: '이용방법', hideChannelTalk: true }
  },
  { name: 'Promotion', path: '/campaign', component: PagePromotionHome, meta: { hideFooter: true } },
  {
    name: 'PromotionContents',
    path: '/campaign/contents',
    component: PagePromotionContents,
    meta: { hideFooter: true }
  },
  {
    name: 'PromotionCodeProcess',
    path: '/campaign/codes/:code',
    props: true,
    component: PagePromotionCodeProcess,
    meta: { hideFooter: true }
  },
  { name: 'Price', path: '/price', component: PagePrice, meta: { hideChannelTalk: true } },
  {
    name: 'HostLanding',
    path: '/host/land',
    component: PageHostLanding,
    meta: { hideHeader: true, hideFooter: true, hideSidebar: true, allowPc: true }
  },
  {
    name: 'HostLandingLeadAds',
    path: '/host/land/lead_ads',
    component: PageHostLandingLeadAds,
    meta: { hideHeader: true, hideFooter: true, hideSidebar: true, allowPc: true }
  },
  // {
  //   name: "Shop",
  //   path: "shop",
  //   component: () => import("@/components/pages/v2/Shop.vue"),
  //   meta: { headerType: "more-depth", title: "이용권 구매", hideChannelTalk: true },
  //   props: true,
  //   children: [
  //     { name: "ShopDetail", path: ':id', component: () => import("@/components/pages/v2/ShopDetail.vue"), meta: { headerType: "more-depth", title: "이용권 구매", hideChannelTalk: true }}
  //   ]
  // },
  {
    name: 'Purchase',
    path: 'purchase/:order_id',
    component: () => import('@/components/pages/v2/Purchase.vue'),
    props: true,
    meta: { headerType: 'more-depth', title: '결제 정보', hideChannelTalk: true }
  },
  {
    name: 'PurchaseComplete',
    path: 'purchase/:order_id/complete',
    component: () => import('@/components/pages/v2/PurchaseComplete.vue'),
    props: true,
    meta: { headerType: 'more-depth', title: '', hideChannelTalk: true }
  },
  {
    name: 'PurchasePayMethods',
    path: 'purchase/:order_id/pay-methods',
    component: PageReservePayMethods,
    props: true,
    meta: {
      headerType: 'more-depth',
      title: '결제수단 선택',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      level: 1,
      reserve_form: true
    }
  },
  {
    name: 'PurchaseAddPayMethod',
    path: 'purchase/:order_id/pay-methods/add',
    component: PageReserveAddPayMethod,
    props: true,
    meta: {
      headerType: 'more-depth',
      title: '카드정보 입력',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      level: 1,
      reserve_form: true
    }
  },
  {
    name: 'SamsungHostLanding',
    path: '/samsungHostLanding',
    component: PageHostLanding,
    meta: { hideFooter: true }
  },
  { name: 'HanaHostLanding', path: '/hanaHostLanding', component: PageHostLanding, meta: { hideFooter: true } },
  {
    name: 'RollersLAB',
    path: '/RollersLAB',
    component: PageAffiliateCompany,
    meta: { hideFooter: true, inviteCode: 'LG72WH' }
  },
  { name: 'HostGuide', path: '/hostGuide', component: PageHostGuide, meta: { hideFooter: true } },
  { name: 'HostEvent', path: '/hostevent', component: PageHostEvent, meta: { hideFooter: true } },
  { name: 'IezLanding', path: '/iezLanding', component: PageIezLanding, meta: { hideFooter: true } },
  { name: 'Iez', path: '/iez', component: PageIez, meta: { hideFooter: true, headerType: 'iez' } },

  {
    name: 'AuthLogin',
    path: '/login',
    component: PageAuthLogin,
    props: true,
    meta: {
      guest: true,
      hideHeader: true,
      hideSidebar: true,
      hideFooter: true,
      hideChannelTalk: true,
      allowPc: true
    }
  },
  {
    name: 'AuthLoginForExistingUsers',
    path: '/login/existingusers',
    component: PageAuthLoginForExistingUsers,
    props: true,
    meta: { guest: false, hideHeader: true, hideSidebar: true, hideFooter: true, hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'Auth',
    path: '/auth',
    redirect: '/auth/terms',
    component: () => import('@/components/pages/v2/AuthRegister.vue'),
    meta: {
      guest: true,
      headerType: 'more-depth',
      hideSidebar: true,
      hideFooter: true,
      hideChannelTalk: true,
      allowPc: true
    },
    children: [
      {
        name: 'AuthRegisterTerms',
        path: 'terms',
        component: () => import('@/components/pages/v2/AuthRegisterTerms.vue'),
        meta: {
          guest: true,
          headerType: 'more-depth',
          hideSidebar: true,
          hideFooter: true,
          hideChannelTalk: true,
          allowPc: true
        }
      },
      {
        name: 'AuthRegisterEmail',
        path: 'email',
        component: () => import('@/components/pages/v2/AuthRegisterEmail.vue'),
        meta: {
          guest: true,
          headerType: 'more-depth',
          hideSidebar: true,
          hideFooter: true,
          hideChannelTalk: true,
          allowPc: true
        }
      },
      {
        name: 'AuthRegisterPhone',
        path: 'phone',
        component: () => import('@/components/pages/v2/AuthRegisterPhone.vue'),
        meta: {
          guest: true,
          headerType: 'more-depth',
          hideSidebar: true,
          hideFooter: true,
          hideChannelTalk: true,
          allowPc: true
        }
      },
      {
        name: 'AuthRegisterPhoneComplete',
        path: 'register/complete',
        component: () => import('@/components/pages/v2/AuthRegisterPhoneComplete.vue'),
        meta: {
          // guest: true,
          headerType: 'none',
          hideSidebar: true,
          hideFooter: true,
          hideChannelTalk: true,
          allowPc: true
        }
      }
    ]
  },
  {
    name: 'AuthSendResetPassword',
    path: '/reset_password/send',
    component: isTouchDevice ? () => import('@/components/pages/v2/AuthSendResetPassword.vue') : PageAuthSendResetPassword,
    meta: isTouchDevice
      ? {
          headerType: 'more-depth',
          hideSidebar: true,
          hideFooter: true,
          hideChannelTalk: true,
          guest: true,
          allowPc: true
        }
      : { guest: true, allowPc: true }
  },
  {
    name: 'AuthResetPassword',
    path: '/reset_password',
    component: PageAuthResetPassword,
    meta: { guest: true, allowPc: true }
  },
  { name: 'AuthDelete', path: '/delete', component: PageAuthDelete, meta: { level: 1, allowPc: true } },
  {
    name: 'AuthFacebookCallback',
    path: '/auth/facebook/callback',
    component: PageAuthFacebookCallback,
    meta: { allowPc: true }
  },
  {
    name: 'AuthGoogleCallback',
    path: '/auth/google/callback',
    component: PageAuthGoogleCallback,
    meta: { allowPc: true }
  },
  {
    name: 'AuthInstagramCallback',
    path: '/auth/instagram/callback',
    component: PageAuthInstagramCallback,
    meta: { guest: true, allowPc: true }
  },
  {
    name: 'AuthNaverCallback',
    path: '/auth/naver/callback',
    component: PageAuthNaverCallback,
    meta: { guest: true, allowPc: true, headerType: 'none', footerType: 'none' }
  },
  {
    name: 'AuthWechatCallback',
    path: '/auth/wechat/callback',
    component: PageAuthWechatCallback,
    meta: { guest: true, allowPc: true }
  },
  {
    name: 'AuthAppleCallback',
    path: '/auth/apple/callback',
    component: PageAuthAppleCallback,
    meta: { guest: true, allowPc: true }
  },
  {
    name: 'PlaceSearch',
    path: '/stores/places',
    component: () => import('@/components/pages/v2/PlaceSearch.vue'),
    meta: {
      hideHeader: true,
      hideSidebar: true,
      hideFooter: true,
      reserve_form: true,
      hideChannelTalk: isTouchDevice
    }
  },
  {
    name: 'StoreSingle',
    path: '/stores/:store_id',
    component: () => import('@/components/pages/store/StoreSingle.vue'),
    props: true,
    meta: isTouchDevice
      ? {
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          reserve_form: true,
          hideChannelTalk: isTouchDevice,
          allowPc: true
        }
      : { hideFooter: true, hideSidebar: true, reserve_form: true, hideChannelTalk: isTouchDevice, allowPc: true }
  },
  {
    name: 'QrCodeCallback',
    path: '/qr-code/callback',
    component: PageQrCodeCallback,
    props: true,
    meta: { hideHeader: true, hideFooter: true, hideSidebar: true, hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'Reserve',
    path: '/reserve',
    component: PageReserve,
    props: true,
    meta: {
      headerType: 'more-depth',
      hideChannelTalk: true,
      hideSidebar: true,
      hideFooter: true,
      level: 1,
      reserve_form: true
    },
    children: [
      {
        name: 'ReserveOrder',
        path: ':order_id',
        component: PageReserveOrder,
        props: true,
        meta: {
          headerType: 'more-depth',
          hideChannelTalk: true,
          hideSidebar: true,
          hideFooter: true,
          level: 1,
          reserve_form: true
        }
      },
      {
        name: 'LockerReserve',
        path: 'locker/:order_id',
        component: () => import('@/components/pages/locker/LockerReserve.vue'),
        props: true,
        meta: {
          headerType: 'more-depth',
          hideChannelTalk: true,
          hideSidebar: true,
          hideFooter: true,
          level: 1,
          reserve_form: true
        }
      },
      {
        name: 'ReservePayMethods',
        path: ':order_id/pay-methods',
        component: PageReservePayMethods,
        props: true,
        meta: {
          headerType: 'more-depth',
          title: '결제수단 선택',
          hideChannelTalk: true,
          hideSidebar: true,
          hideFooter: true,
          level: 1,
          reserve_form: true
        }
      },
      {
        name: 'ReserveAddPayMethod',
        path: ':order_id/pay-methods/add',
        component: PageReserveAddPayMethod,
        props: true,
        meta: {
          headerType: 'more-depth',
          title: '카드정보 입력',
          hideChannelTalk: true,
          hideSidebar: true,
          hideFooter: true,
          level: 1,
          reserve_form: true
        }
      }
    ]
  },
  {
    name: 'StoreDelivery',
    redirect: '/stores/:store_id/delivery/step0',
    path: '/stores/:store_id/delivery',
    component: PageStoreDelivery,
    props: true,
    meta: { headerType: 'back', hideFooter: true },
    children: [
      {
        name: 'StoreDeliveryStep0',
        path: '/stores/:store_id/delivery/step0',
        component: PageStoreDeliveryStep0,
        props: true,
        meta: { level: 1, headerType: 'back', hideSidebar: true, hideFooter: true }
      },
      {
        name: 'StoreDeliveryStep1',
        path: '/stores/:store_id/delivery/step1',
        component: PageStoreDeliveryStep1,
        props: true,
        meta: { level: 1, headerType: 'back', hideSidebar: true, hideFooter: true }
      }
    ]
  },
  {
    name: 'StoreDeliveryStep2',
    path: '/stores/delivery/step2',
    component: PageStoreDeliveryStep2,
    meta: { level: 1, headerType: 'back', hideSidebar: true, hideFooter: true }
  },
  {
    name: 'StoreDeliveryStep3',
    path: '/stores/delivery/step3',
    component: PageStoreDeliveryStep3,
    meta: { level: 1, headerType: 'back', hideSidebar: true, hideFooter: true }
  },
  {
    name: 'StoreDeliveryStep4',
    path: '/stores/delivery/step4',
    component: PageStoreDeliveryStep4,
    meta: { level: 1, headerType: 'back', hideSidebar: true, hideFooter: true }
  },
  {
    name: 'Cs',
    path: 'cs',
    component: () => import('@/components/pages/cs/Cs.vue'),
    meta: { headerType: 'more-depth', title: '고객센터', hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'CsPolicy',
    path: 'cs/policy',
    component: () => import('@/components/pages/cs/CsPolicy.vue'),
    meta: { headerType: 'more-depth', title: '약관 및 정책', hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'CsPrivacyPolicy',
    path: '/cs/privacy-policy',
    component: PageCsPrivacyPolicy,
    meta: { title: '개인정보 처리방침', hideChannelTalk: true, allowPc: true, hideSidebar: !isTouchDevice }
  },
  {
    name: 'CsPrivacyPolicyTheThird',
    path: '/cs/privacy-policy-the-third',
    meta: { title: '개인정보 제3자 제공', hideChannelTalk: true },
    component: () => import('@/components/pages/cs/CsPrivacyPolicyTheThird')
  },
  {
    name: 'CsTermsOfUse',
    path: '/cs/terms-of-use',
    component: PageCsTermsOfUse,
    meta: { hideSidebar: !isTouchDevice, allowPc: true }
  },
  { name: 'CsFaq', path: '/cs/faq', component: PageCsFaq, meta: { hideSidebar: !isTouchDevice, allowPc: true } },
  // {
  //   name: 'CsNotices',
  //   path: '/cs/notices',
  //   component: PageCsNotices,
  //   meta: { headerType: 'more-depth', title: '공지사항', hideChannelTalk: true }
  // },
  // {
  //   name: 'CsNoticesSingle',
  //   path: '/cs/notices/:id',
  //   component: PageCsNoticesSingle,
  //   meta: { headerType: 'more-depth', title: '공지사항', hideChannelTalk: true },
  //   props: true
  // },
  {
    name: 'CsGuide',
    path: '/cs/guide',
    component: PageCsGuide,
    meta: { hideSidebar: !isTouchDevice, allowPc: true }
  },
  { name: 'CsHost', path: '/cs/host', component: PageCsHost, meta: { level: 1, nohost: true } },

  {
    name: 'MypageCoupons',
    path: 'mypage/coupons',
    component: PageMypageCoupons,
    meta: {
      hideFooter: true,
      level: 1,
      fetchData: true,
      headerType: 'more-depth',
      title: '이용권/쿠폰 내역',
      hideChannelTalk: true,
      allowPc: true
    }
  },
  {
    name: 'Usage',
    path: '/usage',
    component: () => import('@/components/pages/landings/MobileLandingTickets.vue'),
    meta: { fetchData: true, hideHeader: true, hideChannelTalk: true, allowPc: true }
  },
  {
    name: 'TicketSingle',
    path: '/myReservation/tickets/:id',
    component: () => import('@/components/pages/v2/TicketSingle.vue'),
    props: true,
    meta: { hideHeader: true, hideFooter: true, hideChannelTalk: true, allowPc: true, level: 1 }
  },

  {
    name: 'Mypage',
    path: '/mypage',
    component: PageMypage,
    redirect: '/mypage/profile',
    children: [
      {
        name: 'MypageInterestStores',
        path: 'interests',
        component: PageMypageInterestStores,
        meta: Object.assign({ level: 1, allowPc: true })
      },
      {
        name: 'MypageProfile',
        path: 'profile',
        component: PageMypageProfile,
        meta: Object.assign({ level: 1, allowPc: true })
      },
      {
        name: 'MypageChangePassword',
        path: 'change-password',
        component: PageMypageChangePassword,
        meta: { allowPc: true }
      }
    ],
    meta: Object.assign({ level: 1, allowPc: true })
  },
  {
    name: 'MypageCoupons',
    path: 'mypage/coupons',
    component: PageMypageCoupons,
    meta: {
      hideFooter: true,
      level: 1,
      fetchData: true,
      headerType: 'more-depth',
      title: '이용권/쿠폰 내역',
      hideChannelTalk: true
    }
  },

  {
    name: 'ShareTicketSingle',
    path: '/tickets/:code/shares',
    component: () => import('@/components/pages/v2/InvitedTicketSingle.vue'),
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true }
  },
  {
    name: 'MyReservation',
    path: '/myReservation',
    component: PageMyReservation,
    redirect: '/myReservation/tickets',
    children: [
      {
        name: 'MypageTickets',
        path: '/myReservation/tickets',
        component: PageMypageTickets,
        props: true,
        meta: { level: 1 }
      },
      {
        name: 'TicketSingle',
        path: '/myReservation/tickets/:id',
        component: isTouchDevice ? PageMypageTicketSingleV2 : PageMypageTicketSingle,
        props: true,
        meta: { hideHeader: true, hideSidebar: true, hideFooter: true, hideChannelTalk: true, level: 1 }
      }
    ],
    meta: { level: 1 }
  },
  {
    name: 'TicketSingleReceipt',
    path: '/myReservation/tickets/:id/receipt',
    component: PageTicketSingleReceipt,
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true, hideChannelTalk: true, level: 1 }
  },
  {
    name: 'CancelTicket',
    path: '/tickets/:id/cancel',
    component: PageCancelTicket,
    props: true,
    meta: { headerType: 'more-depth', title: 'cancel reservation', hideChannelTalk: true }
  },
  {
    name: 'DeliveryStatus',
    path: '/myReservation/tickets/:id/delivery/status',
    component: PageDeliveryStatus,
    props: true,
    meta: { headerType: 'back', hideSidebar: true, hideFooter: true }
  },
  {
    name: 'Host',
    path: '/host',
    component: PageHost,
    children: [
      {
        name: 'HostProfile',
        path: 'profile',
        component: () => import('@/components/pages/host/HostPageProfile'),
        meta: { level: 1, host: true, hideHeader: true, hideFooter: true, hideSidebar: true, allowPc: true }
      },
      {
        name: 'HostCheck',
        path: 'check',
        component: PageHostCheck,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostTickets',
        path: 'tickets',
        component: PageHostTickets,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostTicketSingle',
        path: 'tickets/:code',
        component: PageHostTicketSingle,
        props: true,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostStores',
        path: 'stores',
        component: PageHostStores,
        children: [],
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostStoreCreate',
        path: 'stores/create',
        component: PageHostStoreCreate,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostStoreEdit',
        path: 'stores/:id/edit',
        component: PageHostStoreEdit,
        props: true,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostNotices',
        path: 'notices',
        component: PageHostNotices,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostNoticesSingle',
        path: 'notices/:id',
        component: PageHostNoticesSingle,
        props: true,
        meta: {
          level: 1,
          host: true,
          hideHeader: true,
          hideFooter: true,
          hideSidebar: true,
          allowPc: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostSettlements',
        path: 'settlements',
        component: () => import('@/components/pages/host/settlement/HostSettlements.vue'),
        props: true,
        meta: {
          level: 1,
          host: true,
          allowPc: true,
          host: true,
          hideHeader: true,
          hideSidebar: true,
          subpageHeaderVisible: true
        }
      },
      {
        name: 'HostSettlementRequest',
        path: 'settlements/request',
        component: () => import('@/components/pages/host/settlement/HostSettlementRequest.vue'),
        props: true,
        meta: {
          level: 1,
          host: true,
          allowPc: true,
          host: true,
          hideHeader: true,
          hideSidebar: true
        }
      },
      {
        name: 'HostSettlementAmount',
        path: 'settlements/amountcheck',
        component: () => import('@/components/pages/host/settlement/HostSettlementAmount.vue'),
        props: true,
        meta: {
          level: 1,
          host: true,
          allowPc: true,
          host: true,
          hideHeader: true,
          hideSidebar: true
        }
      },
      {
        name: 'HostSettlementDetail',
        path: 'settlements/:id',
        component: () => import('@/components/pages/host/settlement/HostSettlementDetail.vue'),
        props: true,
        meta: {
          level: 1,
          host: true,
          allowPc: true,
          host: true,
          hideHeader: true,
          hideSidebar: true
        }
      },
      {
        name: 'ListsForHostSettlement',
        path: 'settlements/listsforhostsettlement',
        component: () => import('@/components/pages/host/settlement/ListsForHostSettlement.vue'),
        props: true,
        meta: {
          level: 1,
          host: true,
          allowPc: true,
          host: true,
          hideHeader: true,
          hideSidebar: true
        }
      }
    ],
    meta: { level: 1, host: true, hideHeader: true, hideFooter: true, hideSidebar: true, allowPc: true }
  },

  // {name: "HostApply", path: "/host/apply", component: PageHostApply, meta: {hideHeader: true, hideSidebar: true, hideFooter: true}},
  // {
  //   name: 'IncheonSurvey',
  //   path: '/survey/incheon',
  //   component: PageIncheonSurvey,
  //   meta: { hideHeader: true, hideSidebar: true, hideFooter: true, allowPc: true }
  // },
  { name: 'AppDownload', path: '/app-download', component: () => import('@/components/pages/app/AppDownload.vue') },
  {
    name: 'SetHostStatusCallback',
    path: '/host/:id/set-status/callback',
    component: PageHostSetStatusCallback,
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true, allowPc: true }
  },
  {
    name: 'SetStoreStatusCallback',
    path: '/stores/:id/set-status/callback',
    component: PageStoreSetStatusCallback,
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true, allowPc: true }
  },
  {
    name: 'ImageDownload',
    path: '/image-download',
    component: () => import('@/components/pages/_/ImageDownload.vue'),
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true, allowPc: true }
  },
  {
    name: 'Redirect',
    path: 'r/:code',
    component: () => import('@/components/pages/redirect/Redirect.vue'),
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true, allowPc: true }
  },
  {
    name: 'RedirectTicketList',
    path: 'r-tickets',
    component: () => import('@/components/pages/redirect/RedirectTicketList.vue'),
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true, allowPc: true }
  },
  {
    name: 'PageConnect',
    path: 'connect/:fullPath',
    component: () => import('@/components/pages/connect/PageConnect.vue'),
    props: true,
    meta: { hideHeader: true, hideSidebar: true, hideFooter: true }
  },
  {
    name: 'More',
    path: 'more',
    component: () => import('@/components/pages/landings/MobileLandingMore'),
    meta: { hideHeader: true, hideChannelTalk: true },
    children: [
      {
        name: 'MoreTickets',
        path: '/more/tickets',
        component: () => import('@/components/pages/v2/MoreTickets.vue'),
        meta: { headerType: 'more-depth', title: '이용내역', hideChannelTalk: true }
      },
      {
        name: 'MoreCoupons',
        path: '/more/coupons',
        component: () => import('@/components/pages/v2/MoreCoupons'),
        meta: { headerType: 'more-depth', title: '쿠폰', hideChannelTalk: true }
      },
      {
        name: 'MorePolicy',
        path: '/more/policy',
        component: () => import('@/components/pages/v2/MorePolicy'),
        meta: { headerType: 'more-depth', title: '약관 및 정책', hideChannelTalk: true }
      },
      {
        name: 'MoreGuides',
        path: '/more/guides',
        component: () => import('@/components/pages/v2/MoreGuides'),
        meta: { headerType: 'more-depth', title: '이용안내', hideChannelTalk: true }
      },
      {
        name: 'MoreLanguage',
        path: '/more/language',
        component: () => import('@/components/pages/v2/MoreLanguage'),
        meta: { headerType: 'more-depth', title: '언어 설정', hideChannelTalk: true }
      },
      {
        name: 'MoreBillings',
        path: '/billings',
        component: () => import('@/components/pages/v2/MoreBillings'),
        meta: {
          hideFooter: true,
          level: 1,
          fetchData: true,
          headerType: 'more-depth',
          title: 'Payment Card',
          hideChannelTalk: true
        }
      },
      {
        name: 'MoreBillingAddPayMethod',
        path: 'billings/add',
        component: () => import('@/components/pages/v2/ReserveAddPayMethod.vue'),
        props: true,
        meta: {
          headerType: 'more-depth',
          title: '카드정보 입력',
          hideChannelTalk: true,
          hideSidebar: true,
          hideFooter: true,
          level: 1
        }
      }
    ]
  },

  {
    name: 'Stores',
    path: '/stores',
    component: () => import('@/components/pages/store/Stores.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true,
      reserve_form: true,
      mapVisible: true,
      allowPc: true,
      statusBar: {
        statusBar: {
          'status-bar-style': 'light',
          'background-color': '#000000'
        }
      }
    }
  },
  {
    name: 'StoreSingle',
    path: '/stores/:store_id',
    component: () => import('@/components/pages/store/StoreSingle.vue'),
    props: true,
    meta: { hideHeader: true, hideFooter: true, hideSidebar: true, hideChannelTalk: true, reserve_form: true, allowPc: true }
  }
];
