<template>
  <div class="page" id="share-ticket-page">
    <div class="container">
      <div class="page-body">
        <router-view />
      </div>

    </div>
  </div>
</template>

<script>
import SiteHeader from "@/components/outline/Header";

export default {
}
</script>
