<template>
  <div id="reserve-order" :class="`page ${$route.meta.headerType}`">
    <div class="container">
      <div class="page-header">
        <h1 class="title">{{ $__t("보관정보") }}</h1>
        <!-- <p class="secondary">{{ $__t('{reserve-order}.header.secondary') }}</p> -->
        <div class="reserve-info">
          <div class="reserve-info__content">
            <h4 class="content-header">
              {{ order && order.product_data ? order.product_data.store_name : "" }}
            </h4>
            <p class="content-body" v-html="usageTimeText" />
          </div>
          <button
            type="button"
            @click="openRef('condition-modal')"
            class="reserve-info__button"
          >
            {{ $__t("예약변경") }}
          </button>
        </div>
      </div>
      <div class="page-body">
        <template v-if="order && order.product_data">
          <ui-form
            ref="form"
            @submit="submitForm"
            @error="scrollToError"
            :formData="formData"
          >
            <div class="section booker">
              <div class="section-header">
                <h2>{{ $__t("Drop Off by") | capitalizeFirstLetter }}</h2>
              </div>
              <div class="section-body">
                <ui-form-list>
                  <!--성명-->
                  <ui-form-list-item>
                    <lug-text-input
                      :placeholder="formData.user_name.placeholder"
                      :type="formData.user_name.type"
                      :label="formData.user_name.label"
                      :error="formData.user_name.error"
                      v-model="formData.user_name.value"
                      :required="true"
                      :readonly="true"
                    />
                  </ui-form-list-item>
                  <!--휴대폰번호-->
                  <ui-form-list-item>
                    <div class="lug-phone">
                      <label>
                        {{ formData.user_phone.label }}
                        <span style="color: red"> * </span>
                        <div class="body">
                          <lug-select
                            :placeholder="formData.user_dial_code.placeholder"
                            :type="formData.user_dial_code.type"
                            :items="formData.user_dial_code.items"
                            v-model="formData.user_dial_code.value"
                            :disabled="true"
                          />
                          <lug-text-input
                            :placeholder="formData.user_phone.placeholder"
                            :type="formData.user_phone.type"
                            :inputmode="formData.user_phone.input_mode"
                            v-model="formData.user_phone.value"
                            :mask="formData.user_phone.mask"
                            :readonly="true"
                          />
                        </div>
                      </label>
                      <div
                        class="required"
                        style="color: rgb(230, 20, 20); font-size: 0.875rem"
                      >
                        {{ formData.user_phone.error }}
                      </div>
                    </div>
                  </ui-form-list-item>
                  <!--이메일-->
                  <!-- <ui-form-list-item>
                    <lug-text-input
                      :placeholder="formData.user_email.placeholder"
                      :type="formData.user_email.type"
                      :label="formData.user_email.label"
                      :error="formData.user_email.error"
                      v-model="formData.user_email.value"
                      :required="true"
                    />
                  </ui-form-list-item> -->
                </ui-form-list>
              </div>
            </div>

            <div class="section pay-method">
              <div class="section-header">
                <h2>{{ $__t("결제수단 선택") | capitalizeFirstLetter }}</h2>
              </div>
              <div class="section-body">
                <div class="method-area">
                  <dl
                    v-if="order.promotion && order.promotion.type === 'pass'"
                    @click="
                      $router.push({
                        name: 'ReservePayMethods',
                        params: { ...$route.params },
                      })
                    "
                  >
                    <dt>
                      {{ $__t("프리패스") + " " + order.promotion_pass_code }}
                    </dt>
                    <dd>
                      <button type="button" class="icon">
                        <img src="/img/arrow-right@3x.png" class="arrow" />
                      </button>
                    </dd>
                  </dl>
                  <dl
                    v-else
                    v-button
                    @click="
                      $router.push({
                        name: 'ReservePayMethods',
                        params: { ...$route.params },
                      })
                    "
                  >
                    <dt :class="{ required: !formData.pay_method.value }">
                      <template v-if="formData.pay_method.value">
                        {{
                          parseCardName(formData.pay_method.value.card_name) +
                          "   " +
                          formData.pay_method.value.card_number.substring(
                            formData.pay_method.value.card_number.length - 4
                          )
                        }}
                      </template>
                      <template v-else>
                        {{ $__t("{reserve-order}.pay_method.required") }}</template
                      >
                    </dt>
                    <dd>
                      <span class="text">
                        <template v-if="order.promotion && order.promotion.promotion_id">
                          <template v-if="order.promotion.promotion_type === 'coupon'">
                            {{ coupon && coupon.coupon_name ? coupon.coupon_name : "" }}
                          </template>
                          <template
                            v-else-if="order.promotion.promotion_type === 'discount-code'"
                          >
                            {{ order.promotion.discount_code }}
                          </template>
                        </template>
                        <template v-else-if="formData.pay_method.value">
                          {{ $__t("쿠폰") | capitalizeFirstLetter }}·{{ $__t("코드") }}
                        </template>
                      </span>
                      <button type="button" class="icon">
                        <img src="/img/arrow-right@3x.png" class="arrow" />
                      </button>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="section price">
              <div class="section-header">
                <h2>
                  {{ $__t("{check-out-info}.payment_amount") | capitalizeFirstLetter }}
                </h2>
              </div>
              <div class="section-body">
                <div class="price-section">
                  <dl>
                    <dt class="title">
                      {{
                        $__t(
                          order.product_data.store_type === "LOCKER"
                            ? "기본요금"
                            : "{reserve-order}.usage.fee"
                        ) | capitalizeFirstLetter
                      }}
                    </dt>
                    <dd class="description">
                      <span class="color--blue"
                        >{{ originAmount }} {{ $__t("won") }}</span
                      >
                    </dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">
                      {{
                        $__t("{estimated-price}.section.list.key.BASIC_FEE")
                          | capitalizeFirstLetter
                      }}
                    </dt>
                    <dd class="description">
                      {{ totalBasicPrice.toLocaleString() }} {{ $__t("won") }}
                    </dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">
                      {{ $__t("Additional Charge") | capitalizeFirstLetter }}
                    </dt>
                    <dd class="description">
                      {{
                        totalAdditionalPrice > 0
                          ? "+ " + totalAdditionalPrice.toLocaleString()
                          : 0
                      }}
                      {{ $__t("won") }}
                    </dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">
                      {{ $__t("쿠폰") | capitalizeFirstLetter }}·{{ $__t("코드") }}
                    </dt>
                    <dd class="description">
                      {{ discountAmount > 0 ? "-" + discountAmount.toLocaleString() : 0 }}
                      {{ $__t("won") }}
                    </dd>
                  </dl>
                  <dl class="secondary">
                    <dt class="title">
                      {{ $__t("이용권 사용 금액") | capitalizeFirstLetter }}
                    </dt>
                    <dd class="description">
                      {{ order.promotion.type === "pass" ? "- " + discountAmount : 0 }}
                      {{ $__t("won") }}
                    </dd>
                  </dl>
                </div>
                <!-- <div class="price-section">
                  <dl>
                    <dt class="title">
                      {{ $__t('deposit') | capitalizeFirstLetter }}
                      <button
                        type="button"
                        @click="
                          $analytics.logEvent('show deposit info');
                          openRef('deposit-modal');
                        "
                        class="title__button"
                        v-button
                      >
                        <img src="/img/question.svg " />
                      </button>
                    </dt>
                    <dd class="description">
                      <span class="color--blue">+ 5,000 {{ $__t('won') }}</span>
                    </dd>
                  </dl>
                </div> -->
                <div class="price-section">
                  <dl class="main-item">
                    <dt class="title">
                      {{
                        (order.product_data.store_type === "LOCKER"
                          ? $__t("예상 이용 금액")
                          : $__t("{reserve-order}.reserve.payment.total"))
                          | capitalizeFirstLetter
                      }}
                    </dt>
                    <dd class="description">
                      <span class="color--blue"
                        ><strong>{{ predictedAmount }}</strong> {{ $__t("won") }}</span
                      >
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="section footer">
              <!--이용안내-->
              <dl class="footer-item">
                <dt class="title">
                  <img src="/img/icon/emoji-pouch.svg" />
                  <span class="title__text">{{ $__t("이용 안내") }}</span>
                </dt>
                <dd
                  class="description"
                  v-html="$__t('{reserve-order}.notice.guide.description.new')"
                />
              </dl>
              <!--티켓만료-->
              <dl class="footer-item">
                <dt class="title">
                  <img src="/img/icon/emoji-ticket.svg" />
                  <span class="title__text">{{
                    $__t("{reserve-order}.notice.expired_ticket.title")
                  }}</span>
                </dt>
                <dd
                  class="description"
                  v-html="$__t('{reserve-order}.notice.expired_ticket.description.new')"
                ></dd>
              </dl>
              <!--notice-->
              <dl class="footer-item">
                <dt class="title">
                  ⛔️<span class="title__text">{{
                    $__t("{reserve-order}.notice.payorcancel.title")
                  }}</span>
                </dt>
                <dd
                  class="description"
                  v-html="$__t('{reserve-order}.notice.payorcancel.description')"
                ></dd>
              </dl>
              <div class="footer-item footer-item--transparent">
                {{ $__t("위 주문 내용을 확인 하였으며, 회원 본인은 결제에 동의합니다.") }}
              </div>
            </div>

            <div class="section condition">
              <div class="section-header">
                <h3>
                  {{ $__t("구매조건 확인 및 결제대행 서비스 약관 동의") }}
                </h3>
                <button
                  v-button
                  type="button"
                  class="text underline"
                  @click="openRef('modalTerms')"
                >
                  <span class="text">{{ $__t("보기") }}</span>
                </button>
              </div>
            </div>

            <div class="button-area">
              <button type="submit" v-button class="activated">
                {{
                  $__t(
                    $route.query["order-type"] === "IMMEDIATE" ? "라커 배정" : "예약하기"
                  )
                }}
              </button>
            </div>
          </ui-form>
        </template>
        <template v-else>
          <div class="section">
            <div v-for="i in 10" :key="`section-${i}`" class="section-body">
              <PuSkeleton :count="3" :loading="true" height="3rem" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <guide-modal id="condition-modal" ref="condition-modal" :transparent="true">
      <template v-slot:body>
        <Condition @close="closeRef('condition-modal')" />
      </template>
    </guide-modal>

    <guide-modal
      id="modal-terms"
      ref="modalTerms"
      :noCloseButton="true"
      :noBgClick="true"
      :external-close="true"
    >
      <template v-slot:body>
        <agree-terms :store_name="order.product_data.store_name" />
      </template>
    </guide-modal>

    <guide-modal id="deposit-modal" ref="deposit-modal">
      <template v-slot:body>
        <p class="content">
          서비스 이용을 위해 상점 예약 시 발생하는 금액입니다. 이용 완료 시 보증금은
          <strong>전액 환급</strong>됩니다.
        </p>
      </template>
    </guide-modal>

    <ui-slider
      id="notice-slider"
      ref="notice-slider"
      :bgClick="true"
      @close="orderAgreed = false"
    >
      <div class="notice-order">
        <h1 class="notice-order__header">
          <img src="/img/emoji/both-hands.svg" class="header-image" alt="both hands" />
          <div class="header-text" v-html="$__t('{notice-order}.header')" />
        </h1>
        <div class="notice-order__body">
          <div class="notice-order__alert" v-html="$__t('{notice-order}.alert')" />
          <ul class="notice-order__list">
            <li v-html="$__t('{notice-order}.list.item.1')"></li>
            <li v-html="$__t('{notice-order}.list.item.2')"></li>
            <li v-html="$__t('{notice-order}.list.item.3')"></li>
          </ul>
        </div>
        <div class="notice-order__footer">
          <ui-checkbox
            v-model="orderAgreed"
            :label="$__t('{notice-order}.checkbox.label')"
            class="notice-order__check"
          />
          <button
            v-button
            type="button"
            class="ui-submit-button"
            @click.prevent="orderAgreed ? onSubmit() : submitForm()"
            :disabled="!orderAgreed"
            :class="{ activated: orderAgreed }"
          >
            <span class="ui-submit-button__text"> {{ $__t("예약하기") }} </span>
          </button>
        </div>
      </div>
    </ui-slider>
  </div>
</template>

<script>
import countryCodes from "@/assets/json/country-codes.json";
import Condition from "@/components/pages/store/StoresSearchCondition";
import AgreeTerms from "@/components/includes/ReserveOrder/AgreeTerms.vue";
import utils from "@/utils";
import UiSlider from "@/components/modules/Slider/Body.vue";
import _ from "lodash";

export default {
  name: "ReserveOrder",

  props: ["order_id"],

  data() {
    const AUTH_DATA = this.$store.state.auth;
    // console.log('AUTH_DATA', AUTH_DATA);
    return {
      reserveInfoVisible: false,

      formData: {
        user_name: {
          type: "text",
          label: this.$__t("성명"),
          // value: AUTH_DATA.user_name || AUTH_DATA.user_last_name + ' ' + AUTH_DATA.user_first_name,
          value: AUTH_DATA.user_name,
          validation: [{ type: "required" }],
          error: "",
        },
        user_dial_code: {
          value: AUTH_DATA.user_dial_code || "+82",
          error: "",
          validation: [{ type: "required" }],
          items: this.parseCountryCodes(countryCodes),
          label: this.$__t("국가 번호"),
        },
        user_phone: {
          type: "text",
          label: this.$__t("휴대폰 번호"),
          placeholder: this.$__t("휴대폰 번호를 입력해주세요"),
          validation: [{ type: "required" }, { type: "number" }],
          input_mode: "number",
          error: "",
          value: AUTH_DATA.phone,
          mask: "###-####-####",
        },
        // user_email: {
        //   type: 'text',
        //   label: this.$__t('이메일 주소'),
        //   validation: [{ type: 'required' }, { type: 'email' }],
        //   value: AUTH_DATA.user_email,
        //   error: ''
        // },
        coupon_code: {
          value: "",
        },
        discount_code: {
          value: "",
        },
        pay_method: {
          items: { label: this.$__t("Credit Card"), value: "card" },
          value: this.primaryBilling,
          validation: [{ type: "required" }],
        },
      },

      duration: null,

      errors: [],

      listQuery: {
        payMethod: {
          page: 1,
          size: 10,
        },
      },

      orderAgreed: false,
    };
  },

  watch: {
    formData: {
      handler: _.debounce(function () {
        this.$store.commit("resourceForm/SET_ITEM", {
          data: {
            form_id: "reserve_form_state",
            user_name: this.formData.user_name.value,
            user_dial_code: this.formData.user_dial_code.value,
            user_phone: this.formData.user_phone.value,
            // user_email: this.formData.user_email.value
          },
        });
      }, 100),
      deep: true,
    },
    primaryBilling(value) {
      this.$data.formData.pay_method.value = value;
    },
  },

  computed: {
    reserveFormState() {
      const RESERVE_FORM_STATE = this.$store.getters["resourceForm/GET_ITEM"]({
        key: "form_id",
        value: "reserve_form_status",
      });
      this.formData.pay_method.value = RESERVE_FORM_STATE?.pay_method;

      return RESERVE_FORM_STATE;
    },

    order() {
      return this.$store.getters["orders/GET_ITEM"]({
        key: "reserve_id",
        value: this.order_id,
      });
    },

    coupon() {
      return this.$store.getters["resourceCoupons/GET_ITEM"]({
        key: "uid",
        value: this.formData.coupon_code.value.uid,
      });
    },

    billings() {
      return Object.keys(this.$store.state.billings.items)
        .map((o) => this.$store.state.billings.items[o])
        .sort((a, b) => {
          const A_CREATD_AT = this.$moment(a.created_at);
          const B_CREATD_AT = this.$moment(b.created_at);

          if (A_CREATD_AT.isBefore(B_CREATD_AT)) {
            return 1;
          }

          if (B_CREATD_AT.isBefore(A_CREATD_AT)) {
            return -1;
          }

          return 0;
        });
    },

    primaryBilling() {
      return this.billings.find((billing) => billing.is_primary > 0);
    },

    submitActivated() {
      return Object.keys(this.formData).every((o) => {
        if (this.formData[o]?.validation?.some((v) => v.type === "required")) {
          return !!this.formData[o].value;
        }

        return true;
      });
    },

    usageTimeText() {
      try {
        const momentStartDate = this.$moment(
          this.order?.product_data?.store_type === "LOCKER"
            ? new Date()
            : this.order.product_data.reserve_start_date_time
        );
        const momentEndDate = this.$moment(
          this.order?.product_data?.store_type === "LOCKER"
            ? this.$moment().add(1, "h")
            : this.order.product_data.reserve_end_date_time
        );

        let value = "";

        if (
          momentStartDate.format("YYYY-MM-DD") === this.$moment().format("YYYY-MM-DD")
        ) {
          if (
            momentStartDate.format("YYYY-MM-DD") === momentEndDate.format("YYYY-MM-DD")
          ) {
            value +=
              this.$__t("{date-time-picker}.today") +
              " " +
              momentStartDate.format("HH:mm") +
              " ~ " +
              momentEndDate.format("HH:mm");
          } else {
            value +=
              this.$__t("{date-time-picker}.today") +
              " " +
              momentStartDate.format("HH:mm") +
              " ~ " +
              momentEndDate.format("MM/DD HH:mm");
          }
        } else if (
          momentStartDate.format("YYYY-MM-DD") !== momentEndDate.format("YYYY-MM-DD")
        ) {
          value +=
            momentStartDate.format("MM/DD HH:mm") +
            " ~ " +
            momentEndDate.format("MM/DD HH:mm");
        } else {
          value +=
            momentStartDate.format("MM/DD HH:mm") + " ~ " + momentEndDate.format("HH:mm");
        }

        if (this.order?.product_data?.store_type === "LOCKER") {
          value = "보관 가능 시간: " + value;

          if (
            (this.order?.product_data?.order_type || this.$route.query["order-type"]) ===
            "DELAYED"
          ) {
            value = "기본 사용시간: 1시간";
          }

          value +=
            "<br/>" +
            "보관함 " +
            Number(this.order?.product_data?.locker_number || 0) +
            "번";
        } else {
          value += `${
            !!this.ticketQuantityText ? " <br/> " + this.ticketQuantityText : ""
          }`;
        }

        return value;
      } catch (e) {
        return null;
      }
    },

    ticketQuantityText() {
      try {
        let value = "";

        if (this.order.product_data.quantity_small > 0) {
          // let quantityString = `${this.$__t('{luggage-selector}.small')} ${this.order.product_data.quantity_small}`;
          let quantityString = `${this.$__t("물품")} ${
            this.order.product_data.quantity_small
          }`;

          if (this.order.product_data.quantity_small > 1) {
            value += quantityString + this.$__t("items");
          } else {
            value += quantityString + this.$__t("item");
          }
        }

        if (this.order.product_data.quantity_big > 0) {
          let quantityString = `${this.$__t("{luggage-selector}.big")} ${
            this.order.product_data.quantity_big
          }`;

          if (this.order.product_data.quantity_big > 0)
            quantityString = " " + quantityString;

          if (this.order.product_data.quantity_big > 1) {
            value += quantityString + this.$__t("items");
          } else {
            value += quantityString + this.$__t("item");
          }
        }

        return value;
      } catch (e) {
        return null;
      }
    },

    predictedAmount() {
      // return ((this.order?.predicted_amount || 0) + 5000).toLocaleString();
      return (this.order?.predicted_amount || 0).toLocaleString();
    },

    discountAmount() {
      return this.order?.payment?.discount || 0;
    },

    originAmount() {
      return (
        this.order?.payment?.origin?.toLocaleString() ||
        (
          this.totalBasicPrice +
          this.smallAdditionalPrice +
          this.largeAdditionalPrice
        ).toLocaleString() ||
        0
      );
    },

    smallBasicPrice() {
      // return parseInt(2000 * this.order?.reserve_quantity || 0);
      return parseInt(2500 * this.order?.reserve_quantity || 0);
    },

    largeBasicPrice() {
      return parseInt(3000 * this.order?.reserve_over_quantity || 0);
    },

    totalBasicPrice() {
      // return parseInt(2000 * this.order?.reserve_quantity + 3000 * this.order?.reserve_over_quantity || 0);
      return parseInt(2500 * this.order?.reserve_quantity || 0);
    },

    totalAdditionalPrice() {
      return this.smallAdditionalPrice + this.largeAdditionalPrice;
    },

    smallAdditionalPrice() {
      if (this.duration) {
        let durationMinutes = this.duration.asMinutes();
        let durationDays = this.duration.asDays();

        if (durationMinutes - 60 <= 0) {
          // 1시간 이전
          return 0;
        } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
          // 1시간 이후 7시간 30분 이전
          // return 100 * Math.ceil((durationMinutes - 60) / 10) * parseInt(this.order?.reserve_quantity || 0);
          return (
            150 *
            Math.ceil((durationMinutes - 60) / 10) *
            parseInt(this.order?.reserve_quantity || 0)
          );
        } else if (450 < durationMinutes && durationMinutes <= 1440) {
          //7시간 30분 초과 24시간 이하
          // return 6000 * parseInt(this.order?.reserve_quantity || 0) - this.smallBasicPrice;
          return (
            8500 * parseInt(this.order?.reserve_quantity || 0) - this.smallBasicPrice
          );
        } else if (1440 < durationMinutes) {
          //24시간 초과
          // return (6000 + Math.ceil(durationDays - 1) * 4000) * parseInt(this.order?.reserve_quantity || 0) - this.smallBasicPrice;
          return (
            (8500 + Math.ceil(durationDays - 1) * 5000) *
              parseInt(this.order?.reserve_quantity || 0) -
            this.smallBasicPrice
          );
        }
      }

      return 0;
    },

    largeAdditionalPrice() {
      if (this.duration) {
        let durationMinutes = this.duration.asMinutes();
        let durationDays = this.duration.asDays();

        if (durationMinutes - 60 <= 0) {
          // 1시간 이전
          return 0;
        } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
          // 1시간 이후 7시간 30분 이전
          return (
            150 *
            Math.ceil((durationMinutes - 60) / 10) *
            parseInt(this.order?.reserve_over_quantity || 0)
          );
        } else if (450 < durationMinutes && durationMinutes <= 1440) {
          //7시간 30분 초과 24시간 이하
          return (
            9000 * parseInt(this.order?.reserve_over_quantity || 0) - this.largeBasicPrice
          );
        } else if (1440 < durationMinutes) {
          //24시간 초과
          return (
            (9000 + Math.ceil(durationDays - 1) * 6000) *
              parseInt(this.order?.reserve_over_quantity || 0) -
            this.largeBasicPrice
          );
        }
      }

      return 0;
    },
  },

  created() {
    this.loadOrderData().then((res) => {
      if (Array.isArray(this.order?.tickets) && this.order.tickets.length > 0) {
        //이미 티켓이 발급된 주문인 경우.
        this.$router.replace("/");
      }

      if (this.order.product_data?.store_type === "LOCKER") {
        this.$router.replace({
          name: "LockerReserve",
          params: { order_id: this.order_id },
        });
        return;
      }

      this.duration = this.$moment.duration(
        Math.abs(
          this.$moment(this.order.reserve_date_start).diff(
            this.$moment(this.order.reserve_date_end)
          )
        )
      );
      this.setReserveFormState(res);

      this.getPayMethods()
        .then(() => this.loadFormStateData())
        .catch(() => {});

      this.onPassCode();
    });
  },

  mounted() {
    this.$analytics.logEvent("mobile reservation info view");
    // console.log('AUTH_DATA', this.AUTH_DATA);
  },

  beforeDestroy() {
    this.$store.commit("resourceForm/UPDATE_ITEM", {
      data: {
        form_id: "reserve_form",
        reserve_info_visible: false,
      },
    });
  },

  methods: {
    parseCountryCodes(countryCodes) {
      let _dial_code =
        countryCodes.map((o) => ({
          label: `${o.dial_code} (${o.code})`,
          value: o.dial_code,
        })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        if (o.name === "Korea, Republic of") {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        if (o.name === "Others") {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },

    async loadOrderData() {
      try {
        return await this.$store.dispatch("orders/reserve", {
          id: this.order_id,
        });
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: this.$__t(e.message),
          status: "error",
        });
        this.$store.commit("loading/SET_TRUE");
        setTimeout(() => {
          this.$router.replace("/stores");
          this.$store.commit("loading/SET_FALSE");
        }, 1000);
      }
    },

    async updateOrder(options = {}) {
      try {
        return await this.$store.dispatch("orders/update", options);
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      }
    },

    loadFormStateData() {
      let {
        user_name,
        user_dial_code,
        user_phone,
        user_email,
        coupon_code,
        discount_code,
        pay_method,
        reserve_info_visible,
      } = this.reserveFormState || {};

      try {
        this.formData.user_name.value = user_name || this.formData.user_name.value;
        this.formData.user_dial_code.value =
          user_dial_code || this.formData.user_dial_code.value;
        this.formData.user_phone.value = user_phone || this.formData.user_phone.value;
        // this.formData.user_email.value = user_email || this.formData.user_email.value;
        this.formData.coupon_code.value = coupon_code || this.formData.coupon_code.value;
        this.formData.discount_code.value =
          discount_code || this.formData.discount_code.value;
        this.formData.pay_method.value =
          pay_method || this.primaryBilling || this.formData.pay_method.value;
        this.reserveInfoVisible = reserve_info_visible || this.reserveInfoVisible;
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", {
          message: e.message,
          status: "error",
        });
      }
    },

    setReserveFormState(res) {
      return this.$store.commit("resourceForm/SET_ITEM", {
        data: {
          form_id: "reserve_form",
          store_id: res.product_id,
          date_start: res.reserve_date_start,
          date_end: res.reserve_date_end,
          quantity_small: parseInt(res.reserve_quantity) || 0,
          quantity_big: parseInt(res.reserve_over_quantity) || 0,
        },
      });
    },

    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    getErrors(error) {
      this.errorArray = [];

      return error.split(",").map((errorString) => {
        if (errorString.includes("INVALID_START_DATE"))
          return this.$__t("It is not opening time");
        if (errorString.includes("INVALID_END_DATE"))
          return this.$__t("It is not opening time");
        if (errorString.includes("CLOSED_START_DATE"))
          return this.$__t("{error-message}.closed.temporal");
        if (errorString.includes("CLOSED_END_DATE"))
          return this.$__t("{error-message}.closed.temporal");
        if (errorString.includes("CLOSED")) return this.$__t("OUT_OF_STOCK");
        if (errorString.includes("OUT_OF_STOCK")) return this.$__t("OUT_OF_STOCK");
        if (errorString.includes("OUT_OF_BIGABLE_STOCK"))
          return this.$__t("OUT_OF_STOCK");
      });
    },

    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll(
          ".ui-form-list-item div.error, .required"
        );
        if (errorList.length >= 0) {
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }

          $("html, body").animate({ scrollTop: offset }, 200);
        }
      }, 0);
    },

    onSubmit() {
      return this.$refs.form.submit();
    },

    openNoticeSlider() {
      return this.$refs["notice-slider"].open();
    },

    submitForm: _.debounce(function (values) {
      if (!this.orderAgreed) {
        this.$analytics.logEvent("reservation completed", {
          store_id: this.order?.product_id,
          store_name: this.order?.product_data?.store_name,
          store_location: this.order?.product_data?.store_place,
          discount_code:
            this.order?.promotion?.discount_code || this.formData?.discount_code?.value,
          coupon_code: this.coupon?.coupon_code,
          small: Number(this.order?.reserve_quantity || 0),
          large: Number(this.order?.reserve_over_quantity || 0),
          price: this.predictedAmount + "원",
        });
        return this.openNoticeSlider();
      }

      this.$store.commit("loading/SET_TRUE");

      try {
        let { pay_method, user_email, user_name, user_phone } = values;
        pay_method = utils.parseJSON(pay_method);

        const USER_DATA = {
          user_name,
          user_phone,
          user_email,
        };

        const type = this.order?.product_data?.store_type;

        let data = Object.assign(
          {
            reserve: JSON.stringify(USER_DATA),
            billing: JSON.stringify({
              billing_id: pay_method.uid,
              ...USER_DATA,
            }),
          },
          type === "LOCKER" ? {} : { issue_type: "DEPOSIT_ADVANCE" }
        );

        return this.$store
          .dispatch(`orders/complete`, {
            id: this.order_id,
            data,
            type: type === "LOCKER" ? "LOCKER" : "LUGGAGE",
            options: { clearGroup: true },
          })
          .then((response) => {
            this.$analytics.logEvent("reservation completed confirm", {
              store_id: this.order?.product_id,
              store_name: this.order?.product_data?.store_name,
              store_location: this.order?.product_data?.store_place,
              discount_code:
                this.order?.promotion?.discount_code ||
                this.formData?.discount_code?.value,
              coupon_code: this.coupon?.coupon_code,
              small: Number(this.order?.reserve_quantity || 0),
              large: Number(this.order?.reserve_over_quantity || 0),
              price: this.predictedAmount + "원",
            });
            this.$store.dispatch("auth/edit", USER_DATA);
            this.$forceUpdate();

            this.fetchTickets().finally(() =>
              this.$router.push({
                name: "TicketSingle",
                params: {
                  id:
                    response.ticket_code ||
                    (response.tickets && Array.isArray(response.tickets)
                      ? response.tickets[0].ticket_code
                      : response.ticket?.ticket_code),
                },
              })
            );
            this.$store.commit("resourceForm/UNSET_ITEM", {
              data: { form_id: "reserve_form_status" },
            });
          })
          .catch((error) =>
            this.$store.commit("alert/ADD_ITEM", {
              message: error,
              status: "error",
            })
          )
          .finally(() => {
            this.$store.commit("loading/SET_FALSE");
          });
      } catch (e) {
        console.error(e);
        this.$store.commit("loading/SET_FALSE");
        this.$store.commit("alert/ADD_ITEM", {
          message: JSON.stringify(e || {}),
          status: "error",
        });
      }
    }, 10),

    userData() {
      return {
        pay_method: this.$store.getters["userData/GET_ITEM"]({
          key: "pay_method",
        }),
        coupon_code: this.$store.getters["userData/GET_ITEM"]({
          key: "coupon_code",
        }),
        discount_code: this.$store.getters["userData/GET_ITEM"]({
          key: "discount_code",
        }),
        user_name: this.$store.getters["userData/GET_ITEM"]({
          key: "user_name",
        }),
        user_dial_code: this.$store.getters["userData/GET_ITEM"]({
          key: "user_dial_code",
        }),
        user_phone: this.$store.getters["userData/GET_ITEM"]({
          key: "user_phone",
        }),
        user_email: this.$store.getters["userData/GET_ITEM"]({
          key: "user_email",
        }),
      };
    },

    async getPayMethods() {
      try {
        return await this.$store.dispatch("billings/getCardList", {
          query: this.listQuery.payMethod,
        });
      } catch (e) {}
    },

    parseCardName(cardName = "") {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(cardName);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return cardName;
    },

    async fetchTickets(options = {}) {
      try {
        this.$store.commit("tickets/CLEAR_ITEMS");
        this.$store.commit("tickets/CLEAR_ALL_LIST");
        //clear all tickets

        const TICKETS_ALL = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_all",
          filter: "all",
          query: { page: 1, size: 10 },
        });

        const TICKETS_AVAILABLE = this.$store.dispatch("tickets/getList", {
          list: "MyTickets_available",
          filter: "available",
          query: { page: 1, size: 10 },
        });

        return await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE]);
      } catch (e) {
        //catch
      }
    },

    async onPassCode() {
      this.$store.commit("loading/SET_TRUE");
      const pass_code = this.$route.query["pass-code"];
      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE");
      }, 1000);

      if (pass_code) {
        return await this.updateOrder({
          id: this.order_id,
          start_date: this.order.product_data.reserve_start_date_time,
          end_date: this.order.product_data.reserve_end_date_time,
          quantity: this.order.product_data.quantity_small,
          over_quantity: this.order.product_data.quantity_big,
          pass_code: pass_code,
        });
      }
    },
  },

  components: {
    Condition,
    AgreeTerms,
    UiSlider,
  },
};
</script>
