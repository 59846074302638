<template>
  <div class="ticket-message" :class="{ 'ticket-message--activated': activated }">
    <div class="container">
      <div class="ticket-message__header">
        <div class="header__label">
          {{ $__t("이용권 안내") | toUpperCase }}
        </div>
        <div class="header__timestamp">
          {{ $__t(ticket.datetime_issued) | moment("HH:mm") }}
        </div>
      </div>

      <div class="ticket-message__body">
        <div class="message-container" :style="computedStyle">
          <div class="message-header">
            <div v-button class="message-header__text-area">
              {{ $__t("프리패스권") + " " + order.promotion_pass_code }}
            </div>
          </div>
          <div class="message-body">
            <div class="message-body__text-area">
              <ul class="message-text__list">
                <li class="message-text__list-item">
                  <div class="message-text__body">
										<span v-if="order.promotion && order.promotion.pass_type === 'DAY'">
											제주도 프리패스권 <strong>({{ order.promotion_pass_code }})은 첫 이용시작부터 이용일 24시까지 이용가능</strong>합니다. <br/> 그 이후에는 럭스테이 실시간 요금으로 책정되며 책정된 금액은 ‘물품 찾기'를 하시면 후불결제 됩니다.
										</span>
										<span v-else-if="order.promotion && order.promotion.pass_type === 'TIME'" >
											제주도 프리패스권 <strong>({{ order.promotion_pass_code }})은 첫 이용시작부터 24시간 이용가능</strong>합니다. <br/> 그 이후에는 럭스테이 실시간 요금으로 책정되며 책정된 금액은 ‘물품 찾기'를 하시면 후불결제 됩니다.
										</span>
									</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageIssued",

  props: {
    ticket: {
      type: Object,
      default: {},
    },

    order: {
      type: Object,
      default: {},
    },

    activated: {
      type: Boolean,
      default: false,
    },

    invitation: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  computed: {
    computedStyle() {
      return {
        'background': '#FFDAD9',
        'border': '1px solid #FF5555',
        'border-radius': '0.667rem'
      }
    }
  }
};
</script>
