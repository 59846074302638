<template>
  <div class="map-connect">
    <h1 class="map-connect__title">
      {{ $__t("지도") }}
    </h1>
    <ul class="map-connect__list">
      <li
        v-button
        class="map-connect__list-item"
        v-for="item in mapList"
        :key="item.name"
        @click="
          () => {
            item.action();
            $emit('close');
          }
        "
      >
        <img :src="item.icon" class="icon" :style="item.style" />

        <span class="text">
          {{ item.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["store"],
  name: "MapConnector",

  data() {
    return {
      mapList: [
        {
          id: "naver",
          name: "네이버 지도",
          icon: require("@/assets/icons/svg/naver.svg"),
          action: () => this.onClickNaverMap(),
        },
        {
          id: "google",
          name: "구글 지도",
          icon: require("@/assets/icons/svg/google.svg"),
          action: () => this.onClickGoogleMap(),
        },
        {
          id: "copy",
          name: "주소복사",
          icon: require("@/assets/icons/png/copy.png"),
          style: {
            width: "1.5rem",
            height: "1.5rem",
          },
          action: () => this.onClickCopy(),
        },
      ],
    };
  },

  methods: {
    openNaverMapInIphoneBrowser(url) {
      this.$store.commit("loading/SET_TRUE");

      window.location.href = url;

      setTimeout(() => {
        try {
          if (!document.hidden) {
            throw new Error("1102:NO_EXISTS_DATA");
          }
        } catch (e) {
          let confirmed = confirm(
            this.$__t("Naver map is not installed, so go to the App Store.")
          );

          if (confirmed) {
            location.href = "http://itunes.apple.com/app/id311867728?mt=8";
          }
        } finally {
          this.$store.commit("loading/SET_FALSE");
        }
      }, 1000);
    },

    openNaverMap(url) {
      const encodedUrl = encodeURI(url);

      if (this.wni.isNative) {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          this.wni.execute("wnOpenURL", { url: url });
          setTimeout(() => {
            if (!document.hidden) {
              const _url =
                url.replace("nmap://", "intent://") +
                "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
              return this.wni.execute("wnOpenURL", { url: _url });
            }
          }, 2000);
        }
      } else {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          url =
            url.replace("nmap://", "intent://") +
            "#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end";
          location.href = url;
        } else {
          location.href = url;
        }
      }
    },

    onClickNaverMap() {
      this.openNaverMap(
        `nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`
      );
    },

    onClickGoogleMap() {
      if (this.wni.isNative) {
        this.wni.execute("wnOpenURL", {
          url: `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,
        });
      } else {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`,
          "_blank"
        );
      }
    },

    onClickCopy() {
      const storeAddress = this.store?.store_address;

      this.$analytics.logEvent("share store information", { "link to": "url" });
      this.$copyText(storeAddress).then((e) => {
        this.$store.commit("toast/ADD_ITEM", {
          message: this.$__t("복사되었습니다"),
        });
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.map-connect {
  padding: unit(20) 0;

  &__title {
    font-size: unit(16);
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.006em;
  }

  &__list {
    margin-top: unit(46);
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: unit(12);
    margin: unit(36) 0;

    .icon {
      flex: 0 0 unit(24);
      width: unit(24);
      height: unit(24);
      object-fit: contain;
    }

    .text {
      flex: 1;
      font-weight: 400;
      font-size: unit(14);
      line-height: 1.43;
    }

    .check {
      flex: 0 0 unit(24);
      width: unit(24);
      height: unit(24);
      object-fit: contain;
    }

    &.on {
      .text {
        color: $color-primary;
      }
    }
  }
}
</style>
