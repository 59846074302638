<template>
  <div
    class="ui-ticket for-host"
    :class="{
      ISSUED: ticket.status == 'ISSUED',
      CHECK_IN: ticket.status == 'CHECK_IN',
      CHECK_OUT: ticket.status == 'CHECK_OUT',
      CANCELLED: ticket.status == 'CANCELLED',
      EXPIRED: isExpired,
    }"
  >
    <router-link :to="{ name: 'HostTicketSingle', params: { code: ticket.ticket_code } }">
      <div class="container">
        <div class="store">
          <img
            :src="ticket.resources.luggage_photo.resource_url"
            v-if="
              ticket.resources &&
              ticket.resources.luggage_photo &&
              ticket.resources.luggage_photo.resource_url
            "
          />
          <img src="/img/no-image.png" v-else />
          <div class="name">
            {{
              ticket.user_name ||
              ticket.reserve.user_first_name + " " + ticket.reserve.user_last_name
            }}<br /><span
              ><i class="fa fa-envelope" /> {{ ticket.reserve.user_email }}</span
            ><br /><span
              ><i class="fa fa-phone-alt" /> {{ ticket.reserve.user_phone }}</span
            >
          </div>
          <div class="arrow">
            <i class="fa fa-angle-right"></i>
          </div>
          <!--          <div class="address">{{ ticket.store_address }} ({{ ticket.store_zipcode }})</div>-->
        </div>
        <div class="reserve">
          <div class="dateInfo">
            {{ this.$moment(this.ticket.ticket_date_start).format($__t("M. D. HH:mm")) }}
            ~
            {{ this.$moment(this.ticket.ticket_date_end).format($__t("M. D. HH:mm")) }} /
            {{ this.ticket.ticket_quantity + this.ticket.ticket_over_quantity }}
            {{ $__t("개") }}
          </div>
          <div class="statusInfo">
            <div class="status">
              {{ isExpired ? $__t("EXPIRED") : $__t(`${ticket.status}_H`) }}
            </div>
            <div
              class="button"
              v-if="
                !isExpired && (ticket.status == 'ISSUED' || ticket.status == 'CHECK_IN')
              "
              @click.prevent="checkTicketCode(ticket.ticket_code)"
            >
              {{ ticket.status == "ISSUED" ? "check-in" : "check-out" }}
            </div>
            <span v-if="ticket.status === 'CHECK_OUT'" class="payment">
              {{ ticket.payment_origin > 0 ? ticket.payment_origin.toLocaleString() : 0
              }}{{ $__t("won") }}
            </span>
          </div>
        </div>
      </div>
    </router-link>

    <transition name="slide-down">
      <checkout-dialog
        :data="DialogItem"
        v-if="flag"
        @makeCheckoutButtonDisable="makeCheckoutButtonDisable"
        :checkoutButtonDisable="checkoutButtonDisable"
      />
    </transition>
  </div>
</template>

<script>
import config from "@/config";
import CheckoutDialog from "@/components/modules/Dialog/CheckoutDialog";
import LuggagePhotoUploader from "@/components/modules/RichForm/LuggagePhotoUploader";

export default {
  props: ["ticket"],
  components: {
    CheckoutDialog,
    LuggagePhotoUploader,
  },
  data: function () {
    return {
      DialogItem: {},
      flag: false,
      checkoutButtonDisable: false,
    };
  },
  mounted() {
    if (this.$route.query.checkTicketCode == this.ticket.ticket_code) {
      this.checkTicketCode(this.ticket.ticket_code);
    }
  },
  computed: {
    isExpired() {
      return this.ticket.status == "EXPIRED";
    },
  },
  methods: {
    // checkIn(code) {
    //   let confirmCheckIn = confirm('체크인하시겠습니까?');
    //   if (!confirmCheckIn) return;

    //   return this.$store
    //     .dispatch('tickets/managerCheckIn', { code })
    //     .then((res) => {
    //       console.log('res', res);
    //       this.$store.commit('tickets/SET_ITEM', { data: res.data.data });
    //       this.$store.commit('alert/ADD_ITEM', { message: '체크인되었습니다.', status: 'success' });
    //       this.$parent.getListItems(true);
    //       this.$parent.getTicketStatusCount();
    //       this.$emit('checked-in', res.data.data);
    //     })
    //     .catch((err) => {
    //       this.$store.commit('alert/ADD_ITEM', { message: res, status: 'error' });
    //     });
    // },
    makeCheckoutButtonDisable() {
      // console.log('makecheckoutbuttondisalbe');
      this.checkoutButtonDisable = true;
    },
    async checkIn(code) {
      let confirmCheckIn = confirm(
        "체크인 하시겠습니까? QR코드 인식 없이 바로 체크인이 완료됩니다! 고객이 QR코드를 찍지 못할 경우에만 버튼을 눌러주세요."
      );
      if (!confirmCheckIn) return;

      try {
        let res = await this.$store.dispatch("tickets/managerCheckIn", { code });
        let data = res.data.data;
        this.$store.commit("tickets/SET_ITEM", { data: data });
        this.$store.commit("alert/ADD_ITEM", {
          message: "체크인되었습니다.",
          status: "success",
        });
        this.$parent.getListItems(true);
        this.$parent.getTicketStatusCount();
        this.$emit("checked-in", data);
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: res, status: "error" });
      }
    },
    async checkOut(code, checkoutTime) {
      //@TODO: 앱에서는 이 confirm이 안뜸.
      // let confirmCheckOut = confirm('체크아웃하시겠습니까?');
      // if (!confirmCheckOut) return;

      try {
        let res = await this.$store.dispatch("tickets/getByCode", { code: code });
        let issue_type = res.data?.data?.issue_type;

        if (issue_type == "PAID") {
          res = await this.$store.dispatch("tickets/checkOut", {
            code: code,
            issue_type,
          });
        } else if (
          issue_type == "RESERVE" ||
          issue_type === "PAY_LATER" ||
          issue_type === "DEPOSIT_ADVANCE"
        ) {
          res = await this.$store.dispatch("tickets/checkoutAfter", {
            code: code,
            checkout_time: checkoutTime,
            issue_type,
          });
        }

        this.$store.commit("tickets/SET_ITEM", { data: res.data.data });
        this.$store.commit("alert/ADD_ITEM", {
          message: "체크아웃되었습니다.",
          status: "success",
        });
        this.$parent.getListItems(true);
        this.$parent.getTicketStatusCount();
      } catch (e) {
        this.checkoutButtonDisable = false;
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      }
    },
    checkTicketCode(ticket_code) {
      this.$emit("check");
      this.$store.commit("loading/SET_TRUE");
      this.axios
        .get(config.apiURL + "/v2/manager/tickets/" + ticket_code, {
          headers: config.userHeaders,
        })
        .then((res) => {
          if (res.data.data.status == "CHECK_OUT") {
            this.$store.commit("alert/ADD_ITEM", {
              message: this.$__t("이미 처리된 티켓입니다."),
              status: "error",
            });
          } else if (res.data.data.status == "CANCELLED") {
            this.$store.commit("alert/ADD_ITEM", {
              message: this.$__t("취소된 티켓입니다."),
              status: "error",
            });
          } else if (res.data.data.status == "ISSUED") {
            this.checkIn(ticket_code).finally(() => {
              setTimeout(() => {
                this.$store.commit("loading/SET_FALSE");
              }, 1000);
            });
          } else if (res.data.data.status == "CHECK_IN") {
            this.$store.commit("loading/SET_FALSE");
            this.DialogItem.ticket = this.ticket;
            this.DialogItem.message =
              (res.data.data.reserve.user_name ||
                res.data.data.reserve.user_last_name +
                  " " +
                  res.data.data.reserve.user_first_name) +
              " " +
              this.$__t("님이 예약하신 티켓입니다.") +
              " " +
              this.$__t(
                (res.data.data.status == "CHECK_IN" ? "체크아웃" : "체크인") +
                  "하시겠습니까?"
              );
            this.DialogItem.notice = this.$__t(
              "체크아웃 후에는 시간 변경이 불가합니다.\n현재 시간이 체크아웃 시간이 아닐 경우 체크아웃을 진행하지 마시고 고객센터로 연락주세요.\n❌임의 체크아웃시 정산금이 차감될 수 있으니 꼭 시간을 확인해주세요."
            );
            this.DialogItem.ok = {
              label: this.$__t("예"),
              callback: () => {
                this.$store.commit("loading/SET_TRUE");
                this.checkOut(ticket_code).finally(() => {
                  setTimeout(() => {
                    this.$store.commit("loading/SET_FALSE");
                  }, 1000);
                });
              },
            };
            this.DialogItem.cancel = {
              label: this.$__t("아니오"),
              callback: () => {
                this.$store.commit("loading/SET_FALSE");
                this.flag = false;
                $("#ch-plugin-launcher").removeClass("hidden");
              },
            };
            this.flag = true;
            $("#ch-plugin-launcher").addClass("hidden");
          }
        })
        .catch((err) => {
          this.$store.commit("alert/ADD_ITEM", { message: err, status: "error" });
        });
    },
  },
};
</script>
