<template>
  <div
    id="site-header"
    :class="{
      [type]: true,
      'header-hidden': $route.name == 'Stores',
      'header-payment': $route.name == 'DeliveryStatus' || $route.path.indexOf('/delivery/step') > -1
    }"
  >
    <div class="container">
      <template v-if="type === 'more-depth'">
        <div class="header">
          <button v-button class="header__button--left" type="button" @click="onBack">
            <img src="@/assets/icons/svg/arrow-left.svg" />
          </button>
          <div class="title">{{ $__t(title) | capitalizeFirstLetter }}</div>
        </div>
      </template>

      <template v-else-if="type === 'pc-header'">
        <div class="logo-center">
          <button @click="$router.push({ name: 'Home' }).catch(() => {})">
            <img src="/img/logotype.svg" @click="onClickHome" />
          </button>
        </div>
      </template>

      <template v-else>
        <div class="ticket" v-if="$store.state.auth.gid">
          <img src="/img/icon-ticket.png" @click="onClick('my_bookings')" style="cursor: pointer; z-index: 1" />
          <div class="ticket-badge" v-if="tickets.length > 0">
            <badge type="circle" bg-color="red">{{ tickets.length }}</badge>
          </div>
          <div class="ticket-badge" v-if="tickets.length == 0">
            <badge type="circle" bg-color="#C1C1C1">{{ tickets.length }}</badge>
          </div>
        </div>
        <div class="logo" v-if="logoType == 'default'">
          <div><img src="/img/logotype.svg" @click="onClickHome" style="cursor: pointer" /></div>
        </div>
        <div class="logo" v-else-if="logoType == 'iez'">
          <div>
            <img src="/img/iez-header.png" @click="onClickIezLogo" style="cursor: pointer; height: 2.3rem; top: 0.2rem" />
          </div>
        </div>
        <div class="quick-menu">
          <ul v-if="!$store.state.auth.gid">
            <li @click="onClick('search_stores')">{{ $__t('go keep my item') | capitalizeFirstLetter }}</li>
            <li @click="onClick('guide')" class="guide">{{ $__t('서비스 가이드') }}</li>
            <li @click="onClick('faq')" class="guide">{{ $__t('자주 찾는 질문') }}</li>
          </ul>
          <ul v-if="$store.state.auth.gid">
            <li @click="onClick('search_stores')">{{ $__t('go keep my item') | capitalizeFirstLetter }}</li>
            <li @click="onClick('my_bookings')">{{ $__t('예약 확인하기') }}</li>
            <li @click="onClick('favorite_stores')">{{ $__t('관심 상점 보기') }}</li>
            <!-- <li v-if="clickedFrom" @click="onClick('become_host')" v-ga="$analytics.tracking.bind(this, 'home', 'click', 'home_top_become_host')">
              {{ $__t('호스트 신청하기') }}
            </li>
            <li v-else @click="onClick('become_host')">
              {{ $__t('호스트 신청하기') }}
            </li> -->
          </ul>
        </div>
        <div class="navigation">
          <ul>
            <li v-if="$store.state.auth.gid" @click="onClick('guide')" class="guide">{{ $__t('서비스 가이드') }}</li>
            <!-- <li v-if="!$store.state.auth.gid" @click="onClick('become_host')" class="guide">
              {{ $__t('호스트 신청하기') }}
            </li> -->
            <li class="session" @click="onClick('login')" v-if="!$store.state.auth.gid">{{ $__t('로그인') }}</li>
            <li class="session" @click="onClick('my_page')" v-if="$store.state.auth.gid">{{ $__t('마이페이지') }}</li>
          </ul>
        </div>
      </template>
      <!--here-->
      <template>
        <div
          id="site-sidebar-toggler"
          v-if="sidebarTogglerVisible"
          @click="toggleSidebar"
          :class="{
            on: $route.name === 'Stores',
            payment: $route.path.indexOf('/reserve/') > 0
          }"
        >
          <i class="fa fa-bars"></i>
          <badge v-if="redAlarm" type="circle" bg-color="red" class="red-alarm"></badge>
        </div>
      </template>
      <transition name="sidebar">
        <site-sidebar v-if="sidebarOpened" @close="closeSidebar" />
      </transition>
      <!--here-->
    </div>
  </div>
</template>

<script>
import Badge from '@/components/modules/Badge/Badge';
import utils from '@/utils';
import SiteSidebar from '@/components/outline/Sidebar';

export default {
  components: {
    Badge,
    SiteSidebar
  },
  data() {
    return {
      //here
      sidebarOpened: false,
      //here
      logoType: this.type
    };
  },
  props: ['type', 'title'],
  computed: {
    //here
    coupons() {
      let coupons = this.$store.getters['resourceCoupons/GET_ITEMS_BY_LIST']({
        list: 'MyCoupons'
      });

      if (coupons && coupons.length > 0) {
        coupons = coupons.filter((e) => !e.used && !e.expired);
      }

      return coupons;
    },

    redAlarm() {
      if ((this.coupons && this.coupons.length) || (this.unreadNotices && this.unreadNotices.length) || (this.tickets && this.tickets.length)) {
        return true;
      }

      return false;
    },
    sidebarTogglerVisible() {
      if (!this.$store.state.config.isTouchDevice) {
        return false;
      }

      if (this.wni.isNative) {
        return false;
      }

      if (this.$route.meta.headerType === 'more-depth' || this.$route.params.headerType === 'more-depth') {
        return false;
      }

      return this.$route.meta && this.$route.meta.hideSidebar !== true;
    },
    //here
    clickedFrom() {
      if (this.$route.name === 'Home') return 'home';
      else if (this.$route.name === 'Stores') return 'stores';
      else if (this.$route.name === 'StoreSingle') return 'shop';
      else return null;
    },
    tickets() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({ list: 'MyTickets_' + 'available' });
    },
    auth() {
      return this.$store.state.auth.gid;
    },
    searchText() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'search_text' });
    }
  },
  watch: {
    $route: {
      async handler(to, from) {
        const view_type = localStorage.getItem('__LUGSTAY__VIEW__TYPE__');

        if (view_type) {
          this.logoType = view_type;
        }
      },
      immediate: true
    },
    type(value) {
      if (value) this.logoType = value;
    }
  },
  methods: {
    //here
    toggleSidebar() {
      this.sidebarOpened = !this.sidebarOpened;
      // console.log('sidebar', this.sidebarOpened);
      if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_sidebar');
    },
    closeSidebar() {
      this.sidebarOpened = false;
    },
    //here
    onBack() {
      const backRouteName = this.$route.query && this.$route.query.back || this.$route.props && this.$route.props.back;
      if (backRouteName){
        this.$router.push({ name: backRouteName });
        return;
      }

      this.$router.go(-1);
    },
    onClickHome() {
      this.$analytics.logEvent('home button click');

      if (this.$route.name === 'Home') {
        this.$analytics.tracking.call(this, 'home', 'click', 'home_top_logo');
        window.scrollTo(0, 0);
        return;
      } else if (this.$route.name === 'Stores') {
        this.$analytics.tracking.call(this, 'stores', 'click', 'stores_top_logo');
      } else if (this.$route.name === 'StoreSingle') {
        this.$analytics.tracking.call(this, 'shop', 'click', 'shop_top_logo');
      }

      this.$router.push({ name: 'Home' });
    },
    onClickIezLogo() {
      this.$router.push({ name: 'Iez' });
    },
    shiftLanguage(e) {
      this.$store
        .dispatch('auth/edit', { user_language: e.target.value })
        .then((res) => {
          localStorage.setItem('lugstay_language', e.target.value);
          window.location = utils.removeURLParameter(window.location.href, 'language');
        })
        .catch((err) => {
          localStorage.setItem('lugstay_language', e.target.value);
          window.location = utils.removeURLParameter(window.location.href, 'language');
        });
    },
    goToMyTickets() {
      if (this.clickedFrom) {
        this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_my_bookings');
      }
      this.$router.push({ name: 'MyReservation' });
    },

    onClick(param) {
      let routeName = '';

      switch (param) {
        case 'search_stores':
          this.$analytics.logEvent('gnb click', { menu: 'search_stores' });
          routeName = 'Stores';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
            this.$router.push({ name: 'Stores' });
          }
          break;
        case 'guide':
          this.$analytics.logEvent('gnb click', { menu: 'guide' });
          routeName = 'CsGuide';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
            this.$router.push({ name: 'CsGuide' });
          }
          break;
        case 'faq':
          this.$analytics.logEvent('gnb click', { menu: 'faq' });
          routeName = 'CsFaq';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
            this.$router.push({ name: 'CsFaq' });
          }
          break;
        case 'login':
          this.$analytics.logEvent('gnb click', { menu: 'login' });
          routeName = 'AuthLogin';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
            this.$router.push({ name: 'AuthLogin', query: { redirect: this.$router.currentRoute.fullPath } });
          }
          break;
        case 'become_host':
          this.$analytics.logEvent('gnb click', { menu: 'become_host' });
          if (this.clickedFrom) {
            this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
          }
          this.$router.push({ name: 'HostLanding' });
          break;
        case 'my_page':
          this.$analytics.logEvent('gnb click', { menu: 'my_page' });
          routeName = 'Mypage';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
            this.$router.push({ name: 'Mypage' }).catch((err) => {});
          }
          break;
        case 'favorite_stores':
          this.$analytics.logEvent('gnb click', { menu: 'favorite_stores' });
          routeName = 'MypageInterestStores';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);
            this.$router.push({ name: 'MypageInterestStores' });
          }
          break;
        case 'my_bookings':
          this.$analytics.logEvent('gnb click', { menu: 'my_bookings' });
          routeName = 'MypageTickets';
          if (this.$route.name !== routeName) {
            if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_top_' + param);

            if (this.wni.isNative) {
              this.$router.push('/#tickets');
            } else {
              this.$router.push({ name: 'MypageTickets' }).then(() => this.$analytics.logEvent('booking list page view'));
            }
          }
          break;
      }
    }
  }
};
</script>
